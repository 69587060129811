import React from 'react'
import { Form } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { Field } from 'formik'

import { TextField, Select, Checkbox } from '@/components/forms/formik'

import { PlaybookFormActions } from '../../components/PlaybookFormActions'
import { FieldWarning } from '../../components/FieldWarning'
import { FormObserver } from '../../components/FormObserver'
import {
  triggerTimeUnits,
  notificationTriggerTypes,
  notificationTriggerTypesDeprecated,
  accessors,
} from '../../playbook.helpers'

export const NotificationsForm = ({
  readOnly,
  values,
  errors,
  entryId,
  handleSubmit,
  handleCancel,
  handleResetForm,
  ...formikProps
}) => {
  const isRetiredNotificationType = ['keywords', 'verbatim'].includes(values.trigger.type)
  const triggerTypeOptions = isRetiredNotificationType
    ? notificationTriggerTypesDeprecated
    : notificationTriggerTypes
  const messagePlaceholder =
    values.trigger.type === 'dead_air'
      ? `You haven't said anything for a while - break the silence!`
      : 'Slow down a little bit?'
  const minimumThresholdTime = values.trigger.time_unit === 'sec' ? '10' : '1'
  const formDisabled = readOnly || isRetiredNotificationType
  const submitDisabled = !isEmpty(errors) || formDisabled

  return (
    <Form onSubmit={handleSubmit} className="playbook-detail__content">
      <FormObserver entryId={entryId} sectionName={accessors.NOTIFICATION} />
      {isRetiredNotificationType && (
        <div className="form-caption">
          Keywords is a retired notification type. This entry is read only.
        </div>
      )}
      <Field
        required
        label="Trigger Type"
        name="trigger.type"
        component={Select}
        options={triggerTypeOptions}
        isClearable={false}
        onChange={handleResetForm}
        disabled={formDisabled}
      />
      <Field required label="Name" name="name" component={TextField} disabled={formDisabled} />
      {!readOnly && <FieldWarning accessor="notifications" field="name" entryId={entryId} />}
      <Field
        label="Message"
        name="display.reason"
        component={TextField}
        placeholder={messagePlaceholder}
        disabled={formDisabled}
      />
      {!readOnly && (
        <FieldWarning accessor="notifications" field="display.reason" entryId={entryId} />
      )}
      {isRetiredNotificationType && (
        <>
          <Field
            label="Replacement"
            name="display.replacement"
            component={TextField}
            disabled={formDisabled}
          />
          {!readOnly && (
            <FieldWarning accessor="notifications" field="display.replacement" entryId={entryId} />
          )}
        </>
      )}
      {values.trigger.type === 'dead_air' && (
        <>
          <Form.Field>
            <label>Time Configuration</label>
            <div className="form-caption">
              Specify the length of dead air that must be present before this notification triggers
            </div>
            <div className="form-row">
              <Field
                name="trigger.threshold_time"
                className="small-field"
                component={TextField}
                type="number"
                min={minimumThresholdTime}
                disabled={formDisabled}
              />
              <Field
                name="trigger.time_unit"
                component={Select}
                options={triggerTimeUnits}
                isClearable={false}
                disabled={formDisabled}
              />
            </div>
          </Form.Field>
          <Field
            label="Trigger Once"
            name="trigger.max_triggers"
            component={Checkbox}
            inline
            disabled={formDisabled}
          />
        </>
      )}

      <PlaybookFormActions
        isDisabled={submitDisabled}
        handleCancel={handleCancel}
        dirty={formikProps.dirty}
      />
    </Form>
  )
}
