import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { fetchPlaylist } from '@/reducers/playlists/playlists.actions'

import { PlaylistCallsContent } from './components/PlaylistCallsContent'

const PlaylistPage = () => {
  const dispatch = useDispatch()
  const { playlistUuid } = useParams()
  const { search } = useLocation()
  const { organization_id: organizationId } = queryString.parse(search)

  useEffect(() => {
    if (playlistUuid) {
      dispatch(fetchPlaylist(playlistUuid, organizationId))
    }
  }, [playlistUuid])

  return <PlaylistCallsContent />
}

export default PlaylistPage
