/**
 * Helper function for consistently setting user attributes in LaunchDarkly
 * @see https://docs.launchdarkly.com/home/users/attributes
 */
export const buildUserAttributes = (user) => {
  const { username, organizationid } = user

  return {
    // built-in LD attributes
    key: username, // must be unique for each user
    email: username,
    custom: {
      // custom-defined attributes
      organizationId: organizationid,
    },
  }
}
