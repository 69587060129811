import React, { useEffect } from 'react'
import { Button, Form, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { first, isEmpty } from 'lodash'

import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { EditColumnsForm } from '@/components/tables/CustomColumns/EditColumnsForm'
import Widget from '@/components/widget/Widget'
import {
  fetchAggregateScoresByConfigId,
  fetchAggregateScoresByMultipleScorecards,
  fetchAggregateScores,
  fetchScorecardByScorecardConfigs,
  fetchStreamingScores,
} from '@/reducers/scorecards/scorecards.actions'
import { setFilter, setTableHeaders } from '@/reducers/scorecards/scorecards.redux'

import { ViewScoresFilters } from '../../ViewScoresFilters/ViewScoresFilters'
import { ViewScoresOptions } from '../ViewScoresOptions'

import './FiltersSection.css'

// Custom columns on this page is front end only and does not save to the backend database, so it's not using the shared code
export const FiltersSection = () => {
  const dispatch = useDispatch()
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const isBaltoAdmin = organizationId === 1
  const { data, filters, loading } = useSelector((state) => state.scorecards)
  const location = useLocation()
  const isQACopilot = location.pathname.startsWith('/qa-copilot')

  const getAggregateData = () => {
    if (!isQACopilot) {
      filters.scorecard_type = 'automated'
      if (filters.groupBy.some((item) => item.value === 'calls')) {
        dispatch(fetchStreamingScores())
        dispatch(fetchScorecardByScorecardConfigs(true))
      } else if (!isEmpty(filters.scorecards) && filters.scorecards.length > 1) {
        dispatch(fetchAggregateScoresByMultipleScorecards('table'))
      } else if (!isEmpty(filters.scorecards)) {
        dispatch(fetchAggregateScoresByConfigId(first(filters.scorecards).value, 'table'))
      } else if (!isBaltoAdmin) {
        dispatch(fetchAggregateScores('table'))
      }
    } else {
      filters.scorecard_type = 'copilot'
      dispatch(fetchScorecardByScorecardConfigs())
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setFilter('groupBy', []))
    }
  }, [])

  const setFilteredColumns = (values) => {
    dispatch(setTableHeaders('advancedExports', values || []))
  }

  const isPreviewDisabled = () => {
    if (loading.scores) {
      return true
    }

    if (isEmpty(filters.scorecards)) {
      return true
    }

    if (!isQACopilot && (isEmpty(filters.metrics) || isEmpty(filters.groupBy))) {
      return true
    }

    if (loading.advancedExportsStreamedCsv) {
      return true
    }

    return false
  }

  return (
    <div className="scorecard-exports_filter-wrapper">
      <Widget displayControls={false}>
        <div className="scorecard-exports_filter-selector">
          <h4 className="scorecard-exports_filter-title">Filters</h4>
          <ViewScoresFilters
            hideFilterButton
            multiScorecard
            hide={{ scorecardConfigs: true, measures: isQACopilot }}
            exports
          />
        </div>
        {!isQACopilot && (
          <div className="scorecard-exports_filter-row">
            <ViewScoresOptions />
          </div>
        )}
        <div className="scorecard-exports_filter-last-row">
          <Form.Field>
            <Popup
              inverted
              disabled={
                !isPreviewDisabled() || loading.scores || loading.advancedExportsStreamedCsv
              }
              trigger={
                <div className="scorecard-exports_preview-button">
                  <Button
                    primary
                    type="submit"
                    data-testid="filter-button"
                    loading={loading.scores || loading.advancedExportsStreamedCsv}
                    disabled={isPreviewDisabled()}
                    onClick={getAggregateData}
                  >
                    Preview
                  </Button>
                </div>
              }
              content={
                isQACopilot
                  ? 'At least one scorecard must be selected'
                  : 'At least one scorecard, display metric, and grouped by variable must be selected'
              }
            />
          </Form.Field>
          <Form.Field>
            <div className="columns-button">
              <ButtonAndFormModal
                buttonLabel="Edit Columns"
                modalTitle="Edit Columns"
                modalId="scorecardExports/editColumns"
                modalProps={{ size: 'tiny' }}
                disabled={
                  data?.tableHeaders?.advancedExports.length === 0 ||
                  data?.tableRows?.advancedExports.length === 0 ||
                  isPreviewDisabled()
                }
                buttonProps={{ secondary: true }}
                form={
                  <EditColumnsForm
                    allColumns={data?.tableHeaders?.advancedExports}
                    handleSubmit={(values) => {
                      setFilteredColumns(values)
                    }}
                  />
                }
              />
            </div>
          </Form.Field>
        </div>
      </Widget>
    </div>
  )
}
