import React from 'react'
import classNames from 'classnames'

import './ModuleCard.scss'

export const ModuleCard = ({
  title,
  children,
  buttons,
  pills,
  fullHeight,
  className,
  compact,
  ...props
}) => {
  return (
    <div
      className={classNames('module-card', className, { 'full-height': fullHeight, compact })}
      {...props}
    >
      <header className="flex flex-align-center flex-space-between">
        <div className="flex flex-align-center gap">
          <h2>{title}</h2>
          {pills}
        </div>
        {buttons}
      </header>
      {children}
    </div>
  )
}
