import React from 'react'
import classNames from 'classnames'

import './Banner.scss'

export const Banner = ({
  emphasized,
  brand,
  success,
  warning,
  caution,
  critical,
  informative,
  shrunk,
  magical,
  header,
  icon,
  large,
  content,
  children,
  className,
  dataTestId,
  ...props
}) => {
  return (
    <div
      data-testid={dataTestId || 'banner'}
      className={classNames('banner', className, {
        emphasized,
        brand,
        success,
        warning,
        caution,
        critical,
        informative,
        shrunk,
        magical,
        icon,
        large,
      })}
      {...props}
    >
      {icon}
      {header && (
        <header data-testid={dataTestId ? `${dataTestId}-header` : 'header'}>
          <h2>{header}</h2>
        </header>
      )}
      <div>{content || children}</div>
    </div>
  )
}
