import React from 'react'
import { useDispatch } from 'react-redux'
import { Image, List } from 'semantic-ui-react'

import { setParsedCsv } from '@/reducers/organizations/organizationUsersCSV.redux'
import exampleCSV from '../../../assets/images/exampleCSV.png'
import parseCSVData, { headersWhiteList, toTitleCase } from './helpers'
import CSVReader from '../../CSVReader'

export const Instructions = ({ tagCategories, nextPanel }) => {
  const dispatch = useDispatch()

  const downloadCSVTemplate = () => {
    const templateData = [
      headersWhiteList,
      [
        'Wile',
        'E Coyote',
        'roadrunner@acme.com',
        'Acme - Outbound Anvil Sales',
        '214901042915436',
        'yes',
        'yes',
        'no',
        'active',
        'cartoon, road_runner',
        'yes',
        'yes',
        'standard',
        'yes',
        'yes',
        'no',
        'yes',
        'no',
        'yes',
        '',
      ],
      [
        'Bugs',
        'Bunny',
        'doc@acme.com',
        'Acme - Inbound Anvil Sales',
        '',
        'yes',
        'no',
        'no',
        'active',
        'cartoon, bunny',
        'yes',
        'yes',
        'admin',
        'yes',
        'yes',
        'yes',
        'yes',
        'yes',
        'yes',
        'bugs@warnerbros.com',
      ],
      [
        'Tweety',
        'Bird',
        'bird@acme.com',
        'Acme - Bird House Sales',
        '1234141',
        'no',
        'no',
        'no',
        'deleted',
        'cartoon, bird, tiny',
        'yes',
        'yes',
        'standard',
        'yes',
        'yes',
        'no',
        'yes',
        'no',
        'yes',
        'tweety@xhitter.com',
      ],
    ]

    // add tag category columns in the template.
    for (const category of tagCategories) {
      templateData[0].push(category.name)
    }

    // add empty values to tag category columns.
    for (let i = 1; i < templateData.length; i++) {
      for (let j = 0; j < tagCategories.length; j++) {
        templateData[i].push('')
      }
    }

    const csvData = templateData.map((row) => row.map((cell) => `"${cell}"`).join(',')).join('\n')

    const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })
    const encodedUri = URL.createObjectURL(csvBlob)
    const link = document.createElement('a')

    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'balto_bulk_user_upload_template.csv')

    document.body.appendChild(link) // Required for FF
    link.click()
    document.body.removeChild(link)
  }

  const handleCSVUpload = (csvData) => {
    const { tags, users, headers, configNames } = parseCSVData(csvData, tagCategories)

    dispatch(setParsedCsv({ tags, users, headers, configNames, tagCategories }))
    return nextPanel()
  }

  const requiredStrings = ['first_name', 'last_name', 'username', 'playbook']
  const firstOptionalStrings = [
    'voip_user_id',
    'balto_cloud_access',
    'playbook_edit_access',
    'user_management_access',
    'user_status',
  ]
  const lastOptionalStrings = [
    'realtime_qa_access',
    'edit_leaderboard',
    'realtime_coaching_access',
    'game_admin',
    'midcall_playbook_switching_enabled',
    'desktop_enterprise_enabled',
    'edit_qa',
    'edit_qa_copilot',
    'hierarchy_manager',
    'screen_capture_enabled',
    'desired_username',
  ]
  const titlesAndDescriptions = {
    playbooks: () =>
      'Playbook names <strong>must match exactly</strong>. If we can not find a matching playbook name, the user will not be created.',
    realtime_coaching_access: () =>
      'Gives user access to Realtime Coaching. Accepted values: <strong>standard</strong> / <strong>admin</strong>',
    user_status: () =>
      'Set user status to active or deleted. Accepted values: <strong>active</strong> / <strong>deleted</strong>',
    screen_capture_enabled: () =>
      'Set Screen Capture. Accepted values: <strong>yes</strong> / <strong>no</strong> / <strong>null</strong>. Setting to "null" will nullify the value, opposed to nothing in the cell, which will not update anything.',
    desired_username: () => "Set this to the user's new email address if they want to change it.",
    everything_else: () =>
      "Everything else will be <strong>yes</strong> or <strong>no</strong> and work how you'd expect",
  }

  const renderDescription = (description) => {
    const parts = description.split(/(<strong>|<\/strong>)/)

    return parts.map((part, index) => {
      if (part === '<strong>' || part === '</strong>') {
        return null
      }
      if (index > 0 && parts[index - 1] === '<strong>') {
        return <strong key={parts[index]}>{part}</strong>
      }

      return part
    })
  }

  return (
    <div>
      <Image
        fluid
        onClick={() => downloadCSVTemplate()}
        style={{ cursor: 'pointer' }}
        label={{
          as: 'a',
          color: 'blue',
          content: 'Click to Download Template',
          onClick: (e) => {
            e.stopPropagation()
            downloadCSVTemplate()
          },
          ribbon: true,
        }}
        src={exampleCSV}
      />

      <List divided relaxed>
        <div className="ui grid">
          <div className="five wide column">
            <List.Item>
              <List.Content>
                <List.Header>
                  <strong>Headers</strong>
                </List.Header>
                <List.Description>
                  We <strong>only</strong> accept these headers
                  <ul>
                    {requiredStrings.map((required) => (
                      <li key={required}>
                        <code>{required}</code>
                      </li>
                    ))}
                    {firstOptionalStrings.map((option) => (
                      <li key={option}>
                        <code>{option}</code> (optional)
                      </li>
                    ))}
                    {/* Let's keep the tag stuff the way it was, where it was, as to not confuse users */}
                    <li>
                      <code>uncategorized_tags</code> (optional)
                    </li>
                    <li>
                      <code>
                        {(!tagCategories || tagCategories.length === 0) && '<tag category>'}
                        {tagCategories && tagCategories.length > 0 && 'Tag category:'}
                        {tagCategories &&
                          tagCategories.length > 0 &&
                          tagCategories.map((category) => category.name).join(', ')}
                      </code>{' '}
                      (optional)
                    </li>
                    {lastOptionalStrings.map((option) => (
                      <li key={option}>
                        <code>{option}</code> (optional)
                      </li>
                    ))}
                  </ul>
                </List.Description>
              </List.Content>
            </List.Item>
          </div>
          <div className="eleven wide column">
            <List.Item>
              <List.Content>
                <List.Header>
                  <strong>Uncategorized Tags</strong>
                </List.Header>
                <List.Description>
                  Any tags that do not exist will be created automatically.
                </List.Description>
                <List.Description>
                  You can have as many or as few tags in the <code>tags</code> column as you like.
                </List.Description>
                <List.Description>
                  Tags may only contain <code>a-z-0-9 and _</code>. No other characters or capital
                  letters are allowed.
                </List.Description>
                <List.Description>
                  Users will fail to be created if their row contains an invalid tag.
                </List.Description>
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                <List.Header>
                  {(!tagCategories || tagCategories.length === 0) && (
                    <strong>&lt;tag category&gt;</strong>
                  )}
                  {tagCategories && tagCategories.length > 0 && (
                    <strong>{tagCategories.map((category) => category.name).join(', ')}</strong>
                  )}
                </List.Header>
                <List.Description>
                  You can add a tag category column and can set any tag that belong to that category
                  as value for a user.
                </List.Description>
                <List.Description>
                  You can have any number of <code>tag category</code> you like.
                </List.Description>
                <List.Description>
                  Tags may only contain <code>a-z-0-9 and _</code> no other characters or capital
                  letters are allowed.
                </List.Description>
                <List.Description>
                  Users will fail to be created if their row contains an invalid tag.
                </List.Description>
              </List.Content>
            </List.Item>
            {Object.entries(titlesAndDescriptions).map(([key, getDescription]) => (
              <List.Item key={key}>
                <List.Content>
                  <List.Header>
                    <strong key={`${key}-strong`}>{toTitleCase(key)}</strong>
                  </List.Header>
                  <List.Description key={`${key}-description`}>
                    {renderDescription(getDescription())}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </div>
        </div>
      </List>
      <CSVReader onFileLoaded={(csvData) => handleCSVUpload(csvData)} />
    </div>
  )
}
