import React from 'react'
import classNames from 'classnames'
import { Icon, Checkbox } from 'semantic-ui-react'

import { WinIcon } from '@/components/icons/WinIcon'
import { accessors } from '../../playbook.helpers'

export const ChecklistEntryPreview = ({ isEditEntry, entry, accessor }) => {
  if (!entry) {
    return null
  }

  return (
    <li
      className={classNames('checklist-entry', {
        'extra-muted-text': !isEditEntry,
        'dynamic-prompt': accessor === accessors.DYNAMIC_PROMPT,
        win: entry.win,
        locked: entry.is_locked,
      })}
    >
      {accessor === accessors.DYNAMIC_PROMPT && !entry.win && (
        <Checkbox readOnly className="preview-checkbox" />
      )}
      {entry.win && <WinIcon className="win-icon" data-testid="win-icon" />}
      {entry.is_locked && !entry.win && <Icon name="lock" />}
      <span>{entry.name}</span>
    </li>
  )
}
