import React from 'react'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { selectStyles, selectTheme, Input } from '../helpers/selectHelpers'

const handleNumberOnlyInput = (e) => {
  if (e.nativeEvent.code === 'KeyE' || e.nativeEvent.code === 'Minus') {
    e.preventDefault()
  }
}

export default function Select({
  loading,
  options = [],
  value,
  fixedWidth,
  allowFreeText,
  isNumberField,
  isClearable = true,
  ...props
}) {
  const getOptionValue = (selectOptions, selectValue, isNumber) => {
    if (!selectValue) {
      return undefined
    }
    // Option exists
    const foundValue = selectOptions.find((option) => option.value === selectValue)
    if (foundValue) {
      return foundValue
    }
    // Option is free text
    return { value: selectValue, label: `${selectValue}${isNumber ? ' minutes or more' : ''}` }
  }

  if (allowFreeText) {
    return (
      <CreatableSelect
        isClearable
        backspaceRemovesValue
        options={options}
        formatCreateLabel={
          isNumberField ? (userInput) => `Select ${userInput} minutes or more` : undefined
        }
        onChange={props.onChange}
        value={getOptionValue(options, value, isNumberField)}
        onKeyDown={isNumberField ? handleNumberOnlyInput : undefined}
        components={isNumberField ? { Input } : undefined}
        styles={selectStyles(fixedWidth)}
        theme={(theme) => selectTheme(theme)}
        {...props}
      />
    )
  }
  return (
    <ReactSelect
      isClearable={isClearable}
      isSearchable
      closeMenuOnSelect
      escapeClearsValue={false}
      hideSelectedOptions={false}
      backspaceRemovesValue={false}
      options={options}
      value={options.find((option) => option.value === value) || ''}
      styles={selectStyles(fixedWidth)}
      theme={(theme) => selectTheme(theme)}
      isLoading={loading}
      {...props}
    />
  )
}
