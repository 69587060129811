import React from 'react'
import { Button } from 'semantic-ui-react'

export const BaltoCloudAccessModal = ({ saveUser, user }) => {
  const { first_name, last_name, username } = user

  const handleSaveUser = () => {
    saveUser(user)
  }

  return (
    <>
      <div className="modal-message" data-testid="cloud-access-modal">
        <div>
          Are you sure you want to give{' '}
          <b>
            {first_name} {last_name}
          </b>{' '}
          Balto Cloud Access?
        </div>
        <div>
          If you confirm we&apos;ll send an account setup email to <b>{username}.</b>
        </div>
      </div>
      <Button onClick={handleSaveUser} primary data-testid="balto-cloud-access-button">
        Give Access
      </Button>
    </>
  )
}

export default BaltoCloudAccessModal
