import React, { useState } from 'react'
import { Button, Card, Form, Icon, Popup } from 'semantic-ui-react'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import pluralize from 'pluralize'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { IconPencil } from '@tabler/icons-react'

import { Pill } from '@/components/pills/Pill'
import { editMeasureName, editSectionName } from '@/reducers/scorecards/scorecards.actions'
import { TextField } from '@/components/forms/formik'

import './scorecards.css'

export const ScorecardEditorCard = ({ cardData, onClick, children, sectionData = false }) => {
  const { data } = useSelector((state) => state.scorecards)
  const { sid } = data.targetScorecardConfig
  const { ssid } = data.targetScorecardSection
  const { currentOrganizationId } = data
  const dispatch = useDispatch()

  const [editing, setEditing] = useState(false)
  const [name, setName] = useState(cardData.name)

  const activeClass = cardData.active ? 'status-success' : 'status-critical'
  const autoFail =
    cardData && cardData.measures ? cardData.measures.filter((measure) => measure.auto_fail) : []
  const autoZero =
    cardData && cardData.measures ? cardData.measures.filter((measure) => measure.auto_zero) : []
  const criteriaLength = []
  cardData?.measures?.forEach((measure) => criteriaLength.push(measure.criteria.length))
  const measureLength = []
  cardData?.measures?.forEach((measure) => {
    if (measure.criteria.length > 0) {
      measureLength.push(measure)
    }
  })

  const toggleEdit = () => {
    if (name !== cardData.name && editing) {
      setName(cardData.name)
    }
    setEditing(!editing)
  }

  const submitNameChange = ({ name }) => {
    if (sectionData) {
      dispatch(editMeasureName(sid, ssid, cardData.smid, name, currentOrganizationId))
    } else {
      dispatch(editSectionName(sid, cardData.ssid, name, currentOrganizationId))
    }
    setEditing(false)
  }

  const initialValues = {
    name: cardData.name,
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, 'Name cannot be longer than 100 characters.')
      .required('Name is required'),
  })
  return (
    <Card
      className={activeClass}
      data-testid="scorecard-section-card"
      key={cardData.name}
      onClick={() => {}}
    >
      <Card.Content>
        {editing ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => submitNameChange(values)}
          >
            {({ handleSubmit, errors }) => (
              <div className="editor-card-form">
                <Form>
                  <Field
                    data-testid="edit_section_name_box"
                    name="name"
                    component={TextField}
                    className="editor-card-input"
                    placeholder="Section Name"
                  />
                </Form>
                <Button
                  primary
                  icon
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!isEmpty(errors)}
                  className="editor-card-submit"
                  data-testid="edit_name_submit"
                >
                  <Icon name="check" />
                </Button>
                <Button
                  icon
                  type="button"
                  className="editor-card-cancel"
                  onClick={() => toggleEdit()}
                  data-testid="edit_name_cancel"
                >
                  <Icon name="cancel" />
                </Button>
              </div>
            )}
          </Formik>
        ) : (
          <Card.Header data-testid="scorecard-section-name" className="scorecard-section-name">
            <Popup
              inverted
              content={cardData.name}
              trigger={<div className="scorecard-editor-title">{cardData.name}</div>}
            />
            <Button
              type="button"
              className="svg-button editor-card-pencil"
              icon
              basic
              onClick={() => toggleEdit()}
              data-testid="edit_category_toggle"
            >
              <IconPencil />
            </Button>
          </Card.Header>
        )}
      </Card.Content>

      <Card.Content style={{ borderTop: 'none' }} onClick={() => onClick(cardData)}>
        <Card.Description>
          <div className="editor-card-content" data-testid="scorecard-section-message">
            {children}
            {measureLength.length > 0 && (
              <Pill emphasized small informative data-testid="criteria-badge">
                {pluralize(`Measure`, measureLength.length, true)} in use
              </Pill>
            )}
            {autoFail.length > 0 && (
              <Pill emphasized small critical data-testid="autofail-badge">
                {autoFail.length} Auto-fail
              </Pill>
            )}
            {autoZero.length > 0 && (
              <Pill emphasized small caution data-testid="autozero-badge">
                {autoZero.length} Auto-zero
              </Pill>
            )}
            {!sectionData && measureLength.length === 0 && <div>No measures in use</div>}
          </div>
        </Card.Description>
      </Card.Content>

      <Card.Content onClick={() => onClick(cardData)}>
        <Card.Header
          className="flex-space-between"
          style={{ fontWeight: '600', fontSize: '1.1rem' }}
        >
          <span className={activeClass} data-testid="scorecard-section-active">
            {cardData.active ? 'Active' : 'Inactive'}
          </span>
          <span data-testid="scorecard-section-weight">
            {cardData.weight ? `${cardData.weight}%` : ''}
          </span>
        </Card.Header>
      </Card.Content>
    </Card>
  )
}
