import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import classNames from 'classnames'

import { DragAndDropButton } from './DragAndDropButton'

// To enable nested drag and drop, the `outer` property has been added, which will adjust the margins
// and padding accordingly, and ensure the correct icons are hidden/displayed on hover.
export const Drag = ({
  draggableId,
  index,
  alwaysShowIcon,
  outer,
  nestedEntriesCount,
  readOnly,
  isModal,
  isHidden,
  showDragStyle,
  reverse,
  dataTestId,
  ...props
}) => {
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => {
        if (isModal && snapshot.isDragging) {
          provided.draggableProps.style = {
            ...provided.draggableProps.style,
            left: provided.draggableProps.style.offsetLeft,
            top: provided.draggableProps.style.offsetTop,
          }
        }

        return (
          <div ref={provided.innerRef} {...provided.draggableProps} data-testid="draggable-entry">
            <div
              data-testid={dataTestId}
              className={classNames({
                'outer-draggable-item': outer,
                'draggable-item': !outer,
                'hidden-button-wrapper': !alwaysShowIcon && !outer,
                'hidden-button-wrapper-outer': !alwaysShowIcon && outer,
                'drag-style': showDragStyle && snapshot.isDragging,
                reverse,
              })}
            >
              <DragAndDropButton
                snapshot={snapshot}
                provided={provided}
                outer={outer}
                readOnly={readOnly}
                isHidden={isHidden}
              />
              {nestedEntriesCount > 0 && snapshot.isDragging && (
                <div className="nested-items-badge">{nestedEntriesCount}</div>
              )}
              {props.children}
            </div>
          </div>
        )
      }}
    </Draggable>
  )
}
