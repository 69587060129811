import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, Popup, Radio } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { IconPencil, IconTrash, IconCopy } from '@tabler/icons-react'

import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { getOrganizationOptions, formatTime } from '@/utils/helpers'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { FormModal } from '@/components/layout/modals/FormModal'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { truncatedColumn } from '@/components/helpers/tableColumnHelpers'
import {
  copyScorecard,
  deleteScorecard,
  updateScorecardSettings,
  toggleScorecardActive,
} from '@/reducers/scorecards/scorecards.actions'
import { closeModal } from '@/reducers/ui/ui.redux'

import { formatScorecardType, scorecardHasCriteria } from './helpers/index'

import './scorecards.css'

export const ScorecardsTable = ({ isCopilot = false }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { data, loading } = useSelector((state) => state.scorecards)
  const { organizationid: currentUserOrgId } = useSelector((state) => state.currentUser)
  const { organizations } = useSelector((state) => state)

  const [openNoCriteriaModal, setOpenNoCriteriaModal] = useState(false)
  const [openActivateModal, setOpenActivateModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [targetConfig, setTargetConfig] = useState({})
  const isBaltoAdmin = currentUserOrgId === 1

  const redirectToEdit = (e, row) => {
    const url =
      row.type === 'QA Copilot' ? `qa-copilot/${row.id}` : `scorecards/edit_config/${row.id}`
    history.push(url)
  }

  const handleCloseActivateModal = () => {
    setTargetConfig({})
    setOpenActivateModal(false)
  }

  const handleCloseNoCriteriaModal = () => {
    setTargetConfig({})
    setOpenNoCriteriaModal(false)
  }

  const handleCloseDeletionModal = () => {
    setTargetConfig({})
    setOpenDeleteModal(false)
  }

  const softDelete = () => {
    dispatch(deleteScorecard(targetConfig.id, isCopilot))
    handleCloseDeletionModal()
  }

  const openActiveModal = (scorecardConfig) => {
    const hasCriteria = scorecardHasCriteria(scorecardConfig)
    if (hasCriteria) {
      setTargetConfig(scorecardConfig)
      setOpenActivateModal(true)
    } else {
      setTargetConfig(scorecardConfig)
      setOpenNoCriteriaModal(true)
    }
  }
  const setAgentViewable = (scorecardConfig) => {
    dispatch(
      updateScorecardSettings(
        scorecardConfig,
        { agent_viewable: !scorecardConfig.agent_viewable },
        isCopilot
      )
    )
  }

  const openDeletionModal = (e, row) => {
    setTargetConfig(row)
    setOpenDeleteModal(true)
  }

  const toggleActiveStatus = () => {
    dispatch(toggleScorecardActive(targetConfig))
    handleCloseActivateModal()
  }

  const createScorecardCopy = async (formValues, row) => {
    if (!formValues.organization_id) {
      return
    }

    const scorecardConfig = data.scorecards.find((scorecard) => scorecard?.sid === row?.sid)
    const existingCopies = data.scorecards.filter((scorecard) =>
      scorecard.name.includes(`COPY OF ${row.name.value}`)
    )

    dispatch(
      copyScorecard(scorecardConfig, existingCopies.length, isCopilot, formValues.organization_id)
    )
    dispatch(closeModal())
  }

  let columns = [
    {
      accessor: 'name',
      label: 'Scorecard Name',
      isSearchable: true,
    },
    {
      accessor: 'active',
      label: 'Active',
    },
    {
      accessor: 'agent_viewable',
      label: 'Visible to Agents',
    },
  ]

  if (!isCopilot) {
    columns.push({
      accessor: 'config_name',
      label: 'Playbook',
      isSearchable: true,
      format: (playbook) => truncatedColumn(playbook),
    })
  }

  columns = columns.concat([
    {
      accessor: 'created_name',
      label: 'Created By',
    },
    {
      accessor: 'created_on',
      label: 'Created On',
      format: (value) => formatTime(value),
    },
    {
      accessor: 'modified_on',
      label: 'Last Modified',
      format: (value) => formatTime(value),
    },
  ])

  const actions = [
    {
      label: 'Edit',
      fn: redirectToEdit,
      icon: <IconPencil />,
    },
    {
      label: 'Delete',
      fn: openDeletionModal,
      icon: <IconTrash />,
    },
  ]

  if (isBaltoAdmin && isCopilot) {
    actions.push({
      label: 'Clone Scorecard',
      trigger: (row) => (
        <ButtonAndFormModal
          popup
          popupProps={{ position: 'top right' }}
          icon={<IconCopy />}
          modalTitle="Clone Scorecard"
          modalId={`scorecards/clone-${row.id}`}
          modalProps={{ size: 'tiny' }}
          modalContent={
            <p>
              Clone <strong>{row.name.value}</strong> to the following organization:
            </p>
          }
          buttonProps={{ basic: true, compact: true }}
          form={
            <AbstractForm
              existingValues={{ organization_id: row.organization_id }}
              schema={[
                {
                  name: 'organization_id',
                  type: 'select',
                  label: 'Organization',
                  options: getOrganizationOptions(organizations),
                  required: true,
                },
              ]}
              onSubmit={(formValues) => createScorecardCopy(formValues, row)}
              buttonLabel="Save"
              onClose={() => dispatch(closeModal())}
              isModal
            />
          }
        />
      ),
    })
  }

  const rows = data.scorecards.map((scorecard) => ({
    ...scorecard,
    index: scorecard.id,
    active: {
      as: (
        <Radio
          data-testid="scorecard-active-toggle"
          toggle
          checked={scorecard.active}
          onChange={() => openActiveModal(scorecard)}
        />
      ),
      value: scorecard.active,
    },
    agent_viewable: {
      as: (
        <Radio
          data-testid="scorecard-agent-viewable-toggle"
          toggle
          checked={scorecard.agent_viewable}
          onChange={() => setAgentViewable(scorecard)}
        />
      ),
      value: scorecard.agent_viewable,
    },
    name: {
      as: (
        <div className="editor-page-warning">
          {!scorecard.playbook_up_to_date && (
            <Popup
              inverted
              content="This scorecard is out of date with its playbook. You can update it in edit mode."
              trigger={
                <Icon
                  color="yellow"
                  name="warning sign"
                  data-testid="playbook-not-up-to-date-warning"
                />
              }
            />
          )}
          <Link
            to={
              scorecard.type === 'copilot'
                ? `qa-copilot/${scorecard.id}`
                : `scorecards/edit_config/${scorecard.id}`
            }
            className="table-link"
          >
            {' '}
            {scorecard.name}
          </Link>
        </div>
      ),
      value: scorecard.name,
    },
    created_name: `${scorecard?.created_user?.first_name || ''} ${
      scorecard?.created_user?.last_name || ''
    }`,
    type: formatScorecardType(scorecard.type),
  }))

  return (
    <div data-testid="scorecards-table">
      <AdvancedTable
        loading={loading.scorecards}
        index="index"
        rows={rows}
        columns={columns}
        actions={actions}
        defaultOrderBy="modified_on"
        defaultOrder="desc"
        pagination
        stickyAction
      />

      {openDeleteModal && (
        <FormModal
          title={`Delete ${targetConfig.name.value}`}
          onSave={softDelete}
          onClose={handleCloseDeletionModal}
          closeButtonLabel="Cancel"
          submitButtonLabel="Delete"
          show={openDeleteModal}
          isLoading={loading.scorecards}
          size="tiny"
          critical
        >
          <div
            data-testid="confirm-delete-modal"
            className="scorecard-editor__active-modal-content"
          >
            {`Are you sure you want to delete "${targetConfig.name.value}"?`}
          </div>
        </FormModal>
      )}

      {openActivateModal && (
        <FormModal
          title={`${targetConfig.active ? 'Deactivate' : 'Activate'} Scorecard Configuration`}
          onSave={toggleActiveStatus}
          onClose={handleCloseActivateModal}
          closeButtonLabel="Cancel"
          submitButtonLabel={`${targetConfig.active ? 'Deactivate' : 'Activate'}`}
          show={openActivateModal}
          isLoading={loading.scorecards}
        >
          <div
            data-testid="confirm-active-modal"
            className="scorecard-editor__active-modal-content"
          >
            {`Are you sure you want to ${
              targetConfig.active ? 'deactivate' : 'activate'
            } this scorecard configuration?
              If you do, it will ${targetConfig.active ? 'not' : ''} be used to score calls.`}
          </div>
        </FormModal>
      )}

      {openNoCriteriaModal && (
        <BasicModal
          title="Configure Scorecard"
          onClose={handleCloseNoCriteriaModal}
          show={openNoCriteriaModal}
          data-testid="no-criteria-modal"
        >
          <p>This scorecard cannot become active until one or more measures are in use.</p>

          <p>Please select the criteria you would like Balto to score calls with.</p>
        </BasicModal>
      )}
    </div>
  )
}
