import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'

import { multiSelectStyles, selectTheme } from '../helpers/selectHelpers'

const createOption = (label) => ({ label, value: label })

export const CreatableInput = ({
  loading,
  options,
  fixedWidth,
  allowFreeText,
  isNumberField,
  isClearable = true,
  nameKey,
  handleUpdateFilters,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('')

  const handleKeyDown = (event) => {
    if (!inputValue) return

    if (event.key === 'Enter' || event.key === 'Tab' || event.key === ',') {
      const currentValues = props.value || []
      handleUpdateFilters(nameKey, [...currentValues, createOption(inputValue.trim())])
      setInputValue('')
      event.preventDefault()
    }
  }

  const handleBlur = (event) => {
    if (event.target?.value) {
      const currentValues = props.value || []
      handleUpdateFilters(nameKey, [...currentValues, createOption(inputValue.trim())])
      setInputValue('')

      event.preventDefault()
    }
  }

  const clearKeyword = (keyword) => {
    const currentValues = props.value || []

    handleUpdateFilters(
      nameKey,
      currentValues.filter((option) => option.value !== keyword)
    )
  }

  const clearAllKeywords = () => {
    handleUpdateFilters(nameKey, [])
  }

  return (
    <CreatableSelect
      components={{ DropdownIndicator: null }}
      inputValue={inputValue}
      isClearable={isClearable}
      isMulti
      menuIsOpen={false}
      onChange={(option, action) => {
        if (action?.action === 'clear') {
          clearAllKeywords()
        } else if (action?.action === 'remove-value') {
          clearKeyword(action.removedValue?.value)
        }
      }}
      onBlur={handleBlur}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      styles={multiSelectStyles(null, null, true)}
      theme={(theme) => selectTheme(theme)}
      {...props}
    />
  )
}
