import React, { useState } from 'react'

import { CustomRightAxis } from '@/components/charts/customCharts/CustomRightAxis'
import { CustomLegend } from '@/components/charts/customCharts/CustomLegend'
import { chartColors } from '@/utils/constants'
import { formatPercent } from '@/utils/helpers'
import BarChart from '@/components/charts/BarChart'
import { secondaryChart } from '@/components/charts/customCharts/helpers'

const colors = {
  bars: '#B4C0FF',
  lines: '#4D63FE', // blurple-500
  legends: {
    text: '#777', // default nivo legend color
  },
}

export const BarChartWithLayers = ({
  barData,
  secondaryChartData,
  secondaryChartDataKey,
  secondaryChartType,
  tooltipDescription = '',
  withCustomAxis = false,
  withCustomLegend = false,
  keys,
  indexBy,
  isPercent,
  layout = 'vertical',
  ...props
}) => {
  const tooltipId = `${tooltipDescription || 'no'}-tooltip`

  const [customTooltipVisible, setCustomTooltipVisible] = useState(false)
  const showDefaultTooltip = !customTooltipVisible

  const toggleTooltip = (value) => {
    if (tooltipDescription) {
      setCustomTooltipVisible(value)
    }
  }

  return (
    <>
      <BarChart
        data={barData}
        keys={keys}
        indexBy={indexBy}
        padding={0.3}
        margin={{ top: 50, right: 50, bottom: 50, left: 70 }}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        valueFormat={{ format: '', enabled: false }}
        colors={chartColors}
        colorBy="index"
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisLeft={null}
        axisRight={null}
        axisTop={null}
        labelFormat={(v) => (isPercent ? formatPercent(v) : Number(v).toLocaleString())}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="white"
        layout={layout}
        enableLabel={false}
        enableGridX={false}
        enableGridY={false}
        motionConfig="stiff"
        animate={false}
        isInteractive={showDefaultTooltip}
        legends={[
          {
            anchor: 'top-left',
            direction: 'row',
            justify: false,
            translateX: -40,
            translateY: -30,
            itemWidth: 100,
            itemHeight: 10,
            itemsSpacing: 4,
            symbolSize: 9,
            symbolShape: 'circle',
            itemDirection: 'left-to-right',
            itemTextColor: colors.legends.text,
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        layers={[
          'grid',
          (canvasContext, nivoProps) =>
            withCustomAxis &&
            CustomRightAxis(canvasContext, nivoProps, {
              dataPoints: secondaryChartData,
              dataPointKey: 'y',
            }),
          'axes',
          'bars',
          'markers',
          'legends',
          (canvasContext, nivoProps) =>
            withCustomLegend &&
            CustomLegend(canvasContext, nivoProps, {
              anchor: 'top-right',
              direction: 'row',
              legendItems: [
                {
                  color: colors.lines,
                  hidden: false,
                  id: 'Total Calls',
                  label: 'Total Calls',
                },
              ],
            }),
          (canvasContext, nivoProps) =>
            secondaryChart(secondaryChartType, canvasContext, nivoProps, {
              dataPoints: secondaryChartData,
              dataPointKey: secondaryChartDataKey,
              color: colors.lines,
              tooltipDescription,
              tooltipId,
              toggleTooltip,
            }),
        ]}
        {...props}
      />
      <div id={tooltipId} data-testid="custom-tooltip-div" />
    </>
  )
}
