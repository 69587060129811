import React from 'react'

import { BaltoLogo } from '../../components/BaltoLogo'

import './AuthContainer.scss'

export const AuthContainer = ({ children }) => {
  return (
    <div className="balto-wrapper" data-testid="auth-container">
      <div className="vertical-wrapper">
        <div className="auth-container">
          <BaltoLogo height={80} />
          {children}
        </div>
      </div>
    </div>
  )
}
