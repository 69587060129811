import React from 'react'
import { Button, Accordion } from 'semantic-ui-react'
import { IconChevronDown, IconBulb } from '@tabler/icons-react'

export const EntryInsight = ({
  insightDetails = [],
  fieldName,
  insightAction,
  handleDeleteListItem,
  isAdmin,
}) => {
  const entryInsights = fieldName && (
    <div className="entry-insight">
      {insightDetails
        .filter((insight) => insight.item === fieldName.name)
        .map(
          ({ hit_fraction, win_fraction, drop_performance, drop_count }) =>
            (drop_performance && (
              <Accordion
                key={1}
                defaultActiveIndex={1}
                panels={[
                  {
                    key: 'KPI1',
                    title: {
                      content: (
                        <div className="flex-align-center small-gap">
                          <IconBulb />
                          <span>
                            This decklist item has poor performance. Consider
                            <Button
                              className="inline-link-button"
                              type="submit"
                              onClick={(event) => {
                                event.preventDefault()
                                insightAction('accept', 'drop_performance')
                                if (handleDeleteListItem) {
                                  handleDeleteListItem()
                                }
                              }}
                            >
                              removing it.
                            </Button>
                          </span>
                          {isAdmin && <IconChevronDown className="chevron-icon" />}
                          <Button
                            className="dismiss-insight"
                            onClick={() => insightAction('dismiss', 'drop_performance')}
                          >
                            Dismiss
                          </Button>
                        </div>
                      ),
                      icon: false,
                    },
                    content: isAdmin
                      ? {
                          content: (
                            <span style={{ marginLeft: '25px' }}>
                              {`This item's win percentage is ${(win_fraction * 100).toFixed(2)}%.`}
                            </span>
                          ),
                        }
                      : {},
                  },
                ]}
              />
            )) ||
            (drop_count && (
              <Accordion
                key={2}
                defaultActiveIndex={1}
                panels={[
                  {
                    key: 'KPI2',
                    title: {
                      content: (
                        <div className="flex-align-center small-gap">
                          <IconBulb />
                          <span>
                            This decklist item is infrequently used. Consider
                            <Button
                              className="inline-link-button"
                              type="submit"
                              onClick={(event) => {
                                event.preventDefault()
                                insightAction('accept', 'drop_count')
                                if (handleDeleteListItem) {
                                  handleDeleteListItem()
                                }
                              }}
                            >
                              removing it.
                            </Button>
                          </span>
                          {isAdmin && <IconChevronDown className="chevron-icon" />}
                          <Button
                            className="dismiss-insight"
                            onClick={() => insightAction('dismiss', 'drop_count')}
                          >
                            Dismiss
                          </Button>
                        </div>
                      ),
                      icon: false,
                    },
                    content: {
                      content: isAdmin && (
                        <span style={{ marginLeft: '25px' }}>
                          {`This item's use percentage is ${(hit_fraction * 100).toFixed(2)}%.`}
                        </span>
                      ),
                    },
                  },
                ]}
              />
            ))
        )}
    </div>
  )

  return <>{entryInsights}</>
}
