import React, { useRef } from 'react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Button } from 'semantic-ui-react'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { closeModal } from '@/reducers/ui/ui.redux'
import { updateSettings } from '@/reducers/qa-copilot/qa-copilot.redux'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'

import { deleteScorecardConfig } from '@/reducers/qa-copilot/qa-copilot.actions'
import { addPrompt, setPrompts } from '@/reducers/prompts/prompts.redux'
import { updatePromptHelper } from '@/reducers/prompts/prompts.helpers'
import { QACopilotSettingsForm } from '../forms/QACopilotSettingsForm'
import { settingsValidationSchema } from '../QACopilot.helpers'

export const QACopilotSettingsModal = ({ isCreate }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { organizationid: organizationId, prompt_editor: promptEditor } = useSelector(
    (state) => state.currentUser
  )
  const { currentlyOpenModalId } = useSelector((state) => state.ui)
  const { scorecardConfig, data } = useSelector((state) => state.qaCopilot)
  const { prompts } = useSelector((state) => state.prompts)
  const eligibilityPrompt = prompts.find(
    (prompt) =>
      (scorecardConfig?.eligibility_prompt_id &&
        prompt.id === scorecardConfig?.eligibility_prompt_id) ||
      prompt.scid === 'eligibility'
  )
  const modalId = `qaCopilot/settings`
  const isBaltoAdmin = organizationId === 1
  const isPromptEditor = isBaltoAdmin || promptEditor
  const formikDirtyRef = useRef(false)

  if (isEmpty(scorecardConfig?.sections)) {
    return null
  }

  if (modalId !== currentlyOpenModalId) {
    return null
  }

  const selectedPlaybooks = scorecardConfig.config_cids?.map((playbookCid) => ({
    value: playbookCid,
    label: data.playbooks.find((playbook) => playbook.value === playbookCid)?.label,
  }))

  const selectedAgents = scorecardConfig.users?.map((user) => ({
    value: user.id,
    label: data.agents.find((agent) => agent.value === user.id)?.label,
  }))

  const selectedTags = scorecardConfig.tags?.map((tag) => ({
    value: tag.id,
    label: data.tags
      .flatMap((tagCat) => tagCat.options)
      .find((tagCatTag) => tagCatTag.value === tag.id)?.label, // :(
  }))

  const settingsInitialValues = {
    organization_id: scorecardConfig?.organization_id || '',
    improvement_threshold: scorecardConfig?.improvement_threshold || 70,
    meets_threshold: scorecardConfig?.meets_threshold || 80,
    exceeds_threshold: scorecardConfig?.exceeds_threshold || 90,
    required_score_count: scorecardConfig?.required_score_count || 0,
    scorecard_frequency: scorecardConfig?.scorecard_frequency || 'monthly',
    min_duration_in_minutes: Math.round(scorecardConfig?.min_duration_in_seconds) / 60 || 1,
    shell_prompt: scorecardConfig?.shell_prompt || '',
    eligibility_prompt_id: scorecardConfig?.eligibility_prompt_id,
    should_eligibility_respond_yes:
      scorecardConfig?.should_eligibility_respond_yes?.toString() || false,
    user_prompt: eligibilityPrompt?.user_prompt || '',
    first_x_minutes: eligibilityPrompt?.first_x_minutes || '',
    last_x_minutes: eligibilityPrompt?.last_x_minutes || '',
    side: eligibilityPrompt?.side || 'both',
    model: eligibilityPrompt?.model || 'gpt-4o',
    prompt_id: eligibilityPrompt?.id || null,
    system_prompt: eligibilityPrompt?.system_prompt || '',
    max_response_tokens: eligibilityPrompt?.max_response_tokens || null,
    call_id: '',
    expected_response: '',
    users: selectedAgents || [],
    users_inclusive: scorecardConfig?.users_inclusive?.toString() || 'true',
    tags: selectedTags || [],
    tags_inclusive: scorecardConfig?.tags_inclusive?.toString() || 'true',
    playbooks: selectedPlaybooks || [],
    multicall: scorecardConfig?.multicall || false,
  }

  const handleCloseModal = () => {
    if (formikDirtyRef.current) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to leave without saving?')) {
        dispatch(closeModal())
      }
    } else {
      dispatch(closeModal())
    }
  }

  const handleDeleteScorecardConfig = () => {
    dispatch(deleteScorecardConfig(scorecardConfig, history))
  }

  const handleSaveSettings = (values) => {
    if (isPromptEditor && values.user_prompt !== '') {
      const newPrompt = {
        ...eligibilityPrompt,
        user_prompt: values.user_prompt || '',
        first_x_minutes: values.first_x_minutes || null,
        last_x_minutes: values.last_x_minutes || null,
        side: values.side,
        model: values.model,
        feature: 'qa_copilot_eligibility', // prompt API requires a feature
        scid: 'eligibility', // this is so we can save this prompt with the rest of them
      }
      if (eligibilityPrompt?.id !== null) {
        const updatedPrompts = updatePromptHelper(prompts, newPrompt)
        dispatch(setPrompts(updatedPrompts))
      } else {
        dispatch(addPrompt(newPrompt))
      }
    }
    // Clear eligibility prompt if we remove the user prompt
    const emptyEligibilityPrompt = isBaltoAdmin && values.user_prompt === ''

    dispatch(
      updateSettings({
        ...values,
        organization_id: values.organization_id,
        users: !isEmpty(values.users) ? values.users.map((user) => ({ id: user.value })) : [],
        users_inclusive: values.users_inclusive === 'true' || false,
        tags: !isEmpty(values.tags) ? values.tags.map((tag) => ({ id: tag.value })) : [],
        tags_inclusive: values.tags_inclusive === 'true' || false,
        config_cids: !isEmpty(values.playbooks)
          ? values.playbooks.map((playbookCid) => playbookCid.value)
          : [],
        required_score_count: values.required_score_count || 0,
        min_duration_in_seconds: Math.round(values.min_duration_in_minutes) * 60 || 1,
        should_eligibility_respond_yes: values.should_eligibility_respond_yes === 'true',
        eligibility_prompt_id: emptyEligibilityPrompt ? null : values.eligibility_prompt_id,
        multicall: values.multicall,
      })
    )
    dispatch(closeModal())
  }

  return (
    <Formik
      initialValues={settingsInitialValues}
      validationSchema={settingsValidationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={handleSaveSettings}
    >
      {({ values, errors, handleSubmit, dirty, setFieldValue }) => {
        formikDirtyRef.current = dirty

        return (
          <BasicModal
            className="qa-copilot-settings-modal"
            title="General Settings"
            onClose={handleCloseModal}
            show={currentlyOpenModalId === modalId}
            size="small"
            scrolling
            footer={
              <div className="flex-align-center flex-end small-gap">
                <Button
                  type="button"
                  secondary
                  onClick={handleCloseModal}
                  data-testid="close-modal"
                >
                  Discard
                </Button>
                <ConditionalTooltip
                  condition={!isEmpty(errors)}
                  content="Must fix errors to submit"
                >
                  <Button
                    type="button"
                    primary
                    onClick={handleSubmit}
                    disabled={!isEmpty(errors)}
                    data-testid="qa-copilot-settings-submit-button"
                  >
                    Update
                  </Button>
                </ConditionalTooltip>
              </div>
            }
          >
            <QACopilotSettingsForm
              values={values}
              errors={errors}
              handleSubmit={handleSubmit}
              handleDeleteScorecardConfig={handleDeleteScorecardConfig}
              handleCloseModal={handleCloseModal}
              isBaltoAdmin={isBaltoAdmin}
              isPromptEditor={isPromptEditor}
              isCreate={isCreate}
              setFieldValue={setFieldValue}
            />
          </BasicModal>
        )
      }}
    </Formik>
  )
}
