import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchCurrentUserPermissions } from '@/reducers/auth/currentUser.actions'

// on refresh, if the user has a token in localStorage, we hit the BE to get up to date permissions
const UserPermissionsProvider = () => {
  const dispatch = useDispatch()
  const { user_id, organizationid, own_organization_id } = useSelector((state) => state.currentUser)
  useEffect(() => {
    if (user_id) {
      dispatch(fetchCurrentUserPermissions(user_id, own_organization_id || organizationid))
    }
  }, [user_id])

  return null
}

export default UserPermissionsProvider
