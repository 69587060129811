import React from 'react'
import { useDispatch } from 'react-redux'

import { rejectTascRecommendation } from '@/reducers/recommendations/recommendations.actions'
import { FormModal } from '@/components/layout/modals/FormModal'

const TascRejectRecommendationModal = ({ showModal, setShowModal, selectedRecommendation }) => {
  const dispatch = useDispatch()

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSubmit = () => {
    dispatch(
      rejectTascRecommendation({
        recommendationId: selectedRecommendation.id,
      })
    )
    setShowModal(false)
  }

  return (
    <FormModal
      show={showModal}
      onClose={handleCloseModal}
      onSave={handleSubmit}
      title="Reject Recommendation"
      size="small"
      closeButtonLabel="Cancel"
      submitButtonLabel="Reject"
      description="Are you sure you want to reject this recommendation? It will no longer show up on this table."
      critical
    />
  )
}

export default TascRejectRecommendationModal
