import React, { useState } from 'react'
import { Button, Input } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { IconPlus, IconX, IconCheck } from '@tabler/icons-react'
import { editScorecardConfig } from '@/reducers/qa-copilot/qa-copilot.redux'

export const QACopilotSidebarInlineEntry = ({ label }) => {
  const dispatch = useDispatch()
  const { scorecardConfig } = useSelector((state) => state.qaCopilot)
  const [inlineEntry, setInlineEntry] = useState({ editing: false, value: '' })

  const handleCreateInlineEntry = () => {
    setInlineEntry({ editing: true, value: '' })
  }

  const handleCancelInlineEntry = () => {
    setInlineEntry({ editing: false, value: '' })
  }

  const handleUpdateInlineEntry = (event) => {
    setInlineEntry({ editing: true, value: event.target.value })
  }

  const handleSubmitInlineEntry = (event) => {
    event.preventDefault()

    const updatedScorecardConfig = {
      ...scorecardConfig,
      sections: [
        ...scorecardConfig.sections,
        {
          ssid: uuid(),
          name: inlineEntry.value,
          measures: [{ name: `${inlineEntry.value} Measure`, criteria: [] }],
          order_id: scorecardConfig.sections.length,
        },
      ],
    }

    dispatch(editScorecardConfig(updatedScorecardConfig))

    setInlineEntry({ editing: false, value: '' })
  }

  return (
    <div className="qa-copilot-sidebar__inline-entry">
      {!inlineEntry.editing ? (
        <Button
          icon
          primary
          className="minimal-button flex small-gap svg-button"
          onClick={handleCreateInlineEntry}
          style={{ marginLeft: '-1rem' }}
        >
          <IconPlus />
          <span className="muted-text">{label}</span>
        </Button>
      ) : (
        <form className="vertical-center small-gap" onSubmit={handleSubmitInlineEntry}>
          <Input
            autoFocus
            type="text"
            value={inlineEntry.value}
            onChange={handleUpdateInlineEntry}
            className="w-100"
            placeholder="Enter a name"
            style={{ maxWidth: '160px' }}
          />
          <div className="vertical-center small-gap">
            <Button
              type="button"
              secondary
              onClick={handleCancelInlineEntry}
              icon
              className="margin-0 svg-button"
            >
              <IconX />
            </Button>
            <Button
              type="submit"
              primary
              disabled={!inlineEntry.value}
              icon
              className="margin-0 svg-button"
            >
              <IconCheck />
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}
