import React from 'react'

import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'

import { CustomizeBrandingForm } from '../CustomizeBrandingForm'

export const CustomizeBrandingButton = ({ organizationId }) => {
  return (
    <div style={{ margin: '.5rem' }}>
      <ButtonAndFormModal
        buttonLabel="Customize"
        modalTitle="Customize Branding"
        modalId="customize-branding"
        buttonProps={{ primary: true }}
        form={<CustomizeBrandingForm organizationId={organizationId} />}
      />
    </div>
  )
}
