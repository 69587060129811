import { useRef, useCallback, useEffect, useLayoutEffect } from 'react'
import { toLower } from 'lodash'

// https://www.epicreact.dev/the-latest-ref-pattern-in-react
export const useShortcut = (value, callback) => {
  const callbackRef = useRef(callback)

  useLayoutEffect(() => {
    callbackRef.current = callback
  })

  const handleKeyPress = useCallback(
    (event) => {
      // Do not trigger shortcut if the user is typing in a text area or input
      if (
        event.target instanceof HTMLTextAreaElement ||
        (event.target instanceof HTMLInputElement &&
          (!event.target.type || event.target.type === 'text'))
      ) {
        return event.stopPropagation()
      }

      // Trigger shortcut if the key pressed matches the value
      if (toLower(event.key) === toLower(value)) {
        callbackRef.current(event)
      }

      return null
    },
    [value]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])
}
