import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, TextArea, Form } from 'semantic-ui-react'
import moment from 'moment'
import { IconNote, IconPlus, IconTrash, IconPencil } from '@tabler/icons-react'

import { BasicModal } from '@/components/layout/modals/BasicModal'
import ConfirmForm from '@/components/forms/ConfirmForm'
import { closeModal, openModal } from '@/reducers/ui/ui.redux'

import './QANote.scss'

export const QANote = ({ handleSaveNote, handleDeleteNote, scorecard }) => {
  const dispatch = useDispatch()
  const [showNoteCreator, setShowNoteCreator] = useState(false)
  const noteExists = scorecard?.note?.id && scorecard?.note?.deleted === false
  const [showNoteExistsView, setShowNoteExistsView] = useState(noteExists)
  const [noteText, setNoteText] = useState(scorecard?.note?.note_text || '')
  const noteDate = scorecard?.note?.modified_time
  const { currentlyOpenModalId } = useSelector((state) => state.ui)
  const deleteModalId = `note/delete/${scorecard.id}`

  const getNoteDate = () => {
    const date = moment.utc(noteDate).local()

    return date.fromNow()
  }

  const handleAddNoteClick = () => {
    setShowNoteCreator(true)
  }

  const handleDiscardClick = () => {
    setShowNoteCreator(false)

    if (noteExists) {
      setShowNoteExistsView(true)
    }
  }

  const handleDeleteClick = (scorecardId) => {
    handleDeleteNote(scorecardId)
    setShowNoteExistsView(false)
    setNoteText('')
  }

  const handleSaveClick = () => {
    handleSaveNote(scorecard.id, noteText)
    setShowNoteCreator(false)
  }

  const toggleEditMode = () => {
    setShowNoteExistsView(false)
    setShowNoteCreator(true)
  }

  const handleCloseModal = () => dispatch(closeModal())
  const handleOpenDeleteModal = () => dispatch(openModal(deleteModalId))

  return (
    <div>
      {!noteExists && !showNoteCreator && (
        <div className="new-note-container">
          <div className="flex-align-center small-gap">
            <IconNote />
            <span>Private Note</span>
          </div>
          <Button
            compact
            icon
            secondary
            className="svg-button"
            data-testid="add-note-button"
            onClick={handleAddNoteClick}
          >
            <IconPlus />
          </Button>
        </div>
      )}

      {showNoteCreator && (
        <div className="note-container">
          <Form style={{ marginBottom: '1rem' }}>
            <div className="flex-align-center small-gap mb">
              <IconNote />
              <span>Private Note</span>
            </div>

            <TextArea
              data-testid="note-input"
              placeholder="Share your feedback..."
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
            />
          </Form>
          <div className="flex-end small-gap">
            <Button data-testid="discard-button" secondary onClick={handleDiscardClick}>
              Dismiss
            </Button>
            <Button data-testid="save-button" primary onClick={handleSaveClick}>
              Save Note
            </Button>
          </div>
        </div>
      )}

      {showNoteExistsView && (
        <div className="note-container">
          <div className="flex-align-center small-gap">
            <IconNote />
            <span>Private Note</span>
          </div>

          <div className="qa-note-box">
            <div className="note-header">
              <div className="note-last-edited">{`Last edited ${getNoteDate()}`}</div>
              <div className="flex small-gap">
                <Button
                  data-testid="edit-button"
                  secondary
                  icon
                  className="svg-button"
                  compact
                  onClick={toggleEditMode}
                >
                  <IconPencil />
                </Button>
                <Button
                  data-testid="delete-button"
                  className="svg-button"
                  secondary
                  icon
                  compact
                  onClick={handleOpenDeleteModal}
                >
                  <IconTrash />
                </Button>
              </div>
            </div>
            <div className="note-body">{noteText}</div>
          </div>
        </div>
      )}

      {currentlyOpenModalId === deleteModalId && (
        <BasicModal
          data-testid="delete-note-modal"
          title="Delete Note"
          onClose={handleCloseModal}
          show={currentlyOpenModalId === deleteModalId}
          size="tiny"
        >
          <ConfirmForm
            valueToSubmit={scorecard.id}
            bodyText="Are you sure you want to delete this Note?"
            danger
            primaryButtonText="Delete Note"
            handleSubmit={() => {
              handleDeleteClick(scorecard.id)
            }}
            modalClose={handleCloseModal}
          />
        </BasicModal>
      )}
    </div>
  )
}
