import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { useRouteMatch } from 'react-router-dom'

import { setChatVisible } from '@/reducers/baltoGPT/baltoGPT.redux'

import { BaltoGPTChat } from './BaltoGPTChat'
import { BaltoGPTButton } from './BaltoGPTButton'

export const BaltoGPT = () => {
  const dispatch = useDispatch()
  const { chatVisible } = useSelector((state) => state.baltoGPT)
  const { calls, callExplorer, filters } = useSelector((state) => state.callSearch)
  const currentUser = useSelector((state) => state.currentUser)
  const currentUserOrganization = useSelector((state) => state.currentOrganization)

  const isCallDetailPage = useRouteMatch('/call-explorer/:id')
  const isCallExplorerPage = useRouteMatch('/call-explorer')

  const isBaltoAdmin = currentUser.organizationid === 1
  const isHierarchyManager = !isBaltoAdmin && currentUser.hierarchy_manager

  // Balto Users have permissions to see BaltoGPT for all orgs.
  // Hierarchy managers can only see BaltoGPT for their org since they don't currently have BE permissions for other orgs.
  // One issue is that Balto Users will see BaltoGPT for all orgs, including orgs that don't have it enabled
  // TODO: Fetch organization properties of the filtered orgs and check if call_summarization_enabled for both Balto and Hierarchy
  // TODO: Allow Hierarchy Managers to use GPT for their sub orgs in the BE
  const hasCallSummarizationPermissions = isHierarchyManager
    ? filters.organizationId === currentUser.organizationid &&
      currentUserOrganization.call_summarization_enabled
    : currentUserOrganization.call_summarization_enabled
  const shouldShowForCallExplorer =
    isCallExplorerPage && !isEmpty(calls) && hasCallSummarizationPermissions
  const shouldShowForCallDetails = isCallDetailPage && !!callExplorer?.transcript
  const shouldShowBaltoGPT = shouldShowForCallDetails || shouldShowForCallExplorer

  const handleToggleChatVisible = () => {
    dispatch(setChatVisible(!chatVisible))
  }

  if (!shouldShowBaltoGPT) {
    return null
  }

  return (
    <div id="balto-gpt" data-testid="balto-gpt-wrapper">
      {chatVisible && (
        <BaltoGPTChat
          handleToggleChatVisible={handleToggleChatVisible}
          isCallDetailPage={shouldShowForCallDetails}
        />
      )}
      <BaltoGPTButton handleToggleChatVisible={handleToggleChatVisible} />
    </div>
  )
}
