import React from 'react'
import uuid from 'uuid/v4'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Button, Dimmer, Loader } from 'semantic-ui-react'
import { orderBy } from 'lodash'
import { IconCheck, IconX, IconAlertTriangle } from '@tabler/icons-react'

import { fetchOrganizationUsersAndTags } from '@/reducers/organizations/organization.actions'

import { SortButton } from '../../SortButton' // TODO: Convert table to AdvancedTable and remove need to sort manually here

// TODO: Convert to functional component
class Receipt extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      sortBy: 0,
      asc: true,
    }

    this.sort = this.sort.bind(this)

    this.toggleLoading = () => this.setState((prevState) => ({ loading: !prevState.loading }))
    this.reloadUsersAndTags = () => {
      const { organizationid } =
        this.props.organizationid === 1 ? this.props.match.params : this.props

      this.toggleLoading()
      return this.props.fetchOrganizationUsersAndTags({
        organizationId: organizationid,
        toggleLoading: this.toggleLoading,
        modalClose: this.props.modalClose,
      })
    }
  }

  sort(header) {
    // Keeping as 'Message\r' since that's what will be in the CSV for new line
    const headerNames = ['Data Type', 'Success', 'Message\r']
    this.setState((prevState) => ({
      ...prevState,
      sortBy: headerNames.indexOf(header),
      asc: !prevState.asc,
    }))
  }

  render() {
    const { loading, sortBy, asc } = this.state
    const { uploadResponse, streamingComplete } = this.props.organizationUsersCSV

    const [headers = []] = uploadResponse
    const responseData = orderBy(uploadResponse.slice(1), sortBy, asc ? 'asc' : 'desc')

    return (
      <Dimmer.Dimmable blurring active="true">
        <div>
          <table className="ui celled table">
            <thead>
              <tr>
                {headers.map((header) => (
                  <th key={uuid()}>
                    <SortButton
                      handleClick={() => this.sort(header)}
                      dataType={header}
                      sortBy={['Data Type', 'Success', 'Message\r'][sortBy]}
                      asc={asc}
                    >
                      {header}
                    </SortButton>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {responseData.map((row) => (
                <tr key={uuid()}>
                  {row.map((cell) => (
                    <td key={uuid()}>
                      <div className="flex-align-center small-gap">
                        {cell === 'True' && <IconCheck className="icon-svg status-success" />}
                        {cell === 'False' && <IconX className="icon-svg status-critical" />}
                        {cell === 'Warning' && (
                          <IconAlertTriangle className="icon-svg status-warning" />
                        )}
                        <span>{cell}</span>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {streamingComplete && (
            <Button fluid color="blue" onClick={this.reloadUsersAndTags}>
              Reload Users
            </Button>
          )}
        </div>
        {loading && (
          <Dimmer active page>
            <Loader active size="massive" />
          </Dimmer>
        )}
      </Dimmer.Dimmable>
    )
  }
}

// TODO: Convert to functional component and remove bindActionCreators and connect
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchOrganizationUsersAndTags }, dispatch)

export default withRouter(
  connect(
    (state) => ({
      organizationUsersCSV: state.organizationUsersCSV,
      organizationid: state.currentUser.organizationid,
    }),
    mapDispatchToProps
  )(Receipt)
)
