import React from 'react'
import { IconAlertTriangle } from '@tabler/icons-react'
import classNames from 'classnames'

import './ErrorMessage.scss'

export const ErrorMessage = ({ content, warning, ...props }) => {
  return (
    <div className={classNames('error-message', { warning })} {...props}>
      <IconAlertTriangle />
      <span>{content}</span>
    </div>
  )
}
