// Action Types
export const SET_ACTIVE_USERNAME = 'realtime/setActiveUsername'
export const COLLAPSE_CHAT = 'realtime/collapseChat'
export const EXPAND_CHAT = 'realtime/expandChat'
export const BEGIN_CHAT = 'realtime/beginChat'
export const ADD_MESSAGE_TO_CHAT = 'realtime/addMessageToChat'
export const END_CHAT = 'realtime/endChat'

// Initial State
export const initialState = {
  collapsed: true,
  chats: {},
  activeUsername: null,
}

// Reducer
export default function realtimeChatsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_USERNAME:
      return { ...state, activeUsername: action.activeUsername }
    case COLLAPSE_CHAT:
      return { ...state, collapsed: true }
    case EXPAND_CHAT:
      return { ...state, collapsed: false }
    case BEGIN_CHAT: {
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.agentUsername]: {
            messages: [],
            alertId: action.alertId,
            agentId: action.agentId,
            managerId: action.managerId,
            agentUsername: action.agentUsername,
            agentLastName: action.agentLastName,
            agentFirstName: action.agentFirstName,
            managerUsername: action.managerUsername,
            managerFirstName: action.managerFirstName,
            managerLastName: action.managerLastName,
          },
        },
        activeUsername: action.agentUsername,
        collapsed: false,
      }
    }
    case ADD_MESSAGE_TO_CHAT: {
      // if chat has not begun yet then return state.
      if (!state.chats[action.agentUsername]) return state

      return {
        ...state,
        chats: {
          ...state.chats,
          [action.agentUsername]: {
            ...state.chats[action.agentUsername],
            messages: [
              ...state.chats[action.agentUsername].messages,
              {
                message: action.message,
                date: action.date,
                isMessageFromManager: action.isMessageFromManager,
              },
            ],
          },
        },
      }
    }
    case END_CHAT: {
      const chatItems = { ...state.chats }
      delete chatItems[action.username]
      let { activeUsername } = state
      if (activeUsername === action.username) {
        if (Object.keys(chatItems).length > 0) {
          // eslint-disable-next-line prefer-destructuring
          activeUsername = [...Object.keys(chatItems)][0]
        } else {
          activeUsername = null
        }
      }

      return { ...state, chats: chatItems, activeUsername }
    }
    default:
      return state
  }
}
