// Action Types
export const SET_INTEGRATIONS = 'integrations/setIntegrations'
export const SET_LOADING = 'integrations/setLoading'
export const SET_ACTIVATING = 'integrations/setActivating'
export const SET_MODAL = 'integrations/setModal'
export const SET_DIRTY = 'integrations/setDirty'
export const SET_TEMP_INTEGRATION = 'integrations/setTempIntegration'
export const SET_LOGS = 'integrations/setLogs'
export const SET_FILTER = 'integrations/setFilter'
export const SET_INTEGRATION = 'integrations/setIntegration'
export const SET_OUTCOME = 'integrations/setOutcome'
export const SET_OUTCOMES = 'integrations/setOutcomes'
export const SET_CATEGORIES = 'integrations/setCategories'

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setIntegrations = (payload) => ({ type: SET_INTEGRATIONS, payload })
export const setActivating = (payload) => ({ type: SET_ACTIVATING, payload })
export const setModal = (payload) => ({ type: SET_MODAL, payload })
export const setDirty = (payload) => ({ type: SET_DIRTY, payload })
export const setTempIntegration = (payload) => ({ type: SET_TEMP_INTEGRATION, payload })
export const setLogs = (payload) => ({ type: SET_LOGS, payload })
export const setFilter = (payload) => ({ type: SET_FILTER, payload })
export const setIntegration = (payload) => ({ type: SET_INTEGRATION, payload })
export const setOutcome = (payload) => ({ type: SET_OUTCOME, payload })
export const setOutcomes = (payload) => ({ type: SET_OUTCOMES, payload })
export const setCategories = (payload) => ({ type: SET_CATEGORIES, payload })

// State
export const initialState = {
  integrations: [],
  outcomes: [],
  categories: [],
  loading: false,
  activating: false,
  modal: false,
  tempIntegration: {},
  dirty: false,
  logs: {},
  filter: {
    time_range: 15, // in minutes
    date_from: new Date(Date.now() - 15 * 60000),
    date_to: new Date(),
    num_logs: 20,
  },
  integration: {},
}

// Reducers
export default function integrationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INTEGRATIONS:
      return { ...state, integrations: action.payload }
    case SET_LOADING:
      return { ...state, loading: action.payload }
    case SET_ACTIVATING:
      return { ...state, activating: action.payload }
    case SET_MODAL:
      return { ...state, modal: action.payload }
    case SET_DIRTY:
      return { ...state, dirty: action.payload }
    case SET_TEMP_INTEGRATION:
      return { ...state, tempIntegration: action.payload }
    case SET_LOGS:
      return { ...state, logs: action.payload }
    case SET_FILTER:
      return { ...state, filter: { ...state.filter, ...action.payload } }
    case SET_INTEGRATION:
      return { ...state, integration: action.payload }
    case SET_OUTCOME:
      return { ...state, outcome: action.payload }
    case SET_OUTCOMES:
      return { ...state, outcomes: action.payload }
    case SET_CATEGORIES:
      return { ...state, categories: action.payload }

    default:
      return state
  }
}
