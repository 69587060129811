import { displayError } from '@/reducers/organizations/organizationTagManager.actions'

const processUncategorizedTags = (user, tags) => {
  const userTags = []

  if (user.uncategorizedTags) {
    user.uncategorizedTags.forEach((uncategorizedTag) => {
      userTags.push(tags.find((tag) => tag.id === uncategorizedTag.value))
    })
  }

  return userTags
}

export const getUserAdjective = (userFilter) => {
  switch (userFilter) {
    case 'all':
      return 'total'
    case 'active':
      return 'active'
    default:
      return 'deleted'
  }
}

export const determineAccessForUser = (user) => {
  const mutatedUser = { ...user }

  if (mutatedUser.access) {
    mutatedUser.cloud_access = false
    mutatedUser.edit_config = false
    mutatedUser.edit_qa = false
    mutatedUser.edit_qa_copilot = false
    mutatedUser.game_admin = false
    mutatedUser.edit_users = false
    mutatedUser.edit_leaderboard = false
    mutatedUser.realtime_coaching_access = false
    mutatedUser.midcall_playbook_switching_enabled = false
    mutatedUser.hierarchy_manager = false
    mutatedUser.prompt_editor = false

    mutatedUser.access.forEach((item) => {
      switch (item.value) {
        case 'cloud_access':
          mutatedUser.cloud_access = true
          break
        case 'edit_config':
          mutatedUser.edit_config = true
          break
        case 'edit_qa':
          mutatedUser.edit_qa = true
          break
        case 'edit_qa_copilot':
          mutatedUser.edit_qa_copilot = true
          break
        case 'game_admin':
          mutatedUser.game_admin = true
          break
        case 'edit_users':
          mutatedUser.edit_users = true
          break
        case 'edit_leaderboard':
          mutatedUser.edit_leaderboard = true
          break
        case 'realtime_coaching_access':
          mutatedUser.realtime_coaching_access = true
          break
        case 'midcall_playbook_switching_enabled':
          mutatedUser.midcall_playbook_switching_enabled = true
          break
        case 'hierarchy_manager':
          mutatedUser.hierarchy_manager = true
          break
        case 'prompt_editor':
          mutatedUser.prompt_editor = true
          break
        default:
          break
      }
    })
    delete mutatedUser.access
  }

  return mutatedUser
}

export const processTags = (user, tags) => {
  let userTags = []

  for (const key in user) {
    if (Object.prototype.hasOwnProperty.call(user, key)) {
      const foundTag = tags.find((tag) => tag.id === user[key] && key !== 'id')

      if (foundTag) {
        userTags.push(foundTag)
      }
    }
  }
  userTags = [...userTags, ...processUncategorizedTags(user, tags)]

  return userTags
}

export const isCategoryNameTooLong = (categoryName) => {
  if (categoryName.length > 40) {
    const error = 'Category name is too long. Must be under 40 characters.'
    displayError(error)
    return true
  }
  return false
}
