import React from 'react'
import Select from '@/components/forms/Select'
import { Form } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'

export const GroupByFilter = () => {
  const dispatch = useDispatch()
  const { filters } = useSelector((state) => state.realtimeCoaching)
  return (
    <Form.Field>
      <label>Group By</label>
      <Select
        placeholder="placeholder"
        options={[
          { value: 'day', label: 'Day' },
          { value: 'week', label: 'Week' },
          { value: 'month', label: 'Month' },
          { value: 'quarter', label: 'Quarter' },
        ]}
        value={filters.groupBy}
        onChange={({ value }) => {
          dispatch(setFilter({ groupBy: value }))
        }}
        isClearable={false}
      />
    </Form.Field>
  )
}
