import React from 'react'
import { Button, Modal, Header } from 'semantic-ui-react'

import { IconX } from '@tabler/icons-react'

import './modal.css'

export const BasicModal = ({
  title,
  onClose,
  children,
  footer,
  show,
  showCloseButton = true,
  canCloseOnDimmerClick = true,
  scrolling,
  hideHeader = false,
  ...otherProps
}) => {
  return (
    <Modal
      open={Boolean(show)}
      closeOnDimmerClick={canCloseOnDimmerClick}
      data-testid="basic-modal"
      onClose={onClose}
      {...otherProps}
    >
      {!hideHeader && (
        <Header className="basic-modal__header">
          <h1>{title}</h1>
          {showCloseButton && (
            <Button
              icon
              type="button"
              onClick={onClose}
              data-testid="basic-modal-close-button"
              className="basic-modal__close-button"
            >
              <IconX />
            </Button>
          )}
        </Header>
      )}
      <Modal.Content scrolling={scrolling}>{children}</Modal.Content>
      {Boolean(footer) && <Modal.Actions>{footer}</Modal.Actions>}
    </Modal>
  )
}
