import React from 'react'
import { useSelector } from 'react-redux'
import { IconClipboardCheck, IconPencil } from '@tabler/icons-react'
import './EventsViewer.scss'
import { Loader } from 'semantic-ui-react'
import { isEmpty } from 'lodash'

import NoData from '@/components/NoData'
import { formatTime } from '@/utils/helpers'

export const ActivityViewer = () => {
  const { qaAuditEvents, scorecards } = useSelector((state) => state.scorecards.data)
  const { qaAuditEvents: qaEventsLoading, scorecards: scorecardsLoading } = useSelector(
    (state) => state.scorecards.loading
  )
  const allowedDisputeTypes = ['open_dispute', 'update_dispute']
  const allowedAuditTypes = ['criteria_update', 'scorecard_note', ...allowedDisputeTypes]
  const getScoreDescription = (score) => {
    if (score === 1) {
      return 'passed'
    }

    if (score === 0) {
      return 'failed'
    }

    return 'unscored'
  }

  const getDisputeDescription = (dispute) => {
    if (dispute === 'pending') {
      return 'opened'
    }
    return dispute
  }

  const sortedScorecards = !isEmpty(scorecards)
    ? scorecards.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at)
      })
    : []

  if (qaEventsLoading || scorecardsLoading) {
    return (
      <div data-testid="widget-loading" className="loading-container">
        <Loader active inline />
      </div>
    )
  }

  if (isEmpty(qaAuditEvents)) {
    return (
      <div className="empty-table">
        <NoData text="No QA activity associated with this call" />
      </div>
    )
  }

  return (
    <div className="audit-events-container">
      {qaAuditEvents
        .filter((auditRecord) => allowedAuditTypes.includes(auditRecord.audit_type))
        .map((auditRecord) => (
          <div
            className="audit-event-item"
            data-testid="audit-event-item"
            key={auditRecord.created_at}
          >
            <div className="icon-container">
              <IconPencil />
            </div>
            {auditRecord.audit_type === 'criteria_update' && (
              <div
                className="audit-event-info"
                key={auditRecord.created_at}
                data-testid={auditRecord.created_at}
              >
                <div className="audit-event-title">
                  <strong>
                    {auditRecord.first_name} {auditRecord.last_name}
                  </strong>{' '}
                  edited the score for the criteria <strong>{auditRecord.criteria_name}</strong> on
                  the scorecard <strong>&quot;{auditRecord.scorecard_name}&quot;</strong>
                </div>
                <div className="audit-event-update">
                  Changed from{' '}
                  <span>{getScoreDescription(auditRecord.previous_criteria_score)}</span> to{' '}
                  <span
                    className={getScoreDescription(auditRecord.new_criteria_score).toLowerCase()}
                  >
                    {getScoreDescription(auditRecord.new_criteria_score)}
                  </span>
                </div>
                <div className="audit-event-date">{formatTime(auditRecord.created_at)}</div>
              </div>
            )}

            {auditRecord.audit_type === 'scorecard_note' && (
              <div
                className="audit-event-info"
                key={auditRecord.created_at}
                data-testid={auditRecord.created_at}
              >
                <div className="audit-event-title">
                  <strong>
                    {auditRecord.first_name} {auditRecord.last_name}
                  </strong>{' '}
                  {auditRecord.text === '' ? 'deleted' : 'edited'} the note for the scorecard{' '}
                  <strong>&quot;{auditRecord.scorecard_name}&quot;</strong>
                </div>
                {auditRecord.text !== '' && (
                  <div className="note-box">
                    <div className="note-body">{auditRecord.text}</div>
                  </div>
                )}
                <div className="audit-event-date">{formatTime(auditRecord.created_at)}</div>
              </div>
            )}
            {allowedDisputeTypes.includes(auditRecord.audit_type) && (
              <div
                className="audit-event-info"
                key={auditRecord.created_at}
                data-testid={auditRecord.created_at}
              >
                <div className="audit-event-title" data-testid={`${auditRecord.created_at}-title`}>
                  <strong>
                    {auditRecord.first_name} {auditRecord.last_name}
                  </strong>{' '}
                  {getDisputeDescription(auditRecord.status)} a dispute for the scorecard{' '}
                  <strong>&quot;{auditRecord.scorecard_name}&quot;</strong> for the criteria{' '}
                  <strong>{auditRecord.criteria_name}</strong>
                  {auditRecord.text !== '' && (
                    <>
                      {' '}
                      with the note:
                      <div className="note-box">
                        <div
                          className="note-body"
                          data-testid={`${auditRecord.created_at}-comment`}
                        >
                          {auditRecord.text}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="audit-event-date">{formatTime(auditRecord.created_at)}</div>
              </div>
            )}
          </div>
        ))}
      <div className="audit-event-item">
        <div className="icon-container">
          <IconClipboardCheck />
        </div>
        <div className="audit-event-info">
          {sortedScorecards.map((scorecard) => (
            <div className="audit-event-title">
              <strong>System</strong> generated a new score using <strong>{scorecard.name}</strong>
            </div>
          ))}
          <div className="audit-event-date">
            {!isEmpty(scorecards) && formatTime(scorecards[0].start_time)}
          </div>
        </div>
      </div>
    </div>
  )
}
