import { toast } from 'react-toastify'
import { stringify } from 'query-string'
import {
  setConfigPaths,
  setConfigPathsLoading,
  updateConfigPaths,
} from '@/reducers/realtime/alerts/realtimeNewAlertConfiguration.redux'
import {
  setLoading,
  setAlertConfigurations,
} from '@/reducers/realtime/alerts/realtimeOrganizationAlertConfigurations.redux'
import { fetchingAPI, apiService } from '@/api'
import { stringifyAlertConfig } from '@/views/RealtimeCoaching/helpers'
import { fetchOrganization } from '../organizations/organization.actions'
import { fetchOrganizationTags } from '../organizations/organizationTags.actions'
import { fetchOrganizationTagCategories } from '../organizations/organizationTagCategories.actions'
import { fetchUsers } from '../organizations/organizationUsers.actions'

export const startListeningToAlert =
  ({ agentUsername, alertCallId, processingUrl, managerUsername, alertId }) =>
  (dispatch) => {
    dispatch({
      type: 'realtime/attemptListeningToCall',
      agentUsername,
      alertCallId,
      processingUrl,
      managerUsername,
      alertId,
    })
  }

export const stopListeningToAlert = () => (dispatch) => {
  dispatch({ type: 'realtime/stopListeningToCall' })
}

export const closeAlert =
  ({ username }) =>
  (dispatch, getState) => {
    const remainingAlerts = { ...getState().realtimeUserAlerts.alerts }

    delete remainingAlerts[username]

    if (getState()?.realtimeUserAlerts?.listeningToUsername === username) {
      dispatch(stopListeningToAlert())
    }
    dispatch({ type: 'realtime/dismissAlert', alerts: remainingAlerts })
  }

export const loadPlaybookNamesAndCids = (
  { userOrganizationId = null } = { userOrganizationId: null }
) => {
  return async (dispatch, getState) => {
    dispatch(setConfigPathsLoading(true))

    try {
      const organizationId = userOrganizationId || getState().currentUser.organizationid
      const requestedPropertiesQueryString = `?${stringify({
        requested_properties: 'name,id,cid',
        active_only: true,
      })}`
      const configUrl = `${apiService.web}/api/${organizationId}/configs${requestedPropertiesQueryString}`
      const configs = await fetchingAPI(configUrl, 'GET', dispatch)
      const configPaths = {}

      configs.forEach((config) => {
        if (!configPaths[config.cid]) {
          configPaths[config.cid] = {
            name: config.name,
            id: config.id,
            paths: [],
          }
        }
      })

      dispatch(setConfigPaths(configPaths))
    } catch (err) {
      toast.error('Failed to load configPaths')
    } finally {
      dispatch(setConfigPathsLoading(false))
    }
  }
}

const buildPaths = (type, config, configPaths) => {
  if (!config.body) {
    return
  }

  const entries = config.body[type]?.entries

  if (entries) {
    Object.keys(entries).forEach((entry) => {
      const categoryName = entries[entry].name

      if (entries[entry]?.entries && ['deck', 'classified_postcall'].includes(type)) {
        const nestedEntry = entries[entry].entries

        if (Object.keys(nestedEntry).length > 0) {
          const nestedOptions = []

          Object.keys(nestedEntry).forEach((insideEntry) => {
            if (nestedEntry[insideEntry]) {
              const formattedName = nestedEntry[insideEntry].name
              const formattedValue = `${type} - ${categoryName} - ${formattedName}`

              if (!nestedOptions.filter((i) => i.value === formattedValue).length) {
                nestedOptions.push({
                  label: formattedName,
                  value: formattedValue,
                  key: formattedName,
                })
              }
            }
          })

          configPaths[config.cid].paths.push({
            label: categoryName,
            options: nestedOptions,
            type,
          })
        }
      } else {
        const formattedValue = `${type} - ${categoryName}`
        if (configPaths[config.cid].paths.filter((i) => i.value === formattedValue).length === 0) {
          const item = {
            label: categoryName,
            value: formattedValue,
            key: categoryName,
            type,
          }

          if (type === 'checklist' && entries[entry].trigger?.required_before) {
            item.required_before = entries[entry].trigger.required_before
          }
          configPaths[config.cid].paths.push(item)
        }
      }
    })
  }
}

export const loadPlaybookBodyByCid = ({ playbookCid = '' }) => {
  return async (dispatch) => {
    dispatch(setConfigPathsLoading(true))

    try {
      const configUrl = `${apiService.web}/api/config/${playbookCid}`
      const { configs } = await fetchingAPI(configUrl, 'GET', dispatch)
      const config = configs[0]
      const configPaths = {}

      configPaths[config.cid] = {
        name: config.name,
        paths: [],
      }

      const configTypes = ['deck', 'checklist', 'notifications', 'classified_postcall']
      configTypes.forEach((path) => {
        buildPaths(path, config, configPaths)
      })

      dispatch(updateConfigPaths(configPaths))
    } catch (err) {
      toast.error('Failed to load playbook')
    } finally {
      dispatch(setConfigPathsLoading(false))
    }
  }
}

export const loadAlertConfigurations = (
  { ignoreLoading = false, userId = null, userOrganizationId = null } = {
    ignoreLoading: false,
    userId: null,
    userOrganizationId: null,
  }
) => {
  return async (dispatch, getState) => {
    if (!ignoreLoading) {
      dispatch(setLoading(true))
    }

    try {
      const organizationId = userOrganizationId || getState().currentUser.organizationid
      const formattedQueryString = userId ? `?${stringify({ user_id: userId })}` : ''
      const url = `${apiService.web}/api/alert_configs${formattedQueryString}`
      const [alert_configurations] = await Promise.all([
        fetchingAPI(url, 'GET', dispatch),
        fetchOrganization({ organizationId })(dispatch),
        fetchOrganizationTagCategories({ organizationId })(dispatch),
        fetchOrganizationTags({ organizationId })(dispatch),
        fetchUsers({ organizationId })(dispatch),
      ])

      dispatch(setAlertConfigurations(alert_configurations))
    } catch (err) {
      toast.error('Failed to load alerts')
    } finally {
      if (!ignoreLoading) {
        dispatch(setLoading(false))
      }
    }
  }
}

export const editOrgLevelAlertConfiguration =
  (alertConfig, toastMessage = null) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    const url = `${apiService.web}/api/alert_config`
    const alertConfigDataStr = stringifyAlertConfig(alertConfig)
    try {
      await fetchingAPI(url, 'POST', dispatch, alertConfigDataStr)
      dispatch({
        type: 'REFRESH_USERS_ALERTS',
      })
      await loadAlertConfigurations({ ignoreLoading: true })(dispatch, getState)
    } catch (err) {
      toast.error('Failed to edit alert')
    } finally {
      dispatch(setLoading(false))
      if (toastMessage) {
        toast.success(toastMessage)
      }
    }
  }

export const editAlertConfiguration =
  ({ data }, toastMessage = null) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    const url = `${apiService.web}/api/alert_config`
    try {
      await fetchingAPI(url, 'POST', dispatch, JSON.stringify(data))
      dispatch({
        type: 'REFRESH_USERS_ALERTS',
      })
      await loadAlertConfigurations({ ignoreLoading: true })(dispatch, getState)
    } catch (err) {
      toast.error('Failed to edit alert')
    } finally {
      dispatch(setLoading(false))
      if (toastMessage) {
        toast.success(toastMessage)
      }
    }
  }

export const deleteAlertConfiguration =
  ({ alertConfigurationId, alertName }) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    const alertConfigs = getState().realtimeOrganizationAlertConfigurations.alertConfigurations
    try {
      await fetchingAPI(
        `${apiService.web}/api/alert_config/${alertConfigurationId}`,
        'DELETE',
        dispatch
      )

      dispatch(
        setAlertConfigurations(
          alertConfigs.filter((item) => {
            return item.id !== alertConfigurationId
          })
        )
      )

      dispatch({
        type: 'REFRESH_USERS_ALERTS',
      })
    } catch (err) {
      toast.error('Failed to delete alert')
    } finally {
      dispatch(setLoading(false))
      toast.success(`${alertName} has been deleted`)
    }
  }
