import React from 'react'
import { Button, Checkbox, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { IconArrowLeft } from '@tabler/icons-react'
import { useHistory, useParams } from 'react-router-dom'
import { toArray, isEmpty } from 'lodash'

import {
  createScorecardConfig,
  updateScorecardConfig,
  toggleActivateScorecardConfig,
} from '@/reducers/qa-copilot/qa-copilot.actions'
import { openModal } from '@/reducers/ui/ui.redux'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'

import { QACopilotScorecardName } from './QACopilotScorecardName'

export const QACopilotNavigation = ({ isOutdatedScorecard }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { scorecardConfig, dirty } = useSelector((state) => state.qaCopilot)
  const { prompts } = useSelector((state) => state.prompts)
  const { id } = useParams()
  const noCriteria = isEmpty(
    toArray(scorecardConfig?.sections).flatMap((section) =>
      toArray(section.measures).flatMap((measure) => toArray(measure.criteria))
    )
  )
  const criteriaPointsModalId = 'qaCopilot/criteriaPoints'
  const settingsModalId = 'qaCopilot/settings'

  const canSaveScorecard = () => {
    if (!dirty) {
      return 'Make changes to save'
    }

    if (!scorecardConfig.organization_id) {
      return 'Select an organization to save'
    }

    return false
  }

  const handleGoBack = () => {
    history.push('/qa-copilot')
  }

  const handleOpenCriteriaPointsModal = () => {
    dispatch(openModal(criteriaPointsModalId))
  }

  const handleOpenSettingsModal = () => {
    dispatch(openModal(settingsModalId))
  }

  const createOrSave = (config) => {
    if (id === 'create') {
      dispatch(createScorecardConfig(config, history, prompts))
    } else {
      dispatch(updateScorecardConfig(config, history, prompts))
    }
  }

  const handleSave = () => {
    createOrSave({ ...scorecardConfig, type: 'copilot' })
  }

  const handleSaveAndActivate = () => {
    createOrSave({ ...scorecardConfig, type: 'copilot', active: true })
  }

  const handleToggleActivate = () => {
    // Can't activate a scorecard that hasn't been created
    if (id === 'create') {
      return
    }

    // Save and activate/deactivate if dirty
    if (dirty) {
      createOrSave({ ...scorecardConfig, type: 'copilot', active: !scorecardConfig.active })
    } else {
      // Just activate/deactivate if not dirty
      dispatch(toggleActivateScorecardConfig({ ...scorecardConfig, type: 'copilot' }))
    }
  }

  return (
    <div className="qa-copilot-navigation">
      <div className="qa-copilot-navigation__container">
        <div className="qa-copilot-navigation__title">
          <Button secondary icon className="svg-button" onClick={handleGoBack}>
            <IconArrowLeft />
          </Button>
          <QACopilotScorecardName />
        </div>
        {!isOutdatedScorecard && (
          <div className="qa-copilot-navigation__actions">
            <Button
              basic
              onClick={handleOpenSettingsModal}
              data-testid="qa-copilot-settings-button"
            >
              Settings
            </Button>

            <ConditionalTooltip condition={noCriteria} content="Add criteria to edit points">
              <Button
                basic
                data-testid="edit-points-button"
                onClick={handleOpenCriteriaPointsModal}
                disabled={noCriteria}
              >
                Edit Points
              </Button>
            </ConditionalTooltip>

            <ConditionalTooltip condition={!!canSaveScorecard()} content={canSaveScorecard()}>
              <Button
                data-testid="save-button"
                primary={id !== 'create'}
                secondary={id === 'create'}
                onClick={handleSave}
                disabled={!!canSaveScorecard()}
              >
                Save
              </Button>
            </ConditionalTooltip>

            {id === 'create' ? (
              <ConditionalTooltip
                tooltipProps={{ position: 'bottom right' }}
                condition={!!canSaveScorecard()}
                content={canSaveScorecard()}
              >
                <Button
                  data-testid="save-and-activate-button"
                  primary
                  onClick={handleSaveAndActivate}
                  disabled={!!canSaveScorecard()}
                >
                  Save & Activate
                </Button>
              </ConditionalTooltip>
            ) : (
              <Popup
                position="bottom right"
                inverted
                content={`Click to ${
                  scorecardConfig?.active ? 'deactivate' : 'activate'
                } scorecard`}
                trigger={
                  <Checkbox
                    toggle
                    data-testid="activate-scorecard-config"
                    label={scorecardConfig?.active ? 'Active' : 'Inactive'}
                    checked={scorecardConfig?.active}
                    onChange={handleToggleActivate}
                    style={{ marginLeft: '1rem' }}
                  />
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
