import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import moment from 'moment'

import { Button, TextArea, Form, Dropdown } from 'semantic-ui-react'

import { Banner } from '@/components/banners/Banner'

export const CoachingNote = ({ coachingComment, callId, updateComment }) => {
  const formatDate = (date) => moment.utc(date).local().format('MMMM Do, h:mm A')
  const [isEditing, setIsEditing] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [editCommentText, setEditCommentText] = useState('')

  const { user_id: currentUserId } = useSelector((state) => state.currentUser)

  const { id, author, comment, created_at, edited_at, user_id } = coachingComment

  const timeText = `${formatDate(created_at)}${edited_at ? ' (edited)' : ''}`

  return (
    <Banner
      dataTestId={`coaching-note-${id}`}
      informative
      className="full-width"
      header={
        <div className="gap flex-align-center flex-space-between full-width">
          <div className="gap flex-align-center">
            <div data-testid={`coaching-note-author-${id}`}>{author}</div>
            <small data-testid={`coaching-note-timestamp-${id}`} style={{ fontWeight: 400 }}>
              <i>{timeText}</i>
            </small>
          </div>
          {currentUserId === user_id && (
            <Dropdown
              item
              direction="right"
              icon="ellipsis vertical"
              button
              open={menuOpen}
              onClick={() => setMenuOpen(!menuOpen)}
              onBlur={() => setMenuOpen(false)}
              className="icon minimal-button hidden"
              data-testid={`coaching-note-dropdown-${id}`}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  data-testid={`coaching-note-edit-button-${id}`}
                  onClick={(event) => {
                    event.stopPropagation()
                    setIsEditing(true)
                    setEditCommentText(comment)
                    setMenuOpen(false)
                  }}
                >
                  Edit Note
                </Dropdown.Item>
                <Dropdown.Item
                  className="danger"
                  data-testid={`coaching-note-delete-button-${id}`}
                  onClick={(event) => {
                    event.stopPropagation()
                    updateComment({ deleted: true }, id, callId)
                    setMenuOpen(false)
                  }}
                >
                  Delete Note
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      }
    >
      {isEditing ? (
        <Form className="full-width">
          <TextArea
            data-testid={`coaching-note-textarea-${id}`}
            className="mb"
            style={{ minHeight: '150px' }}
            placeholder="Enter a coaching note..."
            value={editCommentText}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => setEditCommentText(e.target.value)}
            autoFocus
          />
          <div className="flex-end gap">
            <Button
              data-testid={`coaching-note-discard-${id}`}
              secondary
              onClick={() => {
                setEditCommentText(comment)
                setIsEditing(false)
              }}
            >
              Discard
            </Button>
            <Button
              type="submit"
              data-testid={`coaching-note-save-${id}`}
              primary
              onClick={() => {
                updateComment({ comment: editCommentText.trim() }, id, callId)
                setIsEditing(false)
              }}
              disabled={editCommentText === comment || isEmpty(editCommentText)}
            >
              Save
            </Button>
          </div>
        </Form>
      ) : (
        <p
          className="mb"
          data-testid={`coaching-note-comment-${id}`}
          style={{ whiteSpace: 'pre-line' }}
        >
          {comment}
        </p>
      )}
    </Banner>
  )
}
