import React from 'react'
import { Table } from 'semantic-ui-react'
import { getItemTypeLabel } from '@/views/RealtimeCoaching/Alerts/OrgLevelAlertWizard/components/helpers'

export const ReviewPageTriggersTable = ({ triggerItems, goToPageNumber }) => {
  return (
    <Table className="alert-wizard__review-page-table" basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="alert-wizard__review-table-header-cell">
            Triggers
          </Table.HeaderCell>
          <Table.HeaderCell
            className="alert-wizard__review-edit-button alert-wizard__review-table-header-cell"
            textAlign="right"
            onClick={() => goToPageNumber(2)}
            data-testid="edit-triggers"
          >
            Edit
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {triggerItems.map(
          ({ playbookLabel, itemType, triggerItemLabel, playbookCid, triggerItemPath }) => (
            <Table.Row key={playbookCid + triggerItemPath}>
              <Table.Cell className="alert-wizard__review-table-cell">
                <div className="alert-wizard__trigger-item-review-page">
                  <span
                    className="alert-wizard__trigger-item-description"
                    data-testid="review-page-trigger-item"
                  >
                    {playbookLabel}
                    <span className="alert-wizard__trigger-item-bullet-separator">•</span>
                    {getItemTypeLabel(itemType)}
                  </span>
                  <span
                    className="alert-wizard__trigger-item-name"
                    data-testid="review-page-trigger-item-name"
                  >
                    {triggerItemLabel}
                  </span>
                </div>
              </Table.Cell>
              <Table.Cell className="alert-wizard__review-table-cell" />
            </Table.Row>
          )
        )}
      </Table.Body>
    </Table>
  )
}
