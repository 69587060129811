import React from 'react'
import { IconPhone, IconMoodSad, IconClipboardText } from '@tabler/icons-react'
import { Loader } from 'semantic-ui-react'
import { isNumber, isEmpty } from 'lodash'

import { Pill } from '@/components/pills/Pill'

import { ExternalCallIdsLink } from '../components/ExternalCallIdsLink'

const showDisplayValue = (value, callIds, isPercent) => {
  if (isPercent) {
    // Backend returns 100% score if 0 calls are evaluated. Show N/A instead
    if (isEmpty(callIds)) {
      return 'N/A'
    }

    return `${value.toFixed(2) || 0}%`
  }

  return isNumber(value) ? Number(value).toLocaleString() : value || '0'
}

export const OverviewIcon = ({ noData, type }) => {
  if (noData) {
    return <IconClipboardText />
  }

  if (type === 'neutral') {
    return <IconPhone />
  }

  if (type === 'negative') {
    return <IconMoodSad />
  }

  return <IconClipboardText />
}

export const OverviewCard = ({ title, type, value, callIds, isPercent, loading, showLink }) => {
  const dataTestId = `overview-card-${title.split(' ').join('-').toLowerCase()}`
  const noData = isEmpty(callIds)
  const isStatusIcon = !noData && !type && isPercent

  return (
    <div className="overview-card" data-testid={dataTestId}>
      <Pill
        brand={!noData && type === 'neutral'}
        caution={!noData && type === 'negative'}
        success={isStatusIcon && value > 80}
        warning={isStatusIcon && value > 50 && value <= 80}
        critical={isStatusIcon && value <= 50}
        circular
        icon={<OverviewIcon noData={noData} type={type} />}
        data-testid={`${dataTestId}-icon`}
      />
      {loading ? (
        <Loader active inline />
      ) : !isEmpty(callIds) && showLink ? (
        <ExternalCallIdsLink
          id={dataTestId}
          callIds={callIds}
          label={showDisplayValue(value, callIds, isPercent)}
        />
      ) : (
        <h2 data-testid={`${dataTestId}-value`}>{showDisplayValue(value, callIds, isPercent)}</h2>
      )}

      <h3 data-testid={`${dataTestId}-title`}>{title}</h3>
    </div>
  )
}
