import { pick, toString } from 'lodash'

export const parseToCsv = ({ data, headers }) => {
  const csvData = []

  // Use the labels of the headers for the CSV
  const formatField = (field) => `"${String(field).replace(/"/g, '""')}"`
  const headersToWrite = headers.map((header) => formatField(header.label))
  csvData.push(headersToWrite.join(','))

  // Remove any unnecessary properties from the rows
  const allowedProperties = headers.map((header) => header.accessor)
  const rows = data.map((row) => pick(row, allowedProperties))

  // Format any rows if necessary
  rows.forEach((row) => {
    const formattedRow = []
    headers.forEach((header) => {
      const property = header.accessor
      const value = row[property]

      const formattedValue =
        value == null
          ? '""' // Ensure empty values are represented as ""
          : formatField(header.format ? header.format(value) : toString(value))

      formattedRow.push(formattedValue)
    })

    csvData.push(formattedRow.join(','))
  })

  return csvData.join('\n')
}
