// Action Types
export const SET_ORGANIZATION_TAGS = 'organizations/setOrganizationTags'

// Action Creators
export const setOrganizationTags = (payload) => ({ type: SET_ORGANIZATION_TAGS, payload })

// State
export const initialState = []

// Reducer
export default function organizationTagsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORGANIZATION_TAGS:
      return action.payload
    default:
      return state
  }
}
