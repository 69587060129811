import React from 'react'
import { Button } from 'semantic-ui-react'

export const DateRangePickerButtons = ({
  handleCancel,
  handleSave,
  cancelLabel,
  saveLabel,
  disabled,
}) => {
  return (
    <div className="date-picker-button-section" data-testid="date-picker-button-section">
      <Button secondary onClick={handleCancel} data-testid="cancel">
        {cancelLabel || 'Cancel'}
      </Button>
      <Button primary onClick={handleSave} data-testid="submit" disabled={disabled}>
        {saveLabel || 'Save'}
      </Button>
    </div>
  )
}
