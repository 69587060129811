import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Dropdown, Loader } from 'semantic-ui-react'

import { removeWidget } from '@/reducers/userSettings/userSettings.actions'

import './DashboardWidget.scss'

export const DashboardWidget = ({
  widget,
  dashboardType,
  title,
  description,
  settings,
  loading,
  defaultDateRange,
  defaultDateWindow,
  handleFetchData,
  children,
  ...props
}) => {
  const dispatch = useDispatch()
  const [selectedDateRange, setSelectedDateRange] = useState(defaultDateRange)

  const handleDelete = () => {
    dispatch(removeWidget(dashboardType, widget))
  }

  const rangeOptions = [
    { key: 'Last 90 Days', text: 'Last 90 Days', value: '90_days' },
    { key: 'Last 6 Months', text: 'Last 6 Months', value: '6_months' },
    { key: 'Last Year', text: 'Last Year', value: '1_years' },
  ]

  useEffect(() => {
    handleFetchData(selectedDateRange)
  }, [selectedDateRange])

  return (
    <div className="dashboard-widget" {...props}>
      <div>
        <header>
          <div>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <div className="flex-align-center medium-gap">
            <Dropdown
              item
              direction="right"
              button
              className="icon secondary flex-space-between"
              placeholder="Select Date Range"
              disabled={loading}
              options={rangeOptions}
              defaultValue={selectedDateRange}
              style={{ minWidth: '140px' }}
              onChange={(event, data) => {
                setSelectedDateRange(data.value)
              }}
            />
            <Dropdown
              item
              direction="right"
              icon="ellipsis vertical"
              button
              className="icon secondary"
              disabled={loading}
            >
              <Dropdown.Menu>
                <Dropdown.Item className="danger" onClick={handleDelete}>
                  Remove Widget
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </header>
        <section>
          {loading ? (
            <div className="loading-container">
              <Loader active inline />
            </div>
          ) : (
            children
          )}
        </section>
      </div>
    </div>
  )
}
