// Action Types
export const SET_PLAYBOOKS_LOADING = 'playbooks/setPlaybooksLoading'
export const SET_PLAYBOOKS = 'playbooks/setPlaybooks'

// Action Creators
export const setLoading = (payload) => ({ type: SET_PLAYBOOKS_LOADING, payload })
export const setPlaybooks = (payload) => ({ type: SET_PLAYBOOKS, payload })

// State
export const initialState = {
  playbooks: [],
  loading: false,
}

// Reducer
const playbooksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAYBOOKS_LOADING:
      return { ...state, loading: action.payload }
    case SET_PLAYBOOKS:
      return { ...state, playbooks: action.payload }
    default:
      return state
  }
}

export default playbooksReducer
