import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'semantic-ui-react'
import './ReportsActivityTableContainer.scss'
import {
  fetchAlertCountsByTypeByDay,
  fetchCallAlertPercentage,
  fetchPreviousPeriodAlertCountsByTypeByDay,
  fetchPreviousPeriodCallAlertPercentage,
  fetchTopAlertConfigs,
  fetchTopAlertingAgents,
  fetchTopChattingSupervisor,
  fetchTopListenerSupervisor,
  fetchTopListenerSupervisors,
  fetchTopSupervisorChatOccurrences,
} from '@/reducers/realtime/reports/realtimeReports.actions'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { BaltoAdminOrganizationFilter } from '@/views/RealtimeCoaching/components/filters/BaltoAdminOrganizationFilter'
import { DateRangePicker } from '@/views/RealtimeCoaching/components/filters/DateRangePicker'
import { ReportsAlertTrendsChart } from './ReportsAlertTrendsChart'
import { ReportsActivityTable } from './ReportsActivityTable'
import Widget from '../../../components/widget/Widget'
import { ReportInfoCards } from './ReportInfoCards'

export const Reports = () => {
  const dispatch = useDispatch()
  const dateRange = useSelector((state) => state.realtimeCoaching.filters.dateRange)
  const loading = useSelector((state) => state.realtimeReports.loading)
  const { previousPeriodAlertCountsByTypeByDay, previousPeriodCallAlertPercentage } = useSelector(
    (state) => state.realtimeReports.data
  )
  const { currentUser } = useSelector((state) => state)
  const { filters } = useSelector((state) => state.realtimeCoaching)
  const isBaltoAdmin = currentUser.organizationid === 1

  // for filter button loading/disabled state.
  const isDataLoading = Object.keys(loading).some((dataLoading) => loading[dataLoading] === true)
  // Since dateRange is used by ReportsInfoCard, we have a separate state that only updates after hitting filter
  const [filteredDateRange, setFilteredDateRange] = useState(dateRange)

  const fetchOverviewData = () => {
    if (!filters.dateRangeIsValid) {
      return
    }
    const requestedOrgId = filters.organization || currentUser.organizationid
    // TODO: look into a way to batch these. Still need to be called individually
    // in some places as well so might end with some duplicate code. But would be nice
    // to have an action like loadInitialReportOverview that would batch all the dispatches
    // make the queries and handle correctly. reduce dispatches -> reduce renders -> load faster
    setFilteredDateRange(dateRange)
    dispatch(fetchAlertCountsByTypeByDay(dateRange.startDate, dateRange.endDate, requestedOrgId))
    dispatch(fetchCallAlertPercentage(dateRange.startDate, dateRange.endDate, requestedOrgId))
    dispatch(
      fetchPreviousPeriodAlertCountsByTypeByDay(
        dateRange.startDate,
        dateRange.endDate,
        dateRange.text,
        requestedOrgId
      )
    )
    dispatch(
      fetchPreviousPeriodCallAlertPercentage(
        dateRange.startDate,
        dateRange.endDate,
        dateRange.text,
        requestedOrgId
      )
    )
    dispatch(
      fetchTopAlertingAgents({
        limit: 5,
        order: 'descending',
        alertType: 'positive',
        endDate: dateRange.endDate,
        startDate: dateRange.startDate,
        requestedOrgId,
      })
    )
    dispatch(
      fetchTopAlertingAgents({
        limit: 5,
        order: 'descending',
        alertType: 'negative',
        endDate: dateRange.endDate,
        startDate: dateRange.startDate,
        requestedOrgId,
      })
    )
    dispatch(
      fetchTopAlertingAgents({
        limit: 5,
        order: 'descending',
        alertType: 'informative',
        endDate: dateRange.endDate,
        startDate: dateRange.startDate,
        requestedOrgId,
      })
    )
    dispatch(
      fetchTopAlertConfigs({
        limit: 5,
        order: 'descending',
        endDate: dateRange.endDate,
        startDate: dateRange.startDate,
        requestedOrgId,
      })
    )
    dispatch(
      fetchTopChattingSupervisor({
        endDate: dateRange.endDate,
        startDate: dateRange.startDate,
        requestedOrgId,
      })
    )
    dispatch(
      fetchTopSupervisorChatOccurrences({
        limit: 5,
        order: 'descending',
        endDate: dateRange.endDate,
        startDate: dateRange.startDate,
        requestedOrgId,
      })
    )
    dispatch(
      // Listens KPI Card
      fetchTopListenerSupervisor({
        endDate: dateRange.endDate,
        startDate: dateRange.startDate,
        requestedOrgId,
      })
    )
    dispatch(
      // Listens Overview Table
      fetchTopListenerSupervisors({
        endDate: dateRange.endDate,
        startDate: dateRange.startDate,
        requestedOrgId,
      })
    )
  }

  useEffect(() => {
    if (!filters?.organization) {
      dispatch(setFilter({ organization: currentUser.organizationid }))
    }
    fetchOverviewData()
  }, [])

  const {
    alertCountsByTypeByDay,
    callAlertPercentage,
    topAlertConfigs,
    topAlertingAgentsNegative,
    topAlertingAgentsPositive,
    topAlertingAgentsInformative,
    topChattingSupervisor,
    topChattingSupervisors,
    topListenerSupervisor,
    topListenerSupervisors,
  } = useSelector((state) => state.realtimeReports.data)

  return (
    <>
      <header className="page-header">
        <h1>Reports</h1>
      </header>
      <Form
        className="filter-form"
        data-testid="realtime-reports-overview-filters"
        onSubmit={() => {
          fetchOverviewData()
        }}
      >
        <div className="filter-grid small-grid">
          {isBaltoAdmin && <BaltoAdminOrganizationFilter />}
          <DateRangePicker />
          <div className="filter-buttons">
            <Form.Field>
              <label className="visibility-hidden">&nbsp;</label>
              <Button
                primary
                data-testid="filter-button"
                type="submit"
                loading={isDataLoading}
                disabled={isDataLoading || !filters.dateRangeIsValid}
              >
                Filter
              </Button>
            </Form.Field>
          </div>
        </div>
      </Form>
      <ReportInfoCards
        loading={loading}
        callAlertPercentage={callAlertPercentage}
        alertCountsByTypeByDay={alertCountsByTypeByDay}
        dateRange={filteredDateRange}
        previousPeriodAlertCountsByTypeByDay={previousPeriodAlertCountsByTypeByDay}
        previousPeriodCallAlertPercentage={previousPeriodCallAlertPercentage}
        topChattingSupervisor={topChattingSupervisor}
        topListenerSupervisor={topListenerSupervisor}
      />
      <Widget label="Overall Alert Trends">
        <ReportsAlertTrendsChart />
      </Widget>
      <div className="reports-activity-grid">
        <ReportsActivityTable
          tableKey="topAlertConfigs"
          tableType="alert"
          description="Alert Occurrences"
          alerts={topAlertConfigs}
          drilldownUrlResource="alert"
        />
        <ReportsActivityTable
          tableKey="topAlertingAgentsNegative"
          tableType="negative"
          description="Negative Alerts"
          alerts={topAlertingAgentsNegative}
          includeAgentTagFilter
          drilldownUrlResource="agent"
        />
        <ReportsActivityTable
          tableKey="topAlertingAgentsPositive"
          tableType="positive"
          description="Positive Occurrences"
          alerts={topAlertingAgentsPositive}
          includeAgentTagFilter
          drilldownUrlResource="agent"
        />
        <ReportsActivityTable
          tableKey="topAlertingAgentsInformative"
          tableType="informative"
          description="Informative Occurrences"
          alerts={topAlertingAgentsInformative}
          includeAgentTagFilter
          drilldownUrlResource="agent"
        />
        <ReportsActivityTable
          tableKey="topChattingSupervisors"
          tableType="supervisorOccurrences"
          description="Chat Occurrences"
          alerts={topChattingSupervisors}
          drilldownUrlResource="chat"
        />
        <ReportsActivityTable
          tableKey="topListenerSupervisors"
          tableType="supervisorOccurrences"
          description="Listen Occurrences"
          alerts={topListenerSupervisors}
          drilldownUrlResource="listen"
        />
      </div>
    </>
  )
}

export default Reports
