import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'

import { createEntry, updateEntry } from '@/reducers/playbooks/playbook.redux'
import { fetchPlaybookSectionWarnings } from '@/reducers/playbooks/playbook.actions'
import NoData from '@/components/NoData'

import { accessorSectionMap } from '../playbook.helpers'

export const PlaybookEditor = ({ entry, children, hasCategory, categoryId }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { entryId, section, id } = useParams()
  const {
    loading,
    playbook: { current: isLatestPlaybookVersion },
  } = useSelector((state) => state.playbook)
  const { edit_config } = useSelector((state) => state.currentUser)
  const accessor = accessorSectionMap[section]
  const isCreate = entryId === 'create'
  const readOnly = !isLatestPlaybookVersion || !edit_config
  const handleUpdate = (updatedEntry) => {
    const valueToSend = hasCategory ? { entry: updatedEntry, categoryId } : { entry: updatedEntry }

    if (isCreate) {
      dispatch(createEntry(accessor)(valueToSend))
    } else {
      dispatch(updateEntry(accessor)({ entryId, ...valueToSend }))
    }

    history.push(`/playbooks/${id}/${section}`)
    dispatch(fetchPlaybookSectionWarnings(accessor))
  }

  const handleCancel = () => {
    history.push(`/playbooks/${id}/${section}`)
    dispatch(fetchPlaybookSectionWarnings(accessor))
  }

  if (loading.playbook) {
    return <Loader active />
  }

  if (hasCategory && !categoryId) {
    return <NoData text="No category id found, entry does not exist" position="left" />
  }

  if (!isCreate && !entry) {
    return <NoData text="No item was found for this entry" position="left" />
  }

  return children({ handleUpdate, handleCancel, readOnly })
}
