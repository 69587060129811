import moment from 'moment'
import { toast } from 'react-toastify'
import { saveAs } from 'file-saver'

import { parseDashboardToCsv } from '@/reducers/scorecards/helpers'
import { fetchingAPI, apiService } from '../../api'
import { fetchOrganizationTagCategories } from '../organizations/organizationTagCategories.actions'
import { fetchOrganizationTags } from '../organizations/organizationTags.actions'
import { loadConfigs } from '../../actions/server'
import { setFilter, setLoading, setData, setLeaderboardOrganization } from './leaderboards.redux'

// Helpers are not thunks, so you can call the helpers as async actions within a thunk
async function fetchLeaderboardHelper(dispatch, organizationId, leaderboardId) {
  dispatch(setFilter({ leaderboardId }))

  const startOfDay = moment().startOf('day').format()
  const startOfWeek = moment().startOf('week').format()

  const leaderboard = await fetchingAPI(
    `${apiService.leaderboard}/leaderboards/leaderboard/${leaderboardId}`,
    'POST',
    dispatch,
    JSON.stringify({
      startOfDay,
      startOfWeek,
    })
  )

  dispatch(setData({ leaderboard }))
}

async function fetchLeaderboardsHelper(dispatch, organizationId) {
  const leaderboards = await fetchingAPI(
    `${apiService.leaderboard}/leaderboards/leaderboards?organization_id=${organizationId}`,
    'GET',
    dispatch
  )

  dispatch(setData({ leaderboards }))
}

export const fetchLeaderboard = (organizationId, leaderboardId) => async (dispatch) => {
  dispatch(setLoading({ leaderboard: true }))

  try {
    await fetchLeaderboardHelper(dispatch, organizationId, leaderboardId)
  } catch (err) {
    toast.error('Failed to load leaderboard')
  } finally {
    dispatch(setLoading({ leaderboard: false }))
  }
}

export const fetchOrganizationAndLeaderboards = (organizationId) => async (dispatch) => {
  dispatch(setLeaderboardOrganization(organizationId))
  dispatch(
    setLoading({
      leaderboards: true,
      leaderboard: true,
    })
  )

  try {
    const [leaderboards] = await Promise.all([
      fetchingAPI(
        `${apiService.leaderboard}/leaderboards/leaderboards?organization_id=${organizationId}`,
        'GET',
        dispatch
      ),
      fetchOrganizationTagCategories({ organizationId })(dispatch),
      fetchOrganizationTags({ organizationId })(dispatch),
      loadConfigs({
        organizationId,
        requestedProperties: 'name,cid,organization_id,organization_name',
      })(dispatch),
    ])

    dispatch(setData({ leaderboards }))
    dispatch(setLoading({ leaderboards: false }))

    if (leaderboards?.length > 0 && leaderboards[0].leaderboard?.id) {
      // select the first leaderboard
      await fetchLeaderboardHelper(dispatch, organizationId, leaderboards[0].leaderboard.id)
    } else {
      dispatch(setData({ leaderboard: null }))
    }
  } catch (err) {
    toast.error('Failed to load leaderboards')
  } finally {
    dispatch(
      setLoading({
        leaderboards: false,
        leaderboard: false,
      })
    )
  }
}

export const createEditLeaderboard = (organizationId, data, leaderboardId) => async (dispatch) => {
  dispatch(
    setLoading({
      leaderboards: true,
      leaderboard: true,
    })
  )

  try {
    const { leaderboard } = await fetchingAPI(
      `${apiService.leaderboard}/leaderboards/leaderboards?organization_id=${organizationId}`,
      'POST',
      dispatch,
      JSON.stringify(data)
    )

    await fetchLeaderboardsHelper(dispatch, organizationId)
    if (leaderboardId) {
      // Edit
      await fetchLeaderboardHelper(dispatch, organizationId, leaderboardId)
    } else {
      // Create
      await fetchLeaderboardHelper(dispatch, organizationId, leaderboard?.id)
    }
  } catch (err) {
    toast.error(`Failed to ${leaderboardId ? 'edit' : 'create'} leaderboard`)
  } finally {
    dispatch(
      setLoading({
        leaderboards: false,
        leaderboard: false,
      })
    )
  }
}

export const deleteLeaderboard = (organizationId, leaderboardId) => async (dispatch) => {
  dispatch(
    setLoading({
      leaderboards: true,
      leaderboard: true,
    })
  )

  try {
    await fetchingAPI(
      `${apiService.leaderboard}/leaderboards/leaderboard/${leaderboardId}`,
      'DELETE',
      dispatch
    )
    await fetchLeaderboardsHelper(dispatch, organizationId)

    dispatch(setFilter({ leaderboardId: '' }))
    dispatch(setData({ leaderboard: null }))
  } catch (err) {
    toast.error('Failed to delete leaderboard')
  } finally {
    dispatch(
      setLoading({
        leaderboards: false,
        leaderboard: false,
      })
    )
  }
}

export const exportLeaderboardToCsv = (data) => async (dispatch) => {
  dispatch(
    setLoading({
      csv: true,
    })
  )
  const { leaderboard } = data
  const userLeaderboard = leaderboard.leaderboard
  const leaderboardNameString = leaderboard.name.replace(/\s/g, '_')
  const dateRangeString = ''.concat(leaderboard.start_date, '-', leaderboard.end_date)
  const fileName = `balto-leaderboard-${leaderboardNameString}-${dateRangeString}.csv`

  const headers = [
    {
      accessor: 'rank',
      label: 'Rank',
    },
    {
      accessor: 'name',
      label: 'Name',
    },
    {
      accessor: 'username',
      label: 'Username',
    },
    {
      accessor: 'leaderboard_value',
      label: 'Value',
    },
    {
      accessor: 'total_calls',
      label: 'Total Calls',
    },
  ]

  try {
    const response = parseDashboardToCsv(userLeaderboard, headers)
    saveAs(response, fileName)
  } catch (err) {
    toast.error('Failed to fetch CSV file')
  } finally {
    dispatch(
      setLoading({
        csv: false,
      })
    )
  }
}
