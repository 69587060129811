import React from 'react'

import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { ExportUsersForm } from '../forms/ExportUsersForm'

export const ExportUsersButton = ({
  playbooks,
  exportUsers,
  tags,
  tagCategories,
  organization,
}) => {
  return (
    <div style={{ margin: '.5rem' }}>
      <ButtonAndFormModal
        buttonLabel="Export Users"
        modalTitle="Export Users"
        modalId="users/export-users"
        modalProps={{ size: 'small' }}
        buttonProps={{ primary: true }}
        form={
          <ExportUsersForm
            handleSubmit={exportUsers}
            playbooks={playbooks}
            organization={organization}
            tags={tags}
            tagCategories={tagCategories}
          />
        }
      />
    </div>
  )
}
