import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import { startCase, trim, truncate } from 'lodash'
import { IconPencil, IconTrash } from '@tabler/icons-react'

import {
  deleteKeyword,
  fetchKeywordsRepository,
  saveKeyword,
} from '@/reducers/keywords/keywords.actions'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { parseKeywords } from '@/middleware/sanitizationHelpers'
import { closeModal } from '@/reducers/ui/ui.redux'
import { formatTime } from '@/utils/helpers'

import { KeywordForm } from './KeywordForm'
import { DeleteKeywordForm } from './DeleteKeywordForm'

export const KeywordRepositoryPage = () => {
  const dispatch = useDispatch()
  const { language } = useParams()
  const { keywords, loading } = useSelector((state) => state.keywords)

  const handleUpdateKeyword = async ({ validateOnMount, ...values }) => {
    const formattedKeyword = {
      ...values,
      name: trim(values.name),
      definition: trim(values.definition),
      inference_type: values.inference_type,
      side: values.side || null,
      side_restricted: Boolean(values.side_restricted),
      phrases: parseKeywords(values.phrases),
      examples: values.examples ? parseKeywords(values.examples) : null,
    }
    dispatch(saveKeyword(formattedKeyword, language))
    dispatch(closeModal())
  }

  const handleDeleteKeyword = async (krid) => {
    dispatch(deleteKeyword(krid, language))
  }

  const columns = [
    { accessor: 'name', label: 'Name', isSearchable: true },
    { accessor: 'definition', label: 'Definition', isSearchable: true },
    {
      accessor: 'inference_type',
      label: 'Inference Type',
      isSearchable: true,
      format: (infType) =>
        infType === 'deep_learning_v1' ? 'Deep Learning v1' : startCase(infType),
    },
    { accessor: 'side', label: 'Side', isSearchable: true, format: (side) => startCase(side) },
    {
      accessor: 'side_restricted',
      label: 'Restrict Side',
      format: (side_restricted) => startCase(String(side_restricted)),
      isSearchable: true,
    },
    {
      accessor: 'phrases',
      label: 'Keywords',
      format: (keywords) => truncate(keywords.join(', '), { length: 100 }),
      isSearchable: true,
    },
    {
      accessor: 'examples',
      label: 'Examples',
      format: (examples) => {
        if (examples) {
          return truncate(examples.join(', '), { length: 100 })
        }
        return ''
      },
      isSearchable: true,
    },
    {
      accessor: 'created',
      label: 'Last Updated',
      format: (date) => formatTime(date),
    },
  ]

  const actions = [
    {
      label: 'Edit',
      trigger: (row) => {
        return (
          <ButtonAndFormModal
            popup
            icon={<IconPencil />}
            buttonProps={{ basic: true, compact: true }}
            popupProps={{ position: 'top right' }}
            modalId={`keywords/edit/${row.id}`}
            modalTitle={`Edit "${row.name}"`}
            form={
              <KeywordForm
                handleSubmit={handleUpdateKeyword}
                existingValues={{
                  ...row,
                  phrases: row.phrases.join(', '),
                  examples: row.examples ? row.examples.join(', ') : '',
                }}
                handleClose={() => dispatch(closeModal())}
              />
            }
          />
        )
      },
    },
    {
      label: 'Delete',
      trigger: (row) => {
        return (
          <ButtonAndFormModal
            popup
            icon={<IconTrash />}
            buttonProps={{ basic: true, compact: true }}
            popupProps={{ position: 'top right' }}
            modalId={`keywords/delete/${row.id}`}
            modalTitle={`Delete "${row.name}"`}
            form={<DeleteKeywordForm krid={row.krid} onSubmit={handleDeleteKeyword} />}
          />
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(fetchKeywordsRepository(language))
  }, [])

  return (
    <>
      <header className="page-header">
        <h1>{language === 'spanish' ? 'Spanish Keyword Repository' : 'Keyword Repository'}</h1>

        <ButtonAndFormModal
          buttonLabel="Create Keyword"
          modalId="keywords/create"
          modalTitle="Create Keyword"
          buttonProps={{ primary: true }}
          form={<KeywordForm handleSubmit={handleUpdateKeyword} isCreate />}
        />
      </header>

      {keywords && (
        <Segment className="not-padded">
          <AdvancedTable
            loading={loading.keywords}
            rows={keywords}
            columns={columns}
            actions={actions}
            defaultOrderBy="name"
            pagination
            wrapColumnContent={false}
            stickyAction
          />
        </Segment>
      )}
    </>
  )
}

export default KeywordRepositoryPage
