// Action Types
import produce from 'immer'

const SELECT_ALERT_CONFIGURATION_ORGANIZATION = 'realtime/selectAlertConfigurationOrganization'
const SET_ALERT_CONFIGURATIONS = 'realtime/setAlertConfigurations'
const SEARCH_ALERT_CONFIGURATIONS = 'realtime/searchAlertConfigurations'
const SET_LOADING = 'realtime/setLoading'

export const setAlertConfigurations = (payload) => ({ type: SET_ALERT_CONFIGURATIONS, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })

// Initial State
export const initialState = {
  organizationId: null,
  alertConfigurations: [],
  searchText: '',
  loading: false,
}

// Reducer
export default function realtimeOrganizationAlertConfigurations(state = initialState, action) {
  return produce(state, (newState) => {
    switch (action.type) {
      case SELECT_ALERT_CONFIGURATION_ORGANIZATION:
        newState.organizationId = action.organizationId
        newState.alertConfigurations = null
        break

      case SET_ALERT_CONFIGURATIONS:
        newState.alertConfigurations = action.payload
        break

      case SEARCH_ALERT_CONFIGURATIONS:
        newState.searchText = action.searchText
        break

      case SET_LOADING:
        newState.loading = action.payload
        break

      default:
        break
    }
  })
}
