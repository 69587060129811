import React from 'react'
import { IconFilter, IconX, IconPlus } from '@tabler/icons-react'

import './FilterPillAction.scss'

export const FilterPillAction = ({
  handleRemoveFilter,
  hasSelection,
  isClearable,
  loading = false,
}) => {
  const determineIcon = () => {
    if (hasSelection) {
      if (isClearable) {
        return (
          <>
            <IconFilter className="hack-filter-icon" data-testid="view-filter-icon-with-clear" />
            <IconX className="hack-x-icon" data-testid="clear-filter-icon" />
          </>
        )
      }
      return <IconFilter data-testid="view-filter-icon" />
    }

    return <IconPlus data-testid="add-filter-icon" />
  }

  // Making this a div instead of a button because the console will complain about nested buttons
  return (
    <>
      {isClearable ? (
        <div
          disabled={loading}
          data-testid="clear-filter-button"
          className="ui button basic compact icon svg-button no-padding"
          onClick={(event) => {
            if (!loading) {
              handleRemoveFilter(event)
            }
          }}
        >
          {determineIcon()}
        </div>
      ) : (
        <div className="ui button basic svg-button no-padding borderless">{determineIcon()}</div>
      )}
    </>
  )
}
