import React from 'react'

export const AgentCallStatusHeader = () => {
  const columns = [
    { title: 'Name', sortable: true },
    { title: 'Call Status', sortable: true },
    { title: 'Actions' },
  ]

  return (
    <div className="agent-call-status-header">
      {columns.map((header) => {
        return <div key={header.title}>{header.title}</div>
      })}
    </div>
  )
}
