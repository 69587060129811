import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'

import Form from '@/components/Modal'
import ConfirmForm from '@/components/forms/ConfirmForm'
import { Banner } from '@/components/banners/Banner'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import {
  deleteChecklistItemVariation,
  fetchChecklistItemVariations,
} from '@/reducers/playbooks/playbook.actions'
import { formatTime } from '@/utils/helpers'
import { accessors, accessorSectionMap } from '../../playbook.helpers'

const columns = [
  {
    accessor: 'text',
    label: 'Variation',
    isSearchable: true,
  },
  {
    accessor: 'user',
    label: 'User',
    isSearchable: true,
  },
  {
    accessor: 'created_at',
    label: 'Created on',
    format: (value) => formatTime(value),
  },
]

const actions = (playbook, handleRestoreChecklistItem) => [
  {
    label: 'Restore to original',
    trigger: (row) => {
      const originalChecklistId = row.checklist_properties_uuid
      const originalChecklistText = playbook.body.checklist.entries[originalChecklistId]?.name

      return (
        <Form
          popupContent="Restore to original"
          header="Restore to original"
          icon="undo"
          muted
          modalSize="tiny"
          form={
            <ConfirmForm
              bodyText={
                <>
                  <p>Are you sure you want to restore this variation back to the default?</p>
                  <Banner>
                    <h3>Variation</h3>
                    <p>{row.text}</p>
                    <h3>Default</h3>
                    <p>{originalChecklistText}</p>
                  </Banner>
                  <p>This change will take place immediately.</p>
                </>
              }
              primaryButtonText="Restore"
              handleSubmit={() => handleRestoreChecklistItem(row.user_id)}
            />
          }
        />
      )
    },
  },
]

export const ChecklistVariationsEditor = () => {
  const dispatch = useDispatch()
  const { entryId } = useParams()
  const {
    checklistItemVariations,
    playbook,
    playbook: { current: isLatestPlaybookVersion },
  } = useSelector((state) => state.playbook)
  const accessor = accessorSectionMap[accessors.CHECKLIST]
  const entry = playbook.body[accessor].entries[entryId]
  const playbookUuid = playbook.cid
  const loading = !playbookUuid

  const handleRestoreChecklistItem = (userId) => {
    dispatch(deleteChecklistItemVariation(userId, entryId, playbookUuid))
  }

  useEffect(() => {
    if (playbookUuid && isLatestPlaybookVersion) {
      dispatch(fetchChecklistItemVariations(entryId, playbookUuid))
    }
  }, [playbookUuid])

  // Redirect if they land on the variation tab manually and it's not the latest version
  if (playbookUuid && !isLatestPlaybookVersion) {
    return <Redirect to={`/playbooks/${playbook.id}/checklist/${entryId}`} />
  }

  return (
    <div style={{ paddingTop: '2rem' }}>
      {!loading && <h2 className="variation-title">{entry?.name}</h2>}
      <Segment className="not-padded">
        <AdvancedTable
          loading={!playbookUuid}
          index="uuid"
          defaultOrderBy="created_at"
          defaultOrder="desc"
          columns={columns}
          rows={checklistItemVariations?.[entryId]}
          actions={actions(playbook, handleRestoreChecklistItem)}
          pagination
          wrapColumnContent={false}
        />
      </Segment>
    </div>
  )
}
