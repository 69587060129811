import React from 'react'
import { Form, Grid, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { IconInfoCircle } from '@tabler/icons-react'

import { MultiSelect } from '@/components/forms/MultiSelect'
import { setFilter } from '@/reducers/scorecards/scorecards.redux'

import './ViewScoresOptions.css'

export const ViewScoresOptions = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isQACopilot = location.pathname.startsWith('/qa-copilot')

  const { filters } = useSelector((state) => state.scorecards)
  const metricOptions = [
    {
      value: 'overall_scores',
      label: 'Overall Score',
      accessor: 'percentage_score',
    },
    {
      value: 'sections',
      label: 'Section Score',
    },
  ]

  if (!isQACopilot) {
    metricOptions.push({
      value: 'measures',
      label: 'Measure Score',
    })
  }

  metricOptions.push({
    value: 'criteria',
    label: 'Criteria Score',
  })

  const groupByOptions = [
    {
      value: 'agents',
      label: 'Agents',
    },
    {
      value: 'tags',
      label: 'Tags',
    },
    {
      value: 'calls',
      label: 'Calls',
    },
  ]

  const handleGroupBySelect = (value) => {
    if (value) {
      dispatch(setFilter('groupBy', value))
    } else {
      dispatch(setFilter('groupBy', []))
    }
  }

  const handleMetricSelect = (value) => {
    if (value) {
      dispatch(setFilter('metrics', value))
    } else {
      dispatch(setFilter('metrics', []))
    }
  }

  const isGroupByOptionDisabled = (value) => {
    if (isEmpty(filters.groupBy)) return false

    const disableMap = {
      agents: ['tags', 'calls'],
      tags: ['agents', 'calls'],
      calls: ['agents', 'tags'],
    }

    return filters.groupBy.some((option) => disableMap[option.value]?.includes(value.value))
  }

  const isMetricOptionDisabled = (value) => {
    if (!isEmpty(filters.scorecards) && filters.scorecards.length > 1) {
      const multiScorecardDisabledFilters = ['sections', 'measures', 'criteria']
      return multiScorecardDisabledFilters.includes(value.value)
    }
    return false
  }

  return (
    <div data-testid="view-score-options">
      <div className="scorecard-exports__content">
        <Grid.Row>
          <Grid.Column width={16} className="scorecard-exports_display-options-row">
            <h4 className="scorecard-exports_display-options">Display Options</h4>
            <div style={{ textAlign: 'left' }}>
              <Popup
                trigger={<IconInfoCircle className="icon-svg-large status-informative" />}
                content={
                  <div className="scorecard-exports_display-options-tooltip">
                    <h4 className="scorecard-exports_display-options">Display Options</h4>
                    <p>At a minimum, at least one display metric must be selected</p>
                    <p>
                      Display metrics are quantitative measurements. For example, the display
                      metric, “Overall Score” is the average of scores
                    </p>
                    <p>
                      Display groupings are attributes of your data. For example, grouping by
                      “Agents” will show agents as one selected column and each row as a unique
                      agent
                    </p>
                  </div>
                }
                hoverable
              />
            </div>
          </Grid.Column>
          <Grid.Column className="scorecard-exports_org-forms" width={16}>
            <Form>
              <Form.Field
                required
                className="scorecard-exports_org-forms-field"
                data-testid="advanced-exports-display-metrics-filter"
              >
                <label id="metrics-dropdown">Show Me Metrics</label>
                <div className="scorecard-exports_org-dropdown" data-testid="metrics-dropdown">
                  <MultiSelect
                    maxWidth
                    placeholder="Select Display Metric"
                    aria-labelledby="metrics-dropdown"
                    options={metricOptions}
                    value={filters?.metrics}
                    onChange={(value) => handleMetricSelect(value)}
                    isOptionDisabled={(option) => isMetricOptionDisabled(option)}
                  />
                </div>
              </Form.Field>
              <Form.Field required className="scorecard-exports_org-forms-field">
                <label id="group-by-dropdown">Grouped By</label>
                <div className="scorecard-exports_org-dropdown" data-testid="group-by-dropdown">
                  <MultiSelect
                    maxWidth
                    placeholder="Select Display Grouping"
                    aria-labelledby="group-by-dropdown"
                    options={groupByOptions}
                    value={filters?.groupBy}
                    onChange={(value) => handleGroupBySelect(value)}
                    isOptionDisabled={(option) => isGroupByOptionDisabled(option)}
                  />
                </div>
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </div>
    </div>
  )
}
