import { unauthenticateUser } from '../reducers/auth/currentUser.actions'
import { setRouteError } from '../reducers/errors/routeErrors.redux'
import { history } from '../entry'

/**
 * handleResponse takes the result of a fetch and handles it accordingly.
 *
 * @param {Object} response The result of a fetch
 * @param {Function} dispatch Redux dispatch function
 * @param {Function} callback A callback function that can
 * @param {*} details An optional details object for modifying this handler
 * @returns {Promise} A resolved Promise
 */
export const handleResponse = (response, dispatch, callback, details = {}) => {
  if (details.isCSV) {
    if (response.status === 422) {
      throw new Error('Not enough data')
    }
    return response.blob()
  }

  return new Promise((resolve, reject) => {
    let errorWithStatus = null
    switch (response.status) {
      case 200: // OK
        if (callback) callback()
        if (
          ['text/csv', 'text/csv; charset=utf-8'].includes(response.headers.get('Content-type'))
        ) {
          return resolve(response.body)
        }

        return resolve(response.json())
      case 201: // Created
        return resolve(response.json())
      case 204: // No Content
        return resolve()
      case 400: // Bad Request
        return response.json().then((res) => reject(res))
      case 401: // Unauthorized
        return reject(dispatch(unauthenticateUser({ history })))
      case 403: // Forbidden
        return response.json().then(({ message }) => {
          return reject(
            dispatch(setRouteError(message || 'User not authorized to perform that action.'))
          )
        })
      case 404: // Not Found
        return response.json().then((res) => reject(res))
      case 409: // Conflict
        return response.json().then((res) => reject(res))
      case 422: // Unprocessable Entity
        errorWithStatus = new Error(
          'Not enough data, try a different playbook or longer date range.'
        )
        Object.assign(errorWithStatus, { status: 422 })
        return reject(errorWithStatus)
      case 423: // Locked
        return reject(
          dispatch(
            setRouteError(
              'Another user saved a new version while you were editing. Please note your changes, navigate to the newest version, and try again.'
            )
          )
        )
      case 444: // Call ongoing
        return response.json().then((res) => reject(res))
      case 502: // Gateway Timeout
        return response.json().then((res) => reject(res))
      case 504: // Timeout
        return reject(
          new Error(
            'Connection timed out. Try this query over a shorter time range. If the problem persists, reach out to support@baltosoftware.com.'
          )
        )
      default:
        return reject(
          dispatch(
            setRouteError(
              'Something went wrong. If the problem persists, please reach out to support@baltosoftware.com.'
            )
          )
        )
    }
  })
}
