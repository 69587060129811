import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ChallengesFilters } from '@/views/Gamification/Challenges/ChallengesPage/ChallengesFilters'
import { applyChallengesFilters } from '@/reducers/gamification/gamification.actions'

import { ChallengeForm } from './ChallengeForms'
import './ChallengesPage.scss'

export const ChallengesPage = () => {
  const {
    data: { challengeTemplates },
  } = useSelector((state) => state.gamification)
  const { organizationid: userOrganizationId } = useSelector((state) => state.currentUser)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(applyChallengesFilters(userOrganizationId))
  }, [])

  return (
    <div data-testid="challenges-page">
      <h1>Challenges</h1>
      <ChallengesFilters />
      {challengeTemplates.map((template) => (
        <div key={template.id}>
          <ChallengeForm key={template.id} template={template} />
        </div>
      ))}
    </div>
  )
}

export default ChallengesPage
