import React from 'react'
import { Field } from 'formik'
import { Popup } from 'semantic-ui-react'

import { TextField, TextArea, Select, Checkbox, Slider } from '@/components/forms/formik'
import { FieldWithLabel } from '@/components/forms/formik/FieldWithLabel'
import { KeywordSelect } from '@/components/forms/formik/selects/KeywordSelect'

import { FieldWarning } from './FieldWarning'
import { SmartPhraseTrigger } from '../modules/categories/SmartPhraseTrigger'

import { sides, accessors } from '../playbook.helpers'

export const PlaybookEntryFormTriggers = ({
  loading,
  values,
  dispatch,
  triggerType,
  formikProps,
  readOnly,
  sideDisabled,
  sideHidden,
  triggerHidden,
  entryId,
  accessor,
  updateTriggerWithAi,
}) => {
  if (!triggerType || triggerType === 'none') {
    return null
  }

  return (
    <>
      {!sideHidden && (
        <Popup
          content="This category is designed for this side of the conversation."
          position="right center"
          disabled={!sideDisabled}
          trigger={
            <div className="field">
              <Field
                required
                label="Side"
                name="trigger.side"
                component={Select}
                options={sides}
                isClearable={false}
                disabled={sideDisabled}
              />
            </div>
          }
        />
      )}
      {triggerType === 'transcription_classifier' && (
        <>
          <FieldWithLabel
            required
            label="Topics"
            name="trigger.krid"
            component={KeywordSelect}
            isClearable={false}
            disabled={readOnly}
          />
          {!readOnly && <FieldWarning accessor={accessor} field="trigger.krid" entryId={entryId} />}
        </>
      )}
      {triggerType === 'keywords' && (
        <>
          <Field
            required
            label="Custom Keywords (no duplicates)"
            name="trigger.phrases"
            placeholder="Please enter your words/phrases, separated by commas"
            component={TextArea}
            disabled={readOnly}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
              }
            }}
          />
          {!readOnly && (
            <FieldWarning
              accessor={accessor}
              field="trigger.phrases"
              entryId={entryId}
              updateTriggerWithAi={updateTriggerWithAi}
            />
          )}
        </>
      )}
      {triggerType === 'smart_phrases' && (
        <SmartPhraseTrigger
          loading={loading}
          values={values}
          readOnly={readOnly}
          dispatch={dispatch}
          formikProps={formikProps}
        />
      )}
      {triggerType === 'talk_ratio' && (
        <Field
          required
          label="Talk Ratio"
          name="trigger.ratio"
          placeholder="25"
          component={TextField}
          type="number"
          min="1"
          disabled={readOnly}
        />
      )}
      {!triggerHidden && (
        <Field
          label="Trigger Active"
          name="trigger"
          component={Slider}
          formikProps={formikProps}
          disabled={readOnly}
        />
      )}
      {(accessor === accessors.DYNAMIC_PROMPT || accessor === accessors.POSTCALL) && (
        <Field
          label="Trigger Once"
          name="trigger.max_triggers"
          component={Checkbox}
          inline
          disabled={readOnly}
        />
      )}
    </>
  )
}
