import React from 'react'
import { Button, Form, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAdvancedExportsPreviewData } from '@/reducers/realtime/advancedExports/advancedExports.actions'
import { cloneDeep, isEmpty } from 'lodash'
import { setData } from '@/reducers/realtime/advancedExports/advancedExports.redux'
import { RTCDisplayGroupingOptions } from '@/utils/constants'

export const AdvancedExportsPreviewButton = () => {
  const dispatch = useDispatch()
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const isBaltoAdmin = organizationId === 1
  const { filters } = useSelector((state) => state.realtimeCoaching)
  const { loading } = useSelector((state) => state.realtimeAdvancedExports)
  const isPreviewButtonEnabled =
    (!isEmpty(filters.displayGroupings) && filters.dateRangeIsValid) || loading.table
  const disabledText = isEmpty(filters.displayGroupings)
    ? 'At least one grouped by variable must be selected'
    : 'Please select a valid date range'

  const displayMetrics = filters.displayMetrics.map((filter) => filter.value)
  const displayGroupings = filters.displayGroupings.map((filter) => filter.value)
  const displayFilters = filters
  const combineAndSortColumns = (groupings, metrics) => {
    return [
      ...groupings.sort(
        (firstGrouping, secondGrouping) =>
          RTCDisplayGroupingOptions.findIndex(
            (currentGrouping) => currentGrouping.value === firstGrouping.value
          ) -
          RTCDisplayGroupingOptions.findIndex(
            (currentGrouping) => currentGrouping.value === secondGrouping.value
          )
      ),
      ...metrics,
    ]
  }

  const submitClick = () => {
    dispatch(
      fetchAdvancedExportsPreviewData(
        isBaltoAdmin ? filters.organization : organizationId,
        displayMetrics,
        displayGroupings,
        displayFilters
      )
    )
    const columnsToDisplay = combineAndSortColumns(
      cloneDeep(filters.displayGroupings),
      cloneDeep(filters.displayMetrics)
    )
    dispatch(setData({ columns: columnsToDisplay }))
  }

  return (
    <div className="rtc-exports-preview-button">
      <Form.Field>
        <Popup
          disabled={isPreviewButtonEnabled}
          content={disabledText}
          flowing
          data-testid="preview-button-hover-text"
          trigger={
            <span>
              <Button
                primary
                onClick={submitClick}
                type="submit"
                data-testid="preview-button"
                disabled={!isPreviewButtonEnabled || loading.table}
                loading={loading.table}
              >
                Preview
              </Button>
            </span>
          }
        />
      </Form.Field>
    </div>
  )
}
