import React from 'react'
import classNames from 'classnames'
import { Loader, Popup } from 'semantic-ui-react'
import { IconArrowUp, IconArrowDown } from '@tabler/icons-react'

import { truncateAgentName } from '@/utils/helpers'

import { Pill } from '../pills/Pill'

import './AnalyticsCard.scss'

const determineArrow = (variant, arrowUp) => {
  if (variant === 'neutral') return null

  if (arrowUp) {
    return <IconArrowUp data-testid="up-arrow" />
  }
  return <IconArrowDown data-testid="down-arrow" />
}

export const AnalyticsCard = ({
  data,
  variant = 'neutral',
  description,
  dateDescription = 'vs. previous period',
  hideDateDescription = false,
  percent,
  arrowUp,
  loading,
  comparisonsLoading,
  testId = 'analytics-card',
  displayDataClass = 'analytics-card-data',
  enableAnalyticsCardPopup = false,
}) => {
  const displayCardData = (data) => {
    if (enableAnalyticsCardPopup) {
      return (
        <Popup
          content={data}
          trigger={<div className="analytics-data-truncate-name">{truncateAgentName(data)}</div>}
        />
      )
    }
    return data
  }

  const isPositive = variant === 'positive'
  const isNegative = variant === 'negative'

  return (
    <div data-testid={testId} className={classNames('analytics-card', percent && variant)}>
      <div className="analytics-card-content">
        <div data-testid={`${testId}-description`} className="sub-characters">
          {description}
        </div>
        {comparisonsLoading ? (
          <Loader inline active />
        ) : (
          <div data-testid={`${testId}-percent-change-container`} className="percent-changed-block">
            <div className="analytics-data-percent-block">
              <div className={displayDataClass} data-testid={`${testId}-value`}>
                {loading ? <Loader inline active /> : displayCardData(data)}
              </div>
              {(percent === 0 || percent) && (
                <Pill
                  emphasized={variant === 'neutral'}
                  success={isPositive}
                  critical={isNegative}
                  alignIcon="right"
                  icon={determineArrow(variant, arrowUp)}
                  data-testid={`${testId}-percent-change`}
                >
                  <span data-testid={`${testId}-percent-change-text`}>
                    {parseInt(percent, 10) !== 0
                      ? `${isPositive ? '+' : ''}${isNegative ? '+' : ''}${percent}${variant !== 'neutral' ? '%' : ''}`
                      : 'No Change'}
                  </span>
                </Pill>
              )}
            </div>
            {!hideDateDescription && (
              <div
                data-testid={`${testId}-date-description`}
                className="sub-characters-description"
              >
                {dateDescription}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
