import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { QACopilotScorecardCriteriaPrompt } from '@/views/QACopilot/components/QACopilotScorecardCriteriaPrompt'
import { addTemporaryPromptHelper } from '@/reducers/prompts/prompts.helpers'
import { QACopilotScorecardSection } from './QACopilotScorecardSection'
import { QACopilotAddCriteria } from './QACopilotAddCriteria'
import { QACopilotAddSection } from './QACopilotAddSection'

export const QACopilotEditor = () => {
  const [collapsedSections, setCollapsedSections] = useState([])
  const { temporaryCriteria, scorecardConfig } = useSelector((state) => state.qaCopilot)
  const { temporaryPrompt, prompts } = useSelector((state) => state.prompts)

  if (!scorecardConfig?.sections) {
    return null
  }

  const setCollapsed = (section) => {
    if (collapsedSections.includes(section.ssid)) {
      setCollapsedSections(collapsedSections.filter((ssid) => ssid !== section.ssid))
    } else {
      setCollapsedSections([...collapsedSections, section.ssid])
    }
  }

  return (
    <div className="qa-copilot-editor">
      {scorecardConfig.sections.map((section) => {
        const scorecardCriteriaList = section.measures ? section.measures[0]?.criteria || [] : []
        const isCollapsed = collapsedSections.some((ssid) => ssid === section.ssid)

        return (
          <section className="qa-copilot-editor__scorecard-section" key={section.ssid}>
            <QACopilotScorecardSection
              section={section}
              collapsed={isCollapsed}
              setCollapsed={() => setCollapsed(section)}
            />

            {!isCollapsed && (
              <div className="qa-copilot-editor__scorecard-criteria-list">
                {isEmpty(scorecardCriteriaList) ? (
                  <QACopilotAddCriteria section={section} />
                ) : (
                  <>
                    {scorecardCriteriaList.map((criteria) => {
                      return (
                        <QACopilotScorecardCriteriaPrompt
                          // prompt_id is null for new items, scid is set for all new/edited items
                          // default to temp values since could be null if non-admin created scorecard
                          prompt={
                            prompts.filter(
                              (prompt) =>
                                (criteria.prompt_id && criteria.prompt_id === prompt.id) ||
                                criteria.scid === prompt.scid
                            )[0] || addTemporaryPromptHelper(criteria.scid)
                          }
                          criteria={criteria}
                          section={section}
                          key={criteria.scid}
                        />
                      )
                    })}
                    <QACopilotAddCriteria section={section} />
                  </>
                )}
                {temporaryPrompt &&
                  temporaryCriteria &&
                  section.ssid === temporaryCriteria.ssid && (
                    <QACopilotScorecardCriteriaPrompt
                      prompt={temporaryPrompt}
                      criteria={temporaryCriteria}
                      section={section}
                      key={temporaryCriteria.scid}
                    />
                  )}
              </div>
            )}
          </section>
        )
      })}
      <QACopilotAddSection />
    </div>
  )
}
