import React from 'react'
import { Link } from 'react-router-dom'
import { IconArrowUpRight } from '@tabler/icons-react'
import classNames from 'classnames'

import './ExternalLink.scss'

// Don't wrap text for the link by default, but allow it to be wrapped if the content is long
export const ExternalLink = ({ url, label, id, wrap = false, className, ...props }) => {
  const params = new URLSearchParams(url?.split('?')[1])
  const callIds = params.getAll('call_ids')
  // in testing 440 was too many but 390 was okay, so going 200 to be safe
  const maxCallIdsForLink = 200

  const routeCallExplorerLink = (url) => {
    if (callIds?.length === 1) {
      const callId = callIds[0]
      params.delete('call_ids')
      return `/call-explorer/${callId}?${params.toString()}`
    }

    if (callIds.length > maxCallIdsForLink) {
      params.delete('call_ids')
      return `/call-explorer?${params.toString()}`
    }

    return url
  }

  const processedUrl = url?.includes('/call-explorer') ? routeCallExplorerLink(url) : url

  return (
    <Link
      target="_blank"
      to={processedUrl}
      data-testid={id ? `external-link-${id}` : 'external-link'}
      className={classNames('external-link', className)}
      style={{ whiteSpace: wrap ? 'normal' : 'nowrap' }}
      {...props}
      onClick={(event) => {
        // do local storage set on click, that way it will set whichever callIds you click
        // not whichever ExternalLink happened to render last
        if (callIds?.length > maxCallIdsForLink) {
          localStorage.setItem('linkedCallIds', JSON.stringify(callIds))
        }
        if (props.onClick) {
          props.onClick(event)
        }
      }}
    >
      <span>{label}</span>
      <IconArrowUpRight className="external-link-icon" />
    </Link>
  )
}
