import React, { useEffect, useMemo } from 'react'
import { Button, Form } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { isEmpty } from 'lodash'

import { DateRangePickerReusable } from '@/components/datePickers/DateRangePickerReusable'
import {
  formatTime,
  getOrganizationOptions,
  getHierarchyOrganizationOptions,
} from '@/utils/helpers'
import { setOrganization, setFilter } from '@/reducers/qa-copilot/qa-copilot.redux'
import {
  fetchCopilotFilterData,
  fetchCopilotDashboardData,
  fetchInitialCopilotDashboardData,
} from '@/reducers/qa-copilot/qa-copilot.actions'
import { OrganizationFilter } from '@/components/filters/OrganizationFilter'
import { WinsFilter } from '@/components/filters/WinsFilter'
import { MultiFilter } from '@/components/filters/MultiFilter'
import { PartialScoresFilter } from '@/components/filters/PartialScoresFilter'

export const CopilotFilters = () => {
  const dispatch = useDispatch()
  const organizations = useSelector((state) => state.organizations)
  const { data, filters, loading } = useSelector((state) => state.qaCopilot)
  const { organizationid: currentUserOrgId, hierarchy_manager } = useSelector(
    (state) => state.currentUser
  )
  const { flattenedUserOrgHierarchy } = useSelector((state) => state.orgHierarchy)
  const location = useLocation()
  const isBaltoAdmin = currentUserOrgId === 1

  const { search } = location
  const params = queryString.parse(search)

  const showManagerHierarchyOrgDropdown = !isBaltoAdmin && hierarchy_manager
  const hasOrganizationDropdown = isBaltoAdmin || showManagerHierarchyOrgDropdown
  const organizationOptions = useMemo(
    () => getOrganizationOptions(organizations, true),
    [organizations?.length]
  )
  const hierarchyOrganizationOptions = useMemo(
    () => getHierarchyOrganizationOptions(flattenedUserOrgHierarchy),
    [flattenedUserOrgHierarchy?.length]
  )

  const dispositionsHidden = (organizationId) => {
    // Always show all dispositions for Balto Admin and regular user
    if (!showManagerHierarchyOrgDropdown) {
      return false
    }

    // TODO: Remove this whole function when hierarchy managers can view their sub org dispositions
    return showManagerHierarchyOrgDropdown && organizationId !== currentUserOrgId
  }

  const handleUpdateRange = (range) => {
    const startDate = formatTime(range.selection.startDate, 'yyyy-MM-DDT00:00:00Z')
    const endDate = formatTime(range.selection.endDate, 'yyyy-MM-DDT23:59:59Z')

    dispatch(setFilter({ startDate, endDate }))
  }

  const handleFilterChange = (value) => {
    dispatch(setFilter(value))
  }

  const filterIsDisabled = () => {
    // Balto Admin and Hierarchy Manager require an organization to be selected
    if (hasOrganizationDropdown && !filters.organizationId) {
      return true
    }

    let isLoading = false
    Object.values(loading).forEach((loader) => {
      if (loader) {
        isLoading = true
      }
    })

    return isLoading
  }

  const handleOrgSelect = (option, action) => {
    if (action.action === 'clear') {
      dispatch(setOrganization(null))
    } else {
      dispatch(setOrganization(option.value))
      dispatch(fetchCopilotFilterData(option.value, dispositionsHidden(option.value)))
    }
  }

  useEffect(() => {
    const loadInitialData = async () => {
      dispatch(
        fetchInitialCopilotDashboardData(
          hasOrganizationDropdown,
          currentUserOrgId,
          params,
          dispositionsHidden
        )
      )
    }

    loadInitialData()

    // when you leave this page, set this back to your user until the dropdown for hierarchy managers is everywhere
    return () => {
      if (showManagerHierarchyOrgDropdown && filters.organizationId !== currentUserOrgId) {
        dispatch(setOrganization(currentUserOrgId))
      }
    }
  }, [])

  return (
    <Form className="filter-form copilot-filter-form">
      <div>
        <div className="filter-grid">
          <Form.Field>
            <label>Date Range</label>
            <DateRangePickerReusable
              data-testid="date-range-picker"
              onChange={handleUpdateRange}
              startDate={filters.startDate}
              endDate={filters.endDate}
            />
          </Form.Field>
          {hasOrganizationDropdown && (
            <OrganizationFilter
              dataTestId={
                showManagerHierarchyOrgDropdown
                  ? 'hierarchy-organization-dropdown'
                  : 'organization-dropdown'
              }
              options={
                showManagerHierarchyOrgDropdown ? hierarchyOrganizationOptions : organizationOptions
              }
              value={filters.organizationId}
              onChange={handleOrgSelect}
            />
          )}
          <MultiFilter
            label="Scorecards"
            accessor="scorecards"
            store={{ data, loading, filters }}
            handler={handleFilterChange}
          />
          <MultiFilter
            label="Agents"
            accessor="agents"
            store={{ data, loading, filters }}
            handler={handleFilterChange}
          />
          <MultiFilter
            label="Tags"
            accessor="tags"
            store={{ data, loading, filters }}
            handler={handleFilterChange}
          />
          <WinsFilter
            filters={filters}
            onChange={(option, action) => {
              dispatch(setFilter({ isWin: action.action === 'clear' ? null : option.value }))
            }}
          />
          <PartialScoresFilter
            filters={filters}
            onChange={(option, action) => {
              dispatch(setFilter({ scoredStatus: action.action === 'clear' ? null : option.value }))
            }}
          />
          {!isEmpty(data.dispositions) && !dispositionsHidden(filters.organizationId) && (
            <MultiFilter
              label="Dispositions"
              accessor="dispositions"
              store={{ data, loading, filters }}
              handler={handleFilterChange}
            />
          )}
        </div>
      </div>
      <div className="filter-buttons">
        <Form.Field>
          <label className="visibility-hidden">&nbsp;</label>
          <div>
            <Button
              primary
              className="no-wrap"
              type="submit"
              onClick={() =>
                dispatch(fetchCopilotDashboardData(filters.organizationId || currentUserOrgId))
              }
              disabled={filterIsDisabled()}
              content="Apply Filters"
              data-testid="submit-filters-button"
            />
          </div>
        </Form.Field>
      </div>
    </Form>
  )
}
