import React from 'react'
import { useSelector } from 'react-redux'
import pluralize from 'pluralize'

import { AnalyticsCard } from '../../components/cards/AnalyticsCard'
import {
  formatPercent,
  formatSeconds,
  getChecklistPercent,
  getPercentNumerical,
  compareToPreviousRange,
  getCardVariant,
  getCardVariantReverse,
} from '../../utils/helpers'

export const AnalyticsCards = () => {
  const { data, loading } = useSelector((state) => state.analytics)

  const activeUserPercent = getPercentNumerical(data.activeUserCount, data.totalUserCount)
  const previousActiveUserPercent = getPercentNumerical(
    data.previousActiveUserCount,
    data.previousTotalUserCount
  )

  const checklistUsage = getChecklistPercent(data.checklistUsage)
  const previousChecklistUsage = getChecklistPercent(data.previousChecklistUsage)

  return (
    <div className="analytics-card-grid" data-testid="analytics-cards">
      <AnalyticsCard
        loading={loading.overviewPage}
        comparisonsLoading={loading.overviewPageComparisons}
        arrowUp={data.callsCount > data.previousCallsCount}
        data={Number(data.callsCount).toLocaleString()}
        description="Total Calls"
        dateDescription="vs. previous period"
        percent={compareToPreviousRange(data.callsCount, data.previousCallsCount)}
        variant={getCardVariant(data.callsCount, data.previousCallsCount)}
      />
      <AnalyticsCard
        loading={loading.overviewPage}
        comparisonsLoading={loading.overviewPageComparisons}
        arrowUp={checklistUsage > previousChecklistUsage}
        data={formatPercent(checklistUsage)}
        dateDescription="vs. previous period"
        description="Checklist usage"
        percent={compareToPreviousRange(checklistUsage, previousChecklistUsage)}
        variant={getCardVariant(checklistUsage, previousChecklistUsage)}
      />
      <AnalyticsCard
        loading={loading.overviewPage}
        comparisonsLoading={loading.overviewPageComparisons}
        arrowUp={data.averageHandleTime > data.previousAverageHandleTime}
        data={formatSeconds(data.averageHandleTime)}
        dateDescription="vs. previous period"
        description="Average Handle Time"
        percent={compareToPreviousRange(data.averageHandleTime, data.previousAverageHandleTime)}
        variant={getCardVariantReverse(data.averageHandleTime, data.previousAverageHandleTime)}
      />
      <AnalyticsCard
        loading={loading.overviewPage}
        comparisonsLoading={loading.overviewPageComparisons}
        arrowUp={data.winCount > data.previousWinCount}
        data={Number(data.winCount).toLocaleString()}
        dateDescription="vs. previous period"
        description={pluralize('Win', data.winCount)}
        percent={compareToPreviousRange(data.winCount, data.previousWinCount)}
        variant={getCardVariant(data.winCount, data.previousWinCount)}
      />
      <AnalyticsCard
        loading={loading.overviewPage}
        comparisonsLoading={loading.overviewPageComparisons}
        arrowUp={data.winPercent > data.previousWinPercent}
        data={formatPercent(data.winPercent)}
        dateDescription="vs. previous period"
        description={pluralize('Win', data.winPercent)}
        percent={compareToPreviousRange(data.winPercent, data.previousWinPercent)}
        variant={getCardVariant(data.winPercent, data.previousWinPercent)}
      />
      <AnalyticsCard
        loading={loading.overviewPage}
        comparisonsLoading={loading.overviewPageComparisons}
        arrowUp={activeUserPercent > previousActiveUserPercent}
        data={formatPercent(activeUserPercent)}
        dateDescription="vs. previous period"
        description="Active agents"
        percent={compareToPreviousRange(activeUserPercent, previousActiveUserPercent)}
        variant={getCardVariant(activeUserPercent, previousActiveUserPercent)}
      />
    </div>
  )
}
