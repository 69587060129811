import React, { useState } from 'react'

import { useSelector } from 'react-redux'
import { formatTime } from '@/utils/helpers'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { determineAccessForUser, processTags } from '../helpers'
import { buildExistingValuesForUserForm, buildSchemaForUserForm } from '../organization.schema'
import { DeleteUserModal } from './DeleteUserModal'
import { BaltoCloudAccessModal } from './BaltoCloudAccessModal'

export const UserForm = ({
  configs,
  handleSubmitUser,
  modalClose,
  qaEnabled,
  qaCopilotEnabled,
  realtimeCoachingAccessEnabled,
  orgDesktopEnterpriseEnabled,
  tagCategories,
  tags,
  user,
  flags,
}) => {
  const existingValues = user
    ? buildExistingValuesForUserForm(user, tagCategories)
    : { deleted: false, desktop_enterprise_enabled: false }
  const [isDeleted, setIsDeleted] = useState(false)
  const [hasBaltoCloudAccess, setHasBaltoCloudAccess] = useState(false)
  const [editedUser, setEditedUser] = useState(null)
  const { currentUser } = useSelector((state) => state)
  const isBaltoAdmin = currentUser.organizationid === 1
  const isHierarchyManager = currentUser.hierarchy_manager

  const saveUser = (userToSave) => {
    const savedUser = { ...userToSave }

    savedUser.id = user ? user.id : null
    savedUser.tags = processTags(userToSave, tags)

    handleSubmitUser(savedUser)
  }

  const handleSubmit = (values, { setSubmitting }) => {
    const mutatedUser = determineAccessForUser(values)
    setEditedUser(mutatedUser)

    if (mutatedUser.deleted) {
      setIsDeleted((prevState) => !prevState)
    } else if (mutatedUser.cloud_access && !user?.password) {
      setHasBaltoCloudAccess((prevState) => !prevState)
    } else {
      saveUser(mutatedUser)
      setSubmitting(false)
      modalClose()
    }
  }

  const handleClose = (e) => {
    modalClose(e)
  }

  const schema = buildSchemaForUserForm(
    configs,
    qaEnabled,
    qaCopilotEnabled,
    realtimeCoachingAccessEnabled,
    tagCategories,
    tags,
    orgDesktopEnterpriseEnabled,
    flags,
    isBaltoAdmin,
    isHierarchyManager
  )

  return (
    <>
      {isDeleted && <DeleteUserModal user={editedUser} deleteUser={saveUser} />}
      {hasBaltoCloudAccess && <BaltoCloudAccessModal user={editedUser} saveUser={saveUser} />}
      {!isDeleted && !hasBaltoCloudAccess && (
        <>
          <AbstractForm
            schema={schema}
            buttonLabel="Save"
            onSubmit={handleSubmit}
            onClose={handleClose}
            existingValues={existingValues}
            isModal
          />
          {user && (
            <div
              data-testid="create-date"
              style={{
                display: 'flex',
                alignItems: 'middle',
                justifyContent: 'space-between',
                background: 'var(--dark-knight-50)',
                margin: '0 -1.5rem -1.5rem',
                padding: '1.5rem',
              }}
            >
              <div>
                <div>Created:</div>
                <div>by {user.created_by || 'Balto Admin'}</div>
                <div>on {user.created_on ? formatTime(user.created_on) : 'unknown date'}</div>
              </div>
              {user.last_modified_by && (
                <div data-testid="last-modified-date">
                  <div>Last Modified:</div>
                  <div>by {user.last_modified_by}</div>
                  <div>
                    on {user.last_modified_on ? formatTime(user.last_modified_on) : 'unknown date'}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default UserForm
