import React, { useEffect } from 'react'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { Form } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import {
  fetchAlertNames,
  fetchTriggersByAlerts,
} from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'

export const OrgAlertNameDropdown = ({ useFilters = false }) => {
  const dispatch = useDispatch()
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const { data, filters, loading } = useSelector((state) => state.realtimeCoaching)

  useEffect(() => {
    if (isEmpty(data.alertNames)) {
      dispatch(fetchAlertNames())
    }
  }, [])

  // Update Triggers dropdown when alert name dropdown changes
  const onFilterChange = (values) => {
    dispatch(setFilter({ alertNames: values }))
    const acvIds = values.map(({ acvId }) => acvId)
    dispatch(fetchTriggersByAlerts(filters.organization || organizationId, acvIds))
  }

  const containsCommonCid = (list1, list2) => {
    return list1.some((item) => list2.includes(item))
  }

  const applyFilters = (alertNames) => {
    if (!isEmpty(filters.playbooksAdvancedExports)) {
      const selectedCids = filters.playbooksAdvancedExports.map((playbook) => playbook.value)

      // Filters by selected alert playbook cids
      alertNames = alertNames.map((alertName) => {
        return {
          label: alertName.label,
          options: alertName.options.filter((option) =>
            containsCommonCid(option.cids, selectedCids)
          ),
        }
      })
      alertNames = alertNames.filter((alertName) => !isEmpty(alertName.options))
    }

    // Filters by selected managers
    if (!isEmpty(filters.managers)) {
      alertNames = alertNames.filter((alertGroup) => {
        return filters.managers.some((manager) => manager.label === alertGroup.label)
      })
    }

    // Filters by selected alert types
    if (!isEmpty(filters.alertTypes)) {
      alertNames = alertNames.map((alertName) => {
        return {
          label: alertName.label,
          options: alertName.options.filter((option) =>
            filters.alertTypes.some((alertType) => alertType.value === option.alertType)
          ),
        }
      })
    }

    return alertNames
  }

  return (
    <Form.Field data-testid="advanced-exports-alert-name-dropdown">
      <label>Alert Names</label>
      <MultiSelect
        placeholderPill="All Alerts"
        options={useFilters ? applyFilters(data.alertNames) : data.alertNames}
        optionsLength={data.alertNames.length}
        value={filters.alertNames}
        loading={loading.alertNames}
        onChange={(values) => onFilterChange(values)}
      />
    </Form.Field>
  )
}
