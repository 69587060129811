import React from 'react'
import { truncate } from 'lodash'
import { IconX } from '@tabler/icons-react'
import classNames from 'classnames'

import './FilePreview.scss'

export const FilePreview = ({ name, file, dark, formikProps }) => {
  if (!file) {
    return null
  }
  const fileExtension = file.type?.split('/')[1]

  return (
    <div className={classNames('file-preview', { dark })}>
      <div
        className="file-clear"
        onClick={(e) => {
          e.stopPropagation()

          formikProps.setFieldValue(name, null)
        }}
      >
        <IconX className="icon-svg" />
      </div>
      <div className="file-type">.{fileExtension}</div>
      <div className="file-name">
        <h3>{truncate(file.name, 30)}</h3>
      </div>
    </div>
  )
}
