import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Popup } from 'semantic-ui-react'
import { debounce } from 'lodash'
import classNames from 'classnames'
import uuid from 'uuid/v4'
import { IconAlertTriangle, IconCheck, IconPlus, IconX } from '@tabler/icons-react'

import {
  fetchPlaybookEntryWarnings,
  fetchPlaybookSectionWarnings,
} from '@/reducers/playbooks/playbook.actions'
import { getReadableWarning } from '../playbookWarning.helpers'
import { accessors } from '../playbook.helpers'

export const PlaybookInlineEntry = ({ createEntry, useIcons, label, type, isDraggable }) => {
  const { warnings } = useSelector((state) => state.playbook)
  const entryWarnings = warnings[accessors.CHECKLIST]?.create

  const dispatch = useDispatch()
  const [inlineEntry, setInlineEntry] = useState({ editing: false, value: '' })

  const getPlaybookEntryWarnings = (values) => {
    // Only used for checklist right now
    dispatch(fetchPlaybookEntryWarnings(accessors.CHECKLIST, values, 'create'))
  }

  const debouncedGetWarnings = useCallback(
    debounce(getPlaybookEntryWarnings, 1000, { leading: false, trailing: true }),
    []
  )

  const handleCreateInlineEntry = () => {
    setInlineEntry({ editing: true, value: '' })
  }

  const handleCancelInlineEntry = () => {
    setInlineEntry({ editing: false, value: '' })
    debouncedGetWarnings.cancel()
    dispatch(fetchPlaybookSectionWarnings(accessors.CHECKLIST))
  }

  const handleUpdateInlineEntry = (event) => {
    setInlineEntry({ editing: true, value: event.target.value })

    if (type !== 'category') {
      debouncedGetWarnings({
        name: event.target.value,
        trigger: { side: 'me', type: 'verbatim' },
      })
    }
  }

  const handleSubmitInlineEntry = (event) => {
    event.preventDefault()

    if (type === 'category') {
      dispatch(createEntry({ entry: { name: inlineEntry.value, order: [], entries: {} } }))
    } else {
      // Only used for checklist right now
      debouncedGetWarnings.cancel()
      dispatch(
        createEntry({
          entry: { name: inlineEntry.value, trigger: { side: 'me', type: 'verbatim' } },
        })
      )
      dispatch(fetchPlaybookSectionWarnings(accessors.CHECKLIST))
    }

    setInlineEntry({ editing: false, value: '' })
  }

  return (
    <div className={classNames({ 'draggable-item': isDraggable })}>
      {isDraggable && <div />}
      {!inlineEntry.editing ? (
        <Button
          data-testid="inline-create-entry-button"
          icon
          primary
          className="svg-button"
          onClick={handleCreateInlineEntry}
        >
          <IconPlus />
          {label}
        </Button>
      ) : (
        <div
          className={classNames({
            'playbook-warnings-container': type === 'entry',
          })}
        >
          <form
            className={classNames({
              'playbook-entry': type === 'entry',
              'vertical-center small-gap': type === 'category',
            })}
            onSubmit={handleSubmitInlineEntry}
          >
            <Input
              autoFocus
              type="text"
              value={inlineEntry.value}
              onChange={handleUpdateInlineEntry}
              className="w-100"
            />
            <div className="vertical-center small-gap">
              <Button
                type="button"
                secondary
                onClick={handleCancelInlineEntry}
                icon={useIcons}
                className="svg-button"
              >
                {useIcons ? <IconX /> : 'Cancel'}
              </Button>
              <Button
                data-testid="inline-submit-button"
                type="submit"
                primary
                disabled={!inlineEntry.value}
                icon={useIcons}
                className={classNames({ 'svg-button': useIcons })}
              >
                {useIcons ? <IconCheck /> : 'Add'}
              </Button>
            </div>
          </form>
          {entryWarnings?.warnings && (
            <Popup
              content={
                <ul className="playbook-warnings-popup">
                  {entryWarnings?.warnings &&
                    entryWarnings.warnings.map((warning) => {
                      return <li key={uuid()}>{getReadableWarning(warning)}</li>
                    })}
                </ul>
              }
              trigger={<IconAlertTriangle className="status-warning" />}
            />
          )}
        </div>
      )}
    </div>
  )
}
