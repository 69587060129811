import { useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import classNames from 'classnames'
import React from 'react'

export const BadgeWizardCard = ({ badge, onClick, disabled }) => {
  const { loading } = useSelector((state) => state.gamification)
  const isLoading = loading[`specialtyBadge--${badge.id}`]

  if (isLoading) {
    return (
      <div className="badge-wizard__badge-container">
        <Loader active inline className="badge-wizard__badge-container-loader" />
      </div>
    )
  }

  return (
    <div
      className={classNames('badge-wizard__badge-container', disabled && 'disabled')}
      onClick={() => (disabled ? null : onClick(badge))}
    >
      <img
        className={classNames('badge-wizard__badge-image', disabled && 'grayscale')}
        src={badge.imageUrl}
        alt={`${badge.name} badge`}
      />
      <div className="badge-wizard__badge-title">{badge.name}</div>
    </div>
  )
}
