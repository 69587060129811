import { isEqual } from 'lodash'

export const checkOrderDecklist = (insights, decklist) => {
  const orderSuggestion = []
  insights.forEach((insight) => {
    const matchingItem = Object.keys(decklist.entries).filter(
      (x) => decklist.entries[x].name === insight.item
    )
    if (matchingItem.length > 0) orderSuggestion.push(matchingItem[0])
  })
  const existingOrder = decklist.order.filter((item) => orderSuggestion.includes(item))
  return [isEqual(existingOrder, orderSuggestion), orderSuggestion]
}
