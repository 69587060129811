import React from 'react'
import { ResponsiveBarCanvas } from '@nivo/bar'

import { chartColors } from '../../utils/constants'
import { formatPercent } from '../../utils/helpers'

const BarChart = ({ data, keys, indexBy, isPercent, layout = 'vertical', layers, ...props }) => (
  <ResponsiveBarCanvas
    data={data}
    keys={keys}
    indexBy={indexBy}
    padding={0.3}
    margin={{ top: 50, right: 50, bottom: 50, left: 70 }}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    valueFormat={{ format: '', enabled: false }}
    colors={chartColors}
    colorBy="index"
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisLeft={null}
    axisRight={null}
    axisTop={null}
    labelFormat={(v) => (isPercent ? formatPercent(v) : Number(v).toLocaleString())}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor="white"
    layout={layout}
    enableLabel={false}
    enableGridX={false}
    enableGridY={false}
    motionConfig="stiff"
    animate={false}
    layers={layers}
    {...props}
  />
)

export default BarChart
