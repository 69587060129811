import React, { useState } from 'react'
import './secondaryNavRouter.css'
import { useHistory } from 'react-router-dom'

export const SecondaryNavbar = ({ pages, currentPageLabel, changePage }) => {
  const tabBar = pages.map((page) => (
    <a
      className={`page-tab__navbar-link ${page.label === currentPageLabel ? 'active' : ''}`}
      data-testid={`secondary-nav-link-${page.label}`}
      onClick={() => changePage(page.label, page.url)}
      key={page.label}
    >
      {page.label}
    </a>
  ))
  return (
    <div className="page-tab__navbar-container" data-testid="secondary-nav-link">
      {tabBar}
    </div>
  )
}

export const SecondaryNavRouter = ({ pages, activePage = pages[0].label }) => {
  // pages come in as { label: x, pageComponent: y }
  const [currentPageLabel, setCurrentPageLabel] = useState(activePage)
  const history = useHistory()

  const changePage = (label, url) => {
    setCurrentPageLabel(label)
    if (url) {
      history.push(url)
    }
  }

  const currentComponent = pages.find((page) => page.label === currentPageLabel)

  return (
    <div data-testid="secondary-nav-router">
      {pages.length > 1 && (
        <SecondaryNavbar
          pages={pages}
          currentPageLabel={currentPageLabel}
          changePage={changePage}
        />
      )}
      {currentComponent?.component}
    </div>
  )
}
