import { toast } from 'react-toastify'
import moment from 'moment'
import { isEmpty } from 'lodash'

import { fetchingAPI, apiService } from '@/api'

import * as redux from './commandCenter.redux'
import {
  getAgentAndTagParams,
  getMergedSentimentData,
  appendSentimentFilterParams,
} from './commandCenter.helpers'

export const fetchAgentsWithCallStatus = () => async (dispatch, getState) => {
  const organizationId = getState().currentUser.organizationid
  const ownOrganizationId = getState().currentUser.own_organization_id
  const childOrgSelected = organizationId !== ownOrganizationId
  const { savedFilters } = getState().analytics.data

  // for org hierarchy, we disable my team filter when impersonating child organization
  const myTeamFilter =
    !childOrgSelected && savedFilters.find((filter) => filter.filter_type === 'my_team')

  dispatch(redux.setLoading({ agents: true }))

  try {
    const startDate = moment().startOf('day').utc().format()
    const filterParams = myTeamFilter ? getAgentAndTagParams(myTeamFilter) : null

    const url = filterParams
      ? `${
          apiService.rtc
        }/command-center/${organizationId}/agents?${filterParams.toString()}&startDate=${startDate}`
      : `${apiService.rtc}/command-center/${organizationId}/agents?startDate=${startDate}`

    const response = await fetchingAPI(url, 'GET', dispatch)
    dispatch(redux.setAgents(response))
  } catch (err) {
    toast.error('Failed to fetch agents')
  } finally {
    dispatch(redux.setLoading({ agents: false }))
  }
}

export const fetchAgentWithCallStatus = (agentId) => async (dispatch, getState) => {
  const organizationId = getState()?.currentUser?.organizationid
  const startDate = moment().startOf('day').utc().format()

  try {
    const response = await fetchingAPI(
      `${apiService.rtc}/command-center/${organizationId}/agents/${agentId}?startDate=${startDate}`,
      'GET',
      dispatch
    )
    dispatch(redux.setAgent({ agentId, agentData: response }))
  } catch (err) {
    // Going to avoid a toast here because if something goes wrong, the toasts will be never ending
    console.error(err)
  }
}

export const fetchSentimentData = () => async (dispatch, getState) => {
  const organizationId = getState().currentUser.organizationid
  const ownOrganizationId = getState().currentUser.own_organization_id
  const childOrgSelected = organizationId !== ownOrganizationId
  const { savedFilters } = getState().analytics.data
  const { sentimentConfig } = getState().commandCenter
  // for org hierarchy, we disable my team filter when impersonating child organization
  const myTeamFilter =
    (!childOrgSelected && savedFilters.find((filter) => filter.filter_type === 'my_team')) || {}
  const myTeamFilterParams = getAgentAndTagParams(myTeamFilter)
  const filterParams = !isEmpty(sentimentConfig.filters)
    ? appendSentimentFilterParams(myTeamFilterParams, sentimentConfig)
    : myTeamFilterParams

  dispatch(redux.setLoading({ sentiment: true }))

  let sentimentData

  try {
    sentimentData = await fetchingAPI(
      `${apiService.reporting}/api/sentiment?${filterParams.toString()}`,
      'GET',
      dispatch
    )

    dispatch(redux.setSentimentData(sentimentData))
  } catch (err) {
    toast.error('Failed to fetch sentiment data')
  } finally {
    dispatch(redux.setLoading({ sentiment: false }))
  }

  // only re-request calls w/o an upset reason or happy reason
  const upsetCallIds = sentimentData?.bottom_calls
    .filter((data) => !data.upset_reason)
    .map((data) => data.call_id)

  // Don't send an empty envelope in the mail
  if (!isEmpty(upsetCallIds)) {
    dispatch(redux.setLoading({ sentimentUpsetReasons: true }))

    try {
      const additionalUpsetReasons = await fetchingAPI(
        `${apiService.summary}/sentiment/upset_reason`,
        'POST',
        dispatch,
        JSON.stringify({ call_ids: upsetCallIds })
      )
      const mergedSentimentData = getMergedSentimentData(sentimentData, additionalUpsetReasons)
      dispatch(redux.setSentimentData(mergedSentimentData))
    } catch (err) {
      console.error(err)
    } finally {
      dispatch(redux.setLoading({ sentimentUpsetReasons: false }))
    }
  }

  const positiveCallIds = sentimentData?.top_calls
    .filter((data) => !data.positive_reason)
    .map((data) => data.call_id)

  // Don't send an empty envelope in the mail
  if (!isEmpty(positiveCallIds)) {
    dispatch(redux.setLoading({ sentimentPositiveReasons: true }))

    try {
      const additionalHappyReasons = await fetchingAPI(
        `${apiService.summary}/sentiment/positive_reason`,
        'POST',
        dispatch,
        JSON.stringify({ call_ids: positiveCallIds })
      )
      const mergedSentimentData = getMergedSentimentData(sentimentData, additionalHappyReasons)
      dispatch(redux.setSentimentData(mergedSentimentData))
    } catch (err) {
      console.error(err)
    } finally {
      dispatch(redux.setLoading({ sentimentPositiveReasons: false }))
    }
  }
}

export const fetchSentimentConfig = () => {
  return async (dispatch) => {
    try {
      const response = await fetchingAPI(
        `${apiService.web}/api/reporting/saved_filters?filter_type=sentiment`,
        'GET',
        dispatch
      )
      const sentimentConfig = response.saved_filters ? response.saved_filters[0] : null

      if (sentimentConfig) {
        dispatch(redux.setSentimentConfig(sentimentConfig))
      }
    } catch (err) {
      toast.error('Failed to fetch sentiment configuration')
    }
  }
}
