import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { closeModal } from '@/reducers/ui/ui.redux'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { getTagsWithCategories } from '@/utils/helpers'

const userTypeOptions = [
  { value: 'all', label: 'All' },
  { value: 'agent', label: 'Agents' },
  { value: 'manager', label: 'Managers' },
]

const userStatusOptions = [
  { value: 'all', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Deleted' },
]

export const ExportUsersForm = ({ playbooks, tags, tagCategories, handleSubmit }) => {
  const dispatch = useDispatch()
  const { userPermissionColumns } = useSelector((state) => state.organizationUsers)
  const playbookOptions = playbooks.map((pb) => ({ label: pb.name, value: pb.cid }))
  const tagOptions = getTagsWithCategories(tags, tagCategories)

  const handleClose = () => {
    dispatch(closeModal())
  }

  const handleExportUsers = ({ validateOnMount, ...values }) => {
    // If no columns are selected, nothing will export
    if (isEmpty(values.columns)) {
      return
    }

    // If playbooks or tags are empty, remove them from the payload. If user_type or user_status
    // are 'all', remove them from the payload.
    const updatedValues = {
      ...(!isEmpty(values.playbooks) && { playbooks: values.playbooks.map((pb) => pb.value) }),
      ...(!isEmpty(values.tags) && { tags: values.tags.map((tag) => tag.value) }),
      ...(values.user_type !== 'all' && { user_type: values.user_type }),
      ...(values.user_status !== 'all' && { user_status: values.user_status }),
      columns: values.columns.map((column) => column.value),
    }

    if (!isEmpty(updatedValues)) {
      handleSubmit(updatedValues)
      handleClose()
    }
  }

  const schema = [
    { name: 'user_type', label: 'User Type', type: 'select', options: userTypeOptions },
    { name: 'user_status', label: 'User Status', type: 'select', options: userStatusOptions },
    {
      name: 'tags',
      label: 'Tags',
      type: 'multiSelect',
      options: tagOptions,
      placeholder: 'Select Tags',
      condenseOptions: false,
      showFullOptions: true,
    },
    {
      name: 'playbooks',
      label: 'Playbooks',
      type: 'multiSelect',
      options: playbookOptions,
      placeholder: 'Select Playbooks',
      condenseOptions: false,
      showFullOptions: true,
    },
    {
      name: 'columns',
      label: 'User Attributes',
      type: 'multiSelect',
      placeholder: 'Select User Attributes',
      options: userPermissionColumns,
      condenseOptions: false,
      showFullOptions: true,
    },
  ]

  const existingValues = {
    user_type: 'all',
    user_status: 'all',
    tags: [],
    playbooks: [],
    columns: userPermissionColumns,
  }

  return (
    <AbstractForm
      schema={schema}
      onSubmit={handleExportUsers}
      onClose={handleClose}
      existingValues={existingValues}
      isModal
    />
  )
}
