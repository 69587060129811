import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { orderBy, kebabCase, toArray, get, truncate, isEmpty } from 'lodash'
import { IconPlus, IconChevronDown, IconChevronRight, IconMenu2 } from '@tabler/icons-react'
import { Popup, Button } from 'semantic-ui-react'

import { reorder } from '@/utils/helpers'
import { setTemporaryCriteria, editScorecardConfig } from '@/reducers/qa-copilot/qa-copilot.redux'
import { Drag, Drop, DragAndDrop } from '@/components/dragDrop'

import { setTemporaryPrompt } from '@/reducers/prompts/prompts.redux'
import { addTemporaryPromptHelper } from '@/reducers/prompts/prompts.helpers'
import { QACopilotSidebarInlineEntry } from './QACopilotSidebarInlineEntry'
import { addTemporaryCriteriaHelper } from '../QACopilot.helpers'

export const QACopilotSidebar = () => {
  const dispatch = useDispatch()
  const { scorecardConfig } = useSelector((state) => state.qaCopilot)
  const [sidebarIsCollapsed, setSidebarIsCollapsed] = useState(true)
  const [collapsedSections, setCollapsedSections] = useState([])
  const sortedSections = scorecardConfig?.sections
    ? orderBy(scorecardConfig.sections, ['order_id'])
    : []

  if (!scorecardConfig?.sections) {
    return null
  }

  const handleCollapseSidebar = () => {
    setSidebarIsCollapsed(true)
  }

  const handleExpandSidebar = () => {
    setSidebarIsCollapsed(false)
  }

  const handleScrollToCriteria = (scid) => {
    const criteriaElement = document.getElementById(scid)
    criteriaElement?.scrollIntoView({ behavior: 'smooth' })
  }

  const setCollapsed = (section) => {
    if (collapsedSections.includes(section.ssid)) {
      setCollapsedSections(collapsedSections.filter((ssid) => ssid !== section.ssid))
    } else {
      setCollapsedSections([...collapsedSections, section.ssid])
    }
  }

  const handleAddCriteria = (section) => {
    const tempCriteria = addTemporaryCriteriaHelper(section)
    dispatch(setTemporaryCriteria(tempCriteria))
    dispatch(setTemporaryPrompt(addTemporaryPromptHelper(tempCriteria.scid)))
  }

  const handleDragEnd = (result) => {
    const { source, destination, type } = result
    if (!source || !destination) return
    const sourceSectionId = source.droppableId
    const destinationSectionId = destination.droppableId

    if (type === 'droppable-qa-sidebar-category') {
      const originalOrder = scorecardConfig.sections
      const newOrder = reorder(originalOrder, source.index, destination.index)
      const updatedSectionsWithOrderIds = newOrder.map((section, index) => ({
        ...section,
        order_id: index,
      }))

      dispatch(editScorecardConfig({ ...scorecardConfig, sections: updatedSectionsWithOrderIds }))
    }

    if (type === 'droppable-criteria') {
      if (sourceSectionId === destinationSectionId) {
        const originalCriteriaOrder = toArray(
          scorecardConfig.sections.find((section) => section.ssid === sourceSectionId)?.measures[0]
            ?.criteria
        )
        const updatedCriteriaOrder = reorder(originalCriteriaOrder, source.index, destination.index)
        const updatedCriteriaWithOrderIds = updatedCriteriaOrder.map((section, index) => ({
          ...section,
          order_id: index,
        }))
        const updatedSections = scorecardConfig.sections.map((section) =>
          section.ssid === sourceSectionId
            ? {
                ...section,
                measures: section.measures.map((measure, mIndex) =>
                  mIndex === 0 ? { ...measure, criteria: updatedCriteriaWithOrderIds } : measure
                ),
              }
            : section
        )

        dispatch(editScorecardConfig({ ...scorecardConfig, sections: updatedSections }))
      } else {
        const sourceOrder = toArray(
          scorecardConfig.sections.find((section) => section.ssid === sourceSectionId)?.measures[0]
            ?.criteria
        )
        const destinationOrder = toArray(
          scorecardConfig.sections.find((section) => section.ssid === destinationSectionId)
            ?.measures[0]?.criteria
        )

        const [removed] = sourceOrder.splice(source.index, 1)
        destinationOrder.splice(destination.index, 0, removed)

        destinationOrder[removed] = sourceOrder[removed]
        delete sourceOrder[removed]

        const updatedSourceWithOrderIds = sourceOrder.map((criteria, index) => ({
          ...criteria,
          order_id: index,
        }))
        const updatedDestinationWithOrderIds = destinationOrder.map((criteria, index) => ({
          ...criteria,
          order_id: index,
        }))

        const updatedSections = scorecardConfig.sections.map((section) =>
          section.ssid === sourceSectionId
            ? {
                ...section,
                measures: section.measures.map((measure, mIndex) =>
                  mIndex === 0 ? { ...measure, criteria: updatedSourceWithOrderIds } : measure
                ),
              }
            : section.ssid === destinationSectionId
              ? {
                  ...section,
                  measures: section.measures.map((measure, mIndex) =>
                    mIndex === 0
                      ? { ...measure, criteria: updatedDestinationWithOrderIds }
                      : measure
                  ),
                }
              : section
        )

        dispatch(editScorecardConfig({ ...scorecardConfig, sections: updatedSections }))
      }
    }
  }

  if (sidebarIsCollapsed) {
    return (
      <div className="qa-copilot-sidebar__wrapper collapsed" data-testid="sidebar-navigation">
        <Popup
          inverted
          content="Open QA Copilot sidebar"
          trigger={
            <Button
              secondary
              icon
              className="svg-button round qa-copilot-sidebar-collapsed-button"
              onClick={handleExpandSidebar}
              data-testid="qa-copilot-sidebar-button"
            >
              <IconMenu2 />
            </Button>
          }
        />
      </div>
    )
  }

  return (
    <div className="qa-copilot-sidebar__wrapper" data-testid="sidebar-navigation">
      <div className="qa-copilot-sidebar__section">
        <h2>
          <span>Navigation</span>
          <Button basic compact size="tiny" onClick={handleCollapseSidebar}>
            Collapse
          </Button>
        </h2>

        <div className="qa-copilot-sidebar__items">
          <DragAndDrop onDragEnd={handleDragEnd}>
            <Drop droppableId="droppable" type="droppable-qa-sidebar-category">
              {sortedSections.map((section, index) => {
                const isCollapsed = collapsedSections.some((ssid) => ssid === section.ssid)
                const criteriaList = toArray(get(section, 'measures[0].criteria'))

                return (
                  <Drag
                    key={section.ssid}
                    draggableId={section.ssid}
                    index={index}
                    showDragStyle
                    dataTestId={kebabCase(section.name)}
                    reverse
                    outer
                  >
                    <div className="flex small-gap" style={{ alignItems: 'flex-start' }}>
                      <Button
                        icon
                        basic
                        className="svg-button"
                        onClick={() => setCollapsed(section)}
                      >
                        {isCollapsed ? <IconChevronRight /> : <IconChevronDown />}
                      </Button>
                      <div className="sidebar-section-with-nested-criteria">
                        <h4>{truncate(section.name, { length: 20 })}</h4>

                        {!isCollapsed && (
                          <>
                            <Drop
                              droppableId={section.ssid}
                              type="droppable-criteria"
                              style={isEmpty(criteriaList) ? { padding: 0 } : undefined}
                            >
                              {!isEmpty(criteriaList) &&
                                criteriaList.map((criteria, criteriaIndex) => {
                                  return (
                                    <Drag
                                      key={criteria.scid}
                                      draggableId={criteria.scid}
                                      index={criteriaIndex}
                                      isModal
                                      showDragStyle
                                      reverse
                                    >
                                      <span
                                        className="clickable-criteria"
                                        onClick={() => handleScrollToCriteria(criteria.scid)}
                                      >
                                        {truncate(criteria.name, { length: 20 })}
                                      </span>
                                    </Drag>
                                  )
                                })}
                            </Drop>

                            <Button
                              icon
                              primary
                              size="small"
                              compact
                              className="minimal-button flex small-gap svg-button"
                              onClick={() => handleAddCriteria(section)}
                              style={{ paddingLeft: 0 }}
                            >
                              <IconPlus />
                              <span className="muted-text">Add Criterion</span>
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </Drag>
                )
              })}
            </Drop>
          </DragAndDrop>
        </div>

        <QACopilotSidebarInlineEntry label="Add Section" />
      </div>
    </div>
  )
}
