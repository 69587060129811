import React from 'react'
import { Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, get } from 'lodash'

import { setTemporaryCriteria } from '@/reducers/qa-copilot/qa-copilot.redux'

import { setTemporaryPrompt } from '@/reducers/prompts/prompts.redux'
import { addTemporaryPromptHelper } from '@/reducers/prompts/prompts.helpers'
import { addTemporaryCriteriaHelper } from '../QACopilot.helpers'

export const QACopilotAddCriteria = ({ section }) => {
  const dispatch = useDispatch()
  const { scorecardConfig, temporaryCriteria } = useSelector((state) => state.qaCopilot)
  const isFirstSection = section.ssid === get(scorecardConfig, 'sections[0].ssid')
  const allSectionsAreEmpty = !scorecardConfig.sections.some(
    (section) => !isEmpty(get(section, 'measures[0].criteria'))
  )
  const currentlyCreating = temporaryCriteria && temporaryCriteria.ssid === section.ssid

  const handleAddCriteria = () => {
    const tempCriteria = addTemporaryCriteriaHelper(section)
    dispatch(setTemporaryCriteria(tempCriteria))
    dispatch(setTemporaryPrompt(addTemporaryPromptHelper(tempCriteria.scid)))
  }

  if (isFirstSection && allSectionsAreEmpty && !currentlyCreating) {
    return (
      <div className="qa-copilot-first-criteria" data-testid="first-criteria-message">
        <div>
          <h2>Add Your Criteria</h2>
          <p className="muted-text">
            Begin by writing a detailed and descriptive guideline to evaluate agent behavior and
            performance. A descriptive criterion ensures that reviewers and Copilot can understand
            and accurately evaluate calls.
          </p>
          <Button
            primary
            onClick={handleAddCriteria}
            disabled={!!temporaryCriteria}
            data-testid="add-first-criterion-button"
          >
            Add Criterion
          </Button>
        </div>
      </div>
    )
  }

  if (!currentlyCreating) {
    return (
      <div className="qa-copilot-first-criteria muted">
        <Button secondary onClick={handleAddCriteria} disabled={!!temporaryCriteria}>
          <span>Add Criterion</span>
        </Button>
      </div>
    )
  }

  return null
}
