import React from 'react'
import { toast } from 'react-toastify'

import { unwrapInlineNode } from './helpers'
import ToolbarButton from './ToolbarButton'
import UrlInput from './UrlInput'

export default class Toolbar extends React.Component {
  constructor(props) {
    super(props)

    // https://stackoverflow.com/a/17773849/4541434
    this.urlRegex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
    this.imgSrcRegex = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif))/i

    this.updateUrlValue = (e) => {
      e.preventDefault()
      this.setState({ urlInputValue: e.target.value })
    }
    this.handleUrlSubmit = this.handleUrlSubmit.bind(this)
    this.promptForUrl = this.promptForUrl.bind(this)
    this.resetUrlInput = this.resetUrlInput.bind(this)
    this.addInlineVerbatim = this.addInlineVerbatim.bind(this)
    this.state = {
      showUrlInput: false,
      urlInputValue: '',
      urlInputType: '',
      toolbarButtons: [
        {
          slateObject: 'mark',
          slateObjectType: 'bold',
          iconName: 'bold icon',
          handleMouseDown: this.props.createMark,
        },
        {
          slateObject: 'mark',
          slateObjectType: 'italic',
          iconName: 'italic icon',
          handleMouseDown: this.props.createMark,
        },
        {
          slateObject: 'node',
          slateObjectType: 'header-one',
          iconName: 'heading icon',
          handleMouseDown: this.props.createBlock,
        },
        {
          slateObject: 'node',
          slateObjectType: 'header-two',
          iconName: 'small heading icon',
          handleMouseDown: this.props.createBlock,
        },
        {
          slateObject: 'node',
          slateObjectType: 'numbered-list',
          iconName: 'list ol icon',
          handleMouseDown: this.props.createBlock,
        },
        {
          slateObject: 'node',
          slateObjectType: 'bulleted-list',
          iconName: 'list ul icon',
          handleMouseDown: this.props.createBlock,
        },
        {
          slateObject: 'node',
          slateObjectType: 'image',
          iconName: 'images outline icon',
          handleMouseDown: this.promptForUrl,
        },
        {
          slateObject: 'inline',
          slateObjectType: 'link',
          iconName: 'linkify icon',
          handleMouseDown: this.promptForUrl,
        },
        {
          slateObject: 'inline',
          slateObjectType: 'button',
          iconName: 'bookmark outline icon',
          handleMouseDown: this.promptForUrl,
        },
        {
          slateObject: 'node',
          slateObjectType: 'horizontal-rule',
          iconName: 'arrows alternate horizontal icon',
          handleMouseDown: this.props.createBlock,
        },
      ],
    }
  }

  componentDidMount() {
    const { inlineVerbatim } = this.props
    if (inlineVerbatim) this.addInlineVerbatim()
  }

  addInlineVerbatim() {
    const { toolbarButtons } = this.state
    toolbarButtons.push({
      slateObject: 'node',
      slateObjectType: 'inline-verbatim-container',
      iconName: 'check icon',
      handleMouseDown: this.props.createBlock,
    })

    this.setState((prevState) => ({ ...prevState, toolbarButtons }))
  }

  resetUrlInput() {
    this.setState((prevState) => ({
      ...prevState,
      showUrlInput: false,
      urlInputType: '',
      urlInputValue: '',
    }))
  }

  promptForUrl(e, urlType) {
    e.preventDefault()
    const { editor } = this.props
    const { value } = editor
    const hasLinks = this.props.selectionHasElement('inline', 'link')
    const hasButtons = this.props.selectionHasElement('inline', 'button')
    // if they have a link selected or a button, clicking the button again
    // will unwrap it and set it to a p tag
    if (hasLinks) {
      return editor.command(unwrapInlineNode, 'link')
    }
    if (hasButtons) {
      return editor.command(unwrapInlineNode, 'button')
    }
    if (value.selection.isExpanded && (urlType === 'link' || urlType === 'button')) {
      return this.setState((prevState) => ({
        ...prevState,
        showUrlInput: true,
        urlInputType: urlType,
      }))
    }
    if (urlType === 'image') {
      return this.setState((prevState) => ({
        ...prevState,
        showUrlInput: true,
        urlInputType: urlType,
      }))
    }
    return null
  }

  handleUrlSubmit(e) {
    const { urlInputType, urlInputValue } = this.state

    switch (urlInputType) {
      case 'image':
        if (!urlInputValue.match(this.imgSrcRegex)) {
          e.preventDefault()
          toast.error('Please enter a valid URL')

          return this.setState((prevState) => ({ ...prevState, urlInputValue: '' }))
        }
        return this.props.createBlock(e, urlInputType, this.resetUrlInput, urlInputValue)
      default:
        if (!urlInputValue.match(this.urlRegex)) {
          e.preventDefault()
          toast.error('Please enter a valid URL')

          return this.setState((prevState) => ({ ...prevState, urlInputValue: '' }))
        }
        return this.props.createInlineNode(e, urlInputValue, urlInputType, this.resetUrlInput)
    }
  }

  render() {
    return (
      <>
        <div className="text-editor-toolbar">
          {this.state.toolbarButtons.map((button) => (
            <ToolbarButton
              key={button.slateObjectType}
              isActive={this.props.selectionHasElement(button.slateObject, button.slateObjectType)}
              handleMouseDown={button.handleMouseDown}
              iconName={button.iconName}
              slateObjectType={button.slateObjectType}
            />
          ))}
        </div>
        {this.state.showUrlInput && (
          <UrlInput
            urlInputValue={this.state.urlInputValue}
            updateUrlValue={this.updateUrlValue}
            urlInputType={this.state.urlInputType}
            resetUrlInput={this.resetUrlInput}
            handleSubmit={this.handleUrlSubmit}
          />
        )}
      </>
    )
  }
}
