import React from 'react'
import { Form, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { IconInfoCircle } from '@tabler/icons-react'

import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { RTCDisplayGroupingOptions } from '@/utils/constants'
import { MultiSelect } from '@/components/forms/MultiSelect'

export const AdvancedExportsDisplayOptions = () => {
  const dispatch = useDispatch()
  const { filters } = useSelector((state) => state.realtimeCoaching)

  // Makes agents and tags options mutually exclusive
  const displayGroupingOptions = [...RTCDisplayGroupingOptions]
  const agentIndex = displayGroupingOptions.findIndex(
    (displayGrouping) => displayGrouping.value === 'agent'
  )
  const tagIndex = displayGroupingOptions.findIndex(
    (displayGrouping) => displayGrouping.value === 'tag'
  )
  displayGroupingOptions[agentIndex] = {
    ...displayGroupingOptions[agentIndex],
    isDisabled: filters.displayGroupings.some((displayGrouping) => displayGrouping.value === 'tag'),
  }
  displayGroupingOptions[tagIndex] = {
    ...displayGroupingOptions[tagIndex],
    isDisabled: filters.displayGroupings.some(
      (displayGrouping) => displayGrouping.value === 'agent'
    ),
  }

  return (
    <div className="rtc-exports-display-options-container">
      <div className="rtc-exports-display-options">
        <h4>Display Options</h4>
        <Popup
          trigger={<IconInfoCircle className="icon-svg-large status-informative" />}
          content={
            <div className="rtc-exports_display-options-tooltip">
              <h4 className="rtc-exports_display-options">Display Options</h4>
              <p>At a minimum, at least one display metric must be selected</p>
              <p>
                Display metrics are quantitative measurements. For example, the display metric,
                “Fired Alerts” will show the total number of alerts fired.
              </p>
              <p>
                Display groupings are attributes of your data. For example, grouping by “Agents”
                will show agents as one selected column and each row as a unique agent.
              </p>
            </div>
          }
          hoverable
        />
      </div>

      <Form>
        <Form.Field
          required
          className="rtc-exports-display-options-filter"
          data-testid="advanced-exports-display-metrics-filter"
        >
          <label className="rtc-exports-display-options-label">Show Me Metrics</label>
          <div className="rtc-display-options-select">
            <MultiSelect
              placeholder="Select Display Metric"
              aria-labelledby="metrics-dropdown"
              isClearable={false}
              options={[
                {
                  value: 'fired_alerts',
                  label: 'Fired alerts',
                  accessor: 'fired_alerts',
                  isDisabled: true,
                },
                { value: 'chats', label: 'Chats', accessor: 'chats', isDisabled: true },
                { value: 'listens', label: 'Listens', accessor: 'listens', isDisabled: true },
                {
                  value: 'acknowledgements',
                  label: 'Acknowledgements',
                  accessor: 'acknowledgements',
                  isDisabled: true,
                },
              ]}
              value={filters.displayMetrics}
              onChange={(value) => dispatch(setFilter({ displayMetrics: value }))}
            />
          </div>
        </Form.Field>
        <Form.Field
          className="rtc-exports-display-options-filter"
          data-testid="advanced-exports-display-grouping-filter"
          required
        >
          <label className="rtc-exports-display-options-label">Grouped By</label>
          <div className="rtc-display-options-select">
            <MultiSelect
              placeholder="Select Display Grouping"
              aria-labelledby="group-by-dropdown"
              options={displayGroupingOptions}
              value={filters.displayGroupings}
              onChange={(value) => dispatch(setFilter({ displayGroupings: value }))}
            />
          </div>
        </Form.Field>
      </Form>
    </div>
  )
}
