import React from 'react'
import './AgentAccordionHeader.css'
import { CircleIcon } from '@/components/icons/AlertIcon'

export const AlertCategory = (props) => {
  const { count, name } = props
  const categoryText = `${count} ${name}`
  const color = count ? props.color : 'grey'

  return (
    <div className="alert-category">
      <div className="alert-category-icon">
        <CircleIcon color={color} />
      </div>
      <div className="alert-category-name">{categoryText}</div>
    </div>
  )
}
