// Hacky way to get the right shared worker to initiate in the shared file
// I triple dog dare anyone to find a better solution
const RTC = 'baltocloud-rtc-socket-worker'
const CC = 'command-center-worker'

class WorkerClient {
  constructor(name) {
    this.name = name
    this.sharedWorker = null
    this.unsupportedBrowser = false
  }

  init() {
    if (!this.sharedWorker) {
      try {
        if (this.name === CC) {
          this.sharedWorker = new SharedWorker(
            new URL('../../workers/ccSharedWorker.js', import.meta.url),
            { type: 'module', name: this.name }
          )
          this.sharedWorker.port.start()
        }

        if (this.name === RTC) {
          this.sharedWorker = new SharedWorker(
            new URL('../../workers/rtcSharedWorker.js', import.meta.url),
            { type: 'module', name: this.name }
          )
        }

        this.sharedWorker.port.start()
      } catch (err) {
        this.unsupportedBrowser = true
        console.error(err)
      }
    }
  }

  post(data) {
    this.sharedWorker.port.postMessage(data)
  }
}

export { WorkerClient }
