import React, { useMemo, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { uniq, isEmpty } from 'lodash'

import WidgetContext from '../../../components/widget/widget.context'
import WidgetContainer from '../../../components/widget/WidgetContainer'
import WidgetTooltip from '../../../components/widget/WidgetTooltip'
import BarChart from '../../../components/charts/BarChart'
import { AdvancedTable } from '../../../components/tables/AdvancedTable/AdvancedTable'
import { formatPercent } from '../../../utils/helpers'
import { groupAndFormatChecklistUsage } from './widgetHelpers'

export const ChecklistUsageWidget = () => {
  const { data, loading } = useSelector((state) => state.analytics)
  const { groupByValue, isPercent, handleChangeCsvData, handleResetWidgetFilters } =
    useContext(WidgetContext)

  // Computed properties
  const formattedData = useMemo(
    () => groupAndFormatChecklistUsage(data.checklistUsage, groupByValue, isPercent),
    [data, groupByValue, isPercent]
  )

  const columns = useMemo(
    () => [
      {
        accessor: 'date',
        label: 'Date',
      },
      {
        accessor: 'dataKey',
        label: `Checklist Usage ${isPercent ? 'Percent' : 'Count'}`,
        format: (v) => (isPercent ? formatPercent(v) : Number(v).toLocaleString()),
      },
    ],
    [isPercent]
  )

  const tickValues = useMemo(
    () => uniq(formattedData.map((d) => d.date)).filter((item, i) => i % 2 === 0),
    [formattedData]
  )

  useEffect(() => {
    handleChangeCsvData({ headers: columns, data: formattedData })
  }, [formattedData])

  useEffect(() => {
    handleResetWidgetFilters()
  }, [data])

  return (
    <WidgetContainer
      loading={loading.overviewPage || loading.checklistPage}
      noData={isEmpty(data.checklistUsage)}
      table={
        <AdvancedTable
          index="date"
          defaultOrderBy="date"
          rows={formattedData}
          columns={columns}
          rowsPerPage={10}
          pagination
          enableUpdateRowsPerPage={false}
        />
      }
      chart={
        <BarChart
          isPercent={isPercent}
          data={formattedData}
          keys={['dataKey']}
          indexBy="date"
          tooltip={(item) => (
            <WidgetTooltip color={item.color}>
              <h4>{item.data.date}</h4>
              <p>
                {`${
                  isPercent
                    ? formatPercent(item.data.percent)
                    : Number(item.data.items).toLocaleString()
                } checklist items used`}
              </p>
            </WidgetTooltip>
          )}
          colors={['#4795EC']}
          enableGridY
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Date',
            legendPosition: 'middle',
            legendOffset: 44,
            // What??
            // Remove every other item from the array so we don't have too many ticks ¯\_(ツ)_/¯
            ...(groupByValue === 'day' &&
              uniq(formattedData.map((d) => d.date)).length > 30 && {
                tickValues,
              }),
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Usage',
            legendPosition: 'middle',
            legendOffset: -60,
            format: (v) => (isPercent ? formatPercent(v) : Number(v).toLocaleString()),
            ...(isPercent && { tickValues: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100] }),
          }}
          margin={{ top: 50, right: 10, bottom: 50, left: 70 }}
          animate={false}
        />
      }
    />
  )
}
