import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'

import { BasicModal } from '@/components/layout/modals/BasicModal'
import { closeModal } from '@/reducers/ui/ui.redux'
import ConfirmForm from '@/components/forms/ConfirmForm'
import { setTemporaryCriteria, editScorecardConfig } from '@/reducers/qa-copilot/qa-copilot.redux'

import { setPrompts, setTemporaryPrompt } from '@/reducers/prompts/prompts.redux'
import { removePromptHelper } from '@/reducers/prompts/prompts.helpers'
import { removeCriteriaHelper } from '../QACopilot.helpers'

export const QACopilotDeleteCriteriaModal = ({ criteria, isTemporaryCriteria, setEditMode }) => {
  const dispatch = useDispatch()
  const { currentlyOpenModalId } = useSelector((state) => state.ui)
  const { scorecardConfig } = useSelector((state) => state.qaCopilot)
  const { prompts } = useSelector((state) => state.prompts)
  const modalId = `qaCopilot/deleteCriteria-${criteria.scid}`

  const handleCloseModal = () => {
    dispatch(closeModal())
  }

  const handleDelete = () => {
    if (isTemporaryCriteria) {
      dispatch(setTemporaryCriteria(null))
      dispatch(setTemporaryPrompt(null))
    } else {
      dispatch(setPrompts(removePromptHelper(prompts, criteria.scid)))
      dispatch(editScorecardConfig(removeCriteriaHelper(scorecardConfig, criteria)))
      setEditMode(false)
      toast.success('Criterion has been deleted')
    }
  }

  if (isEmpty(scorecardConfig?.sections)) {
    return null
  }

  if (modalId !== currentlyOpenModalId) {
    return null
  }

  return (
    <BasicModal
      title={`Delete ${criteria.name}`}
      onClose={handleCloseModal}
      show={currentlyOpenModalId === modalId}
      size="tiny"
    >
      <ConfirmForm
        valueToSubmit={criteria.scid}
        bodyText={`Are you sure you want to delete this criterion: ${criteria.name}?`}
        danger
        primaryButtonText="Delete Criterion"
        handleSubmit={handleDelete}
        modalClose={handleCloseModal}
      />
    </BasicModal>
  )
}
