import React, { useContext, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, flatten } from 'lodash'
import moment from 'moment'

import WidgetContext from '@/components/widget/widget.context'
import WidgetContainer from '@/components/widget/WidgetContainer'
import WidgetTooltip from '@/components/widget/WidgetTooltip'
import LineChart from '@/components/charts/LineChart'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { formatPercent, getRangeInDays, getTickValuesForDateRange } from '@/utils/helpers'
import { chartColors } from '@/utils/constants'

export const ScoresPerOrganizationWidget = () => {
  const { data, loading, filters } = useSelector((state) => state.qaCopilot)
  const { hiddenItems, handleChangeCsvData, handleResetWidgetFilters } = useContext(WidgetContext)

  const formattedData = data.enterpriseScoresPerOrganization.map((item, index) => ({
    id: item.organization_name,
    data: item.scores.map((d) => ({
      x: moment(new Date(d.date)).format('YYYY-MM-DD'),
      y: Number(d.score).toFixed(2),
    })),
    color: chartColors[index % chartColors.length],
    organization_id: item.organization_id,
    link: `/qa-copilot/dashboard?organization_id=${item.organization_id}`,
  }))

  // If any items are hidden based on the legend selection
  const dataWithHiddenItems = formattedData.filter((item) => !hiddenItems.has(item.id))

  // Table data is flattened and still hidden based on legend
  const tableData = useMemo(
    () =>
      flatten(
        dataWithHiddenItems.map((arr) => {
          // add color attr to data
          return arr.data.map((item) => {
            return { organization: arr.id, date: item.x, score: item.y, color: arr.color }
          })
        })
      ),
    [data.enterpriseScoresPerOrganization]
  )
  const colors = dataWithHiddenItems.map((data) => data.color)
  const columns = [
    { accessor: 'organization', label: 'Organization' },
    { accessor: 'date', label: 'Date', format: (value) => moment(value).format('MMMM DD, YYYY') },
    {
      accessor: 'score',
      label: 'Score',
      format: (value) => formatPercent(value),
      headerAlignment: 'right',
    },
  ]

  useEffect(() => {
    handleChangeCsvData({ headers: columns, data: tableData })
  }, [tableData])

  useEffect(() => {
    handleResetWidgetFilters()
  }, [data.enterpriseScoresPerOrganization])

  const days = getRangeInDays(filters.startDate, filters.endDate)
  const tickValues = getTickValuesForDateRange(days)

  return (
    <WidgetContainer
      loading={loading.enterpriseScoresPerOrganization}
      noData={isEmpty(tableData) && hiddenItems.size === 0}
      withLegend
      dataForLegend={{ data: formattedData }}
      legendAccessor="id"
      table={
        <AdvancedTable
          index="uuid"
          rows={tableData}
          columns={columns}
          pagination
          rowsPerPage={10}
          enableUpdateRowsPerPage={false}
        />
      }
      chart={
        <LineChart
          isPercent
          data={dataWithHiddenItems}
          colors={colors}
          tooltip={({ point }) => (
            <WidgetTooltip color={point.color} style={{ minWidth: '300px' }}>
              <strong>Organization: </strong>
              <span>{point.serieId}</span>
              <br />
              <strong>Date: </strong>
              <span>{moment(point.data.x).format('MM/DD/YYYY')}</span>
              <br />
              <strong>Score: </strong>
              <span>{`${point.data.y}%`}</span>
            </WidgetTooltip>
          )}
          curve="monotoneX"
          enableGridX={false}
          enableGridY={false}
          pointSize={5}
          xFormat="time:%Y-%m-%d"
          xScale={{
            format: '%Y-%m-%d',
            precision: 'day',
            type: 'time',
            useUTC: false,
          }}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Date',
            legendOffset: 36,
            legendPosition: 'middle',
            format: '%b %d',
            tickValues,
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Score',
            legendOffset: -60,
            legendPosition: 'middle',
            format: (v) => formatPercent(v),
          }}
        />
      }
    />
  )
}
