import queryString from 'query-string'
import moment from 'moment'
import { isEmpty, cloneDeep } from 'lodash'

import { formatTime, sharedScorecardParams } from '@/utils/helpers'

import { formatCallExplorerQueryString } from '../callSearch/helpers'

export const formatScorecardFilters = (
  filterValues = {},
  scoreData = null,
  isCopilot = false,
  isPreview = false
) => {
  const filterTypes = [
    { data: 'agents', key: 'agent_ids' },
    { data: 'tags', key: 'tag_ids' },
    { data: 'scorecards', key: 'scorecard_config_sids', value: 'sid' }, // note: qa copilot is "value"
    { data: 'dispositions', key: 'dispositions' },
    { data: 'groupBy', key: 'group_by' },
    { data: 'metrics', key: 'metrics' },
    { data: 'sections', key: 'sections', value: 'ssid' },
    { data: 'measures', key: 'measures', value: 'smid' },
    { data: 'criteria', key: 'criteria', value: 'scid' },
  ]
  const params = new URLSearchParams()
  const orgId = filterValues.organization

  sharedScorecardParams(filterTypes, filterValues, params, orgId, isCopilot)

  if (scoreData && scoreData.percentage_score !== null) {
    params.set('section_score', parseInt(scoreData.percentage_score, 10))
  }

  if (isPreview) {
    params.set('count', 20)
  }

  return params.toString()
}

export const formatAggregateFilters = (filterValues = {}) => {
  const formattedFilterValues = {}
  if (!isEmpty(filterValues.agents)) {
    formattedFilterValues.agent_ids = filterValues.agents
      .map((agent) => agent.value)
      .join('&agent_ids=')
  }

  if (filterValues.start_date && filterValues.end_date) {
    formattedFilterValues.start_date = moment(filterValues.start_date).utc().format()
    formattedFilterValues.end_date = moment(filterValues.end_date).utc().format()
  }

  return queryString.stringify(formattedFilterValues, { arrayFormat: 'repeat', encode: false })
}

export const parseDashboardToCsv = (rows, headers) => {
  const addComma = (string) => `${string},`

  const headerRow = headers.reduce(
    (reducer, header) => `${reducer ? addComma(reducer) : ''}${header.label}`,
    ''
  )

  const userScoreStrings = rows.reduce(
    (reducer, row) =>
      `${reducer}${headers.reduce(
        (reducer, header) =>
          `${reducer ? addComma(reducer) : ''}${String(row[header.accessor] || '')}`,
        ''
      )}\n`,
    ''
  )

  const csvText = `${headerRow}\n${userScoreStrings}`

  return new Blob([csvText], { type: 'test/csv' })
}

export const formatManualScorecard = (scorecard, orgId) => {
  const formattedPlaybooks = scorecard?.playbooks.map((cid) => ({ value: cid })) || []
  const baseFilters = {
    agents: [],
    playbooks: formattedPlaybooks,
    startDate: scorecard.start_date,
    endDate: scorecard.end_date,
    callDuration: scorecard.min_call_duration,
    organizationId: orgId,
    includeCallsWithoutAudio: true,
  }
  const updatedAgents = scorecard.agents.map((agent, index) => {
    const filters = cloneDeep(baseFilters)
    filters.agents = [{ value: agent.id }]
    const searchParams = formatCallExplorerQueryString(filters)
    const manualScoresComplete = agent?.calls_scored >= scorecard.required_score_count
    let color = 'negative'
    if (manualScoresComplete) {
      color = 'positive'
    } else if (agent.calls_scored > 0) {
      color = 'incomplete'
    }

    const avgScore = Math.round(agent.average_score * 10) / 10

    return {
      ...agent,
      callsScoredLabel: `${agent.calls_scored} of ${scorecard.required_score_count}`,
      average_score: avgScore,
      searchParams,
      color,
      index,
    }
  })
  return {
    ...scorecard,
    agents: updatedAgents,
  }
}

export const formatManualScorecardTableData = (scorecards, orgId) => {
  return scorecards.map((scorecard) => formatManualScorecard(scorecard, orgId))
}

export const formatDisputes = (disputes) => {
  return disputes.map((dispute) => ({
    ...dispute,
    agent_name: `${dispute.first_name} ${dispute.last_name}`,
    created_at: formatTime(dispute.created_at),
    modified_at: formatTime(dispute.modified_at),
    status: dispute.status === 'pending' ? 'Needs Review' : 'Resolved',
    link_call_id: dispute.call_id,
  }))
}
