import React from 'react'
import {
  StopListenIcon,
  StartListenIcon,
  XIcon,
  CheckIcon,
  ChatIcon,
} from '../../../../components/icons/AlertIcon'
import './InteractionOverlay.css'

export const InteractionOverlay = ({ type, style }) => {
  let className = 'interaction-outer-box'
  if (type === 'check' || type === 'close') {
    className += ' circle-outer-box'
  }

  const iconMap = {
    'stop-listen': <StopListenIcon className="alert-icon" />,
    chat: <ChatIcon className="alert-icon" />,
    check: <CheckIcon className="alert-icon" />,
    'start-listen': <StartListenIcon className="alert-icon" />,
    close: <XIcon className="alert-icon close-alert-icon" />,
  }

  return (
    <div className={className} data-testid="interaction-overlay" style={{ ...style }}>
      <div data-testid={type}>{iconMap[type]}</div>
    </div>
  )
}
