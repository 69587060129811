import { isCursorInRect } from '@nivo/core'
import * as ReactDOM from 'react-dom'
import React from 'react'
import { Tooltip } from '@/components/charts/customCharts/LayeredTooltip/Tooltip'
import {
  getRelativeCursor,
  givenValueMatchesCoordinate,
} from '@/components/charts/customCharts/LayeredTooltip/helpers'

// eslint-disable-next-line consistent-return
export function renderLayeredTooltip(
  e,
  {
    canvas,
    dataPointCoordinates,
    toggleTooltip,
    innerWidth,
    innerHeight,
    margin,
    tooltipDescription,
    tooltipId,
  }
) {
  const [x, y] = getRelativeCursor(canvas, e)

  // if we're not on the canvas, we're not on a point
  if (!isCursorInRect(margin.left, margin.top, innerWidth, innerHeight, x, y)) return null

  const matchingPoint = dataPointCoordinates.find((coordinates) =>
    givenValueMatchesCoordinate({ x, y }, coordinates, 4, margin.left, margin.top)
  )

  let element = null

  if (matchingPoint) {
    toggleTooltip(true)

    element = (
      <Tooltip
        position={[
          matchingPoint.xCoordinate,
          matchingPoint.yCoordinate + margin.top + 0.5 * margin.bottom,
        ]}
        point={matchingPoint}
        description={tooltipDescription}
        variant="line"
        isVisible
      />
    )
  } else {
    toggleTooltip(false)
  }

  ReactDOM.render(element, document.getElementById(tooltipId))
}
