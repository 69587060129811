import React, { useState } from 'react'
import { Button, Input } from 'semantic-ui-react'
import { IconPencil } from '@tabler/icons-react'

export const EditableHeader = ({ orgName, updateOrgName }) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [input, setInput] = useState(orgName)

  const handleUpdateOrgName = () => {
    const nameChange = {
      name: input,
    }

    updateOrgName(null, nameChange)
  }

  return (
    <div className="editable-header-container">
      {!isEditMode ? (
        <>
          <h1 data-testid="org-name-header">{orgName}</h1>
          <Button
            basic
            compact
            icon
            onClick={() => setIsEditMode(true)}
            data-testid="edit-header-button"
            className="svg-button"
          >
            <IconPencil />
          </Button>
        </>
      ) : (
        <div data-testid="org-name-editable" className="editable-header-container">
          <Input type="text" placeholder={orgName} onInput={(e) => setInput(e.target.value)} />
          <Button secondary onClick={() => setIsEditMode(false)} data-testid="edit-header-button">
            Cancel
          </Button>
          <Button primary onClick={handleUpdateOrgName} data-testid="save-org-name-button">
            Save
          </Button>
        </div>
      )}
    </div>
  )
}
