export const SET_DATA = 'gamification/setData'
export const SET_LOADING = 'gamification/setLoading'
export const SET_FILTER = 'gamification/setFilter'
export const CLEAR_FILTERS = 'gamification/clearFilters'
export const CLEAR_ORG_FILTER = 'gamification/clearOrgFilter'
export const CLEAR_DATA_MINUS_ORGS = 'gamification/clearDataMinusOrgs'
export const SET_DATE_FILTER = 'gamification/setDateRangeFilter'
export const CLEAR_CHALLENGES_VIEW_FILTERS = 'gamification/clearChallengesViewFilters'

// Action Creators
export const clearOrgFilter = () => ({ type: CLEAR_ORG_FILTER })
export const clearDataMinusOrgs = () => ({ type: CLEAR_DATA_MINUS_ORGS })
export const setLoading = (section, payload) => ({
  type: SET_LOADING,
  section,
  payload,
})
export const setData = (section, payload) => ({
  type: SET_DATA,
  section,
  payload,
})
export const setFilter = (section, payload) => ({
  type: SET_FILTER,
  section,
  payload,
})
export const setDateFilter = (payload) => ({
  type: SET_DATE_FILTER,
  payload,
})
export const clearFilters = () => ({ type: CLEAR_FILTERS })
export const clearChallengesViewFilters = () => ({ type: CLEAR_CHALLENGES_VIEW_FILTERS })

// State
//
export const initialState = {
  data: {
    agents: [],
    challengeTemplates: [],
    collectionBadgeTemplates: [],
    challengeBadgeTemplates: [],
    challengeResults: {},
    currentlySelectedChallenge: {},
    currentlySelectedBadge: {},
    specialtyBadges: [],
  },
  loading: {
    agents: false,
    challengeTemplates: false,
    badgeTemplates: false,
    challengeResults: false,
    currentlySelectedChallenge: false,
    currentlySelectedBadge: false,
    specialtyBadges: false,
  },
  filters: {
    agents: [],
    tags: [],
    challengeType: [],
    challengeStatus: [],
    startDate: null,
    endDate: null,
    organizationId: null,
  },
}

// Reducer
export default function gamificationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.section]: action.payload,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.section]: action.payload,
        },
      }
    case SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.section]: action.payload,
        },
      }
    case SET_DATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      }
    case CLEAR_FILTERS:
      return {
        ...state,
        filters: { ...initialState.filters },
      }
    case CLEAR_ORG_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          agents: [],
          tags: [],
          challengeType: [],
          challengeStatus: [],
        },
        data: {
          ...state.data,
          agents: [],
          tags: [],
          challengeType: [],
          challengeStatus: [],
          savedFilters: state.data.savedFilters,
          selectedSavedFilterId: state.data.selectedSavedFilterId,
        },
      }
    default:
      return state
  }
}
