import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field } from 'formik'
import { isEmpty } from 'lodash'
import { Button, Loader } from 'semantic-ui-react'

import { getSinglePromptTestResults } from '@/reducers/prompts/prompts.actions'
import { TextField, Select } from '@/components/forms/formik'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import { AgentSummaryView } from './AgentSummaryView'

export const TestPromptForm = ({ values, setFieldValue }) => {
  const dispatch = useDispatch()
  const { rtnModelOptions, rtnPrompt } = useSelector((state) => state.playbook)
  const { organizationid } = useSelector((state) => state.currentUser)
  const {
    loading: { promptTests: promptTestsLoading },
  } = useSelector((state) => state.prompts)
  const [testResponse, setTestResponse] = useState(null)
  const formattedModelOptions = rtnModelOptions.map((option) => ({
    value: option.name,
    label: option.display_name,
  }))

  useEffect(() => {
    if (!isEmpty(rtnPrompt.model)) setFieldValue('model', rtnPrompt.model)
  }, [rtnPrompt.model])

  const handleTestRTNPrompt = async () => {
    const response = await dispatch(
      getSinglePromptTestResults(
        values.prompt_id,
        {
          user_prompt: values.user_prompt,
          model: values.model,
          feature: 'summarization_test',
        },
        '',
        values.call_id
      )
    )

    setTestResponse(response)
  }

  const handleModelChange = (model) => {
    setFieldValue('model', model.value)
  }

  return (
    <div className="rtn-editor__prompt">
      <label>3. Test Your Prompt</label>
      <p className="muted-text">Run your instructions to generate a preview and verify results</p>
      <div className="rtn-prompt__additional-details">
        {organizationid === 1 && (
          <div className="ai-model-dropdown" data-testid="ai-model-dropdown-container">
            <div>AI Model</div>
            <div data-testid="ai-model-dropdown">
              <Field
                name="model"
                component={Select}
                options={formattedModelOptions}
                onChange={handleModelChange}
              />
            </div>
          </div>
        )}
        <div>
          <div>Call ID</div>
          <div className="flex-align-start small-gap">
            <div data-testid="call-id-field">
              <Field
                name="call_id"
                component={TextField}
                placeholder="Enter a CallID"
                className="call-id-field"
              />
            </div>
            <ConditionalTooltip
              condition={isEmpty(values.call_id)}
              content="You must add a call id"
              triggerProps={{ style: { display: 'inline-flex' } }}
            >
              <div data-testid="run-test-btn">
                <Button
                  secondary
                  onClick={handleTestRTNPrompt}
                  disabled={isEmpty(values.call_id)}
                  type="button"
                >
                  Run Test
                </Button>
              </div>
            </ConditionalTooltip>
          </div>
        </div>
        <div>
          {promptTestsLoading ? (
            <div className="empty-rtn-response" data-testid="test-result-loader">
              <Loader active inline />
            </div>
          ) : (
            testResponse?.parsed_response && (
              <div className="rtn-response-holder" data-testid="rtn-response-holder">
                <AgentSummaryView parsedSummary={testResponse?.parsed_response} />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}
