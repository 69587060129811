import * as Yup from 'yup'

export const deadAirValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  trigger: Yup.object({
    type: Yup.string().required('Trigger type is required'),
    time_unit: Yup.string().required('Time configuration is required'),
    threshold_time: Yup.string().required('Time configuration is required'),
  }),
})

export const talkSpeedValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  trigger: Yup.object({
    type: Yup.string().required('Trigger type is required'),
  }),
})

export const deadAirInitialValues = {
  name: '',
  trigger: {
    type: 'dead_air',
    time_unit: 'sec',
    threshold_time: 10,
  },
  display: {
    reason: '',
    type: 'language_substitution',
  },
}

export const talkSpeedInitialValues = {
  name: '',
  trigger: {
    type: 'words_per_minute',
    max_triggers: 1,
    side: 'me',
  },
  display: {
    reason: '',
    type: 'language_substitution',
  },
}

export const getValidationSchema = (isCreate, entry) => {
  // default to dead air as initial notification
  if (isCreate) {
    return deadAirValidationSchema
  }

  if (!entry) {
    return null
  }

  if (entry.trigger?.type === 'dead_air') {
    return deadAirValidationSchema
  }

  if (entry.trigger?.type === 'words_per_minute') {
    return talkSpeedValidationSchema
  }

  return null
}
