import React from 'react'
import { Form } from 'semantic-ui-react'
import { Field } from 'formik'

export const FieldWithLabel = ({
  label,
  name,
  required = false,
  type = 'text',
  component: Component,
  ...props
}) => {
  return (
    <Form.Field required={required} disabled={props.disabled}>
      <label>{label}</label>
      <Field name={name} component={Component} type={type} {...props} />
    </Form.Field>
  )
}
