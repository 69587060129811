import React from 'react'
import pluralize from 'pluralize'
import { Popup } from 'semantic-ui-react'

import { ModuleCard } from '@/components/cards/ModuleCard'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { RankedProgressBar } from '@/components/charts/RankedProgressBarChart/RankedProgressBar'
import { ExternalCallIdsLink } from '../components/ExternalCallIdsLink'

export const BarChartCard = ({ data, columns, title, loading, progressKey, maxDataValue }) => {
  const dataTestId = `overview-graph-${title.split(' ').join('-').toLowerCase()}`

  const rows = data.map((row) => {
    return {
      ...row,
      name: {
        value: row.name,
        as: (
          <>
            <div>
              <Popup
                inverted
                content={row.criteria_name}
                disabled={!row.criteria_name || row.name.length < 20}
                trigger={<strong className="no-wrap">{row.name}</strong>}
              />
            </div>
            <div>
              <ExternalCallIdsLink
                callIds={row.call_ids}
                label={`${row[progressKey]} ${pluralize('Call', row.call_ids?.length)}`}
                className="muted-text small-text"
              />
            </div>
          </>
        ),
      },
      [progressKey]: {
        value: row[progressKey],
        as: RankedProgressBar({
          color: '#4D63FE',
          width: (row[progressKey] / maxDataValue) * 100,
          value: row[progressKey],
          skinny: true,
          hideValue: true,
        }),
      },
    }
  })

  return (
    <ModuleCard title={title} data-testid={dataTestId} fullHeight>
      <AdvancedTable
        rows={rows}
        columns={columns}
        loading={loading}
        defaultOrder="desc"
        defaultOrderBy="call_count"
        index="uuid"
        striped={false}
        compact
        displayHeader={false}
        borderlessRows
      />
    </ModuleCard>
  )
}
