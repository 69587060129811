import React from 'react'
import { IconPencil, IconTrash } from '@tabler/icons-react'

import { Pill } from '@/components/pills/Pill'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'

import './SavedFiltersList.scss'

export const SavedFiltersList = ({ savedFilters = [], onEditClick, onDeleteClick }) => {
  const rows = savedFilters.map((filter) => ({
    uuid: filter.uuid,
    name: (
      <>
        {filter.name} {filter.is_default && <Pill small>Default</Pill>}
      </>
    ),
  }))

  const columns = [{ accessor: 'name', label: 'Name' }]

  const actions = [
    { label: 'Edit', icon: <IconPencil />, fn: onEditClick },
    {
      label: 'Delete',
      icon: <IconTrash />,
      fn: (e, row) => {
        onDeleteClick(row.uuid)
      },
    },
  ]

  return (
    <div className="saved-filters-list-wrapper">
      <AdvancedTable
        index="uuid"
        sortEnabled={false}
        rows={rows}
        columns={columns}
        actions={actions}
        pagination
        rowsPerPage={10}
      />
    </div>
  )
}
