import { renderLegendToCanvas } from '@nivo/legends'
import { useCustomNivoTheme } from './helpers'

export const CustomLegend = (canvasContext, props, { anchor, direction, legendItems }) => {
  const { innerWidth: width, innerHeight: height } = props
  const theme = useCustomNivoTheme()

  renderLegendToCanvas(canvasContext, {
    data: legendItems,
    translateY: -30,
    translateX: 70,
    containerWidth: width,
    containerHeight: height,
    itemWidth: 100,
    itemHeight: 15,
    symbolSize: 9,
    anchor,
    direction,
    itemTextColor: '#777',
    theme,
  })
}
