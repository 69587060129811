import React from 'react'
import { Form, Button } from 'semantic-ui-react'

const ConfirmForm = ({
  primaryButtonText,
  handleSubmit,
  modalClose,
  bodyText,
  valueToSubmit,
  danger,
}) => {
  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault()

        handleSubmit(valueToSubmit || event)
        modalClose(event)
      }}
    >
      <div style={{ margin: '0 0 1rem 0' }}>{bodyText}</div>

      <div className="modal-footer">
        <Button secondary type="button" data-testid="cancel-button" onClick={modalClose}>
          Cancel
        </Button>
        <Button
          primary={!danger}
          type="submit"
          data-testid="confirm-button"
          color={danger ? 'red' : undefined}
        >
          {primaryButtonText}
        </Button>
      </div>
    </Form>
  )
}

export default ConfirmForm
