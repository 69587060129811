import moment from 'moment'
import queryString from 'query-string'
import { toast } from 'react-toastify'

import {
  formatRtcFilters,
  formatRtcGraphData,
  getAbbreviatedName,
} from '@/views/RealtimeCoaching/Reports/ReportsHelpers'
import { apiService, fetchingAPI } from '../../../api'
import { setData, setLoading } from './realtimeReports.redux'

const rtcReportsBaseUrl = '/api/realtime_coaching/reports'

export const getPreviousPeriodQueryParams = (startDate, endDate, text = undefined) => {
  let queryStartDate = moment(startDate).startOf('day')
  let queryEndDate = moment(endDate).endOf('day')
  let queryParams

  /* If specific text supplied, we query for an
  entire month instead of a set amount of days */
  if (['thisMonth', 'lastMonth'].includes(text)) {
    queryStartDate = queryStartDate.clone().subtract(1, 'months').startOf('month')
    queryEndDate = queryEndDate.clone().subtract(1, 'months').endOf('month')

    queryParams = queryString.stringify({
      start_date: queryStartDate.toISOString(true),
      end_date: queryEndDate.toISOString(true),
    })
  } else {
    // you need to add a day because it loops over the same day if you dont.
    const daysInDateRange = queryEndDate.diff(queryStartDate, 'days') + 1

    queryParams = queryString.stringify({
      start_date: queryStartDate.subtract(daysInDateRange, 'd').toISOString(true),
      end_date: queryEndDate.subtract(daysInDateRange, 'd').toISOString(true),
    })
  }

  return queryParams
}

export const fetchTopAlertingAgents =
  ({
    startDate,
    endDate,
    limit = 5,
    order = 'descending',
    alertType,
    resource = 'agents',
    requestedOrgId,
  }) =>
  async (dispatch) => {
    let dataKey = null
    try {
      if (alertType === 'positive') {
        dataKey = 'topAlertingAgentsPositive'
      } else if (alertType === 'negative') {
        dataKey = 'topAlertingAgentsNegative'
      } else if (alertType === 'informative') {
        dataKey = 'topAlertingAgentsInformative'
      }

      if (!dataKey) {
        throw new Error('alertType should be one of positive, negative, or informative')
      }

      dispatch(setLoading({ [dataKey]: true }))

      const queryParams = queryString.stringify({
        limit,
        order,
        resource,
        alert_type: alertType,
        end_date: moment(endDate).toISOString(),
        start_date: moment(startDate).toISOString(),
        requested_org_id: requestedOrgId,
      })

      const { top_alerting_agents: agents } = await fetchingAPI(
        `${apiService.web}${rtcReportsBaseUrl}/top_agents?${queryParams}`,
        'GET',
        dispatch
      )

      dispatch(setData({ [dataKey]: agents }))
    } catch (err) {
      toast.error('Failed to fetch top alerting agents')
    } finally {
      dispatch(setLoading({ [dataKey]: false }))
    }
  }

export const fetchTopAlertConfigs =
  ({ startDate, endDate, order = 'descending', limit = 5, requestedOrgId }) =>
  async (dispatch) => {
    try {
      const queryParams = queryString.stringify({
        limit,
        order,
        end_date: moment(endDate).toISOString(),
        start_date: moment(startDate).toISOString(),
        requested_org_id: requestedOrgId,
      })

      dispatch(setLoading({ topAlertConfigs: true }))

      const { top_alert_configs: topAlertConfigs } = await fetchingAPI(
        `${apiService.web}${rtcReportsBaseUrl}/top_alert_configs?${queryParams}`,
        'GET',
        dispatch
      )

      dispatch(setData({ topAlertConfigs }))
    } catch (err) {
      toast.error('Failed to fetch top alerts')
    } finally {
      dispatch(setLoading({ topAlertConfigs: false }))
    }
  }

export const fetchTopSupervisorChatOccurrences =
  ({ startDate, endDate, order = 'descending', limit = 5, requestedOrgId }) =>
  async (dispatch) => {
    try {
      const queryParams = queryString.stringify({
        limit,
        order,
        end_date: moment(endDate).toISOString(),
        start_date: moment(startDate).toISOString(),
        requested_org_id: requestedOrgId,
      })

      dispatch(setLoading({ topChattingSupervisors: true }))

      const response = await fetchingAPI(
        `${apiService.web}${rtcReportsBaseUrl}/top_supervisor_chats?${queryParams}`,
        'GET',
        dispatch
      )
      const topChattingSupervisors = response.top_supervisor_chats.map((supervisorChat) => {
        return {
          supervisor: `${supervisorChat.first_name} ${supervisorChat.last_name}`,
          chats: supervisorChat.chats,
          calls_with_chats: supervisorChat.calls_with_chats,
        }
      })

      dispatch(setData({ topChattingSupervisors }))
    } catch (err) {
      toast.error('Failed to fetch top supervisor chats')
    } finally {
      dispatch(setLoading({ topChattingSupervisors: false }))
    }
  }

export const fetchTopChattingSupervisor =
  ({ startDate, endDate, requestedOrgId }) =>
  async (dispatch) => {
    try {
      const queryParams = queryString.stringify({
        limit: 1,
        order: 'descending',
        end_date: moment(endDate).toISOString(),
        start_date: moment(startDate).toISOString(),
        requested_org_id: requestedOrgId,
      })

      dispatch(setLoading({ topChattingSupervisor: true }))

      const response = await fetchingAPI(
        `${apiService.web}${rtcReportsBaseUrl}/top_supervisor_chats?${queryParams}`,
        'GET',
        dispatch
      )
      const abbreviatedName = getAbbreviatedName(response.top_supervisor_chats)
      dispatch(setData({ topChattingSupervisor: abbreviatedName }))
    } catch (err) {
      toast.error('Failed to fetch top chatting supervisor')
    } finally {
      dispatch(setLoading({ topChattingSupervisor: false }))
    }
  }

export const fetchCallAlertPercentage =
  (startDate, endDate, requestedOrgId) => async (dispatch) => {
    dispatch(setLoading({ callAlertPercentage: true }))

    try {
      const queryParams = queryString.stringify({
        end_date: moment(endDate).toISOString(),
        start_date: moment(startDate).toISOString(),
        requested_org_id: requestedOrgId,
      })
      const response = await fetchingAPI(
        `${apiService.web}${rtcReportsBaseUrl}/call_alert_percentage?${queryParams}`,
        'GET',
        dispatch
      )

      dispatch(setData({ callAlertPercentage: response.call_alert_percentage }))
    } catch (err) {
      toast.error('Failed to fetch call alert percentage')
    } finally {
      dispatch(setLoading({ callAlertPercentage: false }))
    }
  }

export const fetchPreviousPeriodCallAlertPercentage =
  (startDate, endDate, text, requestedOrgId) => async (dispatch) => {
    dispatch(setLoading({ previousPeriodCallAlertPercentage: true }))

    try {
      const queryParams = `${getPreviousPeriodQueryParams(
        startDate,
        endDate,
        text
      )}&requested_org_id=${requestedOrgId}`

      const response = await fetchingAPI(
        `${apiService.web}${rtcReportsBaseUrl}/call_alert_percentage?${queryParams}`,
        'GET',
        dispatch
      )

      dispatch(setData({ previousPeriodCallAlertPercentage: response.call_alert_percentage }))
    } catch (err) {
      toast.error('Failed to fetch the previous period call alert percentage')
    } finally {
      dispatch(setLoading({ previousPeriodCallAlertPercentage: false }))
    }
  }

export const fetchAlertCountsByTypeByDay =
  (startDate, endDate, requestedOrgId) => async (dispatch) => {
    dispatch(setLoading({ alertCountsByTypeByDay: true }))

    try {
      const queryParams = queryString.stringify({
        end_date: moment(endDate).toISOString(true),
        start_date: moment(startDate).toISOString(true),
        requested_org_id: requestedOrgId,
      })
      const response = await fetchingAPI(
        `${apiService.web}${rtcReportsBaseUrl}/alert_counts?${queryParams}`,
        'GET',
        dispatch
      )

      if (response.alert_counts) {
        dispatch(setData({ alertCountsByTypeByDay: response.alert_counts }))
      }
    } catch (err) {
      toast.error('Failed to fetch alert counts')
    } finally {
      dispatch(setLoading({ alertCountsByTypeByDay: false }))
    }
  }

export const fetchPreviousPeriodAlertCountsByTypeByDay =
  (startDate, endDate, text, requestedOrgId) => async (dispatch) => {
    dispatch(setLoading({ previousPeriodAlertCountsByTypeByDay: true }))

    try {
      const queryParams = `${getPreviousPeriodQueryParams(
        startDate,
        endDate,
        text
      )}&requested_org_id=${requestedOrgId}`
      const response = await fetchingAPI(
        `${apiService.web}${rtcReportsBaseUrl}/alert_counts?${queryParams}`,
        'GET',
        dispatch
      )

      if (response.alert_counts) {
        dispatch(setData({ previousPeriodAlertCountsByTypeByDay: response.alert_counts }))
      }
    } catch (err) {
      toast.error('Failed to fetch alert counts')
    } finally {
      dispatch(setLoading({ previousPeriodAlertCountsByTypeByDay: false }))
    }
  }

// The Top Listener KPI Card
export const fetchTopListenerSupervisor =
  ({ startDate, endDate, requestedOrgId }) =>
  async (dispatch) => {
    try {
      const queryParams = queryString.stringify({
        endDate: moment(endDate).toISOString(),
        startDate: moment(startDate).toISOString(),
        requested_org_id: requestedOrgId,
        order: 'descending',
        limit: 1,
      })

      dispatch(setLoading({ topListenerSupervisor: true }))

      const response = await fetchingAPI(
        `${apiService.web}${rtcReportsBaseUrl}/top_supervisor_listeners?${queryParams}`,
        'GET',
        dispatch
      )
      const abbreviatedName = getAbbreviatedName(response.users)
      dispatch(setData({ topListenerSupervisor: abbreviatedName }))
    } catch (err) {
      toast.error('Failed to the fetch top listening supervisor')
    } finally {
      dispatch(setLoading({ topListenerSupervisor: false }))
    }
  }

// Top Listen Overview table uses this
export const fetchTopListenerSupervisors =
  ({ startDate, endDate, requestedOrgId, order = 'descending' }) =>
  async (dispatch) => {
    try {
      const queryParams = queryString.stringify({
        endDate: moment(endDate).toISOString(),
        startDate: moment(startDate).toISOString(),
        requested_org_id: requestedOrgId,
        order,
        limit: 5,
      })

      dispatch(setLoading({ topListenerSupervisors: true }))

      const response = await fetchingAPI(
        `${apiService.web}${rtcReportsBaseUrl}/top_supervisor_listeners?${queryParams}`,
        'GET',
        dispatch
      )
      const topListenerSupervisors = response.users.map((supervisor) => {
        return {
          ...supervisor,
          supervisor: `${supervisor.first_name} ${supervisor.last_name}`,
          count: supervisor.listens,
        }
      })
      dispatch(setData({ topListenerSupervisors }))
    } catch (err) {
      toast.error('Failed to fetch the top listening supervisors')
    } finally {
      dispatch(setLoading({ topListenerSupervisors: false }))
    }
  }

//  The Drilldown Page uses this
export const applyFilters = (resource, requestedOrgId) => async (dispatch, getState) => {
  // Get the query string
  const { realtimeCoaching } = getState()
  const { dateRange, dateRangeIsValid, groupBy } = realtimeCoaching.filters
  const includeManagers = true
  if (!dateRangeIsValid) {
    return
  }

  const filterString = `${formatRtcFilters(
    realtimeCoaching.filters,
    includeManagers
  )}&requested_org_id=${requestedOrgId}`
  dispatch(setData({ groupBy }))
  dispatch(setLoading({ drillDownGraph: true }))
  dispatch(setLoading({ drillDownTable: true }))

  // Apply the filters
  try {
    let { users } = await fetchingAPI(
      `${apiService.web}${rtcReportsBaseUrl}/${resource}_drilldown_report?${filterString}`,
      'GET',
      dispatch
    )
    if (resource === 'chat') {
      users = users.map((user) => {
        return {
          ...user,
          supervisor: `${user.first_name} ${user.last_name}`,
          count: user.chats,
        }
      })
    } else if (resource === 'listen') {
      users = users.map((user) => {
        return {
          ...user,
          supervisor: `${user.first_name} ${user.last_name}`,
          count: user.listens,
        }
      })
    }
    dispatch(setData({ drillDownTable: users }))
    const formattedGraphData = formatRtcGraphData(users, resource, dateRange, groupBy)
    dispatch(setData({ drillDownGraph: formattedGraphData }))
  } catch (err) {
    toast.error('Failed to fetch drilldown page data')
  } finally {
    dispatch(setLoading({ drillDownGraph: false }))
    dispatch(setLoading({ drillDownTable: false }))
  }
}
