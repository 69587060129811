import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import {
  handleShortcutChangeScore,
  handleShortcutNext,
  handleShortcutPrevious,
} from '@/reducers/callSearch/callSearch.actions'
import { Shortcuts } from '@/components/interactions/Shortcuts'
import { updateCallExplorerParams } from './helpers'

export const QAScoreShortcuts = ({ handleChangeScore, filteredCriteriaOrdered, handleSubmit }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const qaShortcuts = [
    {
      label: 'Save Score',
      value: 'q',
      handleShortcut: handleSubmit,
    },
    {
      label: 'Pass',
      value: 'e',
      handleShortcut: () => {
        dispatch(handleShortcutChangeScore('pass', handleChangeScore, filteredCriteriaOrdered))
      },
    },
    {
      label: 'Fail',
      value: 'r',
      handleShortcut: () => {
        dispatch(handleShortcutChangeScore('fail', handleChangeScore, filteredCriteriaOrdered))
      },
    },
    {
      label: 'N/A',
      value: 't',
      handleShortcut: () => {
        dispatch(handleShortcutChangeScore('na', handleChangeScore, filteredCriteriaOrdered))
      },
    },
    {
      label: 'Prev',
      value: 'w',
      visible: true,
      handleShortcut: async () => {
        const prevCriteria = await dispatch(handleShortcutPrevious(filteredCriteriaOrdered))

        if (prevCriteria) {
          updateCallExplorerParams(location, history, { criteriaScoreId: prevCriteria.id })
        }
      },
    },
    {
      label: 'Next',
      value: 's',
      visible: true,
      handleShortcut: async () => {
        const nextCriteria = await dispatch(handleShortcutNext(filteredCriteriaOrdered))

        if (nextCriteria) {
          updateCallExplorerParams(location, history, { criteriaScoreId: nextCriteria.id })
        }
      },
    },
  ]

  return <Shortcuts shortcuts={qaShortcuts} />
}
