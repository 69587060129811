import React, { useEffect, useState } from 'react'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { Form } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { fetchAdvancedExportsPlaybooks } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'

export const AdvancedExportsPlaybooksDropdown = () => {
  const dispatch = useDispatch()
  const [playbookSearchQuery, setPlaybookSearchQuery] = useState('')
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const { data, filters, loading } = useSelector((state) => state.realtimeCoaching)

  useEffect(() => {
    if (isEmpty(data.playbooksAdvancedExports)) {
      dispatch(fetchAdvancedExportsPlaybooks(organizationId))
    }
  }, [])

  const onFilterChange = (values) => {
    dispatch(setFilter({ playbooksAdvancedExports: values }))
  }

  return (
    <Form.Field data-testid="rtc-playbooks-dropdown">
      <label>Playbooks</label>
      <MultiSelect
        isSelectAll
        placeholderPill="All Playbooks"
        options={
          !playbookSearchQuery && data.playbooksAdvancedExports.length > 1000
            ? []
            : data.playbooksAdvancedExports
        }
        optionsLength={data.playbooksAdvancedExports.length}
        loading={loading.playbooksAdvancedExports}
        value={filters.playbooksAdvancedExports}
        onInputChange={(inputValue) => setPlaybookSearchQuery(inputValue)}
        onChange={(values) => onFilterChange(values)}
        noOptionsMessage={() =>
          playbookSearchQuery ? 'No results found' : 'Begin typing to search...'
        }
      />
    </Form.Field>
  )
}
