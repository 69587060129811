import React from 'react'
import { Formik } from 'formik'

import { getInitialCriteriaValues } from './helpers'
import { QAScoreEditHeader } from './QAScoreEditHeader'
import { QAScoreEditContent } from './QAScoreEditContent'

import './QAScore.scss'

export const QAScoreEditMode = ({ score, handleCancelEdit, handleSaveScore, callId }) => {
  const initialCriteriaValues = getInitialCriteriaValues(score)

  return (
    <Formik
      initialValues={initialCriteriaValues}
      onSubmit={(values) => handleSaveScore(values, initialCriteriaValues)}
      enableReinitialize
    >
      {({ handleSubmit, ...formikProps }) => {
        return (
          <>
            <QAScoreEditHeader
              handleSubmit={handleSubmit}
              handleCancelEdit={handleCancelEdit}
              score={score}
            />
            <QAScoreEditContent
              score={score}
              callId={callId}
              formikProps={formikProps}
              handleSubmit={handleSubmit}
            />
          </>
        )
      }}
    </Formik>
  )
}
