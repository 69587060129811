import React from 'react'

export const AudioTrackProgress = ({ isDisabled, duration, progress, handleScrub }) => {
  return (
    <input
      data-testid="audio-track-progress"
      type="range"
      className={{ disabled: isDisabled }}
      max={duration}
      value={progress}
      step={0.01}
      onChange={handleScrub}
      disabled={isDisabled}
    />
  )
}
