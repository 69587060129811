import React from 'react'

import { FilterTypeText } from './FilterTypeText'
import { FilterTypeDate } from './FilterTypeDate'
import { FilterTypeSelect } from './FilterTypeSelect'
import { FilterTypeMultiSelect } from './FilterTypeMultiSelect'

export const FilterComponent = ({ type, ...props }) => {
  const schemaMap = {
    text: FilterTypeText,
    select: FilterTypeSelect,
    multiSelect: FilterTypeMultiSelect,
    date: FilterTypeDate,
  }

  const Component = schemaMap[type]

  return <Component {...props} />
}
