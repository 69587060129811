import React from 'react'
import { IconFilter, IconSearch } from '@tabler/icons-react'
import { Popup } from 'semantic-ui-react'
import classNames from 'classnames'

import { getFilterPopupLabel } from './filter.helpers'

export const FilterColumnButton = ({
  type = 'text',
  columnAccessor,
  displayLabel,
  value,
  hasValue,
}) => {
  const filterPopupLabel = getFilterPopupLabel(type, value, hasValue, displayLabel)

  return (
    <Popup
      inverted
      position="top center"
      trigger={
        <button
          data-testid={`${columnAccessor}-search-button`}
          type="button"
          className={classNames('table-header-button', { 'filter-applied': hasValue })}
        >
          {type === 'text' ? <IconSearch /> : <IconFilter />}
        </button>
      }
      content={filterPopupLabel}
    />
  )
}
