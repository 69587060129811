import { fetchingAPI, apiService } from '../../api'
import { csvStreamingComplete, updateUploadResponse } from './organizationUsersCSV.redux'

export const uploadUserCSV =
  ({ tags, users, newTags, configNames, organizationid }) =>
  async (dispatch) => {
    try {
      const csvStream = await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationid}/users/upload`,
        'POST',
        dispatch,
        JSON.stringify({ tags, users, newTags, configNames })
      )
      const responseReader = csvStream.getReader()
      const readStream = () =>
        responseReader.read().then(({ value, done }) => {
          if (done) {
            return dispatch(csvStreamingComplete())
          }
          const decodedResponseData = new TextDecoder('utf-8').decode(value)

          dispatch(
            updateUploadResponse(
              decodedResponseData
                .split('\n')
                .filter((row) => row !== '')
                .map((row) => row.split(','))
            )
          )
          return readStream()
        })
      readStream()
    } catch (err) {
      // catching error
    }
  }
