import moment from 'moment'

export const getTimestamp = (data) => {
  const firstTimestamp = data.find((line) => line.timestamp)
  if (!firstTimestamp) {
    return ''
  }
  const seconds = moment.utc(Math.floor(firstTimestamp.timestamp) * 1000).format('HH:mm:ss')

  return seconds
}

export const getUpdatedPostcallTranscript = (postcallTranscript) => {
  return postcallTranscript.map((item) => {
    return {
      data: item.data
        ? item.type === 'conversation'
          ? item.data
              .filter((item) => item.text)
              .reduce((acc, item) => {
                if (!acc) {
                  return item.text
                }
                acc += ` ${item.text}`
                return acc
              }, '')
          : ''
        : item.type === 'silence'
          ? `${Math.floor(item.duration)} seconds of silence`
          : '',
      side: item.side === 0 ? 'Agent' : 'Customer',
      timestamp: item.data
        ? getTimestamp(item.data)
        : item.timestamp
          ? moment.utc(Math.floor(item.timestamp) * 1000).format('HH:mm:ss')
          : '',
    }
  })
}
