import React from 'react'

export const WinIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1614_43830)">
        <path
          d="M8.34033 3L10.0913 4.27733L12.259 4.27333L12.9247 6.336L14.6807 7.60667L14.007 9.66667L14.6807 11.7267L12.9247 12.9973L12.259 15.06L10.0913 15.056L8.34033 16.3333L6.58933 15.056L4.42167 15.06L3.756 12.9973L2 11.7267L2.67367 9.66667L2 7.60667L3.756 6.336L4.42167 4.27333L6.58933 4.27733L8.34033 3Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M6.00684 9.66667L7.6735 11.3333L11.0068 8" fill="currentColor" />
        <path
          d="M6.00684 9.66667L7.6735 11.3333L11.0068 8"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1614_43830">
          <rect width="16" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
