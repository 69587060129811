import React from 'react'
import { Form } from 'semantic-ui-react'

import './ActivityLogFilters.css'
import { AgentsDropdown } from '@/views/RealtimeCoaching/components/filters/AgentsDropdown'
import { TagsDropdown } from '@/views/RealtimeCoaching/components/filters/TagsDropdown'
import { AlertTypesDropdown } from '@/views/RealtimeCoaching/components/filters/AlertTypesDropdown'
import { UserAlertNameDropdown } from '@/views/RealtimeCoaching/components/filters/UserAlertNameDropdown'

export const ActivityLogFilters = () => {
  return (
    <Form data-testid="activity-log-filters" className="activity-log-filters_container">
      <div className="filter-grid">
        <AgentsDropdown />
        <TagsDropdown />
        <AlertTypesDropdown />
        <UserAlertNameDropdown />
      </div>
    </Form>
  )
}
