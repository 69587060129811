import React from 'react'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { useDispatch, useSelector } from 'react-redux'
import { Image, Radio, Segment } from 'semantic-ui-react'
import {
  activateBadge,
  createBadgeAndActivateBadge,
  deactivateBadge,
} from '@/reducers/gamification/gamification.actions'
import { closeModal, openModal } from '@/reducers/ui/ui.redux'
import { FormModal } from '@/components/layout/modals/FormModal'

const columns = [
  {
    accessor: 'name',
    label: 'Badge Name',
    isSearchable: true,
  },
  {
    accessor: 'status',
    label: 'Status',
  },
  {
    accessor: 'collectionName',
    label: 'Collection Name',
    isSearchable: true,
  },
  {
    accessor: 'description',
    label: 'Description',
    isSearchable: true,
  },
]

export const CollectionBadgePage = ({ badgeTemplates, redirectToBadgeDetailPage }) => {
  const dispatch = useDispatch()
  const { currentlyOpenModalId: modal } = useSelector((state) => state.ui)
  const { loading } = useSelector((state) => state.gamification)

  const onClose = () => {
    dispatch(closeModal())
  }

  const openStatusModal = (badgeTemplate) => {
    dispatch(openModal(`changeBadgeStatus--${badgeTemplate.templateId}`))
  }
  const toggleBadge = (badgeTemplate) => {
    if (!badgeTemplate.orgHasBadge) {
      dispatch(createBadgeAndActivateBadge(badgeTemplate.templateId))
    } else if (badgeTemplate.badgeActive) {
      dispatch(deactivateBadge(badgeTemplate.templateId))
    } else {
      dispatch(activateBadge(badgeTemplate.templateId))
    }
    onClose()
  }

  const rows = badgeTemplates.map((badgeTemplate, idx) => ({
    ...badgeTemplate,
    id: idx,
    index: badgeTemplate.templateId,
    description: badgeTemplate?.description || badgeTemplate?.templateDescription,
    name: {
      as: (
        <div className="badge-image-name-container">
          <Image
            className="badge-image"
            data-testid="badge-image"
            src={badgeTemplate.imageUrl}
            circular
            size="mini"
          />
          <div className="badge-name">{badgeTemplate.name || badgeTemplate.templateName}</div>
        </div>
      ),
      value: badgeTemplate.name || badgeTemplate.templateName,
      cellProps: {
        onClick: badgeTemplate.id ? () => redirectToBadgeDetailPage(badgeTemplate.id) : () => {},
        disabled: !badgeTemplate.id,
        style: {
          cursor: !badgeTemplate.id ? 'text' : 'pointer',
        },
      },
    },
    status: {
      as: (
        <div>
          <Radio
            data-testid={`badge-active-toggle-${badgeTemplate.id}`}
            className="badge-status"
            toggle
            checked={badgeTemplate.badgeActive}
            onChange={() => openStatusModal(badgeTemplate)}
            style={{ width: '82px' }}
          />
          {modal === `changeBadgeStatus--${badgeTemplate.templateId}` && (
            <FormModal
              title={badgeTemplate.badgeActive ? 'Deactivate Badge' : 'Activate Badge'}
              onSave={() => toggleBadge(badgeTemplate)}
              onClose={onClose}
              closeButtonLabel="Cancel"
              submitButtonLabel="Confirm"
              size="small"
              show={modal === `changeBadgeStatus--${badgeTemplate.templateId}`}
            >
              <div
                data-testid="confirm-status-modal"
                className="scorecard-editor__active-modal-content"
              >
                Are you sure you want to {badgeTemplate.badgeActive ? 'deactivate' : 'activate'} the
                &ldquo;{badgeTemplate.templateName}
                &rdquo; badge?
              </div>
            </FormModal>
          )}
        </div>
      ),
      value: badgeTemplate.badgeActive,
    },
  }))

  return (
    <div data-testid="collection-badges-page" className="collection-badges">
      <Segment className="not-padded">
        <AdvancedTable
          loading={loading.badgeTemplates}
          rows={rows}
          columns={columns}
          pagination
          hoverable={false}
        />
      </Segment>
    </div>
  )
}

export default CollectionBadgePage
