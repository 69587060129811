import { useCustomNivoTheme } from '@/components/charts/customCharts/helpers'
import { useMeasure, useMotionConfig } from '@nivo/core'
import React, { useRef } from 'react'
import { animated, useSpring } from '@react-spring/web'

const TOOLTIP_OFFSET = 14

const tooltipStyle = {
  pointerEvents: 'none',
  position: 'absolute',
  zIndex: 10,
  top: 0,
  left: 0,
}

const translate = (x, y) => `translate(${x}px, ${y}px)`

export const TooltipWrapper = ({ position, anchor, children }) => {
  const theme = useCustomNivoTheme()
  const { animate, config: springConfig } = useMotionConfig() || {}
  const [measureRef, bounds] = useMeasure()
  const previousPosition = useRef(false)

  let translateTo
  let immediate = false
  const hasDimension = bounds.width > 0 && bounds.height > 0

  let x = Math.round(position[0])
  let y = Math.round(position[1])

  if (hasDimension) {
    if (anchor === 'top') {
      x -= bounds.width / 2
      y -= bounds.height + TOOLTIP_OFFSET
    } else if (anchor === 'right') {
      x += TOOLTIP_OFFSET
      y -= bounds.height / 2
    } else if (anchor === 'bottom') {
      x -= bounds.width / 2
      y += TOOLTIP_OFFSET
    } else if (anchor === 'left') {
      x -= bounds.width + TOOLTIP_OFFSET
      y -= bounds.height / 2
    } else if (anchor === 'center') {
      x -= bounds.width / 2
      y -= bounds.height / 2
    }

    translateTo = {
      transform: translate(x || 0, y || 0),
    }

    if (!previousPosition.current) {
      immediate = true
    }

    previousPosition.current = [x, y]
  }

  const animatedProps = useSpring({
    to: translateTo,
    config: springConfig,
    immediate: !animate || immediate,
  })

  const style = {
    ...tooltipStyle,
    ...theme.tooltip,
    transform: animatedProps.transform ?? translate(x, y),
  }

  return (
    <animated.div ref={measureRef} style={style}>
      {children}
    </animated.div>
  )
}
