import React from 'react'
import classNames from 'classnames'

export const PasswordRequirement = ({ requirements, criteria, children }) => {
  return (
    <div
      data-testid={requirements[criteria] ? 'pass' : 'fail'}
      className={classNames('flex', {
        'status-success': requirements[criteria],
        'muted-text': !requirements[criteria],
      })}
    >
      <i
        className={classNames('circle icon', {
          check: requirements[criteria],
          outline: !requirements[criteria],
        })}
      />
      <div>{children}</div>
    </div>
  )
}
