import React from 'react'

import { LeaderboardFilters } from './LeaderboardFilters'
import { LeaderboardTable } from './LeaderboardTable'

const LeaderboardsPage = () => {
  return (
    <div data-testid="leaderboards-page">
      <LeaderboardFilters />
      <LeaderboardTable />
    </div>
  )
}

export default LeaderboardsPage
