import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Card, Grid, Radio } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { cloneDeep, isEmpty, isEqual } from 'lodash'
import * as Yup from 'yup'
import { IconAlertTriangle } from '@tabler/icons-react'

import { Banner } from '@/components/banners/Banner'

import { ScorecardEditorCard } from './ScorecardEditorCard'
import { Breadcrumbs } from '../../components/forms/Breadcrumbs/Breadcrumbs'
import { setData } from '../../reducers/scorecards/scorecards.redux'
import { SpinnerLoader } from '../../components/loader/SpinnerLoader'
import { BasicModal } from '../../components/layout/modals/BasicModal'
import { FormModal } from '../../components/layout/modals/FormModal'
import {
  createScorecardVersion,
  editScorecardSettings,
  getMissingCriteria,
  newCustomSection,
  removeMissingCriteria,
  toggleScorecardActive,
  updatePlaybookVersionForScorecard,
} from '../../reducers/scorecards/scorecards.actions'
import { closeModal, openModal } from '../../reducers/ui/ui.redux'
import './scorecards.css'
import { SectionWeightForm } from './forms/EditSectionsForm'
import { ViewScorecardSummary } from './ViewScorecardSummary/ViewScorecardSummary'
import { UpdatePlaybookForm } from './forms/UpdatePlaybookForm'

import { scorecardHasCriteria, addSectionInScorecardConfig, giveOrderId } from './helpers/index'
import { SecondaryNavRouter } from '../../components/layout/SecondaryNavRouter/SecondaryNavRouter'
import { InfoPage } from './forms/AddScorecardForm'
import { AbstractForm } from '../../components/forms/formik/AbstractForm'

export const ScorecardEditorSectionPage = ({ sections, scorecardConfig, openSectionsModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { currentlyOpenModalId: modal } = useSelector((state) => state.ui)

  const redirectToEdit = (cardData) => {
    dispatch(setData('targetScorecardSection', cardData))
    history.push(`${scorecardConfig.id}/edit_section/${cardData.id}`)
  }

  const createCustomSection = async (formValues) => {
    const configDraft = addSectionInScorecardConfig(scorecardConfig, formValues.name)
    const { newScorecard } = await dispatch(newCustomSection(configDraft))
    dispatch(closeModal())
    await history.push(`/scorecards/edit_config/${newScorecard.id}`)
  }

  const sectionCards = sections
    ? sections.map((section) => (
        <ScorecardEditorCard cardData={section} onClick={redirectToEdit} key={section.name} />
      ))
    : null

  return (
    <div>
      {modal === 'createCustomSections' && (
        <BasicModal
          data-testid="custom-section-modal"
          title="Create Section"
          onClose={() => dispatch(closeModal())}
          show={!!modal}
          size="tiny"
        >
          <AbstractForm
            schema={[{ name: 'name', type: 'text', label: 'Section Name', required: true }]}
            onSubmit={createCustomSection}
            onClose={() => dispatch(closeModal())}
            buttonLabel="Create"
          />
        </BasicModal>
      )}
      <div className="scorecard-editor">
        <div className="scorecard-editor__grid-top-menu">
          <Grid>
            <Grid.Row stretched>
              <Grid.Column width="3">
                <h3>Sections</h3>
              </Grid.Column>
              <Grid.Column width="3" floated="right">
                <Button
                  primary
                  data-testid="weight-button"
                  style={{ marginLeft: '16px', marginRight: '16px' }}
                  onClick={openSectionsModal}
                >
                  Edit Sections
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div className="scorecard-editor-grid">
          <Card.Group>{sectionCards || <SpinnerLoader />}</Card.Group>
        </div>
      </div>
    </div>
  )
}

export const ScorecardEditorSettingsPage = ({ scorecardConfig, handleSubmit }) => {
  const { loading } = useSelector((state) => state.scorecards)

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required.')
      .max(100, 'Name cannot be longer than 100 characters.'),
    organizationId: Yup.number(),
    config_id: Yup.number().required('Scorecard config must be linked to a playbook.'),
    exceeds_threshold: Yup.number()
      .required('Required field.')
      .moreThan(Yup.ref('meets_threshold'), 'Cannot be less than meets.')
      .max(100, 'Cannot be more than 100.'),
    meets_threshold: Yup.number()
      .required('Required field.')
      .moreThan(Yup.ref('improvement_threshold'), 'Cannot be less than below.')
      .lessThan(Yup.ref('exceeds_threshold'), 'Cannot be more than exceeds.'),
    improvement_threshold: Yup.number()
      .required('Required field.')
      .lessThan(Yup.ref('exceeds_threshold'), 'Cannot be more than exceeds.')
      .lessThan(Yup.ref('meets_threshold'), 'Cannot be more than meets.')
      .min(0, 'Cannot be less than 0.'),
    min_duration_in_minutes: Yup.number()
      .required('Min. Call Duration is required.')
      .min(0, 'Cannot be less than 0.'),
  })

  const isFormNotDirty = (values, initialValues) => {
    return isEqual(values, initialValues)
  }

  return (
    <Formik
      initialValues={scorecardConfig}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(props) => (
        <Form className="editor__settings-form" data-testid="editor__settings-form">
          <InfoPage values={scorecardConfig} disablePlaybookSelect />
          <Button
            primary
            disabled={
              !isEmpty(props.errors) ||
              loading.scorecards ||
              isFormNotDirty(props.values, props.initialValues)
            }
            loading={loading.scorecards}
            type="submit"
            data-testid="submit-settings-page"
          >
            Save
          </Button>
        </Form>
      )}
    </Formik>
  )
}

// redux information is dispatched from the Wrapper for both section and config editor
export const ScorecardEditor = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { data, loading } = useSelector((state) => state.scorecards)
  const { currentlyOpenModalId: modal } = useSelector((state) => state.ui)

  const {
    id: scorecard_config_id,
    config_id,
    active,
    name,
    min_duration_in_seconds,
    playbook_up_to_date,
    sections = [],
  } = data.targetScorecardConfig

  const { organization_id: configOrgId } = data.targetPlaybook

  const missingCriteriaLoader = loading.missingCriteria
  const missingCriteria = data.targetScorecardMissingCriteria

  const [sectionState, setSectionState] = useState(sections)

  useEffect(() => {
    if (config_id && scorecard_config_id && !playbook_up_to_date) {
      dispatch(getMissingCriteria(config_id, scorecard_config_id))
    }
    return function cleanup() {
      dispatch(removeMissingCriteria())
    }
  }, [data.targetScorecardConfig])

  const dispatchOpenModal = (value) => {
    dispatch(openModal(value))
  }

  const dispatchCloseModal = () => {
    dispatch(closeModal())
  }

  const handleSectionSubmit = async (updatedSections) => {
    const sectionList = Object.values(updatedSections).map((value) => ({
      ...value,
      weight: value.active ? value.weight : 0,
    }))
    const updatedScorecard = await dispatch(
      createScorecardVersion({
        ...data.targetScorecardConfig,
        sections: sectionList,
      })
    )
    if (!updatedScorecard) return
    dispatchCloseModal()
    history.push(`/scorecards/edit_config/${updatedScorecard.id}`)
  }

  const toggleActiveStatus = () => {
    dispatch(toggleScorecardActive(data.targetScorecardConfig))
    dispatchCloseModal()
  }

  const updatePlaybookVersion = async () => {
    const updatedScorecard = await dispatch(
      updatePlaybookVersionForScorecard(data.targetScorecardConfig)
    )
    if (!updatedScorecard) return
    dispatchCloseModal()
    history.push(`/scorecards/edit_config/${updatedScorecard.id}`)
  }

  const submitSectionChange = async () => {
    const updatedConfig = {
      ...data.targetScorecardConfig,
      sections: sectionState,
    }
    const updatedScorecard = await dispatch(createScorecardVersion(updatedConfig))
    dispatchCloseModal()
    history.push(`/scorecards/edit_config/${updatedScorecard.id}`)
  }

  const handleActivate = () => {
    const hasCriteria = scorecardHasCriteria(data.targetScorecardConfig)
    if (hasCriteria) {
      dispatchOpenModal('active')
    } else {
      dispatchOpenModal('noCriteria')
    }
  }

  const updateSectionsObject = (formValues) => {
    const updateSectionDraft = cloneDeep(formValues)
    setSectionState(updateSectionDraft)
  }

  const openSectionsModal = () => {
    const updateSections = sections.map((section, index) =>
      !section.order_id ? giveOrderId(section, index) : section
    )
    setSectionState(updateSections)
    dispatch(openModal('weight'))
  }

  const handleSubmit = async ({
    sid,
    name,
    exceeds_threshold,
    meets_threshold,
    improvement_threshold,
    min_duration_in_minutes,
  }) => {
    await dispatch(
      editScorecardSettings({
        sid,
        name,
        exceeds_threshold,
        meets_threshold,
        improvement_threshold,
        min_duration_in_minutes,
      })
    )
  }

  const backLinks = {
    label: 'Scorecards',
    link: '/scorecards',
  }

  const mustReview = !playbook_up_to_date && missingCriteria && missingCriteria.length

  const reviewAndUpdateMessage =
    'Update to the latest playbook version in order for Balto to continue scoring calls. Please be aware some criteria will be deleted as they have changed.'

  const updateWithoutReviewMessage =
    'Update to the latest playbook version in order for Balto to continue scoring calls. No changes will be made to your scorecard.'

  const pages = [
    {
      label: 'Sections',
      component: (
        <ScorecardEditorSectionPage
          sections={sections}
          scorecardConfig={data.targetScorecardConfig}
          openSectionsModal={openSectionsModal}
        />
      ),
    },
    {
      label: 'Settings',
      component: (
        <ScorecardEditorSettingsPage
          scorecardConfig={{
            ...data.targetScorecardConfig,
            min_duration_in_minutes: min_duration_in_seconds / 60,
            organization_id: configOrgId,
          }}
          handleSubmit={handleSubmit}
        />
      ),
    },
  ]

  return (
    <div className="scorecard-editor-content" data-testid="scorecard-editor">
      <Breadcrumbs backLinks={backLinks} currentLink={name} />
      <div className="page-header">
        <h1 data-testid="scorecard-editor-name">{name || 'My Scorecard'}</h1>
        <div className="flex-align-center gap">
          <Radio
            checked={active}
            label={active ? 'Deactivate' : 'Activate'}
            toggle
            onClick={() => handleActivate()}
            data-testid="scorecard-active-toggle"
          />

          <Button
            secondary
            onClick={() => dispatchOpenModal('summary')}
            data-testid="view-summary-button"
          >
            View Summary
          </Button>
        </div>
      </div>

      {!playbook_up_to_date && !missingCriteriaLoader && (
        <Banner
          warning
          data-testid="playbook-not-up-to-date-warning"
          icon={<IconAlertTriangle />}
          header="Update Scorecard"
        >
          <>
            <p>{mustReview ? reviewAndUpdateMessage : updateWithoutReviewMessage}</p>
            {!loading.scorecards && (
              <a
                className="editor__update-link"
                data-testid="update-playbook-link"
                disabled={loading.scorecards}
                onClick={() => {
                  mustReview ? dispatchOpenModal('update') : updatePlaybookVersion()
                }}
              >
                {mustReview ? 'Review update' : 'Update'}
              </a>
            )}
          </>
        </Banner>
      )}

      <SecondaryNavRouter pages={pages} />

      {/* MODALS */}

      {modal === 'summary' && (
        <BasicModal title="Scorecard Summary" onClose={dispatchCloseModal} show={!!modal}>
          <ViewScorecardSummary scorecardConfig={data.targetScorecardConfig} />
        </BasicModal>
      )}

      {modal === 'noCriteria' && (
        <BasicModal
          title="Configure Scorecard"
          onClose={dispatchCloseModal}
          show={!!modal}
          data-testid="no-criteria-model"
        >
          <p>This scorecard cannot become active until one or more measures are in use.</p>

          <p>Please select the criteria you would like Balto to score calls with.</p>
        </BasicModal>
      )}

      {modal === 'active' && (
        <FormModal
          title={`${active ? 'Deactivate' : 'Activate'} Scorecard Configuration`}
          onSave={toggleActiveStatus}
          onClose={dispatchCloseModal}
          closeButtonLabel="Cancel"
          submitButtonLabel={`${active ? 'Deactivate' : 'Activate'}`}
          show={modal}
          isLoading={loading.scorecards}
        >
          <div
            data-testid="confirm-active-modal"
            className="scorecard-editor__active-modal-content"
          >
            {`Are you sure you want to ${
              active ? 'deactivate' : 'activate'
            } this scorecard configuration?
                If you do, it will ${active ? 'not ' : ''}be used to score calls.`}
          </div>
        </FormModal>
      )}

      {modal === 'weight' && (
        <BasicModal
          title="Edit Sections"
          onSave={submitSectionChange}
          onClose={dispatchCloseModal}
          show={modal}
          size="small"
        >
          <SectionWeightForm
            sections={sectionState}
            handleSubmit={handleSectionSubmit}
            updateSectionsObject={updateSectionsObject}
          />
        </BasicModal>
      )}

      {modal === 'update' && (
        <FormModal
          title="Update Playbook Version"
          onSave={updatePlaybookVersion}
          onClose={dispatchCloseModal}
          closeButtonLabel="Cancel"
          submitButtonLabel="Update"
          size="small"
          show={modal}
          isLoading={loading.scorecards}
          className="update-playbook-form__container"
        >
          <UpdatePlaybookForm
            scorecards={[
              {
                ...data.targetScorecardConfig,
                criteria_not_in_playbook: missingCriteria,
              },
            ]}
            playbook={data.targetPlaybook}
            handleSubmit={handleSectionSubmit}
          />
        </FormModal>
      )}
    </div>
  )
}

export default ScorecardEditor
