import React from 'react'
import { AnalyticsCard } from '@/components/cards/AnalyticsCard'
import { formatPercent } from '@/utils/helpers'
import { ReportsInfoCardContainer } from './ReportsInfoCardContainer'
import { avgAlertCount } from './ReportsHelpers'

export const calculateVariant = (percent, arrow) => {
  if (!percent || percent === 'N/A') {
    return 'neutral'
  }
  return arrow ? 'positive' : 'negative'
}

export const ReportInfoCards = ({
  loading,
  alertCountsByTypeByDay,
  previousPeriodAlertCountsByTypeByDay,
  callAlertPercentage,
  previousPeriodCallAlertPercentage,
  dateRange,
  topChattingSupervisor,
  topListenerSupervisor,
}) => {
  let positiveArrowUp = true
  let negativeArrowUp = true
  let informativeArrowUp = true
  let callAlertPercentageArrowUp = true
  let positivePercentChange = null
  let negativePercentChange = null
  let informativePercentChange = null
  let callAlertPercentageChange = null
  const NA = 'N/A'
  const noData = 'No Data Available'

  function parseValue(value) {
    if (typeof value === 'string') {
      return parseFloat(value)
    }

    return value
  }

  function calculatePercent(previous, current) {
    // No change
    if (current === previous) {
      return 0
    }
    // Can't divide by zero
    if (previous === 0) {
      return NA
    }

    // Everything else should be (X-Y)/Y
    return ((current - previous) / previous) * 100
  }

  const calculatePercentChange = (currentPeriodValue, previousPeriodValue) => {
    const previous = parseValue(previousPeriodValue)
    const current = parseValue(currentPeriodValue)
    let percent = calculatePercent(previous, current)
    let percentArrowUp = true

    // When it's a decrease, flip the arrow and negate the percentage difference
    if (percent !== NA && parseFloat(percent) < 0) {
      percent *= -1
      percentArrowUp = false
    }

    return { percent, percentArrowUp }
  }

  // Takes in a number and returns it with two decimal points and commas
  const formatNumber = (number) => {
    return number?.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  const analyticsCardStyleClass = (agentName) => {
    return agentName ? 'analytics-card-agent-data' : 'analytics-card-empty-data'
  }

  if (!loading.alertCountsByTypeByDay && !loading.previousPeriodAlertCountsByTypeByDay) {
    const positiveResponse = calculatePercentChange(
      avgAlertCount(alertCountsByTypeByDay.positive, dateRange),
      avgAlertCount(previousPeriodAlertCountsByTypeByDay.positive, dateRange)
    )
    positivePercentChange = positiveResponse.percent
    positiveArrowUp = positiveResponse.percentArrowUp

    const negativeResponse = calculatePercentChange(
      avgAlertCount(alertCountsByTypeByDay.negative, dateRange),
      avgAlertCount(previousPeriodAlertCountsByTypeByDay.negative, dateRange)
    )
    negativePercentChange = negativeResponse.percent
    negativeArrowUp = negativeResponse.percentArrowUp

    const informativeResponse = calculatePercentChange(
      avgAlertCount(alertCountsByTypeByDay.informative, dateRange),
      avgAlertCount(previousPeriodAlertCountsByTypeByDay.informative, dateRange)
    )
    informativePercentChange = informativeResponse.percent
    informativeArrowUp = informativeResponse.percentArrowUp
  }

  if (!loading.callAlertPercentage && !loading.previousPeriodCallAlertPercentage) {
    const callAlertResponse = calculatePercentChange(
      callAlertPercentage,
      previousPeriodCallAlertPercentage
    )
    callAlertPercentageChange = callAlertResponse.percent
    callAlertPercentageArrowUp = callAlertResponse.percentArrowUp
  }

  return (
    <ReportsInfoCardContainer
      cards={
        <>
          <AnalyticsCard
            loading={loading.callAlertPercentage}
            comparisonsLoading={loading.previousPeriodCallAlertPercentage}
            testId="call-with-alerts"
            arrowUp={callAlertPercentageArrowUp}
            data={formatPercent(callAlertPercentage * 100, 2, true)}
            dateDescription="vs. previous period"
            description="Calls w/ Alerts "
            percent={formatNumber(callAlertPercentageChange)}
            variant={calculateVariant(callAlertPercentageChange, callAlertPercentageArrowUp)}
          />

          <AnalyticsCard
            loading={loading.alertCountsByTypeByDay}
            comparisonsLoading={loading.previousPeriodAlertCountsByTypeByDay}
            arrowUp={negativeArrowUp}
            testId="negative-alerts"
            data={formatNumber(avgAlertCount(alertCountsByTypeByDay.negative, dateRange))}
            dateDescription="vs. previous period"
            description="Avg. Negative Alerts "
            percent={formatNumber(negativePercentChange)}
            variant={calculateVariant(negativePercentChange, negativeArrowUp)}
          />

          <AnalyticsCard
            loading={loading.alertCountsByTypeByDay}
            comparisonsLoading={loading.previousPeriodAlertCountsByTypeByDay}
            arrowUp={positiveArrowUp}
            data={formatNumber(avgAlertCount(alertCountsByTypeByDay.positive, dateRange))}
            testId="positive-alerts"
            dateDescription="vs. previous period"
            description="Avg. Positive Alerts "
            percent={formatNumber(positivePercentChange)}
            variant={calculateVariant(positivePercentChange, positiveArrowUp)}
          />

          <AnalyticsCard
            loading={loading.alertCountsByTypeByDay}
            comparisonsLoading={loading.previousPeriodAlertCountsByTypeByDay}
            arrowUp={informativeArrowUp}
            data={formatNumber(avgAlertCount(alertCountsByTypeByDay.informative, dateRange))}
            testId="informative-alerts"
            dateDescription="vs. previous period"
            description="Avg. Informative Alerts "
            percent={formatNumber(informativePercentChange)}
            variant={calculateVariant(informativePercentChange, informativeArrowUp)}
          />

          <AnalyticsCard
            loading={loading.topChattingSupervisor}
            data={topChattingSupervisor || noData}
            displayDataClass={analyticsCardStyleClass(topChattingSupervisor)}
            testId="top-supervisor-chat"
            description="Chat Top Supervisor"
            hideDateDescription
          />

          <AnalyticsCard
            loading={loading.topListenerSupervisor}
            data={topListenerSupervisor || noData}
            displayDataClass={analyticsCardStyleClass(topListenerSupervisor)}
            testId="top-supervisor-listener"
            description="Listens Top Supervisor"
            hideDateDescription
          />
        </>
      }
    />
  )
}
