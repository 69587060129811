import React from 'react'
import { Form } from 'semantic-ui-react'

export const NumberField = ({
  label,
  errorMessage,
  field: { name, ...fieldProps },
  form: { touched, errors },
  ...props
}) => {
  const getErrorMessage = () => {
    if (errorMessage) return { content: errorMessage }
    return errors[name] && touched[name] ? { content: errors[name] } : null
  }

  return (
    <Form.Input
      data-testid={`numberField-${name}`}
      type="number"
      name={name}
      label={label}
      error={getErrorMessage()}
      onKeyDown={(event) => ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault()}
      {...fieldProps}
      {...props}
    />
  )
}
