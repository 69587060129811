import React, { useState, useEffect } from 'react'

import { ListeningStatus } from '@/components/ListeningStatus'
import { Pill } from '@/components/pills/Pill'

import {
  calculateTimeElapsedStatus,
  getTimeElapsedStatus,
  calculateDuration,
} from '../commandCenter.helpers'

export const TimeElapsed = ({ agent }) => {
  const [timeElapsed, setTimeElapsed] = useState(calculateTimeElapsedStatus(agent))

  useEffect(() => {
    setTimeElapsed(calculateTimeElapsedStatus(agent))

    if (agent.call_data?.call_status === 'on_call') {
      const interval = setInterval(() => {
        setTimeElapsed(calculateDuration(agent.call_data?.call_start_time))
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }

    return undefined
  }, [agent])

  return (
    <Pill>
      <ListeningStatus status={agent.call_data?.call_status} />
      {getTimeElapsedStatus(agent, timeElapsed)}
    </Pill>
  )
}
