import React from 'react'
import '../realtimeCoaching.scss'

export const ReportsInfoCardContainer = (props) => {
  const { cards } = { ...props }
  return (
    <div data-testid="overview-cards" className="overview-cards">
      {cards}
    </div>
  )
}
