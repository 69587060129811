import React from 'react'

import { BaltoGPTIcon } from '../icons/BaltoGPTIcon'

import './BaltoGPTButton.scss'

export const BaltoGPTButton = ({ handleToggleChatVisible }) => {
  return (
    <button
      type="button"
      className="balto-gpt-button"
      onClick={handleToggleChatVisible}
      data-testid="balto-gpt-button"
    >
      <BaltoGPTIcon />
    </button>
  )
}
