import moment from 'moment'

export const exportCsv = (csvData, filters, title, customDisplayName) => {
  // Need to replace #'s with %23. If we don't the URL gets cut off and the rest of the CSV is missing when downloaded.
  const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvData}`).replace(/#/g, '%23')
  const link = document.createElement('a')

  const composeDateRange = () => {
    return ` ${moment(filters.startDate).format('YYYY-MM-DD')} - ${moment(filters.endDate).format(
      'YYYY-MM-DD'
    )}`
  }
  const filename = `${customDisplayName || 'Balto'} ${title}${
    filters ? composeDateRange() : ''
  }.csv`

  link.className = 'download-csv'
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  return `CSV file named ${filename} downloaded successfully`
}
