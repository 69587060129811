import { v4 } from 'uuid'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, isEmpty } from 'lodash'
import { Form, Button } from 'semantic-ui-react'

import { fetchAllRtcData } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'
import { editAlertConfiguration } from '@/reducers/realtime/realtime.actions'
import { InfoPage } from './AlertWizard/InfoPage'
import { PlaybookConfigPage } from './AlertWizard/PlaybookConfigPage'
import { genericSchema } from './AlertWizard/AlertValidationSchema'

export const EditAlertForm = ({
  closeModal,
  editedAlert = {},
  configPaths = [],
  readOnly = false,
}) => {
  const dispatch = useDispatch()
  const {
    data: { agents: organizationUsers, tags: organizationTags },
    filters: { organization },
  } = useSelector((state) => state.realtimeCoaching)
  const { organizationid: currentUserOrganizationId } = useSelector((state) => state.currentUser)
  const {
    id: alertId = null,
    tags = [],
    users = [],
    trigger_on = [],
    active = true,
    type: alertType = null,
    audible = false,
    active_alert_config_version_id: acvId = null,
  } = editedAlert
  useEffect(() => {
    dispatch(fetchAllRtcData(organization || currentUserOrganizationId))
  }, [])
  const name = editedAlert.name?.value || ''

  const selectedTags = organizationTags
    .reduce((allTags, tagCategory) => {
      allTags.push(...tagCategory.options)
      return allTags
    }, [])
    .filter((tag) => tags.includes(tag.value))

  const selectedUsers = organizationUsers.filter(({ value }) => users.includes(value))

  let alertsFor = null

  if (tags.length) {
    alertsFor = 'tags'
  } else if (users.length) {
    alertsFor = 'agents'
  }

  const shape = genericSchema
  const validationSchema = Yup.object().shape(shape)

  let triggerType = 'occurrence'

  const triggerItems = trigger_on.map(({ cid, path: alertPath = '' }) => {
    const { name: playbookName = '' } = configPaths[cid] || {}
    const sectionNameFromContextString = alertPath?.split(' - ')?.slice(0, 1)?.[0] || ''
    const entryNameFromContextString = alertPath?.split(' - ')?.slice(-1)?.[0] || ''

    const playbook = {
      key: cid,
      label: playbookName || 'Playbook missing/deleted',
      value: cid,
    }

    const item = {
      value: sectionNameFromContextString,
      label: '',
    }

    const path = {
      value: alertPath,
      label: entryNameFromContextString,
      key: entryNameFromContextString,
      type: sectionNameFromContextString,
    }

    const justCapitalize = ['checklist', 'deck', 'notifications']

    if (justCapitalize.includes(sectionNameFromContextString)) {
      item.label = capitalize(sectionNameFromContextString)
    } else if (sectionNameFromContextString === 'classified_postcall') {
      item.label = 'Post Call'
    } else if (sectionNameFromContextString === 'requirement_missed') {
      triggerType = 'absence'
      item.label = 'Checklist - Timing Configured'
      item.value = 'checklist-timing'
    }

    if (item.value === 'checklist-timing') {
      const associatedConfigPaths = configPaths?.[cid]?.paths || []

      associatedConfigPaths.forEach(({ value, type, required_before }) => {
        const contextStringWithoutRequirementMissed =
          alertPath?.split(' - ')?.slice(1).join(' - ') || ''
        if (contextStringWithoutRequirementMissed === value) {
          path.value = value
          path.type = type
          path.required_before = required_before
        }
      })
    }

    return {
      item,
      path,
      id: v4(),
      playbook,
    }
  })

  const onSubmit = ({
    name,
    acvId,
    active,
    audible,
    alertType,
    alertsFor,
    triggerType,
    selectedTags,
    triggerItems,
    selectedUsers,
  }) => {
    dispatch(
      editAlertConfiguration(
        {
          data: {
            name,
            active,
            audible,
            message: '',
            id: alertId,
            type: alertType,
            all_users: alertsFor === 'all',
            active_alert_config_version_id: acvId,
            tags: alertsFor === 'tags' ? selectedTags.map(({ value }) => value) : [],
            users: alertsFor === 'agents' ? selectedUsers.map(({ value }) => value) : [],
            trigger_on: triggerItems.map(({ playbook, path }) => {
              const { value: cid } = playbook
              const { value: contextString } = path
              return {
                cid,
                path:
                  triggerType === 'absence'
                    ? `requirement_missed - ${contextString}`
                    : contextString,
              }
            }),
          },
        },
        `Changes have been saved for ${name}`
      )
    )
    closeModal()
  }

  return (
    <Formik
      initialValues={{
        displayedForm: 'editForm',
        name,
        acvId,
        active,
        audible,
        alertType,
        alertsFor,
        triggerType,
        selectedTags,
        triggerItems,
        selectedUsers,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
        dirty /* touched prop is here as well, currently unused but will need to be for showing errors on the custom components */,
      }) => (
        <Form onSubmit={handleSubmit}>
          <InfoPage
            setFieldValue={setFieldValue}
            validationFormName="editForm"
            readOnly={readOnly}
            values={values}
            errors={errors}
            tags={organizationTags}
            organizationUsers={organizationUsers}
          />
          <PlaybookConfigPage
            setFieldValue={setFieldValue}
            validationFormName="editForm"
            readOnly={readOnly}
            values={values}
            errors={errors}
            configPaths={configPaths}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '14px',
            }}
          >
            <Button
              data-testid="edit-alert-submit-button"
              primary
              type="submit"
              disabled={!isEmpty(errors) || !touched || !dirty || readOnly}
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
