import { UNCATEGORIZED_TAGS } from '../../utils/constants'

// Action Types
export const SET_CATEGORIES = 'organizationTagManager/setCategories'
export const ADD_CATEGORY = 'organizationTagManager/addCategory'
export const UPDATE_ORDER_OF_TAGS = 'organizationTagManager/updateOrderOfTags'
export const EMPTY_TAG_CATEGORIES = 'organizationTagManager/emptyTagCategories'
export const SET_TAGS = 'organizationTagManager/setTags'

// Action Creators
export const setCategories = (payload) => ({ type: SET_CATEGORIES, payload })
export const addCategory = (payload) => ({ type: ADD_CATEGORY, payload })
export const setTags = (payload) => ({ type: SET_TAGS, payload })
export const updateOrderOfTags = (payload) => ({ type: UPDATE_ORDER_OF_TAGS, payload })
export const emptyTagCategories = () => ({ type: EMPTY_TAG_CATEGORIES })

// State
export const initialState = {
  column: {},
  tags: {},
  columnOrder: [],
}

// Reducer
export default function organizationTagManagerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CATEGORIES: {
      const organizeData = {}
      let columnOrder = []

      action.payload.forEach((category) => {
        const categoryId = `category-${category.id}`
        columnOrder = [...columnOrder, categoryId]
        Object.assign(organizeData, {
          [categoryId]: {
            id: categoryId,
            categoryId: category.id,
            title: category.name,
            organizationId: category.organization_id,
            taskIds: [],
            sortId: category.sort_id,
          },
        })
      })
      columnOrder.push('column-1')
      let newState = {
        ...initialState,
        columnOrder: [...initialState.columnOrder, ...columnOrder],
        column: {
          ...initialState.column,
          ...organizeData,
        },
      }
      newState = {
        ...newState,
        column: {
          ...newState.column,
          'column-1': { id: 'column-1', title: `${UNCATEGORIZED_TAGS}`, taskIds: [] },
        },
      }
      return newState
    }
    case SET_TAGS: {
      const newTags = {}
      action.payload.forEach((tag) => {
        const tagId = `tag-${tag.id}`
        if (tag.tag_category_id) {
          state.column[`category-${tag.tag_category_id}`].taskIds.push(tagId)
        } else {
          state.column['column-1'].taskIds.push(tagId)
        }
        Object.assign(newTags, {
          [tagId]: {
            id: tagId,
            tagId: tag.id,
            content: tag.name,
            organizationId: tag.organization_id,
            tagCategoryId: tag.tag_category_id,
          },
        })
      })
      const updateState = { ...state, tags: newTags }
      return updateState
    }
    case ADD_CATEGORY:
      return {
        ...state,
        columnOrder: [...state.columnOrder, action.payload.id],
        column: {
          ...state.column,
          [action.payload.id]: {
            id: `category-${action.payload.id}`,
            categoryId: action.payload.id,
            title: action.payload.name,
            organizationId: action.payload.organization_id,
            taskIds: [],
            sortId: action.payload.sort_id,
          },
        },
      }
    case UPDATE_ORDER_OF_TAGS:
      return action.payload
    case EMPTY_TAG_CATEGORIES:
      return initialState
    default:
      return state
  }
}
