import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { unstable_batchedUpdates } from 'react-dom'
import { batchedSubscribe } from 'redux-batched-subscribe'
import thunk from 'redux-thunk'

import reducers from './reducers'
import sanitizationMiddleware from './middleware/sanitizationMiddleware'
import { rtcSocketMiddleware } from './middleware/rtcSocketMiddleware'
import listeningSocketMiddleware from './middleware/listeningSocketMiddleware'
import { ccSocketMiddleware } from './middleware/ccSocketMiddleware'
import { SocketClient } from './utils/classes/SocketClient'
import { WorkerClient } from './utils/classes/WorkerClient'
import { BroadcastClient } from './utils/classes/BroadcastClient'
import { LOGOUT_USER } from './reducers/auth/currentUser.redux'

const middleware = applyMiddleware(
  thunk,
  sanitizationMiddleware,
  rtcSocketMiddleware(
    new WorkerClient('baltocloud-rtc-socket-worker'),
    new BroadcastClient('RtcService')
  ),
  listeningSocketMiddleware(new SocketClient()),
  ccSocketMiddleware(
    new WorkerClient('command-center-worker'),
    new BroadcastClient('command-center-broadcast')
  )
)
const enhancer = compose(middleware, batchedSubscribe(unstable_batchedUpdates))
const combinedReducers = combineReducers({ ...reducers })
const rootReducer = (state, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return combinedReducers(undefined, action)
    default:
      return combinedReducers(state, action)
  }
}

const store =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === undefined || process.env.NODE_ENV === 'production'
    ? createStore(rootReducer, enhancer)
    : createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(enhancer))

// Enable Webpack hot module replacement for reducers
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers', () => {
    const nextRootReducer = require('./reducers').default
    store.replaceReducer(combineReducers({ ...nextRootReducer }))
  })
}

export default store
