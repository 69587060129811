import React from 'react'

import { Banner } from '@/components/banners/Banner'
import { formatTime } from '@/utils/helpers'
import { IconConfetti } from '@tabler/icons-react'

export const ChallengeCompletedMessage = ({ challengeInfo }) => {
  const formattedDate = formatTime(challengeInfo.endDate, 'MMMM D, YYYY')
  const completeMessage = `${challengeInfo.winners} won this challenge on ${formattedDate}`

  return (
    <Banner
      data-testid="challenge-completed-message"
      success
      icon={<IconConfetti />}
      header="Challenge is complete!"
    >
      {completeMessage}
    </Banner>
  )
}
