import React, { useMemo } from 'react'
import classNames from 'classnames'
import { toNumber } from 'lodash'

import { determineGradient } from '@/components/helpers'
import { convertAgentNameToInitials } from '@/utils/helpers'

import './UserInitial.scss'

export const UserInitial = ({ userFullName, index, inline, overflowCount }) => {
  if (!userFullName && !overflowCount) return null

  const gradient = useMemo(() => determineGradient(toNumber(index || 0)), [index])

  return (
    <div data-testid="user-initial" className={classNames('user-initials-container', { inline })}>
      <div
        className="user-initials"
        data-testid="user-initial-content"
        style={{ background: overflowCount ? 'var(--dark-knight-200)' : gradient }}
      >
        {overflowCount ? `+${overflowCount}` : convertAgentNameToInitials(userFullName)}
      </div>
    </div>
  )
}
