// Action Types
export const LOGIN_USER = 'auth/loginUser'
export const LOGOUT_USER = 'auth/logoutUser'
export const UPDATE_USER_PERMISSIONS = 'auth/updateUserPermissions'

// Action Creators
export const loginUser = (payload) => ({ type: LOGIN_USER, payload })
export const logoutUser = () => ({ type: LOGOUT_USER })
export const updateUserPermissions = (payload = {}) => ({ type: UPDATE_USER_PERMISSIONS, payload })

// State
export const initialState = {
  first_name: null,
  last_name: null,
  token: null,
  username: null,
  user_id: null,
  organizationid: null,
  organization_name: null,
  edit_config: null,
  edit_users: null,
  edit_leaderboard: null,
  realtime_coaching_access: null,
  org_realtime_coaching_access: null,
  account_leaderboard_visibility: null,
  account_production: null,
  account_rtqa_access: null,
  edit_qa: null,
  edit_qa_copilot: null,
  game_admin: null,
  game_monitor: null,
  game_operator: null,
  playbook_name: null,
  uuid: null,
  hierarchy_manager: null,
  own_organization_id: null,
}

// Reducer
export default function currentUserReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return action.payload
    case UPDATE_USER_PERMISSIONS:
      return {
        ...state,
        edit_users: action.payload.edit_users ? action.payload.edit_users : state.edit_users,
        edit_config: action.payload.edit_config ? action.payload.edit_config : state.edit_config,
        edit_leaderboard: action.payload.edit_leaderboard
          ? action.payload.edit_leaderboard
          : state.edit_leaderboard,
      }
    case LOGOUT_USER:
      return initialState
    default:
      return state
  }
}
