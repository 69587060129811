import queryString from 'query-string'

import { fetchingAPI, apiService } from '../../api'
import * as actions from './insightEvents.redux'
import { checkOrderDecklist } from './insights.helpers'

export const fetchInsightDetails =
  (cid, organizationId, categoryName, deckName, decklist) => async (dispatch) => {
    try {
      const queryParam = `?${queryString.stringify({
        cid,
        org_id: organizationId,
        deck_cat: categoryName,
        deck_name: deckName,
      })}`
      const insights = await fetchingAPI(
        `${apiService.insights}/api/recommendations/decklist${queryParam}`,
        'GET',
        dispatch
      )
      dispatch(actions.setInsightDetails(insights))

      const [result, orderSuggestion] = checkOrderDecklist(insights, decklist)
      dispatch(actions.setInsightsReorderRecommended(!result))
      if (!result) {
        dispatch(actions.setInsightsSuggestedOrder(orderSuggestion))
      }
    } catch (err) {
      console.error(err)
    }
  }

export const saveInsightEvent = (body) => async (dispatch) => {
  try {
    await fetchingAPI(`${apiService.insights}/api/events`, 'POST', dispatch, JSON.stringify(body))
  } catch (err) {
    console.error(err)
  }
}

export const publishInsightEvents = (events, id) => async (dispatch) => {
  try {
    await Promise.all(
      events.map((item) => {
        const saveEventPayload = { ...item, action_taken: 'save', new_config_id: id }

        return fetchingAPI(
          `${apiService.insights}/api/events`,
          'POST',
          dispatch,
          JSON.stringify(saveEventPayload)
        )
      })
    )
    dispatch(actions.clearInsightEvents())
  } catch (err) {
    console.error(err)
  }
}
