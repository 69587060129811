import queryString from 'query-string'
import { toast } from 'react-toastify'
import uuid from 'uuid/v4'

import { fetchingAPI, apiService } from '@/api'
import { publishInsightEvents } from '@/reducers/insights/insightEvents.actions'

import * as actions from './playbooks.redux'

export const fetchPlaybooksHelper = async (dispatch) => {
  const requestedProperties = 'name,cid,organization_id,organization_name,config_created,language'
  const requestedPropertiesQueryString = `?${queryString.stringify({
    requested_properties: requestedProperties,
  })}`
  const url = `${apiService.web}/api/configs${requestedPropertiesQueryString}`
  const playbooks = await fetchingAPI(url, 'GET', dispatch)

  dispatch(actions.setPlaybooks(playbooks))
}

export const fetchPlaybooks = () => {
  return async (dispatch) => {
    dispatch(actions.setLoading(true))

    try {
      await fetchPlaybooksHelper(dispatch)
    } catch (err) {
      toast.error('Failed to load playbooks')
    } finally {
      dispatch(actions.setLoading(false))
    }
  }
}

export const updatePlaybookOrganization = (organizationId, cid) => {
  return async (dispatch) => {
    dispatch(actions.setLoading(true))

    try {
      await fetchingAPI(
        `${apiService.web}/api/configs/${cid}/versions`,
        'PATCH',
        dispatch,
        JSON.stringify({ organization_id: organizationId })
      )
      await fetchPlaybooksHelper(dispatch)
      toast.success('Playbook organization updated')
    } catch (err) {
      toast.error('Failed to update playbook organization')
    } finally {
      dispatch(actions.setLoading(false))
    }
  }
}

export const createPlaybook = (playbook, initialCid, insightEvents) => {
  return async (dispatch) => {
    dispatch(actions.setLoading(true))

    try {
      const body = {
        name: playbook.name,
        cid: uuid(),
        began_editing: playbook.began_editing,
        organization_id: playbook.organization_id,
        startingConfigCid: initialCid,
      }

      const newPlaybook = await fetchingAPI(
        `${apiService.web}/api/configs`,
        'POST',
        dispatch,
        JSON.stringify(body)
      )
      dispatch(publishInsightEvents(insightEvents.events, newPlaybook.id))

      await fetchPlaybooksHelper(dispatch)
      toast.success('New playbook created')
    } catch (err) {
      toast.error('Failed to create playbook')
    } finally {
      dispatch(actions.setLoading(false))
    }
  }
}

export const checkPlaybookForUsers = (
  playbookCid,
  setUsersPreventingDelete,
  setLoading,
  setFetched
) => {
  return async (dispatch) => {
    setLoading(true)

    try {
      const response = await fetchingAPI(
        `${apiService.web}/api/configs/${playbookCid}/users`,
        'GET',
        dispatch
      )

      setUsersPreventingDelete(response)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
      setFetched(true)
    }
  }
}

export const deletePlaybook = (cid) => async (dispatch) => {
  dispatch(actions.setLoading(true))

  try {
    await fetchingAPI(`${apiService.web}/api/configs/${cid}/versions`, 'DELETE', dispatch)
    await fetchPlaybooksHelper(dispatch)
    toast.success('Playbook deleted')
  } catch (err) {
    toast.error('Failed to delete playbook')
  } finally {
    dispatch(actions.setLoading(false))
  }
}

export const reassignUserPlaybooks =
  (userIds, cid, setSubmitting, setUsersPreventingDelete) => async (dispatch) => {
    setSubmitting(true)

    try {
      await fetchingAPI(
        `${apiService.web}/api/users/batch`,
        'PATCH',
        dispatch,
        JSON.stringify({ user_ids: userIds, config_cid: cid })
      )
      toast.success('Playbooks reassigned')
      setUsersPreventingDelete([])
    } catch (err) {
      toast.error('Failed to reassign playbook')
    } finally {
      setSubmitting(false)
    }
  }

export const updatePlaybookLanguage = (language, cid) => async (dispatch) => {
  dispatch(actions.setLoading(true))

  try {
    await fetchingAPI(
      `${apiService.web}/api/configs/${cid}/versions`,
      'PATCH',
      dispatch,
      JSON.stringify({ language })
    )
    await fetchPlaybooksHelper(dispatch)
    toast.success('Playbook language updated')
  } catch (err) {
    toast.error('Failed to update playbook language')
  } finally {
    dispatch(actions.setLoading(false))
  }
}
