import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Icon } from 'semantic-ui-react'
import { AlertCategory } from './AlertCategory'
import './AgentAccordionHeader.css'
import { CallCard } from '../CallCard/CallCard'
import { TimerLabel } from './TimerLabel'

export const AgentAccordionHeader = (props) => {
  const { agent, callsShownLimit, agentCalls } = props
  const [isExpanded, setIsExpanded] = useState(false)
  const defaultCallsShownLimit = 3
  const alertTypesFilter = useSelector((state) => state.realtimeCoaching.filters.alertTypes)
  const alertConfigsFilter = useSelector((state) => state.realtimeCoaching.filters.userAlertNames)

  const filterCallsWithAlertTypes = (call) => {
    // Nothing selected means everything is selected
    if (!alertTypesFilter.length) {
      return true
    }
    return call.alerts?.some((alert) => alertTypesFilter?.some(({ value }) => alert.type === value))
  }

  const filterCallsWithAlertConfigs = (call) => {
    // Nothing selected means everything is selected
    if (!alertConfigsFilter.length) {
      return true
    }
    return call.alerts?.some((alert) =>
      alertConfigsFilter?.some(({ value }) => alert.alert_config_id === value)
    )
  }

  const filteredAgentCalls = () => {
    return agentCalls?.calls
      ?.filter((call) => filterCallsWithAlertTypes(call))
      ?.filter((call) => filterCallsWithAlertConfigs(call))
  }

  const getFilteredAlertTypeCount = (alertType) => {
    const filteredConfigIds = alertConfigsFilter.map(({ value }) => value)
    return agent.alerts
      .filter(({ alert_type }) => alert_type === alertType)
      .filter(
        ({ alert_config_id }) =>
          !filteredConfigIds.length || filteredConfigIds.includes(alert_config_id)
      ).length
  }

  const expandAgent = () => {
    props.expandAgent(agent)
  }

  const onHeaderClick = () => {
    expandAgent()
    setIsExpanded(!isExpanded)
  }

  const filteredCalls = filteredAgentCalls() || []

  const currentlyShowingCount =
    filteredCalls.length <= callsShownLimit ? filteredCalls.length : callsShownLimit

  const totalCallCount = filteredCalls.length

  const toggleShowAllCalls = () => {
    const newValue = callsShownLimit === totalCallCount ? defaultCallsShownLimit : totalCallCount
    props.setCallsShownLimit(newValue)
  }

  return (
    <>
      <div className="accordion-header" onClick={onHeaderClick}>
        <div
          className="accordion-header-name"
          data-testid="accordionHeaderName"
        >{`${agent.first_name} ${agent.last_name}`}</div>
        {!isExpanded && (
          <>
            <TimerLabel lastAlertCreatedAt={agent.last_alert_created_at} />
            <AlertCategory
              color="red"
              name="negative"
              count={getFilteredAlertTypeCount('negative')}
            />
            <AlertCategory
              color="blue"
              name="informative"
              count={getFilteredAlertTypeCount('informative')}
            />
            <AlertCategory
              color="green"
              name="positive"
              count={getFilteredAlertTypeCount('positive')}
            />
          </>
        )}
        <div className="accordion-header-chevron">
          <Icon name={isExpanded ? 'angle up' : 'angle down'} />
        </div>
      </div>
      <div className="accordion-details-wrapper">
        {isExpanded &&
          filteredAgentCalls()
            ?.sort((a, b) => new Date(b.call_start_time) - new Date(a.call_start_time))
            ?.filter((call, index) => index < callsShownLimit)
            ?.map((call) => {
              return (
                <div className="accordion-details" data-testid="accordionDetails" key={call?.id}>
                  <CallCard call={call} />
                </div>
              )
            })}
      </div>
      {isExpanded && (
        <div className="accordion-details-footer" data-testid="accordionDetailsFooter">
          <p data-testid="accordionDetailsFooterCallCount">{`Showing ${currentlyShowingCount} of ${totalCallCount}`}</p>
          <p className="accordion-separator">
            {totalCallCount > defaultCallsShownLimit ? ' | ' : ''}
          </p>
          <a onClick={toggleShowAllCalls} hidden={totalCallCount <= defaultCallsShownLimit}>
            {callsShownLimit < totalCallCount ? 'Show All' : 'Collapse'}
          </a>
        </div>
      )}
    </>
  )
}
