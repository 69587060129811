import React, { useEffect, useState } from 'react'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { Form } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { isEmpty } from 'lodash'
import { fetchUserAlertNames } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'

export const UserAlertNameDropdown = () => {
  const dispatch = useDispatch()
  const [userAlertNameSearchQuery, setUserAlertNameSearchQuery] = useState('')
  const { data, filters, loading } = useSelector((state) => state.realtimeCoaching)

  useEffect(() => {
    if (isEmpty(data.userAlertNames)) {
      dispatch(fetchUserAlertNames())
    }
  }, [])

  return (
    <Form.Field data-testid="rtc-user-alert-name-dropdown">
      <label htmlFor="alertNames">Alert Name</label>
      <MultiSelect
        isSelectAll
        placeholderPill="All Alert Names"
        inputId="alertNames"
        options={
          !userAlertNameSearchQuery && data.userAlertNames.length > 1000 ? [] : data.userAlertNames
        }
        optionsLength={data.userAlertNames.length}
        loading={loading.userAlertNames}
        value={filters.userAlertNames}
        onInputChange={(inputValue) => {
          setUserAlertNameSearchQuery(inputValue)
        }}
        onChange={(value) => dispatch(setFilter({ userAlertNames: value }))}
        noOptionsMessage={() =>
          userAlertNameSearchQuery ? 'No results found' : 'Begin typing to search...'
        }
      />
    </Form.Field>
  )
}
