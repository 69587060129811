import React, { useContext, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import WidgetContext from '../../../components/widget/widget.context'
import WidgetContainer from '../../../components/widget/WidgetContainer'
import WidgetTooltip from '../../../components/widget/WidgetTooltip'
import HeatmapChart from '../../../components/charts/HeatmapChart'
import { WidgetPagination } from '../../../components/widget/WidgetPagination'
import {
  formatItemsByCall,
  formatHeatmapValues,
  groupCallDetails,
  getColorScale,
} from './widgetHelpers'
import { paginateItemsByCall } from '../../../reducers/analytics/analytics.actions'
import { truncateString } from '../../../utils/helpers'

export const ItemsByCallWidget = ({ section }) => {
  const dispatch = useDispatch()
  const { data, loading, filters } = useSelector((state) => state.analytics)
  const { itemDetail, handleChangeItemDetail, handleResetWidgetFilters } = useContext(WidgetContext)
  const { olderCursor, newerCursor } = data[`${section}ItemsByCallCursors`]

  const formattedData = useMemo(() => {
    return formatItemsByCall(data[`${section}Items`], data[`${section}ItemsByCall`], section)
  }, [data])

  // Data is formatted differently for checklist items, so we must account for that special case
  // We are setting a limit of 25 items for the time being THIS WILL BE CHANGED
  const keys = useMemo(() => {
    return section !== 'checklist'
      ? data[`${section}Items`]
      : data.checklistItems.map((item) => item.item)
  }, [data]).filter((x, i) => i < 25)

  const callDetails = useMemo(() => {
    return groupCallDetails(data[`${section}ItemsByCall`], section)
  }, [data])

  // Some nonsense
  const colors = useMemo(
    () => formattedData.map((item) => keys.map((key) => item[`${key}Color`])).flat(),
    [formattedData]
  )
  const colorScale = useMemo(() => getColorScale(colors), [colors])

  const handlePagination = (cursor, oldest) => {
    dispatch(paginateItemsByCall(cursor, oldest, section))
  }

  useEffect(() => {
    handleChangeItemDetail({})
  }, [filters])

  useEffect(() => {
    handleResetWidgetFilters()
  }, [data])

  return (
    <WidgetContainer
      medium
      loading={loading[`${section}Page`] || loading[`${section}ItemsByCall`]}
      noData={isEmpty(formattedData)}
      withDetail={!isEmpty(itemDetail)}
      withPagination
      dataForDetail={itemDetail}
      table={<table />}
      chart={
        <>
          <HeatmapChart
            data={formattedData}
            keys={keys}
            index="callData"
            colors={colorScale}
            minValue={1}
            label={(allItems, item) => {
              const labelValue = allItems[item]

              return labelValue > 1 ? `x${labelValue}` : null
            }}
            labelTextColor="#ffffff"
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: -130,
              format: (v) => {
                const [date, duration, name, voip] = formatHeatmapValues(v)
                const abbrevName = `${name.split(' ')[0]} ${name.split(' ')[1][0]}.`

                return (
                  <>
                    <tspan x="0" dy="-1.1rem" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      {abbrevName}
                    </tspan>
                    <tspan x="0" dy="0.9rem">
                      {date}
                    </tspan>
                    <tspan x="0" dy="0.8rem">
                      {duration}
                    </tspan>
                    <tspan x="0" dy="0.8rem">
                      {truncateString(voip, 18)}
                    </tspan>
                  </>
                )
              },
            }}
            tooltip={({ xKey, yKey, value }) => {
              const [date, duration, name, voip] = formatHeatmapValues(yKey)

              return (
                <WidgetTooltip>
                  <div style={{ textAlign: 'left' }}>
                    <h4>{name}</h4>
                    {voip && <div>{voip}</div>}
                    <div>
                      {date} - {duration}
                    </div>
                    <p>&quot;{xKey}&quot;</p>
                    {value === 0 && (
                      <p>
                        <i>Did not occur</i>
                      </p>
                    )}
                  </div>
                </WidgetTooltip>
              )
            }}
            onClick={(node) => {
              handleChangeItemDetail(callDetails[node.id])
            }}
          />
          <WidgetPagination
            olderCursor={olderCursor}
            newerCursor={newerCursor}
            queryDataByCursor={handlePagination}
          />
        </>
      }
    />
  )
}
