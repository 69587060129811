// Action Types
export const SET_PARSED_CSV = 'organizations/setParsedCsv'
export const UPDATE_PREVIEW_USERS = 'organziations/updatePreviewUsers'
export const UPDATE_UPLOAD_RESPONSE = 'organizations/updateUploadResponse'
export const CLEAR_CSV_UPLOAD_RESPONSE = 'orgnizations/clearCsvUploadResponse'
export const CSV_STREAMING_COMPLETE = 'organizations/csvStreamingComplete'

// Action Creators
export const setParsedCsv = (payload) => ({ type: SET_PARSED_CSV, payload })
export const updatePreviewUsers = (payload) => ({ type: UPDATE_PREVIEW_USERS, payload })
export const updateUploadResponse = (payload) => ({ type: UPDATE_UPLOAD_RESPONSE, payload })
export const clearCsvUploadResponse = () => ({ type: CLEAR_CSV_UPLOAD_RESPONSE })
export const csvStreamingComplete = () => ({ type: CSV_STREAMING_COMPLETE })

// State
export const initialState = {
  tags: [],
  users: [],
  headers: [],
  configNames: [],
  previewUsers: [],
  uploadResponse: [],
  streamingComplete: false,
}

// Reducer
export default function organizationUsersCSVReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PARSED_CSV:
      return {
        ...state,
        tags: action.payload.tags,
        users: action.payload.users,
        headers: action.payload.headers,
        configNames: action.payload.configNames,
        tagCategories: action.payload.tagCategories,
      }
    case UPDATE_UPLOAD_RESPONSE:
      return {
        ...state,
        uploadResponse: [...state.uploadResponse, ...action.payload],
      }
    case UPDATE_PREVIEW_USERS:
      return { ...state, previewUsers: action.payload }
    case CLEAR_CSV_UPLOAD_RESPONSE:
      return { ...state, uploadResponse: [], streamingComplete: false }
    case CSV_STREAMING_COMPLETE:
      return { ...state, streamingComplete: true }
    default:
      return state
  }
}
