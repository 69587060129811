import React from 'react'
import { TriggerSelectForm } from '@/views/RealtimeCoaching/Alerts/forms/TriggerSelectForm'

export const TriggersTab = ({ values, setFieldValue }) => {
  return (
    <div className="edit-alert-page edit-alert-triggers-tab">
      <TriggerSelectForm isEditPage setFieldValue={setFieldValue} values={values} />
    </div>
  )
}
