import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  fetchScorecardConfigById,
  resetSelectedScorecardConfig,
} from '@/reducers/scorecards/scorecards.actions'
import { AbstractForm } from '../../../components/forms/formik/AbstractForm'
import { getTagsWithCategories } from '../../../utils/helpers'
import { rankOnOptions, timePeriodOptions } from '../../../utils/constants'
import { getSchema } from './leaderboard.schema'
import { getLeaderboardInitialValues, getLeaderboardOptions } from '../helpers'

export const LeaderboardForm = ({
  isEdit,
  selectedLeaderboard,
  handleSubmit,
  onClose,
  ...props
}) => {
  const [isQA, setIsQA] = useState(selectedLeaderboard?.version?.rank_identifier === 'qa' || false)
  const { qa_enabled: qaEnabled, qa_copilot_enabled: copilotEnabled } = useSelector(
    (state) => state.currentOrganization
  )
  const dispatch = useDispatch()

  const {
    organizationTags: tags,
    organizationTagCategories: tagCategories,
    configs,
    scorecards,
    leaderboards,
  } = useSelector((state) => state)
  const { data: leaderboardsData } = leaderboards
  const { data: scorecardsData } = scorecards

  const scorecardConfigOptions = useMemo(
    () =>
      scorecardsData?.scorecards?.map((scorecard) => ({
        value: scorecard.sid,
        label: scorecard.name,
        id: scorecard.id,
      })),
    [scorecardsData?.scorecards]
  )

  const playbookOptions = configs.map((config) => ({
    label: config.name,
    value: config.cid,
  }))
  const tagsOptionsWithCategories = getTagsWithCategories(tags, tagCategories)
  const initialValues = getLeaderboardInitialValues(selectedLeaderboard, tags, configs)
  const leaderboardOptions = getLeaderboardOptions(leaderboardsData.leaderboards, initialValues)

  const determineIfQASelected = ({ value }) => {
    if (value === 'qa') {
      setIsQA(true)
    } else {
      setIsQA(false)
      dispatch(resetSelectedScorecardConfig())
    }
  }

  const handleScorecardSelect = ({ id }) => {
    if (!id) {
      dispatch(resetSelectedScorecardConfig())
      return
    }

    dispatch(fetchScorecardConfigById(id))
  }

  const orgAccessibleRankOptions =
    qaEnabled || copilotEnabled
      ? rankOnOptions
      : rankOnOptions.filter((option) => option.value !== 'qa')

  const schema = getSchema({
    isQA,
    determineIfQASelected,
    handleScorecardSelect,
    scorecardValues: scorecardsData.targetScorecardConfig,
    playbookOptions,
    scorecardConfigOptions,
    rankOnOptions: orgAccessibleRankOptions,
    timePeriodOptions,
    tagsOptionsWithCategories,
    leaderboardOptions,
  })

  // fetch scorecard data to populate the scorecard cheatsheet
  useEffect(() => {
    if (isQA && initialValues.scorecard_config_sid) {
      const matchingScorecardConfigOption = scorecardConfigOptions.find(
        (scorecardConfig) => scorecardConfig.value === initialValues.scorecard_config_sid
      )

      if (matchingScorecardConfigOption) {
        dispatch(fetchScorecardConfigById(matchingScorecardConfigOption.id))
      }
    }
  }, [scorecardConfigOptions])

  return (
    <AbstractForm
      data-testid="leaderboard-form"
      existingValues={isEdit ? initialValues : {}}
      schema={schema}
      buttonLabel={isEdit ? 'Update' : 'Create'}
      onSubmit={(values) => {
        handleSubmit(values, selectedLeaderboard)
        if (onClose) onClose()
      }}
      onClose={onClose}
      isModal
      {...props}
    />
  )
}
