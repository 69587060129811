import React, { useEffect } from 'react'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, truncate } from 'lodash'
import { Popup, Button, Form, Grid, Segment } from 'semantic-ui-react'

import { formatTime, formatPercent } from '@/utils/helpers'
import { PerformanceIndicatorBar } from '@/components/charts/PerformanceIndicatorBar/PerformanceIndicatorBar'
import {
  fetchCopilotScorecardsByOrg,
  fetchEnterpriseDashboardData,
} from '@/reducers/qa-copilot/qa-copilot.actions'
import { setFilter } from '@/reducers/qa-copilot/qa-copilot.redux'
import { Card } from '@/components/cards/Card'
import { DateRangePickerReusable } from '@/components/datePickers/DateRangePickerReusable'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { ExternalCallIdsLink } from '@/views/QACopilot/components/ExternalCallIdsLink'
import { ExternalLink } from '@/components/ExternalLink'
import Widget from '@/components/widget/Widget'
import { MultiFilter } from '@/components/filters/MultiFilter'

import { OverviewCard } from './OverviewCard'
import { ScoresPerOrganizationWidget } from './widgets/ScoresPerOrganizationWidget'

import './copilotDashboard.scss'

export const EnterpriseDashboardPage = () => {
  const dispatch = useDispatch()
  const { organizationid: currentUserOrgId } = useSelector((state) => state.currentUser)
  const { data, filters, loading } = useSelector((state) => state.qaCopilot)
  const isBaltoAdmin = currentUserOrgId === 1

  useEffect(() => {
    const loadInitialData = async () => {
      await dispatch(fetchCopilotScorecardsByOrg(currentUserOrgId, true))
      await dispatch(fetchEnterpriseDashboardData(currentUserOrgId))
    }

    loadInitialData()
  }, [])

  const handleUpdateRange = (range) => {
    const startDate = formatTime(range.selection.startDate, 'yyyy-MM-DDT00:00:00Z')
    const endDate = formatTime(range.selection.endDate, 'yyyy-MM-DDT23:59:59Z')

    dispatch(setFilter({ startDate, endDate }))
  }

  const handleFilterChange = (value) => {
    dispatch(setFilter(value))
  }

  const filterIsDisabled = () => {
    let isLoading = false
    Object.values(loading).forEach((loader) => {
      if (loader) {
        isLoading = true
      }
    })
    return isLoading
  }

  const cardStyle = { border: '1px solid var(--dark-knight-100)', minHeight: '140px' }

  return (
    <>
      <h1>Enterprise Dashboard</h1>

      <Form className="filter-form copilot-filter-form">
        <div>
          <div className="filter-grid small-grid">
            <Form.Field>
              <label>Date Range</label>
              <DateRangePickerReusable
                data-testid="date-range-picker"
                onChange={handleUpdateRange}
                startDate={filters.startDate}
                endDate={filters.endDate}
              />
            </Form.Field>
            <MultiFilter
              label="Scorecards"
              accessor="scorecards"
              store={{ data, loading, filters }}
              handler={handleFilterChange}
            />
            <Form.Field>
              <label className="visibility-hidden">&nbsp;</label>
              <div>
                <Button
                  primary
                  className="no-wrap"
                  type="submit"
                  onClick={() => dispatch(fetchEnterpriseDashboardData(currentUserOrgId))}
                  disabled={filterIsDisabled()}
                  content="Apply Filters"
                  data-testid="submit-filters-button"
                />
              </div>
            </Form.Field>
          </div>
        </div>
      </Form>

      <header className="secondary-header">
        <h2>Overview</h2>
      </header>

      <Grid>
        <Grid.Column width={4}>
          <Card>
            <Card style={cardStyle}>
              <OverviewCard
                title="Evaluated Calls"
                value={data.enterpriseEvaluatedCallData?.call_count}
                callIds={data.enterpriseEvaluatedCallData?.call_ids}
                loading={loading.enterpriseEvaluatedCallData}
                type="neutral"
                showLink={isBaltoAdmin}
              />
            </Card>
            <Card style={cardStyle}>
              <OverviewCard
                title="Overall Average Score"
                value={data.enterpriseAverageScore}
                loading={loading.enterpriseAverageScore}
                callIds={data.enterpriseEvaluatedCallData?.call_ids}
                isPercent
              />
            </Card>
            <Card style={cardStyle}>
              <OverviewCard
                title="Non-Compliant Calls"
                callIds={data.enterpriseFailedCallData?.call_ids}
                value={data.enterpriseFailedCallData?.call_count}
                loading={loading.enterpriseFailedCallData}
                type="negative"
                showLink={isBaltoAdmin}
              />
            </Card>
          </Card>
        </Grid.Column>
        <Grid.Column width={6}>
          <Segment className="full-height">
            <h4>Scorecards by Low Compliance Score</h4>
            <AdvancedTable
              columns={[
                {
                  accessor: 'scorecard_config_name',
                  label: 'Scorecard',
                  collapsing: true,
                  isSortable: false,
                  sub_content_accessor: 'organization_name',
                },
                {
                  accessor: 'aggregate_score',
                  label: 'Avg. Score',
                  collapsing: true,
                  format: (value) => formatPercent(value),
                  isSortable: false,
                },
                {
                  accessor: 'organization_id',
                  label: '',
                  collapsing: true,
                  isSortable: false,
                },
              ]}
              rows={data.enterpriseScorecardPerformance.map((row) => ({
                ...row,
                organization_id: {
                  as: (
                    <ExternalLink
                      to={`/qa-copilot/dashboard?${queryString.stringify({ organization_id: row.organization_id, scorecard_config_sid: row.scorecard_config_sid, start_date: filters.startDate, end_date: filters.endDate })}`}
                      label="View"
                      row={row}
                    />
                  ),
                  value: row.organization_id,
                },
              }))}
              loading={loading.enterpriseScorecardPerformance}
              defaultOrder="asc"
              defaultOrderBy="aggregate_score"
              index="uuid"
              striped={false}
              borderlessRows
              compact
              compactHeader
              wrapColumnContent={false}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column width={6}>
          <Segment className="full-height">
            <h4>Organizations Ranked by Low Compliance Score</h4>
            <AdvancedTable
              columns={[
                {
                  accessor: 'organization_name',
                  label: 'Organization',
                  collapsing: true,
                  isSortable: false,
                },
                {
                  accessor: 'aggregate_score',
                  label: 'Avg. Score',
                  collapsing: true,
                  isSortable: false,
                  format: (value) => formatPercent(value),
                },
                {
                  accessor: 'organization_id',
                  label: '',
                  collapsing: true,
                  isSortable: false,
                },
              ]}
              rows={Object.keys(data.enterpriseOrganizationPerformance).map((orgId) => {
                const row = data.enterpriseOrganizationPerformance[orgId]
                return {
                  ...row,
                  name: {
                    as: (
                      <Popup
                        disabled={row.organization_name.length < 23}
                        on="hover"
                        inverted
                        content={row.organization_name}
                        trigger={<span>{truncate(row.organization_name, { length: 23 })}</span>}
                      />
                    ),
                    value: row.organization_name,
                  },
                  organization_id: {
                    as: (
                      <ExternalLink
                        to={`/qa-copilot/dashboard?${queryString.stringify({ organization_id: row.organization_id, start_date: filters.startDate, end_date: filters.endDate })}`}
                        label="View"
                        row={row}
                      />
                    ),
                    value: row.organization_id,
                  },
                }
              })}
              loading={loading.enterpriseOrganizationPerformance}
              defaultOrder="asc"
              defaultOrderBy="aggregate_score"
              index="uuid"
              striped={false}
              borderlessRows
              compact
              compactHeader
              wrapColumnContent={false}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column width={16}>
          <div>
            <header className="secondary-header">
              <h2>Scores per Organization</h2>
            </header>
            <Widget
              widgetId="scores-per-organization"
              label="Scores per Organization"
              showViewToggle
              showCsvDownload
            >
              <ScoresPerOrganizationWidget />
            </Widget>
          </div>
        </Grid.Column>
        <Grid.Column width={16}>
          <div>
            <header className="secondary-header">
              <h2>Organization Performance</h2>
            </header>
            <Segment className="not-padded">
              <AdvancedTable
                columns={[
                  { accessor: 'rank', label: 'Rank', collapsing: true },
                  { accessor: 'organization_name', label: 'Organization', collapsing: true },
                  { accessor: 'organization_id', label: '' },
                  {
                    accessor: 'passing_call_ids',
                    label: 'Passing Calls',
                    collapsing: true,
                    headerAlignment: 'right',
                  },
                  {
                    accessor: 'failing_call_ids',
                    label: 'Failing Calls',
                    collapsing: true,
                    headerAlignment: 'right',
                  },
                  {
                    accessor: 'aggregate_score',
                    label: 'Average Score',
                    collapsing: true,
                    format: (value) => formatPercent(value),
                    headerAlignment: 'right',
                  },
                ]}
                rows={Object.keys(data.enterpriseOrganizationPerformance).map((orgId, index) => {
                  const row = data.enterpriseOrganizationPerformance[orgId]
                  return {
                    ...row,
                    rank: index + 1,
                    organization_id: {
                      as: (
                        <PerformanceIndicatorBar
                          passing_average={
                            !isEmpty(row.passing_call_ids) && !isEmpty(row.failing_call_ids)
                              ? parseFloat(
                                  (row.passing_call_ids.length * 100) /
                                    (row.failing_call_ids.length + row.passing_call_ids.length)
                                )
                              : 0
                          }
                        />
                      ),
                    },
                    passing_call_ids: {
                      as: (
                        <ExternalCallIdsLink
                          callIds={row.passing_call_ids}
                          label={row.passing_call_ids?.length}
                          passedOrgId={orgId}
                        />
                      ),
                      value: row.passing_call_ids?.length,
                    },
                    failing_call_ids: {
                      as: (
                        <ExternalCallIdsLink
                          callIds={row.failing_call_ids}
                          label={row.failing_call_ids?.length}
                          passedOrgId={orgId}
                        />
                      ),
                      value: row.failing_call_ids?.length,
                    },
                  }
                })}
                loading={loading.enterpriseOrganizationPerformance}
                defaultOrder="asc"
                defaultOrderBy="rank"
                testid="organization-performance-table"
                index="uuid"
                pagination
              />
            </Segment>
          </div>
        </Grid.Column>
      </Grid>
    </>
  )
}

export default EnterpriseDashboardPage
