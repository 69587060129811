import React from 'react'

import { FilterComponent } from './types/FilterComponent'

import './FilterBar.scss'

export const FilterBar = ({
  loading,
  filterBarSchema,
  searchFilters,
  updateSearch,
  rows,
  children,
}) => {
  return (
    <div className="filter-bar" data-testid="filter-bar">
      <div className="filter-pills">
        {filterBarSchema.map((schema) => {
          return (
            <FilterComponent
              key={schema.accessor}
              type={schema.type}
              loading={loading}
              schema={schema}
              value={searchFilters[schema.accessor]}
              updateSearch={updateSearch}
              rows={rows}
            />
          )
        })}
      </div>
      {children}
    </div>
  )
}
