import React, { useEffect, useState } from 'react'
import { Icon, Popup, Segment, Button } from 'semantic-ui-react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { startCase } from 'lodash'
import { IconPlus } from '@tabler/icons-react'

import { FormModal } from '@/components/layout/modals/FormModal'
import { addChallenge, updateChallengeStatus } from '@/reducers/gamification/gamification.actions'
import { closeModal, openModal } from '@/reducers/ui/ui.redux'
import { Pill } from '@/components/pills/Pill'

import { NestedChallengeForm } from './NestedChallengeForm'

import './ChallengesPage.scss'

export const ChallengeForm = ({ template }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const [challengeTemplate] = useState(template)
  const [currentChallenge, setCurrentChallenge] = useState({})
  const { currentlyOpenModalId: modal } = useSelector((state) => state.ui)
  const {
    data: { challengeTemplates },
  } = useSelector((state) => state.gamification)

  const [inProgressCount, setInProgressCount] = useState(0)

  useEffect(() => {
    const activeChallenges = challengeTemplate.challenges.filter(
      (challenge) => challenge.status === 'active'
    )
    setInProgressCount(activeChallenges.length)
  }, [challengeTemplate])

  const redirectToChallengeDetail = (challengeId) => {
    history.push(`/gamification/challenges/${challengeId}`)
  }

  const openChallenges = () => {
    setVisible(!visible)
  }

  const handleAddChallenge = (challengeTemplateId) => {
    dispatch(addChallenge(challengeTemplateId))
    if (!visible) setVisible(!visible)
  }

  const handleChangeChallengeStatus = (challengeToUpdate, newStatus) => {
    dispatch(updateChallengeStatus(challengeToUpdate, newStatus))
    dispatch(closeModal())
  }

  const closeChallengeModal = () => {
    dispatch(closeModal())
  }

  const challengeHasEmptyFields = (challenge) => {
    const agentsLength = challenge.groups.map((group) => group.agents.length)
    return agentsLength.includes(0) || !challenge.startDate || !challenge.endDate
  }

  const openCancelChallengeModal = (challenge) => {
    setCurrentChallenge(challenge)
    dispatch(openModal(`cancelChallenge--${challenge.id}`))
  }
  const openDeleteChallengeModal = (challenge) => {
    setCurrentChallenge(challenge)
    dispatch(openModal(`deleteChallenge--${challenge.id}`))
  }

  const actions = {
    cancelChallenge: openCancelChallengeModal,
    deleteChallenge: openDeleteChallengeModal,
  }
  return (
    <div data-testid={`challenge-form-page-${template.name}`}>
      <div className="challenge-segments">
        <Segment.Group>
          <Segment
            className="top-level-segment"
            data-testid={`challenge-segment-${challengeTemplate.name}`}
          >
            <div className="challenge-info">
              <Pill small emphasized>
                {startCase(challengeTemplate.type)}
              </Pill>
              {inProgressCount > 0 && (
                <div className="challenge-template-in-progress">
                  <Pill small caution data-testid="challenge-in-progress-badge">
                    {inProgressCount} In Progress
                  </Pill>
                </div>
              )}
            </div>
            <div className="challenge-header">
              <h3 className="name"> {challengeTemplate.name} </h3>
              <div className="challenge-icons">
                {template.challenges.length >= 1 && (
                  <span className="toggle-challenges">
                    <span className="length">{template.challenges.length}</span>
                    <Icon
                      data-testid={`caret-${visible ? 'up' : 'down'}`}
                      onClick={openChallenges}
                      className="chevron-button"
                      name={`chevron ${visible ? 'up' : 'down'}`}
                    />
                  </span>
                )}
                <Popup
                  inverted
                  content="Add Challenge"
                  trigger={
                    <Button
                      secondary
                      icon
                      className="svg-button"
                      data-testid="add-nested-challenge"
                      onClick={() => handleAddChallenge(challengeTemplate.id)}
                    >
                      <IconPlus />
                    </Button>
                  }
                />
              </div>
            </div>
            <div className="challenge-template-description">
              <p className="text">{challengeTemplate.description}</p>
              <div className="badge">{challengeTemplate.badge}</div>
            </div>
          </Segment>
          {challengeTemplates &&
            visible &&
            template.challenges.map((individualChallenge) => (
              <div className="nested-challenges" key={individualChallenge.id}>
                <NestedChallengeForm
                  key={individualChallenge.id}
                  individualChallenge={{ ...individualChallenge, type: challengeTemplate.type }}
                  challengeTemplate={challengeTemplate}
                  actions={actions}
                  redirectToChallengeDetail={redirectToChallengeDetail}
                  challengeHasEmptyFields={challengeHasEmptyFields}
                />
              </div>
            ))}
          {modal === `cancelChallenge--${currentChallenge.id}` && (
            <FormModal
              title="Cancel Challenge"
              onSave={() => handleChangeChallengeStatus(currentChallenge, 'cancelled')}
              onClose={closeChallengeModal}
              closeButtonLabel="Continue Challenge"
              submitButtonLabel="Cancel Challenge"
              size="tiny"
              show={modal === `cancelChallenge--${currentChallenge.id}`}
              critical
            >
              <div
                data-testid="confirm-cancel-modal"
                className="scorecard-editor__active-modal-content"
              >
                Are you sure you want to cancel the &ldquo;{currentChallenge?.name}&rdquo;
                challenge? This action cannot be reversed.
              </div>
            </FormModal>
          )}
          {modal === `deleteChallenge--${currentChallenge.id}` && (
            <FormModal
              title="Delete Challenge"
              onSave={() => handleChangeChallengeStatus(currentChallenge, 'deleted')}
              onClose={closeChallengeModal}
              closeButtonLabel="Continue Challenge"
              submitButtonLabel="Delete Challenge"
              size="tiny"
              show={modal === `deleteChallenge--${currentChallenge.id}`}
              critical
            >
              <div
                data-testid="confirm-delete-modal"
                className="scorecard-editor__active-modal-content"
              >
                Are you sure you want to delete the &ldquo;{currentChallenge?.name}&rdquo;
                challenge? This action cannot be reversed.
              </div>
            </FormModal>
          )}
        </Segment.Group>
      </div>
    </div>
  )
}
