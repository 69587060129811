import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import pluralize from 'pluralize'
import { Button, Dimmer, Form, Loader } from 'semantic-ui-react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import TagHeader from '@/components/forms/TagHeader'
import { UNCATEGORIZED_TAGS } from '@/utils/constants'
import Column from '@/components/forms/Column'
import { Breadcrumbs } from '@/components/forms/Breadcrumbs/Breadcrumbs'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { FormModal } from '@/components/layout/modals/FormModal'
import { isCategoryNameTooLong } from '@/views/Organizations/helpers'
import {
  duplicateTagsError,
  createCategory,
  updateTagsInState,
  updateUsers,
  updateTagCategories,
  updateCategory,
  deleteTag,
  deleteAllTags,
  deleteCategory,
  createTags,
} from '../../reducers/organizations/organizationTagManager.actions'
import CategoryForm from './CategoryForm'
import './organizations.scss'

const TagForm = ({
  tags,
  organizationId,
  fetchCategoriesAndTags,
  users,
  name,
  userOrganizationId,
  edit_users,
}) => {
  const dispatch = useDispatch()
  const deleteModalObject = {
    title: '',
    closeModal: {},
    deleteFunc: {},
    display: false,
  }
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [category, setCategory] = useState('')
  const [tagGroup, setTagGroup] = useState('')
  const [groupTags, setGroupTags] = useState('')
  const [hoveredTagId, setHoveredTagId] = useState()
  const [loadingMessage, setLoadingMessage] = useState('')
  const [deleteModal, setDeleteModal] = useState(deleteModalObject)
  const tagCategories = []
  let allTags = []
  if (tags && tags.columnOrder) {
    tags.columnOrder.forEach((columnId) => {
      if (tags.column[columnId]?.title !== UNCATEGORIZED_TAGS) {
        tagCategories.push({
          key: tags.column[columnId]?.categoryId,
          label: tags.column[columnId]?.title,
          value: tags.column[columnId]?.categoryId,
        })
      }
    })
    allTags = Object.keys(tags.tags)
  }
  const showModal = (bool) => {
    setShow(bool)
  }

  const reloadTags = ({ organizationId }) => {
    const reset = () => {
      setLoading(false)
      setLoadingMessage('')
    }
    dispatch(fetchCategoriesAndTags(reset, organizationId))
  }

  const removeAllTags = () => {
    dispatch(deleteAllTags({ organizationId, reloadTags }))
    setDeleteModal(deleteModalObject)
  }

  const updateHoveredTagId = (tagId) => {
    setHoveredTagId(tagId)
  }

  const toggleLoading = (message) => {
    setLoadingMessage(message)
  }

  const handleDropdownChange = (event) => {
    event ? setCategory(event?.key) : setCategory('')
  }

  const checkForDuplicateTags = (tagsCreatedByUser) => {
    const allOrgTagNames = Object.values(tags.tags).map((tag) => tag.content.toLowerCase())
    tagsCreatedByUser.forEach((tagName) =>
      allOrgTagNames.push(tagName.toLowerCase().trim().split(' ').join(''))
    )
    return new Set(allOrgTagNames).size !== allOrgTagNames.length
  }

  const checkDuplicateCategories = (categories) => {
    const duplicate = (element) =>
      element.title.toLowerCase().trim().split(' ').join('') ===
      categories.toLowerCase().trim().split(' ').join('')

    // expected output: true
    return Object.values(tags.column).some(duplicate)
  }

  const createTagsLocally = () => {
    const tagNames = tagGroup
      .split(',')
      .map((tagName) => tagName.trim())
      .filter(Boolean)
    if (checkForDuplicateTags(tagNames)) {
      dispatch(duplicateTagsError('tags'))
      return
    }
    if (tagNames.length) {
      toggleLoading(`Creating a tag`)
      dispatch(
        createTags({
          tags: { tagNames },
          category,
          organizationId,
          toggleLoading,
          reloadTags,
        })
      )
    }

    setTagGroup('')
  }

  const addGroupTags = () => {
    if (isCategoryNameTooLong(groupTags)) return

    if (checkDuplicateCategories(groupTags)) {
      dispatch(duplicateTagsError('categories'))
      return
    }
    toggleLoading(`Adding ${pluralize('Category', 1, true)}`)
    dispatch(
      createCategory({
        tagCategories: groupTags,
        organizationId,
        toggleLoading,
        reloadTags,
        allCategories: tags,
      })
    )
    setGroupTags('')
  }
  const handleCloseDeletionModal = () => {
    setDeleteModal(deleteModalObject)
  }

  const deleteTagFromStore = (deletedTag) => {
    toggleLoading('Removing tag')
    dispatch(
      deleteTag({
        tag: deletedTag,
        // we need to reload users here too because when you delete a tag
        // it cascades and also deletes the tags from user_tags
        reloadTags,
        toggleLoading,
        organizationId,
      })
    )
    setDeleteModal(deleteModalObject)
  }

  const openDeleteTagFromStoreModal = async (deletedTag) => {
    const message = `Are you sure you would like to delete ${deletedTag?.content}? It will be removed from all of your organization's users, scorecards, leaderboards, team filters, and alerts. All alerts that are set to this tag only will be disabled.`
    await setDeleteModal({
      title: message,
      closeModal: () => handleCloseDeletionModal(),
      deleteFunc: () => deleteTagFromStore(deletedTag),
      display: true,
    })
  }

  const updateCategoryFromStore = (selectedCategory, catName) => {
    const updates = { name: catName }
    toggleLoading('Updating Category')
    dispatch(
      updateCategory({
        category: selectedCategory,
        updates,
        // we need to reload users here too because when you delete a tag
        // it cascades and also deletes the tags from user_tags
        reloadTags,
        toggleLoading,
        organizationId,
      })
    )
  }

  const updateAffectedCategoriesLocally = (categories, selectedCategory) => {
    const affectedCategories = categories.filter((cat) => cat.sortId > selectedCategory.sortId)
    if (!affectedCategories) return reloadTags({ organizationId })
    const promises = []
    affectedCategories.forEach(async (cat) => {
      const { sortId } = cat
      const newSortId = sortId - 1
      const updates = { sort_id: newSortId }
      promises.push(
        Promise.resolve(
          updateCategory({
            category: selectedCategory,
            updates,
            organizationId,
          })
        )
      )
    })
    return promises
  }

  const deleteCategoryLocally = async (selectedCategory) => {
    toggleLoading('Removing category')
    await dispatch(
      deleteCategory({
        category: selectedCategory,
        // we need to reload users here too because when you delete a tag
        // it cascades and also deletes the tags from user_tags
        reloadTags,
        toggleLoading,
        organizationId,
      })
    )
    const categories = Object.values(tags.column)
    const updateAffectedCategories = updateAffectedCategoriesLocally(categories, selectedCategory)
    toggleLoading('Updating Categories')
    // original code had a return on this promise.all
    // verify this works
    if (updateAffectedCategories.length > 0) {
      Promise.all(updateAffectedCategories).then(() => {
        reloadTags({ organizationId })
      })
    }
    setDeleteModal(deleteModalObject)
  }

  const openDeleteCategoryModal = async (deletedCategory) => {
    const message = `Are you sure you want to delete this ${deletedCategory.title}? It will unstage all tags associated with its category.`
    await setDeleteModal({
      title: message,
      closeModal: () => setDeleteModal(deleteModalObject),
      deleteFunc: () => deleteCategoryLocally(deletedCategory),
      display: true,
    })
  }

  const handleTagGroup = (event) => {
    setTagGroup(event.target.value)
  }

  const addGroupTag = () => {
    const tagPlaceholder = 'Add new tag category'
    return (
      <CategoryForm
        handleChange={(event) => setGroupTags(event.target.value)}
        createAction={addGroupTags}
        category={category}
        loading={loading}
        formValue={groupTags}
        tagCategories={tagCategories}
        formPlaceholder={tagPlaceholder}
      />
    )
  }
  const updateTagCategoriesLocal = (result) => {
    const destinationOrg = tags.column[result.destination.droppableId]
    const droppedTag = tags.tags[result.draggableId]
    try {
      dispatch(updateTagCategories(droppedTag.tagId, destinationOrg))
    } catch {
      toggleLoading()
      reloadTags({ organizationId })
    }
  }
  const checkIfUserHasTag = (result, tagId) => {
    const targetTagCategory = tags.column[result.destination.droppableId]
    // the id is set up as `category-${category_id}` to prevent tag id conflicts, so we split to grab the id
    const targetTagCategoryId = targetTagCategory.id.split('-')[1]
    const destinationId = result.destination.droppableId
    const sourceId = result.source.droppableId
    if (destinationId === sourceId) {
      return false
    }
    const usersAssociatedWithThisTag = []
    let bool = false
    users.forEach((user) => {
      if (user.tags.find((key) => parseInt(tagId, 10) === key.id)) {
        // We are getting all of the users who are associated with
        // the tag we are dragging
        usersAssociatedWithThisTag.push(user)
      }
    })
    usersAssociatedWithThisTag.forEach((user) => {
      // if a user already has a tag in the category
      // we return an error since a user should only have one tag per category
      if (user.tags.some((tag) => tag.tag_category_id === parseInt(targetTagCategoryId, 10))) {
        bool = true
      }
    })
    return bool
  }

  const updateUsersInState = (draggableId, result) => {
    const formatDraggableId = draggableId.split('-')[1]
    return users.map((user) => {
      const newTags = user.tags.map((_tag) => {
        const tag = { ..._tag }
        if (tag.id === parseInt(formatDraggableId, 10)) {
          if (tags.column[result.destination.droppableId].id === 'column-1') {
            tag.tag_category_id = null
          } else {
            tag.tag_category_id = parseInt(
              tags.column[result.destination.droppableId].id.split('-')[1],
              10
            )
          }
        }
        return tag
      })
      return { ...user, tags: newTags }
    })
  }
  const saveTags = (result = {}) => {
    const { destination, source, draggableId } = result
    const newUsers = updateUsersInState(draggableId, result)
    // Save the tag to the database
    updateTagCategoriesLocal(result)
    // Update users in our redux state in order that we
    // don't have to do a hard reload when a tag is dragged/dropped and updated for a user
    dispatch(updateUsers(newUsers))

    // Save tags to our redux store
    const start = tags.column[source.droppableId]
    const finish = tags.column[destination.droppableId]
    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds)
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)
      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      }
      const newTagState = {
        ...tags,
        column: {
          ...tags.column,
          [newColumn.id]: newColumn,
        },
      }
      dispatch(updateTagsInState(newTagState))
      return
    }

    const startTaskIds = Array.from(start.taskIds)
    startTaskIds.splice(source.index, 1)
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    }

    const finishTasksIds = Array.from(finish.taskIds)
    finishTasksIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      taskIds: finishTasksIds,
    }
    const newState = {
      ...tags,
      column: {
        ...tags.column,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    }
    dispatch(updateTagsInState(newState))
  }
  const decreaseSortOrder = (oldIndex, newIndex, draggableId) => {
    toggleLoading('Updating Categories')
    const categories = Object.values(tags.column)
    const affectedCategories = categories.filter(
      (cat) => cat.sortId > oldIndex && cat.sortId <= newIndex
    )
    const movedCategory = categories.filter((cat) => {
      return cat.id === draggableId
    })
    const promises = []
    affectedCategories.forEach(async (cat) => {
      let { sortId } = cat
      sortId -= 1
      const updateSortOrder = { sort_id: sortId }
      promises.push(
        dispatch(
          updateCategory({
            category: cat,
            updates: updateSortOrder,
            // we need to reload users here too because when you delete a tag
            // it cascades and also deletes the tags from user_tags
            reloadTags: () => {},
            organizationId,
          })
        )
      )
    })

    const updates = { sort_id: newIndex }
    const updatedCat = movedCategory[0]
    Promise.all(promises).then(() => {
      dispatch(updateCategory({ category: updatedCat, updates, reloadTags, organizationId }))
    })
  }
  const increaseSortOrder = (oldIndex, newIndex, draggableId) => {
    toggleLoading('Updating Categories')
    const categories = Object.values(tags.column)
    const affectedCategories = categories.filter(
      (category) => category.sortId < oldIndex && category.sortId >= newIndex
    )
    const movedCategory = categories.filter((cat) => {
      return cat.id === draggableId
    })
    const promises = []
    affectedCategories.forEach(async (cat) => {
      let { sortId } = cat
      sortId += 1
      const updates = { sort_id: sortId }
      promises.push(
        dispatch(
          updateCategory({
            category: cat,
            updates,
            // we need to reload users here too because when you delete a tag
            // it cascades and also deletes the tags from user_tags
            reloadTags: () => {},
            organizationId,
          })
        )
      )
    })
    const updateSortOrder = { sort_id: newIndex }
    const updatedCat = movedCategory[0]
    Promise.all(promises).then(() => {
      dispatch(
        updateCategory({
          category: updatedCat,
          updates: updateSortOrder,
          reloadTags,
          organizationId,
        })
      )
    })
  }
  const onDragEnd = (result) => {
    const { destination, source, type } = result
    const { draggableId } = result
    // draggableId needs to be the actual tag id, not 'tag-{tag_id}'
    const tagOrCategoryId = draggableId.split('-')[1]

    if (!destination) {
      return
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }
    const oldIndex = result.source.index
    const newIndex = result.destination.index
    if (type === 'column') {
      const newColumnOrder = Array.from(tags.columnOrder)
      newColumnOrder.splice(source.index, 1)
      newColumnOrder.splice(destination.index, 0, draggableId)
      const newState = {
        ...tags,
        columnOrder: newColumnOrder,
      }
      dispatch(updateTagsInState(newState))
      if (result.destination.index > result.source.index) {
        decreaseSortOrder(oldIndex, newIndex, draggableId)
      }

      if (result.destination.index < result.source.index) {
        increaseSortOrder(oldIndex, newIndex, draggableId)
      }
      return
    }

    if (checkIfUserHasTag(result, tagOrCategoryId)) {
      setShow(true)
    } else {
      saveTags(result)
    }
  }
  const draggableTags = () => {
    if (!tags || !tags.columnOrder || tags.length === 0) {
      return <div />
    }
    const draggedTags = tags
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <TagHeader title={UNCATEGORIZED_TAGS} />
        <Droppable droppableId="all-columns1" direction="horiztonal" type="column1">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {/* eslint-disable array-callback-return */}
              {/* eslint-disable-next-line consistent-return */}
              {draggedTags.columnOrder.map((columnId, index) => {
                const column = draggedTags.column[columnId]
                const nestedTags = column.taskIds.map((taskId) => draggedTags.tags[taskId])
                if (column?.title === UNCATEGORIZED_TAGS) {
                  return (
                    <Column
                      key={column.id}
                      column={column}
                      tags={nestedTags}
                      deleteTag={openDeleteTagFromStoreModal}
                      setHoveredTagId={updateHoveredTagId}
                      hoveredTagId={hoveredTagId}
                      width={16}
                      deleteCategory={openDeleteCategoryModal}
                      edit_users={edit_users}
                      index={index}
                      style={{ margin: 0 }}
                    />
                  )
                }
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <div className="categorized-tags-header">
          <TagHeader title="Categorized Tags" />
        </div>
        {edit_users && addGroupTag()}
        <Droppable droppableId="all-columns" direction="horiztonal" type="column">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ overflowX: 'auto', display: 'flex', marginTop: '.5rem', gap: '15px' }}
            >
              {/* eslint-disable-next-line consistent-return */}
              {tags.columnOrder.map((columnId, index) => {
                const column = draggedTags.column[columnId]
                const nestedTags = column.taskIds.map((taskId) => draggedTags.tags[taskId])
                if (column?.title !== UNCATEGORIZED_TAGS) {
                  return (
                    <Column
                      key={column.id}
                      column={column}
                      tags={nestedTags}
                      deleteCategory={openDeleteCategoryModal}
                      updateCategory={updateCategoryFromStore}
                      deleteTag={openDeleteTagFromStoreModal}
                      setHoveredTagId={updateHoveredTagId}
                      hoveredTagId={hoveredTagId}
                      width={4}
                      edit_users={edit_users}
                      index={index}
                    />
                  )
                }
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
  const displayBreadcrumb = (userOrgId) => {
    const backLinks = {
      label: userOrgId === 1 ? name : 'users',
      link: userOrgId === 1 ? `/organizations/${organizationId}` : '/users',
    }

    return <Breadcrumbs backLinks={backLinks} currentLink="Manage Tags" />
  }

  const openModalDeleteAllTags = async () => {
    const message = `Are you sure you want to delete all tags?`
    await setDeleteModal({
      title: message,
      closeModal: () => setDeleteModal(deleteModalObject),
      deleteFunc: () => removeAllTags(),
      display: true,
    })
  }
  return (
    <div>
      {deleteModal.display && (
        <FormModal
          title="Delete Tag"
          onSave={deleteModal.deleteFunc}
          onClose={deleteModal.closeModal}
          closeButtonLabel="Cancel"
          submitButtonLabel="Delete"
          show={deleteModal.display}
          size="tiny"
        >
          <div data-testid="confirm-delete-modal" className="organization__active-modal-content">
            {deleteModal.title}
          </div>
        </FormModal>
      )}
      <BasicModal title="WARNING" onClose={() => showModal()} show={show}>
        <p>
          Moving this tag is prevented as it will cause a user to have more than one assigned tag
          from this category.
        </p>
        <Button
          color="red"
          onClick={() => showModal()}
          floated="right"
          style={{ marginBottom: '15px' }}
        >
          Close
        </Button>
      </BasicModal>
      <header className="page-header">
        <div>
          <h1 style={{ paddingBottom: '0.75rem' }}>{name}</h1>
          {displayBreadcrumb(userOrganizationId)}
        </div>
        <Form>
          {userOrganizationId === 1 && (
            <button
              type="button"
              className="ui button red"
              onClick={() => openModalDeleteAllTags()}
              disabled={!allTags || !allTags.length || loading}
            >
              Remove All Tags
            </button>
          )}
        </Form>
      </header>
      {edit_users && <h2>Add New Tags</h2>}
      {loading && (
        <div className="ui center aligned basic segment">
          <Dimmer active inverted>
            <Loader active size="large" inline="centered">
              {loadingMessage}
            </Loader>
          </Dimmer>
        </div>
      )}
      {edit_users && (
        <CategoryForm
          handleChange={handleTagGroup}
          createAction={createTagsLocally}
          handleDropdownChange={handleDropdownChange}
          category={category}
          loading={loading}
          formValue={tagGroup}
          tagCategories={tagCategories}
          formPlaceholder={
            "Enter the tag or tags you'd like to add, separated by commas. A tag name cannot include spaces."
          }
          displayDropdown
        />
      )}
      <br />
      {draggableTags()}
    </div>
  )
}
export default withLDConsumer()(TagForm)
