import { Popup, Radio } from 'semantic-ui-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { FormModal } from '@/components/layout/modals/FormModal'
import { closeModal, openModal } from '@/reducers/ui/ui.redux'

export const ChallengeStatusToggle = ({
  challenge,
  challengeHasEmptyFields,
  updateChallStatus,
}) => {
  const dispatch = useDispatch()
  const { currentlyOpenModalId: modal } = useSelector((state) => state.ui)

  const disableToggle = challengeHasEmptyFields && challenge.status !== 'scheduled'

  const hideToggleStatuses = ['active', 'cancelled', 'completed', 'deleted']
  const hideToggle = hideToggleStatuses.includes(challenge.status)

  const editableStatuses = ['inactive', 'scheduled']
  const hidePopup = !challengeHasEmptyFields && editableStatuses.includes(challenge.status)

  const activeStatuses = ['active', 'scheduled']
  const challengeScheduledOrActive = activeStatuses.includes(challenge.status)

  const updatedStatus = challenge.status === 'scheduled' ? 'inactive' : 'scheduled'

  const onClose = () => {
    dispatch(closeModal())
  }

  const handleChangeChallengeStatus = (challengeToUpdate, newStatus) => {
    updateChallStatus(challengeToUpdate, newStatus)
    onClose()
  }

  const openStatusModal = () => {
    dispatch(openModal(`changeChallengeStatus--${challenge.id}`))
  }

  const getChallengeStatusText = () => {
    if (challengeHasEmptyFields && challenge.status === 'inactive') {
      return 'Dates and participants must be selected in order to activate the challenge.'
    }
    if (challenge.status === 'cancelled') {
      return 'This challenge has been cancelled and cannot be reactivated.'
    }
    if (challenge.status === 'active') {
      return 'This challenge is active and can only be cancelled.'
    }
    return ''
  }

  return (
    <label
      data-testid="challenge-status-toggle-label"
      className={classNames('status-label', hideToggle && 'visibility-hidden')}
    >
      Scheduled
      <Popup
        inverted
        content={getChallengeStatusText()}
        disabled={hidePopup}
        trigger={
          <Radio
            data-testid={`challenge-${challenge.status === 'scheduled' ? 'active' : 'dormant'}`}
            className="challenge-active-toggle"
            toggle
            checked={challengeScheduledOrActive}
            disabled={disableToggle}
            onChange={openStatusModal}
          />
        }
      />
      {modal === `changeChallengeStatus--${challenge.id}` && (
        <FormModal
          title="Confirmation"
          onSave={() => handleChangeChallengeStatus(challenge, updatedStatus)}
          onClose={onClose}
          closeButtonLabel="Cancel"
          submitButtonLabel="Confirm"
          size="small"
          show={modal === `changeChallengeStatus--${challenge.id}`}
        >
          {!challengeHasEmptyFields && (
            <div
              data-testid="confirm-status-modal"
              className="scorecard-editor__active-modal-content"
            >
              Are you sure you want to change the status of the &ldquo;{challenge.name}
              &rdquo; challenge?
            </div>
          )}
          {challengeHasEmptyFields && (
            <div
              data-testid="confirm-status-modal"
              className="scorecard-editor__active-modal-content"
            >
              You cannot activate the &ldquo;{challenge.name}
              &rdquo; challenge until you assign a date and users to it.
            </div>
          )}
        </FormModal>
      )}
    </label>
  )
}
