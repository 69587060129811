import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Loader } from 'semantic-ui-react'

import './CallSearchProxy.scss'

export const CallSearchProxy = () => {
  const history = useHistory()

  useEffect(() => {
    history.push('/call-explorer')
  })

  return (
    <div data-testid="call-search-proxy" className="call-search-proxy__container">
      <Loader inline active size="massive" className="call-search-proxy__loader" />
      <div data-testid="backup-text" className="call-search-proxy__backup-text">
        Redirecting to the Call Explorer. If you are not redirected within a few seconds, click{' '}
        <a data-testid="backup-link" href="/call-explorer">
          here
        </a>
      </div>
    </div>
  )
}

export default CallSearchProxy
