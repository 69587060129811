import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Segment, Form, Radio, Accordion, Button } from 'semantic-ui-react'
import { capitalize, isEmpty, xor } from 'lodash'
import { IconCheck, IconX } from '@tabler/icons-react'

import { fetchCSVExport, preFetchCSVExport } from '@/reducers/analytics/analytics.actions'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'

export const ExportCSVSection = () => {
  const dispatch = useDispatch()
  const {
    loading,
    filters: { playbooks },
    data: { callsDownloaded },
  } = useSelector((state) => state.analytics)
  const [disabledSections, setDisabledSections] = useState([])
  const [reportType, setReportType] = useState('call_log')
  const allowedSections = ['checklist', 'notifications', 'classified_postcall', 'deck']
  const allowedSectionsSummary = ['checklist', 'notifications']
  const radioOptions = [
    { label: 'Call Log', value: 'call_log' },
    { label: 'Summary', value: 'summary' },
  ]
  const noSectionsSelected =
    reportType === 'call_log'
      ? isEmpty(xor(allowedSections, disabledSections))
      : isEmpty(xor(allowedSectionsSummary, disabledSections))

  const handleChangeReportType = (event, { value }) => {
    setReportType(value)
  }

  const handleToggleSection = (section) => {
    setDisabledSections((prevState) => {
      return prevState.includes(section)
        ? prevState.filter((disabledSection) => disabledSection !== section)
        : prevState.concat(section)
    })
  }

  const getSelectedSections = () => {
    return reportType === 'call_log'
      ? allowedSections.filter((section) => !disabledSections.includes(section))
      : allowedSectionsSummary.filter((section) => !disabledSections.includes(section))
  }

  const handleExport = async () => {
    const selectedSections = getSelectedSections()

    const playbook = await dispatch(preFetchCSVExport())

    if (playbook) {
      dispatch(fetchCSVExport(reportType, selectedSections, playbook))
    }
  }

  const getSectionName = (section) => {
    if (section === 'classified_postcall') return 'Post Call'
    if (section === 'deck') return 'Dynamic Prompt'

    return capitalize(section)
  }

  const isDisabled = () => {
    if (noSectionsSelected) return true
    if (playbooks.length !== 1) return true

    return false
  }

  return (
    <div data-testid="reports-csv-exports">
      <Segment>
        <h3>Export CSV</h3>
        <Form>
          <Form.Group>
            <Form.Field>
              <label>Report Type</label>
            </Form.Field>
            {radioOptions.map((radioOption) => (
              <Form.Field key={radioOption.value}>
                <Radio
                  label={radioOption.label}
                  name="reportType"
                  value={radioOption.value}
                  checked={reportType === radioOption.value}
                  onChange={handleChangeReportType}
                />
              </Form.Field>
            ))}
          </Form.Group>
          <Accordion>
            {allowedSections
              .filter((section) => {
                // if reportType is summary we don't want classified_postcall/deck included
                // because we haven't decided what customers want in summary for them
                if (allowedSections.includes(section) && reportType !== 'summary') {
                  return true
                }
                if (allowedSectionsSummary.includes(section) && reportType === 'summary') {
                  return true
                }

                return false
              })
              .map((section) => (
                <Accordion.Title
                  onClick={() => handleToggleSection(section)}
                  active={!disabledSections.includes(section)}
                  key={section}
                >
                  <div className="flex-align-center small-gap">
                    {disabledSections.includes(section) ? (
                      <IconX className="icon-svg status-critical" />
                    ) : (
                      <IconCheck className="icon-svg status-success" />
                    )}
                    <span>{getSectionName(section)}</span>
                  </div>
                </Accordion.Title>
              ))}
          </Accordion>
          <div className="flex-align-center gap" style={{ marginTop: '1rem' }}>
            <ConditionalTooltip condition={isDisabled()} content="Select one playbook">
              <Button
                data-testid="export-csv-button"
                primary
                type="button"
                loading={loading.exportCSVPage}
                onClick={handleExport}
                disabled={isDisabled()}
              >
                Export CSV
              </Button>
            </ConditionalTooltip>
            <div>
              <strong>Downloading Calls: </strong>
              <span>{callsDownloaded}</span>
            </div>
          </div>
        </Form>
      </Segment>
    </div>
  )
}
