import React, { useState } from 'react'
import { Accordion, Checkbox, Icon, Table } from 'semantic-ui-react'
import { NestedTableBaseBody } from '@/components/tables/NestedTable/NestedTableBaseBody'

export const NestedTableNestedBody = ({
  columns,
  rows,
  collapsibleChildren,
  selectable,
  selectedRows,
  onSelect,
  nestedSelect,
  basic,
  setColumnSpan = 4,
  expandByDefault = true,
  striped = false,
}) => {
  const dataIsNested = rows?.some((row) => row.nestedField)
  const expandedRowTracker = rows?.reduce(
    (reducer, row) => ({ ...reducer, [row?.name?.value || row?.name]: expandByDefault }),
    {}
  )
  const [expanded, setExpanded] = useState(expandedRowTracker)
  const basicClass = basic ? 'basic' : ''

  const handleNestedSelect = (rows) => {
    nestedSelect(rows)
  }
  //  accordion

  const toggleAccordion = (rowName) => {
    setExpanded({
      ...expanded,
      [rowName]: !expanded[rowName],
    })
  }

  if (dataIsNested) {
    return (
      <>
        {rows.map((row, number) => (
          <React.Fragment key={row?.name.value || row?.name}>
            <Accordion.Title
              as={Table.Row}
              active={!collapsibleChildren || expanded[row?.name.value || row?.name]}
              index={number}
              className={`nested-table__body-row ${basicClass}`}
              data-testid="nested-sub-table-accordion-title"
            >
              {selectable && (
                <Table.Cell collapsing className={`nested-sub-table__header-cell ${basicClass}`}>
                  <Checkbox
                    className="nested-sub-table__row-select-box"
                    data-testid="nested-sub-table-checkbox"
                    checked={selectedRows[row.key]?.selected}
                    indeterminate={selectedRows[row.key]?.partiallySelected}
                    onClick={() => (dataIsNested ? handleNestedSelect(row) : onSelect(row))}
                  />
                </Table.Cell>
              )}
              {columns.map((column, columnIndex) =>
                row[column.accessor]?.as ? (
                  <Table.Cell
                    key={column.accessor}
                    onClick={() => toggleAccordion(row?.name.value || row?.name)}
                    data-testid={`custom-component-${column.accessor}`}
                    className={`nested-sub-table__header-cell ${basicClass}`}
                  >
                    {columnIndex === 0 ? (
                      <div className="first-column">
                        <Icon name="dropdown" /> {row[column.accessor].as}
                      </div>
                    ) : (
                      <div>{row[column.accessor].as} </div>
                    )}
                  </Table.Cell>
                ) : (
                  <Table.Cell
                    key={column.label}
                    onClick={() => toggleAccordion(row?.name.value || row?.name)}
                    className={`nested-sub-table__header-cell ${basicClass}`}
                    data-testid="nested-sub-table-header-cell"
                  >
                    {columnIndex === 0 ? (
                      <div>
                        <Icon name="dropdown" /> {row[column.accessor]}{' '}
                      </div>
                    ) : (
                      <div>{row[column.accessor]} </div>
                    )}
                  </Table.Cell>
                )
              )}
            </Accordion.Title>
            <Accordion.Content
              active={!collapsibleChildren || expanded[row?.name.value || row?.name]}
              as={Table.Row}
              className="nested-sub-table__accordion-content"
              data-testid="nested-sub-table-accordion-content"
            >
              <Table.Cell
                colSpan={setColumnSpan}
                className={`nested-sub-table__sub-table-container-cell ${basicClass}`}
              >
                <Table
                  basic={basic ? 'very' : false}
                  className="nested-sub-table"
                  data-testid="nested-sub-table"
                  key={row?.name.value || row?.name}
                >
                  <Table.Body>
                    <NestedTableNestedBody
                      columns={columns}
                      rows={row[row.nestedField]}
                      collapsibleChildren={collapsibleChildren}
                      selectable={selectable}
                      onSelect={onSelect}
                      selectedRows={selectedRows}
                      nestedSelect={nestedSelect}
                      basic={basic}
                      striped={striped}
                    />
                  </Table.Body>
                </Table>
              </Table.Cell>
            </Accordion.Content>
          </React.Fragment>
        ))}
      </>
    )
  }

  return (
    <NestedTableBaseBody
      rows={rows}
      columns={columns}
      selectable={selectable}
      selectedRows={selectedRows}
      onSelect={onSelect}
      nestedSelect={nestedSelect}
      basic={basic}
      striped={striped}
    />
  )
}
