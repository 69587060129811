import { toast } from 'react-toastify'

import { fetchingAPI, apiService } from '@/api'
import {
  setLoading,
  setOrgDropdown,
  setOrgHierarchy,
  setUserOrgHierarchy,
  setFlattenedUserOrgHierarchy,
} from '@/reducers/orgHierarchy/orghierarchy.redux'

const flattenHierarchy = (hierarchy) => {
  return hierarchy.children.reduce((acc, item) => {
    return [...acc, item, ...flattenHierarchy(item)]
  }, [])
}

export const fetchOrgHierarchy =
  (organizationId, options = {}) =>
  async (dispatch) => {
    // if no organizationId passed in, then we are getting hierarchy for the user's own_organization_id
    const useOwnOrganizationId = !organizationId

    try {
      const orgHierarchy = await fetchingAPI(
        `${apiService.web}/api/organizations/hierarchy${
          useOwnOrganizationId ? '' : `?org_id=${organizationId}`
        }`,
        'GET',
        dispatch,
        undefined,
        undefined,
        undefined,
        useOwnOrganizationId
      )
      if (useOwnOrganizationId) {
        dispatch(setUserOrgHierarchy(orgHierarchy))
        dispatch(setFlattenedUserOrgHierarchy([orgHierarchy, ...flattenHierarchy(orgHierarchy)]))
      } else {
        dispatch(setOrgHierarchy(orgHierarchy))
      }
    } catch (err) {
      if (!options.hideError) {
        toast.error('Error retrieving org hierarchy')
      }
    }
  }

export const fetchUpdateParentOrg = (parentOrg, childOrg, currentViewedOrg) => async (dispatch) => {
  setLoading(true)

  try {
    dispatch(setOrgDropdown({ parentOrgId: null, selectedChildOrgId: '' }))

    await fetchingAPI(
      `${apiService.web}/api/organizations/${childOrg}`,
      'PATCH',
      dispatch,
      JSON.stringify({ parent_id: parentOrg })
    )

    await dispatch(fetchOrgHierarchy(currentViewedOrg))

    if (!parentOrg) {
      toast.success(`Successfully removed ${childOrg} as child org`)
    } else {
      toast.success(`Successfully set ${childOrg} as the child org of ${parentOrg}`)
    }
  } catch (err) {
    toast.error(`Error setting org ${childOrg} parent's id to ${parentOrg}`)
  } finally {
    setLoading(false)
  }
}
