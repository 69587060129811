import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Segment } from 'semantic-ui-react'

import NoData from '@/components/NoData'
import { AdvancedTablePagination } from '@/components/tables/AdvancedTable/AdvancedTablePagination'
import { ModuleCard } from '@/components/cards/ModuleCard'

import { AgentCallStatusHeader } from './AgentCallStatusHeader'
import { AgentCallStatusEntry } from './AgentCallStatusEntry'

// Lots of duplication for pagination and such - will abstract out if we're using this list view again
export const AgentCallStatusList = () => {
  const [activePage, setActivePage] = useState(1)
  const { loading, agents, listeningToAgent } = useSelector((state) => state.commandCenter)
  const rowsPerPage = 10

  useEffect(() => {
    // Reset table if there's a reload
    if (loading.agents) {
      setActivePage(1)
    }
  }, [loading])

  if (isEmpty(agents)) {
    return (
      <Segment className="not-padded">
        <div className="empty-table">
          <NoData text="No agents found" />
        </div>
      </Segment>
    )
  }

  // Find pagination values
  const count = agents.length
  const calculatedRowsPerPage = rowsPerPage
  const totalPages = Math.ceil(count / calculatedRowsPerPage)

  // Paginate and separate pinned agent
  const pinnedAgent = agents.find((agent) => agent.agent_username === listeningToAgent)
  const filteredRows = agents
    .filter((agent) => agent.agent_username !== listeningToAgent)
    .slice((activePage - 1) * calculatedRowsPerPage, activePage * calculatedRowsPerPage)

  return (
    <ModuleCard title="Monitoring">
      <AgentCallStatusHeader />
      {pinnedAgent && <AgentCallStatusEntry agent={pinnedAgent} />}
      {filteredRows.map((agent, i) => {
        return <AgentCallStatusEntry key={agent.agent_username} agent={agent} index={i} />
      })}
      <AdvancedTablePagination
        activePage={activePage}
        rowsPerPage={calculatedRowsPerPage}
        count={count}
        totalPages={totalPages}
        setActivePage={setActivePage}
        transparent
      />
    </ModuleCard>
  )
}
