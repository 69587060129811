import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { format } from 'date-fns'
import { Table, Popup } from 'semantic-ui-react'
import { isEmpty, kebabCase, isNil } from 'lodash'
import { IconCopy } from '@tabler/icons-react'
import { toast } from 'react-toastify'

import { Pill } from '@/components/pills/Pill'
import { fetchCallSummaryByCallId } from '@/reducers/callSearch/callSearch.actions'
import { ExternalLink } from '@/components/ExternalLink'

import { formatDuration } from './helpers'

import './TranscriptDetails.scss'

export const TechnicalDetails = ({ metadata = {}, callId }) => {
  const dispatch = useDispatch()
  const [hoveredRow, setHoveredRow] = useState('')
  const { qa_enabled, qa_copilot_enabled } = useSelector((state) => state.currentOrganization)
  const {
    organization_name: organizationName,
    voip_call_id: voipCallID,
    agent_name: agentName,
    call_start_time: callStartTime,
    call_end_time: callEndTime,
    config_name: configName,
    call_duration: callDuration = 0,
    is_win: isWin,
    username,
    call_uuid: callUUID,
    voip_customer_id: voipCustomerId,
    voip_campaign_name: voipCampaignName,
    disposition,
    qa_eligibility,
    config_id: configId,
  } = metadata

  const dateTimeFormat = 'MMMM d, yyyy h:mm aa'

  const formattedMetadata = [
    { label: 'Organization', value: organizationName },
    { label: 'VOIP ID', value: voipCallID },
    { label: 'Agent', value: agentName },
    { label: 'Call Duration', value: formatDuration(callDuration) },
    { label: 'Start Time', value: callStartTime ? format(callStartTime, dateTimeFormat) : null },
    { label: 'End Time', value: callEndTime ? format(callEndTime, dateTimeFormat) : null },
    {
      label: 'Playbook',
      value: configName,
      component: (
        <ExternalLink
          id="playbook-link"
          to={`/playbooks/${configId}/checklist`}
          label={configName}
        />
      ),
    },
    { label: 'Username', value: username },
    {
      label: 'Win',
      value: !!isWin,
      component: (
        <Pill small success={isWin} critical={!isWin}>
          {isWin ? 'Yes' : 'No'}
        </Pill>
      ),
    },
    { label: 'Call UUID', value: callUUID },
    { label: 'VOIP Customer ID', value: voipCustomerId },
    { label: 'VOIP Campaign Name', value: voipCampaignName },
    { label: 'Disposition', value: disposition },
  ]

  if (qa_enabled || qa_copilot_enabled) {
    const displayValue = !isEmpty(qa_eligibility)
      ? qa_eligibility
          .map((item) => `${item.config_name}: ${item.reason.replaceAll(/_/g, ' ')}`)
          .join('\n')
      : 'Eligible'

    formattedMetadata.push({
      label: 'QA Eligibility',
      value: displayValue,
    })
  }

  useEffect(() => {
    dispatch(fetchCallSummaryByCallId(callId))
  }, [])

  return (
    <div className="call-metadata-container">
      <h2>Technical Details</h2>

      <Table compact basic celled selectable className="call-metadata-grid">
        <Table.Body>
          {formattedMetadata.map((item) => {
            return (
              <Table.Row
                onMouseEnter={() => {
                  setHoveredRow(item.label)
                }}
                onMouseLeave={() => {
                  setHoveredRow('')
                }}
                onClick={() => {
                  if (!isNil(item.value)) {
                    navigator.clipboard.writeText(item.value)
                    toast.success('Copied!')
                  }
                }}
                key={item.label}
                className="call-metadata-grid-row"
                data-testid={`copy-row-${kebabCase(item.label)}`}
                style={{ cursor: !isNil(item.value) ? 'pointer' : 'default' }}
              >
                <Table.Cell>
                  <strong>{item.label}</strong>
                </Table.Cell>
                <Table.Cell>
                  <div className="call-metadata-value-container">
                    <span className="muted-text pre-wrap" data-testid={item.label}>
                      {item.component || item.value}
                    </span>
                    <Popup
                      inverted
                      content="Copy Value"
                      position="top center"
                      trigger={
                        <div
                          className={classNames('copy-button-container', {
                            hovered: item.label === hoveredRow && !isNil(item.value),
                          })}
                          data-testid={`copy-button-${kebabCase(item.label)}`}
                        >
                          <IconCopy />
                        </div>
                      }
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </div>
  )
}
