import React from 'react'
import '../charts.scss'
import classNames from 'classnames'

export const PerformanceIndicatorBar = ({ passing_average }) => {
  const failing_average = 100 - passing_average

  return (
    <div
      className="ui primary progress progress_bar performance-indicator-bar"
      data-testid="performance-indicator-bar"
    >
      <div
        className={classNames(
          passing_average > 99 ? 'uniform-status-border' : 'some-passing-border',
          'passing-bar'
        )}
        style={{
          width: `${passing_average}%`,
        }}
      />
      <div
        className={classNames(
          failing_average > 99 ? 'uniform-status-border' : 'some-failing-border',
          'failing-bar'
        )}
        style={{
          width: `${failing_average}%`,
        }}
      />
    </div>
  )
}
