import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createSpecialtyBadge,
  fetchAgentsByOrg,
  fetchSpecialtyBadgesByOrgId,
  updateSpecialtyBadgeById,
} from '@/reducers/gamification/gamification.actions'
import { Loader } from 'semantic-ui-react'
import './BadgeWizard.scss'
import classNames from 'classnames'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { closeModal } from '@/reducers/ui/ui.redux'
import { BadgeWizardCreationForm } from '@/views/Gamification/Badges/BadgeWizard/BadgeWizardCreationForm'
import { BadgeWizardGrid } from '@/views/Gamification/Badges/BadgeWizard/BadgeWizardGrid'

const borisMembers = ['doc', 'isaac', 'kelsey', 'gaby', 'tony', 'stephen.k', 'deidre', 'kaitlyn.f']
const isBoris = (username) => borisMembers.some((name) => username.includes(name))

export const BadgeWizardPage = () => {
  const dispatch = useDispatch()
  const {
    organizationid: organizationId,
    user_id: userId,
    username,
  } = useSelector((state) => state.currentUser)
  const { data, loading } = useSelector((state) => state.gamification)
  const [selectedBadge, setSelectedBadge] = useState(null)
  const [showCreationModal, setShowCreationModal] = useState(null)

  useEffect(() => {
    dispatch(fetchSpecialtyBadgesByOrgId(organizationId))
    dispatch(fetchAgentsByOrg(organizationId))
  }, [])

  if (organizationId !== 1) {
    return null
  }

  if (!isBoris(username)) {
    return 'Not today ❌'
  }

  const toggleCreationModal = () => {
    setShowCreationModal(!showCreationModal)
  }

  const openEditModal = (badge) => {
    setSelectedBadge(badge)
  }

  const closeEditModal = () => {
    setSelectedBadge(null)
  }

  const saveEditModal = (badgeId, badgeInfo) => {
    const parsedFormData = {
      ...badgeInfo,
      userIds: badgeInfo.userIds.map((userOption) => userOption.value),
    }
    dispatch(updateSpecialtyBadgeById(badgeId, parsedFormData))
    closeEditModal()
  }

  const saveCreationModal = (badgeInfo) => {
    dispatch(createSpecialtyBadge(badgeInfo, userId))
    dispatch(closeModal())
  }

  // Loading state
  if (loading.badgeWizard) {
    return (
      <div data-testid="loading-badge-wizard">
        <Loader active />
      </div>
    )
  }

  return (
    <div data-testid="badge-detail-page" className="badge-detail">
      <div className="badge-wizard__header-section">
        <div className="badge-wizard__title-section">
          <h1 className="badge-detail__title" data-testid="badge-detail-title">
            Badge Wizard 🧙
          </h1>
          <h2 className="muted-subheader">Create and assign specialty badges.</h2>
        </div>
        <ButtonAndFormModal
          data-testid="create-specialty-badge"
          buttonLabel="Create a Specialty Badge"
          modalTitle="Create a Specialty Badge"
          modalId="create-specialty-badge"
          form={
            <BadgeWizardCreationForm
              onSave={saveCreationModal}
              onClose={toggleCreationModal}
              loading={loading.specialtyBadges}
            />
          }
        />
      </div>
      <div
        data-testid="badge-grid"
        className={classNames('badge-wizard__badge-grid')}
        id="badge-wizard-grid"
      >
        <BadgeWizardGrid
          badges={data.specialtyBadges}
          selectedBadge={selectedBadge}
          onClose={closeEditModal}
          onSave={saveEditModal}
          onOpen={openEditModal}
          loading={loading.specialtyBadges}
          agentOptions={data.agents}
        />
      </div>
    </div>
  )
}

export default BadgeWizardPage
