import React from 'react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { IconVolume3 } from '@tabler/icons-react'

import { Banner } from '@/components/banners/Banner'

import { AudioPlayer } from './AudioPlayer'

export const AudioPlayerViewerComponent = ({
  flags = { callExplorerAudioPlayer2022: true },
  audioStatus,
  screenCaptureStatus,
  transcriptScrollerRef = { current: null },
}) => {
  const showAudioPlayer = flags?.callExplorerAudioPlayer2022
  const isAudioExpired = audioStatus === 'expired'
  const isAudioAvailable = audioStatus === 'available'
  const isScreenCaptureAvailable = screenCaptureStatus === 'available'

  return (
    <>
      {isAudioExpired && (
        <Banner
          data-testid="audio-not-available"
          informative
          header="Audio for this call is not available"
          icon={<IconVolume3 />}
        >
          The audio recording is not available per your organization&apos;s data retention policies.
          The transcript and call events associated with this call will remain unchanged and
          accessible.
        </Banner>
      )}
      <AudioPlayer
        transcriptScrollerRef={transcriptScrollerRef}
        isScreenCaptureAvailable={isScreenCaptureAvailable}
        isAudioAvailable={isAudioAvailable}
        showAudioPlayer={showAudioPlayer}
      />
    </>
  )
}

export const AudioPlayerViewer = withLDConsumer()(AudioPlayerViewerComponent)
