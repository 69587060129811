import React, { Component } from 'react'
import { Form, Button, Loader, Dimmer } from 'semantic-ui-react'

// This form breaks the mold because we don't want a plaintext
// passphrase floating around in redux
export default class PasswordForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      passphrase: '',
      loading: true,
    }

    this.updatePassphrase = this.updatePassphrase.bind(this)
    this.deletePassphrase = this.deletePassphrase.bind(this)
    this.generatePassphrase = this.generatePassphrase.bind(this)
    this.submitPassphrase = this.submitPassphrase.bind(this)
    this.submitBadPassphrase = this.submitBadPassphrase.bind(this)
  }

  componentDidMount() {
    this.props.generatePassphrase(this.updatePassphrase)
  }

  updatePassphrase(passphrase) {
    this.setState(() => ({ passphrase, loading: false }))
  }

  deletePassphrase(e) {
    const { user, updateUser, modalClose } = this.props
    user.password = null

    updateUser(user)
    modalClose(e)
  }

  generatePassphrase() {
    this.setState((prevState) => ({ ...prevState, loading: true }))
    this.props.generatePassphrase(this.updatePassphrase)
  }

  submitPassphrase() {
    const { user, updateUser, modalClose } = this.props
    user.password = this.state.passphrase

    updateUser(user)
    modalClose()
  }

  submitBadPassphrase() {
    const { reportPassphrase } = this.props
    const { passphrase: badPassphrase } = this.state

    reportPassphrase(badPassphrase)
  }

  render() {
    const { user } = this.props
    const { passphrase, loading } = this.state

    return (
      <Form>
        <div className="ui center aligned basic segment">
          {/* https://github.com/Semantic-Org/Semantic-UI-React/issues/879 */}
          {loading ? (
            <div className="ui center aligned basic segment">
              <Dimmer active inverted>
                <Loader active size="large" inline="centered" />
              </Dimmer>
              <br />
              <br />
            </div>
          ) : (
            <div className="ui basic segment">
              <h2 data-testid="passphrase">{passphrase}</h2>
            </div>
          )}
          <br />
          <Form.Field className="ui clearing">
            <Button.Group>
              <Button
                data-testid="save-new-password-button"
                onClick={this.submitPassphrase}
                primary
                content="Save New Password"
                icon="save"
                labelPosition="left"
              />
              {user.password && (
                <Button
                  onClick={this.deletePassphrase}
                  content="Remove BaltoCloud Access"
                  icon="trash"
                  size="small"
                  color="red"
                  labelPosition="right"
                />
              )}
            </Button.Group>
            <br />
            <br />
            <a
              onClick={this.generatePassphrase}
              role="presentation"
              data-testid="generate-passphrase-link"
            >
              Don&apos;t like this phrase? Generate a new one.
            </a>
            <br />
            <br />
            <a onClick={this.submitBadPassphrase}>
              This password is horrifying - I want to report it!
            </a>
          </Form.Field>
        </div>
      </Form>
    )
  }
}
