import React from 'react'
import { Popup } from 'semantic-ui-react'
import { isEmpty } from 'lodash'

import { SpinnerLoader } from '@/components/loader/SpinnerLoader'

// text: str
// maxLen: number
export const truncateAndAddPopup = (text, maxLen) => {
  if (text.length < maxLen) return <span className="no-wrap">{text}</span>

  const truncatedText = `${text.slice(0, maxLen - 1)}…`
  return (
    <Popup inverted content={text} trigger={<span className="no-wrap">{truncatedText}</span>} />
  )
}

// selectedList: [] str
// maxLen: number
export const shortenMultipleSelectedHover = (
  selectedList,
  maxLen,
  truncateTextLen = 25,
  selectedWording = 'selected',
  addTagToSingleItem = false
) => {
  if (isEmpty(selectedList)) {
    return { value: '', as: <></> }
  }
  // Case for when we have one or many loaders
  if (React.isValidElement(selectedList[0])) {
    return <SpinnerLoader dark />
  }
  if (selectedList.length === 1) {
    let text = selectedList[0]
    if (addTagToSingleItem) {
      text += ' tag'
    }
    return { value: text, as: truncateAndAddPopup(text, truncateTextLen) }
  }

  const trigger = (
    <span className="rtc-dotted-show-hover">{`${selectedList.length} ${selectedWording}`}</span>
  )
  let hoverString = selectedList[0]
  let endingString = ''
  let maxElements
  if (selectedList.length < maxLen) {
    maxElements = selectedList.length
  } else {
    endingString += `, +${selectedList.length - maxLen} more`
    maxElements = maxLen
  }
  for (let i = 1; i < maxElements; i++) {
    hoverString += `, ${selectedList[i]}`
  }
  hoverString += endingString
  return { value: trigger, as: <Popup inverted content={hoverString} trigger={trigger} /> }
}

export const stringifyAlertConfig = ({
  id,
  active_alert_config_version_id,
  name,
  alertType,
  selectedAgents,
  selectedTags,
  triggerItems,
  audible,
  active,
}) => {
  return JSON.stringify({
    id,
    active_alert_config_version_id,
    name,
    message: '',
    all_users: false,
    type: alertType,
    users: selectedAgents.map(({ value }) => value),
    tags: selectedTags.map(({ value }) => value),
    trigger_on: triggerItems.map(({ playbookCid, triggerItemPath }) => ({
      cid: playbookCid,
      path: triggerItemPath,
    })),
    audible,
    active,
  })
}
