import React, { useEffect } from 'react'
import { Header } from 'semantic-ui-react'
import { TriggerItemSelector } from '@/views/RealtimeCoaching/Alerts/OrgLevelAlertWizard/components/TriggerItemSelector'
import { TriggerItemContainer } from '@/views/RealtimeCoaching/Alerts/OrgLevelAlertWizard/components/TriggerItemContainer'
import { capitalize } from 'lodash'
import { Field } from 'formik'

export const TriggerSelectForm = ({ isEditPage = false, values, setFieldValue }) => {
  const { triggerItems: formikTriggerItems } = values

  useEffect(() => {
    const triggerListBottom = document.getElementById('trigger-list-bottom')
    triggerListBottom?.scrollIntoView({ behavior: 'smooth' })
  }, [formikTriggerItems.length])

  useEffect(() => {
    if (!isEditPage) {
      setFieldValue('displayedForm', 'triggerSelectForm')
    }
  }, [])

  const pushTriggerItems = (playbook, itemType, triggerItems, form) => {
    const newTriggerItems = [...form.values.triggerItems]
    triggerItems.forEach((triggerItem) => {
      newTriggerItems.push({
        playbookLabel: playbook.label,
        playbookCid: playbook.value,
        itemType: capitalize(itemType),
        triggerItemLabel: triggerItem.label,
        triggerItemPath: triggerItem.value,
        requiredBefore: triggerItem.required_before,
      })
    })
    form.setFieldValue('triggerItems', newTriggerItems)
  }

  const removeTriggerItem = (playbookCid, triggerItemPath, form) => {
    const { triggerItems } = form.values
    const newTriggerItems = triggerItems.filter((triggerItem) => {
      return (
        triggerItem.playbookCid !== playbookCid || triggerItem.triggerItemPath !== triggerItemPath
      )
    })
    form.setFieldValue('triggerItems', newTriggerItems)
  }

  return (
    <div className="alert-wizard__page" data-testid="alert-wizard__triggers-page">
      {!isEditPage && <Header className="alert-wizard__field">Add Triggers</Header>}
      <Field name="triggerItemSelector">
        {(formikProps) => (
          <>
            <TriggerItemSelector
              formikTriggerItems={formikTriggerItems}
              onAddTriggerItems={pushTriggerItems}
              {...formikProps}
            />
            <TriggerItemContainer
              formikTriggerItems={formikTriggerItems}
              onRemoveTriggerItem={removeTriggerItem}
              {...formikProps}
            />
          </>
        )}
      </Field>
    </div>
  )
}
