import { isEmpty, sortBy, partition, isNil, last, isNaN } from 'lodash'

// Sort all settings, then split them into two arrays: one with order, one without
// Then, add the new widget to the end of the ordered array, and tack the unordered ones onto the end
export const getSettingsAfterAddNewWidget = (settings, widgetToAdd) => {
  const [hasOrder, hasNoOrder] = partition(settings, (setting) => !isNil(setting.order))

  let updatedSettings = [...hasOrder]

  if (!isEmpty(hasOrder)) {
    const highestOrder = last(hasOrder).order
    updatedSettings.push({ ...widgetToAdd, accepted: true, order: highestOrder + 1 })
  } else {
    updatedSettings.push({ ...widgetToAdd, accepted: true, order: 0 })
  }

  if (!isEmpty(hasNoOrder)) {
    updatedSettings = [...updatedSettings, ...hasNoOrder]
  }

  return updatedSettings
}

export const getSettingsAfterDismissNewWidget = (settings, widgetToDismiss) => {
  const updatedSettings = [...settings, { ...widgetToDismiss, accepted: false, order: null }]

  return updatedSettings
}

// Sort all settings, then split them into two arrays: one with order, one without
// Then, add the new widget to the end of the ordered array, and tack the unordered ones onto the end
export const getSettingsAfterAcceptExistingWidget = (settings, widgetToAccept) => {
  const [hasOrder, hasNoOrder] = partition(settings, (setting) => !isNil(setting.order))

  let updatedSettings = [...hasOrder]
  let highestOrder

  if (!isEmpty(hasOrder)) {
    highestOrder = last(hasOrder).order
  }

  const updatedOrder = hasNoOrder.map((setting) =>
    setting.id === widgetToAccept.id
      ? { ...setting, accepted: true, order: !isNaN(highestOrder + 1) ? highestOrder + 1 : 0 }
      : setting
  )
  updatedSettings = sortBy([...updatedSettings, ...updatedOrder], 'order')

  return updatedSettings
}

// Find the widget to remove, then set its accepted flag to false and remove the order
// Update the orders for all other widgets
export const getSettingsAfterRemoveExistingWidget = (settings, widgetToRemove) => {
  const updatedSettings = settings
    .map((setting) =>
      setting.id === widgetToRemove.id
        ? { ...widgetToRemove, accepted: false, order: null }
        : setting
    )
    .map((setting, index) => {
      if (setting.order) {
        return { ...setting, order: index }
      }
      return setting
    })

  return updatedSettings
}
