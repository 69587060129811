import React from 'react'
import classNames from 'classnames'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { Button, Form } from 'semantic-ui-react'
import Tag from './Tag'
import { UNCATEGORIZED_TAGS } from '../../utils/constants'

/* eslint-disable jsx-quotes */
export default class Column extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false,
      title: this.props.column.title,
    }

    this.handleTitleChange = this.handleTitleChange.bind(this)
  }

  toggleEdit() {
    const { editing } = this.state
    this.setState({ editing: !editing })
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value })
  }

  editAndDeleteIcons() {
    return (
      <div
        style={{
          display: 'inline-flex',
          float: 'right',
        }}
      >
        <i
          onClick={() => this.toggleEdit()}
          style={{
            cursor: 'pointer',
            position: 'relative',
            float: 'right',
            margin: '12px 12px',
          }}
          data-testid="edit_category_toggle"
          className="icon pencil black"
          role="presentation"
          key={`${this.props.column.id}-edit`}
        />
        <i
          onClick={() => this.props.deleteCategory(this.props.column)}
          style={{
            cursor: 'pointer',
            position: 'relative',
            float: 'right',
            margin: '12px 12px',
          }}
          data-testid="delete_category"
          className="icon trash black"
          role="presentation"
          key={`${this.props.column.id}-delete`}
        />
      </div>
    )
  }

  render() {
    const { editing, title } = this.state
    const { edit_users, column } = this.props
    let freezeColumn
    if (column.title === UNCATEGORIZED_TAGS) freezeColumn = true
    if (!edit_users) freezeColumn = true
    const windowSize = column.title === UNCATEGORIZED_TAGS ? '' : '300px'
    return (
      <Draggable
        draggableId={this.props.column.id.toString()}
        index={this.props.index}
        isDragDisabled={freezeColumn}
      >
        {(provided) => (
          <div className="column-container" {...provided.draggableProps} ref={provided.innerRef}>
            <div style={{ minWidth: windowSize, maxWidth: windowSize }}>
              <div className="border-bottom">
                {editing ? (
                  <div style={{ display: 'inline' }}>
                    <div className="column-form-box">
                      <Form
                        onSubmit={() =>
                          this.props.updateCategory(this.props.column, this.state.title)
                        }
                        style={{ display: 'inline-flex' }}
                      >
                        <Form.Input
                          data-testid="edit_category_text_box"
                          placeholder={title}
                          value={title}
                          onChange={this.handleTitleChange}
                          style={{ display: 'inline-flex' }}
                        />
                        <div style={{ display: 'inline-block' }}>
                          <Button
                            secondary
                            data-testid="edit_category_button"
                            style={{
                              display: 'inline-block',
                              marginLeft: '5px',
                              marginTop: '1px',
                            }}
                          >
                            Submit
                          </Button>
                        </div>
                      </Form>
                    </div>
                    {edit_users && this.editAndDeleteIcons()}
                  </div>
                ) : (
                  <h3 className="column-title" {...provided.dragHandleProps}>
                    {this.props.column.title}
                  </h3>
                )}
                {this.props.column.title !== UNCATEGORIZED_TAGS && (
                  <>{!editing && edit_users && this.editAndDeleteIcons()}</>
                )}
              </div>
              <Droppable
                droppableId={this.props.column.id.toString()}
                direction="horizontal"
                type="task"
              >
                {(provided, snapshot) => (
                  <div
                    className={classNames('column-tag-list', {
                      'dragging-over': snapshot.isDraggingOver,
                    })}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {this.props.tags.map((tag, index) => {
                      if (!tag || !tag.id) return <div />
                      return (
                        <Tag
                          key={tag.id}
                          tag={tag}
                          index={index}
                          deleteTag={this.props.deleteTag}
                          setHoveredTagId={this.props.setHoveredTagId}
                          hoveredTagId={this.props.hoveredTagId}
                        />
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        )}
      </Draggable>
    )
  }
}
