import { getTagOptions } from '../../utils/helpers'

export function getLeaderboardBody(values = {}, leaderboardId) {
  const body = {
    ...values,
    ...(leaderboardId && { leaderboard_filter_id: leaderboardId }),
    config_ids: values.config_ids?.map((item) => item.value) || [],
    min_duration_in_seconds: values.call_duration * 60, // turn mins into seconds
    tags: values.tags?.map((item) => item.value) || [],
  }
  delete body.call_duration // this get converted to min_duration_in_seconds

  return body
}

export function getLeaderboardInitialValues(selectedLeaderboard, allTags = [], allConfigs = []) {
  if (!selectedLeaderboard) return {}

  const playbookOptions = allConfigs
    .filter((allConfig) =>
      selectedLeaderboard.version?.config_ids?.some((cid) => cid === allConfig.cid)
    )
    .map((config) => ({ label: config.name, value: config.cid }))
  const tags = allTags.filter((allTag) =>
    selectedLeaderboard.version?.tags?.some((tag) => tag === allTag.id)
  )
  const tagOptions = getTagOptions(tags)

  return {
    name: selectedLeaderboard.version.name,
    agent_visible: selectedLeaderboard.leaderboard.agent_visible,
    include_managers: selectedLeaderboard.leaderboard.include_managers,
    config_ids: playbookOptions,
    scorecard_config_sid: selectedLeaderboard.version.scorecard_config_sid,
    rank_identifier: selectedLeaderboard.version.rank_identifier,
    date_range: selectedLeaderboard.version.date_range,
    call_duration: (selectedLeaderboard.version.min_duration_in_seconds / 60).toFixed(2), // turn seconds into mins.
    tags: tagOptions,
  }
}

export const validateUniqueName = (value, options) => {
  let error
  options.forEach((option) => {
    if (option.toLowerCase() === value.toLowerCase().trim()) {
      error = 'Name is already in use'
    }
  })
  return error
}

export const getLeaderboardOptions = (data, initialValues) => {
  const options = []
  data.forEach((item) => {
    if (item.version.name.toLowerCase() !== initialValues?.name?.toLowerCase()) {
      options.push(item.version.name)
    }
  })
  return options
}
