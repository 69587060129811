import React, { useState, useEffect } from 'react'
import { Segment } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import './ChallengesPage.scss'
import {
  updateChallengeGroup,
  updateChallengeStatus,
  updateGroups,
} from '@/reducers/gamification/gamification.actions'
import { ChallengeStatusBadge } from '@/views/Gamification/Challenges/ChallengeDetailPage/ChallengeStatusBadge'
import { ChallengeStatusToggle } from '@/views/Gamification/Challenges/ChallengesPage/ChallengeStatusToggle'
import { closeModal, openModal } from '@/reducers/ui/ui.redux'
import { AgentTagPicker } from '@/views/Gamification/Challenges/ChallengesPage/AgentTagPicker'
import { AgentTagTrigger } from '@/views/Gamification/Challenges/ChallengesPage/AgentTagTrigger'
import { ChallengeStatusDropdown } from '@/views/Gamification/Challenges/ChallengesPage/ChallengeStatusDropdown'
import { ChallengeDatePicker } from '@/views/Gamification/Challenges/ChallengesPage/ChallengeDatePicker'

export const NestedChallengeForm = ({
  individualChallenge,
  challengeTemplate,
  redirectToChallengeDetail,
  challengeHasEmptyFields,
  actions = {},
}) => {
  const dispatch = useDispatch()
  const [challenge, setChallenge] = useState(individualChallenge)
  const [groupToEdit, setGroupToEdit] = useState({})
  const [orderedGroups, setOrderedGroups] = useState({})
  const [disableGroupEdit, setDisableGroupEdit] = useState(false)
  const challengeHasStarted = !['scheduled', 'inactive'].includes(challenge.status)
  const disableClear = challenge.status === 'scheduled' && disableGroupEdit

  useEffect(() => {
    setChallenge(individualChallenge)
  }, [individualChallenge])

  const { data, loading } = useSelector((state) => state.gamification)

  const showAgentTagPicker = (currentChallenge, group, orderedGroups) => {
    const { groupId } = group
    setChallenge(currentChallenge)
    setGroupToEdit(group)
    setOrderedGroups(orderedGroups)
    dispatch(openModal(`groupAgentPicker--${groupId}`))
  }

  const closeAgentTagPicker = () => {
    dispatch(closeModal)
    setGroupToEdit({})
    setDisableGroupEdit(false)
  }

  const submitGroupChanges = (group, orderedGroups) => {
    if (orderedGroups.groups?.length > 0) {
      dispatch(updateGroups(orderedGroups, challenge))
    }
    dispatch(updateChallengeGroup(group))
    dispatch(closeModal)
    closeAgentTagPicker()
  }

  const updateStatus = (challengeToUpdate, newStatus) => {
    dispatch(updateChallengeStatus(challengeToUpdate, newStatus))
  }

  if (challenge.length === 0) return <></>

  const challengeFieldClassName =
    challengeTemplate.type === 'team vs team' ? 'challenge-fields-tvt' : 'challenge-fields'
  return (
    <div data-testid="nested-challenge-form">
      {/* NESTED FORM CONTENT */}
      <Segment.Group>
        <Segment data-testid="nested-challenge-form-selections">
          <div className={challengeFieldClassName}>
            <div
              className="challenge-name"
              data-testid="challenge.name"
              onClick={() => redirectToChallengeDetail(challenge.id)}
            >
              <div className="flex-column small-gap">
                <div className="challenge-name__badges">
                  <ChallengeStatusBadge status={challenge.status} />
                </div>
                <div data-testid={`challenge-name-${challenge.name}`}>{challenge.name}</div>
              </div>
            </div>
            <ChallengeDatePicker individualChallenge={individualChallenge} />
            <AgentTagTrigger
              challenge={challenge}
              onClick={(group, orderedGroups) => {
                showAgentTagPicker(challenge, group, orderedGroups)
              }}
            />
            <div data-testid="challenge-status" className="challenge-status">
              <ChallengeStatusToggle
                challenge={challenge}
                challengeHasEmptyFields={challengeHasEmptyFields(challenge)}
                updateChallStatus={updateStatus}
              />
              <ChallengeStatusDropdown challenge={challenge} actions={actions} />
            </div>
          </div>
        </Segment>
      </Segment.Group>
      {/* MODAL */}
      <AgentTagPicker
        challengeGroup={groupToEdit}
        challengeStatus={challenge.status}
        orderedGroups={orderedGroups}
        agentOptions={data.agents}
        tagOptions={data.tags}
        loading={loading}
        disableClearButton={disableClear}
        disableSaveButton={challengeHasStarted}
        onSave={submitGroupChanges}
        disableForm={challenge.status === 'active'}
      />
    </div>
  )
}
