import { toString, partition } from 'lodash'
import moment from 'moment'

const callStatusOrder = ['on_call', 'had_calls_today', 'no_calls_today']

export const alphabeticalSort = (a, b) => {
  return toString(a.agent_first_name).localeCompare(toString(b.agent_first_name), 'en')
}

export const callStatusSort = (a, b) => {
  return (
    callStatusOrder.indexOf(toString(a.call_data?.call_status)) -
    callStatusOrder.indexOf(toString(b.call_data?.call_status))
  )
}

export const commandCenterSort = (agents) => {
  const [online, offline] = partition(agents, (item) => item.agent_status === 'online')

  return [
    ...online.sort(alphabeticalSort).sort(callStatusSort),
    ...offline.sort(alphabeticalSort).sort(callStatusSort),
  ]
}

export const getAgentAndTagParams = (myTeamFilter) => {
  const filterParams = new URLSearchParams()

  myTeamFilter.filters?.agents
    ?.map((agent) => agent.value)
    .forEach((agent) => filterParams.append('agents', agent))
  myTeamFilter.filters?.tags
    ?.map((tag) => tag.value)
    .forEach((tag) => filterParams.append('tags', tag))

  return filterParams
}

export const appendSentimentFilterParams = (filterParams, sentimentConfig) => {
  const sentimentFilters = sentimentConfig.filters

  filterParams.append(
    'start_date',
    sentimentFilters?.timePeriod === 'weekly'
      ? moment().subtract(7, 'd').startOf('day').format()
      : moment().startOf('day').format()
  )

  filterParams.append('exclude_weekends', sentimentFilters?.ignoreWeekends || false)

  return filterParams
}

export const getMergedSentimentData = (sentimentData, additionalReasons) => {
  const updateSentimentData = (data) => {
    let callData = data
    additionalReasons.forEach((reason) => {
      if (reason.call_id === data.call_id) {
        callData = { ...data, upset_reason: reason.upset_reason, positive_reason: reason.reason }
      }
    })
    return callData
  }

  return {
    top_calls: sentimentData.top_calls.map(updateSentimentData),
    bottom_calls: sentimentData.bottom_calls.map(updateSentimentData),
  }
}
