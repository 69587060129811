import classNames from 'classnames'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toNumber } from 'lodash'

export const Content = ({ dataGrid, children }) => {
  const location = useLocation()
  const { id } = useParams()
  const isQAEdit = location.pathname.startsWith('/qa-copilot/') && id && !Number.isNaN(toNumber(id))
  const addWithStickyNavigation = location.pathname.startsWith('/qa-copilot/create') || isQAEdit
  const {
    realtime_coaching_access: rtcEnabledForUser,
    org_realtime_coaching_access: rtcEnabledForOrg,
  } = useSelector((state) => state.currentUser)
  const { sidebarCollapsed, alertSidebarCollapsed } = useSelector((state) => state.ui)
  const rtcEnabled = rtcEnabledForOrg && rtcEnabledForUser

  // Shift pendo icon when sidebar expanded/collapsed
  rtcEnabledForOrg && !alertSidebarCollapsed
    ? document.body.classList.add('shift-pendo-icon')
    : document.body.classList.remove('shift-pendo-icon')

  return (
    <main
      className={classNames('balto-content', {
        collapse: sidebarCollapsed,
        'alert-sidebar-collapsed': rtcEnabled && alertSidebarCollapsed,
        'alert-sidebar-expanded': rtcEnabled && !alertSidebarCollapsed,
      })}
    >
      <div
        className={classNames('balto-content__inner', {
          'with-sticky-navigation': addWithStickyNavigation,
          'with-data-grid': dataGrid,
        })}
      >
        {children}
      </div>
    </main>
  )
}
