import React, { useState, useEffect } from 'react'
import { Popup, Button } from 'semantic-ui-react'
import { DateRangePicker, DateRange } from 'react-date-range'
import moment from 'moment'
import classNames from 'classnames'
import { addDays } from 'date-fns'
import { IconCalendar } from '@tabler/icons-react'

import { FilterPill } from '../filters/FilterPill'
import { DateRangePickerButtons } from './DateRangePickerButtons'
import { DateRangePickerClear } from './DateRangePickerClear'
import { customStaticRanges, getTriggerLabel, isValidDate, dateAriaLabels } from '../helpers'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './DateRangePicker.scss'

export const DateRangePickerReusable = ({
  startDate = null,
  endDate = null,
  initialStartDate = null,
  initialEndDate = null,
  error = false,
  onSave = null,
  onCancel = null,
  onClear = null,
  withButtons = false,
  displayClearButton = false,
  withSidebar = true,
  allowFutureDates = false,
  allowPastDates = true,
  basicLabel = false,
  saveLabel = null,
  cancelLabel = null,
  clearButtonLabel = null,
  disableSaveButton = false,
  disableClearButton = false,
  shouldNotResetOnCancel = false,
  isFilterPill = false,
  modalOpen = false,
  friendlyLabel = false,
  columnAccessor = null,
  noValueSelected = false,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const inputIsInvalid = (startDate && !endDate) || (endDate && !startDate)
  const DateComponent = withSidebar ? DateRangePicker : DateRange
  const maxDate = addDays(new Date(), 10000)
  const minDate = addDays(new Date(), -10000)
  const now = moment().toDate()
  const label = getTriggerLabel(startDate, endDate, basicLabel, friendlyLabel, isFilterPill)
  const popupProps = withButtons ? { open } : {}
  const selectedRange = {
    key: 'selection',
    startDate: startDate ? moment(startDate).toDate() : null,
    endDate: endDate ? moment(endDate).toDate() : null,
  }

  const toggleOpen = (event) => {
    event.stopPropagation()

    setOpen(!open)
  }

  const handleCancel = (event) => {
    event.stopPropagation()

    if (!shouldNotResetOnCancel) {
      props.onChange({
        selection: { key: 'selection', startDate: initialStartDate, endDate: initialEndDate },
      })
    }
    setOpen(false)
    onCancel(event)
  }

  const handleSave = (value) => {
    onSave(value)
    setOpen(false)
  }

  const handleChange = ({ selection: { startDate, endDate } }) => {
    if (!endDate || !isValidDate(endDate)) {
      return props.onChange({ selection: { key: 'selection', startDate, endDate: null } })
    }

    if (!startDate || !isValidDate(startDate)) {
      return props.onChange({ selection: { key: 'selection', startDate: null, endDate } })
    }

    return props.onChange({ selection: { startDate, endDate } })
  }

  useEffect(() => {
    // If a different modal opens, close the date picker popup if it's already open
    if (modalOpen && open) {
      setOpen(false)
    }
  }, [modalOpen])

  return (
    <Popup
      basic
      flowing
      on="click"
      position={props.position || 'bottom left'}
      trigger={
        columnAccessor ? (
          <div>
            <Popup
              inverted
              position="top center"
              content={noValueSelected ? 'Filter by Date' : `Filtering: ${label}`}
              trigger={
                <button
                  data-testid={`${props['data-testid']}-column-accessor`}
                  type="button"
                  className={classNames('table-header-button', {
                    'filter-applied': !noValueSelected,
                  })}
                  onClick={toggleOpen}
                >
                  <IconCalendar />
                </button>
              }
            />
          </div>
        ) : isFilterPill ? (
          <FilterPill
            data-testid={`${props['data-testid']}-filter-pill`}
            clickable
            isClearable={false}
            label="Date"
            value={label}
            onClick={toggleOpen}
          />
        ) : (
          <Button
            type="button"
            secondary
            fluid
            className={classNames('date-picker-trigger', { error })}
            data-testid={props['data-testid']}
            onClick={toggleOpen}
          >
            {label}
          </Button>
        )
      }
      {...popupProps}
    >
      <DateComponent
        ranges={[selectedRange]}
        maxDate={allowFutureDates ? maxDate : now}
        minDate={allowPastDates ? minDate : now}
        rangeColors={['#4b60f6']}
        editableDateInputs
        dateDisplayFormat="MM/dd/yyyy"
        staticRanges={customStaticRanges}
        inputRanges={[]}
        startDatePlaceholder="Start Date"
        endDatePlaceholder="End Date"
        ariaLabels={dateAriaLabels}
        {...props}
        onChange={handleChange}
      />
      {displayClearButton && (
        <DateRangePickerClear
          handleClear={onClear}
          clearButtonLabel={clearButtonLabel}
          disabled={disableClearButton}
        />
      )}
      {withButtons && (
        <DateRangePickerButtons
          handleCancel={handleCancel}
          handleSave={handleSave}
          cancelLabel={cancelLabel}
          saveLabel={saveLabel}
          disabled={inputIsInvalid || disableSaveButton || error}
        />
      )}
    </Popup>
  )
}
