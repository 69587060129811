import React from 'react'
import { PlaybookEntry } from '../../components/PlaybookEntry'

export const NovelContentView = (props) => {
  const { novelChecklistItems } = props
  const novelItems = novelChecklistItems.map((item) => {
    const metadata = {
      createdBy: item.user,
      createdAt: item.created_at,
    }
    return (
      <PlaybookEntry
        key={item.checklist_properties_uuid}
        accessor="checklist"
        entryId={item.checklist_properties_uuid}
        entry={item}
        label="Novel item"
        isLockable={false}
        isWinnable={false}
        metadata={metadata}
        notClickable
      />
    )
  })
  return <div data-testid="novel-content-container">{novelItems}</div>
}
