import React from 'react'
import { Segment } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import uuid from 'uuid'
import moment from 'moment'
import pluralize from 'pluralize'
import { toString } from 'lodash'
import queryString from 'query-string'

import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'

import { CoachingOpportunityRow } from './CoachingOpportunityRow'
import { QACopilotEmailAssignmentForm } from '../forms/QACopilotEmailAssignmentForm'
import { ExternalCallIdsLink } from '../components/ExternalCallIdsLink'

export const CoachingOpportunitiesTable = () => {
  const {
    loading,
    data: { coachingOpportunities },
    filters,
  } = useSelector((state) => state.qaCopilot)
  const { organizationid: currentUserOrgId } = useSelector((state) => state.currentUser)

  const columns = [
    { accessor: 'name', label: 'Agent', sub_content_accessor: 'username' },
    {
      accessor: 'call_count',
      label: 'Calls',
      headerAlignment: 'right',
    },
    { accessor: 'coaching_opportunities', label: 'Coaching Opportunities', isSortable: false },
  ]

  const actions = [
    {
      label: 'Assign to',
      noPopup: true,
      trigger: (row) => {
        const callIds = queryString.stringify({
          call_ids: row.top_missed_criteria.failing_call_ids,
          organizationId: filters.organizationId || currentUserOrgId,
        })
        const callExplorerLink = `/call-explorer?${callIds}`
        const duration = moment.duration(moment(filters.endDate).diff(moment(filters.startDate)))
        const dateRange = pluralize('day', toString(Math.floor(duration.asDays())), true)

        return (
          <ButtonAndFormModal
            buttonLabel="Assign to..."
            modalTitle="Assign Coaching Opportunity"
            modalId={`qaCopilot/assignTo-${uuid()}`}
            modalProps={{ size: 'tiny', hideHeader: true }}
            buttonProps={{ secondary: true }}
            form={
              <QACopilotEmailAssignmentForm
                row={row}
                callExplorerLink={callExplorerLink}
                dateRange={dateRange}
              />
            }
          />
        )
      },
    },
  ]

  const rows = coachingOpportunities.map((data) => {
    return {
      ...data,
      name: `${data.first_name} ${data.last_name}`,
      call_count: {
        as: (
          <div className="text-right">
            <ExternalCallIdsLink
              callIds={data.top_missed_criteria.failing_call_ids}
              label={data.top_missed_criteria.failing_call_count}
            />
          </div>
        ),
        value: data.top_missed_criteria.failing_call_count,
      },
      coaching_opportunities: {
        as: <CoachingOpportunityRow data={data} filters={filters} />,
        value: 'top_missed_criteria',
      },
    }
  })

  return (
    <div>
      <header className="secondary-header">
        <h2>Coaching Opportunities By Agent</h2>
      </header>

      <Segment className="not-padded">
        <AdvancedTable
          testid="coaching-opportunities-table"
          index="uuid"
          loading={loading.coachingOpportunities}
          columns={columns}
          rows={rows}
          actions={actions}
          striped={false}
          pagination
          rowsPerPage={5}
          defaultOrderBy="call_count"
          defaultOrder="desc"
          wrapColumnContent={false}
          enableUpdateRowsPerPage={false}
        />
      </Segment>
    </div>
  )
}
