import React from 'react'
import { DrillDownReportsFilters } from '@/views/RealtimeCoaching/Reports/DrillDownReports/DrillDownReportsFilters'
import { DrillDownAlertTrendsGraph } from '@/views/RealtimeCoaching/Reports/DrillDownReports/DrillDownAlertTrendsGraph'
import Widget from '@/components/widget/Widget'
import { DrillDownTable } from '@/views/RealtimeCoaching/Reports/DrillDownReports/DrillDownTable'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const DrillDownReports = () => {
  const location = useLocation()
  const { customBranding } = useSelector((state) => state.customBranding)
  const { resource } = location.state
  const resourceEnum = {
    alert: 'Alert Trends',
    agent: 'Alert Trends',
    chat: 'Chat Trends',
    listen: 'Listen Trends',
  }

  return (
    <>
      <DrillDownReportsFilters />
      <Widget label={resourceEnum[resource]}>
        <DrillDownAlertTrendsGraph />
      </Widget>
      <Widget
        showCsvDownload
        label="Table"
        customDisplayName={customBranding.customBrandingDisplayName}
      >
        <DrillDownTable />
      </Widget>
    </>
  )
}
