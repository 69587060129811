import React from 'react'
import classNames from 'classnames'
import uuid from 'uuid/v4'

import { formatPercent } from '../../utils/helpers'

const ItemRow = ({ point, keyAccessor, dataAccessor, isPercent, column }) => {
  return (
    <div className="item-row">
      {point.color && <div className="color-indicator" style={{ background: point.color }} />}
      <div
        className={classNames({
          'column-item-text': column,
          'display-text': point.displayText,
        })}
      >
        {point[keyAccessor]}
      </div>
      {!point.displayText && (
        <div style={{ fontWeight: 'bold', marginLeft: '4px' }}>
          {isPercent
            ? formatPercent(point.percent, 1)
            : Number(point[dataAccessor]).toLocaleString()}
        </div>
      )}
    </div>
  )
}

const WidgetLineChartTooltip = ({
  header,
  tooltipData = [],
  isPercent = false,
  keyAccessor, // {section}_item
  dataAccessor, // win_count or count
  column = false,
}) => {
  return (
    <div data-testid="widget-tooltip-line" className="widget__tooltip-line">
      {header && <h4>{header}</h4>}
      {column ? (
        <div className="column" data-testid="widget-tooltip-line-columns">
          {tooltipData.map((point) => {
            return (
              <ItemRow
                key={`${point[keyAccessor]}-${uuid()}`}
                point={point}
                keyAccessor={keyAccessor}
                dataAccessor={dataAccessor}
                isPercent={isPercent}
                column={column}
              />
            )
          })}
        </div>
      ) : (
        tooltipData.map((point) => {
          return (
            <ItemRow
              point={point}
              key={point[keyAccessor]}
              keyAccessor={keyAccessor}
              dataAccessor={dataAccessor}
              isPercent={isPercent}
            />
          )
        })
      )}
    </div>
  )
}

export default WidgetLineChartTooltip
