import moment from 'moment'
import React, { useEffect, useState } from 'react'

export const TimerLabel = ({ lastAlertCreatedAt }) => {
  const formatTimeString = () => {
    const alertedTime = moment.utc(lastAlertCreatedAt)
    moment.relativeTimeThreshold('s', 60)
    moment.relativeTimeThreshold('m', 60)
    moment.relativeTimeThreshold('h', 24)

    return `${alertedTime.fromNow(true)} since last alert`
  }

  const [timeString, setTimeString] = useState(formatTimeString())
  const [intervalId, setIntervalId] = useState(null)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeString(formatTimeString())
      // 60 seconds
    }, 60000)
    setIntervalId(intervalId)
  }, [])

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [intervalId])

  return (
    <div className="accordion-header-timestamp" data-testid="accordionHeaderTimestamp">
      {timeString}
    </div>
  )
}
