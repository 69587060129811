import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Form, Button } from 'semantic-ui-react'
import { isEmpty } from 'lodash'

import { PlaybookForm } from './components/PlaybookForm'
import { RowEditorForm } from './components/RowEditorForm'
import { TestPromptForm } from './components/TestPromptForm'
import { buildPrompt } from './helpers'

export const PromptForm = ({ handleSubmit, values, setFieldValue, arrayHelpers }) => {
  const { rtnPrompt } = useSelector((state) => state.playbook)

  useEffect(() => {
    if (!isEmpty(values.prompt_fields)) {
      setFieldValue('user_prompt', buildPrompt(values.prompt_fields))
    }
  }, [values.prompt_fields.length])

  return (
    <Form className="rtn-editor__prompt rtn-edit-form" onSubmit={handleSubmit}>
      <PlaybookForm setFieldValue={setFieldValue} values={values} />
      {rtnPrompt?.user_prompt && (
        <>
          <RowEditorForm
            setFieldValue={setFieldValue}
            values={values}
            arrayHelpers={arrayHelpers}
          />
          <TestPromptForm values={values} setFieldValue={setFieldValue} />
        </>
      )}

      <div className="flex-end small-gap save-button-container">
        <div data-testid="save-prompt-btn">
          <Button type="submit" primary data-testid="update-rtn-form-button">
            Save New Prompt
          </Button>
        </div>
      </div>
    </Form>
  )
}
