import moment from 'moment'
import React, { useMemo, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { groupBy, isEmpty, flatten, uniq } from 'lodash'
import { chartColors } from '@/utils/constants'
import WidgetContainer from '@/components/widget/WidgetContainer'
import LineChart from '@/components/charts/LineChart'
import WidgetLineChartTooltip from '@/components/widget/WidgetLineChartTooltip'
import WidgetContext from '../../../components/widget/widget.context'
import { getDateFormat } from '../../../utils/helpers'
import { AdvancedTable } from '../../../components/tables/AdvancedTable/AdvancedTable'

export const CountByDateAndUserWidget = () => {
  const { data, loading } = useSelector((state) => state.analytics)
  const {
    groupByValue,
    hiddenItems,
    handleChangeCsvData,
    handleResetWidgetFilters,
    ignoreWeekends,
  } = useContext(WidgetContext)

  const formattedData = useMemo(() => {
    const dataGroupedByUserId = groupBy(data.responseCountsByDateAndUser, 'user_id')
    const userDataGroupedByDateAndSorted = Object.entries(dataGroupedByUserId)
      .map(([userId, dataPoints], userIndex) => {
        const userFullName = `${dataPoints[0].first_name} ${dataPoints[0].last_name}`
        const chartColor = chartColors[userIndex % chartColors.length]
        const dataPointsGroupedByDate = Object.entries(
          groupBy(dataPoints, (result) => moment(result.date).utc().startOf(groupByValue))
        )
          .sort((a, b) => new Date(a[0]) - new Date(b[0]))
          .map(([date, data]) => {
            return {
              x: getDateFormat(new Date(date)),
              y: data.reduce((acc, result) => acc + result.count, 0),
              label: userFullName,
              id: userId,
              color: chartColor,
            }
          })
          .filter((item) =>
            // moment thinks sunday is 1 and saturday is 7
            ignoreWeekends && groupByValue === 'day'
              ? !(moment(item.x).isoWeekday() === 7 || moment(item.x).isoWeekday() === 1)
              : true
          )
        const item = {
          id: userId,
          label: userFullName,
          data: dataPointsGroupedByDate,
          color: chartColor,
        }
        return item
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label)
      })
    return userDataGroupedByDateAndSorted
  }, [data.responseCountsByDateAndUser, groupByValue, ignoreWeekends])

  const filteredData = useMemo(() => {
    return formattedData.filter((item) => !hiddenItems.has(item.id))
  }, [formattedData, hiddenItems])

  const tableData = useMemo(() => {
    return flatten(
      filteredData.map((arr) => {
        // add color attr to data
        return arr.data.map((item) => {
          return Object.assign(item, { color: arr.color })
        })
      })
    )
  }, [filteredData])

  const tickValues = useMemo(
    () => uniq(tableData.map((d) => d.x)).filter((item, i) => i % 2 === 0) || [],
    [tableData]
  )

  const colors = useMemo(() => filteredData.map((data) => data.color), [filteredData])
  const columns = [
    { accessor: 'x', label: 'Date' },
    { accessor: 'label', label: 'Agent' },
    { accessor: 'y', label: 'Response Count', format: (value) => value.toLocaleString() },
  ]
  const groupedByDate = groupBy(tableData, (item) => item.x)
  useEffect(() => {
    handleChangeCsvData({ headers: columns, data: tableData })
  }, [tableData])

  useEffect(() => {
    handleResetWidgetFilters()
  }, [data])

  return (
    <WidgetContainer
      loading={loading.deckPage}
      noData={isEmpty(data.responseCountsByDateAndUser) && hiddenItems.size === 0}
      withLegend
      dataForLegend={{ data: formattedData }}
      legendAccessor="id"
      table={
        <AdvancedTable
          index="uuid"
          rows={tableData}
          columns={columns}
          pagination
          rowsPerPage={10}
          enableUpdateRowsPerPage={false}
        />
      }
      chart={
        <LineChart
          isPercent={false}
          data={filteredData}
          colors={colors}
          tooltip={({ point }) => {
            const xGroupedData = groupedByDate[point.data.x]
            const tooltipData = xGroupedData.filter((item) => item.y === point.data.y)
            const tooltipItemCount = tooltipData.length

            const noItemsUsed = tooltipItemCount === filteredData.length && point.data.y === 0
            const excessItems = tooltipItemCount > 20 ? tooltipData.length - 19 : 0

            const displayData = noItemsUsed
              ? // if none of possible items were used, display text instead of all zeroes
                [{ label: `No responses on ${point.data.x}`, displayText: true }]
              : // if more than 20 items, cut off the list at 19 & incl explanatory text
                excessItems > 0
                ? tooltipData.slice(0, 19).concat({
                    label: `+ ${excessItems} users not displayed`,
                    displayText: true,
                  })
                : [...tooltipData]
            return (
              <WidgetLineChartTooltip
                header={point.data.x}
                tooltipData={displayData}
                keyAccessor="label"
                dataAccessor="y"
              />
            )
          }}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Date',
            legendOffset: 36,
            legendPosition: 'middle',
            ...(groupByValue === 'day' &&
              uniq(tableData.map((d) => d.x)).length > 30 && {
                tickValues,
              }),
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Count',
            legendOffset: -60,
            legendPosition: 'middle',
            format: (v) => (Math.floor(v) === v ? Number(v).toLocaleString() : v),
          }}
        />
      }
    />
  )
}
