import { useCustomNivoTheme } from '@/components/charts/customCharts/helpers'
import { Chip } from '@nivo/tooltip'
import React from 'react'

export const BasicTooltip = ({ id, value, enableChip = false, color, renderContent }) => {
  const theme = useCustomNivoTheme()

  let content
  if (typeof renderContent === 'function') {
    content = renderContent()
  } else {
    content = (
      <div style={theme.tooltip.basic}>
        {enableChip && (
          <Chip color={color} style={{ ...theme.tooltip.chip, borderRadius: '50px' }} />
        )}
        {value !== undefined ? (
          <span>
            {id}: <strong>{`${value}`}</strong>
          </span>
        ) : (
          id
        )}
      </div>
    )
  }
  return <div style={theme.tooltip.container}>{content}</div>
}
