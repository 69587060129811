import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Divider, Form, Button } from 'semantic-ui-react'

import { getOrganizationOptions } from '@/utils/helpers'
import {
  fetchOrgHierarchy,
  fetchUpdateParentOrg,
} from '@/reducers/orgHierarchy/orghierarchy.actions'
import { setOrgDropdown } from '@/reducers/orgHierarchy/orghierarchy.redux'
import Select from '@/components/forms/Select'
import { RecursiveOrgList } from './RecursiveOrgList'

import './OrgHierarchyPage.scss'

const OrgHierarchyPage = () => {
  const dispatch = useDispatch()
  const { organizationid } = useParams()
  const { organizations } = useSelector((state) => state)
  const organizationOptions = getOrganizationOptions(organizations)
  const { orgHierarchy, orgDropdown } = useSelector((state) => state.orgHierarchy)
  const root = { children: [orgHierarchy] } // make recursion easier

  const handleSelectOrganizationId = (value) => {
    dispatch(setOrgDropdown({ selectedChildOrgId: value }))
  }

  const handleCloseOrgDropdown = () => {
    dispatch(setOrgDropdown({ parentOrgId: null, selectedChildOrgId: '' }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    dispatch(
      fetchUpdateParentOrg(orgDropdown.parentOrgId, orgDropdown.selectedChildOrgId, organizationid)
    )
  }

  useEffect(() => {
    dispatch(fetchOrgHierarchy(organizationid))
  }, [])

  return (
    <div className="small-container">
      <header className="page-header" data-testid="integrations-page">
        <h1>Organization Hierarchy</h1>
      </header>

      {root && <RecursiveOrgList root={root} />}

      {orgDropdown.parentOrgId && (
        <>
          <Divider />

          <div className="label-style">
            Select child org to add to:&nbsp;
            <strong>
              {organizationOptions.find((org) => org.value === orgDropdown.parentOrgId)?.label} (
              {orgDropdown.parentOrgId})
            </strong>
          </div>

          <Form onSubmit={handleSubmit} className="org-selector">
            <div>
              <Select
                placeholder="Select An Organization"
                options={organizationOptions}
                loading={isEmpty(organizationOptions)}
                value={orgDropdown.selectedChildOrgId}
                onChange={(option, action) =>
                  action.action === 'clear'
                    ? handleSelectOrganizationId('')
                    : handleSelectOrganizationId(option.value)
                }
              />
            </div>
            <div className="flex-align-center small-gap">
              <Button
                primary
                type="submit"
                disabled={!orgDropdown.selectedChildOrgId}
                className="no-wrap"
              >
                Set as Child Org
              </Button>
              <Button secondary type="button" onClick={handleCloseOrgDropdown}>
                Clear
              </Button>
            </div>
          </Form>
        </>
      )}
    </div>
  )
}

export default OrgHierarchyPage
