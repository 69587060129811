import React from 'react'
import { AlertSettingsForm } from '@/views/RealtimeCoaching/Alerts/forms/AlertSettingsForm'
import '../EditAlertPage.scss'

export const SettingsTab = ({ setFieldValue }) => {
  return (
    <div className="edit-alert-page edit-alert-settings-tab">
      <AlertSettingsForm isEditPage setFieldValue={setFieldValue} />
    </div>
  )
}
