import React, { useContext, useEffect } from 'react'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { useSelector } from 'react-redux'
import WidgetContext from '@/components/widget/widget.context'
import moment from 'moment'

export const AdvancedExportsTable = () => {
  const loading = useSelector((state) => state.realtimeAdvancedExports.loading.table)
  const { columns, previewData } = useSelector((state) => state.realtimeAdvancedExports.data)
  const { filters } = useSelector((state) => state.realtimeCoaching)
  const { handleChangeCsvData } = useContext(WidgetContext)

  const formatData = (data) => {
    return data.map((obj) => {
      return { ...obj, date: moment(obj.date).format('YYYY/MM/DD') }
    })
  }

  useEffect(() => {
    handleChangeCsvData({
      data: formatData(previewData),
      headers: columns,
      filters: filters.dateRange,
      fileName: 'RTC Advanced Exports',
    })
  }, [previewData])

  return (
    <div className="rtc-advanced-exports-table">
      <AdvancedTable
        loading={loading}
        columns={columns}
        defaultOrder="asc"
        defaultOrderBy={columns[0]?.value}
        rows={previewData}
        rowsPerPage={10}
        pagination
        wrapColumnContent
        hoverable={false}
        index="uuid"
      />
    </div>
  )
}
