import * as Yup from 'yup'

const isViewingForm = (formName) => (displayedForm) => formName === displayedForm
const isViewingSettingsForm = isViewingForm('alertSettingsForm')
const isViewingAgentsForm = isViewingForm('alertAgentSelectForm')
const isViewingTriggerForm = isViewingForm('triggerSelectForm')
const isViewingEditPage = isViewingForm('edit')

export const genericSchema = {
  displayedForm: Yup.string(),
  active: Yup.boolean(),
  audible: Yup.boolean(),
  alertOn: Yup.string(),
  name: Yup.string().when('displayedForm', {
    is: (displayedForm) => isViewingSettingsForm(displayedForm) || isViewingEditPage(displayedForm),
    then: Yup.string()
      .required('Name is required.')
      .max(100, 'Name cannot be longer than 100 characters.'),
  }),
  alertType: Yup.string().when('displayedForm', {
    is: (displayedForm) => isViewingSettingsForm(displayedForm) || isViewingEditPage(displayedForm),
    then: Yup.string().required('Alert type is required.'),
  }),
  selectedAgents: Yup.array().when(['alertOn', 'displayedForm', 'selectedTags'], {
    is: (alertOn, displayedForm, selectedTags) =>
      alertOn === 'custom' &&
      (isViewingAgentsForm(displayedForm) || isViewingEditPage(displayedForm)) &&
      selectedTags &&
      !selectedTags.length,
    then: Yup.array().min(1).required('Alert on is required'),
  }),
  selectedTags: Yup.array().when(['alertOn', 'displayedForm', 'selectedAgents'], {
    is: (alertOn, displayedForm, selectedAgents) =>
      alertOn === 'custom' &&
      (isViewingAgentsForm(displayedForm) || isViewingEditPage(displayedForm)) &&
      selectedAgents &&
      !selectedAgents.length,
    then: Yup.array().min(1).required('Alert on is required'),
  }),
  triggerItems: Yup.array().when('displayedForm', {
    is: (displayedForm) => isViewingTriggerForm(displayedForm) || isViewingEditPage(displayedForm),
    then: Yup.array().min(1).required('Triggers are required'),
  }),
}
