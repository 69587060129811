import React from 'react'

export const SvgDashboardLine = () => {
  return (
    <svg
      width="589"
      height="57"
      viewBox="0 0 589 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'flex', width: '100%' }}
    >
      <path
        d="M0 55H36.8056C39.77 55 42.7087 54.4508 45.473 53.3803L67.7006 44.772C72.0884 43.0727 76.8789 42.7007 81.4765 43.7022L102.209 48.2187C105.734 48.9866 109.387 48.9499 112.896 48.1112L138.323 42.0342C139.905 41.6562 141.444 41.1185 142.917 40.4292L164.737 30.2182C170.56 27.4933 177.233 27.2185 183.26 29.4555L198.625 35.1581C204.013 37.1577 209.939 37.1577 215.327 35.1581L227.834 30.5161C235.487 27.6757 244.06 28.9202 250.589 33.8197L262.686 42.8963C269.264 47.832 277.91 49.0562 285.6 46.1403L302.275 39.8168C304.994 38.7858 307.877 38.2574 310.785 38.2574H335.214C338.065 38.2574 340.893 37.7496 343.565 36.7578L364.311 29.0582C369.698 27.0586 375.625 27.0586 381.012 29.0582L400.342 36.2323C403.928 37.5631 407.779 38.0177 411.576 37.5582L430.965 35.2117C436.138 34.5856 440.966 32.2932 444.721 28.6807L463.893 10.2354C469.071 5.25372 476.209 2.85407 483.345 3.6959L505.21 6.27536L535.016 9.73323C537.226 9.98954 539.46 9.93748 541.655 9.57853L588 2"
        stroke="currentColor"
        strokeWidth="4"
      />
    </svg>
  )
}
