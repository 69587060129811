import { isEmpty } from 'lodash'

export const checkPasswordRequirements = ({ password, confirmPassword }) => {
  let minChars = true
  let charTypes = true
  let match = false
  let passwordComplexityDidNotMeetCount = 0

  if (password && confirmPassword && password === confirmPassword) {
    match = true
  }

  if (!password || password.length < 10) {
    minChars = false
  }

  if (!/[a-z]/.test(password)) {
    passwordComplexityDidNotMeetCount++
  }

  if (!/[A-Z]/.test(password)) {
    passwordComplexityDidNotMeetCount++
  }

  if (!/[0-9]/.test(password)) {
    passwordComplexityDidNotMeetCount++
  }

  if (!/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)) {
    passwordComplexityDidNotMeetCount++
  }

  if (passwordComplexityDidNotMeetCount > 1) {
    charTypes = false
  }

  return { match, minChars, charTypes }
}

export const isButtonDisabled = (errors, requirements) => {
  if (!isEmpty(errors)) {
    return true
  }

  if (!requirements.match || !requirements.minChars || !requirements.charTypes) {
    return true
  }

  return false
}
