import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import { IconCheck, IconX } from '@tabler/icons-react'
import { truncate } from 'lodash'
import * as Yup from 'yup'

import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { EditButton } from '@/components/buttons/EditButton'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import { patchPlaylist, fetchPlaylist } from '@/reducers/playlists/playlists.actions'

export const PlaylistName = ({ playlist = {}, uuid, loading }) => {
  const dispatch = useDispatch()
  const [editing, setEditing] = useState(false)

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false)

    await dispatch(patchPlaylist(uuid, playlist.organization_id, { name: values.name }))

    setEditing(false)

    await dispatch(fetchPlaylist(uuid, playlist.organization_id))
  }

  const handleCancel = () => {
    setEditing(false)
  }

  if (loading) {
    return <Loader active inline />
  }

  return (
    <div className="flex-align-center medium-gap">
      {!editing ? (
        <ConditionalTooltip
          condition={playlist.name?.length > 100}
          content={playlist.name}
          tooltipProps={{ position: 'bottom left' }}
        >
          <div>{truncate(playlist.name, { length: 100 })}</div>
        </ConditionalTooltip>
      ) : (
        <AbstractForm
          horizontal
          noLabel
          onSubmit={handleSubmit}
          existingValues={{ name: playlist.name || '' }}
          schema={[
            {
              name: 'name',
              type: 'text',
              required: true,
              autoFocus: true,
              autoComplete: 'off',
              placeholder: 'Enter Playlist Name',
              customValidators: [
                Yup.string()
                  .required('Playlist name is required')
                  .max(500, 'Must be less than five hundred characters'),
              ],
            },
          ]}
          buttonProps={{ className: 'svg-button' }}
          closeButtonProps={{ className: 'svg-button' }}
          buttonLabel={<IconCheck />}
          closeButtonLabel={<IconX />}
          onClose={playlist?.name && handleCancel}
        />
      )}
      {!editing && <EditButton onEdit={() => setEditing(!editing)} />}
    </div>
  )
}
