import React from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty, toLower } from 'lodash'
import { useSelector } from 'react-redux'
import pluralize from 'pluralize'

import NoData from '@/components/NoData'

import { PlaybookEntry } from './PlaybookEntry'
import { PlaybookSectionHeader } from './PlaybookSectionHeader'
import { SectionWarnings } from './SectionWarnings'
import {
  accessors,
  accessorSectionMap,
  playbookTitlesSectionMap,
  orderedEntriesAccessorMap,
  notificationTriggerTypesDeprecated,
} from '../playbook.helpers'

export const PlaybookEntryDisplay = () => {
  const {
    playbook,
    playbook: { current: isLatestPlaybookVersion },
    warnings,
    loading,
  } = useSelector((state) => state.playbook)
  const { edit_config } = useSelector((state) => state.currentUser)
  const { section } = useParams()
  const accessor = accessorSectionMap[section]
  const title = playbookTitlesSectionMap[section]
  const entries = playbook.body[accessor]
  const isOrdered = orderedEntriesAccessorMap[accessor]
  const iterable = isOrdered ? entries.order : Object.keys(entries.entries)
  const triggerTypes =
    accessor === accessors.NOTIFICATION ? notificationTriggerTypesDeprecated : null
  const readOnly = !isLatestPlaybookVersion || !edit_config
  const createIsDisabled =
    readOnly || (accessor === accessors.STICKY_NOTE && entries.order.length > 0)

  return (
    <>
      {isLatestPlaybookVersion && (
        <SectionWarnings
          section={section}
          warnings={warnings}
          loading={loading.warnings}
          style={{ maxWidth: '770px' }}
        />
      )}
      <PlaybookSectionHeader
        buttonText={`Create ${pluralize.singular(title)}`}
        buttonDisabled={createIsDisabled}
      />
      <div className="playbook-entries" data-testid="playbook-entry-display">
        {isEmpty(iterable) ? (
          <NoData
            text={`Click on the plus to create a new ${toLower(pluralize.singular(title))}`}
            position="left"
          />
        ) : (
          iterable.map((entryId) => {
            const entry = entries.entries[entryId]
            const isRetiredNotificationType =
              accessor === accessors.NOTIFICATION &&
              ['keywords', 'verbatim'].includes(entry.trigger?.type)

            return (
              <PlaybookEntry
                key={entryId}
                accessor={accessor}
                entryId={entryId}
                entry={entry}
                label={toLower(pluralize.singular(title))}
                readOnly={readOnly}
                isRetiredNotificationType={isRetiredNotificationType}
                triggerTypes={triggerTypes}
              />
            )
          })
        )}
      </div>
    </>
  )
}
