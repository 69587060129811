import React from 'react'
import { Button, Form } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { fetchCriteriaUpdateAuditEventsForOrg } from '@/reducers/scorecards/scores.actions'
import Widget from '@/components/widget/Widget'

import { ViewScoresFilters } from '../../ViewScoresFilters/ViewScoresFilters'

import './FiltersSection.css'

export const FiltersSection = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { loading } = useSelector((state) => state.scorecards)

  // Yeah, it's kind of a hack. Until we refactor all of this, I think it's the best approach.
  const isCopilot = location.pathname.startsWith('/qa-copilot/')

  const getAuditEvents = () => {
    dispatch(fetchCriteriaUpdateAuditEventsForOrg(isCopilot))
  }

  return (
    <div className="scorecard-exports_filter-wrapper">
      <Widget displayControls={false}>
        <div className="scorecard-exports_filter-selector">
          <h4 className="scorecard-exports_filter-title">Filters</h4>
          <ViewScoresFilters
            hideFilterButton
            multiScorecard
            hide={{
              sections: true,
              measures: true,
              criteria: true,
              isWin: true,
              agents: true,
              tags: true,
              dispositions: true,
            }}
          />
        </div>
        <div className="scorecard-exports_filter-last-row">
          <Form.Field>
            <div className="scorecard-exports_preview-button">
              <Button
                primary
                type="submit"
                data-testid="filter-button"
                loading={loading.scores}
                onClick={getAuditEvents}
              >
                Submit
              </Button>
            </div>
          </Form.Field>
        </div>
      </Widget>
    </div>
  )
}
