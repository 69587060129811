import { truncate, uniq } from 'lodash'

export const getDynamicOptions = (rows, schema) => {
  const uniqueOptions = uniq(rows.map((row) => row[schema.accessor]?.value || row[schema.accessor]))

  const formattedOptions = uniqueOptions.map((option) => ({
    label: option,
    value: option,
  }))

  return formattedOptions
}

export const getFilterPopupLabel = (type, value, hasValue, label) => {
  if (type === 'text') {
    if (hasValue) {
      return `Searching: ${truncate(value, { length: 30 })}`
    }

    return `Search ${label}`
  }

  if (type === 'select' || type === 'multiSelect') {
    if (hasValue) {
      return `Filtering by: ${truncate(label, { length: 30 })}`
    }

    return `Filter ${label}`
  }

  return 'Search'
}
