import React from 'react'
import { Form } from 'semantic-ui-react'
import { isEmpty } from 'lodash'

import { getPlaceholderContent } from '@/utils/helpers'

import { MultiSelect } from '../forms/MultiSelect'

export const MultiFilter = ({ label, accessor, store, handler, ...props }) => {
  return (
    <Form.Field data-testid={props.dataTestId || `${accessor}-dropdown`}>
      <label>{label}</label>
      <MultiSelect
        options={store.data[accessor]}
        disabled={store.loading[accessor] || isEmpty(store.data[accessor])}
        loading={store.loading[accessor]}
        value={store.filters[accessor]}
        fixedWidth
        onChange={(value) => {
          if (handler) {
            handler({ [accessor]: value })
          } else {
            props.onChange(value)
          }
        }}
        {...getPlaceholderContent(store.data, store.loading, accessor)}
        {...props}
      />
    </Form.Field>
  )
}
