import { toast } from 'react-toastify'

import { apiService, fetchingAPI } from '@/api'

import * as redux from './audio.redux'

export const fetchAudioForCallId = (callId) => {
  return async (dispatch) => {
    dispatch(redux.resetCallAudio())
    dispatch(redux.setLoading({ callAudio: callId })) // set the ID here so it shows the loading state on the right one

    try {
      const {
        audio_address: url,
        audio_file_status: audioStatus,
        expiration_seconds: audioUrlExpiration,
      } = await fetchingAPI(`${apiService.reporting}/api/calls/${callId}/audio`, 'GET', dispatch)

      if (audioStatus === 'available') {
        dispatch(
          redux.updateCallAudio({
            callId,
            audioUrl: url,
            audioUrlExpiration,
            audioStatus,
            isPlaying: true,
          })
        )
      } else {
        toast.error('Failed to fetch audio for call')
      }
    } catch (err) {
      dispatch(redux.updateCallAudio({ audioError: true }))
      toast.error('Failed to fetch audio for call')
    } finally {
      dispatch(redux.setLoading({ callAudio: null }))
    }
  }
}
