import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import pluralize from 'pluralize'
import { isEmpty, toNumber, isString, isArray } from 'lodash'

import { initialState } from '@/reducers/callSearch/callSearch.redux'
import {
  removeFilterAndFetchCalls,
  clearAllFiltersAndFetchCalls,
} from '@/reducers/callSearch/callSearch.actions'
import { DateRangePickerReusable } from '@/components/datePickers/DateRangePickerReusable'
import {
  showFirstResult,
  showNestedResult,
  getOrganizationOptions,
  getHierarchyOrganizationOptions,
} from '@/utils/helpers'
import { FilterPill } from '@/components/filters/FilterPill'
import { FilterTypeSelect } from '@/components/filters/types/FilterTypeSelect'

export const CallSearchPills = ({
  handleSubmitFilters,
  handleClearRange,
  handleUpdateRange,
  dateInvalid,
  modalOpen,
}) => {
  const dispatch = useDispatch()
  const { currentUser, organizations } = useSelector((state) => state)
  const { filters, loading } = useSelector((state) => state.callSearch)
  const { hierarchy_manager } = useSelector((state) => state.currentUser)
  const isBaltoAdmin = currentUser.organizationid === 1
  const showManagerHierarchyOrgDropdown = !isBaltoAdmin && hierarchy_manager
  const { flattenedUserOrgHierarchy } = useSelector((state) => state.orgHierarchy)
  const hasOrganizationDropdown = isBaltoAdmin || showManagerHierarchyOrgDropdown
  const organizationOptions = getOrganizationOptions(organizations)
  const hierarchyOrganizationOptions = getHierarchyOrganizationOptions(flattenedUserOrgHierarchy)

  const handleRemoveFilter = (accessor) => {
    dispatch(removeFilterAndFetchCalls(accessor))
  }

  // These could be refactored out so this could be used for other components
  const pills = {
    callDuration: {
      label: 'Min. Call Duration',
      format: (accessor) => `${pluralize('minute', toNumber(filters[accessor]), true)}`,
    },
    maxCallDuration: {
      label: 'Max. Call Duration',
      format: (accessor) => `${pluralize('minute', toNumber(filters[accessor]), true)}`,
    },
    agents: {
      label: 'Agents',
      format: (accessor) => showFirstResult(filters[accessor]),
    },
    tags: {
      label: 'Tags',
      format: (accessor) => showFirstResult(filters[accessor]),
    },
    playbooks: {
      label: 'Playbooks',
      format: (accessor) => showFirstResult(filters[accessor]),
    },
    isWin: {
      label: 'Win',
      format: (accessor) => (filters[accessor] === 'true' ? 'Only Wins' : 'Only Non-wins'),
    },
    includeCallsWithoutAudio: {
      label: 'Calls Without Audio',
      format: (accessor) => (filters[accessor] ? 'Include' : 'Exclude'),
    },
    keywords: {
      label: 'Keywords',
      format: (accessor) => showFirstResult(filters[accessor]),
    },
    dispositions: {
      label: 'Dispositions',
      format: (accessor) => showFirstResult(filters[accessor]),
    },
    checklist: {
      label: 'Checklist',
      format: (accessor) => showNestedResult(filters[accessor]?.selected),
    },
    deck: {
      label: 'Dynamic Prompt',
      format: (accessor) => showNestedResult(filters[accessor]?.selected),
    },
    postcall: {
      label: 'Post Call',
      format: (accessor) => showNestedResult(filters[accessor]?.selected),
    },
    notifications: {
      label: 'Notifications',
      format: (accessor) => showNestedResult(filters[accessor]?.selected),
    },
  }

  const shouldShowPill = (accessor) => {
    const initialValue = initialState.filters[accessor]
    const currentValue = filters[accessor]

    // isWin is an array with 3 states, and callDuration has a non-empty default value
    if (accessor === 'isWin' || accessor === 'callDuration') {
      return currentValue !== initialValue
    }

    if (isString(initialValue) || isArray(initialValue)) {
      return !isEmpty(filters[accessor])
    }

    // Nested filters have are in the `selected` property
    if (['checklist', 'deck', 'postcall', 'notifications'].includes(accessor)) {
      return !isEmpty(filters[accessor]?.selected)
    }

    return currentValue !== initialValue
  }

  const optionalFilterPillsApplied = Object.entries(pills).filter(([accessor]) =>
    shouldShowPill(accessor)
  )

  return (
    <div className="flex-align-start small-gap flex-wrap">
      {hasOrganizationDropdown && (
        <FilterTypeSelect
          loading={loading?.calls}
          schema={{
            accessor: 'organizationId',
            label: 'Organization',
            options: showManagerHierarchyOrgDropdown
              ? hierarchyOrganizationOptions
              : organizationOptions,
          }}
          value={filters.organizationId}
          updateSearch={(accessor, selectedOrgId) => {
            if (!selectedOrgId) {
              dispatch(clearAllFiltersAndFetchCalls())
            }
            dispatch(clearAllFiltersAndFetchCalls(selectedOrgId))
          }}
        />
      )}

      <DateRangePickerReusable
        withButtons
        startDate={filters.startDate}
        endDate={filters.endDate}
        cancelLabel="Clear"
        onCancel={handleClearRange}
        onChange={handleUpdateRange}
        onSave={handleSubmitFilters}
        shouldNotResetOnCancel
        saveLabel="Apply"
        error={dateInvalid}
        disableSaveButton={dateInvalid}
        isFilterPill
        friendlyLabel
        basicLabel
        modalOpen={modalOpen}
      />
      {!isEmpty(optionalFilterPillsApplied) && (
        <>
          {optionalFilterPillsApplied.map(([accessor, properties]) => (
            <FilterPill
              key={accessor}
              accessor={accessor}
              handleRemoveFilter={() => handleRemoveFilter(accessor)}
              loading={loading?.calls}
              {...properties}
            />
          ))}
        </>
      )}
    </div>
  )
}
