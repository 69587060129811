import React from 'react'
import classNames from 'classnames'
import { startCase } from 'lodash'
import pluralize from 'pluralize'

import { Pill } from '@/components/pills/Pill'

export const PlaybookPreview = ({ values, section, title, children }) => {
  return (
    <div className="playbook-detail__preview">
      <div className="text-center">
        <Pill emphasized>Preview</Pill>
      </div>

      <div className={classNames('playbook-card-preview', { [`${section}-preview`]: !!section })}>
        <div className="playbook-card-preview__header">
          {title || values.name || `${startCase(pluralize.singular(section))} title`}
        </div>
        <div className="playbook-card-preview__content">{children}</div>
      </div>
    </div>
  )
}
