import React from 'react'
import { Grid } from 'semantic-ui-react'
import { MeasureSummary } from '../ViewScorecardSummary/ViewScorecardSummary'

export const ScorecardCriteriaSummary = ({ section, measureDraft }) => {
  return (
    <Grid className="scorecard-summary" data-testid="view-scorecard-summary">
      <Grid.Column>
        <Grid.Row className="scorecard-summary__section-summary" data-testid="section-summary">
          <h2 className="scorecard-summary__section-title">{section.name}</h2>
          <Grid className="scorecard-summary__measure-container">
            <Grid.Column>
              {/* this will get changed to the new component when natural language is finished */}
              <MeasureSummary measure={measureDraft} key={measureDraft.name} />
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  )
}
