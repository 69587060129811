import React from 'react'

import IntegrationsEditor from '@/views/Integrations/IntegrationsEditor'
import { SecondaryNavRouter } from '@/components/layout/SecondaryNavRouter/SecondaryNavRouter'
import IntegrationsPlaybookSwitchingPage from '@/views/Integrations/IntegrationsPlaybookSwitchingPage'
import IntegrationsCallOutcomesPage from '@/views/Integrations/IntegrationsCallOutcomesPage'

export const EditPage = ({ tab }) => {
  const pages = [
    {
      label: 'Connector Config',
      component: <IntegrationsEditor />,
    },
    {
      label: '🚧 Call Outcomes 🚧',
      component: <IntegrationsCallOutcomesPage />,
    },
    {
      label: '🚧 Playbook Switching 🚧',
      component: <IntegrationsPlaybookSwitchingPage />,
    },
  ]

  return (
    <>
      <h1>Edit Integration</h1>

      <SecondaryNavRouter pages={pages} activePage={tab} />
    </>
  )
}

export default EditPage
