import React from 'react'
import { AdvancedTable } from '../../../components/tables/AdvancedTable/AdvancedTable'
import { AlertTableRowName } from './AlertTableRowName/AlertTableRowName'
import '../realtimeCoaching.scss'

export const AlertCountTable = (props) => {
  const { rows } = props

  function identifySeverityLevel(row) {
    switch (row.type) {
      case 'negative':
        return 1
      case 'informative':
        return 2
      default:
        return 3
    }
  }

  function combineRows(rows) {
    const repetitionDict = {}
    rows.forEach((row) => {
      if (repetitionDict[row.name]) {
        repetitionDict[row.name].count += 1
      } else {
        repetitionDict[row.name] = row
        repetitionDict[row.name].count = 1
        repetitionDict[row.name].severity = identifySeverityLevel(row)
      }
    })
    return Object.values(repetitionDict)
  }

  function addDisplayRow(rows) {
    return rows.map((row) => {
      const new_row = { ...row }
      new_row.display = <AlertTableRowName type={row.type} alertName={row.name} count={row.count} />
      return new_row
    })
  }

  function sortRows(rows) {
    // Sort by severity first, count second, name third
    return rows.sort((a, b) => a.severity - b.severity || b.count - a.count || a.name < b.name)
  }

  const combinedRows = combineRows(rows)
  const displayRows = addDisplayRow(combinedRows)
  const sortedRows = sortRows(displayRows)
  const columns = [
    {
      accessor: 'display',
      label: '',
    },
  ]

  return (
    <div data-testid="alertCountsTable" className="alert-count-table">
      <AdvancedTable
        loading={false}
        index="uuid"
        rows={sortedRows}
        columns={columns}
        rowsPerPage={5}
        pagination
        displayHeader={false}
        sortEnabled={false}
        enableUpdateRowsPerPage={false}
      />
    </div>
  )
}
