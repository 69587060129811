import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Popup } from 'semantic-ui-react'
import { IconPlayerPlay } from '@tabler/icons-react'
import { startsWith } from 'lodash'

import { Pill } from '@/components/pills/Pill'
import { convertAgentNameToInitials } from '@/utils/helpers'
import { POPUP_TRIGGER_ID, formatDuration } from './helpers'

export const ConversationBlock = ({ side, data, showHypothesis, highlighted = false }) => {
  const {
    callExplorer: { hardSelectedEvent, metadata = {} },
  } = useSelector((state) => state.callSearch)
  const initials = convertAgentNameToInitials(metadata.agent_name)

  const firstTimestamp = data[0].timestamp
  const blockStartTime = formatDuration(firstTimestamp)
  const formattedTimestamp = startsWith(blockStartTime, '00:')
    ? blockStartTime.slice(3)
    : blockStartTime
  // older call transcripts may not have hypothesis available
  const hasHypothesis = Boolean(data.find((snippet) => snippet.hyp?.length > 0))

  const conversation = data.map((snippet, index) => {
    const { timestamp: snippetTimestamp, text: snippetText } = snippet
    const matchesTimestamp = hardSelectedEvent?.timestamp === snippetTimestamp
    return (
      <span
        // eslint-disable-next-line react/no-array-index-key
        key={`transcript-${snippetTimestamp}-${snippetText}-${index}`}
        data-timestamp={`transcript-${snippetTimestamp}`}
      >
        {snippetText}{' '}
        {matchesTimestamp && (
          <>
            <Popup
              inverted
              position="top center"
              trigger={
                <span
                  data-testid="transcript-event-trigger"
                  id={POPUP_TRIGGER_ID}
                  className="transcript-event-trigger"
                />
              }
            >
              <em>{hardSelectedEvent.displayText}</em> event triggered
            </Popup>{' '}
          </>
        )}
      </span>
    )
  })

  const hypothesis = data.map((snippet) => {
    return (
      <span
        key={`hypothesis-${snippet.timestamp}`}
        data-timestamp={`hypothesis-${snippet.timestamp}`}
        className={classNames({ 'is-diff': snippet.is_diff })}
      >
        {snippet.hyp}{' '}
      </span>
    )
  })

  return (
    <div className="transcript-block" key={firstTimestamp}>
      <div className="transcript-side-container">
        {(side === 1 || side === 0) && (
          <div
            className={classNames('transcript-side', { customer: side === 1, agent: side === 0 })}
          >
            <div>
              {side === 1 ? (
                <Pill small circular emphasized />
              ) : (
                <Pill small circular brand>
                  {initials}
                </Pill>
              )}
            </div>
            <div>{side === 1 ? 'Customer' : 'Agent'}</div>
            <div className="transcript-start-time">
              <div>{formattedTimestamp}</div>
              <IconPlayerPlay />
            </div>
          </div>
        )}
        <p className={`transcript-conversation ${highlighted ? 'highlighted' : ''}`}>
          {conversation}
        </p>

        {showHypothesis && hasHypothesis && (
          <>
            <p className="hypothesis-label">HYPOTHESIS</p>
            <p className="transcript-hypothesis">{hypothesis}</p>
          </>
        )}
      </div>
    </div>
  )
}
