import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import { ProtectedRoute } from './routing/ProtectedRoute'
import { publicRoutes } from './routing/public.routes'
import { protectedRoutes } from './routing/protected.routes'

const App = () => {
  const location = useLocation()

  useEffect(() => {
    const pendoResourceButton = document.getElementsByClassName(
      '_pendo-resource-center-badge-container'
    )[0]
    if (pendoResourceButton) {
      pendoResourceButton.style = `display: ${location.pathname.indexOf('call-explorer') > 0 ? 'none' : 'block'}`
    }
  }, [location])

  return (
    <Switch>
      {publicRoutes.map(({ exact = true, path, Component }) => (
        <Route exact={exact} key={path} path={path} component={Component} />
      ))}
      {protectedRoutes.map(({ exact = true, path, Component, ...props }) => (
        <ProtectedRoute exact={exact} key={path} path={path} component={Component} {...props} />
      ))}
    </Switch>
  )
}

export default App
