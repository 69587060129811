import React, { useState } from 'react'
import { isEmpty } from 'lodash'

import { Button, TextArea, Form } from 'semantic-ui-react'

import { CoachingNote } from './CoachingNote'

export const CoachingNotes = ({
  callId,
  callComments,
  addComment,
  updateComment,
  showHeader = true,
}) => {
  const [newCommentText, setNewCommentText] = useState('')

  return (
    <div className="call-metadata-container">
      {showHeader && <h2 data-testid="coaching-note-header">Coaching Notes</h2>}
      <h3 className="mb">New Coaching Note</h3>
      <Form className="full-width">
        <TextArea
          className="mb"
          data-testid="coaching-note-textarea"
          placeholder="Write a short Coaching Note"
          value={newCommentText}
          onKeyDown={(e) => {
            e.stopPropagation()
          }}
          onChange={(e) => {
            setNewCommentText(e.target.value)
          }}
        />
        <div className="flex-end">
          <Button
            primary
            data-testid="coaching-note-save"
            type="submit"
            onClick={() => {
              addComment(newCommentText, callId)
              setNewCommentText('')
            }}
            disabled={isEmpty(newCommentText)}
          >
            Save
          </Button>
        </div>
      </Form>
      {callComments.map((comment) => (
        <CoachingNote
          key={comment.id}
          coachingComment={comment}
          callId={callId}
          updateComment={updateComment}
        />
      ))}
    </div>
  )
}
