import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { SearchableDropdown } from '@/components/dropdowns/SearchableDropdown'
import {
  fetchPlaylists,
  addCallsToPlaylist,
  createPlaylist,
  fetchPlaylistsByCallId,
} from '@/reducers/playlists/playlists.actions'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { IconFlag, IconPlaylistAdd } from '@tabler/icons-react'

export const AddToPlaylist = ({ callIds = [], handleDeselectAll, organizationId, ...props }) => {
  const [newPlaylistModalOpen, setNewPlaylistModalOpen] = useState(false)
  const { playlists, loading } = useSelector((state) => state.playlists)
  const dispatch = useDispatch()
  const { id: callId } = useParams()

  const coachingPlaylist = playlists.find((playlist) => playlist.type === 'coaching')

  const playlistOptions = playlists
    .filter((playlist) => playlist.type !== 'coaching')
    .map((playlist) => ({
      key: playlist.uuid,
      label: playlist.name,
      value: playlist.uuid,
    }))

  const handleOpenNewPlaylistModal = async () => {
    setNewPlaylistModalOpen(true)
  }

  const handleCloseNewPlaylistModal = async () => {
    setNewPlaylistModalOpen(false)
  }

  const handleAddToPlaylist = async (playlistUuid, isCoachingPlaylist) => {
    if (playlistUuid && !isEmpty(callIds)) {
      await dispatch(addCallsToPlaylist(playlistUuid, organizationId, callIds, isCoachingPlaylist))

      if (handleDeselectAll) {
        handleDeselectAll()
      }

      if (callId) {
        await dispatch(fetchPlaylistsByCallId(callId))
      }
    }
  }

  const handleCreatePlaylist = async (values) => {
    if (!isEmpty(values.name) && !isEmpty(callIds)) {
      await dispatch(createPlaylist(values.name, organizationId, callIds))

      if (handleDeselectAll) {
        handleDeselectAll()
      }

      if (newPlaylistModalOpen) {
        handleCloseNewPlaylistModal()
      }
    }
  }

  const actions = [
    {
      key: 'new-playlist',
      fn: handleOpenNewPlaylistModal,
      label: (
        <div className="flex-align-center small-gap">
          <IconPlaylistAdd className="icon-svg status-brand" />
          Add to New Playlist
        </div>
      ),
    },
  ]

  if (coachingPlaylist) {
    actions.push({
      key: 'coaching-playlist',
      fn: () => handleAddToPlaylist(coachingPlaylist.uuid, true),
      label: (
        <div className="flex-align-center small-gap">
          <IconFlag className="icon-svg status-warning" />
          Flag for Coaching
        </div>
      ),
    })
  }

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchPlaylists(organizationId, true))
    }
  }, [])

  return (
    <>
      <SearchableDropdown
        buttonProps={{ content: 'Add to...', className: 'primary' }}
        inputProps={{ placeholder: 'Search...' }}
        options={playlistOptions}
        loading={loading.playlists}
        menuPosition="right"
        handleSelect={handleAddToPlaylist}
        actions={actions}
        dataTestId="playlist"
        {...props}
      />

      {newPlaylistModalOpen && (
        <BasicModal
          data-testid="add-to-new-playlist"
          title="New Playlist"
          onClose={handleCloseNewPlaylistModal}
          show={newPlaylistModalOpen}
          size="tiny"
        >
          <AbstractForm
            schema={[
              {
                name: 'name',
                type: 'text',
                label: 'Playlist Name',
                required: true,
                autoFocus: true,
              },
            ]}
            onSubmit={handleCreatePlaylist}
            buttonLabel="Create Playlist with Calls"
            onClose={handleCloseNewPlaylistModal}
            isModal
          />
        </BasicModal>
      )}
    </>
  )
}
