import React from 'react'
import { Link } from 'react-router-dom'

export const ManageTagsLink = ({ toUrl }) => {
  return (
    <div style={{ margin: '.5rem' }} data-testid="manage-tags-link">
      <Link style={{ color: 'white' }} to={toUrl}>
        <button type="button" className="ui primary button">
          Manage Tags
        </button>
      </Link>
    </div>
  )
}
