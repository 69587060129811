import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import React from 'react'
import { badgeWizardSchema } from '@/views/Gamification/Badges/BadgeWizard/badgeWizardSchema'

const createFormFields = [
  { name: 'name', type: 'text', label: 'Badge Name', required: true },
  { name: 'description', type: 'text', label: 'Badge Description', required: true },
  { name: 'imageUrl', type: 'text', label: 'Image URL', required: true },
]

export const BadgeWizardCreationForm = ({ onSave, onClose, loading }) => {
  const handleSave = (formValues) => {
    onSave(formValues)
  }

  return (
    <div className="badge-wizard__creation-form">
      <AbstractForm
        validationSchema={badgeWizardSchema}
        onSubmit={handleSave}
        onClose={onClose}
        schema={createFormFields}
        buttonLabel="Submit Changes"
        closeButtonLabel="Cancel"
        buttonProps={{ loading }}
      />
    </div>
  )
}
