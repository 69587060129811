import React from 'react'
import { formatTime } from '@/utils/helpers'

export const ActivityLogHeader = (props) => {
  const { activitySinceTime } = props
  const formattedActivityTime = activitySinceTime ? formatTime(activitySinceTime) : ''

  return (
    <div>
      <header className="alert-activity-log-header">
        <h1 data-testid="activityLogHeader">Activity Log</h1>
        <span data-testid="activityLogTime">
          <strong>Activity Since:</strong> {formattedActivityTime}
        </span>
      </header>
    </div>
  )
}
