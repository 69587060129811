import React from 'react'

import './dashboardPage.css'
import ReportsDashboardPage from './ReportsDashboardPage'
import { SecondaryNavRouter } from '../../../components/layout/SecondaryNavRouter/SecondaryNavRouter'
import LiveDashboardPage from './LiveDashboardPage'

export const DashboardPage = ({ tab }) => {
  const pages = [
    {
      label: 'Overview',
      component: <ReportsDashboardPage />,
      url: '/scorecards/dashboard/overview',
    },
    {
      label: 'Live Feed',
      component: <LiveDashboardPage />,
      url: '/scorecards/dashboard/live',
    },
  ]

  return (
    <>
      <h1>Dashboard</h1>

      <SecondaryNavRouter pages={pages} activePage={tab} />
    </>
  )
}

export default DashboardPage
