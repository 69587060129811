import React from 'react'
import { Form } from 'semantic-ui-react'

import { scoredOptions } from '@/utils/constants'
import Select from '@/components/forms/Select'

export const PartialScoresFilter = ({ filters, ...props }) => {
  const label = 'QA Score Status'
  const accessor = 'scoredStatus'
  const placeholder = 'Scored'

  return (
    <Form.Field data-testid={`${accessor}-dropdown`}>
      <label>{label}</label>
      <Select
        placeholder={placeholder}
        options={scoredOptions}
        fixedWidth
        value={filters[accessor]}
        {...props}
      />
    </Form.Field>
  )
}
