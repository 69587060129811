import React, { useEffect, useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, useLocation } from 'react-router-dom'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { useDispatch, useSelector } from 'react-redux'

import { applyDefaultSavedFilters, fetchAllData } from '@/reducers/analytics/analytics.actions'

import { Redirect } from 'react-router'
import { AnalyticsFilters } from './AnalyticsFilters'
import { OverviewSection } from './sections/OverviewSection'
import { DynamicPromptSection } from './sections/DynamicPromptSection'
import { ChecklistSection } from './sections/ChecklistSection'
import { NotificationsSection } from './sections/NotificationsSection'
import { PostCallSection } from './sections/PostCallSection'
import { UsageSection } from './sections/UsageSection'
import { ExportCSVSection } from './sections/ExportCSVSection'
import { ManageFilters } from './components/ManageFilters'

import './AnalyticsPage.scss'

const sectionData = [
  {
    route: 'checklist',
    title: 'Checklist',
    component: ChecklistSection,
  },
  {
    route: 'dynamic-prompt',
    title: 'Dynamic Prompt',
    component: DynamicPromptSection,
  },
  {
    route: 'notifications',
    title: 'Notifications',
    component: NotificationsSection,
  },
  {
    route: 'postcall',
    title: 'Post Call',
    component: PostCallSection,
  },
  {
    route: 'usage',
    title: 'Usage',
    component: UsageSection,
  },
  {
    route: 'csv',
    title: 'Export CSV',
    component: ExportCSVSection,
  },
]

function getPageTitle(section) {
  if (section) {
    return sectionData.find((data) => data.route === section).title
  }

  return 'Overview'
}

function renderSection(section) {
  if (section) {
    const Component = sectionData.find((data) => data.route === section).component

    return <Component />
  }

  return <OverviewSection />
}

const AnalyticsPage = () => {
  const dispatch = useDispatch()
  const { section } = useParams()
  const location = useLocation()
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const { customBranding } = useSelector((state) => state.customBranding)
  const {
    data: { savedFilters },
  } = useSelector((state) => state.analytics)
  const pageTitle = customBranding?.customBrandingDisplayName || 'Balto Cloud'
  const isBaltoAdmin = organizationId === 1

  const defaultFilter = savedFilters.find((filter) => filter.is_default)
  const defaultFilterId = defaultFilter ? defaultFilter.uuid : null
  const shouldFetchDefaultFilters = Boolean(defaultFilterId)
  const originalSection = section === 'dynamic-prompt' ? 'deck' : section

  useEffect(() => {
    if (shouldFetchDefaultFilters) {
      dispatch(applyDefaultSavedFilters(originalSection))

      // This already happens for non-admin users by default
      if (isBaltoAdmin) {
        dispatch(fetchAllData(organizationId))
      }
    }
  }, [shouldFetchDefaultFilters])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  // reroute deck section to new dynamic prompt url
  if (section === 'deck') {
    return <Redirect to="/reports/dynamic-prompt" />
  }

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} | Reporting: ${getPageTitle(section)}`}</title>
      </Helmet>

      <div className="header-wrapper">
        <h1 data-testid="analytics-page">{getPageTitle(section)}</h1>
        <ManageFilters />
      </div>
      <AnalyticsFilters />

      {renderSection(section)}
    </>
  )
}

export default withLDConsumer()(AnalyticsPage)
