import React from 'react'
import { Form } from 'semantic-ui-react'
import Select from '@/components/forms/Select'

const CategoryForm = ({
  category,
  loading,
  formValue,
  tagCategories,
  formPlaceholder,
  handleChange,
  createAction,
  handleDropdownChange = null,
  displayDropdown = false,
}) => {
  return (
    <Form>
      <Form.Field>
        <div className="tag-category__form-fields">
          <input
            className="form-container"
            type="text"
            onChange={handleChange}
            name="tagGroup"
            value={formValue}
            placeholder={formPlaceholder}
          />
          {displayDropdown && (
            <div data-testid="add_dropdown">
              <Select
                className="dropdown-container"
                placeholder="Categories"
                options={tagCategories}
                loading={false}
                value={category}
                onChange={(option) => handleDropdownChange(option)}
                fixedWidth
              />
            </div>
          )}
          <button
            className="ui button primary button-container "
            type="button"
            data-testid="add_button"
            onClick={createAction}
            disabled={!formValue || loading}
          >
            Add
          </button>
        </div>
      </Form.Field>
    </Form>
  )
}

export default CategoryForm
