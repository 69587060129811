// Action Types
export const SET_ORGANIZATION_TAG_CATEGORIES = 'organizations/setOrganizationTagCategories'

// Action Creators
export const setOrganizationTagCategories = (payload) => ({
  type: SET_ORGANIZATION_TAG_CATEGORIES,
  payload,
})

// State
export const initialState = []

// Reducer
export default function organizationTagCategoriesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORGANIZATION_TAG_CATEGORIES:
      return action.payload
    default:
      return state
  }
}
