import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

const IndexRedirectComponent = () => {
  const history = useHistory()
  const { organizationid } = useSelector((state) => state.currentUser)

  useEffect(() => {
    if (organizationid === 1) {
      history.push('/organizations')
    } else if (organizationid === null) {
      history.push('/login')
    } else {
      history.push('/command-center')
    }
  })

  return null
}

export default withLDConsumer()(IndexRedirectComponent)
