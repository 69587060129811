// Taken from https://github.com/samirkumardas/pcm-player

export function PCMPlayer(option) {
  this.init(option)
}

function init(option) {
  const defaults = {
    encoding: '16bitInt',
    channels: 1,
    sampleRate: 8000,
    flushingTime: 1000,
  }
  this.option = { ...defaults, ...option }
  this.samples = new Float32Array()
  this.flush = this.flush.bind(this)
  this.interval = setInterval(this.flush, this.option.flushingTime)
  this.maxValue = this.getMaxValue()
  this.typedArray = this.getTypedArray()
  this.createContext()
}

function getMaxValue() {
  const encodings = {
    '8bitInt': 128,
    '8bitUint': 255,
    '16bitInt': 32768,
    '16bitUint': 65535,
    '32bitInt': 2147483648,
    '32bitFloat': 1,
  }

  return encodings[this.option.encoding] ? encodings[this.option.encoding] : encodings['16bitInt']
}

function getTypedArray() {
  const typedArrays = {
    '8bitInt': Int8Array,
    '8bitUint': Uint8Array,
    '16bitUint': Uint16Array,
    '16bitInt': Int16Array,
    '32bitInt': Int32Array,
    '32bitFloat': Float32Array,
  }

  return typedArrays[this.option.encoding]
    ? typedArrays[this.option.encoding]
    : typedArrays['16bitInt']
}

function createContext() {
  this.audioCtx = new (window.AudioContext || window.webkitAudioContext)()
  this.gainNode = this.audioCtx.createGain()
  this.gainNode.gain.value = 1
  this.gainNode.connect(this.audioCtx.destination)
  this.startTime = this.audioCtx.currentTime
}

function isTypedArray(data) {
  return data.byteLength && data.buffer && data.buffer.constructor === ArrayBuffer
}

function feed(data) {
  if (!this.isTypedArray(data)) return

  const formattedData = this.getFormatedValue(data)
  const tmp = new Float32Array(this.samples.length + formattedData.length)
  tmp.set(this.samples, 0)
  tmp.set(formattedData, this.samples.length)
  this.samples = tmp
}

function getFormatedValue(data) {
  // eslint-disable-next-line new-cap
  const formattedData = new this.typedArray(data.buffer)
  const float32 = new Float32Array(formattedData.length)

  for (let i = 0; i < formattedData.length; i++) {
    float32[i] = formattedData[i] / this.maxValue
  }

  return float32
}

function volume(volume) {
  this.gainNode.gain.value = volume
}

function destroy() {
  if (this.interval) {
    clearInterval(this.interval)
  }

  this.samples = null
  this.audioCtx.close()
  this.audioCtx = null
}

function flush() {
  if (!this.samples.length) return

  const bufferSource = this.audioCtx.createBufferSource()
  const length = this.samples.length / this.option.channels
  const audioBuffer = this.audioCtx.createBuffer(
    this.option.channels,
    length,
    this.option.sampleRate
  )

  let audioData
  let channel
  let offset
  let i
  let decrement

  for (channel = 0; channel < this.option.channels; channel++) {
    audioData = audioBuffer.getChannelData(channel)
    offset = channel
    decrement = 50

    for (i = 0; i < length; i++) {
      audioData[i] = this.samples[offset]
      /* fadein */
      if (i < 50) {
        audioData[i] = (audioData[i] * i) / 50
      }
      /* fadeout */
      if (i >= length - 51) {
        audioData[i] = (audioData[i] * decrement--) / 50
      }
      offset += this.option.channels
    }
  }

  if (this.startTime < this.audioCtx.currentTime) {
    this.startTime = this.audioCtx.currentTime
  }

  bufferSource.buffer = audioBuffer
  bufferSource.connect(this.gainNode)
  bufferSource.start(this.startTime)

  this.startTime += audioBuffer.duration
  this.samples = new Float32Array()
}

PCMPlayer.prototype.init = init
PCMPlayer.prototype.getTypedArray = getTypedArray
PCMPlayer.prototype.getMaxValue = getMaxValue
PCMPlayer.prototype.feed = feed
PCMPlayer.prototype.getFormatedValue = getFormatedValue
PCMPlayer.prototype.createContext = createContext
PCMPlayer.prototype.isTypedArray = isTypedArray
PCMPlayer.prototype.volume = volume
PCMPlayer.prototype.destroy = destroy
PCMPlayer.prototype.flush = flush
