import React, { useState } from 'react'

import { Accordion, Card, Icon, Table } from 'semantic-ui-react'
import {
  tablesByPlaybookSection,
  UnknownSectionCriteriaTable,
} from '../MeasureScoresTable/MeasureScoresTable'
import { sortCriteriaByPlaybookSections } from '../helpers/index'

import './scorecardForms.css'

function createCriteriaCell(criteria) {
  return <Table.Cell data-testid="criteria-name-cell">{criteria.name}</Table.Cell>
}

const getCriteriaItemsInSection = (criteria_not_in_playbook, section) => {
  const criteriaItemsInSection = []
  criteria_not_in_playbook.forEach((criteria) => {
    if (parseInt(criteria.scorecard_section_id, 10) === parseInt(section.id, 10)) {
      criteriaItemsInSection.push(criteria)
    }
  })
  return criteriaItemsInSection
}

const getCriteriaItemsInMeasure = (criteria_not_in_playbook, measure) => {
  const criteriaItemsInMeasure = []
  criteria_not_in_playbook.forEach((criteria) => {
    if (parseInt(criteria.scorecard_measure_id, 10) === parseInt(measure.id, 10)) {
      criteriaItemsInMeasure.push(criteria)
    }
  })

  return criteriaItemsInMeasure
}

export const CreateMeasureTable = ({ measure, playbook, missingCriteria }) => {
  const missingCriteriaRefIds = missingCriteria.map((a) => a.playbook_ref_id)
  const criteriaByPlaybookSection = sortCriteriaByPlaybookSections(measure, playbook.body)
  const criteriaWithMissingRefs = criteriaByPlaybookSection.map((section) => ({
    ...section,
    criteria: section.criteria.filter((criteria) =>
      missingCriteriaRefIds.includes(criteria.playbook_ref_id)
    ),
  }))

  criteriaWithMissingRefs.map((playbookSection) => tablesByPlaybookSection(playbookSection))

  return (
    <Card
      className="criteria-scores-summary__popup-card"
      data-testid="criteria-scores-summary__popup-card"
    >
      <Card.Content className="update-playbook-form__measure-table-header">
        <h5>{measure.name}</h5>
      </Card.Content>
      <Card.Content>
        <UnknownSectionCriteriaTable
          missingCriteria={missingCriteria}
          cellFunction={createCriteriaCell}
        />
      </Card.Content>
    </Card>
  )
}

export const CreateSectionTable = ({ scorecard, playbook }) => {
  const [expanded, setExpanded] = useState(false)
  const collapse = () => setExpanded(!expanded)
  const measureTables = scorecard.sections
    .filter((section) => {
      const criteriaItemsInSection = getCriteriaItemsInSection(
        scorecard.criteria_not_in_playbook,
        section
      )

      return criteriaItemsInSection.length
    })
    .map((section) => {
      const criteriaItemsInSection = getCriteriaItemsInSection(
        scorecard.criteria_not_in_playbook,
        section
      )
      return (
        <div
          className="measure-scores-table-container"
          data-testid="measure-scores-table-container"
        >
          <h4>{section.name}</h4>
          {section.measures
            .filter((measure) => {
              const criteriaItemsInMeasure = getCriteriaItemsInMeasure(
                criteriaItemsInSection,
                measure
              )
              return criteriaItemsInMeasure.length
            })
            .map((measure) => {
              const criteriaItemsInMeasure = getCriteriaItemsInMeasure(
                criteriaItemsInSection,
                measure
              )
              return (
                <CreateMeasureTable
                  measure={measure}
                  playbook={playbook}
                  missingCriteria={criteriaItemsInMeasure}
                  key={measure.id}
                />
              )
            })}
        </div>
      )
    })

  if (!measureTables.length) return null

  return (
    <Accordion className="update-playbook-form__accordion">
      <Accordion.Title active={expanded} onClick={collapse}>
        <Icon name="dropdown" />
        {scorecard.name}
      </Accordion.Title>
      <Accordion.Content active={expanded}>{measureTables}</Accordion.Content>
    </Accordion>
  )
}

export const UpdatePlaybookForm = ({ scorecards, playbook }) => {
  const scorecardAccordions = scorecards.map((scorecard) => (
    <CreateSectionTable scorecard={scorecard} playbook={playbook} key={scorecard.id} />
  ))

  return (
    <div>
      <h3>Changes</h3>
      <p>
        Use the dropdown next to each scorecard to view the measures and criteria that will be
        affected once published.
      </p>

      {scorecardAccordions}
    </div>
  )
}
