import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'

import { Pill } from '@/components/pills/Pill'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { ExternalLink } from '@/components/ExternalLink'
import { getAllDisputes } from '@/reducers/scorecards/scorecards.actions'
import {
  formatDuration,
  CALL_EXPLORER_TAB_ENUMS,
  CALL_EXPLORER_CRITERIA_FILTER_ENUMS,
} from '@/views/Calls/components/helpers'
import { DISPUTES_TABLE_NAME } from '@/reducers/customColumns/customColumns.constants'
import { getMemoizedColumns } from '@/reducers/customColumns/customColumns.helpers'

import { CustomColumns } from '@/components/tables/CustomColumns/CustomColumns'

export const DisputesTable = () => {
  const dispatch = useDispatch()
  const { data, loading } = useSelector((state) => state.scorecards)
  const { customColumns } = useSelector((state) => state.customColumns)
  const { disputes } = data
  const savedCustomColumns = customColumns[DISPUTES_TABLE_NAME]

  useEffect(() => {
    dispatch(getAllDisputes())
  }, [])

  const DEFAULT_COLUMNS = [
    {
      accessor: 'agent_name',
      label: 'Agent',
    },
    {
      accessor: 'created_at',
      label: 'Date',
    },
    {
      accessor: 'call_id',
      label: 'Call ID',
      is_hidden: true,
    },
    {
      accessor: 'voip_call_id',
      label: 'VOIP Call ID',
      is_hidden: true,
    },
    {
      accessor: 'call_duration',
      label: 'Call Duration',
      format: (duration) => formatDuration(Math.floor(duration)),
      is_hidden: true,
    },
    {
      accessor: 'scorecard_config_name',
      label: 'Scorecard',
    },
    {
      accessor: 'criteria_name',
      label: 'Criteria',
    },
    {
      accessor: 'status',
      label: 'Status',
    },
    {
      accessor: 'link_call_id',
      label: 'Action',
    },
  ]

  const rows = disputes.map((dispute) => ({
    ...dispute,
    index: dispute.id,
    scorecard_config_name: {
      as: (
        <Link
          to={
            dispute.scorecard_config_type === 'copilot'
              ? `/qa-copilot/${dispute.scorecard_config_id}`
              : `/scorecards/edit_config/${dispute.scorecard_config_id}`
          }
          className="table-link"
        >
          {' '}
          {dispute.scorecard_config_name}
        </Link>
      ),
      value: dispute.scorecard_config_name,
    },
    status: {
      as: (
        <Pill warning={dispute.status === 'Needs Review'}>
          <span>{dispute.status}</span>
        </Pill>
      ),
    },
    link_call_id: {
      as: (
        <ExternalLink
          url={`/call-explorer/${dispute.call_id}?tab=${CALL_EXPLORER_TAB_ENUMS.QA_SCORES}&scorecardId=${dispute.scorecard_id}&scoreFilter=${CALL_EXPLORER_CRITERIA_FILTER_ENUMS.DISPUTES}&criteriaScoreId=${dispute.scorecard_criteria_scores_id}`}
          label="Review Dispute"
          id={dispute.call_id}
          className="pill pill--grey"
        />
      ),
    },
  }))

  const columns = useMemo(
    () => getMemoizedColumns(savedCustomColumns, DEFAULT_COLUMNS),
    [savedCustomColumns, disputes]
  )

  return (
    <>
      <header className="page-header">
        <h1>Agent Disputes</h1>

        <CustomColumns tableName={DISPUTES_TABLE_NAME} defaultColumns={DEFAULT_COLUMNS} />
      </header>
      <Segment className="not-padded" data-testid="disputes-table">
        <AdvancedTable
          loading={loading.disputes}
          index="index"
          rows={rows}
          columns={columns}
          defaultOrderBy="date"
          defaultOrder="desc"
          pagination
          stickyAction
          striped={false}
        />
      </Segment>
    </>
  )
}

export default DisputesTable
