import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popup, Button, Segment, Form } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import {
  IconPencil,
  IconStar,
  IconStarFilled,
  IconTrash,
  IconFilter,
  IconReplace,
} from '@tabler/icons-react'
import { isEmpty, startCase, toString } from 'lodash'

import { setPlaylists, setPlaylistsChanges } from '@/reducers/playlists/playlists.redux'
import {
  deletePlaylist,
  fetchPlaylists,
  patchPlaylist,
} from '@/reducers/playlists/playlists.actions'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { DeleteForm } from '@/components/forms/DeleteForm'
import { OrganizationFilter } from '@/components/filters/OrganizationFilter'
import { getOrganizationOptions, formatTime } from '@/utils/helpers'
import { SearchableDropdown } from '@/components/dropdowns/SearchableDropdown'
import { Pill } from '@/components/pills/Pill'
import { auditColumns } from '@/utils/constants'

import { CreatePlaylistButtonAndModal } from './components/CreatePlaylistButtonAndModal'

const PlaylistsPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { playlists, playlistsChanges, loading } = useSelector((state) => state.playlists)
  const organizations = useSelector((state) => state.organizations)
  const currentOrganization = useSelector((state) => state.currentOrganization)
  const [type, setType] = useState('all')
  const { organizationid: currentUserOrgId, user_id: currentUserId } = useSelector(
    (state) => state.currentUser
  )
  const [searchedOrgId, setSearchedOrgId] = useState(currentUserOrgId)
  const isBaltoAdmin = currentUserOrgId === 1

  const handleDeletePlaylist = (playlistUuid) => {
    dispatch(deletePlaylist(playlistUuid, searchedOrgId))
  }

  const handleOrgSelect = (option, action) => {
    if (action.action === 'clear') {
      setSearchedOrgId(null)
      dispatch(setPlaylists([]))
      dispatch(setPlaylistsChanges([]))
    } else {
      setSearchedOrgId(option.value)
      dispatch(fetchPlaylists(option.value))
    }
  }

  const handleToggleFavorite = async (row) => {
    await dispatch(
      patchPlaylist(row.uuid, row.organization_id, { is_favorite: !row.is_favorite }, searchedOrgId)
    )
    await dispatch(fetchPlaylists(searchedOrgId))
  }

  const columns = [
    {
      accessor: 'is_favorite',
      label: '',
      isSortable: false,
    },
    {
      accessor: 'name',
      label: 'Name',
      isSearchable: true,
      clickableRowLink: {
        prefix: 'playlists',
        accessor: 'playlist_uuid',
        suffix: `?organization_id=${searchedOrgId}`,
      },
    },
    {
      accessor: 'author',
      label: 'Author',
      isSearchable: true,
    },
    {
      accessor: 'call_count',
      label: 'Call Count',
      isSearchable: true,
      format: (value) => toString(value),
      headerAlignment: 'right',
    },
    {
      accessor: 'created_at',
      label: 'Date Created',
      format: (value) => formatTime(value),
    },
    {
      accessor: 'updated_at',
      label: 'Last Modified',
      format: (value) => formatTime(value),
    },
  ]

  const actions = [
    {
      label: 'Edit',
      icon: <IconPencil />,
      fn: (event, row) => {
        history.push(`/playlists/${row.playlist_uuid}?organization_id=${searchedOrgId}`)
      },
    },
    {
      label: 'Delete',
      trigger: (row) => {
        return (
          <ButtonAndFormModal
            popup
            icon={<IconTrash />}
            buttonProps={{ basic: true, compact: true }}
            popupProps={{ position: 'top right' }}
            modalId={`playlists/delete/${row.playlist_uuid}`}
            modalTitle={`Delete "${row.name}"`}
            modalProps={{ size: 'tiny' }}
            form={
              <DeleteForm
                content="Are you sure you want to delete this playlist? This action cannot be undone."
                handleSubmit={() => handleDeletePlaylist(row.playlist_uuid)}
              />
            }
          />
        )
      },
    },
  ]

  const playlistsChangesRows = playlistsChanges?.map((change) => ({
    ...change,
    created_at: formatTime(change.created_at),
    what_changed: change.name,
    event_type: {
      value: change.event_type,
      as: (
        <Pill
          small
          critical={change.event_type === 'delete_playlist'}
          success={change.event_type === 'create_playlist'}
        >
          {startCase(change.event_type.replace(/_/g, ' '))}
        </Pill>
      ),
    },
  }))

  const rows = playlists
    .filter((playlist) => {
      if (type === 'favorites') {
        return playlist.is_favorite
      }

      if (type === 'my') {
        return playlist.user_id === currentUserId
      }

      return true
    })
    .map((playlist) => ({
      ...playlist,
      playlist_uuid: playlist.uuid,
      is_favorite: {
        cellProps: {
          className: 'collapsing compact-cell',
        },
        value: playlist.is_favorite,
        as: (
          <Popup
            inverted
            content={playlist.is_favorite ? 'Remove from favorites' : 'Add to favorites'}
            trigger={
              <Button
                icon
                compact
                basic
                className="svg-button"
                onClick={() => handleToggleFavorite(playlist)}
              >
                {playlist.is_favorite ? (
                  <IconStarFilled className="status-warning" />
                ) : (
                  <IconStar />
                )}
              </Button>
            }
          />
        ),
      },
    }))

  useEffect(() => {
    dispatch(fetchPlaylists(currentUserOrgId))
  }, [])

  return (
    <>
      <header className="page-header" data-testid="playlists-page">
        <h1>Playlists</h1>
        <CreatePlaylistButtonAndModal
          organization={
            isBaltoAdmin
              ? organizations.find((org) => org.id === searchedOrgId)
              : currentOrganization
          }
        />
      </header>

      <>
        {isBaltoAdmin && (
          <Form className="filter-form">
            <OrganizationFilter
              options={getOrganizationOptions(organizations)}
              value={searchedOrgId}
              onChange={handleOrgSelect}
            />
          </Form>
        )}
        <div className="flex-space-between">
          <SearchableDropdown
            hideSearch
            placeholder="Filter Playlists"
            options={[
              { value: 'favorites', label: 'Favorites' },
              { value: 'my', label: 'Created by me' },
              { value: 'all', label: 'Created by anyone' },
            ]}
            selected={type}
            handleSelect={(value) => setType(value)}
            buttonProps={{ className: 'secondary' }}
            icon={<IconFilter />}
          />
          {!isEmpty(playlists) && !isEmpty(playlistsChangesRows) && (
            <ButtonAndFormModal
              buttonLabel="Change History"
              icon={<IconReplace />}
              buttonProps={{ secondary: true }}
              modalId="playlists/audit"
              modalProps={{ size: 'large' }}
              modalTitle="Change History"
              modalContent={
                <Segment className="not-padded">
                  <AdvancedTable
                    loading={loading.playlists}
                    rows={playlistsChangesRows}
                    columns={auditColumns}
                    pagination
                    defaultOrder="desc"
                    sortEnabled={false}
                  />
                </Segment>
              }
            />
          )}
        </div>
        <Segment className="not-padded">
          <AdvancedTable
            index="playlist_uuid" // note: "uuid" does not access the accessor
            loading={loading.playlists}
            rows={rows}
            columns={columns}
            actions={actions}
            pagination
            stickyAction
            defaultOrderBy="created_at"
            defaultOrder="desc"
            pinned="is_favorite"
          />
        </Segment>
      </>
    </>
  )
}

export default PlaylistsPage
