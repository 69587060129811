import React from 'react'
import { isEmpty } from 'lodash'
import { Tab, Loader } from 'semantic-ui-react'
import uuid from 'uuid/v4'

import { AgentRow } from '@/components/cards/AgentRow'

export const SentimentList = ({ loading, sentiment }) => {
  const paneTypes = ['bottom_calls', 'top_calls']
  const descriptionLoading = (type) => {
    return (
      (type === 'bottom_calls' && loading.sentimentUpsetReasons) ||
      (type === 'top_calls' && loading.sentimentPositiveReasons)
    )
  }

  return (
    <div data-testid="sentiment-list-container">
      {loading.sentiment ? (
        <div className="text-center">
          <Loader inline active />
        </div>
      ) : (
        !isEmpty(sentiment) && (
          <Tab
            menu={{ secondary: true, pointing: true, attached: false, className: 'tab-navigation' }}
            panes={paneTypes.map((type) => ({
              menuItem: type === 'bottom_calls' ? 'Negative Calls' : 'Positive Calls',
              render: () => {
                return (
                  <Tab.Pane as="div" key={sentiment[type]}>
                    {!isEmpty(sentiment[type]) ? (
                      sentiment[type].map((data, index) => {
                        const agentFullName = `${data.agent_first_name} ${data.agent_last_name}`

                        return (
                          <AgentRow
                            key={uuid()}
                            agentFullName={agentFullName}
                            description={
                              type === 'bottom_calls' ? data.upset_reason : data.positive_reason
                            }
                            descriptionLoading={descriptionLoading(type)}
                            index={index}
                            externalLink={{
                              label: 'Explore Call',
                              url: `/call-explorer/${data.call_id}`,
                              id: data.call_id,
                            }}
                          />
                        )
                      })
                    ) : (
                      <div className="no-data">No call data available</div>
                    )}
                  </Tab.Pane>
                )
              },
            }))}
            className="tab-container"
          />
        )
      )}
    </div>
  )
}
