import { Image } from 'semantic-ui-react'
import React from 'react'

export const BadgeNameAndImage = ({ template, badgeOrTemplate = 'template' }) => {
  const badgeOrTemplateValue =
    badgeOrTemplate === 'template' ? template.templateName : template.name
  return (
    <div className="badge-image-name-container" data-testid="badge-image-name-container">
      <Image
        className="badge-image"
        data-testid="badge-image"
        src={template.imageUrl}
        circular
        size="mini"
      />
      <div className="badge-name" data-testid={`${badgeOrTemplate}-name`}>
        {badgeOrTemplateValue}
      </div>
    </div>
  )
}
