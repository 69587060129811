import React from 'react'
import { Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  createPlaybookVersion,
  publishPlaybookVersion,
  updateScorecardPlaybookVersion,
} from '@/reducers/playbooks/playbook.actions'
import { openModal, closeModal } from '@/reducers/ui/ui.redux'

import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import { FormModal } from '@/components/layout/modals/FormModal'
import { UpdatePlaybookForm } from '@/views/Scorecards/forms/UpdatePlaybookForm'

import {
  getPlaybookButtonDisabledStatus,
  getPlaybookButtonText,
  checkPlaybookErrors,
} from '../playbook.helpers'

export const PlaybookSubmitButton = ({ numberOfBreakingScorecards }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { section } = useParams()
  const {
    loading,
    playbook,
    playbook: { id: playbookId, active, current: isLatestPlaybookVersion, body },
    dirty,
  } = useSelector((state) => state.playbook)
  const { scorecardAssociations } = useSelector((state) => state.scorecards.data)
  const { currentlyOpenModalId } = useSelector((state) => state.ui)

  const readyToRestore = !isLatestPlaybookVersion
  const readyToSaveDraft = dirty
  const readyToPublish = !dirty && !active
  const saveIsDisabled = getPlaybookButtonDisabledStatus(
    readyToRestore,
    readyToSaveDraft,
    readyToPublish,
    loading.savePlaybook
  )
  const playbookError = checkPlaybookErrors(body)
  const playbookButtonText = getPlaybookButtonText(readyToRestore, readyToPublish)

  const handlePublish = () => {
    const success = dispatch(publishPlaybookVersion(playbookId))

    if (success) {
      scorecardAssociations?.forEach((scorecard) => {
        dispatch(updateScorecardPlaybookVersion(scorecard.id, scorecard.organization_id))
      })
    }
  }

  const handleClickSubmit = () => {
    if (readyToRestore) {
      return dispatch(createPlaybookVersion(playbook, history, section, readyToRestore))
    }

    if (readyToPublish) {
      const shouldTriggerConfirmModal =
        scorecardAssociations?.length > 0 && numberOfBreakingScorecards > 0
      if (shouldTriggerConfirmModal) {
        return dispatch(openModal('confirmPlaybookActivation'))
      }
      return handlePublish()
    }

    return dispatch(createPlaybookVersion(playbook, history, section, readyToRestore))
  }

  const handleModalSave = () => {
    handlePublish()
    dispatch(closeModal())
  }

  return (
    <>
      {currentlyOpenModalId === 'confirmPlaybookActivation' && (
        <FormModal
          title="Publish Draft"
          onSave={handleModalSave}
          size="small"
          onClose={() => {
            dispatch(closeModal())
          }}
          closeButtonLabel="Cancel"
          submitButtonLabel="Publish Anyway"
          show
          isLoading={false}
          className="update-playbook-form__container"
        >
          <UpdatePlaybookForm scorecards={scorecardAssociations} playbook={playbook} />
        </FormModal>
      )}
      <ConditionalTooltip condition={Boolean(playbookError)} content={playbookError}>
        <Button
          type="button"
          className="no-wrap"
          data-testid="playbook-submit-button"
          positive={readyToPublish}
          primary={readyToSaveDraft || readyToRestore}
          secondary={!readyToSaveDraft && !readyToRestore && !readyToPublish}
          onClick={handleClickSubmit}
          disabled={saveIsDisabled || Boolean(playbookError)}
        >
          {playbookButtonText}
        </Button>
      </ConditionalTooltip>
    </>
  )
}
