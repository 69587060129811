import { TooltipWrapper } from '@/components/charts/customCharts/LayeredTooltip/TooltipWrapper'
import React from 'react'
import { LinePointTooltip } from '@/components/charts/customCharts/LayeredTooltip/LinePointTooltip'

const tooltipVariants = {
  line: ({ ...props }) => <LinePointTooltip {...props} />,
}

export const Tooltip = ({
  point = null,
  description = '',
  position = null,
  variant = null,
  isVisible = false,
}) => {
  if (!isVisible) {
    return null
  }

  const tooltip = tooltipVariants[variant] && tooltipVariants[variant]({ point, description })

  return <TooltipWrapper position={position}>{tooltip}</TooltipWrapper>
}
