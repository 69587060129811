import React from 'react'
import { IconAlertTriangle } from '@tabler/icons-react'

import { Banner } from '@/components/banners/Banner'

export const ChallengeCannotBeActivatedWarning = () => (
  <Banner
    data-testid="challenge-cannot-be-activated"
    warning
    header="Continue configuration"
    icon={<IconAlertTriangle />}
  >
    This challenge cannot be activated until it has a date range and participants.
  </Banner>
)
