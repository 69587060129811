import { setData, setLoading } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { apiService, fetchingAPI } from '@/api'
import { getTagsWithCategories } from '@/utils/helpers'
import queryString from 'query-string'
import { groupAlertConfigs } from '@/reducers/realtime/helpers'
import { toast } from 'react-toastify'
import { loadPlaybookNamesAndCids } from '../realtime.actions'

export const fetchManagersByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ managers: true }))

  try {
    const { managers } = await fetchingAPI(
      `${apiService.web}/api/realtime_coaching/${organizationId}/managers`,
      'GET',
      dispatch
    )
    const managerOptions = managers
      .map((manager) => ({
        key: manager.id,
        value: manager.id,
        label: `${manager.first_name} ${manager.last_name}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    dispatch(setData({ managers: managerOptions }))
  } catch (err) {
    toast.error('Failed to fetch managers for the organization')
  } finally {
    dispatch(setLoading({ managers: false }))
  }
}

export const fetchAgentsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ agents: true }))

  try {
    const { users } = await fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/users`,
      'GET',
      dispatch
    )
    const agentOptions = users
      .map((agent) => ({
        key: agent.id,
        value: agent.id,
        label: `${agent.first_name} ${agent.last_name}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    dispatch(setData({ agents: agentOptions }))
  } catch (err) {
    toast.error('Failed to fetch agents for the organization')
  } finally {
    dispatch(setLoading({ agents: false }))
  }
}

export const fetchTagsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ tags: true }))

  try {
    const [tags, tagCategories] = await Promise.all([
      fetchingAPI(`${apiService.web}/api/organizations/${organizationId}/tags`, 'GET', dispatch),
      fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags/categories`,
        'GET',
        dispatch
      ),
    ])

    const tagsOptionsWithCategories = getTagsWithCategories(tags, tagCategories)

    dispatch(setData({ tags: tagsOptionsWithCategories }))
  } catch (err) {
    toast.error('Failed to fetch tags')
  } finally {
    dispatch(setLoading({ tags: false }))
  }
}

export const fetchAlertNames =
  (organizationId = null) =>
  async (dispatch) => {
    dispatch(setLoading({ alertNames: true }))

    try {
      const alertConfigs = await fetchingAPI(
        `${apiService.web}/api/alert_configs?entire_org=True${
          organizationId ? `&requesting_org=${organizationId}` : ''
        }`,
        'GET',
        dispatch
      )
      const alertConfigsGroupedArray = groupAlertConfigs(alertConfigs)
      dispatch(setData({ alertNames: alertConfigsGroupedArray }))
    } catch (err) {
      toast.error('Failed to fetch configs')
    } finally {
      dispatch(setLoading({ alertNames: false }))
    }
  }

export const fetchAdvancedExportsPlaybooks =
  (organizationId, alertIds = null) =>
  async (dispatch) => {
    dispatch(setLoading({ playbooksAdvancedExports: true }))

    try {
      const configProperties = queryString.stringify(
        {
          requested_properties: 'id,name,cid',
          alert_ids: alertIds,
        },
        {
          skipNull: true,
        }
      )
      const playbooks = await fetchingAPI(
        `${apiService.web}/api/${organizationId}/configs?${configProperties}`,
        'GET',
        dispatch
      )
      const playbookOptions = playbooks
        .map((playbook) => ({ value: playbook.cid, label: playbook.name }))
        .sort((a, b) => a.label.localeCompare(b.label))

      dispatch(setData({ playbooksAdvancedExports: playbookOptions }))
    } catch (err) {
      toast.error('Failed to fetch playbooks')
    } finally {
      dispatch(setLoading({ playbooksAdvancedExports: false }))
    }
  }

export const fetchUserAlertNames =
  ({ managerId = null } = {}) =>
  async (dispatch) => {
    dispatch(setLoading({ userAlertNames: true }))

    const queryParams = queryString.stringify({
      user_id: managerId,
    })

    try {
      const userAlertNames = await fetchingAPI(
        `${apiService.web}/api/alert_configs?${queryParams}`,
        'GET',
        dispatch
      )

      const userAlertNameOptions = userAlertNames
        .map(({ id, name }) => ({
          key: id,
          label: name,
          value: id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

      dispatch(setData({ userAlertNames: userAlertNameOptions }))
    } catch (err) {
      toast.error('Failed to fetch configs')
    } finally {
      dispatch(setLoading({ userAlertNames: false }))
    }
  }

export const fetchTriggersByAlerts = (organizationId, acvIds) => async (dispatch) => {
  dispatch(setLoading({ triggers: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.web}/api/${organizationId}/triggers_by_alert_config_version_ids`,
      'POST',
      dispatch,
      JSON.stringify({ acvIds })
    )

    const triggers = Object.keys(response).map((key) => ({ label: key, options: response[key] }))
    dispatch(setData({ triggers }))
  } catch (err) {
    toast.error('Failed to fetch triggers')
  } finally {
    dispatch(setLoading({ triggers: false }))
  }
}

export const fetchAllRtcData = (organizationId) => (dispatch) => {
  dispatch(fetchAgentsByOrg(organizationId))
  dispatch(fetchTagsByOrg(organizationId))
  dispatch(fetchManagersByOrg(organizationId))
  dispatch(fetchAlertNames(organizationId))
  dispatch(loadPlaybookNamesAndCids({ userOrganizationId: organizationId }))
}
