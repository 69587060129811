import React from 'react'
import { Button, Popup } from 'semantic-ui-react'
import { IconVolume3, IconVolume } from '@tabler/icons-react'
import classNames from 'classnames'

import { shortenMultipleSelectedHover, truncateAndAddPopup } from '@/views/RealtimeCoaching/helpers'
import Select from '@/components/forms/Select'
import { formatTime } from '@/utils/helpers'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'

const maxListLen = 14
const maxCellTruncateLen = 25

export const createPopup = (popup) => {
  const divStyle = {
    borderBottom: '1px dotted black',
    width: 'fit-content',
  }

  return (
    <Popup inverted content={popup?.tooltip} trigger={<div style={divStyle}>{popup?.text}</div>} />
  )
}

export const createAudibleAndAlertNameColumns = (alertConfig, isReadOnly, handleAudibleClick) => {
  const { audible: isAudible, id } = alertConfig
  const name = truncateAndAddPopup(alertConfig.name, 21)
  return {
    value: alertConfig.name,
    as: (
      <div className="flex-align-center small-gap">
        <Popup
          inverted
          disabled={isReadOnly}
          content={`${isAudible ? 'Disable' : 'Enable'} audible notifications`}
          trigger={
            <Button
              basic
              compact
              icon
              data-testid={`${isAudible ? 'audible-on' : 'audible-off'}`}
              disabled={isReadOnly}
              className={classNames('svg-button svg-button--large', {
                'svg-button--muted': !isAudible,
              })}
              onClick={() => !isReadOnly && handleAudibleClick(id)}
            >
              {isAudible ? <IconVolume /> : <IconVolume3 />}
            </Button>
          }
        />
        <span className="rtc-alerts_alert_name">{name}</span>
      </div>
    ),
  }
}

export const createActiveColumn = (alertConfig, isReadOnly, updateActiveState) => {
  const activeBoolean = (status) => {
    return status === 'active'
  }

  const booleanToStatus = (b) => {
    if (b) {
      return 'Active'
    }
    return 'Deactivated'
  }

  const userOrTagsSet = alertConfig.users.length > 0 || alertConfig.tags.length > 0
  const isDisabled = isReadOnly || !userOrTagsSet

  return {
    value: alertConfig.active,
    as: (
      <ConditionalTooltip condition={!userOrTagsSet} content="Users or Tags required to activate">
        <Select
          data-testid={`is-active-check-${alertConfig.id}`}
          name="status"
          placeholder={booleanToStatus(alertConfig.active)}
          options={[
            {
              value: 'active',
              label: 'Activate',
            },
            {
              value: 'deactivate',
              label: 'Deactivate',
            },
          ]}
          value={booleanToStatus(alertConfig.active)}
          isClearable={false}
          isSearchable={false}
          isDisabled={isDisabled}
          onChange={({ value }) => {
            if (!isReadOnly && activeBoolean(value) !== alertConfig.active) {
              updateActiveState(alertConfig.id)
            }
          }}
          menuPlacement="top"
        />
      </ConditionalTooltip>
    ),
  }
}

export const createUserColumn = (alertConfig, orgTags, orgUsers) => {
  const alertTags = alertConfig.tags
  const alertUsers = alertConfig.users

  const tagNames = orgTags.filter(({ id }) => alertTags.includes(id)).map(({ name }) => name)

  const userNames = orgUsers
    .filter(({ id }) => alertUsers.includes(id))
    .map(({ first_name, last_name }) => `${first_name} ${last_name}`)

  if (tagNames.length) {
    return shortenMultipleSelectedHover(tagNames, maxListLen, maxCellTruncateLen, 'tags', true)
  }

  if (userNames.length) {
    return shortenMultipleSelectedHover(userNames, maxListLen, maxCellTruncateLen, 'agents')
  }

  // This will happen if the users/tags are deleted
  return {
    value: '',
    as: 'Missing Agents or Tags',
  }
}

export const createPlaybookColumn = (alertConfig, configPaths) => {
  const cids = alertConfig.trigger_on.map(({ cid }) => cid)
  const uniqueCids = [...new Set(cids)]

  return shortenMultipleSelectedHover(
    uniqueCids.map((cid) => {
      return configPaths?.[cid]?.name || 'No Playbook Found'
    }),
    maxListLen,
    maxCellTruncateLen
  )
}

export const createCreatedAtColumn = (alertConfig) => {
  return {
    value: alertConfig.created_at,
    as: createPopup({
      tooltip: `Last modified ${formatTime(alertConfig.modified_at)}`,
      text: formatTime(alertConfig.created_at),
    }),
  }
}

export const createAlertTableRows = (
  alertConfigs = [],
  orgTags = [],
  orgUsers = [],
  configPaths = {},
  isReadOnly,
  handleAudibleClick,
  updateActiveState
) => {
  return alertConfigs.map((alertConfig) => {
    return {
      ...alertConfig,
      name: createAudibleAndAlertNameColumns(alertConfig, isReadOnly, handleAudibleClick),
      active_for: createActiveColumn(alertConfig, isReadOnly, updateActiveState),
      created_at: createCreatedAtColumn(alertConfig),
      alerts_for: createUserColumn(alertConfig, orgTags, orgUsers),
      playbooks_for: createPlaybookColumn(alertConfig, configPaths),
      // TODO: this should change once we have an actual value for created by
      created_by: alertConfig.manager_name,
    }
  })
}
