import React from 'react'
import { Button } from 'semantic-ui-react'

import './ColorButton.scss'

export const ColorButton = ({ color, children, ...props }) => {
  return (
    <Button type="button" secondary className="color-button" {...props}>
      <div
        className="color-dot"
        style={{ backgroundColor: `${color || 'var(--dark-knight-300)'}` }}
      />
      {children}
    </Button>
  )
}
