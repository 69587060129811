import { fetchingAPI, apiService } from '../../api'
import { setCurrentOrganization } from './currentOrganization.redux'

export const fetchCurrentOrganization = (organizationId) => {
  return async (dispatch) => {
    try {
      const organization = await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}`,
        'GET',
        dispatch
      )
      dispatch(setCurrentOrganization(organization))
    } catch (err) {
      console.error(err)
    }
  }
}
