import produce from 'immer'

// Action Types
export const SET_LOADING = 'prompts/setLoading'
export const SET_ERROR = 'prompts/setError'
export const SET_PROMPTS = 'prompts/setPrompts'
export const ADD_PROMPT = 'prompts/addPrompt'
export const EDIT_PROMPT = 'prompts/editPrompt'
export const SET_TEMPORARY_PROMPT = 'prompts/setTemporaryPrompt'
export const SET_MODEL_OPTIONS = 'prompts/setModelOptions'

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setError = (payload) => ({ type: SET_ERROR, payload })
export const setPrompts = (payload) => ({ type: SET_PROMPTS, payload })
export const addPrompt = (payload) => ({ type: ADD_PROMPT, payload })
export const editPrompt = (payload) => ({ type: EDIT_PROMPT, payload })
export const setTemporaryPrompt = (payload) => ({ type: SET_TEMPORARY_PROMPT, payload })
export const setModelOptions = (payload) => ({ type: SET_MODEL_OPTIONS, payload })

// State
export const initialState = {
  prompts: [],
  temporaryPrompt: null,
  modelOptions: [],
  loading: {
    modelOptions: false,
    prompts: false,
    promptTests: false,
  },
  dirty: false,
  error: {
    prompt: false,
  },
}

// Reducer
export default function promptReducer(state = initialState, action) {
  return produce(state, (newState) => {
    const { payload } = action

    switch (action.type) {
      case SET_LOADING:
        newState.loading = { ...state.loading, ...payload }
        break
      case SET_ERROR:
        newState.error = { ...state.error, ...payload }
        break
      case SET_PROMPTS:
        newState.prompts = payload
        newState.dirty = false
        newState.error.prompt = false
        break
      case ADD_PROMPT:
        newState.prompts.push(payload)
        newState.dirty = true
        break
      case SET_TEMPORARY_PROMPT:
        newState.temporaryPrompt = payload
        newState.dirty = true
        break
      case SET_MODEL_OPTIONS:
        newState.modelOptions = payload
        break
      default:
        break
    }
  })
}
