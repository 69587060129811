import React, { useMemo, useContext } from 'react'
import { useSelector } from 'react-redux'
import WidgetTooltip from '@/components/widget/WidgetTooltip'
import { flattenTableData } from '@/views/RealtimeCoaching/Reports/ReportsHelpers'
import moment from 'moment'
import { getTickValues } from '@/components/charts/helpers'
import { createTrend } from '@/utils/helpers'
import LineChart from '../../../components/charts/LineChart'
import WidgetContext from '../../../components/widget/widget.context'
import WidgetContainer from '../../../components/widget/WidgetContainer'

export const ReportsAlertTrendsChart = () => {
  const { data } = useSelector((state) => state.realtimeReports)
  const { hiddenItems } = useContext(WidgetContext)

  const alertTypeColors = {
    negative: '#F15050',
    positive: '#39d583',
    informative: '#58A6FF',
  }
  const alertTypeOrder = ['negative', 'positive', 'informative']
  const sortedColors = alertTypeOrder.map((alertType) => alertTypeColors[alertType])
  const sortedData = alertTypeOrder.map((alertType) => data.alertCountsByTypeByDay[alertType])
  const graphData = sortedData.map((listOfAlertsForAlertType, index) => {
    const alertType = alertTypeOrder[index]
    const formattedAlertData = listOfAlertsForAlertType.map((datum) => {
      // Remove year & hyphen from [YYYY-MM-DD]
      const dateStringWithoutYear = moment(datum.day, 'YYYY-MM-DD').format('MM-DD')

      return { x: dateStringWithoutYear, y: datum.count }
    })

    return {
      id: alertType,
      color: sortedColors[index],
      data: formattedAlertData, // {x: value, y: value}
    }
  })

  const dataWithHiddenItems = useMemo(() => {
    return graphData.filter((item) => !hiddenItems.has(item.id))
  }, [graphData, hiddenItems])
  const colors = useMemo(() => dataWithHiddenItems.map((data) => data.color), [dataWithHiddenItems])
  const checkIfGraphEmpty = () => {
    const { positive, negative, informative } = data.alertCountsByTypeByDay
    const totalAlertCount = positive.length + negative.length + informative.length

    return totalAlertCount <= 0
  }
  const tableData = useMemo(() => {
    return flattenTableData(dataWithHiddenItems)
  }, [dataWithHiddenItems])
  const drawTrendLine = (canvasProps, trendLineCategory) => {
    if (!hiddenItems.has(trendLineCategory)) {
      const canvasContext = canvasProps.ctx
      const points = canvasProps.points.filter((point) => point.serieId === trendLineCategory)
      const data = points.map(({ data }) => ({ x: new Date(data.x).getTime(), y: data.y }))
      const xData = data.map(({ x }) => x)
      const xMax = Math.max(...xData)
      const xMin = Math.min(...xData)
      const trend = createTrend(data, 'x', 'y')

      const startingCoordinates = { x: points[0].x, y: canvasProps.yScale(trend.calcY(xMin)) }
      const endingCoordinates = {
        x: points[points.length - 1].x,
        y: canvasProps.yScale(trend.calcY(xMax)),
      }

      canvasContext.beginPath()
      canvasContext.strokeStyle = alertTypeColors[trendLineCategory]
      canvasContext.setLineDash([5])
      canvasContext.lineWidth = 2

      canvasContext.moveTo(startingCoordinates.x, startingCoordinates.y)
      canvasContext.lineTo(endingCoordinates.x, endingCoordinates.y)
      canvasContext.stroke()
    }
  }

  /* Currently, dates are a little funky when moving the mouse across the line graph
  this seems to be in-line with nivos own charts as seen here:
  https://nivo.rocks/storybook/?path=/story/linecanvas--time-scale */
  return (
    <WidgetContainer
      noData={checkIfGraphEmpty()}
      withLegend
      capitalizeLegend
      dataForLegend={{ data: graphData }}
      legendAccessor="id"
      chart={
        <LineChart
          data={dataWithHiddenItems}
          colors={colors}
          tooltip={({ point }) => (
            <WidgetTooltip color={point.color}>
              <span>Date: </span>
              <strong>{point.data.x}</strong>
              <br />
              <span>Alert Count: </span>
              <strong>{point.data.y}</strong>
            </WidgetTooltip>
          )}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Date [Day]',
            legendOffset: 36,
            legendPosition: 'middle',
            tickValues: getTickValues(tableData, 'x'),
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Alert Count',
            legendOffset: -60,
            legendPosition: 'middle',
          }}
          layers={[
            'grid',
            'axes',
            'lines',
            'points',
            'markers',
            'legends',
            (canvasProps) => drawTrendLine(canvasProps, 'positive'),
            (canvasProps) => drawTrendLine(canvasProps, 'informative'),
            (canvasProps) => drawTrendLine(canvasProps, 'negative'),
          ]}
        />
      }
    />
  )
}
