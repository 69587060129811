import React from 'react'
import Helmet from 'react-helmet'
import Widget from '@/components/widget/Widget'
import { AdvancedExportsFilters } from '@/views/RealtimeCoaching/AdvancedExports/filters/AdvancedExportsFilters'
import { AdvancedExportsDisplayOptions } from '@/views/RealtimeCoaching/AdvancedExports/AdvancedExportsDisplayOptions'
import { AdvancedExportsPreviewButton } from '@/views/RealtimeCoaching/AdvancedExports/AdvancedExportsPreviewButton'
import { AdvancedExportsTable } from '@/views/RealtimeCoaching/AdvancedExports/AdvancedExportsTable'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAdvancedExportsFullData } from '@/reducers/realtime/advancedExports/advancedExports.actions'
import { Header } from 'semantic-ui-react'

export const AdvancedExports = () => {
  const dispatch = useDispatch()
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const { filters } = useSelector((state) => state.realtimeCoaching)
  const { customBranding } = useSelector((state) => state.customBranding)
  const displayMetrics = filters.displayMetrics.map((filter) => filter.value)
  const displayGroupings = filters.displayGroupings.map((filter) => filter.value)
  const isBaltoAdmin = organizationId === 1

  const formatData = (data) => {
    return {
      data,
      filters: { ...filters.dateRange },
      headers: [...filters.displayGroupings, ...filters.displayMetrics],
      fileName: 'RTC Advanced Exports',
    }
  }

  const alternateUrlCsvClickHandler = async () => {
    const response = await dispatch(
      fetchAdvancedExportsFullData(
        isBaltoAdmin ? filters.organization : organizationId,
        displayMetrics,
        displayGroupings,
        filters
      )
    )
    return formatData(response)
  }

  return (
    <>
      <Helmet>
        <title>
          {customBranding?.customBrandingDisplayName || 'Balto Cloud'} | Real Time Exports
        </title>
      </Helmet>
      <header>
        <h1 style={{ marginBottom: '1rem' }}>Advanced Exports</h1>
      </header>
      <Header.Subheader>
        Use the filters and display options to build, preview, and download a report.
      </Header.Subheader>
      <Widget displayControls={false}>
        <AdvancedExportsFilters />
        <AdvancedExportsDisplayOptions />
        <AdvancedExportsPreviewButton />
      </Widget>
      <Widget
        showCsvDownload
        label="Preview Data"
        alternateUrlCsvClickHandler={alternateUrlCsvClickHandler}
        subtitle="(Shows up to 20 results)"
        customDisplayName={customBranding?.customBrandingDisplayName}
      >
        <AdvancedExportsTable />
      </Widget>
    </>
  )
}
