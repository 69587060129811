import React from 'react'
import uuid from 'uuid/v4'
import { CircleIcon } from '@/components/icons/AlertIcon'
import { InteractionOverlay } from '../InteractionOverlay/InteractionOverlay'
import { EventPopup } from '../EventPopup'
import './CallInteractionTimeline.css'
import '../EventHumps/EventHump.css'

export function CallInteractionTimeline({
  alerts = [],
  interactions = [],
  call_duration_seconds = 100,
  style = {},
}) {
  return (
    <div
      data-testid="call-interaction-timeline"
      className="callinteractiontimeline-container"
      style={{ ...style }}
    >
      <div className="callinteractiontimeline-row-label-top">Alerts</div>
      <div className="callinteractiontimeline-row-label-bottom">Playbook</div>
      <div className="callinteractiontimeline-line-start">
        <CircleIcon color="dk100" />
      </div>
      <div className="callinteractiontimeline-line">
        {alerts
          .filter((item) => item.time_seconds >= 0 && item.time_seconds <= call_duration_seconds)
          // TODO log out-of-bound items as errors
          .map((item) => {
            let className = 'alert-log-hump'

            if (item.type === 'positive') {
              className += ' alert-log-green-hump'
            }

            if (item.type === 'negative') {
              className += ' alert-log-red-hump'
            }

            if (item.type === 'informative') {
              className += ' alert-log-blue-hump'
            }

            if (item.type === 'playbook') {
              className += ' alert-log-inverted-hump'
            }

            return (
              <EventPopup
                eventTime={item.created_at}
                eventType={item.type}
                callTimeOffset={item.time_seconds}
                section={item.section}
                description={item.name}
                key={uuid()}
              >
                <div
                  data-testid="event-hump"
                  type={item.type}
                  style={{
                    left: `${(item.time_seconds * 100) / call_duration_seconds}%`,
                  }}
                  key={item.alert_id}
                  className={className}
                />
              </EventPopup>
            )
          })}
        {interactions
          // filtering out stop-listens until we get non-duplicate timestamps of start-listen
          .filter(
            (item) =>
              item.time_seconds >= 0 &&
              item.time_seconds <= call_duration_seconds &&
              item.type !== 'stop-listen'
          )
          // TODO log out-of-bound items as errors
          .map((item) => {
            return (
              <EventPopup
                eventTime={item.created_at}
                eventType={item.type === 'start-listen' ? 'Coaching' : item.type}
                callTimeOffset={item.time_seconds}
                section={item.section}
                description={item.description}
                key={uuid()}
              >
                <div
                  style={{
                    left: `${(item.time_seconds * 100) / call_duration_seconds}%`,
                  }}
                >
                  <InteractionOverlay
                    type={item.type}
                    style={{
                      left: `${(item.time_seconds * 100) / call_duration_seconds}%`,
                    }}
                    key={item.alert_id}
                  />
                </div>
              </EventPopup>
            )
          })}
      </div>
      <div className="callinteractiontimeline-line-end">
        <CircleIcon color="dk100" />
      </div>
    </div>
  )
}
