// Action Types

export const TOGGLE_SIDEBAR = 'ui/toggleSidebarCollapsed'
export const EXPAND_ALERT_SIDEBAR = 'ui/showAlertSidebar'
export const COLLAPSE_ALERT_SIDEBAR = 'ui/collapseAlertSidebar'
export const OPEN_MODAL = 'ui/openModal'
export const CLOSE_MODAL = 'ui/closeModal'
export const UPDATE_AUTH_MESSAGE = 'ui/updateAuthMessage'
export const CLEAR_AUTH_MESSAGE = 'ui/clearAuthMessage'

// Action Creators
export const toggleSidebarCollapsed = (payload) => ({ type: TOGGLE_SIDEBAR, payload })
export const expandAlertSidebar = () => ({ type: EXPAND_ALERT_SIDEBAR })
export const collapseAlertSidebar = () => ({ type: COLLAPSE_ALERT_SIDEBAR })
export const openModal = (payload) => ({ type: OPEN_MODAL, payload })
export const closeModal = () => ({ type: CLOSE_MODAL })
export const updateAuthMessage = (type, text) => ({
  type: UPDATE_AUTH_MESSAGE,
  payload: { type, text },
})
export const clearAuthMessage = () => ({ type: CLEAR_AUTH_MESSAGE })

// State
export const initialState = {
  sidebarCollapsed: false,
  alertSidebarCollapsed: true,
  currentlyOpenModalId: null,
  authMessage: { type: '', text: '' },
}

// Reducer
export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarCollapsed: action.payload }
    case EXPAND_ALERT_SIDEBAR:
      return { ...state, alertSidebarCollapsed: false }
    case COLLAPSE_ALERT_SIDEBAR:
      return { ...state, alertSidebarCollapsed: true }
    case OPEN_MODAL:
      return { ...state, currentlyOpenModalId: action.payload }
    case CLOSE_MODAL:
      return { ...state, currentlyOpenModalId: null }
    case UPDATE_AUTH_MESSAGE:
      return { ...state, authMessage: action.payload }
    case CLEAR_AUTH_MESSAGE:
      return { ...state, authMessage: initialState.authMessage }
    default:
      return state
  }
}
