import * as Yup from 'yup'

export const checklistValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

export const checklistInitialValues = {
  name: '',
  is_locked: false,
  win: false,
  trigger: {
    side: 'me',
    type: 'verbatim',
    occurrence_required: false,
    required_before_time_unit: 'seconds',
  },
}
