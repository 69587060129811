import React from 'react'
import { Button } from 'semantic-ui-react'
import { IconSparkles } from '@tabler/icons-react'

import { Pill } from '@/components/pills/Pill'

import './DashboardWidgetSuggested.scss'

export const DashboardWidgetSuggested = ({
  title,
  description,
  handleCancel,
  cancelText = 'Not Interested',
  handleSubmit,
  submitText = 'Track Insight',
  children,
  ...props
}) => {
  return (
    <div className="dashboard-widget__suggested" {...props}>
      <div>
        <header>
          <Pill brand small content="Suggestion" icon={<IconSparkles />} />
          <h3>{title}</h3>
          <p>{description}</p>
        </header>
        <section>{children}</section>
        <footer>
          <Button basic onClick={handleCancel}>
            {cancelText}
          </Button>
          <Button color="black" onClick={handleSubmit}>
            {submitText}
          </Button>
        </footer>
      </div>
    </div>
  )
}
