import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import {
  fetchActivityLogUsers,
  fetchAgentCalls,
} from '@/reducers/realtime/activityLog/activityLog.actions'
import { setData } from '@/reducers/realtime/activityLog/activityLog.redux'

import { BaltoAdminManagerFilter } from '@/views/RealtimeCoaching/components/filters/BaltoAdminManagerFilter'
import {
  fetchAgentsByOrg,
  fetchTagsByOrg,
  fetchUserAlertNames,
} from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'
import Helmet from 'react-helmet'
import { ActivityLogHeader } from './ActivityLogHeader'
import { AgentAccordionHeader } from './AgentAccordionHeader/AgentAccordionHeader'
import { ActivityLogFilters } from './ActivityLogFilters/ActivityLogFilters'
import NoData from '../../../components/NoData'

export const ActivityLog = () => {
  const dispatch = useDispatch()
  const [callsShownLimit, setCallsShownLimit] = useState(3)
  const { user_id: alertedUserId } = useSelector((state) => state.currentUser)
  const data = useSelector((state) => state.activityLog.data)
  const agentFilters = useSelector((state) => state.realtimeCoaching.filters.agents)
  const tagFilters = useSelector((state) => state.realtimeCoaching.filters.tags)
  const { filters } = useSelector((state) => state.realtimeCoaching)
  const loadingActivityLogUsers = useSelector((state) => state.activityLog.loading.activityLogUsers)
  const { customBranding } = useSelector((state) => state.customBranding)
  const RTCBaltoAdminFilters = useSelector((state) => state.realtimeCoaching.filters)
  const { organizationid } = useSelector((state) => state.currentUser)
  const isBaltoAdmin = organizationid === 1

  const expandAgent = (agent) => {
    const agentCalls = data.agentCalls?.[agent.user_id] || null
    dispatch(
      fetchAgentCalls({
        agentId: agent.user_id,
        timeAfterFilter: agentCalls?.lastUpdated || null,
        managerId: RTCBaltoAdminFilters.baltoAdminManager || null,
      })
    )
    setCallsShownLimit(3)
  }

  const filteredActivityLogUsers =
    useMemo(() => {
      return Object.values(data.activityLogUsers)
        .filter(({ user_id: activityLogUserId }) => {
          // Nothing selected means everything selected
          if (!agentFilters.length) {
            return true
          }
          return agentFilters.some(({ value: filterUserId }) => activityLogUserId === filterUserId)
        })
        .filter(({ alerts }) => {
          const alertTypes = alerts?.map(({ alert_type }) => alert_type) || []
          if (!filters.alertTypes.length) {
            return true
          }
          return filters.alertTypes.some(({ value }) => alertTypes.includes(value))
        })
        .filter(({ alerts }) => {
          const userAlertNames = alerts?.map(({ alert_name }) => alert_name) || []
          if (!filters.userAlertNames.length) {
            return true
          }
          return filters.userAlertNames.some(({ label }) => userAlertNames.includes(label))
        })
        .filter(({ tags }) => {
          // Nothing selected means everything selected
          if (!tagFilters.length) {
            return true
          }
          return tagFilters.some(({ value }) => tags.includes(value))
        })
        .sort((firstUser, secondUser) => {
          return (
            new Date(secondUser.last_alert_created_at) - new Date(firstUser.last_alert_created_at)
          )
        })
    }, [data.activityLogUsers, filters]) || []

  useEffect(() => {
    dispatch(fetchActivityLogUsers({ alertedUserId }))
  }, [])

  const activitySinceTime = useMemo(() => {
    return moment().subtract(1, 'days')
  }, [])

  const handleBaltoManagerFiltersSubmit = ({ userId, userOrganizationId }) => {
    dispatch(setData({ agentCalls: {} }))
    dispatch(setData({ activityLogUsers: {} }))
    dispatch(fetchActivityLogUsers({ alertedUserId: userId, organizationId: userOrganizationId }))
    dispatch(fetchAgentsByOrg(userOrganizationId))
    dispatch(fetchTagsByOrg(userOrganizationId))
    dispatch(fetchUserAlertNames({ managerId: RTCBaltoAdminFilters.baltoAdminManager || null }))
  }

  return (
    <>
      <Helmet>
        <title>
          {customBranding?.customBrandingDisplayName || 'Balto Cloud'} | Real Time Activity Log
        </title>
      </Helmet>
      <ActivityLogHeader activitySinceTime={activitySinceTime} />
      {isBaltoAdmin && <BaltoAdminManagerFilter onSubmit={handleBaltoManagerFiltersSubmit} />}
      <ActivityLogFilters />

      {!filteredActivityLogUsers.length && !loadingActivityLogUsers && (
        <div className="empty-table" data-testid="empty-table">
          <div className="empty-table">
            <NoData text="Nothing meets the filter criteria." />
          </div>
        </div>
      )}

      {loadingActivityLogUsers && <Loader active data-testid="loader" />}

      <div data-testid="agents-table">
        {filteredActivityLogUsers.map((agent) => {
          return (
            <AgentAccordionHeader
              callsShownLimit={callsShownLimit}
              setCallsShownLimit={setCallsShownLimit}
              expandAgent={expandAgent}
              agent={agent}
              agentCalls={data.agentCalls[agent.user_id]}
              key={agent.user_id}
            />
          )
        })}
      </div>
    </>
  )
}
