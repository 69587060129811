import { toString } from 'lodash'

export const initializePendo = () => {
  if (process.env.NODE_ENV !== 'development') {
    const user = JSON.parse(localStorage.getItem('User')) || {}

    // eslint-disable-next-line no-undef
    pendo.initialize({
      visitor: {
        id: user.username,
        playbookEditorAccess: user.edit_config,
        firstName: user.first_name || null,
        lastName: user.last_name || null,
        email: user.username,
        full_name: `${user.first_name} ${user.last_name}`,
        uuid: user.uuid,
        agentStatus: 'active',
        role: user.password ? 'manager' : 'agent',
        playbook: user.playbook_name,
        edit_config: user.edit_config,
        edit_users: user.edit_users,
        leaderboard_access: user.edit_leaderboard,
        qa_access: user.edit_qa,
        qa_copilot_access: user.edit_qa_copilot,
        realtime_coaching_access: user.realtime_coaching_access,
        permissions: [user.edit_config, user.edit_users, user.realtime_coaching_access],
      },
      account: {
        id: toString(user.organizationid),
        organizationName: user.organization_name,
        name: user.organization_name,
        qa_access: user.edit_qa,
        qa_copilot_access: user.edit_qa_copilot,
        production: user.production,
        leaderboard_access: user.edit_leaderboard,
        account_production: user.account_production,
        account_leaderboard_visibility: user.account_leaderboard_visibility,
        account_rtc_access: user.org_realtime_coaching_access,
        account_rtqa_access: user.account_rtqa_access,
      },
    })
  }
}
