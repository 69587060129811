import { renderLayeredTooltip } from '@/components/charts/customCharts/LayeredTooltip/LayeredTooltip'
import { calculateMaxValue, calculateProportionalMaxValue, drawCircle, drawLine } from './helpers'

export const LayeredLineGraph = (
  canvasContext,
  props,
  { dataPoints = [], dataPointKey, color = '#4D63FE', toggleTooltip, tooltipDescription, tooltipId }
) => {
  const { canvas } = canvasContext
  const { bars, innerHeight, margin, innerWidth } = props
  const dataPointCoordinates = []
  const maxValue = calculateMaxValue(dataPoints, dataPointKey)
  const cleanMaxValue = calculateProportionalMaxValue(maxValue)

  if (tooltipId) {
    canvas.onmousemove = (e) =>
      renderLayeredTooltip(e, {
        canvas,
        dataPointCoordinates,
        innerWidth,
        innerHeight,
        margin,
        tooltipId,
        tooltipDescription,
        toggleTooltip,
      })
  }

  if (dataPoints?.length) {
    drawLine(canvasContext, innerHeight, cleanMaxValue, bars, dataPoints, color)
    bars.forEach((point, idx) => {
      const { x, y } = dataPoints[idx]
      const circleSize = 5
      // point.x is the left-start of the bar, so we need to add half bar width to get middle
      const horizontalCenterOfBar = point.x + point.width / 2
      const relativeHeightOfY = innerHeight - (y / cleanMaxValue) * innerHeight

      drawCircle(
        canvasContext,
        circleSize,
        horizontalCenterOfBar,
        y,
        innerHeight,
        cleanMaxValue,
        color
      )

      // push this to a list of coordinates for LayeredTooltip
      dataPointCoordinates.push({
        x,
        y,
        xCoordinate: horizontalCenterOfBar,
        yCoordinate: relativeHeightOfY,
      })
    })
  }
}
