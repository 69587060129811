import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Loader, Popup, Button } from 'semantic-ui-react'
import { v4 as uuid } from 'uuid'
import { toNumber } from 'lodash'
import { IconPlus, IconTrash } from '@tabler/icons-react'

import { setOrgDropdown } from '@/reducers/orgHierarchy/orghierarchy.redux'
import { fetchUpdateParentOrg } from '@/reducers/orgHierarchy/orghierarchy.actions'

export const RecursiveOrgList = ({ root }) => {
  const dispatch = useDispatch()
  const { organizationid } = useParams()
  const { loading } = useSelector((state) => state.orgHierarchy)

  const handleOpenOrgDropdown = (childOrgId) => {
    dispatch(setOrgDropdown({ parentOrgId: childOrgId, selectedChildOrgId: '' }))
  }

  const handleRemoveOrg = (childOrgId) => {
    dispatch(fetchUpdateParentOrg(null, childOrgId, organizationid))
  }

  if (!root?.children) {
    return null
  }

  if (loading) {
    return <Loader inline active />
  }

  return (
    <ul className="org-list">
      {root.children.map((child) => (
        <li key={uuid()}>
          <div className="org-row">
            <a
              href={`/organizations/${child.organization_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${child.name} (${child.organization_id})`}
            </a>
            <div className="flex-align-center small-gap">
              <Popup
                position="top right"
                inverted
                content="Add Child Organization"
                trigger={
                  <Button
                    icon
                    compact
                    secondary
                    className="svg-button"
                    onClick={() => handleOpenOrgDropdown(child.organization_id)}
                  >
                    <IconPlus />
                  </Button>
                }
              />
              {toNumber(child.organization_id) !== toNumber(organizationid) && (
                <Popup
                  position="top right"
                  inverted
                  content="Remove Child Organization"
                  trigger={
                    <Button
                      icon
                      compact
                      color="red"
                      className="svg-button"
                      onClick={() => handleRemoveOrg(child.organization_id)}
                    >
                      <IconTrash />
                    </Button>
                  }
                />
              )}
            </div>
          </div>

          {child.children && <RecursiveOrgList root={child} />}
        </li>
      ))}
    </ul>
  )
}
