import React from 'react'
import './ChallengeDetailPage.scss'

export const ChallengeDetailSummary = ({ summaryDataPoints = [] }) => {
  return (
    <div className="challenge-detail__summary" data-testid="challenge-detail-summary">
      {summaryDataPoints.map((dataPoint) => (
        <div
          className="challenge-detail__summary-section"
          key={dataPoint.description}
          data-testid={`challenge-summary--${dataPoint.description}`}
        >
          <div className="challenge-detail__summary-data">{dataPoint.value}</div>
          <div className="challenge-detail__summary-data-description">{dataPoint.description}</div>
        </div>
      ))}
    </div>
  )
}
