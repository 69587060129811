// Action Types
export const SET_ORGANIZATIONS = 'organizations/setOrganizations'

// Action Creators
export const setOrganizations = (payload) => ({ type: SET_ORGANIZATIONS, payload })

// State
export const initialState = []

// Reducer
export default function organizationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORGANIZATIONS:
      return action.payload
    default:
      return state
  }
}
