import React from 'react'
import { useSelector } from 'react-redux'

export const IntegrationEditorMonitoring = () => {
  const { integration } = useSelector((state) => state.integrations)

  return (
    <div>
      <div className="integration-monitoring-area">
        <div>
          <b>Health Score</b>:
          {integration?.healthScore ? integration?.healthScore.toFixed(2) : 'N/A'}
          &nbsp;
        </div>
        <div>
          <a
            href={`https://app.datadoghq.com/logs?cols=host%2Cservice&event&index=&live=true&messageDisplay=inline&query=service%3Aagent-connectors+org_id%3D${integration?.organization_id}+%7C%7C+organization_id%3D${integration?.organization_id}&stream_sort=desc`}
          >
            Datadog Logs
          </a>
        </div>
      </div>
      <br />
    </div>
  )
}

export default IntegrationEditorMonitoring
