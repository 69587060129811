import React from 'react'
import { Form } from 'semantic-ui-react'
import classNames from 'classnames'

import './Checkbox.scss'

export const Checkbox = ({
  label,
  field: { name, value, ...fieldProps },
  inline = true,
  reverse = false,
  required, // not implemented
  form,
  ...props
}) => {
  return (
    <div
      className={classNames('field', {
        'checkbox-vertical-label': !inline,
        'checkbox-reverse-label': reverse,
      })}
    >
      {label && (!inline || reverse) && <label>{label}</label>}
      <Form.Checkbox
        data-testid={`checkbox-${name}`}
        inline={inline}
        id={name}
        label={inline && !reverse ? label : null}
        name={name}
        checked={!!value}
        {...fieldProps}
        {...props}
      />
    </div>
  )
}
