import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { fetchOrganizationData } from '@/reducers/organizations/organization.actions'
import { fetchCategories, fetchTags } from '@/reducers/organizations/organizationTagManager.actions'

import TagForm from './TagForm'

const TagPage = () => {
  const dispatch = useDispatch()
  const routeParams = useParams()
  const [loading, setLoading] = useState(false)
  const { edit_users: editUsers, organizationid: userOrganizationId } = useSelector(
    (state) => state.currentUser
  )
  const organizationTags = useSelector((state) => state.organizationTagManager)
  const { users } = useSelector((state) => state.organizationUsers)
  const organization = useSelector((state) => state.organization)

  const toggleLoading = () => {
    setLoading((prevLoading) => {
      return !prevLoading
    })
  }
  const fetchCategoriesAndTags = (loader, orgId) => async () => {
    await dispatch(fetchCategories({ organizationId: orgId }))
    await dispatch(fetchTags({ organizationId: orgId, loader }))
    if (loading) toggleLoading()
  }

  useEffect(() => {
    const orgId = routeParams?.organizationid ? routeParams.organizationid : userOrganizationId
    toggleLoading()
    dispatch(fetchOrganizationData({ organizationId: orgId, toggleLoading }))
    dispatch(fetchCategoriesAndTags(toggleLoading, orgId))
  }, [])

  return (
    <div className="tags">
      {loading && (
        <div className="ui active dimmer">
          <div className="ui loader massive" />
        </div>
      )}
      <TagForm
        tags={organizationTags}
        organizationId={organization.id}
        fetchCategoriesAndTags={fetchCategoriesAndTags}
        users={users}
        name={organization.name}
        userOrganizationId={userOrganizationId}
        edit_users={editUsers}
      />
    </div>
  )
}

export default withLDConsumer()(TagPage)
