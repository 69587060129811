import React from 'react'
import { Popup, Button } from 'semantic-ui-react'
import { DefinedRange } from 'react-date-range'
import moment from 'moment'
import classNames from 'classnames'

import { customStaticRanges } from './helpers'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

export default function DateRangePickerDefinedReusable({
  startDate,
  endDate,
  error = false,
  ...props
}) {
  return (
    <Popup
      basic
      flowing
      on="click"
      position="bottom left"
      trigger={
        <Button type="button" basic fluid className={classNames('date-filter', { error })}>
          <strong>{moment(startDate).format('L')}</strong>
          <span>to</span>
          <strong>{moment(endDate).format('L')}</strong>
        </Button>
      }
    >
      <DefinedRange
        ranges={[
          {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
            key: 'selection',
          },
        ]}
        maxDate={moment().toDate()}
        editableDateInputs
        dateDisplayFormat="MM/dd/yyyy"
        staticRanges={customStaticRanges}
        inputRanges={[]}
        startDatePlaceholder=""
        endDatePlaceholder=""
        {...props}
      />
    </Popup>
  )
}
