import React, { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import { fetchKeywordsRepository } from '@/reducers/keywords/keywords.actions'

import { Select } from '../Select'

export const KeywordSelect = ({ ...props }) => {
  const dispatch = useDispatch()
  const { keywords, loading, language: keywordsLanguage } = useSelector((state) => state.keywords)
  const { language: playbookLanguage } = useSelector((state) => state.playbook?.playbook)
  const [showDefinitions, setShowDefinitions] = useState(true)

  useEffect(() => {
    // get keywords if language is different or we don't have them yet
    if (playbookLanguage !== keywordsLanguage || (isEmpty(keywords) && !loading)) {
      dispatch(fetchKeywordsRepository(playbookLanguage))
    }
  }, [playbookLanguage])

  const formatOptionLabel = ({ label, definition, examples }, { context }) => {
    if (context === 'menu' && showDefinitions) {
      return (
        <div className="select-option-with-definition">
          <div className="select-option-title">
            <h4>{label}</h4>
          </div>
          {(definition || examples) && showDefinitions && (
            <div className="select-option-definitions">
              {definition && (
                <div>
                  <strong>Definition</strong>: {definition}
                </div>
              )}
              {examples && (
                <div>
                  <strong>Examples</strong>: {examples}
                </div>
              )}
            </div>
          )}
        </div>
      )
    }

    return <div>{label}</div>
  }

  const options = useMemo(
    () =>
      keywords.map((keyword) => ({
        value: keyword.krid,
        label: keyword.name,
        definition: keyword.definition,
        examples: keyword.examples ? keyword.examples.join(', ') : '',
      })),
    [keywords]
  )

  return (
    <>
      <button
        type="button"
        className="text-button link"
        onClick={(event) => {
          event.stopPropagation()
          setShowDefinitions((prev) => !prev)
        }}
      >
        <span>
          <small>
            {showDefinitions ? 'Hide AI Library Definitions' : 'Show AI Library Definitions'}
          </small>
        </span>
      </button>
      <Select
        loading={loading}
        options={options}
        formatOptionLabel={formatOptionLabel}
        onChange={({ value }) => {
          // nullify side selection when changing categories so we can set default side
          if (props.field.value !== value) props.form.setFieldValue('trigger.side', null)
        }}
        {...props}
      />
    </>
  )
}
