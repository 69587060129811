// Action Types
export const SET_USERS = 'organizations/setUsers'
export const SET_LOADING = 'organizations/setLoading'
export const SET_USER_PERMISSION_COLUMNS = 'organizations/setUserPermissions'

// Action Creators
export const setUsers = (payload) => ({ type: SET_USERS, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setUserPermissionColumns = (payload) => ({
  type: SET_USER_PERMISSION_COLUMNS,
  payload,
})

// State
export const initialState = {
  loading: {
    userTable: false,
    userPermissions: false,
  },
  users: [],
  userPermissionColumns: [],
}

// Reducer
export default function organizationUsersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USERS:
      return { ...state, users: action.payload }
    case SET_LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    case SET_USER_PERMISSION_COLUMNS:
      return { ...state, userPermissionColumns: action.payload }
    default:
      return state
  }
}
