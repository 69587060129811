import React from 'react'
import pluralize from 'pluralize'

import { IconAlertTriangle } from '@tabler/icons-react'
import { Banner } from '@/components/banners/Banner'

export const PlaybookScorecardWarning = ({ numberOfBreakingScorecards }) => {
  return (
    <Banner warning header="Publishing playbook edits" icon={<IconAlertTriangle />}>
      Please be aware that publishing this playbook will affect{' '}
      {pluralize('scorecard', numberOfBreakingScorecards, true)}. These scorecards will
      automatically be updated with your changes.
    </Banner>
  )
}
