import { apiService } from '@/api'
import * as redux from '@/reducers/commandCenter/commandCenter.redux'
import * as actions from '@/reducers/commandCenter/commandCenter.actions'
import { LOGOUT_USER } from '@/reducers/auth/currentUser.redux'

export const ccSocketMiddleware = (worker, broadcast) => (params) => (next) => (action) => {
  const { dispatch, getState } = params
  const { type } = action
  const { username, token, organizationid } = getState().currentUser

  switch (type) {
    case redux.CONNECT:
      // Initialize classes for worker and broadcast
      // Only one instance will be created, if one already exists it will be skipped
      worker.init()
      broadcast.init()

      if (worker.unsupportedBrowser || broadcast.unsupportedBrowser) {
        return next(action)
      }

      // Set up event listeners to receive broadcasts
      broadcast.on('message', ({ data }) => {
        console.info('Receiving message from Command Center Broadcast')

        // Currently, all events result in just fetching a brand new agent
        if (data.type === redux.RECEIVE_BROADCAST) {
          const agent = getState().commandCenter.agents.find(
            (agent) => agent.agent_username === data.message?.user
          )

          if (agent) {
            dispatch(actions.fetchAgentWithCallStatus(agent.agent_id))
          }
        }
      })

      broadcast.on('error', (err) => {
        console.info('An error was encountered in the Command Center Broadcast')
        console.error(err)
      })

      // Post a message to the worker to open the initial websocket connection
      worker.post({
        token,
        username,
        organizationid,
        type: redux.CONNECT,
        url: `${apiService.rtc_websocket}/ws/command-center`,
      })
      break

    case redux.DISCONNECT:
    case LOGOUT_USER:
      // On disconnect, worker and broadcast will always exist so it's not necessary to check, but
      // on logout from no token or bad token, these will never have been initialized.
      if (worker.sharedWorker) {
        worker.post({ type: redux.DISCONNECT })
      }

      if (broadcast.broadcastChannel) {
        broadcast.close()
      }

      break

    default:
      break
  }

  return next(action)
}
