import React from 'react'
import classNames from 'classnames'

import './SpinnerLoader.scss'

export const SpinnerLoader = ({ dark = false }) => (
  <div className={classNames('lds-ring', { 'lds-ring-dark': dark })}>
    <div />
    <div />
    <div />
    <div />
  </div>
)
