import { cloneDeep } from 'lodash'

import {
  sanitizeTrigger,
  sanitizeCategoryEntry,
  sanitizeStatic,
  sanitizeSummary,
  sanitizeDisplay,
  parseKeywords,
} from './sanitizationHelpers'

// these are the rules for how we sanitize each property, scoped by string datatype
const propertySanitizationRules = {
  entry: {
    trigger: (trigger) => sanitizeTrigger(trigger),
    name: (name) => name.replace(/-/g, ' ').replace(/  +/g, ' ').trim(),
    display: (display) => sanitizeDisplay(display),
    // eslint-disable-next-line no-use-before-define
    entry: (entry) => sanitizeProperties(entry, 'entry'),
  },
  category: {
    name: (name) => name.replace(/-/g, ' ').replace(/  +/g, ' ').trim(),
  },
  user: {
    first_name: (name) => name.trim(),
    last_name: (name) => name.trim(),
    code: (code) => (code.trim() ? code.trim() : null),
    username: (username) => username.replace(/ /g, '').trim().toLowerCase(),
  },
  organization: {
    name: (name) => name.trim(),
  },
  playbook: {
    name: (name) => name.trim(),
  },
  config: {
    name: (name) => name.trim(),
  },
  keyword: {
    name: (name) => name.trim(),
    phrases: (phrases) => parseKeywords(phrases),
    examples: (examples) => (examples.trim() ? parseKeywords(examples, true) : null),
  },
}

/**
 * @param {Object} entry - Entry object for config
 * @param {String} section - String representation of the config section
 * @returns {Object} A new entry object that has been sanitized depending on the
 * config section it will live within
 */
const sanitizeEntryForSection = (entry, section) => {
  const newEntry = cloneDeep(entry)
  // suggestionId is a property hidden on the form that we use
  // to get field level suggestions, needs to be deleted before
  // back end config schema rejects the save
  if ('suggestionId' in newEntry) delete newEntry.suggestionId
  switch (section) {
    case 'checklist':
      if (newEntry.win === false) {
        delete newEntry.win
      }
      newEntry.trigger.side = 'me'
      delete newEntry.display
      return newEntry
    case 'notifications':
      // this is for the verbatim => keywords conversion in notifications.
      // we use the entry name for the keyword phrases
      if (newEntry.trigger.type === 'verbatim' || newEntry.trigger.type === 'keywords') {
        newEntry.trigger = {
          ...newEntry.trigger,
          type: 'keywords',
          phrases: [newEntry.name ? newEntry.name : ''],
        }
      }
      return newEntry
    case 'static':
      delete newEntry.trigger
      return sanitizeStatic(newEntry)
    case 'postcall':
      delete newEntry.display
      return newEntry
    case 'summary':
      return sanitizeSummary(newEntry)
    case 'deck':
      return sanitizeCategoryEntry(newEntry, section)
    case 'classified_postcall':
      return sanitizeCategoryEntry(newEntry, section)
    default:
      return console.error('INVALID SECTION TYPE DURING SANITIZATION')
  }
}

/**
 * @param {Object} dirtyObject - Any object that has properties you want to sanitize
 * will map over the keys of the object and check for a matching rule in
 * propertySanitizationRules[datatype] then call the function if match found
 * @param {String} dataType - String representation of type of data you are trying to sanitize,
 * used to scope sanitization rules
 * @returns {Object} An object with sanitized values for
 * properties matching sanitization rules and unchanged values for properties
 * not in sanitization rules
 */
const sanitizeProperties = (dirtyObject, dataType) => {
  const dirtyClone = cloneDeep(dirtyObject)
  return Object.keys(dirtyClone)
    .map((property) => ({
      propertyName: property,
      cleanValue:
        propertySanitizationRules[dataType][property] && dirtyClone[property]
          ? propertySanitizationRules[dataType][property](dirtyClone[property])
          : dirtyClone[property],
    }))
    .reduce((cleanObj, { propertyName, cleanValue }) => {
      cleanObj[propertyName] = cleanValue
      return cleanObj
    }, {})
}

/**
 * @param {Object} store - the redux store object we take dispatch and getState functions from it
 * @param {Object} next - this is dispatch from this point in the middleware chain, instead of
 * dispatching an action that would start back at the beginning and run through all middleware again
 * @param {Object} action - action object that was dispatched
 */
export default () => (next) => (action) => {
  if (action.sanitizeType) {
    // Middleware for new playbook editor
    // The action looks like: { type: 'x', payload: { entry: {} }, accessor: 'checklist', sanitizeType: 'entry' }
    const newSanitizedAction = cloneDeep(action)
    const newSanitizedPayload = sanitizeProperties(action.payload, action.sanitizeType) // sanitizes category name
    if (newSanitizedPayload.entry && action.accessor) {
      newSanitizedPayload.entry = sanitizeEntryForSection(
        newSanitizedPayload.entry,
        action.accessor
      )
    }
    newSanitizedAction.payload = newSanitizedPayload
    delete newSanitizedAction.sanitizeType

    return next(newSanitizedAction)
  }

  return next(action)
}
