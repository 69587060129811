import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Form } from 'semantic-ui-react'
import { IconDownload } from '@tabler/icons-react'

import { fetchScorecardsByOrg } from '@/reducers/scorecards/scorecards.actions'
import ConfirmForm from '../../components/forms/ConfirmForm'
import Select from '../../components/forms/Select'
import {
  fetchOrganizationAndLeaderboards,
  fetchLeaderboard,
  createEditLeaderboard,
  deleteLeaderboard,
  exportLeaderboardToCsv,
} from '../../reducers/leaderboards/leaderboards.actions'
import { getLeaderboardBody } from './helpers'
import { ButtonAndFormModal } from '../../components/layout/modals/ButtonAndFormModal'
import { LeaderboardForm } from './LeaderboardForm/LeaderboardForm'

export const LeaderboardFilters = () => {
  const dispatch = useDispatch()
  const { organizationid: organizationId, edit_leaderboard: canEditLeaderboard } = useSelector(
    (state) => state.currentUser
  )
  const { data, filters, loading } = useSelector((state) => state.leaderboards)
  const organizations = useSelector((state) => state.organizations)
  const selectedLeaderboard = data.leaderboards.find(
    (item) => item?.leaderboard?.id === filters.leaderboardId
  )
  const isBaltoAdmin = organizationId === 1

  const handleSelectOrganization = (option) => {
    const selectedOrganizationId = isBaltoAdmin ? option.value : organizationId
    dispatch(fetchOrganizationAndLeaderboards(selectedOrganizationId))
    dispatch(fetchScorecardsByOrg(selectedOrganizationId))
  }

  const handleSelectLeaderboard = (option) => {
    const leaderboardId = option.value

    dispatch(fetchLeaderboard(filters.organizationId, leaderboardId))
  }

  const handleCreateEditLeaderboard = (values, leaderboard) => {
    // if leaderboard is passed, it's an edit
    const leaderboardId = leaderboard?.leaderboard?.id ? leaderboard.leaderboard.id : null
    const orgId = filters.organizationId
    const body = getLeaderboardBody(values, leaderboardId)

    if (leaderboardId) {
      // Edit
      dispatch(createEditLeaderboard(orgId, body, leaderboardId))
    } else {
      // Create
      dispatch(createEditLeaderboard(orgId, body))
    }
  }

  const handleDeleteLeaderboard = (leaderboardId) => {
    dispatch(deleteLeaderboard(filters.organizationId, leaderboardId))
  }

  useEffect(() => {
    if (!isBaltoAdmin) {
      handleSelectOrganization(organizationId)
    }
  }, [])

  return (
    <>
      <header className="page-header">
        <h1>Leaderboards</h1>

        {canEditLeaderboard && (
          <ButtonAndFormModal
            data-testid="create-leaderboard-button"
            buttonLabel="Create Leaderboard"
            modalTitle="Create Leaderboard"
            modalId="create-leaderboard"
            disabled={isBaltoAdmin && !filters.organizationId}
            buttonProps={{ primary: true }}
            form={<LeaderboardForm handleSubmit={handleCreateEditLeaderboard} />}
          />
        )}
      </header>

      <Form data-testid="leaderboard-filters" className="filter-form">
        <div className="filter-grid small-grid">
          {isBaltoAdmin && (
            <Form.Field>
              <label>Organization</label>
              <div data-testid="organization-dropdown">
                <Select
                  isClearable={false}
                  placeholder="Select Organization"
                  options={organizations.map((org) => ({
                    label: org.name,
                    value: org.id,
                  }))}
                  loading={loading.organizations}
                  value={filters.organizationId}
                  onChange={(option) => handleSelectOrganization(option)}
                />
              </div>
            </Form.Field>
          )}

          <Form.Field>
            <label>Leaderboard</label>
            <div data-testid="leaderboard-dropdown">
              <Select
                isClearable={false}
                placeholder="Select Leaderboard"
                options={data.leaderboards.map((item) => ({
                  label: item.version.name,
                  value: item.leaderboard.id,
                }))}
                loading={loading.leaderboards}
                value={filters.leaderboardId}
                onChange={(option) => handleSelectLeaderboard(option)}
              />
            </div>
          </Form.Field>

          {canEditLeaderboard && (
            <Form.Field>
              <label className="label">Leaderboard Settings</label>

              <div className="flex-align-center small-gap">
                <ButtonAndFormModal
                  buttonLabel="Edit"
                  modalTitle="Edit Leaderboard"
                  modalId="edit-leaderboard"
                  disabled={!filters.leaderboardId}
                  buttonProps={{ primary: true }}
                  form={
                    <LeaderboardForm
                      isEdit
                      selectedLeaderboard={selectedLeaderboard}
                      handleSubmit={handleCreateEditLeaderboard}
                    />
                  }
                />

                <ButtonAndFormModal
                  buttonLabel="Delete"
                  modalTitle="Delete Leaderboard"
                  modalId="delete-leaderboard"
                  disabled={!filters.leaderboardId}
                  modalProps={{ size: 'tiny' }}
                  buttonProps={{ color: 'red' }}
                  form={
                    <ConfirmForm
                      valueToSubmit={filters.leaderboardId}
                      bodyText="Are you sure you want to delete this leaderboard?"
                      danger
                      primaryButtonText="Delete Leaderboard"
                      handleSubmit={handleDeleteLeaderboard}
                    />
                  }
                />

                <Button
                  secondary
                  className="svg-button"
                  data-testid="export-button"
                  disabled={!filters.leaderboardId}
                  loading={loading.csv}
                  onClick={() => dispatch(exportLeaderboardToCsv(data, filters.leaderboardId))}
                >
                  <IconDownload />
                  Export
                </Button>
              </div>
            </Form.Field>
          )}
        </div>
      </Form>
    </>
  )
}
