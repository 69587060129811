import moment from 'moment'

// Action Types
export const SET_CUSTOM_BRANDING = 'customBranding/setCustomBranding'
export const SET_LOADING = 'customBranding/setLoading'

// Action Creators
export const setCustomBranding = (payload) => ({ type: SET_CUSTOM_BRANDING, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })

// State
export const initialState = {
  customBranding: {
    customBrandingDisplayName: null,
    faviconUrl: null,
    iconLightUrl: null,
    iconDarkUrl: null,
  },
  loading: false,
  imageCache: moment().toISOString(),
}

// Reducer
export default function organizationCustomBrandingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload }
    case SET_CUSTOM_BRANDING:
      return {
        ...state,
        customBranding: { ...state.customBranding, ...action.payload },
        imageCache: moment().toISOString(),
      }
    default:
      return state
  }
}
