import React from 'react'

import Widget from '../../../components/widget/Widget'
import { ItemsByDateWidget } from '../widgets/ItemsByDateWidget'
import { ItemsByUserWidget } from '../widgets/ItemsByUserWidget'
import { ItemUsageWidget } from '../widgets/ItemUsageWidget'
import { ItemsByCallWidget } from '../widgets/ItemsByCallWidget'

export const NotificationsSection = () => {
  return (
    <div data-testid="reports-notifications">
      <Widget
        widgetId="notifications-usage"
        label="Notification Usage"
        subtitle="The count of each notification trigger across selected agents"
        subtitlePercent="Average trigger rate of each notification across selected agents"
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ItemUsageWidget section="notifications" />
      </Widget>
      <Widget
        widgetId="notifications-usageByDate"
        label="Notification Usage by Date"
        subtitle="Time series of the count of each notification trigger across selected agents"
        subtitlePercent="Time series of average trigger rate of each notification across selected agents"
        showGroupBy
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ItemsByDateWidget section="notifications" />
      </Widget>
      <Widget
        widgetId="notifications-usageByUser"
        label="Notification Usage by User"
        subtitle="The count of each notification trigger for each selected agents"
        subtitlePercent="Average trigger rate of each notification for each selected agents"
        showPercent
        showViewToggle
        showCsvDownload
        percentViewDefault={false}
      >
        <ItemsByUserWidget section="notifications" />
      </Widget>
      <Widget
        widgetId="notifications-wins"
        label="Notification Wins"
        subtitle="The count of won calls of each notification across selected agents"
        subtitlePercent="Average win rate of each notification across selected agents"
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ItemUsageWidget section="notifications" isWin />
      </Widget>
      <Widget
        widgetId="notifications-usageByCall"
        label="Notification Usage By Call"
        subtitle="Matrix table of calls, where each row represents a call and each column is a notification, with colored cells indicating usage"
      >
        <ItemsByCallWidget section="notifications" />
      </Widget>
    </div>
  )
}
