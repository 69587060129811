export const getRelativeCursor = (el, event) => {
  const { clientX, clientY } = event
  const bounds = el.getBoundingClientRect()

  return [clientX - bounds.left, clientY - bounds.top]
}

export const givenValueMatchesCoordinate = (
  cursorPoint,
  dataPoint,
  marginOfError,
  xOffset,
  yOffset
) => {
  const acceptableXMin = dataPoint.xCoordinate + xOffset - marginOfError
  const acceptableXMax = dataPoint.xCoordinate + xOffset + marginOfError

  if (cursorPoint.x < acceptableXMin || cursorPoint.x > acceptableXMax) return false

  const acceptableYMin = dataPoint.yCoordinate + yOffset - marginOfError
  const acceptableYMax = dataPoint.yCoordinate + yOffset + marginOfError

  if (cursorPoint.y < acceptableYMin || cursorPoint.y > acceptableYMax) return false

  return true
}
