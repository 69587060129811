import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchCustomBranding } from '@/reducers/customBranding/customBranding.actions'

const CustomBrandingProvider = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.currentUser)

  useEffect(() => {
    if (currentUser.organizationid) {
      dispatch(fetchCustomBranding(currentUser.organizationid))
    }
  }, [currentUser?.user_id])

  return null
}

export default CustomBrandingProvider
