import { groupBy } from 'lodash'

/**
 * Filters out duplicate incoming objects
 * @param {Array} incomingObjectArray - Array of objects to be filtered
 * @param {Array} existingObjectArray - Array of objects to be checked against
 * @param {String} filterByProperty - Property to be filtering by
 * @return {Array} Returns filtered object array
 */
export const filterDuplicateObjectsByProperty = (
  incomingObjectArray,
  existingObjectArray,
  filterByProperty
) => {
  const arrayOfExistingObjectProperties = existingObjectArray.map(
    (existingObject) => existingObject[filterByProperty]
  )

  return incomingObjectArray.filter(
    (object) => !arrayOfExistingObjectProperties.includes(object[filterByProperty])
  )
}

// Groups response containing all alert configs by manager username and converts to following format:
// [{label: 'manager 1', options: [key: 1, label: 'alert 1', value: 1, managerUsername: 'manager 1']}]
export const groupAlertConfigs = (alertConfigs) => {
  const alertConfigOptions = alertConfigs.map(
    ({ active_alert_config_version_id, id, cids, name, manager_name, type }) => ({
      key: id,
      label: name,
      value: id,
      acvId: active_alert_config_version_id,
      alertType: type,
      cids,
      managerName: manager_name,
    })
  )
  const alertConfigsGrouped = groupBy(alertConfigOptions, 'managerName')
  return Object.keys(alertConfigsGrouped)
    .sort((a, b) => a.localeCompare(b))
    .map((id) => ({
      label: id,
      options: alertConfigsGrouped[id],
    }))
}

export const getAdvancedExportsFilters = (filters) => {
  return {
    dateRange: filters.dateRange,
    managers: filters.managers,
    agents: filters.agents,
    tags: filters.tags,
    playbooksAdvancedExports: filters.playbooksAdvancedExports,
    alertTypes: filters.alertTypes,
    alertNames: filters.alertNames,
    triggers: filters.triggers,
    triggerTypes: filters.triggerTypes,
    interactions: filters.interactions,
  }
}
