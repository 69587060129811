import { endOfToday, startOfToday } from 'date-fns'
import produce from 'immer'

// Action Types
export const SET_DATA = 'realtimeCoaching/setData'
export const SET_FILTER = 'realtimeCoaching/setFilter'
export const SET_DATE_RANGE_FILTERS = 'realtimeCoaching/setDateRangeFilters'
export const SET_LOADING = 'realtimeCoaching/setLoading'

// Action Creators
export const setData = (payload) => ({ type: SET_DATA, payload })
export const setFilter = (payload) => ({ type: SET_FILTER, payload })
export const setDateRangeFilters = (payload) => ({ type: SET_DATE_RANGE_FILTERS, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })

export const initialState = {
  data: {
    agents: [],
    alertNames: [],
    groupBy: 'day', // 'day, 'week', 'month', or 'quarter'
    interactions: [
      { value: 'chat', label: 'Chat' },
      { value: 'listen', label: 'Listen' },
      { value: 'acknowledgement', label: 'Acknowledgement' },
    ],
    managers: [],
    playbooksAdvancedExports: [],
    tags: [],
    triggers: [],
    triggerTypes: [
      { value: 'occurrence', label: 'Occurrence' },
      { value: 'absence', label: 'Absence' },
    ],
    userAlertNames: [],
    alertStatus: [
      { value: 'pending', label: 'Pending' },
      { value: 'active', label: 'Active' },
      { value: 'deactivated', label: 'Deactivated' },
    ],
  },
  filters: {
    agents: [],
    alertNames: [],
    alertTypes: [],
    baltoAdminManager: null, // manager dropdown for viewing other orgs
    dateRange: {
      startDate: startOfToday(new Date()),
      endDate: endOfToday(new Date()),
    },
    nullableDateRange: {
      startDate: null,
      endDate: null,
    },
    dateRangeIsValid: true,
    nullableDateRangeIsValid: true,
    displayGroupings: [],
    displayMetrics: [
      {
        value: 'fired_alerts',
        label: 'Fired alerts',
        accessor: 'fired_alerts',
      },
      { value: 'chats', label: 'Chats', accessor: 'chats' },
      { value: 'listens', label: 'Listens', accessor: 'listens' },
      {
        value: 'acknowledgements',
        label: 'Acknowledgements',
        accessor: 'acknowledgements',
      },
    ],
    groupBy: 'day', // 'day', 'week', 'month', or 'quarter
    interactions: [],
    managers: [], // manager select dropdown in advanced exports
    organization: null,
    playbooksAdvancedExports: [],
    tags: [],
    triggers: [],
    triggerTypes: [],
    userAlertNames: [],
    assignedTo: [],
    createdBy: [],
    alertStatus: [],
  },
  loading: {
    // Filters
    agents: false,
    alertNames: false,
    interactions: false,
    managers: false,
    organization: false,
    playbooksAdvancedExports: false,
    tags: false,
    triggers: false,
    triggerTypes: false,
    userAlertNames: false,
  },
}

export default function realtimeCoachingReducer(state = initialState, action) {
  return produce(state, (newState) => {
    switch (action.type) {
      case SET_DATA:
        newState.data = { ...state.data, ...action.payload }
        break

      case SET_FILTER:
        newState.filters = { ...state.filters, ...action.payload }
        break

      case SET_DATE_RANGE_FILTERS:
        newState.filters.dateRange = {
          startDate: action.payload.startDate || state.filters.dateRange.startDate,
          endDate: action.payload.endDate || state.filters.dateRange.endDate,
          text: action.payload.text,
        }
        break

      case SET_LOADING:
        newState.loading = { ...state.loading, ...action.payload }
        break

      default:
        break
    }
  })
}
