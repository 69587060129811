import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Loader } from 'semantic-ui-react'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

import { apiService } from '@/api'
import { TextField } from '@/components/forms/formik'
import { Banner } from '@/components/banners/Banner'

import { AuthContainer } from './AuthContainer'
import { AuthMessage } from './AuthMessage'
import { PasswordRequirement } from './PasswordRequirement'

import { updateAuthMessage, clearAuthMessage } from '../../reducers/ui/ui.redux'
import { checkPasswordRequirements, isButtonDisabled } from './helpers'

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required.'),
  confirmPassword: Yup.string().required('Confirm password is required.'),
})

const NewPasswordPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { temporaryLink } = useParams()
  const { authMessage } = useSelector((state) => state.ui)
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const getUserFromTemporaryLink = async () => {
    try {
      const response = await fetch(`${apiService.web}/api/temporary_links/${temporaryLink}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })

      if (response.status !== 200) {
        throw new Error('Expired link')
      }
      return response.json()
    } catch (err) {
      throw new Error(err)
    }
  }

  const handleCreateNewPassword = async ({ password }) => {
    const userData = {
      id: user.id,
      username: user.username,
      passphrase: password,
      tempLink: temporaryLink,
    }
    setLoading(true)

    try {
      await fetch(`${apiService.web}/api/temporary_links/${temporaryLink}`, {
        method: 'PATCH',
        body: JSON.stringify(userData),
        headers: { 'Content-Type': 'application/json' },
      })
      dispatch(updateAuthMessage('success', 'Successfully updated password! Redirecting...'))
      setTimeout(() => {
        history.push('/login')
      }, 2000)
    } catch (err) {
      console.error(err)
      dispatch(updateAuthMessage('error', 'Unable to generate new password.'))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    dispatch(clearAuthMessage())
    setLoading(true)

    getUserFromTemporaryLink()
      .then((userFromTemporaryLink) => {
        setUser(userFromTemporaryLink)
      })
      .catch((err) => {
        console.error(err)
        dispatch(updateAuthMessage('error', 'User is not found or link is invalid/expired.'))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <>
      <Helmet>
        <title>Balto Cloud | New Password</title>
      </Helmet>

      {loading ? (
        <AuthContainer>
          <Loader active data-testid="loading" />
        </AuthContainer>
      ) : (
        <AuthContainer>
          <h2 data-testid="new-password-page">Create New Password</h2>
          {user && <Banner>{user.username}</Banner>}
          {!authMessage.text && (
            <Formik
              initialValues={{ confirmPassword: '', password: '' }}
              validationSchema={validationSchema}
              onSubmit={handleCreateNewPassword}
            >
              {({ handleSubmit, values, errors }) => {
                const requirements = checkPasswordRequirements(values)

                return (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <Field
                        name="password"
                        component={TextField}
                        placeholder="Password"
                        label="Password"
                        type="password"
                      />
                      <Field
                        name="confirmPassword"
                        component={TextField}
                        placeholder="Confirm Password"
                        label="Confirm Password"
                        type="password"
                      />
                      <Button
                        primary
                        fluid
                        type="submit"
                        onClick={handleSubmit}
                        disabled={isButtonDisabled(errors, requirements)}
                      >
                        Create New Password
                      </Button>
                    </Form>
                    <div style={{ marginTop: '1rem' }}>
                      <h4>Password Requirements</h4>
                      <PasswordRequirement requirements={requirements} criteria="match">
                        Password and confirm password must match
                      </PasswordRequirement>
                      <PasswordRequirement requirements={requirements} criteria="minChars">
                        Must contain at least 10 characters
                      </PasswordRequirement>
                      <PasswordRequirement requirements={requirements} criteria="charTypes">
                        Must have three of the following:
                        <ul style={{ marginTop: '0' }}>
                          <li>at least 1 uppercase character</li>
                          <li>at least 1 lowercase character</li>
                          <li>at least 1 symbol</li>
                          <li>at least 1 number</li>
                        </ul>
                      </PasswordRequirement>
                    </div>
                  </>
                )
              }}
            </Formik>
          )}
          <AuthMessage />

          <Link to="/login" className="link">
            Back to Login
          </Link>
        </AuthContainer>
      )}
    </>
  )
}

export default NewPasswordPage
