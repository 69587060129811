import moment from 'moment'

export const calculateDuration = (startTime, endTime) => {
  const start = moment.utc(startTime)
  const now = moment.utc(endTime)
  const duration = moment.duration(now.diff(start))
  const milliseconds = duration.asMilliseconds()

  // Occasionally it will be a negative time initially due to race condition
  if (milliseconds < 1) {
    return '00:00'
  }

  // One hour
  if (milliseconds >= 3_600_000) {
    return moment.utc(duration.asMilliseconds()).format('H:mm:ss')
  }

  return moment.utc(duration.asMilliseconds()).format('mm:ss')
}

export const calculateTimeElapsedStatus = (agent) => {
  if (!agent) {
    return null
  }

  if (agent.call_data?.call_status === 'on_call') {
    return calculateDuration(agent.call_data.call_start_time)
  }

  if (agent.call_data?.call_status === 'had_calls_today') {
    return calculateDuration(agent.call_data.call_start_time, agent.call_data.call_end_time)
  }

  return null
}

export const getTimeElapsedStatus = (agent, timeElapsed) => {
  if (!agent) {
    return null
  }

  if (agent.call_data?.call_status === 'on_call') {
    return timeElapsed
  }

  if (agent.call_data?.call_status === 'had_calls_today') {
    return `Call Duration ${timeElapsed}`
  }

  return 'No calls today'
}
