import React from 'react'

import { AnalyticsCards } from '../AnalyticsCards'
import Widget from '../../../components/widget/Widget'
import { ChecklistUsageWidget } from '../widgets/ChecklistUsageWidget'
import { TopWidget } from '../widgets/TopWidget'

export const OverviewSection = () => {
  return (
    <div data-testid="reports-summary">
      <AnalyticsCards />

      <Widget
        widgetId="overview-checklistUsage"
        label="Checklist Usage"
        subtitle="Time series of the count of checklist items usage across selected agents"
        subtitlePercent="Time series of average checklist usage across selected agents"
        showGroupBy
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ChecklistUsageWidget />
      </Widget>

      <Widget
        widgetId="overview-topDeckItems"
        label="Top Dynamic Prompts"
        subtitle="The count of the 5 most frequent dynamic prompts used across selected agents"
        subtitlePercent="Average usage of the 5 most common dynamic prompts across selected agents"
        showPercent
        showViewToggle
        showCsvDownload
      >
        <TopWidget accessor="deck_item" label="Dynamic Prompts" />
      </Widget>

      <Widget
        widgetId="overview-topNotifications"
        label="Top Notifications"
        subtitle="The count of times the 5 most frequent notifications were triggered, across selected agents"
        subtitlePercent="Average percentage of calls the 5 most common notifications were triggered on, across selected agents"
        showPercent
        showViewToggle
        showCsvDownload
      >
        <TopWidget accessor="notification" label="Notifications" />
      </Widget>
    </div>
  )
}
