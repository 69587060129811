import React from 'react'
import { Loader, Segment } from 'semantic-ui-react'
import NoData from '@/components/NoData'

const ScorecardGraphContainer = ({ loading, noData, graph, title, subTitle, dataTestId }) => {
  const renderWidget = () => {
    if (loading) {
      return (
        <div data-testid="widget-loading" className="loading-container">
          <Loader active inline />
        </div>
      )
    }

    if (noData) {
      return (
        <div className="empty-table">
          <NoData />
        </div>
      )
    }

    return graph
  }

  return (
    <Segment data-testid={dataTestId} className="scorecard-dashboard_graph-container">
      <h4>{title}</h4>
      {subTitle}
      {renderWidget()}
    </Segment>
  )
}

export default ScorecardGraphContainer
