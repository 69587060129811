import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { capitalize } from 'lodash'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import {
  fetchTopAlertConfigs,
  fetchTopAlertingAgents,
  fetchTopSupervisorChatOccurrences,
  fetchTopListenerSupervisors,
} from '@/reducers/realtime/reports/realtimeReports.actions'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { overviewTableColumns } from '@/views/RealtimeCoaching/Reports/constants'
import { formatReportsActivityTableRows } from './ReportsHelpers'
import Select from '../../../components/forms/Select'

export const ReportsActivityTable = ({
  tableKey,
  tableType,
  description,
  alerts,
  drilldownUrlResource,
  includeAgentTagFilter = false,
  hideDrilldownButton = false,
}) => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => state)
  const { filters } = useSelector((state) => state.realtimeCoaching)
  const { dateRange } = filters
  const loading = useSelector((state) => state.realtimeReports.loading[tableKey])
  const filterButtonClicked = useSelector(
    (state) => state.realtimeReports.loading.callAlertPercentage
  )
  const [tableFilter, setTableFilter] = useState('descending')
  const [tableResource, setTableResource] = useState('agents')

  const [columns, setColumns] = useState(overviewTableColumns[tableKey])
  const filterByOptions = [
    { value: 'descending', label: 'Top 5' },
    { value: 'ascending', label: 'Bottom 5' },
  ]
  const resourceOptions = [
    { value: 'agents', label: 'By Agent' },
    { value: 'tags', label: 'By Tag' },
  ]

  const rows = useMemo(() => {
    return formatReportsActivityTableRows(alerts, tableType, tableFilter, tableKey)
  }, [alerts])

  // Updates the part of the state that was changed by the dropdown
  const handleDropdownChange = (value, type) => {
    if (type === 'filter') {
      setTableFilter(value)
    } else if (type === 'resource') {
      setTableResource(value)
    }
  }

  // Preps the filters for DrillDownReports based on which graph it was clicked from
  const prepFilters = () => {
    if (drilldownUrlResource === 'agent') {
      dispatch(setFilter({ alertTypes: [{ value: tableType, label: capitalize(tableType) }] }))
    } else {
      dispatch(setFilter({ alertTypes: [] }))
    }

    if (tableResource === 'tags') {
      dispatch(setFilter({ agents: [] }))
    } else if (tableResource === 'agents') {
      dispatch(setFilter({ tags: [] }))
    }
  }

  // Fetches new data whenever one of the dropdowns changes values
  useEffect(() => {
    if (tableKey === 'topAlertConfigs') {
      dispatch(
        fetchTopAlertConfigs({
          limit: 5,
          order: tableFilter,
          endDate: dateRange.endDate,
          startDate: dateRange.startDate,
          requestedOrgId: filters.organization || currentUser.organizationid,
        })
      )
    } else if (tableKey === 'topChattingSupervisors') {
      dispatch(
        fetchTopSupervisorChatOccurrences({
          limit: 5,
          order: tableFilter,
          endDate: dateRange.endDate,
          startDate: dateRange.startDate,
          requestedOrgId: filters.organization || currentUser.organizationid,
        })
      )
    } else if (tableKey === 'topListenerSupervisors') {
      dispatch(
        fetchTopListenerSupervisors({
          limit: 5,
          order: tableFilter,
          endDate: dateRange.endDate,
          startDate: dateRange.startDate,
          requestedOrgId: filters.organization || currentUser.organizationid,
        })
      )
    }
    // tableKey is either 'topAlertingAgentsNegative', 'topAlertingAgentsPositive', or 'topAlertingAgentsInformative'
    else {
      dispatch(
        fetchTopAlertingAgents({
          limit: 5,
          order: tableFilter,
          resource: tableResource,
          alertType: tableType,
          endDate: dateRange.endDate,
          startDate: dateRange.startDate,
          requestedOrgId: filters.organization || currentUser.organizationid,
        })
      )
    }
  }, [tableFilter, tableResource])

  // Updates the columns state whenever our table resource changes
  useEffect(() => {
    if (!includeAgentTagFilter) {
      return
    }
    const state = [...columns]
    columns[1].label = tableResource === 'agents' ? 'Agent' : 'Tag'
    setColumns(state)
  }, [tableResource])

  // when you load new data with filter button it loads with default
  // of agents and descending so if they hit the filter button we need
  // to reset the dropdowns
  useEffect(() => {
    setTableFilter('descending')
    setTableResource('agents')
  }, [filterButtonClicked])

  return (
    <div className="reports-activity-card" data-testid="reports-activity-card">
      <div className="reports-activity-card-header">
        <div className="reports-activity-card-header-text">
          <h4>{description}</h4>
          {!hideDrilldownButton && (
            <Link
              className="ui button secondary"
              data-testid="view-report-button"
              to={{
                pathname: `/realtime_coaching/reports/${drilldownUrlResource}_reports`,
                state: {
                  resource: drilldownUrlResource,
                  tableFilter,
                  tableType,
                },
              }}
              onClick={() => {
                prepFilters()
              }}
            >
              View Report
            </Link>
          )}
        </div>
        <div className="reports-activity-card-filters">
          <Select
            className="reports-activity-card-filter"
            options={filterByOptions}
            value={tableFilter}
            isClearable={false}
            onChange={({ value }) => {
              handleDropdownChange(value, 'filter')
            }}
          />
          {includeAgentTagFilter && (
            <Select
              className="reports-activity-card-filter"
              options={resourceOptions}
              value={tableResource}
              isClearable={false}
              onChange={({ value }) => {
                handleDropdownChange(value, 'resource')
              }}
            />
          )}
        </div>
      </div>
      <AdvancedTable
        index="uuid"
        rows={rows || []}
        columns={columns}
        loading={loading}
        hoverable={false}
      />
    </div>
  )
}
