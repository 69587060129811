import { isEmpty } from 'lodash'

export const operations = {
  '>': (a, b) => a > b,
  '>=': (a, b) => a >= b,
  '<': (a, b) => !!a && a < b,
  '<=': (a, b) => !!a && a <= b,
  '===': (a, b) => a === b,
  '!==': (a, b) => a !== b,
  includes: (a, b) => a.find((item) => item.value === b),
  // eslint-disable-next-line no-unused-vars
  exists: (a, _) => (typeof a !== 'object' ? !!a : !isEmpty(a)),
}

export const showDependentField = (schemaItem, formValues) => {
  // dependencies is not camelcase because it throws a react prop error
  for (const dependency of schemaItem.dependencies) {
    const { operator = 'exists', key, value: desiredValue } = dependency

    const currentValue = formValues[key]

    if (!operations[operator](currentValue, desiredValue)) {
      return false
    }
  }

  return true
}
