import React, { useContext, useEffect } from 'react'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import WidgetContext from '@/components/widget/widget.context'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { capitalize } from 'lodash'

export const DrillDownTable = () => {
  const data = useSelector((state) => state.realtimeReports.data.drillDownTable)
  const groupBy = useSelector((state) => state.realtimeReports.data.groupBy)
  const loading = useSelector((state) => state.realtimeReports.loading.drillDownTable)
  const selectedDateRange = useSelector((state) => state.realtimeCoaching.filters.dateRange)
  const date = `Date [${capitalize(groupBy)}]`
  const location = useLocation()
  const { resource } = location.state
  const { handleChangeCsvData } = useContext(WidgetContext)

  const getColumns = () => {
    if (resource === 'agent') {
      return [
        { accessor: 'name', label: 'Agent' },
        { accessor: 'date', label: date },
        { accessor: 'alert_type', label: 'Alert Type' },
        { accessor: 'alert_name', label: 'Alert Name' },
        { accessor: 'count', label: 'Alert Count' },
      ]
    }
    if (resource === 'tag') {
      return [
        { accessor: 'tag_name', label: 'Tag' },
        { accessor: 'date', label: date },
        { accessor: 'alert_type', label: 'Alert Type' },
        { accessor: 'alert_name', label: 'Alert Name' },
        { accessor: 'count', label: 'Alert Count' },
      ]
    }
    if (resource === 'alert') {
      return [
        { accessor: 'alert_name', label: 'Alert Name' },
        { accessor: 'date', label: date },
        { accessor: 'alert_type', label: 'Alert Type' },
        { accessor: 'count', label: 'Alert Count' },
      ]
    }
    if (resource === 'chat') {
      return [
        { accessor: 'supervisor', label: 'Supervisor' },
        { accessor: 'date', label: date },
        { accessor: 'alert_type', label: 'Alert Type' },
        { accessor: 'alert_name', label: 'Alert Name' },
        { accessor: 'count', label: 'Number of Chats' },
      ]
    }
    if (resource === 'listen') {
      return [
        { accessor: 'supervisor', label: 'Supervisor' },
        { accessor: 'date', label: date },
        { accessor: 'alert_type', label: 'Alert Type' },
        { accessor: 'alert_name', label: 'Alert Name' },
        { accessor: 'listens', label: 'Number of listens' },
      ]
    }
    return []
  }

  const columns = getColumns()

  const formatData = (data) => {
    return data.map((obj) => {
      return { ...obj, date: moment(obj.date).format('YYYY/MM/DD') }
    })
  }
  // don't add selectedDateRange to dep array, otherwise the date range will be currently
  // selected range not the range picked when data was pulled. using stale closure to our advantage
  useEffect(() => {
    const downloadColumns = getColumns()

    // The Agent Drilldown Reports will include an email address only when downloading the CSV
    if (resource === 'agent') {
      downloadColumns.splice(1, 0, { accessor: 'email', label: 'Email' })
    }

    handleChangeCsvData({
      data: formatData(data),
      headers: downloadColumns,
      filters: selectedDateRange,
      fileName: 'Alert Trends',
    })
  }, [data])

  return (
    <div className="drilldown-table">
      <AdvancedTable
        loading={loading}
        columns={columns}
        defaultOrder="asc"
        defaultOrderBy="date"
        rows={formatData(data)}
        rowsPerPage={10}
        pagination
        wrapColumnContent
        hoverable={false}
        index="uuid"
      />
    </div>
  )
}
