import React from 'react'
import classNames from 'classnames'

import { useShortcut } from '@/utils/hooks/useShortcut'

export const Shortcut = ({ shortcut, isExpanded }) => {
  const alwaysVisible = shortcut.visible

  useShortcut(shortcut.value, shortcut.handleShortcut)

  return (
    <div
      data-testid={`shortcut-${shortcut.value}`}
      className={classNames('shortcut', {
        visible: alwaysVisible,
        'fade-in': !alwaysVisible && isExpanded === true,
        'fade-out': !alwaysVisible && isExpanded === false,
      })}
    >
      <div>{shortcut.label}</div>
      <div className="keyboard-value">{shortcut.value}</div>
    </div>
  )
}
