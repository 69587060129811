import React, { useEffect, useState } from 'react'
import { Button, Card, Icon, Loader, Popup, Table } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import classNames from 'classnames'

import { fetchCriteriaScoresByMeasureId } from '@/reducers/scorecards/scores.actions'

import { fetchPlaybookById } from '@/reducers/scorecards/scorecards.actions'
import { NestedTable } from '@/components/tables/NestedTable/NestedTable'
import { Pill } from '@/components/pills/Pill'

import { calculatePercentage } from '../CallScoreCarousel/helpers'
import { sortCriteriaByPlaybookSections, flattenCopilotSection } from '../helpers/index'

import './measureScoresTable.css'

const criteriaCellClasses = (criteria) => {
  const isDecklist = Object.keys(criteria).includes('decklist_triggered')
  const decklistTriggered = isDecklist && criteria.decklist_triggered
  const countMissed = decklistTriggered || !isDecklist

  return criteria.condition_type === 'AND' && countMissed
    ? {
        span: criteria.hit ? 'hit' : 'missed',
        icon: criteria.hit ? 'check circle' : 'times circle',
      }
    : {
        span: criteria.hit ? 'hit' : 'informative',
        icon: criteria.hit ? 'check circle' : 'minus circle',
      }
}

export const createCriteriaCell = (criteria) => {
  const classes = criteriaCellClasses(criteria)

  return (
    <Table.Cell
      data-testid="criteria-name-cell"
      className={classNames({
        'status-success': classes.span === 'hit',
        'status-critical': classes.span === 'missed',
        'status-informative': classes.span === 'greyed',
      })}
    >
      <Icon name={classes.icon} />
      {criteria.name}
    </Table.Cell>
  )
}

export const createBasicCriteriaCell = (criteria) => {
  const classes = criteriaCellClasses(criteria)

  return (
    <span
      data-testid="criteria-name-cell"
      className={classNames({
        'status-success': classes.span === 'hit',
        'status-critical': classes.span === 'missed',
        'status-informative': classes.span === 'greyed',
      })}
    >
      <Icon name={classes.icon} />
      {criteria.name}
    </span>
  )
}

export const UnknownSectionCriteriaTable = ({ missingCriteria, cellFunction }) => {
  return (
    <div data-testid="criteria-nested-section-table">
      <Table basic="very" className="measure-scores-table" style={{ marginTop: 0 }}>
        <Table.Body>
          {missingCriteria.map((criteria) => (
            <Table.Row key={criteria.id}>
              {Object.keys(criteria).includes('hit') ? (
                cellFunction(criteria)
              ) : (
                <Table.Cell data-testid="criteria-name-cell">{criteria.name}</Table.Cell>
              )}
              <Table.Cell collapsing>
                <Button
                  disabled
                  compact
                  data-testid="criteria-must-be-present-button"
                  content={criteria.must_be_present ? 'Must Be Present' : 'Must Not Be Present'}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <Button disabled compact content={criteria.weight.value || criteria.weight} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

export const tablesByPlaybookSection = (playbookSection, sectionLogic) => {
  const sectionHasAndLogic = sectionLogic?.condition_type === 'AND'

  const columns = [
    {
      label: 'Name',
      accessor: 'name',
    },
    {
      label: 'Present or Not?',
      accessor: 'must_be_present',
    },
  ]

  if (sectionHasAndLogic) {
    columns.push({
      label: 'Weight',
      accessor: 'weight',
    })
  }
  const possibleTables = {
    Postcall: (playbookSection) => (
      <NestedTable
        columns={columns}
        rows={playbookSection.criteria}
        flattenedHeader="Post Call"
        basic
        noHeaders
      />
    ),
    Copilot: (playbookSection) => (
      <NestedTable
        columns={columns}
        rows={playbookSection.criteria}
        flattenedHeader="Copilot"
        basic
        noHeaders
      />
    ),
    Checklist: (playbookSection) => (
      <NestedTable
        columns={columns}
        rows={playbookSection.criteria}
        flattenedHeader="Checklist"
        basic
        noHeaders
      />
    ),
    Notifications: (playbookSection) => (
      <NestedTable
        columns={columns}
        rows={playbookSection.criteria}
        flattenedHeader="Notifications"
        basic
        noHeaders
      />
    ),
    Deck: (playbookSection) => (
      <NestedTable
        columns={columns}
        rows={playbookSection.criteria}
        flattenedHeader="Deck"
        basic
        noHeaders
      />
    ),
  }

  return possibleTables[playbookSection.sectionName](playbookSection)
}

export const CriteriaScoresTable = ({ measure, setOpen, copilot }) => {
  const { data, loading } = useSelector((state) => state.scorecards)
  if (loading.criteriaScoresByMeasure || loading.targetPlaybook) return <Loader />
  if (isEmpty(data.criteriaScoresByMeasure) || isEmpty(data.targetPlaybook)) return null
  let criteriaByPlaybookSection = []
  if (copilot === false) {
    criteriaByPlaybookSection = sortCriteriaByPlaybookSections(
      data.criteriaScoresByMeasure,
      data.targetPlaybook.body
    )
  } else {
    criteriaByPlaybookSection = flattenCopilotSection(data.criteriaScoresByMeasure)
  }

  return (
    <Card
      className="criteria-scores-summary__popup-card"
      data-testid="criteria-scores-summary__popup-card"
    >
      <Card.Content className="criteria-scores-summary__popup-card-header">
        <h3>{measure.name}</h3>
        <AndOrLogicLabel measureScore={data.criteriaScoresByMeasure || {}} />
        <AutoFailZeroLabel measureScore={measure} />
        <Button
          icon
          onClick={() => setOpen(false)}
          data-testid="basic-modal-close-button"
          className="basic-modal__close-button"
        >
          <Icon name="close" />
        </Button>
      </Card.Content>
      <Card.Content className="criteria-scores-summary__content-container">
        {criteriaByPlaybookSection.map((playbookSection) =>
          tablesByPlaybookSection(playbookSection, data.criteriaScoresByMeasure)
        )}
      </Card.Content>
    </Card>
  )
}

export const MeasureScoresRow = ({ measureScore }) => {
  if (!measureScore) return null

  return (
    <Table.Row className="measure-scores-table__table-row">
      <Table.Cell>{measureScore.name}</Table.Cell>
      <Table.Cell className="measure-scores-table__td-number">
        {measureScore.auto_failed || measureScore.auto_zeroed ? (
          <AutoFailZeroLabel measureScore={measureScore} />
        ) : (
          `${calculatePercentage(measureScore.aggregate_score, measureScore.possible_score)}%`
        )}
      </Table.Cell>
    </Table.Row>
  )
}

export const CriteriaScorePopup = ({ measureScore, copilot }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const { data } = useSelector((state) => state.scorecards)
  useEffect(() => {
    if (open) {
      if (data.currentScore.id && measureScore.id) {
        dispatch(fetchCriteriaScoresByMeasureId(data.currentScore.id, measureScore.id))
        dispatch(fetchPlaybookById(data.currentScore.config_id))
      }
    }
  }, [open])

  if (!measureScore) return null

  return (
    <Popup
      pinned
      positionFixed
      className="criteria-scores-summary__popup"
      data-testid="criteria-scores-summary__popup"
      on="click"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      position="top center"
      trigger={
        <Table.Cell>
          <a
            data-testid="criteria-scores-summary__popup-trigger"
            className={`criteria-scores-summary__name-link ${open ? 'open' : ''}`}
          >
            {measureScore.name}
          </a>
        </Table.Cell>
      }
    >
      <CriteriaScoresTable measure={measureScore} setOpen={setOpen} copilot={copilot} />
    </Popup>
  )
}

export const AutoFailZeroLabel = ({ measureScore }) => {
  if (!measureScore.auto_failed && !measureScore.auto_zeroed) return null

  if (measureScore.auto_failed) {
    return (
      <Pill small critical data-testid="autofail-badge-measure">
        Auto-fail
      </Pill>
    )
  }

  return (
    <Pill small caution data-testid="autozero-badge-measure">
      Auto-zero
    </Pill>
  )
}

export const AndOrLogicLabel = ({ measureScore }) => {
  if (!measureScore || !measureScore.condition_type) return null

  if (measureScore.condition_type === 'AND') {
    return (
      <Pill small emphasized data-testid="and-logic-badge">
        AND Logic
      </Pill>
    )
  }

  if (measureScore.condition_type === 'OR') {
    return (
      <Pill small emphasized data-testid="or-logic-badge">
        OR Logic
      </Pill>
    )
  }

  return null
}

export const MeasureScoresTable = ({
  measureScores = [],
  canViewCriteria = false,
  copilot = false,
}) => {
  return (
    <Table celled striped className="measure-scores-table" data-testid="measure-scores-table">
      <Table.Body>
        {measureScores.map((measureScore) => (
          <Table.Row className="measure-scores-table__table-row" key={measureScore.id}>
            {/* first cell will not be clickable in aggregation */}
            {canViewCriteria ? (
              <CriteriaScorePopup measureScore={measureScore} copilot={copilot} />
            ) : (
              <Table.Cell>{measureScore.name}</Table.Cell>
            )}
            <Table.Cell className="measure-scores-table__td-number">
              {measureScore.auto_failed || measureScore.auto_zeroed ? (
                <AutoFailZeroLabel measureScore={measureScore} />
              ) : (
                `${
                  measureScore.percentage_score ||
                  calculatePercentage(measureScore.aggregate_score, measureScore.possible_score)
                }%`
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}
