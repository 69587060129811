import React, { useState, useEffect } from 'react'
import { Form, Button, Loader } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty, isArray } from 'lodash'
import pluralize from 'pluralize'

import { deleteKeywordCheck } from '@/reducers/keywords/keywords.actions'

export const DeleteKeywordForm = ({ krid, onSubmit, modalClose }) => {
  const dispatch = useDispatch()
  const [associatedPlaybooks, setAssociatedPlaybooks] = useState([])
  const [fetched, setFetched] = useState(false)
  const [hasError, setHasError] = useState(false)
  const { loading } = useSelector((state) => state.keywords)
  const canDeleteKeyword = fetched && isEmpty(associatedPlaybooks) && !hasError

  const getAssociatedPlaybooks = async () => {
    try {
      const playbooks = await dispatch(deleteKeywordCheck(krid))

      setAssociatedPlaybooks(playbooks)
    } catch (err) {
      // This is necessary because this endpoint keeps returning 502
      setHasError(true)
    } finally {
      setFetched(true)
    }
  }

  useEffect(() => {
    getAssociatedPlaybooks()
  }, [])

  const getModalContent = () => {
    if (loading.deleteCheck || !fetched) {
      return (
        <Loader inline active className="modal-loader" data-testid="delete-keyword-loading">
          Checking for associated playbooks...
        </Loader>
      )
    }

    if (!isEmpty(associatedPlaybooks) && isArray(associatedPlaybooks)) {
      return (
        <div data-testid="delete-keyword-check-failed">
          <h3>This keyword repository cannot be deleted!</h3>
          <p>
            There are {pluralize('playbook', associatedPlaybooks.length, true)} associated with this
            keyword.
          </p>
          <p>Disassociate the keyword from the playbooks before deleting.</p>
          {associatedPlaybooks.map((playbook) => (
            <a
              key={playbook.name}
              rel="noopener noreferrer"
              style={{ display: 'block' }}
              target="_blank"
              href={`/playbooks/${playbook.id}`}
            >
              {playbook.name}
            </a>
          ))}
        </div>
      )
    }

    if (hasError) {
      return (
        <div data-testid="delete-keyword-error">
          There was an error attempting to get the associated playbooks. Please try again later.
        </div>
      )
    }

    return (
      <div data-testid="delete-keyword-message">
        Are you sure you want to delete this keyword repository?
      </div>
    )
  }

  return (
    <Form
      data-testid="delete-keyword-form"
      onSubmit={(event) => {
        event.preventDefault()

        onSubmit(krid)
        modalClose()
      }}
    >
      {getModalContent()}
      <div className="modal-footer">
        <Button secondary type="button" data-testid="cancel-button" onClick={modalClose}>
          Cancel
        </Button>
        {canDeleteKeyword && (
          <Button color="red" type="submit" data-testid="delete-keyword-button">
            Delete
          </Button>
        )}
      </div>
    </Form>
  )
}
