import React, { useMemo, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import WidgetContext from '../../../components/widget/widget.context'
import WidgetContainer from '../../../components/widget/WidgetContainer'
import WidgetTooltip from '../../../components/widget/WidgetTooltip'
import BarChart from '../../../components/charts/BarChart'
import { AdvancedTable } from '../../../components/tables/AdvancedTable/AdvancedTable'
import { formatPercent } from '../../../utils/helpers'
import { formatAverageUsageByUser, formatFullName } from './widgetHelpers'
import { AdvancedTablePagination } from '../../../components/tables/AdvancedTable/AdvancedTablePagination'

export const AverageUsageByUserWidget = ({ section }) => {
  const [activePage, setActivePage] = useState(1)
  const { data, loading } = useSelector((state) => state.analytics)
  const { isPercent, handleChangeCsvData, handleResetWidgetFilters } = useContext(WidgetContext)

  const itemAccessor = `${section}_item`
  const tableData = useMemo(() => {
    return formatAverageUsageByUser(
      itemAccessor,
      data[`${section}Items`],
      data[`${section}ItemsByUser`]
    )
  }, [data])

  const columns = useMemo(
    () => [
      {
        accessor: 'user',
        label: 'User',
        noWrap: true,
        format: (v) => formatFullName(v),
      },
      {
        label: 'Average Completion %',
        accessor: 'average_completion_percent',
        format: (v) => formatPercent(v),
      },
      // the following block is commented out for GOAT-603
      //      {
      //        label: 'Average Win Rate',
      //        accessor: 'average_win_percent',
      //        format: (v) => formatPercent(v),
      //      },
    ],
    [isPercent]
  )

  // Find pagination values
  const rowsPerPage = 20
  const count = tableData.length
  const totalPages = Math.ceil(count / rowsPerPage)
  const paginatedRows = useMemo(
    () => tableData.slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage).reverse(),
    [tableData, activePage]
  )

  useEffect(() => {
    handleChangeCsvData({ headers: columns, data: tableData })
  }, [tableData])

  useEffect(() => {
    handleResetWidgetFilters()
  }, [data])

  return (
    <WidgetContainer
      large
      withPagination
      loading={loading.overviewPage || loading.checklistPage}
      noData={isEmpty(data.checklistUsage)}
      table={
        <AdvancedTable
          index="user"
          defaultOrderBy="user"
          rows={tableData}
          columns={columns}
          rowsPerPage={10}
          pagination
          enableUpdateRowsPerPage={false}
        />
      }
      chart={
        <>
          <BarChart
            layout="horizontal"
            // the following line is commented out for GOAT-603
            // groupMode="grouped"
            enableGridX
            isPercent
            data={paginatedRows}
            padding={0.3}
            innerPadding={3}
            keys={['average_completion_percent']}
            // the following line is commented out for GOAT-603
            // keys={['average_completion_percent', 'average_win_percent']}
            indexBy="user"
            tooltip={(item) => (
              <WidgetTooltip color={item.color}>
                <h4>{formatFullName(item.data.user)}</h4>
                <p>
                  {item.id === 'average_completion_percent'
                    ? `${formatPercent(item.data.average_completion_percent)} average checklist
                    completion`
                    : `${formatPercent(item.data.average_win_percent)} average win rate`}
                </p>
              </WidgetTooltip>
            )}
            colorBy="id"
            colors={['#4795EC']}
            // The following line is commented out for GOAT-603
            // colors={['#4795EC', '#02C9B8']}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              padding: 0.5,
              tickPadding: 5,
              tickRotation: 0,
              format: (v) => formatPercent(v),
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: (v) => formatFullName(v),
            }}
            margin={{ bottom: 50, left: 150, right: 50, top: 20 }}
          />
          <AdvancedTablePagination
            activePage={activePage}
            rowsPerPage={rowsPerPage}
            count={count}
            totalPages={totalPages}
            setActivePage={setActivePage}
          />
        </>
      }
    />
  )
}
