import React, { useContext, useMemo } from 'react'
import WidgetContainer from '@/components/widget/WidgetContainer'
import LineChart from '@/components/charts/LineChart'
import { useSelector } from 'react-redux'
import { capitalize, isEmpty } from 'lodash'
import WidgetContext from '@/components/widget/widget.context'
import WidgetTooltip from '@/components/widget/WidgetTooltip'
import { useLocation } from 'react-router-dom'
import { getTickValues } from '@/components/charts/helpers'
import { flattenTableData } from '@/views/RealtimeCoaching/Reports/ReportsHelpers'

export const DrillDownAlertTrendsGraph = () => {
  const loading = useSelector((state) => state.realtimeReports.loading.drillDownGraph)
  const graphData = useSelector((state) => state.realtimeReports.data.drillDownGraph)
  const groupBy = useSelector((state) => state.realtimeReports.data.groupBy)
  const { hiddenItems } = useContext(WidgetContext)
  const location = useLocation()
  const { tableFilter, resource } = location.state || ''
  const tableFilterEnum = {
    ascending: 'bottomFive',
    descending: 'topFive',
  }
  const legendEnum = {
    alert: 'Alert Count',
    agent: 'Alert Count',
    chat: 'Chat Count',
    listen: 'Listens Count',
  }

  const dataWithHiddenItems = useMemo(() => {
    return graphData.filter((item) => !hiddenItems.has(item.id))
  }, [graphData, hiddenItems])
  const colors = useMemo(() => dataWithHiddenItems.map((data) => data.color), [dataWithHiddenItems])
  const tableData = useMemo(() => {
    return flattenTableData(dataWithHiddenItems)
  }, [dataWithHiddenItems])

  return (
    <WidgetContainer
      loading={loading}
      noData={isEmpty(graphData)}
      withSortedLegend
      tableFilter={tableFilterEnum[tableFilter]}
      dataForLegend={{ data: graphData }}
      legendAccessor="id"
      chart={
        <LineChart
          data={dataWithHiddenItems}
          colors={colors}
          tooltip={({ point }) => (
            <WidgetTooltip color={point.color}>
              <span>Date: </span>
              <strong>{point.data.x}</strong>
              <br />
              <span>{`${capitalize(resource)}: `}</span>
              <strong>{point.serieId}</strong>
              <br />
              <span>Alert Count: </span>
              <strong>{point.data.y}</strong>
            </WidgetTooltip>
          )}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: `Date [${capitalize(groupBy)}]`,
            legendOffset: 36,
            legendPosition: 'middle',
            tickValues: getTickValues(tableData, 'x'),
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: legendEnum[resource],
            legendOffset: -60,
            legendPosition: 'middle',
          }}
        />
      }
    />
  )
}
