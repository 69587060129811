import React from 'react'
import { Form } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import Select from '@/components/forms/Select'

import { getPlaybookVersionOptions } from '../playbook.helpers'

export const PlaybookVersionSelector = () => {
  const history = useHistory()
  const { section } = useParams()
  const {
    playbook: { id: playbookId },
    versions,
  } = useSelector((state) => state.playbook)

  const handleSelectPlaybookVersion = (value) => {
    history.push(`/playbooks/${value}/${section}`)
  }

  return (
    <Form data-testid="playbook-version-selector" className="playbook-version-selector">
      <Form.Field>
        <Select
          options={getPlaybookVersionOptions(versions)}
          value={playbookId}
          isClearable={false}
          onChange={({ value }) => {
            handleSelectPlaybookVersion(value)
          }}
          fixedWidth
        />
      </Form.Field>
    </Form>
  )
}
