import React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Grid } from 'semantic-ui-react'

import Widget from '@/components/widget/Widget'
import { truncateString } from '@/utils/helpers'
import { Card } from '@/components/cards/Card'
import { BarChartWithLayers } from '@/components/charts/customCharts/BarChartWithLayers'
import { getTickValues } from '@/components/charts/helpers'
import { colors } from '@/views/Scorecards/DashboardPage/helpers'
import ScorecardGraphContainer from '@/views/Scorecards/DashboardPage/GraphContainer'
import { AgentPerformanceChart } from '@/components/charts/PerformanceIndicatorBar/AgentPerformanceChart'

import { BarChartCard } from './BarChartCard'
import { OverviewCard } from './OverviewCard'
import { CoachingOpportunitiesTable } from './CoachingOpportunitiesTable'
import { TopInfractorsTable } from './TopInfractorsTable'
import { CopilotFilters } from '../components/CopilotFilters'

import './copilotDashboard.scss'

export const CopilotDashboardPage = () => {
  const { data, loading } = useSelector((state) => state.qaCopilot)

  const agentPerformanceColumns = [
    { accessor: 'rank', label: 'Rank', collapsing: true },
    { accessor: 'full_name', label: 'Agent', collapsing: true },
    { accessor: 'passing_average', label: '', headerAlignment: 'center', isSortable: false },
    { accessor: 'pass_rate', label: 'Pass Rate', collapsing: true },
    { accessor: 'passed_call_count', label: 'Passing Calls', collapsing: true },
    { accessor: 'failed_call_count', label: 'Failing Calls', collapsing: true },
  ]

  const cardStyle = { border: '1px solid var(--dark-knight-100)', minHeight: '140px' }

  return (
    <>
      <h1>Copilot Dashboard</h1>

      <CopilotFilters />

      <header className="secondary-header">
        <h2>Overview</h2>
      </header>

      <Grid>
        <Grid.Column width={4}>
          <Card>
            <Card style={cardStyle}>
              <OverviewCard
                title="Evaluated Calls"
                value={data.evaluatedCallData?.call_count}
                callIds={data.evaluatedCallData?.call_ids}
                loading={loading.evaluatedCallData}
                type="neutral"
                showLink
              />
            </Card>
            <Card style={cardStyle}>
              <OverviewCard
                title="Average Score"
                value={data.reporting.aggregate_scores.total}
                callIds={data.evaluatedCallData?.call_ids}
                loading={loading.reporting}
                isPercent
              />
            </Card>
            <Card style={cardStyle}>
              <OverviewCard
                title="Flagged Calls"
                callIds={data.failedCallData?.call_ids}
                value={data.failedCallData?.call_count}
                loading={loading.failedCallData}
                type="negative"
                showLink
              />
            </Card>
          </Card>
        </Grid.Column>
        <Grid.Column width={6}>
          <BarChartCard
            title="Non-Compliant Criteria"
            data={data.nonCompliantCriteria}
            loading={loading.nonCompliantCriteria}
            progressKey="call_count"
            columns={[
              { accessor: 'name', label: 'Agent' },
              { accessor: 'call_count', label: 'Calls', fullWidthRow: true },
            ]}
            maxDataValue={data.nonCompliantCriteria.reduce(
              (acc, val) => (acc > val.call_count ? acc : val.call_count),
              0
            )}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <BarChartCard
            title="Non-Compliant Agents"
            data={data.nonCompliantAgents}
            loading={loading.nonCompliantAgents}
            progressKey="call_count"
            columns={[
              { accessor: 'name', label: 'Agent' },
              { accessor: 'call_count', label: 'Calls', fullWidthRow: true },
            ]}
            maxDataValue={data.nonCompliantAgents.reduce(
              (acc, val) => (acc > val.call_count ? acc : val.call_count),
              0
            )}
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <CoachingOpportunitiesTable />
        </Grid.Column>
        <Grid.Column width={16}>
          <TopInfractorsTable />
        </Grid.Column>
        <Grid.Column width={16}>
          <Widget showCsvDownload label="Agent Performance" displayBackground={false}>
            <AgentPerformanceChart
              columns={agentPerformanceColumns}
              rows={data.reportingByUser.aggregate_scores_by_user || []}
              loading={loading.reportingByUser}
              defaultOrderBy="rank"
            />
          </Widget>
        </Grid.Column>
        <Grid.Column width={16}>
          <ScorecardGraphContainer
            loading={loading.reportingByDay}
            dataTestId="scores-by-day-chart"
            title="Score Trends"
            subTitle="All Scorecards"
            noData={isEmpty(data.reportingByDay.aggregates)}
            graph={
              <div style={{ height: '364px' }}>
                <BarChartWithLayers
                  data={data.reportingByDay.aggregates}
                  secondaryChartData={data.reportingByDay.calls}
                  secondaryChartDataKey="y"
                  secondaryChartType="line"
                  tooltipDescription="Total Calls"
                  withCustomLegend
                  withCustomAxis
                  keys={['QA Score']}
                  indexBy="day"
                  layout="vertical"
                  colors={[colors.bars]}
                  enableGridY
                  borderRadius={3}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    padding: 0.5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getTickValues(data.reportingByDay.aggregates, 'day'),
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    format: (v) => `${truncateString(v, 30)}%`,
                  }}
                />
              </div>
            }
          />
        </Grid.Column>
      </Grid>
    </>
  )
}

export default CopilotDashboardPage
