import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { Prompt, useParams, useHistory } from 'react-router-dom'
import { Button, Loader } from 'semantic-ui-react'

import { fetchScorecardConfigById } from '@/reducers/qa-copilot/qa-copilot.actions'
import { setScorecardConfig } from '@/reducers/qa-copilot/qa-copilot.redux'
import NoData from '@/components/NoData'
import { Banner } from '@/components/banners/Banner'
import { fetchModels } from '@/reducers/prompts/prompts.actions'

import { QACopilotNavigation } from './components/QACopilotNavigation'
import { QACopilotSidebar } from './components/QACopilotSidebar'
import { QACopilotEditor } from './components/QACopilotEditor'
import { addDefaultSectionHelper } from './QACopilot.helpers'
import { QACopilotCriteriaPointsModal } from './modals/QACopilotCriteriaPointsModal'
import { QACopilotSettingsModal } from './modals/QACopilotSettingsModal'

import './QACopilot.scss'

export const QACopilotPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, error, dirty, scorecardConfig } = useSelector((state) => state.qaCopilot)
  const { loading: loadingPrompt } = useSelector((state) => state.prompts)
  const { organizationid: organizationId, prompt_editor: isPromptEditor } = useSelector(
    (state) => state.currentUser
  )
  const isBaltoAdmin = organizationId === 1
  const canEditPrompts = isBaltoAdmin || isPromptEditor
  const { id } = useParams()

  useEffect(() => {
    if (canEditPrompts) {
      dispatch(fetchModels())
    }
  }, [])

  useEffect(() => {
    if (id && id !== 'create') {
      dispatch(fetchScorecardConfigById(id, canEditPrompts))
    }

    if (id === 'create') {
      dispatch(
        setScorecardConfig({
          name: 'New QA Copilot Scorecard',
          sections: [addDefaultSectionHelper()],
          organization_id: isBaltoAdmin ? '' : organizationId,
        })
      )
    }

    return () => {
      // Clear scorecard config in Redux on unmount
      dispatch(setScorecardConfig(null))
    }
  }, [id])

  if (loading.scorecardConfig || loadingPrompt.prompts) {
    return (
      <div className="empty loading-container" data-testid="qa-copilot-loading">
        <Loader active inline>
          Scorecard is loading...
        </Loader>
      </div>
    )
  }

  if (error.scorecardConfig) {
    return (
      <div className="empty-table" data-testid="qa-copilot-error">
        <NoData headerText="No Scorecard Found">
          <p>No QA Copilot scorecard was found with the provided ID.</p>

          <div className="flex vertical-center gap">
            <Button secondary onClick={() => history.push('/qa-copilot')}>
              Back to Scorecards
            </Button>
            <Button primary onClick={() => history.push('/qa-copilot/create')}>
              Create New Scorecard
            </Button>
          </div>
        </NoData>
      </div>
    )
  }

  const isOutdatedScorecard = scorecardConfig?.latest_scorecard_config_id !== scorecardConfig?.id

  return (
    <>
      <Prompt when={dirty} message="You have unsaved changes. Are you sure you want to leave?" />
      <QACopilotNavigation isOutdatedScorecard={isOutdatedScorecard} />
      <div className="qa-copilot-wrapper" data-testid="qa-copilot-page">
        {isOutdatedScorecard && (
          <Banner informative>
            <p>
              This scorecard is outdated. Please navigate to the newest version to make changes.
            </p>
            <Button
              primary
              onClick={() =>
                history.push(`/qa-copilot/${scorecardConfig.latest_scorecard_config_id}`)
              }
            >
              Go to Newest Version
            </Button>
          </Banner>
        )}
        <div className="qa-copilot-grid">
          <QACopilotSidebar />
          <QACopilotEditor />
        </div>
      </div>
      <QACopilotCriteriaPointsModal />
      <QACopilotSettingsModal isCreate={id === 'create'} />
    </>
  )
}

export default withLDConsumer()(QACopilotPage)
