import React, { useState } from 'react'

import { Pill } from '../pills/Pill'
import { Shortcut } from './Shortcut'

import './Shortcuts.scss'

export const Shortcuts = ({ shortcuts }) => {
  const [isExpanded, setIsExpanded] = useState(null) // no state until first click

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className="shortcuts-container">
      <div className="shortcuts">
        {shortcuts.map((shortcut) => (
          <Shortcut key={shortcut.value} shortcut={shortcut} isExpanded={isExpanded} />
        ))}
        <Pill
          dataTestId="shortcut-toggle"
          className="clickable"
          informative
          small
          onClick={toggleExpanded}
          alignIcon="right"
          style={{ pointerEvents: 'all' }}
        >
          {isExpanded ? 'Collapse Shortcuts' : 'All Shortcuts'}
        </Pill>
      </div>
    </div>
  )
}
