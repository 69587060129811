import React, { useContext } from 'react'
import { Popup } from 'semantic-ui-react'
import { AnimateTriggerItem } from './AlertWizard'
import './TriggerItem.css'

export const TriggerItem = ({ item, onItemRemoved, readOnly = false }) => {
  const timeConstrainedHidden = !(item && item.path && item.path.required_before)
  const animate = useContext(AnimateTriggerItem)
  const characterLimit = 80
  const isPopupDisabled = item.path.label.length < characterLimit
  const truncatedDescription = `${item.path.label.slice(0, characterLimit)}…`

  return (
    <div>
      <div className={`trigger-item-box${animate ? ' added' : ''}`}>
        <div className="trigger-item-name-box">
          <div className="trigger-name">{item.playbook.label}</div>
          <div className="trigger-name">{item.item.label}</div>
          <div className="trigger-name">
            {item.path.value.split('-') &&
              item.path.value.split('-').length > 2 &&
              item.path.value.split('-')[1].trim()}
          </div>
        </div>
        <div className="trigger-name-box ">
          <Popup
            data-testid="trigger-description-popup"
            position="top center"
            header={`${item.playbook.label} - ${item.item.label}`}
            content={
              <div className="trigger-description-popup">
                <p>{item.path.label}</p>
              </div>
            }
            trigger={
              <div className="trigger-description">
                {isPopupDisabled ? item.path.label : truncatedDescription}
              </div>
            }
            disabled={isPopupDisabled}
            key={item.path.label}
          />
        </div>
        <div className="trigger-all-width flex-space-between">
          <div className="flex">
            {!timeConstrainedHidden && (
              <>
                <div className="alert-wizard__time-constrained">
                  <div className="alert-wizard__time-constrained-text">Time Constrained</div>
                </div>
                <div className="footer-text">
                  Start of call - {Math.round(item.required_before / 60)} min(s)
                </div>
              </>
            )}
          </div>
          {!readOnly && (
            <div className="remove-box">
              <div
                onClick={() => {
                  onItemRemoved(item)
                }}
                className="remove-text"
              >
                Remove
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
