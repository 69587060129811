import { isNil } from 'lodash'

import { sharedScorecardParams } from '@/utils/helpers'

export const formatQACopilotFilters = (filterValues = {}, organizationId) => {
  const filterTypes = [
    { data: 'agents', key: 'agent_ids' },
    { data: 'tags', key: 'tag_ids' },
    { data: 'scorecards', key: 'scorecard_config_sids' },
    { data: 'dispositions', key: 'dispositions' },
  ]
  const params = new URLSearchParams()
  const orgId = filterValues.organizationId || organizationId

  sharedScorecardParams(filterTypes, { ...filterValues, scorecardType: 'copilot' }, params, orgId)

  if (!isNil(filterValues.threshold)) {
    params.set('threshold', filterValues.threshold)
  }

  return params.toString()
}
