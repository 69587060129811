import React from 'react'
import { Editor } from 'slate-react'
import Html from 'slate-html-serializer'
import { Block } from 'slate'

import {
  markHotkey,
  rules,
  insertImage,
  wrapInlineNode,
  renderMark,
  renderNode,
  unwrapInlineNode,
} from './helpers'

import Toolbar from './Toolbar'

const html = new Html({ rules })

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props)

    // TODO: Bad stuff. Make a new RichTextEditor for the new playbook editor
    this.state = {
      value: html.deserialize(this.props.field.value),
    }
    // certain blocks have no content and they cannot be edited for those blocks add void:true
    // normalize function responds to error code value with an action, in this case
    // insert p tag so we can edit underneath void blocks
    this.schema = {
      document: {
        last: { type: 'paragraph' },
        // eslint-disable-next-line consistent-return
        normalize: (editor, { code, node }) => {
          // eslint-disable-next-line default-case
          switch (code) {
            case 'last_child_type_invalid': {
              const paragraph = Block.create('paragraph')
              return editor.insertNodeByKey(node.key, node.nodes.size, paragraph)
            }
          }
        },
      },
      blocks: {
        image: {
          isVoid: true,
        },
        'horizontal-rule': {
          isVoid: true,
        },
      },
    }
    this.DEFAULT_NODE = 'paragraph'

    this.ref = (editor) => {
      this.editor = editor
    }

    this.plugins = [
      markHotkey({ key: 'b', type: 'bold' }),
      markHotkey({ key: 'i', type: 'italic' }),
    ]

    this.onChange = ({ value }) => {
      this.setState({ value })
    }
    // this handles toggling mark type creation from clicking a mark button bold/italic
    this.createMark = (event, type) => {
      event.preventDefault()
      this.editor.toggleMark(type)
    }
    this.createBlock = this.createBlock.bind(this)
    this.createInlineNode = this.createInlineNode.bind(this)
    this.selectionHasElement = this.selectionHasElement.bind(this)
  }

  unwrapNestedBlock(overrideBlock) {
    // if you select the same block you're on,
    // this resets the nested block to a p
    const { editor } = this

    editor
      .setBlocks(overrideBlock || this.DEFAULT_NODE)
      .unwrapBlock('inline-verbatim-container')
      .unwrapBlock('bulleted-list')
      .unwrapBlock('numbered-list')
  }

  rewrapNestedBlocks(slateObjectType, childObjectType) {
    const { editor } = this
    this.unwrapNestedBlock(childObjectType)
    editor.wrapBlock(slateObjectType)
  }

  createInlineVerbatim(slateObjectType) {
    const { editor } = this
    // Handle the extra wrapping required for creating a list
    const isPhraseMatchItem = this.selectionHasElement('node', 'inline-verbatim')
    const isType = this.selectionHasParentElement(slateObjectType)

    if (isPhraseMatchItem && isType) {
      this.unwrapNestedBlock()
    } else if (isPhraseMatchItem) {
      // reset to p if already selected
      editor
        .unwrapBlock(
          slateObjectType === 'inline-verbatim-container'
            ? this.DEFAULT_NODE
            : 'inline-verbatim-container'
        )
        .wrapBlock(slateObjectType)
    } else {
      const isList = this.selectionHasElement('node', 'list-item')
      // if it's a list we need to unwrap the nested elements and rewrap them as inline-phrases
      if (isList) {
        this.rewrapNestedBlocks(slateObjectType, 'inline-verbatim')
      } else {
        editor.setBlocks('inline-verbatim').wrapBlock(slateObjectType)
      }
    }
  }

  createList(slateObjectType) {
    const { editor } = this
    const isList = this.selectionHasElement('node', 'list-item')
    const isType = this.selectionHasParentElement(slateObjectType)

    if (isList && isType) {
      this.unwrapNestedBlock()
    } else if (isList) {
      // switch between lists
      editor
        .unwrapBlock(slateObjectType === 'bulleted-list' ? 'numbered-list' : 'bulleted-list')
        .wrapBlock(slateObjectType)
    } else {
      const isPhraseMatch = this.selectionHasElement('node', 'inline-verbatim')
      // let's us switch between inline phrase match and lists smoothly
      if (isPhraseMatch) {
        this.rewrapNestedBlocks(slateObjectType, 'list-item')
      } else {
        editor.setBlocks('list-item').wrapBlock(slateObjectType)
      }
    }
  }

  createFlatBlock(slateObjectType, resetUrlInput, urlInputValue) {
    const { editor } = this

    const isActive = this.selectionHasElement('node', slateObjectType)
    const isList =
      this.selectionHasElement('node', 'list-item') ||
      this.selectionHasElement('node', 'inline-verbatim')

    // if a list is selected before you can make it a new type
    // unwrap the list and set it to default node
    if (isList) {
      this.unwrapNestedBlock(!isActive && slateObjectType)
    } else {
      if (slateObjectType === 'horizontal-rule') {
        editor.insertBlock({
          type: 'horizontal-rule',
        })

        editor.moveToEnd()
        return
      }
      if (slateObjectType === 'image') {
        // TODO swap these timeouts for wrapping this in a promise so .command finishes first
        setTimeout(() => resetUrlInput(), 0)
        editor.command(insertImage, urlInputValue)
        return
      }
      editor.setBlocks(isActive ? this.DEFAULT_NODE : slateObjectType)
    }
  }

  createBlock(event, slateObjectType, resetUrlInput, urlInputValue = null) {
    event.preventDefault()
    const { editor } = this

    const hasLinks = this.selectionHasElement('inline', 'link')
    const hasButtons = this.selectionHasElement('inline', 'button')

    if (hasLinks) editor.command(unwrapInlineNode, 'link')
    if (hasButtons) editor.command(unwrapInlineNode, 'button')
    // Handle everything but list buttons.
    if (slateObjectType === 'inline-verbatim-container') {
      this.createInlineVerbatim(slateObjectType)
    } else if (slateObjectType !== 'bulleted-list' && slateObjectType !== 'numbered-list') {
      this.createFlatBlock(slateObjectType, resetUrlInput, urlInputValue)
    } else {
      this.createList(slateObjectType)
    }
    return null
  }

  // currently links and buttons are our only inline nodes
  createInlineNode(e, urlInputValue, urlInputType, resetUrlInput) {
    e.preventDefault()
    const { editor } = this

    setTimeout(() => resetUrlInput(), 0)
    return editor.command(wrapInlineNode, urlInputValue, urlInputType)
  }

  selectionHasElement(slateObject, slateObjectType) {
    const { value } = this.state
    // blocks are handled differently because of lists
    // you have to look at the parent wrapper element
    // to know what kind of list is active
    let blockIsActive

    switch (slateObject) {
      case 'mark':
        try {
          return value.activeMarks.some((mark) => mark.type === slateObjectType)
        } catch {
          return false
        }
      case 'node':
        blockIsActive = value.blocks.some((node) => node.type === slateObjectType)
        if (
          ['numbered-list', 'bulleted-list', 'inline-verbatim-container'].includes(slateObjectType)
        ) {
          const {
            value: { document, blocks },
          } = this.state

          if (blocks.size > 0) {
            const parent = document.getParent(blocks.first().key)
            blockIsActive =
              this.selectionHasElement(
                'node',
                slateObjectType === 'inline-verbatim-container' ? 'inline-verbatim' : 'list-item'
              ) &&
              parent &&
              parent.type === slateObjectType
          }
        }
        return blockIsActive
      case 'inline':
        return value.inlines.some((inline) => inline.type === slateObjectType)
      default:
        return false
    }
  }

  selectionHasParentElement(parentObjectType) {
    // if an inline element gets a nested element this will need to change
    const {
      value,
      value: { document },
    } = this.editor
    // this is the slate document, not js global document
    return value.blocks.some(
      (block) => !!document.getClosest(block.key, (parent) => parent.type === parentObjectType)
    )
  }

  render() {
    return (
      <div className="rich-text-editor-container">
        <Toolbar
          createBlock={this.createBlock}
          createInlineNode={this.createInlineNode}
          createMark={this.createMark}
          editor={this.editor}
          selectionHasElement={this.selectionHasElement}
          inlineVerbatim={this.props.inlineVerbatim}
        />
        <Editor
          spellCheck
          style={{ padding: '1rem' }}
          placeholder="Enter Display..."
          readOnly={this.props.disabled}
          value={this.state.value}
          plugins={this.plugins}
          schema={this.schema}
          onChange={(value) => {
            this.onChange(value)
            this.props.form.setFieldValue('display.html', html.serialize(this.state.value))
          }}
          ref={this.ref}
          renderNode={renderNode}
          renderMark={renderMark}
        />
      </div>
    )
  }
}

export default RichTextEditor
