import React from 'react'
import { Form } from 'semantic-ui-react'

export const TextField = ({
  label,
  errorMessage,
  field: { name, ...fieldProps },
  form: { touched, errors },
  ...props
}) => {
  const getErrorMessage = () => {
    if (errorMessage) return { content: errorMessage }
    return errors[name] && touched[name] ? { content: errors[name] } : null
  }

  return (
    <Form.Input
      data-testid={`textField-${name}`}
      name={name}
      label={label}
      error={getErrorMessage()}
      {...fieldProps}
      {...props}
    />
  )
}
