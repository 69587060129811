import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Button, Icon, Segment } from 'semantic-ui-react'
import { IconDownload } from '@tabler/icons-react'
import { first } from 'lodash'

import { formatTime } from '@/utils/helpers'
import {
  fetchAggregateScoresByUserByConfigId,
  fetchAggregateScoresByUser,
} from '@/reducers/scorecards/scorecards.actions'
import { setDateRangeFilter } from '@/reducers/scorecards/scorecards.redux'
import { exportScoreDashboardToCsv } from '@/reducers/scorecards/scores.actions'

import { ViewScoresFilters } from '../ViewScoresFilters/ViewScoresFilters'
import { DashboardTable } from './DashboardTable/DashboardTable'

import './dashboardPage.css'

export const LiveDashboardPage = () => {
  const dispatch = useDispatch()
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const isBaltoAdmin = organizationId === 1
  const { data, filters } = useSelector((state) => state.scorecards)
  const { tableHeaders: headers, tableRows: rows } = data
  let updatedTime = formatTime(moment())

  const getAggregateData = () => {
    if (first(filters.scorecards)?.value) {
      dispatch(fetchAggregateScoresByUserByConfigId(first(filters.scorecards).value))
    } else if (!isBaltoAdmin) {
      dispatch(fetchAggregateScoresByUser(organizationId))
    }
    updatedTime = formatTime(moment())
  }

  useEffect(() => {
    if (organizationId !== 1) {
      dispatch(
        setDateRangeFilter({
          startDate: moment.utc(moment(Date.now()).startOf('day').toDate()),
          endDate: moment.utc(moment(Date.now()).endOf('day').toDate()),
        })
      )
    }
    getAggregateData()
  }, [dispatch])

  useEffect(() => {
    const interval = setInterval(() => getAggregateData(), 60000)
    return () => {
      clearInterval(interval)
    }
  }, [filters.scorecards])

  const handleFilterSubmit = () => {
    getAggregateData()
  }

  return (
    <>
      <div data-testid="scorecard-dashboard" className="scorecard-dashboard">
        <div className="scorecard-dashboard__export-button-container">
          {rows?.scorecardDashboard.length ? (
            <Button
              secondary
              className="svg-button"
              data-testid="export-button"
              onClick={() =>
                dispatch(
                  exportScoreDashboardToCsv(rows.scorecardDashboard, headers?.scorecardDashboard)
                )
              }
            >
              <IconDownload />
              Export
            </Button>
          ) : null}
        </div>

        <ViewScoresFilters
          handleFilterSubmit={handleFilterSubmit}
          hide={{
            dateRange: !isBaltoAdmin,
            sections: true,
            measures: true,
            criteria: true,
            multiScorecard: false,
          }}
        />

        <div className="scorecard-dashboard__content">
          <div className="scorecard-dashboard__content-top-menu">
            <h3>Average scores in last day</h3>
            <div className="scorecard-dashboard__content-time-stamp">
              <Icon className="clock" />
              <p data-testid="last-updated">Last update: {updatedTime}</p>
            </div>
          </div>
          <Segment className="not-padded">
            <DashboardTable />
          </Segment>
        </div>
      </div>
    </>
  )
}

export default LiveDashboardPage
