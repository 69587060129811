import React, { useContext, useEffect } from 'react'
import { Button, Popup } from 'semantic-ui-react'
import classNames from 'classnames'
import { capitalize } from 'lodash'

import { Pill } from '../pills/Pill'
import WidgetContext from './widget.context'
import { ExternalLink } from '../ExternalLink'
import { Banner } from '../banners/Banner'

const WidgetLegend = ({
  data,
  warningMessage,
  accessor,
  withLegend,
  capitalizeLegend,
  withSortedLegend,
  tableFilter,
  disableLegendToggle = false,
}) => {
  const {
    selectAllStatus,
    topFiveStatus,
    bottomFiveStatus,
    hiddenItems,
    handleChangeHiddenItems,
    onPageLoad,
    handleToggleAllHiddenItems,
    handleSelectAllToggle,
    handleSelectTopFive,
    handleSelectBottomFive,
    updateLegendState,
  } = useContext(WidgetContext)

  const selectedItemsText = () => {
    return hiddenItems.size !== 0
      ? `${data.length - hiddenItems.size} of ${data.length} items`
      : `${data.length} items`
  }

  const items = data.map((item) => item[accessor])

  useEffect(() => {
    if (tableFilter) {
      onPageLoad(items, tableFilter)
    }
  }, [])

  useEffect(() => {
    if (withSortedLegend) {
      updateLegendState(items)
    }
  }, [hiddenItems])

  return (
    <div className="widget__legend" data-testid="widget-legend">
      {withLegend && (
        <div className="widget__legend-toggle-container">
          <div>{selectedItemsText()}</div>
          {!disableLegendToggle && (
            <Button
              basic
              onClick={() => handleToggleAllHiddenItems(data.map((item) => item[accessor]))}
            >
              Toggle all
            </Button>
          )}
        </div>
      )}
      {withSortedLegend && (
        <div className="widget__legend-sorted-container">
          <div
            className="widget__legend-item clickable"
            onClick={() => handleSelectAllToggle(items)}
          >
            <div className="widget__legend-item--color">
              <div
                className={classNames('color-checkbox', selectAllStatus)}
                style={{ backgroundColor: 'var(--blurple-500)' }}
              />
            </div>
            <div className="widget__legend-item--name">{selectedItemsText()}</div>
          </div>
          <div
            className="widget__legend-item clickable"
            onClick={() => handleSelectTopFive(items.slice(0, 5))}
          >
            <div className="widget__legend-item--color">
              <div
                className={classNames('color-checkbox', { hidden: !topFiveStatus })}
                style={{ backgroundColor: 'var(--blurple-500)' }}
              />
            </div>
            <div className="widget__legend-item--name">Top 5</div>
          </div>
          <div
            className="widget__legend-item clickable"
            onClick={() => handleSelectBottomFive(items.slice(-5))}
          >
            <div className="widget__legend-item--color">
              <div
                className={classNames('color-checkbox', { hidden: !bottomFiveStatus })}
                style={{ backgroundColor: 'var(--blurple-500)' }}
              />
            </div>
            <div className="widget__legend-item--name">Bottom 5</div>
          </div>
        </div>
      )}
      {warningMessage && (
        <Banner critical header={warningMessage.title}>
          {warningMessage.text}
        </Banner>
      )}
      {data.map((item) => {
        return (
          <div
            // checklist items won't combine if their text matches but they have different "win" status,
            // so without appending "+win" we may get duplicated keys in that section
            key={`${item[accessor]}${item?.win ? '+win' : ''}`}
            className={classNames('widget__legend-item', { clickable: !disableLegendToggle })}
            onClick={() => {
              if (!disableLegendToggle) {
                handleChangeHiddenItems(item[accessor])
              }
            }}
          >
            <div className="widget__legend-item--color">
              <div
                className={classNames('color-checkbox', {
                  hidden: hiddenItems.has(item[accessor]),
                })}
                style={{ backgroundColor: item.color }}
              />
            </div>
            <div className="widget__legend-item--name">
              {item.win && (
                <Popup
                  inverted
                  on="hover"
                  size="small"
                  content="Win Marker"
                  trigger={
                    <Pill small success data-testid="win-marker">
                      win
                    </Pill>
                  }
                />
              )}
              <span>
                {capitalizeLegend
                  ? capitalize(item?.label || item[accessor])
                  : item?.label || item[accessor]}
              </span>
              {item.link && (
                <ExternalLink
                  to={item.link}
                  label="View"
                  target="_blank"
                  onClick={(event) => event.stopPropagation()}
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default WidgetLegend
