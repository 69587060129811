import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'

import { buildUserAttributes } from '@/utils/launchDarkly'
import { fetchOrgHierarchy } from '@/reducers/orgHierarchy/orghierarchy.actions'
import { apiService, fetchingAPI } from '@/api'
import { initializePendo } from '@/utils/initializePendo'
import { clearAuthMessage, updateAuthMessage } from '../ui/ui.redux'
import { loginUser, logoutUser } from './currentUser.redux'
import { clearRouteErrors } from '../errors/routeErrors.redux'

export const authenticateUser = (userData, setSubmitting) => {
  return async (dispatch) => {
    const { email, password, token, history, location, ldClient } = userData

    dispatch(clearAuthMessage())
    dispatch(clearRouteErrors())

    try {
      const response = await fetch(`${apiService.web}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, token }),
      })

      if (response.status === 401) {
        dispatch(updateAuthMessage('error', 'Incorrect username or password.'))
        if (setSubmitting) return setSubmitting(false)
        return null
      }

      if (response.status >= 500) {
        dispatch(
          updateAuthMessage(
            'error',
            'The server encountered an internal error and was unable to login.'
          )
        )
        if (setSubmitting) return setSubmitting(false)
        return null
      }

      const currentUser = await response.json()

      if (currentUser.error) {
        dispatch(updateAuthMessage('error', 'Something went wrong.'))
        if (setSubmitting) return setSubmitting(false)
        return null
      }

      // for hierarchy managers, the user's organizationid will change to whichever org they selected
      // we store own_organization_id as the user's true organization id
      currentUser.own_organization_id = currentUser.organizationid

      // Persist current user in localstorage so user remains logged in when they refresh the page
      localStorage.setItem('User', JSON.stringify(currentUser))

      // Log user into Redux
      dispatch(loginUser(currentUser))
      dispatch(clearAuthMessage())

      // Initialize Pendo
      initializePendo()

      // Initialize LaunchDarkly
      if (ldClient) {
        ldClient.identify(buildUserAttributes(currentUser))
      }

      const defaultDestination =
        currentUser.organizationid === 1 ? '/organizations' : '/command-center'

      const { next } = queryString.parse(location.search)
      // This line prevents some back end errors that are blowing up the logs
      if (next === '/users/' && currentUser.organizationid === 1) {
        return history.push('/command-center')
      }
      const redirect = next || defaultDestination

      return history.push(redirect)
    } catch (err) {
      console.error(err)

      dispatch(updateAuthMessage('error', 'Something went wrong, please try again.'))
      if (setSubmitting) return setSubmitting(false)
      return null
    }
  }
}

export const unauthenticateUser = ({ history, location, ldClient }) => {
  return async (dispatch) => {
    const redirect = `/login?${location ? `next=${location.pathname}` : ''}`

    try {
      await fetchingAPI(`${apiService.web}/api/logout`, 'POST', dispatch)
    } catch (err) {
      console.error(err)
    }
    // Log user out of Redux
    dispatch(logoutUser())

    // Clear user from local storage
    localStorage.removeItem('User')
    localStorage.removeItem('balto_org_id')

    // Clear user data from LaunchDarkly
    if (ldClient) {
      ldClient.identify({ anonymous: true })
    }

    // Redirect to login
    history.push(redirect)
  }
}

export const resetUserPassword = (email, setSubmitting) => async (dispatch) => {
  dispatch(clearAuthMessage())

  try {
    const response = await fetch(`${apiService.web}/api/reset_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: email }),
    })

    if (response.status !== 200) {
      dispatch(updateAuthMessage('error', 'Something went wrong, please try again.'))
      return setSubmitting(false)
    }

    const currentUser = await response.json()

    if (currentUser.error) {
      dispatch(updateAuthMessage('error', currentUser.error))
      return setSubmitting(false)
    }

    dispatch(
      updateAuthMessage(
        'success',
        "We sent an email with a link to reset your password. If you don't see the email from noreply@baltocloud.com, please check your spam folder."
      )
    )
    return setSubmitting(false)
  } catch (err) {
    dispatch(updateAuthMessage('error', 'Something went wrong, please try again.'))
    return setSubmitting(false)
  }
}

export const fetchCurrentUserPermissions = (userId, organizationId) => {
  return async (dispatch, getState) => {
    const { currentUser } = getState()
    try {
      const userPermissions = await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/users/${userId}/permissions`,
        'GET',
        dispatch,
        undefined,
        undefined,
        undefined,
        true
      )

      if (!isEmpty(userPermissions)) {
        dispatch(loginUser({ ...currentUser, ...userPermissions }))
        localStorage.setItem('User', JSON.stringify({ ...currentUser, ...userPermissions }))
      }

      // get org hierarchy (other orgs user has permission for) if user is hierarchy manager
      if (userPermissions?.hierarchy_manager) {
        dispatch(fetchOrgHierarchy())
      }
    } catch (err) {
      console.error(err)
    }
  }
}
