import React, { Component } from 'react'
import { connect } from 'react-redux'

import { BaltoLogo } from '../BaltoLogo'
import { postError } from '../../reducers/errors/errors.actions'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    this.setState(() => ({ hasError: true }))

    const digestibleInfo = info.componentStack
      .split('\n')
      .map((string) => string.trim())
      .join(' ')

    this.props.postError({ error: error.toString(), info: digestibleInfo })
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      return (
        <div className="balto-wrapper">
          <div className="vertical-wrapper">
            <div className="vertical-container text-center">
              <BaltoLogo height={80} />
              <h2>Something went wrong!</h2>
              <p>Please refresh and try again.</p>
              <p>
                If the problem persists, please email
                <a href="mailto:support@balto.ai" target="_blank" rel="noopener noreferrer">
                  <strong>
                    <em> support@balto.ai</em>
                  </strong>
                </a>
                .
              </p>
              <a href="/" className="ui button fluid primary">
                Return home
              </a>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default connect(null, { postError })(ErrorBoundary)
