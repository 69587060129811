import produce from 'immer'

// Action Types
export const SET_LOADING = 'audio/setLoading'
export const UPDATE_CALL_AUDIO = 'audio/updateCallAudio'
export const UPDATE_SETTINGS = 'audio/updateSettings'
export const TOGGLE_CALL_AUDIO_PLAYING = 'audio/toggleCallAudioPlaying'
export const RESET_CALL_AUDIO = 'audio/resetCallAudio'
export const PAUSE_CALL_AUDIO = 'audio/pauseCallAudio'

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const updateCallAudio = (payload) => ({ type: UPDATE_CALL_AUDIO, payload })
export const updateSettings = (payload) => ({ type: UPDATE_SETTINGS, payload })
export const toggleCallAudioPlaying = (payload) => ({ type: TOGGLE_CALL_AUDIO_PLAYING, payload }) // prettier-ignore
export const resetCallAudio = (payload) => ({ type: RESET_CALL_AUDIO, payload }) // prettier-ignore
export const pauseCallAudio = () => ({ type: PAUSE_CALL_AUDIO }) // prettier-ignore

// State
export const initialState = {
  callAudio: {
    callId: null,
    isPlaying: false,
    audioUrl: null,
    audioStatus: 'not_available',
    audioUrlExpiration: null, // not using for audio drawer yet - expiration is 1 hour, which seems long enough
    duration: 0,
    progress: 0,
    audioError: false,
  },
  settings: {
    playbackRate: 1, // preserve playback rate from local storage between calls/refresh so it doesn't get reset
  },
  loading: {
    callAudio: null,
  },
}

// Reducer
export default function audioReducer(state = initialState, action) {
  return produce(state, (newState) => {
    const { payload } = action

    switch (action.type) {
      case SET_LOADING:
        newState.loading = { ...state.loading, ...payload }
        break
      case UPDATE_CALL_AUDIO:
        newState.callAudio = { ...state.callAudio, ...payload }
        break
      case UPDATE_SETTINGS:
        newState.settings = { ...state.settings, ...payload }
        break
      case TOGGLE_CALL_AUDIO_PLAYING:
        if (state.callAudio.audioUrl && state.callAudio.callId) {
          newState.callAudio = { ...state.callAudio, isPlaying: !state.callAudio.isPlaying }
        } else {
          newState.callAudio = initialState.callAudio
        }
        break
      case RESET_CALL_AUDIO:
        newState.callAudio = initialState.callAudio
        break
      case PAUSE_CALL_AUDIO:
        newState.callAudio = { ...state.callAudio, isPlaying: false }
        break
      default:
        break
    }
  })
}
