import React, { useMemo, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import WidgetContext from '../../../components/widget/widget.context'
import WidgetContainer from '../../../components/widget/WidgetContainer'
import WidgetTooltip from '../../../components/widget/WidgetTooltip'
import BarChart from '../../../components/charts/BarChart'
import { AdvancedTable } from '../../../components/tables/AdvancedTable/AdvancedTable'
import { AdvancedTablePagination } from '../../../components/tables/AdvancedTable/AdvancedTablePagination'
import { formatPercent, getPercentNumerical, truncateString } from '../../../utils/helpers'
import { getItemsWithColors, getSectionLabel } from './widgetHelpers'

export const ItemUsageWidget = ({ section, isWin }) => {
  const [activePage, setActivePage] = useState(1)
  const { data, loading } = useSelector((state) => state.analytics)
  const { isPercent, hiddenItems, handleChangeCsvData, handleResetWidgetFilters } =
    useContext(WidgetContext)

  const itemAccessor = 'item'
  const itemsWithColor = getItemsWithColors(section, data, itemAccessor)
  const sectionLabel = getSectionLabel(section)
  const formattedData = useMemo(() => {
    return data[`${section}UsageByItem`]
      .map((item) => ({
        ...item,
        rate: getPercentNumerical(item.count, item.total),
        win_rate: getPercentNumerical(item.win_count, item.count),
        ...itemsWithColor.find((itemColor) => itemColor[itemAccessor] === item[itemAccessor]),
      }))
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t[itemAccessor] === value[itemAccessor])
      )
      .sort((a, b) => {
        if (section === 'checklist') {
          return a.order - b.order
        }
        return a[itemAccessor] - b[itemAccessor]
      })
  }, [data])
  const dataWithHiddenItems = useMemo(
    () => formattedData.filter((item) => !hiddenItems.has(item[itemAccessor])),
    [formattedData, hiddenItems]
  )

  const countAccessor = isWin ? 'win_count' : 'count'
  const rateAccessor = isWin ? 'win_rate' : 'rate'
  const percentLabel = isWin ? 'Win Rate' : 'Percent'
  const countLabel = isWin ? 'Win Count' : 'Count'

  const columns = [
    {
      accessor: itemAccessor,
      label: sectionLabel,
    },
    {
      accessor: isPercent ? rateAccessor : countAccessor,
      label: isPercent ? percentLabel : countLabel,
      format: (v) => (isPercent ? formatPercent(v) : Number(v).toLocaleString()),
    },
  ]

  // Find pagination values
  const rowsPerPage = 20
  const count = dataWithHiddenItems.length
  const totalPages = Math.ceil(count / rowsPerPage)
  const paginatedRows = useMemo(
    () =>
      dataWithHiddenItems.slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage).reverse(),
    [dataWithHiddenItems, activePage]
  )

  useEffect(() => {
    handleChangeCsvData({ headers: columns, data: formattedData })
  }, [formattedData])

  useEffect(() => {
    setActivePage(1)
    handleResetWidgetFilters()
  }, [data])

  return (
    <WidgetContainer
      large
      loading={loading[`${section}Page`]}
      noData={isEmpty(dataWithHiddenItems) && hiddenItems.size === 0}
      withLegend
      withPagination
      dataForLegend={{ data: itemsWithColor }}
      legendAccessor={itemAccessor}
      table={<AdvancedTable index={itemAccessor} rows={formattedData} columns={columns} />}
      chart={
        <>
          <BarChart
            isPercent={isPercent}
            data={paginatedRows}
            colors={(values) => values.data.color}
            keys={[isPercent ? rateAccessor : countAccessor]}
            indexBy={itemAccessor}
            enableGridX
            layout="horizontal"
            tooltip={(item) => (
              <WidgetTooltip color={item.color} small>
                <strong>{item.data[itemAccessor]}</strong>
                <span>
                  {`: ${
                    isPercent
                      ? formatPercent(item.data[rateAccessor])
                      : Number(item.data[countAccessor]).toLocaleString()
                  }`}
                </span>
              </WidgetTooltip>
            )}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 0,
              format: (v) => (isPercent ? formatPercent(v) : Number(v).toLocaleString()),
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 10,
              format: (v) => truncateString(v, 22),
            }}
            margin={{ top: 20, right: 50, bottom: 50, left: 150 }}
          />
          <AdvancedTablePagination
            activePage={activePage}
            rowsPerPage={rowsPerPage}
            count={count}
            totalPages={totalPages}
            setActivePage={setActivePage}
          />
        </>
      }
    />
  )
}
