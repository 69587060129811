import React from 'react'

import { SvgDashboardLine } from './SvgDashboardLine'

import './SvgDashboardLines.scss'

export const SvgDashboardLines = () => {
  return (
    <div className="svg-dashboard-lines">
      <SvgDashboardLine />
      <SvgDashboardLine />
      <SvgDashboardLine />
      <SvgDashboardLine />
    </div>
  )
}
