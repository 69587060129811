import React, { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  fetchChallengeDetailsById,
  fetchChallengeResultsById,
  updateChallengeStatusFromDetails,
} from '@/reducers/gamification/gamification.actions'
import { Loader } from 'semantic-ui-react'
import './ChallengeDetailPage.scss'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { NestedTable } from '@/components/tables/NestedTable/NestedTable'
import { ChallengeDetailSummary } from '@/views/Gamification/Challenges/ChallengeDetailPage/ChallengeDetailSummary'
import { Breadcrumbs } from '@/components/forms/Breadcrumbs/Breadcrumbs'
import { isEmpty } from 'lodash'
import {
  determineChallengeWinner,
  determineDateRangeString,
  determineEmptyStateText,
  findChallengeResultForGroupAndUser,
} from '@/views/Gamification/Challenges/helpers'
import { ChallengeCannotBeActivatedWarning } from '@/views/Gamification/Challenges/ChallengeDetailPage/ChallengeCannotBeActivatedWarning'
import { ChallengeStatusBadge } from '@/views/Gamification/Challenges/ChallengeDetailPage/ChallengeStatusBadge'
import { ChallengeCompletedMessage } from '@/views/Gamification/Challenges/ChallengeDetailPage/ChallengeCompletedMessage'
import { ChallengeStatusToggle } from '@/views/Gamification/Challenges/ChallengesPage/ChallengeStatusToggle'

export const ChallengeDetailPage = () => {
  const dispatch = useDispatch()
  const { challenge_id: challengeId } = useParams()
  const { data, loading } = useSelector((state) => state.gamification)
  const { customBranding } = useSelector((state) => state.customBranding)
  const pageTitle = customBranding?.customBrandingDisplayName || 'Balto Cloud'
  const { challengeResults, currentlySelectedChallenge: challenge } = data
  const challengeHasParticipants = challenge?.numUsers >= 1
  const challengeReadyForActivation =
    challenge?.startDate && challenge?.endDate && challengeHasParticipants
  const moreThanOneGroupExists = challengeResults?.results?.length > 1

  const { groupResults, userResults } = useMemo(
    () => findChallengeResultForGroupAndUser(challengeResults),
    [challengeResults]
  )

  useEffect(() => {
    dispatch(fetchChallengeDetailsById(challengeId))
    dispatch(fetchChallengeResultsById(challengeId))
  }, [])

  const challengeWinners = useMemo(
    () => determineChallengeWinner(challenge, challengeId, challengeResults),
    [challengeId, challenge, challengeResults]
  )

  // Loading state
  if (loading.currentlySelectedChallenge) {
    return (
      <div data-testid="loading-challenge-detail">
        <Loader active />
      </div>
    )
  }

  if (isEmpty(challengeResults)) return null

  const updateStatus = (challengeToUpdate, newStatus) => {
    dispatch(updateChallengeStatusFromDetails(challengeToUpdate, newStatus))
  }

  const emptyState = (
    <div className="challenge-detail__empty-results" data-testid="challenge-detail-empty">
      <img
        className="challenge-detail__empty-results-badge grayscale"
        data-testid="badge-image"
        src={challengeResults.badgeImageUrl}
        alt={`Badge for ${challenge.name || 'this challenge'}`}
      />
      <div className="challenge-detail__empty-results-text" data-testid="empty-result-text">
        {determineEmptyStateText(challenge?.status)}
      </div>
    </div>
  )

  const breadCrumbLinks = [{ label: 'All Challenges', link: '/gamification/challenges' }]

  const summaryDataPoints = [
    {
      value: determineDateRangeString(challenge.startDate, challenge.endDate),
      description: 'Date Range',
    },
    { value: challenge.type, description: 'Challenge Type' },
    { value: 'Badge', description: 'Reward' },
  ]

  const tableHeaders = [
    { label: 'Rank', accessor: 'rank' },
    { label: 'Name', accessor: 'name' },
    { label: 'Total Score', accessor: 'leaderboardValue', left: true },
    { label: 'Target Reached', accessor: 'hits', left: true },
    { label: 'Target Not Reached', accessor: 'misses', left: true },
  ]
  const tableToDisplay = moreThanOneGroupExists ? (
    <NestedTable
      columns={tableHeaders}
      rows={groupResults}
      setColumnSpan={5}
      collapsibleChildren
      striped
    />
  ) : (
    <AdvancedTable
      columns={tableHeaders}
      rows={userResults}
      index="rank"
      defaultOrderBy="rank"
      defaultOrder="asc"
    />
  )

  return (
    <div className="challenge-detail" data-testid="challenge-detail-page">
      <Helmet>
        <title>
          {pageTitle} | {challenge.name}
        </title>
      </Helmet>

      <Breadcrumbs backLinks={breadCrumbLinks} currentLink={challenge.name} />
      <h1 className="challenge-detail__title" data-testid="challenge-detail-title">
        <div>
          {challenge.name}
          <ChallengeStatusBadge status={challenge.status} />
        </div>
        <div className="challenge-detail__toggle">
          <ChallengeStatusToggle
            challenge={challenge}
            challengeHasEmptyFields={!challengeReadyForActivation}
            updateChallStatus={updateStatus}
          />
        </div>
      </h1>
      <h2 className="muted-subheader">{challenge.description}</h2>
      {!challengeReadyForActivation && <ChallengeCannotBeActivatedWarning />}
      {challengeWinners && <ChallengeCompletedMessage challengeInfo={challengeWinners} />}
      <div>
        <ChallengeDetailSummary summaryDataPoints={summaryDataPoints} />
      </div>
      <div className="challenge-detail__result-table" data-testid="challenge-detail-result-table">
        {challengeResults?.results.length ? tableToDisplay : emptyState}
      </div>
    </div>
  )
}

export default ChallengeDetailPage
