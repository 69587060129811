import React from 'react'
import { useDispatch } from 'react-redux'

import { closeModal } from '@/reducers/ui/ui.redux'
import { createPlaylist } from '@/reducers/playlists/playlists.actions'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'

export const CreatePlaylistButtonAndModal = ({ organization, buttonProps = {} }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeModal())
  }

  const handleCreatePlaylist = (formValues) => {
    dispatch(createPlaylist(formValues.name, organization.id))
    handleClose()
  }

  if (!organization) {
    return null
  }

  return (
    <ButtonAndFormModal
      buttonLabel="Create Playlist"
      modalTitle={`Create Playlist for ${organization.name}`}
      modalId="playlists/create"
      modalProps={{ size: 'tiny' }}
      buttonProps={{ primary: true, ...buttonProps }}
      form={
        <AbstractForm
          schema={[
            {
              name: 'name',
              type: 'text',
              label: 'Playlist Name',
              required: true,
              autoFocus: true,
            },
          ]}
          onSubmit={handleCreatePlaylist}
          buttonLabel="Save"
          onClose={handleClose}
          isModal
        />
      }
    />
  )
}
