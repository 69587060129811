import React, { useEffect } from 'react'
import { Field } from 'formik'
import { isEmpty } from 'lodash'
import { Button } from 'semantic-ui-react'

import { RadioGroup, Checkbox } from '@/components/forms/formik'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'

export const QACopilotSectionSettingsForm = ({
  handleSubmit,
  handleCancel,
  values,
  errors,
  formikProps,
}) => {
  useEffect(() => {
    if (values.auto_fail) {
      formikProps.setFieldValue('auto_zero', false)
    }
  }, [values.auto_fail])

  useEffect(() => {
    if (values.auto_zero) {
      formikProps.setFieldValue('auto_fail', false)
    }
  }, [values.auto_zero])

  return (
    <>
      <section>
        <h3>Scoring Logic</h3>
        <Field
          name="condition_type"
          component={RadioGroup}
          radioType="button"
          options={[
            {
              value: 'AND',
              label: (
                <label htmlFor="condition_type-AND">
                  <h5>AND Logic</h5>
                  <p>
                    All items in the section are required to match the expected criteria in order to
                    receive full credit for the section.
                  </p>
                </label>
              ),
            },
            {
              value: 'OR',
              label: (
                <label htmlFor="condition_type-OR">
                  <h5>OR Logic</h5>
                  <p>
                    Only one of the selected items will need to be correct in order for agents to
                    receive full credit.
                  </p>
                </label>
              ),
            },
          ]}
        />
        <h3>Failure Logic</h3>
        <p className="muted-text">Only one type of failure logic can be active at a time.</p>
        <Field
          label={
            <div className="mb">
              <h5>Auto-Fail</h5>
              <p>
                When one or more items do not meet the expected criteria, the entire call will be
                evaluated as a failure.
              </p>
            </div>
          }
          name="auto_fail"
          component={Checkbox}
          toggle
          inline
          reverse
        />
        <Field
          label={
            <div className="mb">
              <h5>Auto-Zero</h5>
              <p>
                When one or more items do not meet the expected criteria, all criteria in the
                section will be evaluated as a zero.
              </p>
            </div>
          }
          name="auto_zero"
          component={Checkbox}
          toggle
          inline
          reverse
        />
      </section>

      <div className="modal-footer">
        <Button type="button" secondary onClick={handleCancel}>
          Discard Changes
        </Button>
        <ConditionalTooltip condition={!isEmpty(errors)} content="Must fix errors to submit">
          <Button type="button" primary onClick={handleSubmit} disabled={!isEmpty(errors)}>
            Update
          </Button>
        </ConditionalTooltip>
      </div>
    </>
  )
}
