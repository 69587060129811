import React from 'react'
import { Dropdown as SemanticDropdown } from 'semantic-ui-react'
import classNames from 'classnames'
import { isNil } from 'lodash'

import { ErrorMessage } from '@/components/forms/ErrorMessage'

export const Dropdown = ({
  label,
  field: { name, value },
  form: { setFieldValue, touched, errors },
  required,
  index,
  innerRef,
  ...props
}) => {
  const hasError = errors[name] && touched[name]
  const getErrorMessage = () => {
    return errors[name] && touched[name] ? { content: errors[name] } : null
  }
  const enableFocus = innerRef && !isNil(index)

  // eslint-disable-next-line no-return-assign
  const handleSetRef = (el) => (innerRef.current[index] = el)

  return (
    <div className={classNames('field', { required })} data-testid={`${name}-dropdown`}>
      {label && <label htmlFor={name}>{label}</label>}
      <SemanticDropdown
        ref={enableFocus ? handleSetRef : null}
        onChange={(e, { value }) => {
          e.preventDefault()

          setFieldValue(name, value)
        }}
        value={value}
        {...props}
      />
      {hasError && <ErrorMessage content={getErrorMessage()} />}
    </div>
  )
}
