import { renderAxisToCanvas } from '@nivo/axes'
import { defaultTheme } from '@nivo/core'
import {
  calculateMaxValue,
  calculateProportionalMaxValue,
  createAxisTicksToCustomScale,
  createCustomScale,
  useCustomNivoTheme,
} from './helpers'
import { truncateString } from '../../../utils/helpers'

export const CustomRightAxis = (canvasContext, props, { dataPoints, dataPointKey }) => {
  const theme = defaultTheme
  if (!dataPoints?.length) {
    return
  }

  // we need to customize the theme because the axis font is transparent by default
  const customTheme = useCustomNivoTheme(theme)

  const {
    yScale: currentYScale,
    innerWidth: width,
    innerHeight: height,
    tickPadding,
    right = {
      orient: 'right',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      format: (v) => truncateString(v, 30),
    },
  } = props

  const maxValue = calculateMaxValue(dataPoints, dataPointKey)
  const proportionalMaxValue = calculateProportionalMaxValue(maxValue)

  const axisTicks = createAxisTicksToCustomScale(currentYScale, proportionalMaxValue)
  const customScale = createCustomScale(height, proportionalMaxValue, axisTicks)

  canvasContext.moveTo(width, 0)

  renderAxisToCanvas(canvasContext, {
    axis: 'y',
    scale: customScale,
    right,
    tickValues: axisTicks,
    tickPadding,
    ticksPosition: 'after',
    x: width,
    y: 0,
    length: width,
    theme: customTheme,
    // eslint-disable-next-line no-restricted-globals
    format: (v) => (isNaN(v) ? v : Math.floor(v)),
  })

  canvasContext.save()
}
