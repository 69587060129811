import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Segment } from 'semantic-ui-react'
import { Formik, Field } from 'formik'
import { isEmpty } from 'lodash'

import { Pill } from '@/components/pills/Pill'
import { RadioGroup, Checkbox } from '@/components/forms/formik'
import { ModuleCard } from '@/components/cards/ModuleCard'
import {
  fetchSentimentData,
  fetchSentimentConfig,
} from '@/reducers/commandCenter/commandCenter.actions'
import { saveFilter, updateSavedFilter } from '@/reducers/analytics/analytics.actions'

import { SentimentList } from './SentimentList'

export const Sentiment = () => {
  const [sentimenConfigFetched, setSentimentConfigFetched] = useState(false)
  const [isConfigView, setIsConfigView] = useState(false)
  const dispatch = useDispatch()
  const { loading, sentiment, sentimentConfig } = useSelector((state) => state.commandCenter)
  const hasExistingSentimentConfig = sentimentConfig && !isEmpty(sentimentConfig.filters)
  const initialValues = {
    ignoreWeekends: hasExistingSentimentConfig ? sentimentConfig.filters.ignoreWeekends : false,
    timePeriod: hasExistingSentimentConfig ? sentimentConfig.filters.timePeriod : 'daily',
  }

  const handleSaveSentimentConfig = (values) => {
    const filterToSave = {
      name: 'Sentiment',
      isDefault: false,
      filterType: 'sentiment',
      ...values,
    }

    if (!hasExistingSentimentConfig) {
      dispatch(saveFilter(filterToSave, 'sentiment'))
    } else {
      dispatch(updateSavedFilter(sentimentConfig.uuid, filterToSave, 'sentiment'))
    }

    setIsConfigView(false)
  }

  useEffect(() => {
    async function initialSetup() {
      // Connect to the socket on every page mount
      await dispatch(fetchSentimentConfig())
      setSentimentConfigFetched(true)
    }

    initialSetup()
  }, [])

  useEffect(() => {
    if (sentimenConfigFetched) {
      dispatch(fetchSentimentData())
    }
  }, [
    sentimenConfigFetched,
    sentimentConfig.filters?.ignoreWeekends,
    sentimentConfig.filters?.timePeriod,
  ])

  return (
    <ModuleCard
      title={`Sentiment Analysis ${isConfigView ? 'Configuration' : ''}`}
      buttons={
        isConfigView ? (
          <div
            className="flex flex-align-center flex-space-between small-gap"
            data-testid="config-options-container"
          >
            <Button
              secondary
              onClick={() => {
                setIsConfigView(false)
              }}
              data-testid="discard-button"
            >
              Discard Changes
            </Button>
            <Button primary type="submit" form="sentiment-config-form" data-testid="save-button">
              Save
            </Button>
          </div>
        ) : (
          <Button
            secondary
            onClick={() => {
              setIsConfigView(true)
            }}
            data-testid="edit-button"
          >
            Edit
          </Button>
        )
      }
      pills={
        isConfigView ? null : (
          <div className="flex-align-center medium-gap">
            <Pill small emphasized data-testid="time-period-label">
              {sentimentConfig.filters?.timePeriod === 'weekly' ? 'Last 7 Days' : 'Last 24 hours'}
            </Pill>
            {sentimentConfig.filters?.ignoreWeekends && (
              <Pill small emphasized data-testid="ignore-weekends-label">
                Ignoring Weekends
              </Pill>
            )}
          </div>
        )
      }
    >
      {isConfigView ? (
        <Formik initialValues={initialValues}>
          {({ values }) => (
            <Form
              id="sentiment-config-form"
              onSubmit={() => handleSaveSentimentConfig(values)}
              data-testid="config-form"
            >
              <section className="sentiment-config">
                <div>
                  <h3>Time Period</h3>
                  <p className="muted-text">Display calls for the last 24 hours or past 7 days</p>
                </div>
                <div>
                  <Segment>
                    <Field
                      name="timePeriod"
                      component={RadioGroup}
                      options={[
                        { value: 'daily', text: 'Daily' },
                        { value: 'weekly', text: 'Weekly' },
                      ]}
                    />
                  </Segment>
                  <Segment>
                    <Field
                      label="Ignore Weekends"
                      name="ignoreWeekends"
                      component={Checkbox}
                      toggle
                      inline
                    />
                  </Segment>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      ) : (
        <SentimentList loading={loading} sentiment={sentiment} />
      )}
    </ModuleCard>
  )
}
