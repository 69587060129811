import React from 'react'

export const DesktopModeButtonGroup = ({ alwaysStartCall, visibility, handleModalChange }) => {
  const baltoZero = {
    desktop_always_start_call: false,
    desktop_visibility: false,
  }

  const baltoLite = {
    desktop_always_start_call: true,
    desktop_visibility: false,
  }

  const baltoStandard = {
    desktop_always_start_call: false,
    desktop_visibility: true,
  }
  return (
    <div className="ui basic segment" style={{ minWidth: 'fit-content' }}>
      <h3>Desktop Mode</h3>
      <div className="flex-align-center gap">
        <button
          data-testid="balto-zero-button"
          type="button"
          onClick={() => handleModalChange(baltoZero, 'Change Desktop Mode to Balto Zero')}
          className={`ui ${!alwaysStartCall && !visibility && 'active'} button secondary`}
        >
          Balto Zero
        </button>
        <button
          data-testid="balto-lite-button"
          type="button"
          onClick={() => handleModalChange(baltoLite, 'Change Desktop Mode to Balto Lite')}
          className={`ui ${alwaysStartCall && !visibility && 'active'} button secondary`}
        >
          Balto Lite
        </button>
        <button
          data-testid="standard-balto-button"
          type="button"
          onClick={() => handleModalChange(baltoStandard, 'Change Desktop Mode to Balto Standard')}
          className={`ui ${!alwaysStartCall && visibility && 'active'} button secondary`}
        >
          Standard Balto
        </button>
      </div>
    </div>
  )
}
