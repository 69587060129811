import classNames from 'classnames'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import React from 'react'
import { badgeWizardSchema } from '@/views/Gamification/Badges/BadgeWizard/badgeWizardSchema'

const editFormFields = (agentOptions) => [
  { name: 'name', type: 'text', label: 'Badge Name', required: true },
  { name: 'description', type: 'text', label: 'Badge Description', required: true },
  {
    name: 'userIds',
    type: 'multiSelect',
    label: 'Agents',
    options: agentOptions,
  },
]

export const BadgeWizardEditForm = ({
  badge,
  loading,
  onSave,
  onClose,
  isLastColumn,
  currentRow,
  currentColumn,
  agentOptions,
}) => {
  const gridColumnStyle = isLastColumn ? `-3 / span 2` : `${currentColumn} / span 2`

  const handleSave = (badgeInfo) => {
    onSave(badge.id, badgeInfo)
  }
  return (
    <div
      className={classNames('badge-edit-form', isLastColumn && 'last-column')}
      style={{ gridRow: `${currentRow} / span 3`, gridColumn: gridColumnStyle }}
      data-testid="badge-edit-form"
    >
      <div className="badge-edit-form__summary">
        <img alt={`${badge.name} badge`} src={badge.imageUrl} className="badge-edit-form__image" />
        <div className="badge-edit-form__summary-title">{badge.name}</div>
      </div>
      <div className="badge-edit-form__form">
        <AbstractForm
          existingValues={badge}
          validationSchema={badgeWizardSchema}
          onSubmit={handleSave}
          onClose={onClose}
          schema={editFormFields(agentOptions)}
          buttonLabel="Submit Changes"
          closeButtonLabel="Cancel"
          buttonProps={{ loading }}
        />
      </div>
    </div>
  )
}
