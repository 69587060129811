import React from 'react'
import { intervalToDuration, formatDuration } from 'date-fns'

const formatTimestampDuration = (duration) => {
  const d = intervalToDuration({
    start: 0,
    end: Math.round(duration) * 1000,
  })
  return formatDuration(d)
}

export const SilenceBlock = ({ duration, highlighted }) => {
  const formattedDuration = formatTimestampDuration(duration)
  return (
    <div className={`silence-block ${highlighted ? 'highlighted' : ''}`}>
      {formattedDuration} of silence
    </div>
  )
}
