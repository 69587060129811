import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'
import { debounce } from 'lodash'

import { fetchPlaybookEntryWarnings } from '@/reducers/playbooks/playbook.actions'

export const FormObserver = ({ entryId, sectionName }) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()

  const getPlaybookEntryWarnings = (values) => {
    dispatch(fetchPlaybookEntryWarnings(sectionName, values, entryId))
  }

  const debouncedGetWarnings = useCallback(
    debounce(getPlaybookEntryWarnings, 1000, { leading: false, trailing: true }),
    []
  )

  useEffect(() => {
    debouncedGetWarnings(values)

    return () => {
      debouncedGetWarnings.cancel()
    }
  }, [values])

  return null
}
