// Action Types
export const SET_KEYWORDS = 'keywords/setKeywords'
export const SET_LOADING = 'keywords/setLoading'

// Action Creators
export const setKeywords = (payload, language) => ({ type: SET_KEYWORDS, payload, language })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })

// State
export const initialState = {
  keywords: [],
  sideMapping: {},
  loading: { keywords: false, deleteCheck: false },
  language: '',
}

// Reducer
export default function keywordsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_KEYWORDS: {
      const sideMapping = {}
      action.payload.forEach((row) => {
        sideMapping[row.krid] = { side: row.side, restricted: row.side_restricted }
      })
      return {
        ...state,
        keywords: action.payload,
        sideMapping,
        language: action.language || 'english',
      }
    }
    case SET_LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    default:
      return state
  }
}
