import React from 'react'
import { Form, Radio } from 'semantic-ui-react'
import classNames from 'classnames'

export const RadioGroup = ({
  label,
  field: { name, value, ...fieldProps },
  form: { touched, errors },
  required,
  options,
  inline = true,
  radioType,
  ...props
}) => {
  return (
    <>
      {label && (
        <Form.Field
          required={required}
          error={errors[name] && touched[name] ? { content: errors[name] } : false}
          label={label}
        />
      )}
      <Form.Group
        grouped={!inline}
        className={classNames({ 'radio-button-container': radioType === 'button' })}
      >
        {options.map((option) => (
          <Form.Field key={option.value}>
            <Radio
              id={`${name}-${option.value}`}
              label={option.text || option.label}
              value={option.value}
              checked={option.value === value}
              disabled={option.disabled}
              name={name}
              data-testid={`radioGroup-${option.value}`}
              className={classNames({ 'radio-button': radioType === 'button' })}
              {...fieldProps}
              {...props}
            />
          </Form.Field>
        ))}
      </Form.Group>
    </>
  )
}
