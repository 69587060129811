import React from 'react'
import { List, Button } from 'semantic-ui-react'
import { IconBulb } from '@tabler/icons-react'

import { Banner } from '@/components/banners/Banner'

export const ReorderDecklist = ({ reorderRecommend, useInsightOrder, dismissInsight }) => {
  const issuesDisplay = reorderRecommend && (
    <List>
      <List.Item
        key={1}
        content={
          <div className="flex-align-center gap">
            <IconBulb className="icon-svg" />
            <strong>Balto Insight Recommendation</strong>
          </div>
        }
      />
      <List.Item
        key={2}
        content={
          <div className="flex-align-center">
            <span style={{ marginLeft: '27px' }}>
              Balto recommends rearranging some Decklist Items for better win performance.
            </span>
          </div>
        }
      />
      <List.Item
        key={3}
        content={
          <Button.Group floated="right" style={{ marginTop: '5px' }}>
            <Button className="inline-link-button" onClick={() => useInsightOrder()}>
              Rearrange Decklist Items
            </Button>
            <Button className="dismiss-insight" onClick={() => dismissInsight()}>
              Dismiss
            </Button>
          </Button.Group>
        }
      />
    </List>
  )

  return <>{issuesDisplay && <Banner informative>{issuesDisplay}</Banner>}</>
}
