import React from 'react'
import classNames from 'classnames'

export const EventFilters = (props) => {
  const title = `${props.title}:`
  return (
    <div className="events-viewer-actions" data-testid="event-filters">
      <div className="events-viewer-actions-top">
        <h2 data-testid="events-viewer-title">{title}</h2>

        <div className="events-viewer-filters">
          {props.filterOptions.map((option) => {
            const key = `${option.id}-selector`
            return (
              <button
                data-testid={key}
                type="button"
                className={classNames('text-button', {
                  active: props.selectedFilters === option.id,
                })}
                key={key}
                onClick={() => props.handleFilterSelect(option)}
              >
                {option.label}
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
}
