import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Radio } from 'semantic-ui-react'

import NoData from '@/components/NoData'
import {
  createEntry,
  reorderEntry,
  toggleNovelChecklistItems,
} from '@/reducers/playbooks/playbook.redux'
import { fetchChecklistVariations } from '@/reducers/playbooks/playbook.actions'
import { Drag, Drop, DragAndDrop } from '@/components/dragDrop'
import { reorder } from '@/utils/helpers'

import { PlaybookInlineEntry } from './PlaybookInlineEntry'
import { PlaybookSectionHeader } from './PlaybookSectionHeader'
import { PlaybookEntry } from './PlaybookEntry'
import { accessorSectionMap, accessors, sections } from '../playbook.helpers'

import { SectionWarnings } from './SectionWarnings'
import { NovelContentView } from '../modules/beacon/NovelContentView'

export const PlaybookEntryDisplayDraggable = () => {
  const dispatch = useDispatch()
  const {
    playbook,
    playbook: { current: isLatestPlaybookVersion },
    warnings,
    loading,
    checklistVariations,
    novelChecklistItemsVisible,
    novelChecklistItems,
  } = useSelector((state) => state.playbook)
  const { edit_config } = useSelector((state) => state.currentUser)
  const { section } = useParams()
  const accessor = accessorSectionMap[section]
  const entries = playbook.body[accessor]
  const readOnly = !isLatestPlaybookVersion || !edit_config

  const handleReorder = (result) => {
    const { source, destination } = result
    const originalOrder = entries.order

    if (!destination) return

    if (source.droppableId === destination.droppableId) {
      const newOrder = reorder(originalOrder, source.index, destination.index)
      dispatch(reorderEntry(accessor)(newOrder))
    }
  }

  const toggleNovelContentVisible = () => {
    dispatch(toggleNovelChecklistItems(!novelChecklistItemsVisible))
  }

  useEffect(() => {
    if (section === sections.CHECKLIST && playbook.cid) {
      // playbook.id is actually the associated config_id on playbook, not the playbook object's id
      dispatch(fetchChecklistVariations(playbook.cid, playbook.id))
    }
  }, [section, playbook.cid])

  return (
    <>
      {isLatestPlaybookVersion && (
        <SectionWarnings
          section={section}
          warnings={warnings}
          loading={loading.warnings}
          style={{ maxWidth: '770px' }}
        />
      )}
      <PlaybookSectionHeader buttonText="Add Item" buttonDisabled={readOnly} />

      {isEmpty(entries.order) ? (
        <NoData text={`Click on the plus to create a new ${section} item`} position="left" />
      ) : (
        <div className="playbook-entries" data-testid="playbook-entry-display">
          <DragAndDrop onDragEnd={handleReorder}>
            <Drop droppableId={`ordered-${section}-entries`}>
              {entries.order.map((entryId, index) => {
                return (
                  <Drag key={entryId} draggableId={entryId} index={index} readOnly={readOnly}>
                    <PlaybookEntry
                      key={entryId}
                      accessor={accessor}
                      entryId={entryId}
                      entry={entries.entries[entryId]}
                      label={`${section} item`}
                      isLockable={accessor === accessors.CHECKLIST}
                      isWinnable={accessor === accessors.CHECKLIST}
                      readOnly={readOnly}
                      checklistVariationCount={checklistVariations?.[entryId]}
                    />
                  </Drag>
                )
              })}
            </Drop>
          </DragAndDrop>

          {accessor === accessors.CHECKLIST && (
            <div className="checklist-bottom-controls-container">
              {!readOnly && (
                <PlaybookInlineEntry
                  createEntry={createEntry(accessor)}
                  label="Add Item"
                  type="entry"
                  isDraggable
                />
              )}
              <Radio
                data-testid="novel-checklist-content-toggle"
                className="novel-checklist-content-toggle"
                toggle
                checked={novelChecklistItemsVisible}
                onChange={toggleNovelContentVisible}
                disabled={novelChecklistItems.length === 0}
                label={`Display Custom Items (${novelChecklistItems.length})`}
              />
            </div>
          )}
          {accessor === accessors.CHECKLIST && novelChecklistItemsVisible && (
            <NovelContentView novelChecklistItems={novelChecklistItems} />
          )}
        </div>
      )}
    </>
  )
}
