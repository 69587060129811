import React from 'react'
import { useSelector } from 'react-redux'
import { Segment } from 'semantic-ui-react'
import { isEmpty, isNil } from 'lodash'
import { IconCalendar } from '@tabler/icons-react'

import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { formatPercent, formatTime } from '@/utils/helpers'
import NoData from '../../components/NoData'

export const LeaderboardTable = () => {
  const { data, filters, loading } = useSelector((state) => state.leaderboards)
  const selectedLeaderboard = data?.leaderboards?.find(
    (item) => item?.leaderboard?.id === filters.leaderboardId
  )

  const rows = data?.leaderboard?.leaderboard || []
  const columns = [
    { accessor: 'rank', label: 'Rank' },
    { accessor: 'name', label: 'Name' },
    { accessor: 'total_calls', label: 'Call Count' },
  ]

  if (!isEmpty(rows)) {
    if (rows.find((row) => !isNil(row.call_checklist_completion_percent))) {
      columns.push({
        accessor: 'call_checklist_completion_percent',
        label: 'Checklist Usage',
        format: (v) => formatPercent(v),
      })
    }
    if (rows.find((row) => !isNil(row.decklist_trigger_rate))) {
      columns.push({
        accessor: 'decklist_trigger_rate',
        label: 'Decklist Usage',
        format: (v) => formatPercent(v),
      })
    }
    if (rows.find((row) => !isNil(row.wins))) {
      columns.push({ accessor: 'wins', label: 'Wins' })
    }
    if (rows.find((row) => !isNil(row.win_rate))) {
      columns.push({ accessor: 'win_rate', label: 'Win Rate', format: (v) => formatPercent(v) })
    }
    if (rows.find((row) => !isNil(row.qa))) {
      columns.push({ accessor: 'qa', label: 'QA Score', format: (v) => formatPercent(v) })
    }
  }

  const getDisplayDate = (date) => {
    // sometimes the date returns with timezone and sometimes it doesn't? so just remove it always
    // 2024-05-01T05:00:00+00:00 vs 2024-05-01
    const YYYY_MM_DD = date.substring(0, 10)
    // adding time to account for timezone conversion in new Date since the return format isn't an isostr
    const displayDate = formatTime(`${YYYY_MM_DD}T12:00:00`, 'dddd MMMM D, YYYY')

    return displayDate
  }

  if (!selectedLeaderboard) {
    return (
      <Segment className="not-padded">
        <div className="empty-table">
          <NoData text="No data loaded, select your filters" />
        </div>
      </Segment>
    )
  }

  return (
    <div data-testid="leaderboard-table">
      {selectedLeaderboard && (
        <h2 data-testid="leaderboard-name">{selectedLeaderboard?.version?.name}</h2>
      )}
      {selectedLeaderboard && data.leaderboard?.start_date && (
        <div className="flex-align-center medium-gap">
          <IconCalendar />
          <strong>
            {getDisplayDate(data.leaderboard?.start_date)}
            {data.leaderboard?.end_date && ` - ${getDisplayDate(data.leaderboard.end_date)}`}
          </strong>
        </div>
      )}

      <Segment className="not-padded">
        <AdvancedTable
          index="rank"
          defaultOrderBy="rank"
          defaultOrder="asc"
          loading={loading.leaderboard}
          rows={rows}
          columns={columns}
          pagination
        />
      </Segment>
    </div>
  )
}
