import React from 'react'

import './IconDecoration.scss'

export const IconDecoration = ({ icon, index, ...props }) => {
  return (
    <div
      className="icon-decoration"
      style={{ background: `var(--gradient-dark-${index})` }}
      {...props}
    >
      {icon}
    </div>
  )
}
