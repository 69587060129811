import React from 'react'
import { DateRangePickerReusable } from '@/components/datePickers/DateRangePickerReusable'
import { Form } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment'
import {
  setDateRangeFilters,
  setFilter,
} from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { ErrorMessage } from '@/components/forms/ErrorMessage'

export const DateRangePicker = ({ filterKey = 'dateRange' }) => {
  const dispatch = useDispatch()
  const { filters } = useSelector((state) => state.realtimeCoaching)

  const handleDateRangeFilterChange = ({ selection }) => {
    const startDateTime = startOfDay(selection.startDate)
    const endDateTime = endOfDay(selection.endDate)
    const duration = moment.duration(moment(endDateTime).diff(moment(startDateTime)))
    const days = duration.asDays()
    if (days > 186) {
      dispatch(setFilter({ [`${filterKey}IsValid`]: false }))
    } else {
      dispatch(setFilter({ [`${filterKey}IsValid`]: true }))
    }
    const { text } = selection
    if (filterKey === 'nullableDateRange') {
      dispatch(setFilter({ [filterKey]: { startDate: startDateTime, endDate: endDateTime } }))
    } else {
      // this action passes text and is used for previous date range in reports, not using same
      // function to keep current functionality undisturbed
      dispatch(setDateRangeFilters({ startDate: startDateTime, endDate: endDateTime, text }))
    }
  }

  const handleDateRangeClear = () => {
    dispatch(setFilter({ [filterKey]: { startDate: null, endDate: null } }))
  }

  return (
    <Form.Field>
      <label>Date Range</label>
      <DateRangePickerReusable
        data-testid="rtc-date-picker"
        displayClearButton={filterKey === 'nullableDateRange'}
        onClear={handleDateRangeClear}
        disableClearButton={!filters[filterKey].startDate && !filters[filterKey].endDate}
        startDate={filters[filterKey].startDate}
        endDate={filters[filterKey].endDate}
        onChange={handleDateRangeFilterChange}
        error={!filters[`${filterKey}IsValid`]}
      />
      {!filters[`${filterKey}IsValid`] && (
        <ErrorMessage content="Date range must be six months or less" />
      )}
    </Form.Field>
  )
}
