import { formatPercent, formatTime } from '@/utils/helpers'
import React from 'react'
import { BadgeNameAndImage } from '@/views/Gamification/Badges/BadgeNameAndImage'

export const getUniqueAgents = (agents) => {
  const uniqueNames = []
  const uniqueAgents = []
  agents.forEach((agent) => {
    if (!uniqueNames.includes(agent.label)) {
      uniqueNames.push(agent.label)
      uniqueAgents.push(agent)
    }
  })
  return uniqueAgents
}

export const getAgentsByTags = (agentOptions, tagOptions, agentsInGroup) => {
  agentOptions.forEach((agent) => {
    agent.tags.forEach((tag) => {
      tagOptions.forEach((selectedTag) => {
        if (selectedTag.label === tag.name) {
          agentsInGroup = [...agentsInGroup, agent]
        }
      })
    })
  })
  return agentsInGroup
}

export const getAllAgentsByAllTags = (agents, clickEvent) => {
  const agentNames = []
  agents.forEach((agent) => {
    agent.tags.forEach((tag) => {
      clickEvent.removedValues.forEach((tagOption) => {
        if (tagOption.label === tag.name) {
          agentNames.push(agent.label)
        }
      })
    })
  })
  return agentNames
}

export const getAgentNames = (agents, clickEvent, selectedTags) => {
  const agentNames = []
  const tagOptions = []
  if (selectedTags.length > 0) {
    selectedTags.forEach((st) => tagOptions.push(st.label))
  }
  agents.forEach((agent) => {
    // RETURN IF AGENT HAS NO TAGS, WE DON'T NEED TO INSPECT
    if (agent?.tags?.length === 0) return
    // CHECK FOR AGENTS WITH TWO TAGS and THAT there are multiple selected tags
    if (agent?.tags?.length > 1 && selectedTags.length > 0) {
      let result = []
      result = agent?.tags.map((tag) => {
        return selectedTags.map((st) => {
          // CHECK ALL SELECTED TAGS AGAINST THE AGENT TAGS, RETURN TRUE IF ANY SELECTED TAGS MATCH AN AGENTS TAG
          return tag.name === st.label
        })
      })
      const combineResults = [...result[0], ...result[1]]
      // IF THE AGENT DOES NOT HAVE MULTIPLE TAGS THEN RETURN!
      if (!combineResults.includes(true)) {
        agent?.tags.forEach((tag) => {
          selectedTags.forEach((tagOption) => {
            if (clickEvent.option.label === tag.name && tagOption.label !== tag.name) {
              agentNames.push(agent.label)
            }
          })
        })
      }
    } else {
      // IF AGENT DOES NOT HAVE TWO TAGS, WE CAN SIMPLY REMOVE THE TAG
      agent?.tags.forEach((tag) => {
        if (selectedTags.length === 0) {
          if (clickEvent.option.label === tag.name) {
            agentNames.push(agent.label)
          }
        } else {
          selectedTags.forEach((tagOption) => {
            if (clickEvent.option.label === tag.name && tagOption.label !== tag.name) {
              agentNames.push(agent.label)
            }
          })
        }
      })
    }
  })
  return agentNames
}

export const removeAgentsByTags = (group, agentNames) => {
  const remainingAgents = []
  group.agents.forEach((agent) => {
    if (!agentNames.includes(agent.label)) remainingAgents.push(agent)
  })
  return remainingAgents
}

export const determineStatusLabel = (status) => {
  switch (status) {
    case 'completed':
      return { label: 'Completed', statusType: { success: true } }
    case 'active':
      return { label: 'In Progress', statusType: { warning: true } }
    case 'cancelled':
      return { label: 'Cancelled', statusType: { critical: true } }
    case 'scheduled':
      return { label: 'Scheduled', statusType: { brand: true } }
    default:
      return { label: null, statusType: null }
  }
}

export const determineDateRangeString = (startDate, endDate) => {
  const formatString = 'MMMM D, YYYY'

  if (startDate && endDate) {
    return `${formatTime(startDate, formatString)} - ${formatTime(endDate, formatString)}`
  }

  if (startDate) {
    return `After ${formatTime(startDate, formatString)}`
  }

  if (endDate) {
    return `Before ${formatTime(endDate, formatString)}`
  }

  return '--'
}

export const determineEmptyStateText = (status) => {
  switch (status) {
    case 'scheduled':
      return 'There isn’t enough data to display a useful table because this challenge has not started.'
    case 'inactive':
      return 'There isn’t enough data to display a useful table.\nContinue configuring this challenge within the Settings tab.'
    default:
      return 'There isn’t enough data to display a useful table.'
  }
}

export const showResultsAverageOrCount = (resultValue, thresholdType) => {
  if (thresholdType === 'count') {
    return Math.round(resultValue * 100) / 100
  }
  return formatPercent(resultValue, 2)
}

export const findChallengeResultForGroupAndUser = (challengeResults) => {
  if (!challengeResults?.results) return {}

  const groupResults = []
  const userResults = []

  // parse through group results to return groups and users
  challengeResults?.results.forEach((groupResult, groupIndex) => {
    const userResult = groupResult.groupResult.map((userResult, userIndex) => ({
      ...userResult,
      rank: userIndex + 1,
      leaderboardValue: showResultsAverageOrCount(
        userResult.leaderboardValue,
        groupResult.thresholdType
      ),
    }))
    groupResults.push({
      groupId: groupResult.groupId,
      thresholdType: groupResult.thresholdType,
      name: groupResult.groupName ?? `Team ${groupIndex + 1}`,
      leaderboardValue: showResultsAverageOrCount(
        groupResult.teamProgress,
        groupResult.thresholdType
      ),
      rank: groupIndex + 1,
      userResults: userResult,
      nestedField: 'userResults',
    })

    groupResult.groupResult.forEach((userResult, userIndex) => {
      userResults.push({
        ...userResult,
        rank: userIndex + 1,
        leaderboardValue: showResultsAverageOrCount(
          userResult.leaderboardValue,
          groupResult.thresholdType
        ),
      })
    })
  })

  return { groupResults, userResults }
}

const challengeDescriptionSlice = (description) => {
  if (description.length > 100) {
    description = `${description.substring(0, 100)}...`
  }
  return <div className="badge-description">{description}</div>
}

const buildBadgeInstance = (badgeTemplate, redirectToBadgeDetailPage) => ({
  name: {
    value: badgeTemplate.name,
    as: <BadgeNameAndImage template={badgeTemplate} badgeOrTemplate="badge" />,
    cellProps: {
      onClick: () => redirectToBadgeDetailPage(badgeTemplate.id),
      style: { cursor: 'pointer' },
    },
  },
  challengeType: '',
  description: '',
  id: badgeTemplate.id,
  imageUrl: badgeTemplate.imageUrl,
})

export const getChallengeBadgesByTemplates = (badgeTemplates, redirectToBadgeDetailPage) => {
  const challengeBadgeTemplates = []
  badgeTemplates.forEach((badgeTemplate) => {
    const matchingChallengeTemplate = challengeBadgeTemplates.find(
      (challengeBadgeTemplate) => challengeBadgeTemplate.name.value === badgeTemplate.templateName
    )
    if (matchingChallengeTemplate) {
      matchingChallengeTemplate.badges.push(
        buildBadgeInstance(badgeTemplate, redirectToBadgeDetailPage)
      )
    } else {
      challengeBadgeTemplates.push({
        name: {
          as: <BadgeNameAndImage template={badgeTemplate} />,
          value: badgeTemplate.templateName,
        },
        challengeType: badgeTemplate.challengeType,
        description: challengeDescriptionSlice(badgeTemplate.templateDescription),
        id: badgeTemplate.templateId,
        imageUrl: badgeTemplate.imageUrl,
        nestedField: 'badges',
        badges: [buildBadgeInstance(badgeTemplate, redirectToBadgeDetailPage)],
      })
    }
  })
  return challengeBadgeTemplates
}

export const getGroupTags = (group, agentOptions, groupedTagOptions) => {
  const tags = []
  const tagOptions = groupedTagOptions?.reduce(
    (reducer, tagGroup) => [...reducer, ...tagGroup.options],
    []
  )
  tagOptions.forEach((tagOption) => {
    const agentsWithTag = agentOptions.filter((agentOption) =>
      agentOption.tags.some((tag) => tag.id === tagOption.value)
    )

    const allPossibleAgentsSelected =
      agentsWithTag.length &&
      agentsWithTag.every((agent) =>
        group?.agents?.some((groupAgent) => groupAgent.value === agent.value)
      )

    const tagInGroup = group?.tags?.some((tag) => tag.value === tagOption.value)

    if (allPossibleAgentsSelected || tagInGroup) {
      tags.push({ value: tagOption.value, label: tagOption.label })
    }
  })

  return tags
}

export const determineChallengeWinner = (challenge, challengeId, challengeResults) => {
  let challengeWinnersInfo
  if (
    challenge &&
    challenge.id === challengeId &&
    challenge.status === 'completed' &&
    challengeResults &&
    challengeResults.results?.length > 0
  ) {
    if (challenge.type === 'leaderboard') {
      const results = challengeResults.results[0]
      const winners = results.groupResult?.filter((user) => user.rank === 1 && user.badgeAwarded)
      const winnerNames = winners?.map((winner) => winner.name).join(', ')
      if (winners.length > 0) {
        challengeWinnersInfo = {
          winners: winnerNames,
          endDate: challenge.endDate,
        }
      }
    } else if (challenge.type === 'team vs team') {
      const winningGroup = challengeResults.results[0]
      challengeWinnersInfo = {
        winners: winningGroup?.groupName ?? 'Team 1',
        endDate: challenge.endDate,
      }
    }
  }
  return challengeWinnersInfo
}

export const createPseudoRandomNumberFromUUID = (groupId, index) => {
  const sumOfIntegers = groupId
    .split('')
    // eslint-disable-next-line no-restricted-globals
    .filter((char) => !isNaN(Number(char)))
    .reduce((reducer, x) => reducer + Number(x), 0)
  return sumOfIntegers * (index + 1)
}
