import { isEmpty, truncate } from 'lodash'

export const safeSort = (scorecardData, sortKey) => {
  return scorecardData.sort((a, b) => {
    if (!a[sortKey][0] && a[sortKey][0] !== 0) {
      return 1
    }
    if (!b[sortKey][0] && b[sortKey][0] !== 0) {
      return -1
    }
    return a[sortKey][0] > b[sortKey][0] ? -1 : 1
  })
}

export const agentCardStyleClass = (agentName) => {
  return agentName ? 'analytics-card-agent-data' : 'analytics-card-empty-data'
}

export const topAgent = (aggregate_scores_by_user) => {
  return aggregate_scores_by_user && aggregate_scores_by_user[0]?.user_full_name
}
export const bottomAgent = (aggregate_scores_by_user) => {
  return aggregate_scores_by_user && aggregate_scores_by_user.slice(-1)[0]?.user_full_name
}

export const colors = {
  bars: '#B4C0FF',
  lines: '#4D63FE', // blurple-500
  legends: {
    text: '#777', // default nivo legend color
  },
}

export const getScorecardAggregateHeight = (reporting) => {
  // The reason for this is if we don't have a max height, we get one big fat graph.
  // This logic just shrinks the graph based on how many items in the graph we had.
  // Dirty... but a limitation of the graphing library.
  const scorecardList = reporting?.scorecard_configs?.filter(
    (scorecard) => reporting?.aggregate_scores[scorecard.sid] !== undefined
  )

  let numberOfScorecards = !isEmpty(scorecardList) ? scorecardList.length : 1
  if (numberOfScorecards > 5) {
    numberOfScorecards = 5
  }

  const height = (330 / 5) * numberOfScorecards + 70

  return height
}

export const getScorecardAggregates = (reporting) => {
  const scorecardData = reporting?.scorecard_configs
    .filter((scorecard) => reporting?.aggregate_scores[scorecard.sid] !== undefined)
    .map((scorecard) => ({
      id: truncate(scorecard.name, { length: 18 }),
      ranges: [
        0,
        scorecard.improvement_threshold,
        scorecard.meets_threshold,
        scorecard.exceeds_threshold,
        100,
      ],
      measures: [reporting?.aggregate_scores[scorecard.sid]],
    }))

  return safeSort(scorecardData, 'measures').slice(0, 5) // Only top/bottom 5;
}
