export const atBottomLevel = (rows) => Array.isArray(rows) && !rows.some((row) => row.nestedField)

// returns boolean reflecting whether none of the nested rows are selected
export const isNotSelected = (rows, selectedRowsObject) => {
  if (selectedRowsObject) {
    return rows.every((row) => !selectedRowsObject[row.key]?.selected && !row.selected)
  }

  return rows.every((row) => !row.selected)
}

// returns boolean reflecting whether all of the nested rows are selected
export const isFullySelected = (rows, selectedRowsObject) => {
  if (selectedRowsObject) {
    return rows.every((row) => selectedRowsObject[row.key]?.selected)
  }

  return rows.every((row) => row.selected)
}

// returns boolean reflecting whether some, but not all, of the nested rows are selected
export const isPartiallySelected = (rows, selectedRowsObject) => {
  if (selectedRowsObject) {
    return (
      rows.some(
        (row) =>
          selectedRowsObject[row.key]?.selected || selectedRowsObject[row.key]?.partiallySelected
      ) && !isFullySelected(rows, selectedRowsObject)
    )
  }

  return rows.some((row) => row.selected || row.partiallySelected) && !isFullySelected(rows)
}

// recurses through a nested dataset and returns a nested object in this format:
// {
//   topLevel: {
//     selected: false,
//     partiallySelected: true,
//   },
//   midLevel: {
//     selected: true,
//     partiallySelected: false,
//   },
//   lowLevel: {
//     selected: true,
//     partiallySelected: false,
//   },
// }
export const buildSelectedRowsObject = (rows, selectedRowsObject) => {
  if (!rows) return {}
  // if we're at the lowest nested level, we return the selected rows object with selected state
  if (atBottomLevel(rows)) {
    const result = rows.reduce(
      (reducer, row) => ({
        ...reducer,
        [row.key]: {
          selected: row.selected || false,
          partiallySelected: false,
          bottomLevel: true,
        },
      }),
      {}
    )
    return { ...selectedRowsObject, ...result }
  }
  // if we're not at lowest level, we recurse through again for each nested item and then set current level selected state
  const nestedResult = rows.reduce(
    (reducer, row) => ({ ...reducer, ...buildSelectedRowsObject(row[row.nestedField], reducer) }),
    selectedRowsObject
  )
  rows.forEach((row) => {
    nestedResult[row.key] = {
      selected: row.selected || false,
      partiallySelected: row.partiallySelected || false,
    }
  })

  // once we handle current level and nested level, we return the object
  return nestedResult
}

// uses the original object to determine whether fields are fully/partially selected after change
export const rebuildSelectedRowsObject = (rows, selectedRowsObject) => {
  // if we're at the lowest nested level, we return the selected rows object with selected state
  if (atBottomLevel(rows)) {
    const result = rows.reduce(
      (reducer, row) => ({
        ...reducer,
        [row.key]: {
          selected: selectedRowsObject[row.key]?.selected || false,
          partiallySelected: false,
          bottomLevel: true,
        },
      }),
      {}
    )
    return { ...selectedRowsObject, ...result }
  }

  // if we're not at lowest level, we recurse for each nested item and then set current level selected state
  const nestedResult = rows.reduce(
    (reducer, row) => ({ ...reducer, ...rebuildSelectedRowsObject(row[row.nestedField], reducer) }),
    selectedRowsObject
  )
  rows.forEach((row) => {
    nestedResult[row.key] = {
      selected: isFullySelected(row[row.nestedField], selectedRowsObject) || false,
      partiallySelected: isPartiallySelected(row[row.nestedField], selectedRowsObject) || false,
    }
  })

  // once we handle current level and nested level, we return the object
  return nestedResult
}

// recurses through the selected row, calls onSelect on the children,
// and sets selected values of every nested item in the tree equal to value param.
// returns value in the same structure as buildSelectedRowsObject
export const toggleNestedRows = (rows, selectedRowsObject, onSelect, value) => {
  // if row doesn't have a nested field, that means we're at the lowest level
  if (Array.isArray(rows) && !rows.some((row) => row.nestedField)) {
    // so we build up the row and return without continuing past the if block
    rows.forEach((row) =>
      onSelect(
        {
          ...row,
          active: value,
          weight: row.weight?.value || '1',
          must_be_present: row.must_be_present?.value,
        },
        value
      )
    )
    return rows.reduce(
      (reducer, row) => ({
        ...reducer,
        [row.key]: {
          active: value,
          selected: value,
          bottomLevel: true,
        },
      }),
      selectedRowsObject
    )
  }

  // if not at the bottom level, recurse through nested rows
  const nestedResult = rows.reduce(
    (reducer, row) => ({
      ...reducer,
      ...toggleNestedRows(row[row.nestedField], reducer, onSelect, value),
    }),
    selectedRowsObject
  )
  rows.forEach((row) => {
    nestedResult[row.key] = {
      selected: value,
      partiallySelected: false,
    }
  })

  // return results for this level, either back up the chain or out to the original function call
  return nestedResult
}

//
export const toggleRowAndUpdateSelectedObject = (rows, targetRow, selectedRowsObject, onSelect) => {
  let updatedObject = selectedRowsObject
  let foundMatchingItem = false

  for (const row of rows) {
    // if we found the match, we don't need to check anything else
    if (foundMatchingItem) break

    // found the matching item
    if (row.key === targetRow.key) {
      // if row is currently selected OR partially selected, we unselect.
      const newValue = !(
        selectedRowsObject[row.key]?.selected || selectedRowsObject[row.key]?.partiallySelected
      )
      // toggle all the rows down the tree to match
      const resultAfterToggle = toggleNestedRows([row], selectedRowsObject, onSelect, newValue)
      // now rebuild up the tree to change selected/partially selected
      const objectToReturn = rebuildSelectedRowsObject(rows, resultAfterToggle)
      foundMatchingItem = true
      // return object with new values, and foundMatchingItem so we can update that field and break the loop
      return [{ ...updatedObject, ...objectToReturn }, foundMatchingItem]
    }

    if (!atBottomLevel(rows) && !foundMatchingItem) {
      const result = toggleRowAndUpdateSelectedObject(
        row[row.nestedField],
        targetRow,
        selectedRowsObject,
        onSelect
      )
      // lint wouldn't allow destructuring reassignment, so i didn't
      // eslint-disable-next-line prefer-destructuring
      updatedObject = result[0]
      // eslint-disable-next-line prefer-destructuring
      foundMatchingItem = result[1]
    }
  }

  // as we move back up the tree, this gets passed up and these values are returned in the end
  return [rebuildSelectedRowsObject(rows, updatedObject), foundMatchingItem]
}
