import React, { useEffect } from 'react'
import { Button, Form } from 'semantic-ui-react'
import Select from '@/components/forms/Select'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getOrganizationOptions } from '@/utils/helpers'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { fetchManagersByOrg } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'

export const BaltoAdminManagerFilter = ({
  onSubmit,
  showManagerDropdown = true,
  extraClass = null,
}) => {
  const dispatch = useDispatch()
  const { currentUser, organizations } = useSelector((state) => state)
  const { data, filters, loading } = useSelector((state) => state.realtimeCoaching)
  const organizationOptions = getOrganizationOptions(
    organizations.filter((org) => org.realtime_coaching_access)
  )

  // Populates the Org and Managers dropdowns to the current user's on load if empty
  useEffect(() => {
    // Coming from Reports page - don't want org/manager mismatch
    dispatch(fetchManagersByOrg(filters.organization || currentUser.organizationid))
    if (filters.organization && filters.baltoAdminManager) {
      return
    }
    if (!filters.organization) {
      dispatch(setFilter({ organization: currentUser.organizationid }))
      dispatch(setFilter({ baltoAdminManager: currentUser.user_id }))
    }
    if (filters.organization === currentUser.organizationid) {
      dispatch(setFilter({ baltoAdminManager: currentUser.user_id }))
    }
  }, [])

  const handleOrgSelect = (organizationId) => {
    dispatch(setFilter({ organization: organizationId }))
    dispatch(setFilter({ baltoAdminManager: null }))
    dispatch(fetchManagersByOrg(organizationId))
  }

  const handleManagerSelect = (userId) => {
    dispatch(setFilter({ baltoAdminManager: userId }))
  }

  const handleFilterSubmit = () => {
    onSubmit({ userId: filters.baltoAdminManager, userOrganizationId: filters.organization })
  }

  return (
    <>
      <Form
        data-testid="rtc-organization-filters"
        className={`filter-form ${extraClass}`}
        onSubmit={() => handleFilterSubmit()}
      >
        <div className="filter-grid small-grid">
          <Form.Field required>
            <label htmlFor="organization">Organization</label>
            <Select
              placeholder="Select Organization"
              options={organizationOptions}
              loading={isEmpty(organizations)}
              value={filters.organization}
              onChange={({ value }) => handleOrgSelect(value)}
              isClearable={false}
            />
          </Form.Field>
          {showManagerDropdown && (
            <Form.Field required>
              <label htmlFor="user">Manager</label>
              <Select
                placeholder="Select User"
                options={data.managers}
                loading={loading.managers}
                value={filters.baltoAdminManager}
                onChange={({ value }) => handleManagerSelect(value)}
                isClearable={false}
              />
            </Form.Field>
          )}
          <div className="filter-buttons">
            <Form.Field>
              <label className="visibility-hidden">&nbsp;</label>
              <Button
                primary
                disabled={
                  !filters.organization || (!filters.baltoAdminManager && showManagerDropdown)
                }
                data-testid="filter-button"
                type="submit"
              >
                Filter
              </Button>
            </Form.Field>
          </div>
        </div>
      </Form>
    </>
  )
}
