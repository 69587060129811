import React from 'react'
import { Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty, toArray } from 'lodash'
import { toast } from 'react-toastify'

import { BasicModal } from '@/components/layout/modals/BasicModal'
import { closeModal } from '@/reducers/ui/ui.redux'
import { editScorecardConfig } from '@/reducers/qa-copilot/qa-copilot.redux'

import { QACopilotSectionSettingsForm } from '../forms/QACopilotSectionSettingsForm'
import { updateSectionSettingsHelper } from '../QACopilot.helpers'

export const QACopilotSectionSettingsModal = ({ section }) => {
  const dispatch = useDispatch()
  const { currentlyOpenModalId } = useSelector((state) => state.ui)
  const { scorecardConfig } = useSelector((state) => state.qaCopilot)
  const modalId = `qaCopilot/sectionSettings-${section.ssid}`
  const firstMeasure = toArray(section.measures)[0]
  const sectionSettingsInitialValues = {
    condition_type: firstMeasure.condition_type || 'AND',
    auto_fail: firstMeasure.auto_fail || false,
    auto_zero: firstMeasure.auto_zero || false,
  }

  const handleCloseModal = () => {
    dispatch(closeModal())
  }

  const handleUpdateScorecardSection = (values) => {
    dispatch(editScorecardConfig(updateSectionSettingsHelper(scorecardConfig, section, values)))
    dispatch(closeModal())
    toast.success('Scorecard section settings have been updated')
  }

  if (isEmpty(scorecardConfig?.sections)) {
    return null
  }

  if (modalId !== currentlyOpenModalId) {
    return null
  }

  return (
    <BasicModal
      className="qa-copilot-section-settings-modal"
      title="Section Settings"
      onClose={handleCloseModal}
      show={currentlyOpenModalId === modalId}
      size="small"
    >
      <Formik initialValues={sectionSettingsInitialValues} onSubmit={handleUpdateScorecardSection}>
        {({ values, errors, handleSubmit, ...formikProps }) => {
          return (
            <QACopilotSectionSettingsForm
              initialValues={sectionSettingsInitialValues}
              handleSubmit={handleSubmit}
              handleCancel={handleCloseModal}
              values={values}
              errors={errors}
              formikProps={formikProps}
            />
          )
        }}
      </Formik>
    </BasicModal>
  )
}
