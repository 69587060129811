import moment from 'moment'
import React from 'react'
import { Popup } from 'semantic-ui-react'
import queryString from 'query-string'
import { flatten, groupBy as lodashGroupBy, isEmpty } from 'lodash'
import { chartColors } from '@/utils/constants'
import { RankedProgressBar } from '@/components/charts/RankedProgressBarChart/RankedProgressBar'
import { overviewTableColumns } from '@/views/RealtimeCoaching/Reports/constants'
import { getPercentNumerical, truncateString } from '@/utils/helpers'

const sumCounts = (previousSum, nextDay) => previousSum + nextDay.count

export const avgAlertCount = (alertsByDay, dateRange) => {
  const daysInDateRange = moment
    .duration(moment(dateRange.endDate).diff(moment(dateRange.startDate)))
    .days()
  return alertsByDay?.reduce(sumCounts, 0) / (daysInDateRange || 1)
}

export const formatReportsActivityTableRows = (data, tableType, tableFilter, tableKey) => {
  const colors = {
    positive: '#39d583', // --green-500
    negative: '#f15050', // --red-500
    informative: '#58a6ff', // --sky-blue-500
    supervisorOccurrences: '#4D63FE', // --blurple-500
  }
  const opacity =
    tableFilter === 'descending' ? ['FF', 'CC', '99', '66', '33'] : ['33', '66', '99', 'CC', 'FF']
  const nums = []
  data?.map((e) => nums.push(e.alert_type_count || e.alert_count || e.chats || e.listens))
  const maxCount = Math.max(...nums)

  return data?.map((row, index) => {
    const name = row.name || row.agent_name || row.tag_name || row.supervisor || 'Name Unknown'
    const value = row.alert_type_count || row.alert_count || row.chats || row.listens || 0
    const width = getPercentNumerical(value, maxCount)
    return {
      [overviewTableColumns[tableKey][0].accessor]: {
        value: tableFilter === 'descending' ? data.length - index : index + 1,
        as: tableFilter === 'descending' ? data.length - index : index + 1,
      },
      [overviewTableColumns[tableKey][1].accessor]: {
        value: name,
        as:
          name.length < 25 ? (
            name
          ) : (
            <Popup content={name} trigger={<span>{truncateString(name, 25)}</span>} />
          ),
      },
      [overviewTableColumns[tableKey][2].accessor]: {
        as: RankedProgressBar({
          color: `${colors[row.alert_type] || colors[tableType]}${opacity[index]}`,
          width: width * 0.9,
          value,
          format: (value) => value,
        }),
        value,
      },
      [overviewTableColumns[tableKey][3].accessor]: {
        value: row[overviewTableColumns[tableKey][3].accessor] || '-',
        as: row[overviewTableColumns[tableKey][3].accessor] || '-',
      },
    }
  })
}

export const formatRtcFilters = (filterValues, includeManagers = false) => {
  const formattedFilterValues = {
    ...(includeManagers && { managers: filterValues.managers.map((x) => x.value) }),
    agents: filterValues.agents.map((x) => x.value),
    alertNames: filterValues.alertNames.map((x) => x.label),
    alertTypes: filterValues.alertTypes.map((x) => x.value),
    groupBy: filterValues.groupBy,
    tags: filterValues.tags.map((x) => x.value),
    dateRange: {
      startDate: moment(filterValues.dateRange.startDate).startOf('day').toISOString(true),
      endDate: moment(filterValues.dateRange.endDate).endOf('day').toISOString(true),
    },
  }

  return queryString.stringify({
    ...(includeManagers && { managers: formattedFilterValues.managers }),
    agents: formattedFilterValues.agents,
    alertNames: formattedFilterValues.alertNames,
    alertTypes: formattedFilterValues.alertTypes,
    groupBy: formattedFilterValues.groupBy,
    tags: formattedFilterValues.tags,
    startDate: formattedFilterValues.dateRange.startDate,
    endDate: formattedFilterValues.dateRange.endDate,
  })
}

export const formatRtcGraphData = (tableData, resource, dateRange, groupBy) => {
  const graphData = {}
  const groupByEnum = {
    day: 'day',
    week: 'isoWeek',
    month: 'month',
    quarter: 'quarter',
  }
  const groupByAddEnum = {
    day: 'days',
    week: 'weeks',
    month: 'months',
    quarter: 'quarters',
  }
  const resourceEnum = {
    agent: 'name',
    chat: 'supervisor',
    tag: 'tag_name',
    alert: 'alert_name',
    listen: 'supervisor',
  }

  // Groups by resource and date
  const userGroup = lodashGroupBy(tableData, resourceEnum[resource])
  Object.keys(userGroup).forEach((resource) => {
    graphData[resource] = lodashGroupBy(userGroup[resource], 'date')
  })

  // Adds up values from each date
  Object.keys(graphData).forEach((resource) => {
    const userData = graphData[resource]
    Object.keys(graphData[resource]).forEach((date) => {
      const userDataByDate = userData[date]
      graphData[resource][date] = userDataByDate.reduce(
        (prevData, data) => prevData + data.count,
        0
      )
    })
  })

  // Insert any dates that do not have values with a 0 value
  // This takes into account 'group by' so that we are not inserting every day
  const now = moment(dateRange.startDate).startOf(groupByEnum[groupBy])
  const endDate = moment(dateRange.endDate).endOf(groupByEnum[groupBy])
  while (now.isSameOrBefore(endDate)) {
    const date = now.format('YYYY-MM-DD')
    Object.keys(graphData).forEach((resource) => {
      if (!graphData[resource][date]) {
        graphData[resource][date] = 0
      }
    })
    now.add(1, groupByAddEnum[groupBy])
  }

  // Get data in a format nivo can understand
  const formattedGraphData = []
  Object.keys(graphData).forEach((resource, i) => {
    const graphDataEntry = {
      id: resource,
      color: chartColors[i % chartColors.length],
      data: [],
      totalNumAlerts: 0,
    }
    Object.keys(graphData[resource]).forEach((date) => {
      graphDataEntry.data.push({ x: date, y: graphData[resource][date] })
      graphDataEntry.totalNumAlerts += graphData[resource][date]
    })
    graphDataEntry.data.sort((a, b) => (a.x > b.x ? 1 : -1))
    // Get rid of the year after we sort (2022-12-30 --> 12-30)
    graphDataEntry.data = graphDataEntry.data.map((item) => {
      return { ...item, x: moment(item.x, 'YYYY-MM-DD').format('MM-DD') }
    })

    formattedGraphData.push(graphDataEntry)
  })

  formattedGraphData.sort((a, b) => (a.totalNumAlerts < b.totalNumAlerts ? 1 : -1))
  return formattedGraphData
}

export const flattenTableData = (dataWithHiddenItems) => {
  return flatten(
    dataWithHiddenItems.map((arr) => {
      // add color attr to data
      return arr.data.map((item) => {
        return { ...item, color: arr.color }
      })
    })
  )
}

export const getAbbreviatedName = (supervisors) => {
  if (isEmpty(supervisors)) {
    return ''
  }
  const topSupervisor = supervisors[0]
  return `${topSupervisor.first_name[0]}. ${topSupervisor.last_name}`
}
