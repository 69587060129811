import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { Button, Dropdown, Radio, Segment } from 'semantic-ui-react'

import { setHardSelectedEvent } from '@/reducers/callSearch/callSearch.actions'
import { setCallExplorerData } from '@/reducers/callSearch/callSearch.redux'
import VideoPlayer from '@/components/VideoPlayer'
import { AudioPlayerViewer } from './AudioPlayerViewer'
import { ConversationBlock } from './ConversationBlock'
import { SilenceBlock } from './SilenceBlock'
import { TRANSCRIPT_VIEWER_ID, updateCallExplorerParams } from './helpers'

import './TranscriptViewer.scss'

const TYPE_CONVERSATION = 'conversation'
const TYPE_SILENCE = 'silence'

const POSTPROCESSED_TRANSCRIPT = 'postprocessed-transcript'
const REALTIME_TRANSCRIPT = 'realtime-transcript'
const HYPOTHESIS_TRANSCRIPT = 'hypothesis-transcript'
const allTranscriptOptions = [
  { value: POSTPROCESSED_TRANSCRIPT, text: 'Post-Processed Transcript' },
  { value: REALTIME_TRANSCRIPT, text: 'Real-Time Transcript' },
  { value: HYPOTHESIS_TRANSCRIPT, text: 'Hypothesis Transcript' },
]
const transcriptDisplayValues = {
  [POSTPROCESSED_TRANSCRIPT]: {
    showPostcallTranscript: true,
    showHypothesis: false,
  },
  [REALTIME_TRANSCRIPT]: {
    showPostcallTranscript: false,
    showHypothesis: false,
  },
  [HYPOTHESIS_TRANSCRIPT]: {
    showPostcallTranscript: false,
    showHypothesis: true,
  },
}

export const TranscriptViewerComponent = ({ flags, transcript, postcallTranscript }) => {
  const {
    callExplorer: {
      hardSelectedEvent,
      audioStatus,
      screenCaptureStatus,
      screenCaptureUrls,
      audioProgress: progress,
    },
  } = useSelector((state) => state.callSearch)
  const location = useLocation()
  const history = useHistory()
  const transcriptScrollerRef = useRef(null)
  const transcriptBlockRef = useRef(null)
  const closestTranscriptIndex = useRef(0)
  const showTranscriptSelector = flags?.callsExplorerTranscriptHypothesis
  // Determine the  default transcript and transcript options to show a user
  const hasPostcallTranscript = postcallTranscript && postcallTranscript.length > 0
  const [transcriptType, setTranscriptType] = useState(
    hasPostcallTranscript ? POSTPROCESSED_TRANSCRIPT : REALTIME_TRANSCRIPT
  )
  const [allowAutoScroll, setAllowAutoScroll] = useState(true)
  const transcriptSelectorOptions = hasPostcallTranscript
    ? allTranscriptOptions
    : allTranscriptOptions.slice(1)
  const transcriptToDisplay = transcriptDisplayValues[transcriptType].showPostcallTranscript
    ? postcallTranscript
    : transcript
  const showTranscriptHypothesis = transcriptDisplayValues[transcriptType].showHypothesis
  const transcriptBlocksFirstTimes = transcriptToDisplay.map(
    ({ timestamp: silenceTimeStamp, data: [{ timestamp }] = [{ timestamp: 0 }] }) =>
      silenceTimeStamp || timestamp
  )
  const dispatch = useDispatch()

  useEffect(() => {
    const onTimeUpdate = () => {
      if (transcriptType !== POSTPROCESSED_TRANSCRIPT) {
        closestTranscriptIndex.current = 0
        return
      }
      // if this is slow for long calls we could implement a recursive binary search. Only risk is while it may be faster
      // stack size could grow large which would be problematic for people on potato comps. We'll have to see which problem is more problematic
      // speed or stack size
      let closest = 0
      for (let i = 0; i < transcriptBlocksFirstTimes.length; i++) {
        const currentLoopFirstTime = transcriptBlocksFirstTimes[i]
        if (currentLoopFirstTime <= transcriptScrollerRef.current.currentTime) {
          closest = i
        } else if (currentLoopFirstTime > transcriptScrollerRef.current.currentTime) {
          break
        }
      }
      if (closestTranscriptIndex !== closest) {
        closestTranscriptIndex.current = closest
      }
    }
    if (transcriptScrollerRef.current !== null) {
      transcriptScrollerRef.current.addEventListener('timeupdate', onTimeUpdate)
    }
    return () => {
      if (transcriptScrollerRef.current !== null) {
        transcriptScrollerRef.current.removeEventListener('timeupdate', onTimeUpdate)
      }
    }
  }, [transcriptType])

  useEffect(() => {
    if (
      transcriptBlockRef.current !== null &&
      closestTranscriptIndex.current !== null &&
      allowAutoScroll
    ) {
      if (transcriptBlockRef.current.childNodes[closestTranscriptIndex.current]) {
        transcriptBlockRef.current.childNodes[closestTranscriptIndex.current].scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }, [closestTranscriptIndex.current, transcriptBlockRef.current])

  useEffect(() => {
    // if an event is selected, scroll the container to show the trigger
    // and surrounding transcript context

    if (hardSelectedEvent) {
      // Copy pasted from onTimeUpdate
      let closest = 0
      for (let i = 0; i < transcriptBlocksFirstTimes.length; i++) {
        const currentLoopFirstTime = transcriptBlocksFirstTimes[i]
        if (currentLoopFirstTime <= transcriptScrollerRef.current.currentTime) {
          closest = i
        } else if (currentLoopFirstTime > transcriptScrollerRef.current.currentTime) {
          break
        }
      }
      if (closestTranscriptIndex !== closest) {
        closestTranscriptIndex.current = closest
      }
    }
  }, [hardSelectedEvent])

  const handleAllowAutoScrollChange = () => {
    setAllowAutoScroll(!allowAutoScroll)
  }

  const handleTranscriptSelectionChange = (event, option) => {
    setTranscriptType(option.value)
  }

  const handleTimestampClick = (timestamp) => {
    updateCallExplorerParams(location, history, { timestamp }, ['eventId'])
    dispatch(setHardSelectedEvent({ timestamp }))
    dispatch(setCallExplorerData({ audioPlaying: true }))
  }
  // the controls in full screen also control the audio
  const videoOnPlaying = () => {
    dispatch(setCallExplorerData({ audioPlaying: true }))
  }

  const videoOnPause = () => {
    dispatch(setCallExplorerData({ audioPlaying: false }))
  }

  const videoOnSeeked = (event) => {
    const { currentTime } = event.target
    dispatch(setHardSelectedEvent({ timestamp: currentTime }))
  }

  return (
    <div className="flex-column flex-item" style={{ width: '50%' }}>
      <div>
        <Segment>
          <VideoPlayer
            sources={screenCaptureUrls}
            onPlaying={videoOnPlaying}
            onPause={videoOnPause}
            onSeeked={videoOnSeeked}
            progress={progress}
          />
          <AudioPlayerViewer
            audioStatus={audioStatus}
            screenCaptureStatus={screenCaptureStatus}
            transcriptScrollerRef={transcriptScrollerRef}
          />
        </Segment>
        <header className="call-window-scrollable__headers transcript-viewer-actions">
          <h3>Transcript</h3>
          <div className="transcript-options-container">
            <Radio
              toggle
              data-testid="auto-scroll-toggle"
              checked={allowAutoScroll}
              onChange={handleAllowAutoScrollChange}
              label="Enable Auto-Scroll"
            />
            {showTranscriptSelector && (
              <Dropdown
                icon={null}
                trigger={
                  <Button
                    basic
                    icon="ellipsis vertical"
                    data-testid="transcript-selector"
                    compact
                  />
                }
                floating
                direction="right"
                onChange={handleTranscriptSelectionChange}
                clearable={false}
                value={transcriptType}
                options={transcriptSelectorOptions}
              />
            )}
          </div>
        </header>
      </div>
      <div
        id={TRANSCRIPT_VIEWER_ID}
        className="call-window-scrollable__content transcript-viewer-container"
      >
        <div className="transcript-blocks" data-testid="transcript-blocks" ref={transcriptBlockRef}>
          {transcriptToDisplay?.map((section, index) => {
            const { type, side, data, timestamp, duration } = section
            if (type === TYPE_CONVERSATION) {
              return (
                <div
                  className="transcript-scroll-top"
                  onClick={() => {
                    handleTimestampClick(data[0].timestamp)
                  }}
                >
                  <ConversationBlock
                    key={`conversation-${data[0].timestamp}`}
                    side={side}
                    data={data}
                    showHypothesis={showTranscriptHypothesis}
                    highlighted={closestTranscriptIndex.current === index}
                  />
                </div>
              )
            }
            if (type === TYPE_SILENCE) {
              return (
                <SilenceBlock
                  key={`silence-${timestamp}`}
                  duration={duration}
                  highlighted={closestTranscriptIndex.current === index}
                />
              )
            }
            return null
          })}
        </div>
      </div>
    </div>
  )
}

export const TranscriptViewer = withLDConsumer()(TranscriptViewerComponent)
