import { toast } from 'react-toastify'

import { setData, setLoading } from '@/reducers/realtime/advancedExports/advancedExports.redux'
import { apiService, fetchingAPI } from '@/api'
import { getAdvancedExportsFilters } from '@/reducers/realtime/helpers'

export const fetchAdvancedExportsPreviewData =
  (organizationId, displayedMetrics, groupedBy, filters) => async (dispatch) => {
    dispatch(setLoading({ table: true }))
    try {
      const previewData = await fetchingAPI(
        `${apiService.web}/api/realtime_coaching/reports/advanced_export_preview`,
        'POST',
        dispatch,
        JSON.stringify({
          filters: getAdvancedExportsFilters(filters),
          displayed_metrics: displayedMetrics,
          grouped_by: groupedBy,
          preview: true,
          requested_org: organizationId,
        })
      )
      dispatch(setData({ previewData }))
    } catch (err) {
      toast.error('Failed to fetch advanced exports data')
    } finally {
      dispatch(setLoading({ table: false }))
    }
  }

export const fetchAdvancedExportsFullData =
  (organizationId, displayedMetrics, groupedBy, filters) => async (dispatch) => {
    dispatch(setLoading({ table: true }))
    let data = []
    try {
      data = await fetchingAPI(
        `${apiService.web}/api/realtime_coaching/reports/advanced_export_preview`,
        'POST',
        dispatch,
        JSON.stringify({
          filters: getAdvancedExportsFilters(filters),
          displayed_metrics: displayedMetrics,
          grouped_by: groupedBy,
          preview: false,
          requested_org: organizationId,
        })
      )
    } catch (err) {
      toast.error('Failed to fetch full advanced exports dataset')
    } finally {
      dispatch(setLoading({ table: false }))
    }

    return data
  }
