import { chartColors } from '@/utils/constants'
import { snakeCase } from 'lodash'

const defaultLine = {
  type: 'line',
  smooth: true,
  symbolSize: 0,
  lineStyle: { width: 3, type: 'solid' },
}

export const keyFactorData = [
  { value: 45, name: 'Product Mismatch' },
  { value: 25, name: 'Pricing Concerns' },
  { value: 20, name: 'Budget Limitations' },
  { value: 14, name: 'Decision-Making Delays' },
  { value: 2, name: 'Competitor Advantage' },
  { value: 1, name: 'Insufficient Product Knowledge' },
  { value: 0, name: 'Poor Timing' },
  { value: 0, name: 'Lack of Urgency' },
  { value: 0, name: 'Negative Customer Experience' },
  { value: 0, name: 'Product Availability Issues' },
].map((item, index) => ({
  ...item,
  groupId: snakeCase(item.name),
  color: item.value !== 0 ? chartColors[index] : 'var(--dark-knight-400)', // not used in graph, but useful for table
}))

export const customerCallingData = [
  {
    ...defaultLine,
    triggerLineEvent: true,
    name: 'Technical Support',
    data: [220, 182, 191, 234, 290, 330, 310],
  },
  {
    ...defaultLine,
    triggerLineEvent: true,
    name: 'Customer Inquiry',
    data: [120, 132, 101, 134, 90, 230, 210],
  },
].map((item, index) => ({
  ...item,
  groupId: snakeCase(item.name),
  color: item.value !== 0 ? chartColors[index] : 'var(--dark-knight-400)', // not used in graph, but useful for table
}))

export const pieData = {
  label: 'Key Factor in Missed Sales',
  color: chartColors,
  dataGroupId: '',
  legend: { show: false },
  tooltip: { trigger: 'item' },
  universalTransition: { enabled: true, divideShape: 'clone' },
  emphasis: { focus: 'self' },
  series: {
    id: 'pie1',
    name: 'Key Factor in Missed Sales',
    type: 'pie',
    radius: ['40%', '70%'],
    label: { show: false },
    labelLine: { show: false },
    data: keyFactorData,
  },
}

export const lineData = {
  label: 'Why Customers Are Calling',
  dataGroupId: '',
  color: chartColors,
  tooltip: { trigger: 'axis' },
  legend: { show: false },
  grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    axisLine: { show: true },
    splitLine: { show: false },
  },
  yAxis: {
    type: 'value',
    axisLine: { show: false },
    splitLine: { show: false },
  },
  emphasis: { focus: 'series' },
  series: customerCallingData,
}

export const drillDownPieData = [
  ...keyFactorData.map((item) => ({
    name: item.name,
    dataGroupId: snakeCase(item.name),
    color: item.color,
    data: [
      {
        value: 90,
        name: 'Wrong Label',
        color: chartColors[2],
        itemStyle: { color: chartColors[2] },
      },
      {
        value: 10,
        name: 'Incorrect or Missing Product',
        color: chartColors[3],
        itemStyle: { color: chartColors[3] },
      },
    ],
  })),
]

export const drillDownLineData = [
  ...customerCallingData.map((item) => ({
    label: item.name,
    dataGroupId: snakeCase(item.name),
    color: item.color,
    data: [
      {
        ...defaultLine,
        silent: true,
        data: [90, 10, 5, 0, 20, 10, 70],
        name: 'Billing and Payments',
        color: chartColors[2],
        lineStyle: { color: chartColors[2] },
      },
      {
        ...defaultLine,
        silent: true,
        data: [10, 20, 30, 80, 80, 9, 14],
        name: 'Product Information',
        color: chartColors[3],
        lineStyle: { color: chartColors[3] },
      },
    ],
  })),
]
