import React from 'react'
import { Form, Checkbox } from 'semantic-ui-react'

export const CheckboxGroup = ({
  label,
  field: { name, value, ...fieldProps },
  form: { touched, errors },
  options,
  required,
  inline = true,
  ...props
}) => {
  return (
    <>
      <Form.Field
        required={required}
        error={errors[name] && touched[name] ? { content: errors[name] } : false}
        label={label}
        data-testid={`checkboxGroup-${name}`}
      />
      <Form.Group grouped={!inline}>
        {options.map((option) => (
          <Form.Field key={option.value}>
            <Checkbox
              id={option.value}
              label={option.text || option.label}
              value={option.value}
              checked={value.includes(option.value)}
              name={name}
              {...fieldProps}
              {...props}
            />
          </Form.Field>
        ))}
      </Form.Group>
    </>
  )
}
