// Action Types
export const SET_TASC_RECOMMENDATIONS = 'recommendations/setTascRecommendations'
export const SET_LOADING = 'recommendations/setLoading'
export const SET_RECOMMENDATIONS_CID = 'recommendations/setRecommendationsCid'

// Action Creators
export const setTascRecommendations = (payload) => ({ type: SET_TASC_RECOMMENDATIONS, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setRecommendationsCid = (payload) => ({ type: SET_RECOMMENDATIONS_CID, payload })

export const initialState = {
  recommendationsCid: null,
  tascRecommendations: [],
  loading: false,
}

export default function recommendationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TASC_RECOMMENDATIONS:
      return { ...state, tascRecommendations: action.payload }
    case SET_LOADING:
      return { ...state, loading: action.payload }
    case SET_RECOMMENDATIONS_CID:
      return { ...state, recommendationsCid: action.payload }
    default:
      return state
  }
}
