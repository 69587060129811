import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Input,
  Menu,
  Popup,
  Radio,
  Table,
} from 'semantic-ui-react'
import { cloneDeep } from 'lodash'
import { v4 as uuid } from 'uuid'
import { IconInfoCircle, IconX, IconCheck, IconPencil } from '@tabler/icons-react'

import { Pill } from '@/components/pills/Pill'
import { NestedTable } from '@/components/tables/NestedTable/NestedTable'
import { buildSelectedRowsObject } from '@/components/tables/NestedTable/helpers'

import { convertPlaybookBodyToCriteriaObjects } from '../helpers/index'

import './ScorecardCriteria.scss'

export const ScorecardCriteriaButton = ({
  criteria,
  mustBePresent,
  toggleMustBePresent,
  readOnly,
}) => {
  const [mustBePresentState, setMustBePresentState] = useState(mustBePresent)

  useEffect(() => {
    setMustBePresentState(criteria?.must_be_present)
  }, [criteria])

  if (!criteria?.active) return null

  const handleClick = () => {
    toggleMustBePresent(criteria)
    setMustBePresentState(!mustBePresentState)
  }

  return (
    <Button
      onClick={handleClick}
      data-testid={mustBePresentState ? 'must_be_present' : 'must_not_be_present'}
      basic
      disabled={readOnly}
      compact
      color={mustBePresentState ? 'green' : 'red'}
      content={mustBePresentState ? 'Must Be Present' : 'Must Not Be Present'}
    />
  )
}

export const ScorecardCriteriaWeightInput = ({ criteria, changeCriteriaWeight, readOnly }) => {
  const [currentWeight, setCurrentWeight] = useState(criteria?.weight || 1)

  useEffect(() => {
    setCurrentWeight(criteria?.weight)
  }, [criteria])

  if (!criteria?.active) return null

  const handleWeightChange = ({ target: { value } }) => {
    setCurrentWeight(value)
    changeCriteriaWeight({
      ...criteria,
      weight: value,
    })
  }
  return (
    <Input
      type="number"
      placeholder="1"
      value={currentWeight}
      disabled={readOnly}
      data-testid="criteria-weight-input"
      max={100}
      min={0}
      onChange={handleWeightChange}
    />
  )
}

export const ScorecardCriteriaNameInput = ({ criteria, changeCriteriaName }) => {
  const [nameDraft, setNameDraft] = useState('')
  const [editMode, setEditMode] = useState(false)

  if (!criteria.active) return criteria.name

  const handleNameChange = ({ target: { value } }) => {
    setNameDraft(value)
  }

  const handleEditClick = () => {
    setNameDraft(criteria.name)
    setEditMode(true)
  }

  const handleDismiss = () => {
    setEditMode(false)
  }

  const handleSubmit = () => {
    changeCriteriaName({
      ...criteria,
      name: nameDraft,
    })
    setEditMode(false)
  }

  return (
    <div className="flex-align-center small-gap">
      {editMode ? (
        <>
          <Input
            placeholder="Manual Criteria"
            value={nameDraft}
            onChange={handleNameChange}
            autoFocus
          />
          <div className="flex-align-center small-gap">
            <Button
              secondary
              icon
              className="svg-button"
              onClick={handleDismiss}
              style={{ margin: 0 }}
            >
              <IconX />
            </Button>
            <Button primary icon className="svg-button" onClick={handleSubmit}>
              <IconCheck />
            </Button>
          </div>
        </>
      ) : (
        <>
          <span>{criteria.name}</span>
          <Button secondary icon className="svg-button" onClick={handleEditClick}>
            <IconPencil />
          </Button>
        </>
      )}
    </div>
  )
}

const ScorecardCriteriaSelectorComponent = ({
  flags,
  findInMeasure,
  onClick,
  toggleFail,
  toggleZero,
  toggleCriteriaSettings,
  changeCriteriaWeight,
  changeCriteriaName,
  measureDraft,
  toggleConditionType,
  addCriteriaToMeasure,
  setMeasureDraft,
  scorecardType,
}) => {
  const defaultSection = scorecardType === 'automated' ? 'checklist' : 'manual'
  const [activePlaybookSection, setActivePlaybookSection] = useState(defaultSection)
  const [selectedRows, setSelectedRows] = useState({})
  const [currentRows, setCurrentRows] = useState([])
  const isManualSection = activePlaybookSection === 'manual'

  const handleNestedSelect = (result) => {
    // For manual we need to toggle active
    if (isManualSection) {
      const newMeasureDraft = cloneDeep(measureDraft)
      newMeasureDraft.criteria.forEach((criteria, index) => {
        criteria.active = result[index]?.selected
      })
      setMeasureDraft(newMeasureDraft)
    }
    setSelectedRows(result)
  }

  const [filterBy, setFilter] = useState('')
  const { data } = useSelector((state) => state.scorecards)
  const { body } = data.targetPlaybook

  const autoFail = measureDraft?.auto_fail || false
  const autoZero = measureDraft?.auto_zero || false

  const conditionType =
    measureDraft && measureDraft.condition_type ? measureDraft.condition_type : ''

  let menuItems = []

  if (scorecardType === 'automated') {
    menuItems = [
      {
        label: 'Checklist',
        accessor: 'checklist',
      },
      {
        label: 'Dynamic Prompts',
        accessor: 'deck',
      },
      {
        label: 'Notifications',
        accessor: 'notifications',
      },
      {
        label: 'Post Call',
        accessor: 'classified_postcall',
      },
    ]
  } else if (scorecardType === 'manual') {
    if (flags?.enableManualQa) {
      menuItems.push({
        label: 'Manual',
        accessor: 'manual',
      })
    }
  }

  const handleMenuChange = (name) => {
    setCurrentRows([])
    setActivePlaybookSection(name)
  }

  const handleSearch = (e) => {
    setFilter(e.target.value)
  }

  const columns = [
    {
      label: 'Name',
      accessor: 'name',
    },
    {
      label: 'Present or Not?',
      accessor: 'must_be_present',
    },
  ]

  if (conditionType === 'AND') {
    columns.push({
      label: 'Weight',
      accessor: 'weight',
    })
  }

  const getMenuCount = (name, label) => {
    const newRow = convertPlaybookBodyToCriteriaObjects(
      body,
      name,
      filterBy,
      findInMeasure,
      toggleCriteriaSettings,
      changeCriteriaWeight,
      changeCriteriaName,
      measureDraft
    )
    const selectedRows = buildSelectedRowsObject(newRow, {})
    const count = Object.values(selectedRows).filter(
      (row) => row.bottomLevel && row.selected
    ).length

    if (count > 0) {
      return (
        <div className="flex-align-center small-gap">
          <span className="scorecard-criteria_label">{label}</span>
          <Pill small brand emphasized>
            {count}
          </Pill>
        </div>
      )
    }
    return (
      <div>
        <span className="scorecard-criteria_label">{label}</span>
      </div>
    )
  }

  const addManualCriteria = () => {
    const entryId = uuid()
    const newCriteria = {
      name: 'Manual Criteria',
      active: false,
      criteria_type: 'manual',
      must_be_present: true,
      weight: 1,
      entryId,
      key: entryId,
    }
    addCriteriaToMeasure(newCriteria)

    const newRows = convertPlaybookBodyToCriteriaObjects(
      body,
      'manual',
      filterBy,
      findInMeasure,
      toggleCriteriaSettings,
      changeCriteriaWeight,
      changeCriteriaName,
      measureDraft
    )

    setCurrentRows(newRows)
  }

  useEffect(() => {
    const newRows = convertPlaybookBodyToCriteriaObjects(
      body,
      activePlaybookSection,
      filterBy,
      findInMeasure,
      toggleCriteriaSettings,
      changeCriteriaWeight,
      changeCriteriaName,
      measureDraft
    )

    setCurrentRows(newRows)
  }, [selectedRows, activePlaybookSection, filterBy, measureDraft, onClick])

  const options = [
    {
      key: 1,
      text: 'AND Logic',
      content: (
        <Header
          className="scorecard-criteria_condition_type_text"
          content="AND Logic"
          subheader="All selected items will need to be correct in order
          to receive full credit."
          onClick={() => toggleConditionType('AND')}
          data-testid="and-logic-button"
        />
      ),
      value: 'AND',
    },
    {
      key: '2',
      text: 'OR Logic',
      content: (
        <Header
          className="scorecard-criteria_condition_type_text"
          content="OR Logic"
          subheader="Only one of the selected items will need to be
              correct in order for agents to receive full credit."
          onClick={() => toggleConditionType('OR')}
          data-testid="or-logic-button"
        />
      ),
      value: 'OR',
    },
  ]
  return (
    <Grid data-testid="scorecard-criteria" className="scorecard-criteria" columns={4}>
      <Grid.Row>
        <Grid.Column width={4} floated="left">
          <Dropdown
            className="scorecard-criteria_condition_type"
            selection
            fluid
            options={options}
            placeholder={`${conditionType} Logic`}
            text={`${conditionType} Logic`}
            data-testid={`${conditionType}-condition-type-button`}
          />
        </Grid.Column>
        <Grid.Column width={6} />
        <Grid.Column width={3}>
          <Form className="scorecard-criteria__toggle_box">
            {conditionType !== 'OR' && (
              <Form.Field>
                <div className="scorecard-criteria__toggle">
                  <div className="scorecard-criteria__auto_zero_popup">
                    Auto-zero
                    <Popup
                      inverted
                      position="top right"
                      trigger={<IconInfoCircle className="icon-svg-large status-informative" />}
                      content="With auto-zero on, if any items are missed on the call, this measure will be zeroed out."
                      hideOnScroll
                    />
                  </div>
                  <Radio
                    checked={autoZero}
                    data-testid="autozero-toggle"
                    toggle
                    onClick={() => toggleZero()}
                  />
                </div>
              </Form.Field>
            )}
          </Form>
        </Grid.Column>
        <Grid.Column width={3}>
          <Form className="scorecard-criteria__toggle_box">
            <Form.Field>
              <div className="scorecard-criteria__toggle">
                <div className="scorecard-criteria__auto_fail_popup">
                  Auto-fail
                  <Popup
                    inverted
                    position="top right"
                    trigger={<IconInfoCircle className="icon-svg-large status-informative" />}
                    content="With auto-fail on, if any items are missed on the call, this measure will be zeroed out and the call will be marked as a failure."
                    hideOnScroll
                  />
                </div>
                <Radio
                  checked={autoFail}
                  data-testid="autofail-toggle"
                  toggle
                  onClick={() => toggleFail()}
                />
              </div>
            </Form.Field>
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width={16} className="scorecard-criteria_table_layout">
        <div className="scorecard-criteria-bordered">
          <Menu pointing secondary className="tab-navigation">
            {menuItems.map((menuItem) => (
              <Menu.Item
                name={menuItem.label}
                active={activePlaybookSection === menuItem.accessor}
                onClick={() => handleMenuChange(menuItem.accessor)}
                key={menuItem.label}
              >
                {getMenuCount(menuItem.accessor, menuItem.label)}
              </Menu.Item>
            ))}
          </Menu>
          <div className="flex-align-center gap">
            <Input
              className="scorecard_criteria_search_input"
              fluid
              icon="search"
              name="filter"
              placeholder="Search for criteria..."
              value={filterBy}
              onChange={handleSearch}
            />
            {isManualSection && (
              <Button
                primary
                type="button"
                onClick={addManualCriteria}
                data-testid="add_manual_criteria_button"
                style={{ whiteSpace: 'nowrap' }}
              >
                Add Criteria
              </Button>
            )}
          </div>
        </div>
      </Grid.Column>

      <Grid.Row>
        <Grid.Column width={16}>
          <Grid.Column width={8} className="scorecard-criteria__criteria-list-container">
            {currentRows && currentRows.length !== 0 ? (
              <NestedTable
                columns={columns}
                rows={currentRows}
                onSelect={onClick}
                onNestedSelect={handleNestedSelect}
                selectable
                collapsibleChildren
              />
            ) : (
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan={4}>
                      <div className="empty-table ml-auto" data-testid="empty-list">
                        {isManualSection ? (
                          <div className="scorecard-criteria__no_manual_criteria">
                            <h3>Add Manual Scorecard Criteria</h3>
                            <span>
                              Broaden your call scoring strategies and incorporate prompts that
                              facilitate a more manual review process in Call Explorer.
                            </span>
                            <Button
                              primary
                              type="button"
                              onClick={addManualCriteria}
                              data-testid="add_manual_criteria_button"
                              className="scorecard-criteria__no_manual_criteria_button"
                            >
                              Add Criteria
                            </Button>
                          </div>
                        ) : (
                          'Nothing matches your filter'
                        )}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )}
          </Grid.Column>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export const ScorecardCriteriaSelector = withLDConsumer()(ScorecardCriteriaSelectorComponent)
