import React from 'react'

import Form from '../../../../components/Modal'
import UserForm from '../UserForm'

export const AddNewUserButton = ({
  configs,
  tagCategories,
  createNewUser,
  userOrganizationId,
  orgRealtimeCoachingAccess,
  orgDesktopEnterpriseEnabled,
  tags,
  qaEnabled,
  qaCopilotEnabled,
}) => {
  return (
    <div style={{ margin: '.5rem' }}>
      <Form
        buttonLabel="Add New User"
        header="Add New User"
        buttonId="add-new-user-button"
        form={
          <UserForm
            configs={configs}
            tagCategories={tagCategories}
            handleSubmitUser={createNewUser}
            userOrganizationId={userOrganizationId}
            realtimeCoachingAccessEnabled={orgRealtimeCoachingAccess}
            orgDesktopEnterpriseEnabled={orgDesktopEnterpriseEnabled}
            tags={tags}
            qaEnabled={qaEnabled}
            qaCopilotEnabled={qaCopilotEnabled}
          />
        }
      />
    </div>
  )
}
