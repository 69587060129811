import React from 'react'
import { Placeholder } from 'semantic-ui-react'

import { UserInitial } from '@/components/UserInitial'
import { ExternalLink } from '@/components/ExternalLink'

import './AgentRow.scss'

export const AgentRow = ({
  index,
  agentFullName,
  description,
  descriptionLoading,
  externalLink,
}) => {
  return (
    <div className="agent-row flex-align-center flex-space-between">
      <div>
        <div className="name flex-align-center small-gap">
          <UserInitial userFullName={agentFullName} index={index} />
          <h2>{agentFullName}</h2>
        </div>
        {descriptionLoading ? (
          <Placeholder>
            <Placeholder.Line length="full" />
          </Placeholder>
        ) : (
          description && <div className="description">{description}</div>
        )}
      </div>
      {externalLink && (
        <ExternalLink
          url={externalLink.url}
          label={externalLink.label}
          id={externalLink.id}
          onClick={(event) => {
            event.stopPropagation()
          }}
        />
      )}
    </div>
  )
}
