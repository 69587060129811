import React from 'react'

import Widget from '../../../components/widget/Widget'
import { ChecklistUsageWidget } from '../widgets/ChecklistUsageWidget'
import { ItemsByDateWidget } from '../widgets/ItemsByDateWidget'
import { ItemsByUserWidget } from '../widgets/ItemsByUserWidget'
import { ItemUsageWidget } from '../widgets/ItemUsageWidget'
import { ItemsByCallWidget } from '../widgets/ItemsByCallWidget'
import { AverageUsageByUserWidget } from '../widgets/AverageUsageByUserWidget'

export const ChecklistSection = () => {
  return (
    <div data-testid="reports-checklist" style={{ paddingBottom: '50px' }}>
      <Widget
        widgetId="checklist-usage"
        label="Checklist Usage"
        subtitle="Time series of the count of checklist items used across selected agents"
        subtitlePercent="Time series of average checklist usage across selected agents"
        showGroupBy
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ChecklistUsageWidget />
      </Widget>
      <Widget
        widgetId="checklist-itemUsage"
        label="Checklist Item Usage"
        subtitle="The count of each checklist item usage across selected agents"
        subtitlePercent="Average usage of each checklist item across selected agents. Usage is calculated based on if the item was shown or in the specific playbook the agent was using at the time"
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ItemUsageWidget section="checklist" />
      </Widget>
      <Widget
        widgetId="checklist-itemUsageByDate"
        label="Checklist Item Usage by Date"
        subtitle="Time series of the count of each checklist item usage across selected agents"
        subtitlePercent="Time series of average usage of each checklist item used across selected agents. Usage is calculated based on if the item was shown or in the specific playbook the agent was using at the time"
        showGroupBy
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ItemsByDateWidget section="checklist" />
      </Widget>
      <Widget
        widgetId="checklist-averageUsageByUser"
        label="Average Checklist Usage by User"
        subtitle="Average usage of all checklist items for each selected agents"
        showViewToggle
        showCsvDownload
      >
        <AverageUsageByUserWidget section="checklist" />
      </Widget>
      <Widget
        widgetId="checklist-itemUsageByUser"
        label="Checklist Item Usage by User"
        subtitle="The count of each checklist item usage for each selected agents"
        subtitlePercent="Average usage of each checklist item for each selected agents. Usage is calculated based on if the item was shown or in the specific playbook the agent was using at the time"
        showPercent
        showViewToggle
        showCsvDownload
        percentViewDefault={false}
      >
        <ItemsByUserWidget section="checklist" />
      </Widget>
      <Widget
        widgetId="checklist-itemWins"
        label="Checklist Item Wins"
        subtitle="The count of won calls of each checklist item across selected agents"
        subtitlePercent="Average win rate of each checklist item across selected agents"
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ItemUsageWidget section="checklist" isWin />
      </Widget>
      <Widget
        widgetId="checklist-itemUsageByCall"
        label="Checklist Item Usage by Call"
        subtitle="Matrix table of calls, where each row represents a call and each column is a checklist item, with colored cells indicating usage"
      >
        <ItemsByCallWidget section="checklist" />
      </Widget>
    </div>
  )
}
