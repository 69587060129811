import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
  getAgentNames,
  getAgentsByTags,
  getAllAgentsByAllTags,
  getGroupTags,
  getUniqueAgents,
  removeAgentsByTags,
} from '@/views/Gamification/Challenges/helpers'
import { closeModal } from '@/reducers/ui/ui.redux'
import { FormModal } from '@/components/layout/modals/FormModal'
import { MultiSelect } from '@/components/forms/MultiSelect'

export const AgentTagPicker = ({
  challengeGroup,
  challengeStatus,
  orderedGroups,
  tagOptions,
  agentOptions,
  onSave,
  loading,
  disableSaveButton,
  disableClearButton,
  disableForm = false,
}) => {
  const dispatch = useDispatch()
  const { currentlyOpenModalId: modal } = useSelector((state) => state.ui)

  const [group, setGroup] = useState(challengeGroup)
  const [groupInBadState, setGroupInBadState] = useState(false)
  const groupTags = getGroupTags(group, agentOptions, tagOptions)

  useEffect(() => {
    setGroup(challengeGroup)
  }, [challengeGroup])

  const onClose = () => {
    setGroup({ ...challengeGroup })
    dispatch(closeModal())
  }

  const handleAgentOrTagSelect = (selectedAgentOptions, selectedTagOptions = []) => {
    const updatedGroup = { ...group, agents: selectedAgentOptions, tags: [...selectedTagOptions] }
    setGroup(updatedGroup)
  }

  const handleAgentsSelect = (selectedAgentOptions) => {
    let updatedTagOptions = []
    if (group?.tags) {
      updatedTagOptions = [...group.tags]
    }
    if (challengeStatus !== 'inactive') {
      setGroupInBadState(!selectedAgentOptions.length)
    }
    handleAgentOrTagSelect(selectedAgentOptions, updatedTagOptions)
  }

  const handleSelectingAgentsByTags = (selectedTagOptions) => {
    const agentNames = getAgentsByTags(agentOptions, selectedTagOptions, [...group.agents])
    const uniqueAgents = getUniqueAgents(agentNames)
    handleAgentOrTagSelect(uniqueAgents, selectedTagOptions)
  }

  const handleDeselectingAgentsByTags = (selectedTagOptions, clickEvent) => {
    let agentNames = []
    if (clickEvent.action === 'deselect-option') {
      agentNames = getAgentNames(agentOptions, clickEvent, selectedTagOptions)
    }
    if (clickEvent.action === 'remove-value') {
      clickEvent.option = clickEvent.removedValue
      agentNames = getAgentNames(agentOptions, clickEvent, selectedTagOptions)
    }
    if (clickEvent.action === 'clear') {
      agentNames = getAllAgentsByAllTags(group.agents, clickEvent)
    }
    const remainingAgents = removeAgentsByTags(group, agentNames, selectedTagOptions)
    const uniqueAgents = getUniqueAgents(remainingAgents)
    handleAgentOrTagSelect(uniqueAgents, selectedTagOptions)
  }

  const handleTagSelect = (selectedTagOptions, clickEvent) => {
    if (clickEvent.action === 'select-option') {
      return handleSelectingAgentsByTags(selectedTagOptions)
    }
    return handleDeselectingAgentsByTags(selectedTagOptions, clickEvent)
  }

  if (modal !== `groupAgentPicker--${challengeGroup.groupId}`) {
    return null
  }

  return (
    <div data-testid="challenge-modal">
      <FormModal
        title="Add Participants"
        size="small"
        onSave={() => onSave(group, orderedGroups)}
        onClose={onClose}
        closeButtonLabel="Cancel"
        disableSubmitButton={disableClearButton || disableSaveButton || groupInBadState}
        submitButtonLabel="Save"
        show={modal === `groupAgentPicker--${challengeGroup.groupId}`}
        isLoading={loading.challenges}
        modalClassName="challenges-modal"
      >
        <div className="form-modal__challenge-content">
          <div className="form-modal__challenge-content_container">
            <div data-testid="challenge-tags" className="challenge-agents">
              <div
                data-testid="challenge-tag-header"
                className="form-modal__challenge-content-header"
              >
                Tags
              </div>
              <MultiSelect
                isSelectAll
                placeholder="Search..."
                resource="Tags"
                options={tagOptions}
                loading={loading?.tags}
                value={groupTags}
                onChange={(value, event) => handleTagSelect(value, event)}
                removeDropdown
                menuIsOpen
                isDisabled={disableForm}
              />
            </div>
          </div>
          <div className="form-modal__challenge-content_container">
            <div data-testid="challenge-agents" className="challenge-agents">
              <div
                data-testid="challenge-agent-header"
                className="form-modal__challenge-content-header"
              >
                Agents
              </div>
              <MultiSelect
                isSelectAll
                placeholder="Search..."
                resource="Agents"
                options={agentOptions}
                loading={loading?.agents}
                value={group.agents}
                onChange={(value) => handleAgentsSelect(value)}
                removeDropdown
                menuIsOpen
                isDisabled={disableForm}
              />
            </div>
          </div>
        </div>
      </FormModal>
    </div>
  )
}
