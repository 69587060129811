import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateIntegration } from '@/reducers/integrations/integrations.actions'
import { Prompt } from 'react-router-dom'
import { useHistory } from 'react-router'
import { setDirty, setModal, setTempIntegration } from '@/reducers/integrations/integrations.redux'
import { FormModal } from '@/components/layout/modals/FormModal'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { getEditSchema, buildDefaults } from './integrations.schema'
import { diffIntegrations, getConnectorDisplayType } from './helpers'

const IntegrationEditorSettings = () => {
  const dispatch = useDispatch()
  const { integration, modal, tempIntegration, dirty } = useSelector((state) => state.integrations)
  const schema = getEditSchema(integration?.type) || []
  const history = useHistory()
  const formRef = useRef()
  const toggle_active_save_message = { true: 'block', false: 'none' }

  // this value is ignored in the backend but is used to make it more readable for FE
  integration.display_type = getConnectorDisplayType(integration.type)

  const handleSubmit = ({ ...values }) => {
    const tempValue = values
    delete tempValue.validateOnMount
    dispatch(setTempIntegration(tempValue))
    JSON.stringify(diffIntegrations(integration, tempValue)) === '{}'
      ? dispatch(setModal('no-changes'))
      : dispatch(setModal('save'))
  }

  const handleDirty = (isDirty) => {
    // Must check equality or else it will infinite loop
    if (isDirty !== dirty) {
      dispatch(setDirty(isDirty))
    }
  }

  // Prompt react component does not work on page refresh so this is used for unsaved work popup
  if (dirty) {
    window.onbeforeunload = () => true
  } else {
    window.onbeforeunload = () => null
  }

  const dispatchCloseModal = () => {
    if (formRef.current) {
      formRef.current.setSubmitting(false)
    }
    dispatch(setModal(false))
  }

  const saveConfig = () => {
    dispatch(setDirty(false))
    window.onbeforeunload = () => null
    dispatch(setModal(false))
    for (const key in tempIntegration) {
      if (typeof tempIntegration[key] === 'string' || tempIntegration[key] instanceof String) {
        tempIntegration[key] = tempIntegration[key].trim()
      }
    }
    dispatch(updateIntegration({ id: integration.id, values: tempIntegration, push: history.push }))
  }

  return (
    <div>
      {dirty ? (
        <Prompt when={dirty} message="You have unsaved changes. Are you sure you want to leave?" />
      ) : (
        <Prompt
          when={!integration?.active}
          message="You have not activated the configuration. Are you sure you want to leave?"
        />
      )}

      {modal === 'save' && (
        <FormModal
          title="Save Integration Configuration"
          onSave={saveConfig}
          onClose={dispatchCloseModal}
          closeButtonLabel="Cancel"
          submitButtonLabel="Save"
          show={modal}
        >
          <div
            data-testid="confirm-active-modal"
            className="integration-editor__active-modal-content"
          >
            The following fields have been changed. Do you want to save? <br />
            {JSON.stringify(diffIntegrations(integration, tempIntegration), null, 4)}
          </div>
          <b style={{ display: toggle_active_save_message[integration?.active] }}>
            <br />
            Changes saved to an active config will not take affect until midnight or is deactivated
            deactivated and reactivated.
            <br />
            WARNING: deactivating an integration may cause ongoing calls to not behave correctly.
          </b>
        </FormModal>
      )}
      {modal === 'no-changes' && (
        <BasicModal show={modal} title="No changes" onClose={dispatchCloseModal}>
          There are no changes to save
        </BasicModal>
      )}
      <AbstractForm
        enableReinitialize
        schema={schema}
        existingValues={buildDefaults(integration, schema)}
        buttonLabel="Save"
        onSubmit={handleSubmit}
        onDirty={handleDirty}
        innerRef={(f) => {
          formRef.current = f
        }}
      />
      {integration.type === 'zoom' && (
        <div className="integration-zoom-info-outer">
          <div className="integration-zoom-info-inner">
            <span className="integration-zoom-info">INFO: </span>
            This integration provides start/stop for both Zoom meetings and phone. You can select
            either or both when configuring the webhook app on Zoom&apos;s side. The webhook log,
            which appears in the agent connector service, exposes if a call was a meeting or phone
            call.
          </div>
        </div>
      )}
    </div>
  )
}

export default IntegrationEditorSettings
