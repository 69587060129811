import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { first } from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import {
  IconUser,
  IconClock,
  IconX,
  IconChevronLeft,
  IconChevronRight,
  IconHeadphones,
} from '@tabler/icons-react'

import { formatTime } from '@/utils/helpers'
import { fetchScoreById } from '@/reducers/scorecards/scorecards.actions'
import { ExternalLink } from '@/components/ExternalLink'
import { Pill } from '@/components/pills/Pill'

import { MeasureScoresTable } from '../MeasureScoresTable/MeasureScoresTable'
import { returnScoreColor, ScoreAccordion } from '../ScoreAccordion/ScoreAccordion'
import { calculatePercentage } from './helpers'

import './callScoreCarousel.css'

const CallScoreCarouselItem = ({ sectionScore, copilot }) => {
  const score = calculatePercentage(sectionScore.aggregate_score, sectionScore.possible_score)

  return (
    <ScoreAccordion score={score} title={sectionScore.name}>
      <MeasureScoresTable
        measureScores={sectionScore.measure_scores}
        copilot={copilot}
        canViewCriteria
      />
    </ScoreAccordion>
  )
}

export const CallScoreMetadata = ({ user, call_duration, voipId, voipMetadata, call_id }) => {
  const voipLinkLabel = () => {
    return voipId || 'Listen to Call'
  }
  return (
    <>
      <div className="call-score-carousel__metadata">
        <span data-testid="call-score-carousel-user-name">
          <IconUser className="icon-svg" />
          {user}
        </span>
        <span>
          <IconClock className="icon-svg" />
          {`${call_duration} mins`}
        </span>
        {voipMetadata?.voip_call_url && (
          <span>
            <IconHeadphones className="icon-svg" />
            <a
              target="_blank"
              href={voipMetadata.voip_call_url}
              rel="noreferrer"
              data-testid="voipUrlLink"
            >
              {voipLinkLabel()}
            </a>
          </span>
        )}
      </div>
      <div className="call-score-carousel__metadata" style={{ marginLeft: 'auto' }}>
        <span className="external-link">
          <ExternalLink
            url={`../call-explorer/${call_id}`}
            target="_blank"
            label="Explore Call"
            id={call_id}
          />
        </span>
      </div>
    </>
  )
}

const CallScoreCarouselPage = ({ callScoreId, closeCarousel, numPages, pageNumber, copilot }) => {
  const dispatch = useDispatch()
  const { data } = useSelector((state) => state.scorecards)
  const { targetScorecardConfig } = data
  const { currentScore, selectedScorecards } = data
  const {
    user_name: userName,
    section_scores: sectionScores,
    aggregate_score: aggregateScore,
    possible_score: possibleScore,
    voip_metadata: voipMetadata,
    auto_failed,
    auto_zeroed,
    call_id,
  } = currentScore
  const { voipId } = first(selectedScorecards)
  const percentageScore = calculatePercentage(aggregateScore, possibleScore)
  const successClass = returnScoreColor(percentageScore, targetScorecardConfig)
  const callStartTime = formatTime(currentScore.start_time)
  const startTime = moment(currentScore.start_time)
  const stopTime = moment(currentScore.end_time)
  const diff = stopTime.diff(startTime, 'minutes')

  useEffect(() => {
    dispatch(fetchScoreById(callScoreId))
  }, [dispatch, callScoreId])

  return (
    <div className="call-score-carousel__page" data-testid="call-score-carousel-page">
      <div className="call-score-carousel__title" data-testid="call-score-carousel-header">
        <h3 data-testid="call-score-carousel-title">{callStartTime}</h3>
        <Button icon compact basic onClick={closeCarousel} className="svg-button">
          <IconX />
        </Button>
      </div>
      <div className="call-score-carousel__content">
        <CallScoreMetadata
          user={userName}
          call_id={call_id}
          call_duration={diff}
          voipId={voipId}
          voipMetadata={voipMetadata}
        />
        {!sectionScores && (
          <h3 className="call-score-carousel__empty-scores">There are no scores.</h3>
        )}
        {currentScore &&
          sectionScores &&
          sectionScores
            .sort((a, b) => a.order_id - b.order_id)
            .map((sectionScore) => (
              <CallScoreCarouselItem
                sectionScore={sectionScore}
                key={sectionScore.name}
                copilot={copilot}
              />
            ))}
        <span className="call-score-carousel__overall-score-section">
          {auto_failed && (
            <Pill emphasized small critical data-testid="autofail-badge">
              Auto-fail
            </Pill>
          )}
          {auto_zeroed && (
            <Pill emphasized small caution data-testid="autozero-badge">
              Auto-zero
            </Pill>
          )}
          <h3>
            <span style={{ marginRight: '.5rem' }}>Call Score:</span>
            <span
              className={classNames({
                'status-success': successClass === 'exceeds_threshold',
                'status-warning': successClass === 'meets_threshold',
                'status-caution': successClass === 'improvement_threshold',
                'status-critical': successClass === 'failure',
              })}
            >
              {calculatePercentage(aggregateScore, possibleScore)}%
            </span>
          </h3>
        </span>
      </div>
      <div className="call-score-carousel__footer" data-testid="call-score-carousel-footer">
        {`${pageNumber} of ${numPages}`}
      </div>
    </div>
  )
}

export const CallScoreCarousel = ({ callScoreIds, closeCarousel, copilot }) => {
  const { data } = useSelector((state) => state.scorecards)
  const [scoreId, setScoreId] = useState(first(data.selectedScorecards)?.id)
  const [currentPage, setCurrentPage] = useState(
    callScoreIds.indexOf(first(data.selectedScorecards)?.id)
  )
  useEffect(() => {
    setScoreId(first(data.selectedScorecards)?.id)
  }, [data.selectedScorecards])
  const numPages = callScoreIds.length
  const pageNumber = currentPage + 1
  const previousPage = () => {
    if (currentPage !== 0) {
      setScoreId(callScoreIds[currentPage - 1])
      setCurrentPage(currentPage - 1)
    }
  }

  const nextPage = () => {
    if (currentPage !== callScoreIds.length - 1) {
      setScoreId(callScoreIds[currentPage + 1])
      setCurrentPage(currentPage + 1)
    }
  }

  return (
    <div className="call-score-carousel" data-testid="call-score-carousel">
      <Button
        secondary
        icon
        className="svg-button"
        onClick={previousPage}
        data-testid="previous-page-button"
        aria-label="previous-page-button"
      >
        <IconChevronLeft />
      </Button>
      <CallScoreCarouselPage
        callScoreId={scoreId}
        pageNumber={pageNumber}
        closeCarousel={closeCarousel}
        numPages={numPages}
        copilot={copilot}
      />
      <Button
        secondary
        icon
        className="svg-button"
        onClick={nextPage}
        data-testid="next-page-button"
        aria-label="next-page-button"
      >
        <IconChevronRight />
      </Button>
    </div>
  )
}
