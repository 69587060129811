import React from 'react'

import './AlertTableRowName.css'

export const AlertTableRowName = ({ type, alertName, count = -1 }) => {
  return (
    <div className="flex alert-table-row" data-testid="alert-table-row">
      <div className="blue flex">
        {type === 'positive' && <div data-testid="bar-positive" className="bar-positive" />}
        {type === 'negative' && <div data-testid="bar-negative" className="bar-negative" />}
        {type === 'informative' && (
          <div data-testid="bar-informative" className="bar-informative" />
        )}
      </div>
      <span className="alert-name" data-testid="alert-name">
        {alertName}
      </span>
      {count > 0 && (
        <span data-testid="alert-group-count" className="alert-name alert-group-count">
          {' '}
          ({count})
        </span>
      )}
    </div>
  )
}
