import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AlertSidebarDropdown } from '@/views/RealtimeCoaching/Alerts/AlertSidebar/AlertSidebarDropdown'
import { setScrollToAlertTime } from '@/reducers/realtime/alerts/realtimeUserAlerts.redux'

export const GroupedAlerts = ({ agentUsername, groupedAlerts, expanded, toggleExpand }) => {
  const dispatch = useDispatch()
  const { listeningToUsername } = useSelector((state) => state.realtimeUserAlerts)
  const activelyListeningToUser = agentUsername === listeningToUsername
  const borderColorTypes = {
    positive: 'var(--color-fill-success)',
    negative: 'var(--color-fill-critical)',
    informative: 'var(--color-fill-informative)',
  }
  return groupedAlerts.map(
    ({ type, alert_config_id: alertConfigId, name: alertName, count, timeStamp, created }) => {
      // TODO: We should change 'progress' to be the new timestamp once it is added
      return (
        <div key={created} className="flex alert-name-container">
          <div
            style={{ borderLeftColor: borderColorTypes[type] }}
            className={`alert-sidebar-name ${activelyListeningToUser ? 'clickable' : ''}`}
            data-testid={`alert-name-${agentUsername}-${alertName}`}
            onClick={() => {
              if (activelyListeningToUser) {
                if (created.length === 1) {
                  dispatch(setScrollToAlertTime(created[0]))
                } else {
                  toggleExpand(alertConfigId, agentUsername)
                }
              }
            }}
          >
            {alertName} {count > 1 && <span data-testid="alert-group-count"> ({count})</span>}
          </div>
          <AlertSidebarDropdown
            activelyListeningToUser={activelyListeningToUser}
            agentUsername={agentUsername}
            alertName={alertName}
            count={count}
            created={created}
            expanded={expanded[`${agentUsername}_${alertConfigId}`]}
            timeStamp={timeStamp}
            toggleExpand={() => toggleExpand(alertConfigId, agentUsername)}
            type={type}
          />
        </div>
      )
    }
  )
}
