import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { IconEye, IconEyeClosed } from '@tabler/icons-react'

import { reorder } from '@/utils/helpers'

import { DragAndDrop, Drag, Drop } from '../../dragDrop'

import './EditColumnsForm.scss'

export const EditColumnsForm = ({ handleSubmit, allColumns, loading, onClose }) => {
  const [tempColumns, setTempColumns] = useState(allColumns)

  const handleReorder = (result) => {
    const { source, destination } = result
    if (!destination) return

    const reorderedItems = reorder(tempColumns, source.index, destination.index)
    setTempColumns(reorderedItems)
  }

  const handleUpdateColumnVisibility = (accessor, isHidden) => {
    setTempColumns(
      tempColumns.map((column) =>
        column.accessor === accessor ? { ...column, is_hidden: !isHidden } : column
      )
    )
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()

        handleSubmit(tempColumns)

        onClose()
      }}
    >
      <div className="edit-columns-form">
        <div className="edit-columns-row" style={{ padding: '0 1rem' }}>
          <div className="label">Column Name</div>
          <div className="label">Visibility</div>
        </div>
        <DragAndDrop onDragEnd={handleReorder}>
          <Drop droppableId="droppable">
            {tempColumns.map((column, index) => {
              return (
                <Drag
                  key={column.accessor}
                  draggableId={column.accessor}
                  index={index}
                  alwaysShowIcon
                  showDragStyle
                  isModal
                >
                  <div
                    data-testid={`edit-columns-row-${column.accessor}`}
                    className="edit-columns-row"
                  >
                    <div>
                      <strong>
                        {
                          allColumns.find((everyColumn) => everyColumn.accessor === column.accessor)
                            ?.label
                        }
                      </strong>
                    </div>
                    <Button
                      icon
                      type="button"
                      className="minimal-button"
                      position="top center"
                      onClick={() =>
                        handleUpdateColumnVisibility(column.accessor, column.is_hidden)
                      }
                    >
                      {column.is_hidden ? (
                        <IconEyeClosed
                          data-testid={`edit-columns-row-${column.accessor}-closed-eye`}
                          className="icon-svg"
                        />
                      ) : (
                        <IconEye
                          data-testid={`edit-columns-row-${column.accessor}-open-eye`}
                          className="icon-svg"
                        />
                      )}
                    </Button>
                  </div>
                </Drag>
              )
            })}
          </Drop>
        </DragAndDrop>
      </div>
      <div className="modal-footer">
        <Button secondary data-testid="abstract-form-close" type="button" onClick={onClose}>
          Discard
        </Button>
        <Button
          data-testid="edit-columns-form-submit"
          primary
          type="submit"
          loading={loading}
          disabled={loading}
        >
          Save
        </Button>
      </div>
    </form>
  )
}
