class BroadcastClient {
  constructor(name) {
    this.name = name
    this.broadcastChannel = null
    this.unsupportedBrowser = false
  }

  init() {
    if (!this.broadcastChannel) {
      try {
        this.broadcastChannel = new BroadcastChannel(this.name)
      } catch (err) {
        this.unsupportedBrowser = true
        console.error(err)
      }
    }
  }

  on(eventName, callback) {
    this.broadcastChannel.addEventListener(eventName, callback)
  }

  close() {
    if (this.broadcastChannel) {
      this.broadcastChannel.close()
      this.broadcastChannel = null
    }
  }
}

export { BroadcastClient }
