import React from 'react'
import { Form } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { Field } from 'formik'

import { TextField } from '@/components/forms/formik'
import { FieldWithLabel } from '@/components/forms/formik/FieldWithLabel'
import RichTextEditor from '@/components/richTextEditor/RichTextEditor'

import { PlaybookFormActions } from '../../components/PlaybookFormActions'

export const StickyNoteForm = ({
  handleSubmit,
  handleCancel,
  errors,
  readOnly,
  ...formikProps
}) => {
  const submitDisabled = readOnly || !isEmpty(errors)

  return (
    <Form onSubmit={handleSubmit} className="playbook-detail__content">
      <Field
        required
        label="Name"
        name="name"
        placeholder="e.g. Reminder, 5 Key Behaviors, ..."
        component={TextField}
        disabled={readOnly}
      />
      <FieldWithLabel
        label="Display"
        name="display.html"
        component={RichTextEditor}
        disabled={readOnly}
      />
      <PlaybookFormActions
        isDisabled={submitDisabled}
        handleCancel={handleCancel}
        dirty={formikProps.dirty}
      />
    </Form>
  )
}
