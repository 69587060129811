import React from 'react'
import { Popup, Table } from 'semantic-ui-react'
import { capitalize } from 'lodash'

export const ReviewPageSettingsTable = ({ name, alertType, audible, active, goToPageNumber }) => {
  const maxTextLen = 70

  const createPopup = (text) => {
    const isPopupDisabled = text.length < maxTextLen
    const truncatedText = isPopupDisabled ? text : `${text.slice(0, maxTextLen - 1)}…`
    return (
      <Popup
        position="top center"
        disabled={isPopupDisabled}
        trigger={<span>{truncatedText}</span>}
        content={text}
      />
    )
  }
  return (
    <Table className="alert-wizard__review-page-table" basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="alert-wizard__review-table-header-cell">
            Settings
          </Table.HeaderCell>
          <Table.HeaderCell
            className="alert-wizard__review-edit-button alert-wizard__review-table-header-cell"
            textAlign="right"
            onClick={() => goToPageNumber(0)}
            data-testid="edit-settings"
          >
            Edit
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell className="alert-wizard__review-table-cell">Name</Table.Cell>
          <Table.Cell
            className="alert-wizard__review-table-cell"
            textAlign="right"
            data-testid="review-page-name"
          >
            {createPopup(name)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="alert-wizard__review-table-cell">Type</Table.Cell>
          <Table.Cell
            className="alert-wizard__review-table-cell"
            textAlign="right"
            data-testid="review-page-trigger-type"
          >
            {capitalize(alertType)}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell className="alert-wizard__review-table-cell">Audible Notifications</Table.Cell>
          <Table.Cell
            className="alert-wizard__review-table-cell"
            textAlign="right"
            data-testid="review-page-audible"
          >
            {audible ? 'Enabled' : 'Disabled'}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="alert-wizard__review-table-cell">Status</Table.Cell>
          <Table.Cell
            className="alert-wizard__review-table-cell"
            textAlign="right"
            data-testid="review-page-status"
          >
            {active ? 'Enabled' : 'Disabled'}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}
