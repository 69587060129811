import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Form } from 'semantic-ui-react'
import { capitalize } from 'lodash'
import { applyFilters } from '@/reducers/realtime/reports/realtimeReports.actions'
import { BaltoAdminOrganizationFilter } from '@/views/RealtimeCoaching/components/filters/BaltoAdminOrganizationFilter'
import { DateRangePicker } from '@/views/RealtimeCoaching/components/filters/DateRangePicker'
import { AgentsDropdown } from '@/views/RealtimeCoaching/components/filters/AgentsDropdown'
import { TagsDropdown } from '@/views/RealtimeCoaching/components/filters/TagsDropdown'
import { AlertTypesDropdown } from '@/views/RealtimeCoaching/components/filters/AlertTypesDropdown'
import { OrgAlertNameDropdown } from '@/views/RealtimeCoaching/components/filters/OrgAlertNameDropdown'
import { GroupByFilter } from '@/views/RealtimeCoaching/components/filters/GroupByFilter'
import { ManagersDropdown } from '@/views/RealtimeCoaching/components/filters/ManagersDropdown'

export const DrillDownReportsFilters = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { resource, tableType } = location.state
  const { currentUser } = useSelector((state) => state)
  const { organization: RTCFiltersOrgId, dateRangeIsValid } = useSelector(
    (state) => state.realtimeCoaching.filters
  )
  const organizationId = RTCFiltersOrgId || currentUser.organizationid
  const isBaltoAdmin = currentUser.organizationid === 1

  const handleFilterSubmit = () => {
    dispatch(applyFilters(resource, organizationId))
  }

  // Populates filter values on load if needed
  useEffect(() => {
    handleFilterSubmit()
  }, [])

  return (
    <>
      <header className="page-header">
        <h1 data-testid="drilldown-header">
          <Link to="/realtime_coaching/reports">Reports </Link>/ {capitalize(resource)} Reports
        </h1>
      </header>
      <Form
        className="filter-form"
        data-testid="agents-reports-filters"
        onSubmit={() => {
          handleFilterSubmit()
        }}
      >
        <div className="filter-grid">
          {isBaltoAdmin && <BaltoAdminOrganizationFilter />}
          <DateRangePicker />

          {tableType === 'supervisorOccurrences' && <ManagersDropdown />}

          {(tableType === 'alert' ||
            tableType === 'supervisorOccurrences' ||
            resource === 'agent') && <AgentsDropdown />}

          {(tableType === 'alert' ||
            tableType === 'supervisorOccurrences' ||
            resource === 'tag') && <TagsDropdown />}
          <AlertTypesDropdown />
          <OrgAlertNameDropdown />
          <GroupByFilter />
        </div>

        <div className="filter-buttons">
          <Form.Field>
            <label className="visibility-hidden">&nbsp;</label>
            <Button
              primary
              disabled={!dateRangeIsValid}
              data-testid="filter-button"
              type="submit"
              loading={false}
            >
              Filter
            </Button>
          </Form.Field>
        </div>
      </Form>
    </>
  )
}
