import React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, toArray } from 'lodash'
import { IconAlertTriangle } from '@tabler/icons-react'
import uuid from 'uuid/v4'

import { Banner } from '@/components/banners/Banner'

export const FieldWarning = ({ accessor, field, entryId, nestedEntryId, updateTriggerWithAi }) => {
  const { warnings } = useSelector((state) => state.playbook)
  let entryWarningsForField = []

  if (nestedEntryId) {
    entryWarningsForField = toArray(
      warnings[accessor]?.[entryId]?.decklist_warnings?.[nestedEntryId]
    ).filter((warning) => warning?.field === field)
  } else {
    entryWarningsForField = toArray(warnings[accessor]?.[entryId]?.warnings).filter(
      (warning) => warning?.field === field
    )
  }

  if (isEmpty(entryWarningsForField)) {
    return null
  }

  return (
    <Banner warning>
      {entryWarningsForField.map((warning) => (
        <p className="flex-start medium-gap" key={uuid()}>
          <IconAlertTriangle className="icon-svg" />
          <div>
            {warning.headline && <strong>{warning.headline}:</strong>} <span>{warning.detail}</span>
            {warning.krid && (
              <span onClick={() => updateTriggerWithAi(warning.krid)} className="ai-suggestion">
                Update
              </span>
            )}
          </div>
        </p>
      ))}
    </Banner>
  )
}
