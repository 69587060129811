import React from 'react'

import { CategoryEntryDisplay } from './CategoryEntryDisplay'
import { CategoriesSidebarDisplay } from './CategoriesSidebarDisplay'

export const CategoryDisplay = () => {
  return (
    <div className="playbook-category-and-entry-wrapper">
      <CategoriesSidebarDisplay />
      <CategoryEntryDisplay />
    </div>
  )
}
