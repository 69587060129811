import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { IconPencil } from '@tabler/icons-react'

import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { updatePlaybookName } from '@/reducers/playbooks/playbook.redux'

export const PlaybookHeader = ({ playbook, readOnly }) => {
  const dispatch = useDispatch()
  const [editing, setEditing] = useState(false)
  const canEditPlaybookName = !editing && !readOnly

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false)
    dispatch(updatePlaybookName(values))
    setEditing(false)
  }

  return (
    <header className="playbook-header" data-testid="playbook-header">
      <div className="playbook-header__name">
        {!editing ? (
          <h1>
            {playbook.name} {readOnly && '[Read Only]'}
          </h1>
        ) : (
          <>
            <AbstractForm
              horizontal
              onSubmit={handleSubmit}
              existingValues={{ name: playbook.name }}
              schema={[{ name: 'name', type: 'text', label: 'Playbook Name', required: true }]}
              buttonLabel="Update"
              closeButtonLabel="Cancel"
              onClose={() => setEditing(false)}
            />
          </>
        )}
        {canEditPlaybookName && (
          <Button
            icon
            compact
            basic
            className="svg-button"
            onClick={() => setEditing(!editing)}
            data-testid="edit-playbook-name"
          >
            <IconPencil />
          </Button>
        )}
      </div>
    </header>
  )
}
