import { fetchingAPI, apiService } from '../../api'
import { setOrganizationCustomBranding, setLoading } from './organizationCustomBranding.redux'

export const fetchOrganizationCustomBranding = (organizationId) => async (dispatch) => {
  dispatch(setLoading(true))

  try {
    const customBranding = await fetchingAPI(
      `${apiService.web}/api/custom_branding/${organizationId}`,
      'GET',
      dispatch
    )
    dispatch(setOrganizationCustomBranding(customBranding))
  } catch (err) {
    // catching error
  } finally {
    dispatch(setLoading(false))
  }
}
