/* eslint-disable no-param-reassign */
// disabling this lint rule because the canvas gets passed around and you're SUPPOSED to reassign values
import { createLinearScale } from '@nivo/scales'
import { defaultTheme } from '@nivo/core'
import { LayeredLineGraph } from '@/components/charts/customCharts/LayeredLineGraph'

// when we render our own shit, the default theme doesn't play well.
// text is transparent sometimes, or fontSizes don't exist
export const useCustomNivoTheme = () => {
  const theme = defaultTheme
  return {
    ...theme,
    axis: {
      ...theme.axis,
      ticks: {
        ...theme.axis.ticks,
        text: {
          fontFamily: 'sans-serif',
          fontSize: 11,
          fill: '#333333',
        },
      },
    },
    legends: {
      ...theme.legends,
      text: {
        fill: '#333333',
        fontFamily: 'sans-serif',
        fontSize: 11,
      },
    },
  }
}

export const drawCircle = (
  canvasContext,
  size,
  x = Math.sqrt(size / Math.PI),
  y = 0,
  canvasHeight,
  maxValue,
  color
) => {
  // find the proportion of our y value in comparison to the size of the canvas
  const adjustedYValue = canvasHeight - (y / maxValue) * canvasHeight

  // draw our circle
  canvasContext.beginPath()
  canvasContext.moveTo(x, adjustedYValue)
  canvasContext.arc(x, adjustedYValue, size, 0, 2 * Math.PI)
  canvasContext.fillStyle = color
  canvasContext.fill()
}

// takes non-proportional max value and draws a line in the graph based on the scale
export const drawLine = (
  canvasContext,
  canvasHeight,
  maxValue,
  bars,
  dataPoints,
  color = '#4D63FE'
) => {
  if (bars?.length && dataPoints?.length) {
    // loop through bar-graph data to draw line where it needs to go
    bars.forEach((bar, index) => {
      const { y } = dataPoints[index]
      const { x, width } = bar
      const hasMoreBars = bars.length > index + 1

      // find the middle of the bar
      const currentX = x + width / 2
      // in canvases, larger numbers means further down. we need to basically flip that
      const currentY = canvasHeight - (y / maxValue) * canvasHeight

      if (hasMoreBars) {
        // get data from next bar
        const nextBarindex = index + 1
        const { y: nextY } = dataPoints[nextBarindex]
        const { x: nextX, width: nextWidth } = bars[nextBarindex]

        // calculate middle of next bar
        const nextPX = nextX + nextWidth / 2
        // in canvases, larger numbers means further down. we need to basically flip that
        const nextPY = canvasHeight - (nextY / maxValue) * canvasHeight

        // if you take this out and put it above the forEach, the lines overlap and are uneven making it pixelated
        canvasContext.beginPath()
        canvasContext.strokeStyle = color
        canvasContext.shadowColor = color
        canvasContext.lineWidth = 3

        // draw your line
        canvasContext.moveTo(currentX, currentY)
        canvasContext.lineTo(nextPX, nextPY)
        canvasContext.stroke()
      }
    })
  }
}

// we want our ticks to be proportionally equivalent to their ticks, so we have to make a scale
export const createCustomScale = (height, proportionalMaxValue, axisTicks) => {
  const axisData = {
    min: 0,
    max: proportionalMaxValue,
    all: axisTicks,
  }

  const scale = createLinearScale(
    {
      min: 0,
      max: 'auto',
      nice: true,
    },
    axisData,
    height,
    'y'
  )

  return scale
}

export const createAxisTicksToCustomScale = (currentScale, proportionalMaxValue) => {
  if (!currentScale?.ticks().length) {
    return []
  }

  // we need to scale our ticks down from their ticks in order for the grid to line up
  const oppositeAxisMaxValue = Math.max(...currentScale?.domain())
  const tickScale = oppositeAxisMaxValue / proportionalMaxValue

  // scale down each tick by the proportionate scale and return
  return currentScale?.ticks()?.map((tick) => {
    const newTickIsDecimal = Math.floor(tick / tickScale) !== tick / tickScale
    if (newTickIsDecimal) {
      return Number((tick / tickScale).toFixed(2))
    }
    return tick / tickScale
  })
}

export const calculateMaxValue = (dataPoints = [], dataPointKey) => {
  let maxValue
  if (dataPointKey) {
    maxValue = Math.max(...dataPoints.map((dataPoint) => dataPoint[dataPointKey]))
  } else {
    maxValue = Math.max(...dataPoints)
  }

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(maxValue)) {
    throw Error(
      "Max value is not a number. You've likely passed in an object with no datakey, or a list of values with a datakey we don't need."
    )
  }
  return maxValue
}

export const calculateProportionalMaxValue = (maxValue = 1) => {
  let proportionalMaxValue

  // LEAVE THIS ALONE
  // We've found that for values over 200, the axis ticks only line up correctly if the maxValue we pass in
  // is equal to N + x(0.1*N) where N is n*10^y while value < n+1*10^y.
  // That's a nightmare to read, so basically:

  // 1. rounded value + any increment of 10% of the rounded value works: 10% of 200 = 20, so 220, 240, 260, 280
  // are all good, whereas 275 is bad.

  // 2. if our value was 3150, that gets rounded to 3000. 10% of 3000 = 300, so 3300, 3600, 3900 are all good,
  // whereas 3400 is bad.

  // 3. To AVOID that shitshow ^, we just round up to the nearest power of 10.

  if (maxValue > 200) {
    for (let i = 2; i < 10; i++) {
      const comparisonValue = maxValue / 10 ** i
      if (comparisonValue >= 1 && comparisonValue <= 10) {
        proportionalMaxValue = Math.ceil(maxValue / 10 ** i) * 10 ** i
      }
    }
  } else {
    proportionalMaxValue = Math.ceil(maxValue / 10) * 10
  }
  return proportionalMaxValue
}

export const secondaryChart = (type, canvas, nivoProps, config) => {
  switch (type) {
    case 'line':
      return LayeredLineGraph(canvas, nivoProps, config)
    default:
      return null
  }
}
