import React from 'react'
import { Menu } from 'semantic-ui-react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useQuery } from '@/utils/hooks/useQuery'

import { sections } from '../../playbook.helpers'
import { ChecklistEditor } from './ChecklistEditor'
import { ChecklistVariationsEditor } from './ChecklistVariationsEditor'
import './Checklist.scss'

export const ChecklistTabs = () => {
  const location = useLocation()
  const { id: playbookId, entryId } = useParams()
  const isCreate = entryId === 'create'
  const query = useQuery()
  const isVariationsTab = query.get('tab') === 'variations'
  const {
    playbook: { current: isLatestPlaybookVersion },
  } = useSelector((state) => state.playbook)
  const tabs = [
    {
      route: sections.CHECKLIST,
      title: 'Details',
    },
  ]

  if (isLatestPlaybookVersion && !isCreate) {
    tabs.push({
      route: sections.CHECKLIST,
      title: 'Variations',
      query: 'variations',
    })
  }

  const isActiveChecklistTab = (match, tab) => {
    if (!match) {
      return false
    }

    if (isVariationsTab && query.get('tab') === tab.query && location.search.includes(query)) {
      return true
    }

    if (!isVariationsTab && !query.get('tab') && !tab.query) {
      return true
    }

    return false
  }

  return (
    <div className="checklist-tabs-container tab-container">
      <Menu pointing secondary className="tab-navigation background-tab-navigation">
        {tabs.map((tab) => {
          return (
            <NavLink
              isActive={(match) => isActiveChecklistTab(match, tab)}
              key={tab.title}
              to={`/playbooks/${playbookId}/${tab.route}/${entryId}${
                tab.query ? `?tab=${tab.query}` : ''
              }`}
              data-testid={`checklist-navigation-${tab.route}-tab${
                tab.query ? `-${tab.query}` : ''
              }`}
              className="item"
            >
              <span>{tab.title}</span>
            </NavLink>
          )
        })}
      </Menu>
      {isVariationsTab ? <ChecklistVariationsEditor /> : <ChecklistEditor />}
    </div>
  )
}
