import { toast } from 'react-toastify'

import { setData, setLoading } from '@/reducers/gamification/gamification.redux'
import { apiService, fetchingAPI } from '@/api'
import { formatFilters, getTagOptions } from '@/utils/helpers'

export const fetchAgentsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading('agents', true))

  try {
    const { users } = await fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/users`,
      'GET',
      dispatch
    )
    const agentOptions = users
      .map((agent) => ({
        value: agent.id,
        label: `${agent.first_name} ${agent.last_name}`,
        tags: agent.tags,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
    dispatch(setData('agents', agentOptions))
    dispatch(setData('currentOrganizationId', organizationId))
  } catch (err) {
    toast.error('Failed to fetch agents')
  } finally {
    dispatch(setLoading('agents', false))
  }
}

export const fetchChallengeTemplatesByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading('challengeTemplates', true))
  try {
    const challengeTemplates = await fetchingAPI(
      `${apiService.game}/challenges/challenge_summary/organization/${organizationId}`,
      'GET',
      dispatch
    )
    const { templates } = challengeTemplates
    dispatch(setData('challengeTemplates', templates))
    dispatch(setData('currentOrganizationId', organizationId))
  } catch (err) {
    toast.error('Failed to fetch challenge templates')
  } finally {
    dispatch(setLoading('challengeTemplates', false))
  }
}

export const fetchChallengeDetailsById = (challengeId) => async (dispatch) => {
  dispatch(setLoading('currentlySelectedChallenge', true))

  try {
    const challengeResults = await fetchingAPI(
      `${apiService.game}/challenges/challenge/${challengeId}`,
      'GET',
      dispatch
    )
    const result = challengeResults
    dispatch(setData('currentlySelectedChallenge', result))
  } catch (err) {
    toast.error('Failed to fetch challenge details')
  } finally {
    dispatch(setLoading('currentlySelectedChallenge', false))
  }
}

export const fetchBadgeResultsById = (badgeId) => async (dispatch) => {
  dispatch(setLoading('currentlySelectedBadge', true))

  try {
    const badgeResults = await fetchingAPI(
      `${apiService.game}/badges/badge/${badgeId}/results`,
      'GET',
      dispatch
    )
    const result = badgeResults
    dispatch(setData('currentlySelectedBadge', result))
  } catch (err) {
    toast.error('Failed to fetch badge details')
  } finally {
    dispatch(setLoading('currentlySelectedBadge', false))
  }
}
export const applyBadgeDetailsFilter = (badgeId) => async (dispatch, getState) => {
  dispatch(setLoading('currentlySelectedBadge', true))
  const { gamification } = getState()
  const queryParameterString = formatFilters(gamification.filters)
  try {
    const badgeResults = await fetchingAPI(
      `${apiService.game}/badges/badge/${badgeId}/results?${queryParameterString}`,
      'GET',
      dispatch
    )
    const result = badgeResults
    dispatch(setData('currentlySelectedBadge', result))
  } catch (err) {
    toast.error('Failed to apply badge details')
  } finally {
    dispatch(setLoading('currentlySelectedBadge', false))
  }
}

export const fetchChallengeResultsById = (challengeId) => async (dispatch) => {
  dispatch(setLoading('challengeResults', true))

  try {
    const challengeResults = await fetchingAPI(
      `${apiService.game}/challenges/challenge/${challengeId}/results`,
      'GET',
      dispatch
    )
    const result = challengeResults
    dispatch(setData('challengeResults', result))
  } catch (err) {
    toast.error('Failed to fetch challenge results')
  } finally {
    dispatch(setLoading('challengeResults', false))
  }
}

export const fetchTagsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ tags: true }))

  try {
    const [tags, tagCategories] = await Promise.all([
      fetchingAPI(`${apiService.web}/api/organizations/${organizationId}/tags`, 'GET', dispatch),
      fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags/categories`,
        'GET',
        dispatch
      ),
    ])

    const tagOptionsByCategory = []
    // Created grouped tag categories
    tagCategories.forEach((cat) => {
      tagOptionsByCategory.push({
        label: cat.name,
        options: getTagOptions(tags.filter((tag) => tag.tag_category_id === cat.id)),
      })
    })

    // Add uncategorized tags
    tagOptionsByCategory.push({
      label: 'Uncategorized',
      options: getTagOptions(tags.filter((tag) => !tag.tag_category_id)),
    })
    dispatch(setData('tags', tagOptionsByCategory))
  } catch (err) {
    toast.error('Failed to fetch tags')
  } finally {
    dispatch(setLoading({ tags: false }))
  }
}

export const fetchChallengesByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading('challenges', true))
  try {
    const challenges = await fetchingAPI(
      `${apiService.game}/challenges?organization_id=${organizationId}`,
      'GET',
      dispatch
    )

    dispatch(setData('challenges', challenges))
    dispatch(setData('currentOrganizationId', organizationId))
  } catch (err) {
    toast.error(`Failed to fetch challenges for organization: ${organizationId}`)
  } finally {
    dispatch(setLoading('challenges', false))
  }
}

export const fetchBadgeSummaryByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading('badgeTemplates', true))
  try {
    const { badgeTemplates } = await fetchingAPI(
      `${apiService.game}/badges/badge_summary/organization/${organizationId}`,
      'GET',
      dispatch
    )

    const challengeBadgeTemplates = badgeTemplates.filter(
      ({ badgeType }) => badgeType === 'challenge'
    )

    const collectionBadgeTemplates = badgeTemplates.filter(
      ({ badgeType }) => badgeType === 'collection'
    )

    dispatch(setData('collectionBadgeTemplates', collectionBadgeTemplates))
    dispatch(setData('challengeBadgeTemplates', challengeBadgeTemplates))
    dispatch(setData('currentOrganizationId', organizationId))
  } catch (err) {
    toast.error(`Failed to fetch challenges for organization: ${organizationId}`)
  } finally {
    dispatch(setLoading('badgeTemplates', false))
  }
}

export const createBadgeAndActivateBadge = (badgeTemplateId) => async (dispatch, getState) => {
  const { gamification } = getState()
  const { currentOrganizationId } = gamification.data

  try {
    dispatch(setLoading('badgeTemplates', true))
    const body = JSON.stringify({
      badgeTemplateId,
      organizationId: currentOrganizationId,
      active: true,
    })

    await fetchingAPI(`${apiService.game}/badges/badge`, 'POST', dispatch, body)

    dispatch(fetchBadgeSummaryByOrg(currentOrganizationId))
  } catch (err) {
    toast.error('Failed to create and activate badge')
  } finally {
    dispatch(setLoading('badgeTemplates', false))
  }
}

export const activateBadge = (badgeTemplateId) => async (dispatch, getState) => {
  const { gamification } = getState()
  const { currentOrganizationId } = gamification.data
  dispatch(setLoading('badgeTemplates', true))
  try {
    const body = JSON.stringify({
      badgeTemplateId,
      organizationId: currentOrganizationId,
    })

    await fetchingAPI(`${apiService.game}/badges/activate`, 'PUT', dispatch, body)
    dispatch(fetchBadgeSummaryByOrg(currentOrganizationId))
    toast.success('Badge activated')
  } catch (err) {
    toast.error('Failed to activate badge')
  } finally {
    dispatch(setLoading('badgeTemplates', false))
  }
}

export const deactivateBadge = (badgeTemplateId) => async (dispatch, getState) => {
  const { gamification } = getState()
  const { currentOrganizationId } = gamification.data
  dispatch(setLoading('badgeTemplates', true))
  try {
    const body = JSON.stringify({
      badgeTemplateId,
      organizationId: currentOrganizationId,
    })
    await fetchingAPI(`${apiService.game}/badges/deactivate`, 'PUT', dispatch, body)
    dispatch(fetchBadgeSummaryByOrg(currentOrganizationId))
    toast.success('Badge deactivated')
  } catch (err) {
    toast.error('Failed to deactivate badge')
  } finally {
    dispatch(setLoading('badgeTemplates', false))
  }
}

export const applyChallengesFilters = (organizationId) => async (dispatch, getState) => {
  dispatch(setLoading('challengeTemplates', true))
  const { gamification } = getState()
  const queryParameterString = formatFilters(gamification.filters)
  try {
    const challengeTemplates = await fetchingAPI(
      `${apiService.game}/challenges/challenge_summary/organization/${organizationId}?${queryParameterString}`,
      'GET',
      dispatch
    )
    const { templates } = challengeTemplates
    dispatch(setData('challengeTemplates', templates))
    dispatch(setData('currentOrganizationId', organizationId))
  } catch (err) {
    toast.error('Failed to fetch challenge templates')
  } finally {
    dispatch(setLoading('challengeTemplates', false))
  }
}

export const addChallenge = (challengeTemplateId) => async (dispatch, getState) => {
  dispatch(setLoading('challengeTemplates', true))
  try {
    const { gamification } = getState()
    const { currentOrganizationId } = gamification.data
    const body = JSON.stringify({
      challengeTemplateId,
      organizationId: currentOrganizationId,
      status: 'inactive',
      startDate: null,
      endDate: null,
      userIds: [],
      tagIds: [],
    })
    await fetchingAPI(`${apiService.game}/challenges/challenge`, 'POST', dispatch, body)

    dispatch(applyChallengesFilters(currentOrganizationId))
    toast.success('Challenge created')
  } catch (err) {
    toast.error('Failed to add challenge')
  } finally {
    dispatch(setLoading('challengeTemplates', false))
  }
}

export const deleteChallenge = (challengeId) => async (dispatch, getState) => {
  dispatch(setLoading('challenges', true))
  try {
    const { gamification } = getState()
    const { currentOrganizationId } = gamification.data
    await fetchingAPI(`${apiService.game}/challenges/challenge/${challengeId}`, 'DELETE', dispatch)
    dispatch(applyChallengesFilters(currentOrganizationId))
  } catch (err) {
    toast.error('Failed to delete challenge')
  } finally {
    dispatch(setLoading('challenges', false))
  }
}

export const updateChallengeStatus = (challenge, updatedStatus) => async (dispatch, getState) => {
  dispatch(setLoading('challenges', true))
  try {
    const { gamification } = getState()
    const { currentOrganizationId } = gamification.data
    const body = JSON.stringify({
      status: updatedStatus,
    })
    await fetchingAPI(
      `${apiService.game}/challenges/challenge/${challenge.id}`,
      'PATCH',
      dispatch,
      body
    )
    dispatch(applyChallengesFilters(currentOrganizationId))
    toast.success(`Challenge ${updatedStatus}`)
  } catch (err) {
    toast.error('Failed to update challenge status')
  } finally {
    dispatch(setLoading('challenges', false))
  }
}

export const updateChallengeStatusFromDetails = (challenge, updatedStatus) => async (dispatch) => {
  dispatch(setLoading('currentlySelectedChallenge', true))
  try {
    const body = JSON.stringify({
      status: updatedStatus,
      start_date: challenge.startDate,
      end_date: challenge.endDate,
    })
    const updatedChallenge = await fetchingAPI(
      `${apiService.game}/challenges/challenge/${challenge.id}`,
      'PATCH',
      dispatch,
      body
    )
    dispatch(setData('currentlySelectedChallenge', updatedChallenge))
    toast.success(`Challenge ${updatedStatus}`)
  } catch (err) {
    toast.error('Failed to update challenge status')
  } finally {
    dispatch(setLoading('currentlySelectedChallenge', false))
  }
}

export const updateChallengeGroup = (group) => async (dispatch, getState) => {
  dispatch(setLoading('challenges', true))
  const { gamification } = getState()
  const { currentOrganizationId } = gamification.data
  try {
    const body = JSON.stringify({
      agents: group.agents,
    })
    await fetchingAPI(
      `${apiService.game}/challenges/groups/group/${group.groupId}`,
      'PATCH',
      dispatch,
      body
    )
    dispatch(applyChallengesFilters(currentOrganizationId))
    toast.success('Participants updated')
  } catch (err) {
    toast.error('Failed to update challenge groups')
  } finally {
    dispatch(setLoading('challenges', false))
  }
}

export const updateGroups = (groups) => async (dispatch) => {
  dispatch(setLoading('challenges', true))
  try {
    const body = JSON.stringify({
      challengeId: groups.challengeId,
      groups: groups.groups,
    })
    await fetchingAPI(`${apiService.game}/challenges/groups`, 'PATCH', dispatch, body)
  } catch (err) {
    toast.error('Failed to update challenge groups')
  } finally {
    dispatch(setLoading('challenges', false))
  }
}

export const updateChallengeDates = (challenge) => async (dispatch, getState) => {
  dispatch(setLoading('challenges', true))
  try {
    const { gamification } = getState()
    const { currentOrganizationId } = gamification.data
    const body = JSON.stringify({
      end_date: challenge.endDate || null,
      start_date: challenge.startDate || null,
    })
    await fetchingAPI(
      `${apiService.game}/challenges/challenge/${challenge.id}`,
      'PATCH',
      dispatch,
      body
    )
    dispatch(applyChallengesFilters(currentOrganizationId))
    toast.success('Date added')
  } catch (err) {
    toast.error('Failed to update challenge dates')
  } finally {
    dispatch(setLoading('challenges', false))
  }
}

export const fetchSpecialtyBadgesByOrgId = (orgId) => async (dispatch) => {
  dispatch(setLoading('specialtyBadges', true))

  try {
    const badges = await fetchingAPI(
      `${apiService.game}/badges/badge/specialty_badge/organization/${orgId}`,
      'GET',
      dispatch
    )

    dispatch(setData('specialtyBadges', badges))
  } catch (e) {
    toast.error('Failed to fetch badges')
  } finally {
    dispatch(setLoading('specialtyBadges', false))
  }
}

export const createSpecialtyBadge = (badgeInfo, userId) => async (dispatch) => {
  dispatch(setLoading('specialtyBadges', true))
  const body = {
    ...badgeInfo,
    organizationId: 1,
    createdBy: userId,
  }
  const requestBody = JSON.stringify(body)
  try {
    await fetchingAPI(
      `${apiService.game}/badges/badge/specialty_badge`,
      'POST',
      dispatch,
      requestBody
    )

    dispatch(fetchSpecialtyBadgesByOrgId(1))
  } catch (e) {
    toast.error('Failed to create badge')
  } finally {
    dispatch(setLoading('specialtyBadges', false))
  }
}

export const updateSpecialtyBadgeById = (badgeId, badgeInfo) => async (dispatch) => {
  dispatch(setLoading(`specialtyBadge--${badgeId}`, true))
  const requestBody = JSON.stringify(badgeInfo)
  try {
    await fetchingAPI(
      `${apiService.game}/badges/badge/specialty_badge/${badgeId}`,
      'PATCH',
      dispatch,
      requestBody
    )

    dispatch(fetchSpecialtyBadgesByOrgId(1))
  } catch (e) {
    toast.error('Failed to update badge')
  } finally {
    dispatch(setLoading(`specialtyBadge--${badgeId}`, false))
  }
}
