import React, { useState, useEffect, useRef } from 'react'
import nouislider from 'nouislider'
import classNames from 'classnames'

import './Slider.scss'

export const Slider = ({
  label,
  field: { name, value },
  required,
  formikProps,
  disabled,
  ...props
}) => {
  const [slider, setSlider] = useState(null)
  const sliderContainerRef = useRef(null)

  const createSlider = () => {
    const sliderComponent = nouislider.create(sliderContainerRef.current, {
      start: [value.after || 0, value.before || 3660],
      connect: true,
      step: 60,
      keyboardSupport: true,
      format: {
        to: (value) => Math.round(value),
        from: (value) => Math.round(value),
      },
      tooltips: [
        {
          from: Number,
          to: (value) => {
            if (value === 0) return 'From beginning'

            return `From ${Math.round(value) / 60} minutes`
          },
        },
        {
          from: Number,
          to: (value) => {
            if (value === 3660) return 'To end of call'

            return `To ${Math.round(value) / 60} minutes`
          },
        },
      ],
      range: {
        min: 0,
        max: 3660,
      },
      // spreading props after so you can override whatever you need to if we re-use
      ...props,
    })

    // apply all event listener props passed, make this conditional if you don't use redux forms
    sliderComponent.on('change', (range) => {
      formikProps.setFieldValue('trigger.after', range[0])
      formikProps.setFieldValue('trigger.before', range[1])
    })

    setSlider(sliderComponent)
  }

  useEffect(() => {
    createSlider()

    return () => {
      if (slider) return slider.destroy()

      return null
    }
  }, [])

  return (
    <div
      className={classNames('field', {
        [props.className]: props.className,
        required,
        disabled,
      })}
      data-testid={`${name}-select`}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <div
        ref={sliderContainerRef}
        className="form-slider"
        style={{ pointerEvents: disabled ? 'none' : 'auto' }}
      />
    </div>
  )
}
