import React from 'react'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { ExternalLink } from '@/components/ExternalLink'

export const ExternalCallIdsLink = ({ callIds, className, passedOrgId, ...props }) => {
  const { organizationid: currentUserOrgId } = useSelector((state) => state.currentUser)
  const { filters } = useSelector((state) => state.qaCopilot)
  // If Balto admin, use dashboard filter. If filter is empty, use current user's org id
  const organizationId = passedOrgId || filters.organizationId || currentUserOrgId
  const queryParams = queryString.stringify({ call_ids: callIds, organizationId })

  if (isEmpty(callIds)) {
    return <span>{props.label}</span>
  }

  return (
    <ExternalLink
      url={`/call-explorer?${queryParams}`}
      target="_blank"
      className={classNames(className)}
      {...props}
    />
  )
}
