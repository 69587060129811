import React from 'react'

export const SoundWaveAnimation = () => {
  return (
    <div className="sound-wave-animation" data-testid="cc-listen-animation">
      <div className="sound-wave-animation__bar" />
      <div className="sound-wave-animation__bar" />
      <div className="sound-wave-animation__bar" />
      <div className="sound-wave-animation__bar" />
    </div>
  )
}
