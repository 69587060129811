/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef } from 'react'
import { Form, Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Field } from 'formik'
import classNames from 'classnames'
import { IconRefresh, IconArrowRight, IconMinus } from '@tabler/icons-react'
import { isEmpty } from 'lodash'

import { TextArea } from '@/components/forms/formik'
import {
  searchCallsWithBaltoGPT,
  searchTranscriptWithBaltoGPT,
  resetChatFeed,
} from '@/reducers/baltoGPT/baltoGPT.actions'
import { BaltoGPTIcon } from '@/components/icons/BaltoGPTIcon'

import { BALTO_GPT_SUGGESTIONS } from './baltoGPT.helpers'

import './BaltoGPTChat.scss'

export const BaltoGPTChat = ({ handleToggleChatVisible, isCallDetailPage }) => {
  const dispatch = useDispatch()
  const {
    loading: { baltoGPTChat: baltoGPTChatLoading },
    chatFeed,
  } = useSelector((state) => state.baltoGPT)
  const { callExplorer } = useSelector((state) => state.callSearch)
  const chatFeedRef = useRef(null)
  const initialValues = isCallDetailPage
    ? { prompt: '' }
    : { summary_section: 'reason', prompt: '' }

  const handleUpdatePrompWithSuggestion = (suggestion, setFieldValue) => {
    setFieldValue('summary_section', suggestion.summarySection)
    setFieldValue('prompt', suggestion.text)
  }

  const handleSubmit = (values, { setFieldValue }) => {
    if (isCallDetailPage && callExplorer?.transcript) {
      dispatch(searchTranscriptWithBaltoGPT(values))
    } else {
      dispatch(searchCallsWithBaltoGPT(values))
    }

    setFieldValue('prompt', '')
  }

  const scrollToBottom = () => {
    chatFeedRef?.current?.scrollIntoView()
  }

  const handleKeyUp = (event, handleSubmit, values, setFieldValue) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmit(values, { setFieldValue })
    }
  }
  const handleRefresh = () => {
    dispatch(resetChatFeed())
  }

  useEffect(() => {
    scrollToBottom()
  }, [chatFeed.length])

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, setFieldValue, values }) => {
        return (
          <div className="balto-gpt-chat" data-testid="balto-gpt-chat">
            <header>
              <div className="brand">
                <div className="brand-circle">
                  <BaltoGPTIcon className="icon-svg" />
                </div>
                <div className="brand-name">BaltoGPT</div>
              </div>
              <div className="flex-align-center small-gap">
                <Button
                  icon
                  secondary
                  className="svg-button"
                  type="button"
                  onClick={handleRefresh}
                  data-testid="balto-gpt-refresh-button"
                >
                  <IconRefresh />
                </Button>
                <Button
                  icon
                  secondary
                  className="svg-button"
                  type="button"
                  onClick={handleToggleChatVisible}
                  data-testid="balto-gpt-minimize-button"
                >
                  <IconMinus />
                </Button>
              </div>
            </header>
            <section>
              <div className="balto-gpt-chat-box">
                {chatFeed.map((chat, index) => {
                  const isLastItem = chatFeed.length - 1 === index

                  return (
                    <div
                      ref={isLastItem ? chatFeedRef : null}
                      key={`${chat.text}${index}`}
                      className={classNames('chat-item', {
                        'error-chat-bubble': chat.chatType === 'error',
                        'balto-gpt-chat-bubble': chat.chatType === 'baltoGPT',
                        'agent-chat-bubble': chat.chatType === 'agent',
                      })}
                    >
                      {chat.text}
                    </div>
                  )
                })}
                {!isCallDetailPage && isEmpty(chatFeed) && (
                  <div className="balto-gpt-suggestions" data-testid="balto-gpt-suggestions">
                    <h3>Suggestions</h3>
                    {BALTO_GPT_SUGGESTIONS.map((suggestion) => (
                      <button
                        type="button"
                        key={suggestion.text}
                        className="suggestion-bubble"
                        onClick={() => handleUpdatePrompWithSuggestion(suggestion, setFieldValue)}
                      >
                        {suggestion.text}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </section>
            <footer>
              <Form className="horizontal" onSubmit={handleSubmit}>
                <div className="balto-gpt-footer-container">
                  <Field
                    name="prompt"
                    component={TextArea}
                    rows="1"
                    placeholder={
                      isCallDetailPage
                        ? 'Ask questions about your call...'
                        : 'Ask questions about your calls...'
                    }
                    type="text"
                    className="full-width"
                    onKeyUp={(e) => handleKeyUp(e, handleSubmit, values, setFieldValue)}
                  />
                  <Button
                    icon
                    data-testid="balto-gpt-submit-button"
                    className="black"
                    type="submit"
                    onClick={handleSubmit}
                    loading={baltoGPTChatLoading}
                  >
                    <IconArrowRight />
                  </Button>
                </div>
              </Form>
            </footer>
          </div>
        )
      }}
    </Formik>
  )
}
