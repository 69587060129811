import React from 'react'
import { startCase } from 'lodash'

export const AgentSummaryView = ({ parsedSummary }) => {
  const colors = {
    summary: 'blue',
    reason: 'blue',
    objections: 'red',
    'next steps': 'green',
    outcome: 'blue',
  }
  return (
    <div className="agent-summary-view">
      {Object.keys(parsedSummary).map((key) => (
        <div className="agent-summary-container" key={key}>
          <div className="agent-summary-headline">
            <div
              className={`agent-summary-view__section-header__color-block ${colors[key] || 'grey'}`}
            />
            <h2 className="agent-summary-view__section-header">{startCase(key)}</h2>
          </div>
          <div>{parsedSummary[key]}</div>
        </div>
      ))}
    </div>
  )
}
