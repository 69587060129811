import React, { useEffect, useState } from 'react'
import { Accordion, Checkbox, Table } from 'semantic-ui-react'

import './nestedTable.scss'
import { NestedTableNestedBody } from '@/components/tables/NestedTable/NestedTableNestedBody'
import {
  buildSelectedRowsObject,
  toggleRowAndUpdateSelectedObject,
  toggleNestedRows,
  isFullySelected,
  isPartiallySelected,
} from './helpers'

// accepts rows (nested as many layers as needed) structured like this:
// {
//   row_name: {
//     name: 'row_name',
//     nestedField: 'nestedFieldName',
//     nestedFieldName: [ ...rows (can be nested as well) ]
//   }
// }
export const NestedTable = ({
  columns,
  rows,
  collapsibleChildren,
  selectable,
  onSelect,
  onNestedSelect,
  basic,
  noHeaders,
  setColumnSpan = 4,
  flattenedHeader = '',
  expandByDefault = true,
  striped = false,
}) => {
  const [selectedRows, setSelectedRows] = useState(buildSelectedRowsObject(rows, {}))
  const [numberSelected, setNumberSelected] = useState(null)
  const topLayerSelected = {
    selected: isFullySelected(rows, selectedRows),
    partiallySelected: isPartiallySelected(rows, selectedRows),
  }

  const nestedSelect = (targetRow) => {
    const [result] = toggleRowAndUpdateSelectedObject(rows, targetRow, selectedRows, onSelect)
    setSelectedRows(result)
    onNestedSelect(result)
  }

  const topLevelSelect = () => {
    const updatedSelectedRows = toggleNestedRows(
      rows,
      selectedRows,
      onSelect,
      !(topLayerSelected.partiallySelected || topLayerSelected.selected)
    )
    setSelectedRows(updatedSelectedRows)
    onNestedSelect(updatedSelectedRows)
  }

  const firstCheckboxLabel = (label) =>
    numberSelected ? `${numberSelected} Criteria Selected` : label

  useEffect(() => {
    setNumberSelected(
      Object.values(selectedRows).filter((row) => row.bottomLevel && row.selected).length
    )
  }, [selectedRows])

  return (
    <Table basic={basic ? 'very' : false} data-testid="nested-table">
      {!noHeaders && (
        <Table.Header>
          {selectable && (
            <Table.HeaderCell collapsing>
              <Checkbox
                className="nested-table__row-select-box"
                data-testid="top-level-select"
                checked={rows?.length && topLayerSelected?.selected}
                disabled={!rows?.length}
                indeterminate={topLayerSelected?.partiallySelected}
                onClick={topLevelSelect}
              />
            </Table.HeaderCell>
          )}
          {columns.map((column, index) => (
            <Table.HeaderCell
              key={column.label}
              textAlign={index >= 2 && !column.left ? 'right' : 'left'}
              data-testid="nested-table-header-cell"
              className="nested-table_header-cell"
            >
              {index === 0 ? firstCheckboxLabel(column.label) : column.label}
            </Table.HeaderCell>
          ))}
        </Table.Header>
      )}
      {rows?.length ? (
        <Accordion as={Table.Body} className="nested-sub-table__accordion">
          {flattenedHeader && <strong>{flattenedHeader}</strong>}
          <NestedTableNestedBody
            selectedRows={selectedRows}
            nestedSelect={nestedSelect}
            collapsibleChildren={collapsibleChildren}
            selectable={selectable}
            rows={rows}
            columns={columns}
            onSelect={onSelect}
            basic={basic}
            expandByDefault={expandByDefault}
            setColumnSpan={setColumnSpan}
            striped={striped}
          />
        </Accordion>
      ) : (
        <Table.Body>
          <Table.Row>
            <Table.Cell className="test-this-please" colSpan={setColumnSpan}>
              <div className="empty-table ml-auto" data-testid="empty-list">
                Nothing matches your filter
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      )}
    </Table>
  )
}
