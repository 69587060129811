import React, { useContext } from 'react'
import classNames from 'classnames'
import { Loader } from 'semantic-ui-react'

import WidgetContext from './widget.context'
import WidgetLegend from './WidgetLegend'
import WidgetDetail from './WidgetDetail'
import NoData from '../NoData'

const WidgetContainer = ({
  loading,
  noData,
  withLegend = false,
  withSortedLegend = false,
  tableFilter = '', // 'topFive' or 'bottomFive'
  dataForLegend = [],
  withDetail = false,
  capitalizeLegend = false,
  withPagination = false,
  dataForDetail = {},
  disableLegendToggle = false,
  legendAccessor = 'id',
  table,
  chart,
  medium,
  large,
}) => {
  const { isTableView } = useContext(WidgetContext)

  const renderWidget = () => {
    if (loading) {
      return (
        <div data-testid="widget-loading" className="loading-container">
          <Loader active inline />
        </div>
      )
    }

    if (noData) {
      return <NoData />
    }

    return (
      <>
        <div
          className={classNames('widget__container--inner', {
            'with-legend': withLegend || withSortedLegend,
            'with-detail': withDetail,
            'with-pagination': withPagination,
            table: isTableView,
            chart: !isTableView,
          })}
        >
          {isTableView ? table : chart}
        </div>
        {(withLegend || withSortedLegend) && (
          <WidgetLegend
            withLegend={withLegend}
            capitalizeLegend={capitalizeLegend}
            withSortedLegend={withSortedLegend}
            tableFilter={tableFilter}
            data={dataForLegend.data}
            warningMessage={dataForLegend.warningMessage}
            accessor={legendAccessor}
            disableLegendToggle={disableLegendToggle}
          />
        )}
        {withDetail && <WidgetDetail data={dataForDetail} />}
      </>
    )
  }

  return (
    <div
      data-testid="widget-container"
      className={classNames('widget__container', { table: isTableView, medium, large })}
    >
      {renderWidget()}
    </div>
  )
}

export default WidgetContainer
