import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { closeModal } from '@/reducers/ui/ui.redux'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import {
  fetchAgentsByOrg,
  fetchCategoriesByOrgSectionAndPlaybooks,
  fetchPlaybooksByOrg,
  fetchTagsByOrg,
  saveFilter,
  updateSavedFilter,
} from '@/reducers/analytics/analytics.actions'
import { buildSchema } from './FilterForm.schema'

export const FilterForm = ({ editableFilter, filterFormOptions, filterType = 'default' }) => {
  const dispatch = useDispatch()
  const { organizationid } = useSelector((state) => state.currentUser)
  const analyticsStore = useSelector((state) => state.analytics)
  const { data, loading } = analyticsStore
  const existingValues = editableFilter
    ? {
        name: editableFilter.name,
        isDefault: editableFilter.is_default,
        playbooks: editableFilter.filters.playbooks || [],
        agents: editableFilter.filters.agents || [],
        tags: editableFilter.filters.tags || [],
        callDuration: editableFilter.filters.callDuration || '',
        maxCallDuration: editableFilter.filters.maxCallDuration || '',
        dateRange: editableFilter.filters.dateRange,
        includeManagers: editableFilter.filters.includeManagers,
        deckCategories: editableFilter.filters.deckCategories || [],
        postcallCategories: editableFilter.filters.postcallCategories || [],
        filterType,
      }
    : {
        isDefault: false,
        ...(filterType === 'my_team' && { filterType, name: 'My Team' }),
      }

  const handleClose = () => {
    dispatch(closeModal('analytics/createFilter'))
  }

  const handleSubmit = async (filter, { setSubmitting }) => {
    if (editableFilter) {
      await dispatch(updateSavedFilter(editableFilter.uuid, filter, filterType))
    } else {
      await dispatch(saveFilter(filter, filterType))
    }

    setSubmitting(false)
  }

  useEffect(() => {
    // My team only uses agents and tags currently
    dispatch(fetchTagsByOrg(organizationid))
    dispatch(fetchAgentsByOrg(organizationid))

    // Reporting filters use more data
    if (filterType !== 'my_team') {
      dispatch(fetchPlaybooksByOrg(organizationid))
      dispatch(fetchCategoriesByOrgSectionAndPlaybooks(organizationid, 'deck'))
      dispatch(fetchCategoriesByOrgSectionAndPlaybooks(organizationid, 'postcall'))
    }
  }, [])

  const schema = buildSchema(data, loading, filterFormOptions)

  return (
    <AbstractForm
      onSubmit={handleSubmit}
      existingValues={existingValues}
      schema={schema}
      buttonLabel="Save"
      onClose={handleClose}
      isModal
    />
  )
}
