import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { Field } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Button, Segment, Loader } from 'semantic-ui-react'
import { IconSparkles, IconFlask } from '@tabler/icons-react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import {
  TextField,
  TextArea,
  NumberField,
  Select,
  RadioGroup,
  Dropdown,
} from '@/components/forms/formik'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import {
  createPromptTest,
  deletePromptTest,
  getPromptTestResults,
  getSinglePromptTestResults,
} from '@/reducers/prompts/prompts.actions'
import { ExternalLink } from '@/components/ExternalLink'
import { Banner } from '@/components/banners/Banner'
import { Pill } from '@/components/pills/Pill'

import { criteriaTypeOptions } from '../QACopilot.helpers'

const QACopilotScorecardCriteriaPromptFormComponent = ({
  handleSubmit,
  handleCancel,
  values,
  errors,
  isTemporaryCriteria,
  setFieldValue,
  flags,
}) => {
  const dispatch = useDispatch()
  const {
    loading: { promptTests: promptTestsLoading, modelOptions: modelOptionsLoading },
    prompts,
    modelOptions,
  } = useSelector((state) => state.prompts)
  const [copilotTestResponse, setCopilotTestResponse] = useState(null)
  const { organizationid: organizationId, prompt_editor: isPromptEditor } = useSelector(
    (state) => state.currentUser
  )

  const { focusArea } = useSelector((state) => state.qaCopilot)
  const isBaltoAdmin = organizationId === 1
  const isPromptTestingDisabled = !values.prompt_id
  const isPromptPairEmpty = isEmpty(values.call_id) || isEmpty(values.expected_response)
  const prompt = prompts.find((prompt) => values.prompt_id && prompt.id === values.prompt_id)
  const promptTests = prompt?.prompt_tests || []
  const canEditPrompts = isBaltoAdmin || isPromptEditor
  const promptsEnabled =
    canEditPrompts && (values.criteria_type === 'ai' || values.manual_timestamp_markers === 'true')
  const promptTestsEnabled = canEditPrompts && values.criteria_type === 'ai'

  const handleSavePromptTest = async () => {
    dispatch(
      createPromptTest(prompts, prompt, {
        inputs: { call_id: values.call_id },
        expected_response: values.expected_response,
        prompt_uuid: prompt.prompt_uuid,
      })
    )

    setFieldValue('call_id', '')
    setFieldValue('expected_response', '')
  }

  const handleDeletePromptTest = (promptTestId) => {
    dispatch(deletePromptTest(prompts, prompt, promptTestId))
  }

  const handleSinglePromptTest = async (promptId, expectedResponse, callId) => {
    const response = await dispatch(
      getSinglePromptTestResults(
        promptId,
        {
          user_prompt: values.user_prompt,
          model: values.model,
          feature: 'qa_copilot_test',
          system_prompt: values.system_prompt,
          first_x_minutes: values.first_x_minutes || null,
          last_x_minutes: values.last_x_minutes || null,
          side: values.side,
          max_response_tokens: values.max_response_tokens,
        },
        expectedResponse,
        callId
      )
    )

    if (response) {
      setCopilotTestResponse([response])
    }
  }

  const handleTestCopilotPrompt = async () => {
    const response = await dispatch(
      getPromptTestResults(values.prompt_id, {
        user_prompt: values.user_prompt,
        model: values.model,
        feature: 'qa_copilot_test',
        system_prompt: values.system_prompt,
        first_x_minutes: values.first_x_minutes || null,
        last_x_minutes: values.last_x_minutes || null,
        side: values.side,
        max_response_tokens: values.max_response_tokens,
      })
    )

    setCopilotTestResponse(response)
  }

  const getActualResponse = (actualResponse) => {
    try {
      return JSON.stringify(JSON.parse(actualResponse), null, 2)
    } catch (err) {
      return actualResponse
    }
  }

  return (
    <Form
      className="qa-copilot-editor__scorecard-criteria qa-copilot-edit-form"
      onSubmit={handleSubmit}
    >
      <header>
        <Field
          name="name"
          component={TextField}
          placeholder="Guideline Title"
          type="text"
          className="input-title"
          autoFocus={focusArea === ''}
        />
        <Field
          name="criteria_type"
          component={Dropdown}
          placeholder="Select Criteria Type"
          options={criteriaTypeOptions.filter((option) =>
            !flags.enableManualQa ? option.value !== 'manual' : true
          )}
          className="icon secondary flex small-gap no-wrap"
          floating
          button
          icon="angle down"
        />
      </header>
      <div className="qa-copilot-editor__scorecard-criteria--content">
        <div className="qa-copilot-editor__scorecard-criteria--description">
          <h3 className="copilot-prompt__header">Assessment Guidelines</h3>
          <Field
            name="description"
            component={TextArea}
            placeholder="Write the assessment guidelines..."
            rows={null}
          />
        </div>

        {values.criteria_type === 'manual' && (
          <Segment>
            <h3>Evaluation Input</h3>
            <p>
              When evaluating a call using this assessment guideline, analysts will have three
              options to choose from:
            </p>
            <div className="flex-align-center medium-gap">
              <Banner success style={{ flex: 1 }}>
                Pass
              </Banner>
              <Banner critical style={{ flex: 1 }}>
                Fail
              </Banner>
              <Banner style={{ flex: 1 }}>N/A</Banner>
            </div>
          </Segment>
        )}

        {values.criteria_type === 'manual' && (
          <div
            className="flex-align-center small-gap additional-details-holder"
            style={{ marginBottom: '1rem' }}
          >
            <div>
              <div className="label-style flex-align-center medium-gap">
                Timestamp Marker
                <Pill magical small icon={<IconSparkles />}>
                  AI
                </Pill>
              </div>
              <p className="muted-text">
                Use AI to pinpoint and jump to key moments in the conversation. It automatically
                marks when specific events or topics are mentioned in the transcript, making it
                easier for a human to evaluate the current criterion.
              </p>
            </div>
            <Field
              name="manual_timestamp_markers"
              component={RadioGroup}
              options={[
                { value: 'true', text: 'Yes' },
                { value: 'false', text: 'No' },
              ]}
            />
          </div>
        )}

        {promptsEnabled && (
          <div>
            {values.criteria_type === 'ai' && (
              <h3 className="copilot-prompt__header">
                <IconSparkles className="icon-svg" />
                Copilot AI Prompt
              </h3>
            )}

            <div className="qa-copilot-editor__scorecard-criteria--user-prompt">
              <Field
                name="user_prompt"
                component={TextArea}
                placeholder="Write a prompt..."
                rows={null}
                autoFocus={focusArea === 'prompt'}
              />
            </div>
          </div>
        )}

        {promptTestsEnabled && (
          <>
            <div className="copilot-prompt__testing-headline">
              <IconFlask className="icon-svg" />
              <h3>Testing</h3>
            </div>
            <Segment>
              <div className="copilot-prompt__additional-details">
                <div>
                  <div className="label-style">Call IDs</div>
                  <div className="copilot-prompt-pair">
                    <Field
                      name="call_id"
                      component={TextField}
                      placeholder="Enter a CallID"
                      className="call-id-field"
                      autoFocus={focusArea === 'callid'}
                    />
                    <div>.....</div>
                    <Field
                      name="expected_response"
                      component={TextField}
                      placeholder="Select an expected response"
                      className="expected-response-field"
                    />
                    <ConditionalTooltip
                      condition={isPromptPairEmpty || isPromptTestingDisabled}
                      content={
                        isPromptPairEmpty
                          ? 'You must add a call id and an expected response'
                          : 'You must save the scorecard before setting up prompt testing'
                      }
                      triggerProps={{ style: { display: 'inline-flex', gap: '.5rem' } }}
                    >
                      <Button
                        type="button"
                        secondary
                        onClick={handleSavePromptTest}
                        disabled={isPromptTestingDisabled || isPromptPairEmpty}
                        className="no-wrap"
                      >
                        Add Pair
                      </Button>
                      <Button
                        type="button"
                        color="black"
                        onClick={() =>
                          handleSinglePromptTest(
                            values.prompt_id,
                            values.expected_response,
                            values.call_id
                          )
                        }
                        disabled={isPromptTestingDisabled || isPromptPairEmpty}
                        className="no-wrap"
                      >
                        Test Pair
                      </Button>
                    </ConditionalTooltip>
                  </div>
                </div>
                <div>
                  {promptTests.map((promptTest) => (
                    <div key={promptTest.id} className="prompt-test flex-align-center small-gap">
                      <Field
                        name={`call_id_${promptTest.inputs.call_id}`}
                        component={TextField}
                        placeholder={promptTest.inputs.call_id}
                        className="call-id-field"
                        disabled
                      />
                      <div>.....</div>
                      <Field
                        name={`expected_response_${promptTest.id}`}
                        component={TextField}
                        disabled
                        placeholder={promptTest.expected_response}
                        className="expected-response-field"
                      />

                      <Button
                        type="button"
                        negative
                        color="red"
                        onClick={() => handleDeletePromptTest(promptTest.id)}
                        style={{ marginTop: '0.5rem' }}
                      >
                        Remove
                      </Button>
                      <Button
                        color="black"
                        type="button"
                        className="no-wrap"
                        onClick={() =>
                          handleSinglePromptTest(
                            promptTest.id,
                            promptTest.expected_response,
                            promptTest.inputs.call_id
                          )
                        }
                        style={{ marginTop: '0.5rem' }}
                      >
                        Test Pair
                      </Button>
                    </div>
                  ))}
                </div>

                <ConditionalTooltip
                  condition={isEmpty(promptTests)}
                  content="Create a test first"
                  triggerProps={{ style: { display: 'inline-flex' } }}
                >
                  <Button
                    secondary
                    onClick={handleTestCopilotPrompt}
                    style={{ marginTop: '0.5rem' }}
                    disabled={isEmpty(promptTests)}
                    type="button"
                  >
                    Run Test
                  </Button>
                </ConditionalTooltip>
                <div>
                  {promptTestsLoading ? (
                    <div className="empty-copilot-response">
                      <Loader active inline />
                    </div>
                  ) : (
                    copilotTestResponse && (
                      <div className="copilot-response-holder">
                        {copilotTestResponse
                          .sort((resp) => resp.responses_match)
                          .map((response) => (
                            <div
                              className={`copilot-response ${response.responses_match ? 'success' : 'failure'}`}
                              key={response.prompt_test_id}
                            >
                              <div>
                                <strong>Inputs: </strong>
                                <br />
                                <strong>Call ID:</strong>{' '}
                                {response.inputs?.call_id && (
                                  <ExternalLink
                                    url={`/call-explorer/${response.inputs.call_id}`}
                                    label={response.inputs.call_id}
                                  />
                                )}
                              </div>
                              <div>
                                <strong>Expected Response: </strong>
                                <br />
                                <span>{response.expected_response}</span>
                              </div>
                              <div>
                                <strong>Actual Response: </strong>
                                <br />
                                <pre>{getActualResponse(response.actual_response)}</pre>
                              </div>
                              <div>
                                <strong>Tokens Used: </strong> <span>{response.tokens_used}</span>
                              </div>
                            </div>
                          ))}
                      </div>
                    )
                  )}
                </div>
              </div>
            </Segment>
          </>
        )}
        {promptsEnabled && (
          <>
            <div className="copilot-prompt__testing-headline">
              <IconFlask className="icon-svg" />
              <h3>Other Settings</h3>
            </div>
            <Segment>
              {values.criteria_type === 'ai' && (
                <div className="copilot-prompt__additional-details">
                  <div className="flex-align-center small-gap additional-details-holder">
                    <div className="label-style">Expected Response</div>
                    <Field
                      name="must_be_present"
                      component={RadioGroup}
                      options={[
                        { value: 'true', text: 'Yes' },
                        { value: 'false', text: 'No' },
                      ]}
                    />
                  </div>
                </div>
              )}
              <div className="copilot-prompt__additional-details">
                <div className="flex-align-center small-gap additional-details-holder">
                  <div className="label-style">Use Specific Timeframes</div>
                  <div className="flex-align-center small-gap">
                    <Field
                      placeholder="Minutes at the start"
                      name="first_x_minutes"
                      component={NumberField}
                    />
                    <Field
                      placeholder="Minutes at the end"
                      name="last_x_minutes"
                      component={NumberField}
                    />
                  </div>
                </div>
              </div>
              <div className="copilot-prompt__additional-details">
                <div className="flex-align-center small-gap additional-details-holder">
                  <div className="label-style">Side to Include in Transcript</div>
                  <Field
                    name="side"
                    component={Select}
                    options={[
                      { value: 'both', label: 'Both' },
                      { value: 'agent', label: 'Agent Only' },
                      { value: 'customer', label: 'Customer Only' },
                    ]}
                  />
                </div>
              </div>
              <div className="copilot-prompt__additional-details">
                <div className="flex-align-center small-gap additional-details-holder">
                  <div className="label-style">AI Model</div>
                  <Field
                    name="model"
                    component={Select}
                    options={modelOptions}
                    loading={modelOptionsLoading}
                  />
                </div>
              </div>
            </Segment>
          </>
        )}
      </div>
      <footer>
        <div className="flex vertical-center small-gap">
          <Button type="button" secondary onClick={handleCancel}>
            {isTemporaryCriteria ? 'Discard' : 'Discard Changes'}
          </Button>
          <ConditionalTooltip condition={!isEmpty(errors)} content="Must fix errors to submit">
            <Button
              type="submit"
              primary
              disabled={!isEmpty(errors)}
              data-testid="add-criterion-form-button"
            >
              {isTemporaryCriteria ? 'Add Criterion' : 'Update Criterion'}
            </Button>
          </ConditionalTooltip>
        </div>
      </footer>
    </Form>
  )
}

export const QACopilotScorecardCriteriaPromptForm = withLDConsumer()(
  QACopilotScorecardCriteriaPromptFormComponent
)
