import React from 'react'
import { AlertAgentSelectForm } from '@/views/RealtimeCoaching/Alerts/forms/AlertAgentSelectForm'

export const UsersTab = ({ values, setFieldValue }) => {
  return (
    <div className="edit-alert-page edit-alert-users-tab">
      <AlertAgentSelectForm isEditPage values={values} setFieldValue={setFieldValue} />
    </div>
  )
}
