import React from 'react'

export const DragAndDropIcon = ({ fill, ...props }) => {
  return (
    <svg height="15" width="10" fill={fill} {...props}>
      <circle cx="2" cy="2" r="1.75" />
      <circle cx="7" cy="2" r="1.75" />

      <circle cx="2" cy="7" r="1.75" />
      <circle cx="7" cy="7" r="1.75" />

      <circle cx="2" cy="12" r="1.75" />
      <circle cx="7" cy="12" r="1.75" />
    </svg>
  )
}
