import React from 'react'
import { Popup } from 'semantic-ui-react'

export const ConditionalTooltip = ({
  condition,
  content,
  tooltipProps = {},
  triggerProps = {},
  children,
}) => {
  return condition ? (
    <Popup
      position="top center"
      content={content}
      inverted
      trigger={<div {...triggerProps}>{children}</div>}
      {...tooltipProps}
    />
  ) : (
    children
  )
}
