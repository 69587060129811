import React, { useEffect } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  applyBadgeDetailsFilter,
  fetchBadgeResultsById,
} from '@/reducers/gamification/gamification.actions'
import { Button, Form, Loader } from 'semantic-ui-react'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { Breadcrumbs } from '@/components/forms/Breadcrumbs/Breadcrumbs'
import './BadgeDetailPage.scss'
import { UserInitial } from '@/components/UserInitial'
import { Helmet } from 'react-helmet'
import { DateRangePickerReusable } from '@/components/datePickers/DateRangePickerReusable'
import { setDateFilter } from '@/reducers/gamification/gamification.redux'
import { formatTime } from '@/utils/helpers'

export const BadgeDetailPage = () => {
  const dispatch = useDispatch()
  const { badge_id: badgeId } = useParams()
  const { data, loading, filters } = useSelector((state) => state.gamification)
  const { customBranding } = useSelector((state) => state.customBranding)
  const pageTitle = customBranding?.customBrandingDisplayName || 'Balto Cloud'
  const { currentlySelectedBadge } = data

  useEffect(() => {
    dispatch(fetchBadgeResultsById(badgeId))
  }, [])

  // Loading state
  if (loading.currentlySelectedBadge) {
    return (
      <div data-testid="loading-badge-detail">
        <Loader active />
      </div>
    )
  }

  if (!currentlySelectedBadge.results) {
    return null
  }

  const breadCrumbLinks = [{ label: 'All Badges', link: '/gamification/badges/collections' }]

  const rows = currentlySelectedBadge.results.map((row, idx) => ({
    ...row,
    hasBeenEarned: row.hasBeenEarned ? 'Yes' : 'No',
    earnedOn: row.hasBeenEarned ? formatTime(row.earnedOn) : 'N/A',
    userInitial: {
      as: <UserInitial userFullName={row.userFullName} index={idx} />,
    },
  }))

  const columns = [
    { label: '', accessor: 'userInitial' },
    { label: 'User Name', accessor: 'userFullName' },
    { label: 'Unlocked?', accessor: 'hasBeenEarned' },
    { label: 'Unlocked On', accessor: 'earnedOn' },
  ]
  const handleDateRangeFilterChange = (value) => {
    const formattedDate = {}
    formattedDate.startDate = moment(value.startDate).startOf('day').utc().format()
    formattedDate.endDate = moment(value.endDate).endOf('day').utc().format()
    dispatch(setDateFilter(formattedDate))
  }

  const handleDateRangeClear = () => {
    dispatch(setDateFilter({ startDate: null, endDate: null }))
  }

  const handleFilterSubmit = () => {
    dispatch(applyBadgeDetailsFilter(badgeId))
  }
  const removeDateRange = () => {
    const formattedDate = {}
    formattedDate.startDate = moment().startOf('day').utc().format()
    formattedDate.endDate = moment().startOf('day').utc().format()
    dispatch(setDateFilter(formattedDate))
  }
  const isLoading = loading.challengeTemplates

  return (
    <div data-testid="badge-detail-page" className="badge-detail">
      <Helmet>
        <title>
          {pageTitle} | {currentlySelectedBadge.badgeName}
        </title>
      </Helmet>

      <Breadcrumbs backLinks={breadCrumbLinks} currentLink={currentlySelectedBadge.badgeName} />
      <h1 className="badge-detail__title" data-testid="badge-detail-title">
        {currentlySelectedBadge.badgeName}
      </h1>
      <h2 className="muted-subheader">{currentlySelectedBadge.badgeDescription}</h2>
      <div className="badges-date-range-picker">
        <Form
          className="filter-form challenges-filters"
          data-testid="challenges-filters-form"
          onSubmit={() => {
            handleFilterSubmit(filters.organizationId)
          }}
        >
          <div className="filter-grid">
            <Form.Field data-testid="date-range-field" className="form-field">
              <label>Date Range</label>
              <DateRangePickerReusable
                startDate={filters?.startDate}
                endDate={filters?.endDate}
                allowFutureDates
                displayClearButton
                onClear={handleDateRangeClear}
                onCancel={() => removeDateRange()}
                onChange={({ selection }) => {
                  handleDateRangeFilterChange(selection)
                }}
              />
            </Form.Field>
          </div>

          <Form.Field className="filter-buttons">
            <label className="visibility-hidden">&nbsp;</label>
            <div>
              <Button primary type="submit" loading={isLoading}>
                Filter
              </Button>
            </div>
          </Form.Field>
        </Form>
      </div>
      <div data-testid="badge-detail-table" className="badge-detail__result-table">
        <AdvancedTable rows={rows} columns={columns} index="userId" pagination />
      </div>
    </div>
  )
}

export default BadgeDetailPage
