import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { fetchOrganizations } from '../../reducers/organizations/organizations.actions'

const OrganizationsProvider = () => {
  const dispatch = useDispatch()
  const organizations = useSelector((state) => state.organizations)
  const currentUser = useSelector((state) => state.currentUser)

  useEffect(() => {
    if (isEmpty(organizations) && currentUser.organizationid === 1) {
      dispatch(fetchOrganizations())
    }
  }, [currentUser])

  return null
}

export default OrganizationsProvider
