import React from 'react'
import { BasicTooltip } from '@/components/charts/customCharts/LayeredTooltip/BasicTooltip'

export const LinePointTooltip = ({ point, description }) => {
  const descriptionString = description ? `${description} - ${point.x}: ` : `${point.x}: `
  return (
    <BasicTooltip
      id={
        <span>
          {descriptionString} <strong>{point.y}</strong>
        </span>
      }
      enableChip
      color="#4D63FE"
    />
  )
}
