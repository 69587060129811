import React, { useState } from 'react'
import { Button, Modal, Form, Header } from 'semantic-ui-react'
import { Formik } from 'formik'
import { IconX } from '@tabler/icons-react'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import { ProgressBar } from '@/components/navigation/ProgressBar'
import { closeModal } from '@/reducers/ui/ui.redux'

import './modal.css'

export const WizardModal = ({
  description,
  initialValues = {},
  isLoading = false,
  // disables the buttons based on external conditions
  disableButtons,
  submitDisabledOverride,
  rightButtonSubmitLabel = 'Submit',
  onPageChange,
  onSave,
  onClose,
  pages = [],
  title,
  validationSchema,
  size = 'tiny',
  startPage = 0,
  playbook = null,
  enableButton = false,
  scrollable = false,
  validate,
  alwaysEnablePreviousButton = false,
  innerRef = () => {},
  ...otherProps
}) => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(startPage)
  const lastPage = pages.length - 1
  // Based on the UI Redux store, all modals should have the same close function
  const handleClose = () => {
    dispatch(closeModal())
  }

  const nextPage = (values) => {
    if (currentPage === lastPage) {
      return
    }
    const nextPageIndex = currentPage + 1
    if (onPageChange) onPageChange(values, nextPageIndex)
    setCurrentPage(nextPageIndex)
  }

  const prevPage = (values) => {
    if (currentPage === 0) {
      return
    }
    const prevPageIndex = currentPage - 1
    if (onPageChange) onPageChange(values, prevPageIndex)
    setCurrentPage(prevPageIndex)
  }

  const goToPageNumber = (value) => {
    setCurrentPage(value)
  }

  const displayPlaybook = () => {
    return playbook ? <span className="target-playbook">{playbook?.name}</span> : <></>
  }

  const rightButton = {
    label: currentPage === lastPage ? rightButtonSubmitLabel : 'Continue',
    fn:
      currentPage === lastPage
        ? (handleSubmit) => handleSubmit()
        : (handleSubmit, values) => nextPage(values),
  }

  const headerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }

  const shouldDisableButton = (loading, errors, touched, shouldDisableButtons) => {
    if (enableButton) return !enableButton
    if (loading || !isEmpty(errors) || isEmpty(touched) || shouldDisableButtons) {
      return true
    }
    return false
  }
  // this prop/function isn't needed, can just use yup and the shouldDisableButton function above will disable submit,
  // cant delete until not used by HamtarAcceptReccommendationModal
  const shouldDisableSubmit = (values) => {
    return submitDisabledOverride && submitDisabledOverride(values, currentPage)
  }
  return (
    <Modal
      open
      className="wizard-modal"
      data-testid="youre-a-wizard-harry"
      onClose={onClose || handleClose}
      {...otherProps}
      size={size}
    >
      <Header style={headerStyles}>
        {title} {displayPlaybook()}
        <Button
          icon
          onClick={onClose || handleClose}
          data-testid="basic-modal-close-button"
          className="basic-modal__close-button"
        >
          <IconX />
        </Button>
      </Header>
      <Formik
        initialValues={initialValues}
        validate={validate ? (values) => validate(values, currentPage) : null}
        validationSchema={validationSchema}
        onSubmit={onSave}
        enableReinitialize
        innerRef={innerRef}
      >
        {({ handleSubmit, values, errors, touched, setFieldValue, ...props }) => (
          <>
            <Modal.Content scrolling={scrollable}>
              {description && <p>{description}</p>}
              {/* Expects pages to be a list of functions so we can pass form values */}
              {/* as well as the goToPageNumber function. */}
              {/* Check the pages list on ScorecardWizard.js for an example */}
              <Form>
                {pages.length
                  ? pages[currentPage](values, goToPageNumber, errors, setFieldValue, props)
                  : null}
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <div className="wizard-modal__button-container">
                <div style={{ minWidth: '120px' }}>
                  {currentPage === 0 || (
                    <Button
                      data-testid="left-wizard-button"
                      className="wizard-modal__cancel-button"
                      onClick={(values) => prevPage(values)}
                      disabled={
                        // don't want to disable close modal button
                        alwaysEnablePreviousButton
                          ? false
                          : shouldDisableButton(isLoading, errors, touched, disableButtons)
                      }
                    >
                      Previous
                    </Button>
                  )}
                </div>
                <div className="wizard-modal__page-status">
                  <ProgressBar totalPages={pages.length} currentPage={currentPage + 1} isProgress />
                </div>
                <div style={{ minWidth: '120px' }}>
                  <Button
                    data-testid="right-wizard-button"
                    primary
                    type={currentPage === lastPage ? 'submit' : 'button'}
                    onClick={() => rightButton.fn(handleSubmit, values)}
                    loading={isLoading}
                    disabled={
                      shouldDisableSubmit(values) ||
                      shouldDisableButton(isLoading, errors, touched, disableButtons)
                    }
                  >
                    {rightButton.label}
                  </Button>
                </div>
              </div>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </Modal>
  )
}
