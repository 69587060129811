import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { DashboardWidgetSidebar } from '@/components/dashboards/DashboardWidgetSidebar'
import { addNewWidget, acceptWidget } from '@/reducers/userSettings/userSettings.actions'

import { GPT_DASHBOARD_TYPE } from '../widgets/gptWidgets'

export const InsightsDrawer = ({ objectives, handleClose }) => {
  const dispatch = useDispatch()
  const { dashboardSettings } = useSelector((state) => state.userSettings)

  const handleAddWidgetToDashboard = (objective) => {
    const widgetSetting = dashboardSettings[GPT_DASHBOARD_TYPE].find(
      (setting) => setting.chart_id === objective.id
    )

    if (widgetSetting) {
      dispatch(acceptWidget(GPT_DASHBOARD_TYPE, widgetSetting))
    } else {
      dispatch(addNewWidget(GPT_DASHBOARD_TYPE, { chart_id: objective.id }))
    }
    handleClose()
  }

  const filteredObjectives = objectives.filter((objective) => {
    // Filter accepted widgets out of the list
    return !dashboardSettings[GPT_DASHBOARD_TYPE].find(
      (dashboardSetting) => dashboardSetting.chart_id === objective.id && dashboardSetting.accepted
    )
  })

  return (
    <div className="insights-drawer">
      {!isEmpty(filteredObjectives) ? (
        filteredObjectives.map((objective, index) => {
          return (
            <DashboardWidgetSidebar
              key={objective.id}
              index={index}
              title={objective.name}
              description={objective.description}
              handleSubmit={() => handleAddWidgetToDashboard(objective)}
            />
          )
        })
      ) : (
        <div className="empty">
          <p>No widgets to add.</p>
        </div>
      )}
      <div className="dashboard-widget dashed">
        <h3>Request Custom Insight</h3>
        <p>
          Reach out to your Customer Success Manager to discuss and align on tracking a unique data
          insight tailored to your specifc needs.
        </p>
      </div>
    </div>
  )
}
