import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import classNames from 'classnames'

export const Drop = ({ droppableId, type, condensed, ...props }) => {
  return (
    <Droppable droppableId={droppableId} type={type}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classNames('droppable-area', {
              'is-dragging-over': snapshot.isDraggingOver,
              condensed,
            })}
            {...props}
          >
            {props.children}
            {provided.placeholder}
          </div>
        )
      }}
    </Droppable>
  )
}
