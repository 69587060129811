import React, { useEffect, useMemo, useState } from 'react'
import { BadgeWizardEditForm } from '@/views/Gamification/Badges/BadgeWizard/BadgeWizardEditForm'
import { BadgeWizardCard } from '@/views/Gamification/Badges/BadgeWizard/BadgeWizardCard'

export const BadgeWizardGrid = ({
  badges,
  loading,
  onOpen,
  onClose,
  onSave,
  selectedBadge,
  agentOptions,
}) => {
  const [maxColumn, setMaxColumn] = useState(null)
  const grid = useMemo(() => document.getElementById('badge-wizard-grid'))

  useEffect(() => {
    if (grid) {
      const gridStyle = window.getComputedStyle(grid)
      const gridColumnCount = gridStyle
        ?.getPropertyValue('grid-template-columns')
        ?.split(' ')?.length
      setMaxColumn(gridColumnCount)
    }
  })

  if (!badges.length) {
    return (
      <div className="badge-wizard__no-results">
        <div>No badges found. Create a specialty badge to get started.</div>
      </div>
    )
  }

  return (
    <>
      {badges.map((badge, idx) =>
        selectedBadge?.id === badge.id ? (
          <BadgeWizardEditForm
            loading={loading}
            badge={selectedBadge}
            onSave={onSave}
            onClose={onClose}
            isLastColumn={(idx + 1) % maxColumn === 0}
            currentRow={Math.ceil((idx + 1) / maxColumn)}
            currentColumn={(idx + 1) % maxColumn}
            agentOptions={agentOptions}
          />
        ) : (
          <BadgeWizardCard badge={badge} onClick={onOpen} disabled={!!selectedBadge} />
        )
      )}
    </>
  )
}
