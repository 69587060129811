import { fetchingAPI, apiService } from '@/api'
import { toast } from 'react-toastify'

import * as redux from '@/reducers/customColumns/customColumns.redux'

export const fetchCustomColumns = (tableName) => async (dispatch) => {
  dispatch(redux.setLoading({ [tableName]: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.reporting}/api/user_settings/custom_columns/${tableName}`,
      'GET',
      dispatch
    )

    dispatch(redux.setCustomColumns({ [tableName]: response.columns }))
  } catch (err) {
    console.error(err)
    // fail silently
  } finally {
    dispatch(redux.setLoading({ [tableName]: false }))
  }
}

export const upsertCustomColumns = (tableName, customColumns) => async (dispatch) => {
  dispatch(redux.setLoading({ [tableName]: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.reporting}/api/user_settings/custom_columns/${tableName}`,
      'POST',
      dispatch,
      JSON.stringify(customColumns)
    )

    dispatch(redux.setCustomColumns({ [tableName]: response.columns }))
  } catch {
    toast.error('Failed to update columns')
  } finally {
    dispatch(redux.setLoading({ [tableName]: false }))
  }
}
