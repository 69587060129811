import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { openModal } from '@/reducers/ui/ui.redux'
import { editAlertConfiguration } from '@/reducers/realtime/realtime.actions'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { AlertWizard } from './AlertWizard/AlertWizard'
import { OrgLevelAlertWizard } from './OrgLevelAlertWizard/OrgLevelAlertWizard'

const AlertHeaderComponent = ({ flags = {}, readOnly = false, loading = false }) => {
  const { currentlyOpenModalId: modal } = useSelector((state) => state.ui)
  const state = useSelector((state) => state)
  const dispatch = useDispatch()
  const {
    data: { agents: organizationUsers, tags: organizationTags },
  } = useSelector((state) => state.realtimeCoaching)
  const { realtimeNewAlertConfiguration } = state
  const { user_id: currentUserId } = useSelector((state) => state.currentUser)
  const updateAlertConfiguration = (values) => {
    const body = {
      ...values,
    }

    const trigger_on = []

    for (const item of body.triggerItems) {
      trigger_on.push({
        cid: item.playbook.value,
        path:
          values.triggerType === 'absence'
            ? `requirement_missed - ${item.path.value}`
            : item.path.value,
      })
    }

    const managers = (body?.managers || []).map((manager) => manager.value)
    const onlyYourselfSelected = managers.length === 1 && managers[0] === currentUserId

    const newAlert = {
      data: {
        name: body.name,
        message: body.message || '',
        type: body.alertType,
        all_users: body.alertsFor === 'all',
        users:
          body.alertsFor === 'agents' && body.selectedUsers && body.selectedUsers.length > 0
            ? body.selectedUsers.map((item) => item.value)
            : [],
        tags:
          body.alertsFor === 'tags' && body.selectedTags && body.selectedTags.length > 0
            ? body.selectedTags.map((item) => item.value)
            : [],
        trigger_on,
        audible: body.audible,
        active: body.active,
      },
    }

    if (!onlyYourselfSelected) {
      newAlert.data.managers = managers
    }

    dispatch(editAlertConfiguration(newAlert, `${body.name} has been created`))
  }

  const createNewAlertConfiguration = (values) => {
    updateAlertConfiguration(values)
  }

  const updateOrgLevelAlertConfiguration = ({
    name,
    alertType,
    selectedAgents,
    selectedTags,
    triggerItems,
    audible,
    active,
  }) => {
    dispatch(
      editAlertConfiguration(
        {
          data: {
            name,
            message: '',
            all_users: false,
            type: alertType,
            users: selectedAgents.map(({ value }) => value),
            tags: selectedTags.map(({ value }) => value),
            trigger_on: triggerItems.map(({ playbookCid, triggerItemPath }) => ({
              cid: playbookCid,
              path: triggerItemPath,
            })),
            audible,
            active,
          },
        },
        `${name} has been created`
      )
    )
  }

  const createNewOrgLevelAlertConfiguration = (values) => {
    updateOrgLevelAlertConfiguration(values)
  }

  const openAlertModal = () => {
    dispatch(openModal('newAlert'))
  }

  const openOrgLevelAlertModal = () => {
    dispatch(openModal('newOrgLevelAlert'))
  }

  return (
    <div>
      <header className="page-header">
        <h1>{`My Alerts ${readOnly ? '(Read Only)' : ''}`}</h1>
        <div>
          <Button
            primary
            data-testid="add-alert-button"
            onClick={() => openAlertModal()}
            disabled={readOnly || loading}
            loading={loading}
          >
            Create Alert
          </Button>
          {modal === 'newAlert' && (
            <AlertWizard
              tags={organizationTags}
              handleSubmit={createNewAlertConfiguration}
              configPaths={realtimeNewAlertConfiguration.configPaths}
              organizationUsers={organizationUsers}
            />
          )}
          {flags.cloudRtcOrgLevelAlertCreation2022 && (
            <Button
              primary
              data-testid="add-org-level-alert-button"
              onClick={() => openOrgLevelAlertModal()}
              disabled={readOnly || loading}
              loading={loading}
            >
              Create Org Level Alert
            </Button>
          )}
          {modal === 'newOrgLevelAlert' && (
            <OrgLevelAlertWizard handleSubmit={createNewOrgLevelAlertConfiguration} />
          )}
        </div>
      </header>
    </div>
  )
}

export const AlertHeader = withLDConsumer()(AlertHeaderComponent)
