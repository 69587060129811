import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Grid } from 'semantic-ui-react'
import ScorecardGraphContainer from '@/views/Scorecards/DashboardPage/GraphContainer'
import { BarChartWithLayers } from '@/components/charts/customCharts/BarChartWithLayers'
import { RankedProgressBarChart } from '@/components/charts/RankedProgressBarChart/RankedProgressBarChart'
import {
  agentCardStyleClass,
  bottomAgent,
  colors,
  getScorecardAggregateHeight,
  getScorecardAggregates,
  topAgent,
} from '@/views/Scorecards/DashboardPage/helpers'
import {
  fetchAggregateScoresByOrgIdMultipleScorecards,
  fetchAggregateScoresByOrgIdMultipleScorecardsByDay,
  fetchAggregateScoresByOrgIdMultipleScorecardsByUser,
} from '@/reducers/scorecards/scorecards.actions'
import { AnalyticsCard } from '@/components/cards/AnalyticsCard'
import { compareToPreviousRange, getCardVariant, truncateString } from '@/utils/helpers'
import { getTickValues } from '@/components/charts/helpers'

import { ViewScoresFilters } from '../ViewScoresFilters/ViewScoresFilters'
import BulletChart from '../../../components/charts/BulletChart'

import './dashboardPage.css'

export const ReportsDashboardPage = () => {
  const dispatch = useDispatch()
  const { data, loading } = useSelector((state) => state.scorecards)
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const currentCallCount = data.reporting?.call_counts?.total || 0
  const previousCallCount = data?.reportingComparison?.call_counts?.total || 0

  const handleFilterSubmit = () => {
    // This will use the dropdown value if its set for an admin, if not default to currentUser.
    const orgIdToUse = data.currentOrganizationId || organizationId
    dispatch(fetchAggregateScoresByOrgIdMultipleScorecards(orgIdToUse))
    dispatch(fetchAggregateScoresByOrgIdMultipleScorecardsByDay(orgIdToUse))
    dispatch(fetchAggregateScoresByOrgIdMultipleScorecardsByUser(orgIdToUse))
  }

  useEffect(() => {
    handleFilterSubmit()
  }, [])

  const scoreTrendsData = data.reportingByDay?.aggregates || []
  const callCountsData = data.reportingByDay?.calls || []
  const userColumns = [
    { accessor: 'rank', label: 'Rank' },
    { accessor: 'user_full_name', label: 'Agent' },
    { accessor: 'total', label: 'Score' },
    { accessor: 'number_of_calls', label: 'Calls' },
  ]

  return (
    <>
      <div data-testid="scorecard-dashboard" className="scorecard-dashboard">
        <ViewScoresFilters
          handleFilterSubmit={handleFilterSubmit}
          hide={{
            agents: true,
            tags: true,
            sections: true,
            measures: true,
            criteria: true,
            scorecards: true,
          }}
        />
        <Grid columns="equal">
          <Grid.Column>
            <AnalyticsCard
              loading={loading.reporting}
              arrowUp={currentCallCount > previousCallCount}
              data={currentCallCount}
              description="Total Calls Scored"
              percent={compareToPreviousRange(currentCallCount, previousCallCount)}
              variant={getCardVariant(currentCallCount, previousCallCount)}
              testId="calls-count-box"
              className="analytics-card"
            />
          </Grid.Column>
          <Grid.Column>
            <AnalyticsCard
              loading={loading.reporting}
              arrowUp
              data={`${data.reporting?.aggregate_scores?.total.toFixed(2) || 0}%`}
              description="Average QA Score"
              variant="positive"
              testId="average-score-box"
              dateDescription=""
              className="analytics-card"
            />
          </Grid.Column>
          <Grid.Column>
            <AnalyticsCard
              loading={loading.reportingByUser}
              data={topAgent(data?.reportingByUser?.aggregate_scores) || 'No Data Available'}
              description="Top Agent"
              testId="top-agent-box"
              dateDescription=""
              displayDataClass={agentCardStyleClass(
                topAgent(data?.reportingByUser?.aggregate_scores)
              )}
              enableAnalyticsCardPopup={!!topAgent(data?.reportingByUser?.aggregate_scores)}
            />
          </Grid.Column>
          <Grid.Column>
            <AnalyticsCard
              loading={loading.reporting}
              data={bottomAgent(data?.reportingByUser?.aggregate_scores) || 'No Data Available'}
              description="Bottom Agent"
              testId="bottom-agent-box"
              dateDescription=""
              displayDataClass={agentCardStyleClass(
                bottomAgent(data?.reportingByUser?.aggregate_scores)
              )}
              enableAnalyticsCardPopup={!!bottomAgent(data?.reportingByUser?.aggregate_scores)}
            />
          </Grid.Column>
        </Grid>
        <Grid columns="equal">
          <Grid.Column>
            <ScorecardGraphContainer
              loading={loading.reportingByDay}
              dataTestId="scores-by-day-chart"
              title="Score Trends"
              subTitle="All Scorecards"
              noData={isEmpty(scoreTrendsData)}
              graph={
                <BarChartWithLayers
                  data={scoreTrendsData}
                  secondaryChartData={callCountsData}
                  secondaryChartDataKey="y"
                  secondaryChartType="line"
                  tooltipDescription="Total Calls"
                  withCustomLegend
                  withCustomAxis
                  keys={['QA Score']}
                  indexBy="day"
                  layout="vertical"
                  colors={[colors.bars]}
                  enableGridY
                  borderRadius={3}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    padding: 0.5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getTickValues(scoreTrendsData, 'day'),
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    format: (v) => `${truncateString(v, 30)}%`,
                  }}
                />
              }
            />
          </Grid.Column>
          <Grid.Column>
            <ScorecardGraphContainer
              loading={loading.reporting}
              dataTestId="scorecard-aggregate-graph"
              title="Scorecard Performance"
              subTitle="Top 5 Scorecards"
              noData={isEmpty(data.reporting?.scorecard_configs)}
              graph={
                <div
                  style={{
                    height: `${getScorecardAggregateHeight(data.reporting)}px`,
                  }}
                >
                  <BulletChart
                    data={
                      data.reporting?.scorecard_configs
                        ? getScorecardAggregates(data?.reporting)
                        : []
                    }
                  />
                </div>
              }
            />
          </Grid.Column>
        </Grid>
        <Grid columns="equal">
          <Grid.Column>
            <ScorecardGraphContainer
              loading={loading.reporting}
              dataTestId="top-list-chart-card"
              title="Scorecard Performance"
              subTitle="Agent Performance"
              noData={isEmpty(data?.reportingByUser?.aggregate_scores)}
              graph={
                <div style={{ height: '350px' }}>
                  <RankedProgressBarChart
                    columns={userColumns}
                    rows={data?.reportingByUser?.aggregate_scores || []}
                    loading={loading.reportingByUser}
                    progressKey="total"
                    baltoThemeColor="blurple"
                    format={(value) => `${Math.round(value * 100) / 100}%`}
                    maxValue={100}
                    defaultOrderBy="rank"
                  />
                </div>
              }
            />
          </Grid.Column>
          <Grid.Column />
        </Grid>
      </div>
    </>
  )
}

export default ReportsDashboardPage
