import React, { useState } from 'react'
import { MultiSelect } from '@/components/forms/MultiSelect'

export const ExclusiveMultiSelect = ({ options = [], value, initialSelectIndex = 0, ...props }) => {
  const [visibleSelectIndex, setVisibleSelectIndex] = useState(initialSelectIndex)

  const handleVisibleSelectChange = (index) => {
    if (visibleSelectIndex === index) return
    setVisibleSelectIndex(index)
  }

  const labels = options.map((option) => option.label)

  const selectedIndexOnChange = options[visibleSelectIndex].onChange

  return (
    <MultiSelect
      isExclusive
      isClearable={false}
      options={options[visibleSelectIndex].data}
      labels={labels}
      value={value}
      visibleSelectIndex={visibleSelectIndex}
      onVisibleSelectChange={handleVisibleSelectChange}
      onChange={selectedIndexOnChange || props.onChange}
      condenseOptions={false}
      {...props}
    />
  )
}
