import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { IconCheck, IconX } from '@tabler/icons-react'
import { v4 as uuid } from 'uuid'

import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { editScorecardConfig } from '@/reducers/qa-copilot/qa-copilot.redux'

export const QACopilotAddSection = () => {
  const dispatch = useDispatch()
  const [isAdding, setIsAdding] = useState(false)
  const { scorecardConfig } = useSelector((state) => state.qaCopilot)

  const handleAddSection = (values) => {
    const updatedScorecardConfig = {
      ...scorecardConfig,
      sections: [
        ...scorecardConfig.sections,
        {
          ssid: uuid(),
          name: values.name,
          measures: [{ name: `${values.name} Measure`, criteria: [] }],
          order_id: scorecardConfig.sections.length,
        },
      ],
    }

    dispatch(editScorecardConfig(updatedScorecardConfig))
    setIsAdding(false)
  }

  if (isAdding) {
    return (
      <div style={{ maxWidth: '300px', marginTop: '1rem' }} data-testid="add-section-name-form">
        <AbstractForm
          horizontal
          noLabel
          onSubmit={handleAddSection}
          schema={[
            {
              name: 'name',
              type: 'text',
              required: true,
              autoFocus: true,
              autoComplete: 'off',
              placeholder: 'Add section name',
            },
          ]}
          buttonProps={{ className: 'svg-button' }}
          closeButtonProps={{ className: 'svg-button' }}
          buttonLabel={<IconCheck />}
          closeButtonLabel={<IconX />}
          onClose={() => setIsAdding(false)}
        />
      </div>
    )
  }

  return (
    <Button
      secondary
      onClick={() => setIsAdding(true)}
      style={{ marginTop: '1rem' }}
      data-testid="add-section-button"
    >
      Add Section
    </Button>
  )
}
