import React from 'react'
import { Menu } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

import { Pill } from '@/components/pills/Pill'

import { sections, playbookTitlesSectionMap, accessorSectionMap } from '../playbook.helpers'

const tabs = [
  { route: sections.CHECKLIST, title: playbookTitlesSectionMap[sections.CHECKLIST] },
  { route: sections.DYNAMIC_PROMPT, title: playbookTitlesSectionMap[sections.DYNAMIC_PROMPT] },
  { route: sections.NOTIFICATION, title: playbookTitlesSectionMap[sections.NOTIFICATION] },
  { route: sections.STICKY_NOTE, title: playbookTitlesSectionMap[sections.STICKY_NOTE] },
  { route: sections.POSTCALL, title: playbookTitlesSectionMap[sections.POSTCALL] },
  { route: sections.BEACON, title: playbookTitlesSectionMap[sections.BEACON] },
]

const onlyVisibleOnLatestVersion = [sections.BEACON]

const PlaybookTabs = ({ playbookId, section, validationError, isLatestPlaybookVersion }) => {
  return (
    <Menu pointing secondary className="tab-navigation background-tab-navigation">
      {tabs
        .filter((tab) => {
          if (onlyVisibleOnLatestVersion.includes(tab.route) && !isLatestPlaybookVersion) {
            return false
          }
          return true
        })
        .map((tab) => {
          const isActive = section === tab.route
          const hasValidationError = validationError?.accessor === accessorSectionMap[tab.route]

          return (
            <NavLink
              key={tab.route}
              to={`/playbooks/${playbookId}/${tab.route}`}
              className="item"
              data-testid={`playbook-tabs-${tab.route}-${isActive ? 'active' : 'inactive'}`}
            >
              <span>
                {tab.title}
                {hasValidationError && (
                  <Pill small critical emphasized style={{ marginLeft: '0.5rem' }}>
                    1
                  </Pill>
                )}
              </span>
            </NavLink>
          )
        })}
    </Menu>
  )
}

export default PlaybookTabs
