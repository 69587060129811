import React, { useEffect } from 'react'
import { Header } from 'semantic-ui-react'
import { RadioGroup } from '@/components/forms/formik'
import { Field } from 'formik'
import { AgentsTagsExclusivePicker } from '@/views/RealtimeCoaching/components/pickers/AgentsTagsExclusivePicker'
import { isEmpty } from 'lodash'

export const AlertAgentSelectForm = ({
  isEditPage = false,
  values,
  errors,
  setFieldValue,
  readOnly = false,
}) => {
  const maxUnCondensedOptions = isEditPage ? 8 : 5
  const { selectedAgents, selectedTags, alertOn = 'custom' } = values

  useEffect(() => {
    if (!isEditPage) {
      setFieldValue('displayedForm', 'alertAgentSelectForm')
    }
  }, [])

  return (
    <div className="alert-wizard__page" data-testid="alert-wizard__agent-page">
      {!isEditPage && <Header className="alert-wizard__field">Select Agents</Header>}
      <Field
        required
        disabled={readOnly}
        errors={errors}
        name="alertOn"
        label={
          <>
            <label className="alert-wizard__field-label-text">Alert on</label>
            <div className="alert-wizard__field-subtext">
              Choose which agents or tags you would like to be alerted on
            </div>
          </>
        }
        component={RadioGroup}
        options={[
          { value: 'custom', text: 'Custom Select' },
          // { value: 'all', text: 'All Agents' },
        ]}
      />
      {alertOn === 'custom' && (
        <div data-testid="agents-tags-exclusive-picker">
          <Field className="alert-wizard__field" required name="selectedAgentsOrTags">
            {(formikProps) => (
              <AgentsTagsExclusivePicker
                onAgentChange={(event) => {
                  formikProps.form.setFieldValue('selectedAgents', event)
                }}
                onTagChange={(event) => {
                  formikProps.form.setFieldValue('selectedTags', event)
                }}
                value={[...selectedAgents, ...selectedTags]}
                initialSelectIndex={!isEmpty(selectedTags) ? 1 : 0}
                maxUnCondensedOptions={maxUnCondensedOptions}
              />
            )}
          </Field>
        </div>
      )}
    </div>
  )
}
