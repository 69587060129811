import React, { Component } from 'react'
import classNames from 'classnames'

import { Header, Modal, Button, Popup, Icon } from 'semantic-ui-react'

import { IconX } from '@tabler/icons-react'

export default class Form extends Component {
  constructor(props) {
    super(props)

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.renderButton = this.renderButton.bind(this)
    this.state = { modalOpen: false }
  }

  handleOpen() {
    // if we pass modalOpen, another component's state is managing whether or not it's open
    const { modalOpen } = this.props

    if (typeof modalOpen === 'boolean') {
      this.props.openModal()
    } else {
      this.setState({ modalOpen: true })
    }
  }

  handleClose(e, options) {
    const { modalOpen } = this.props

    if (typeof modalOpen === 'boolean') {
      this.props.closeModal(e, options)
    } else {
      this.setState({ modalOpen: false })
    }
  }

  renderButton() {
    const { icon, buttonLabel, buttonProps, muted, buttonId, disabled, popupContent } = this.props

    return popupContent ? (
      <Popup
        on="hover"
        content={popupContent}
        inverted
        trigger={
          <Button
            primary={!muted}
            disabled={disabled}
            data-testid={`${buttonId}-default`}
            onClick={this.handleOpen}
            className={classNames({ 'minimal-button': muted })}
            icon={!!icon}
            {...buttonProps}
          >
            {buttonLabel || <Icon className={icon} style={{ margin: 0 }} />}
          </Button>
        }
      />
    ) : (
      <Button
        primary={!muted}
        disabled={disabled}
        data-testid={`${buttonId}-default`}
        onClick={this.handleOpen}
        className={classNames({ 'minimal-button': muted })}
        icon={!!icon}
        {...buttonProps}
      >
        {buttonLabel || <Icon className={icon} style={{ margin: 0 }} />}
      </Button>
    )
  }

  render() {
    const {
      header,
      form,
      modalOpen,
      fullScreen,
      modalSize = 'small',
      canCloseOnDimmerClick = true,
    } = this.props

    const newForm = React.cloneElement(form, {
      modalClose: this.handleClose,
      closeModal: this.handleClose,
    })

    return (
      <Modal
        trigger={this.renderButton()}
        size={fullScreen ? 'fullscreen' : modalSize}
        open={modalOpen || this.state.modalOpen}
        onClose={(e) => this.handleClose(e, false)}
        closeOnDimmerClick={canCloseOnDimmerClick}
      >
        <Header className="basic-modal__header">
          <h1>{header}</h1>
          <Button
            icon
            onClick={(e) => this.handleClose(e, false)}
            data-testid="basic-modal-close-button"
            className="basic-modal__close-button"
          >
            <IconX />
          </Button>
        </Header>
        <Modal.Content>{newForm}</Modal.Content>
      </Modal>
    )
  }
}
