import React from 'react'
import { Popup, Table } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import pluralize from 'pluralize'

export const ReviewPageUsersTable = ({ selectedAgents, selectedTags, goToPageNumber }) => {
  let selectedList = []
  let selectedListText
  if (!isEmpty(selectedAgents)) {
    selectedList = selectedAgents
    selectedListText = pluralize('agent', selectedAgents.length)
  }
  if (!isEmpty(selectedTags)) {
    selectedList = selectedTags
    selectedListText = pluralize('tag', selectedTags.length)
  }

  const createHoverString = (selectedList) => {
    const maxLen = 14
    let hoverString = selectedList[0].label
    let endingString = ''
    let maxElements
    if (selectedList.length < maxLen) {
      maxElements = selectedList.length
    } else {
      endingString += `, +${selectedList.length - maxLen} more`
      maxElements = maxLen
    }
    for (let i = 1; i < maxElements; i++) {
      hoverString += `, ${selectedList[i].label}`
    }
    hoverString += endingString
    return hoverString
  }

  const createPopup = (selectedList, selectedListText) => {
    if (isEmpty(selectedList)) return '0 selected'
    const hoverString = createHoverString(selectedList)
    return (
      <Popup
        position="top center"
        trigger={<span>{`${selectedList.length} ${selectedListText}`}</span>}
        content={hoverString}
      />
    )
  }

  return (
    <Table className="alert-wizard__review-page-table" basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="alert-wizard__review-table-header-cell">
            Users
          </Table.HeaderCell>
          <Table.HeaderCell
            className="alert-wizard__review-edit-button alert-wizard__review-table-header-cell"
            textAlign="right"
            onClick={() => goToPageNumber(1)}
            data-testid="edit-users"
          >
            Edit
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell className="alert-wizard__review-table-cell">Alert on</Table.Cell>
          <Table.Cell className="alert-wizard__review-table-cell" textAlign="right">
            <span className="rtc-dotted-show-hover" data-testid="review-page-users">
              {createPopup(selectedList, selectedListText)}
            </span>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}
