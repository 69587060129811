import React from 'react'
import uuid from 'uuid/v4'
import { Button, Form, Grid, Icon } from 'semantic-ui-react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext } from 'react-beautiful-dnd'
import { isEmpty } from 'lodash/lang'
import * as Yup from 'yup'
import { cloneDeep } from 'lodash'

import { Pill } from '@/components/pills/Pill'
import { closeModal } from '@/reducers/ui/ui.redux'

import { EditSectionsRow } from './EditSectionsRow'
import { ModalFields } from './ModalFields'
import { handleDrag } from '../helpers/index'
import { sectionsSchema } from './scorecardConfig.schema'

import './editSectionsForm.css'

export const handleAddSection = (push, items) => {
  const scorecardConfigId = items[0]?.scorecard_config_id
  const newSection = {
    name: '',
    active: true,
    deleted: false,
    measures: [],
    placeholder: 'Section Name',
    scorecard_config_id: scorecardConfigId,
    weight: 20,
    order_id: items.length,
    id: uuid(),
  }
  push(newSection)
}
export const SectionWeightForm = ({ sections = [], handleSubmit, updateSectionsObject }) => {
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.scorecards)
  let totalWeight

  const createSectionsObject = (sections) => {
    return sections.reduce((reducer, section) => ({ ...reducer, [section.name]: section }), {})
  }

  const onSubmit = async (values) => {
    const request = createSectionsObject(values.sections)
    await handleSubmit(request)
  }

  const onDragEnd = async (result) => {
    const { destination, source } = result
    if (!destination) {
      return
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }
    const updateSections = handleDrag(result, sections)
    await updateSectionsObject(updateSections)
  }

  const addSection = (push, items) => {
    const scorecardConfigId = items[0]?.scorecard_config_id
    const newSection = {
      name: '',
      active: true,
      deleted: false,
      measures: [],
      placeholder: 'Section Name',
      scorecard_config_id: scorecardConfigId,
      weight: 20,
      order_id: items.length,
      id: uuid(),
    }
    const newSections = [...sections, newSection]
    const updateSections = cloneDeep(newSections)
    updateSectionsObject(updateSections)
  }

  const removeObject = async (formValues) => {
    const updateSections = sections.filter((section) => section.id !== formValues.id)
    await updateSectionsObject(updateSections)
  }
  const updateNameInObject = async (e, formValues) => {
    const updateSectionWeight = sections.map((section) => {
      const newSection = section
      if (section.id === formValues.id) {
        newSection.name = e.target.value
      }
      return newSection
    })
    const updateSections = cloneDeep(updateSectionWeight)
    await updateSectionsObject(updateSections)
  }
  const updateStatusInObject = async (formValues) => {
    const updateSectionStatus = sections.map((section) => {
      const newSection = section
      if (section.id === formValues.id) {
        newSection.active = !formValues.active
      }
      return newSection
    })
    const updateSections = cloneDeep(updateSectionStatus)
    await updateSectionsObject(updateSections)
  }
  const updateWeightInObject = async (e, formValues) => {
    const updateSectionWeight = sections.map((section) => {
      const newSection = section
      if (section.id === formValues.id) {
        newSection.weight = parseInt(e.target.value, 10)
      }
      return newSection
    })
    const updateSections = cloneDeep(updateSectionWeight)
    await updateSectionsObject(updateSections)
  }

  const shape = Yup.object().shape(sectionsSchema)
  return (
    <Formik
      enableReinitialize
      initialValues={{ sections }}
      validationSchema={shape}
      onSubmit={onSubmit}
    >
      {({ values, errors, ...props }) => {
        totalWeight = values.sections.reduce((reducer, section) => {
          if (section.weight === '' || !section.active) {
            return reducer
          }
          return section.weight + reducer
        }, 0)
        return (
          <Form onSubmit={props.handleSubmit}>
            <Grid
              className="edit-scorecard-form__container"
              id="edit-scorecard-form__container"
              data-testid="manage-section-form"
            >
              <Grid.Row>
                <Grid.Column
                  style={{ marginLeft: '30px' }}
                  width={8}
                  className="edit-scorecard-form__columns-title"
                >
                  Sections
                </Grid.Column>
                <Grid.Column
                  style={{ textAlign: 'right' }}
                  width={3}
                  className="edit-scorecard-form__columns-title"
                >
                  Status
                </Grid.Column>
                <Grid.Column width={4} className="edit-scorecard-form__columns-title">
                  Weight %
                </Grid.Column>
              </Grid.Row>
              <DragDropContext onDragEnd={onDragEnd}>
                <ModalFields
                  items={values.sections}
                  name="sections"
                  errors={errors}
                  RowComponent={EditSectionsRow}
                  handleAddItem={addSection}
                  addItemLabel="Add Section"
                  disableDraggable={false}
                  removeFromObject={removeObject}
                  updateWeightInObject={updateWeightInObject}
                  updateStatusInObject={updateStatusInObject}
                  updateItemsInObect={updateSectionsObject}
                  updateNameInObject={updateNameInObject}
                />
              </DragDropContext>
              <Grid.Row id="edit-scorecard-form__total-row">
                <Grid.Column width={12} id="edit-scorecard-form__total-row-error">
                  <h5 className="edit-scorecard-form__total-text">Total</h5>
                  {totalWeight !== 100 ? (
                    <>
                      <Icon name="exclamation triangle" color="red" />
                      <p>Sum of weights must equal 100%.</p>{' '}
                    </>
                  ) : null}
                </Grid.Column>
                <Grid.Column width={3}>
                  <Pill
                    critical={totalWeight !== 100}
                    success={totalWeight === 100}
                    data-testid="total-weight-edit"
                  >
                    {`${Math.round(totalWeight).toString().slice(0, 3)}%`}
                  </Pill>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div className="modal-footer">
              <Button
                secondary
                data-testid="edit-scorecard-form__cancel-button"
                onClick={() => dispatch(closeModal())}
              >
                Cancel
              </Button>
              <Button
                data-testid="save_modal"
                type="submit"
                primary
                loading={loading.scorecards}
                disabled={totalWeight !== 100 || loading.scorecards || !isEmpty(errors)}
              >
                Save
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
