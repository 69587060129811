// Action Types
export const SET_ROUTE_ERROR = 'errors/setRouteError'
export const CLEAR_ROUTE_ERRORS = 'errors/clearRouteErrors'

// Action Creators
export const setRouteError = (payload) => ({ type: SET_ROUTE_ERROR, payload })
export const clearRouteErrors = (payload) => ({ type: CLEAR_ROUTE_ERRORS, payload })

// State
export const initialState = []

// Reducer
export default function errorsReducer(state = initialState, action) {
  let errors

  switch (action.type) {
    case SET_ROUTE_ERROR:
      errors = state.concat(action.payload)
      return errors.filter((item, index) => errors.indexOf(item) === index)
    case CLEAR_ROUTE_ERRORS:
      return initialState
    default:
      return state
  }
}
