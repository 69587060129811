import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'semantic-ui-react'
import uuid from 'uuid/v4'
import moment from 'moment'
import { IconX, IconMessages, IconMinus, IconSend, IconArrowUpRight } from '@tabler/icons-react'

export const ChatPanel = () => {
  const dispatch = useDispatch()
  const chats = useSelector((state) => state.realtimeChats)
  const { isOpen: rtcSocketIsOpen } = useSelector((state) => state.realtimeSocket)
  const [chatMessage, setChatMessage] = useState('')
  // this is the open chat within chats.chats
  const {
    messages: activeMessages,
    alertId: activeAlertId,
    agentId: activeAgentId,
    managerId: activeManagerId,
    agentUsername: activeAgentUsername,
    agentLastName: activeAgentLastName,
    agentFirstName: activeAgentFirstName,
    managerUsername: activeManagerUsername,
  } = chats?.chats?.[chats?.activeUsername] || {}

  let messageList = null

  const scrollToBottom = () => {
    messageList.scrollTop = messageList.scrollHeight
  }

  const setActiveUsername = (username) => {
    dispatch({ type: 'realtime/setActiveUsername', activeUsername: username })
  }

  const endChat = (username) => {
    dispatch({ type: 'realtime/endChat', username })
  }

  const sendMessage = ({
    message,
    alertId,
    toUserId,
    fromUserId,
    agentUsername,
    managerUsername,
  }) => {
    // for empty messages, do not do anything!
    if (!message || message === '') {
      return
    }

    dispatch({
      type: 'realtime/addMessageToChat',
      agentUsername,
      message,
      isMessageFromManager: true,
      date: moment().format('LT'),
    })
    // should probably throw some alert here
    // if no rtmsocket so they know it didn't send
    if (rtcSocketIsOpen) {
      dispatch({
        type: 'SEND_CHAT_TO_DESKTOP',
        message,
        alert_id: alertId,
        to_user_id: toUserId,
        from_user_id: fromUserId,
        agent_username: agentUsername,
        manager_username: managerUsername,
      })
    }

    setChatMessage('')
    scrollToBottom()
  }

  const collapseChat = () => {
    dispatch({ type: 'realtime/collapseChat' })
  }

  const expandChat = () => {
    dispatch({ type: 'realtime/expandChat' })
  }

  return (
    Object.keys(chats?.chats || {}).length > 0 && (
      <div className="chats" style={{ height: chats?.collapsed ? '40px' : '374px' }}>
        <div className="pointer chat-header">
          <div className="flex-align-center small-gap">
            <IconMessages />
            <strong>Chat</strong>
          </div>
          <Button
            compact
            basic
            icon
            className="svg-button"
            data-testid="collapse-expand-icon"
            onClick={() => {
              if (chats.collapsed) {
                expandChat()
              } else {
                collapseChat()
              }
            }}
          >
            {chats.collapsed ? <IconArrowUpRight /> : <IconMinus />}
          </Button>
        </div>
        {!chats.collapsed && (
          <div>
            <div className="chat-tabs flex flex-start">
              {Object.keys(chats.chats).map((agent) => {
                const { agentUsername, agentLastName, agentFirstName } = chats?.chats?.[agent]

                return (
                  <div
                    key={uuid()}
                    onClick={() => {
                      setActiveUsername(agentUsername)
                    }}
                    className={
                      chats.activeUsername === agentUsername
                        ? 'chat-tab chat-tab-active'
                        : 'chat-tab'
                    }
                  >
                    {agentFirstName[0]}
                    {agentLastName[0]}
                  </div>
                )
              })}
            </div>
            {Object.keys(chats?.chats) && chats?.chats?.[chats?.activeUsername] && (
              <div className="chat-agent-name">
                <div>{`${activeAgentFirstName} ${activeAgentLastName}`}</div>
                <Button
                  basic
                  compact
                  icon
                  className="svg-button"
                  data-testid="close-icon"
                  onClick={() => {
                    endChat(chats.activeUsername)
                  }}
                >
                  <IconX />
                </Button>
              </div>
            )}
            <div className="chat-body">
              <div
                className="chat-area"
                ref={(el) => {
                  messageList = el
                }}
              >
                {activeMessages.map((messageItem) => {
                  return (
                    <div key={uuid()}>
                      {!messageItem.endMessage ? (
                        <div
                          className={
                            messageItem.isMessageFromManager
                              ? 'chat-time-manager'
                              : 'chat-time-agent'
                          }
                        >
                          {messageItem.date}
                        </div>
                      ) : (
                        <div className="chat-time-ended">{messageItem.date}</div>
                      )}
                      {!messageItem.endMessage ? (
                        <div
                          className={
                            messageItem.isMessageFromManager
                              ? 'chat-text-manager'
                              : 'chat-text-agent'
                          }
                        >
                          {messageItem.message}
                        </div>
                      ) : (
                        <div className="chat-text-ended">{messageItem.message}</div>
                      )}
                    </div>
                  )
                })}
              </div>
              <div className="chat-footer">
                <div className="chat-text-input-container">
                  <textarea
                    data-testid="chat-box"
                    className="chat-textarea"
                    value={chatMessage}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    onChange={(e) => {
                      if (e.target.value.includes('\n')) {
                        setChatMessage('')
                      } else {
                        setChatMessage(e.target.value)
                      }
                    }}
                    onKeyPress={(e) => {
                      // if enter is pressed.
                      if (e.key === 'Enter') {
                        sendMessage({
                          message: chatMessage,
                          alertId: activeAlertId,
                          toUserId: activeAgentId,
                          fromUserId: activeManagerId,
                          agentUsername: activeAgentUsername,
                          managerUsername: activeManagerUsername,
                        })
                      }
                    }}
                  />
                  <Button
                    icon
                    compact
                    primary
                    className="svg-button chat-icon"
                    data-testid="send-chat"
                    onClick={() => {
                      sendMessage({
                        message: chatMessage,
                        alertId: activeAlertId,
                        toUserId: activeAgentId,
                        fromUserId: activeManagerId,
                        agentUsername: activeAgentUsername,
                        managerUsername: activeManagerUsername,
                      })
                    }}
                  >
                    <IconSend />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  )
}
