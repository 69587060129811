import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TransitionablePortal, Button } from 'semantic-ui-react'
import { IconX } from '@tabler/icons-react'

import { clearRouteErrors } from '@/reducers/errors/routeErrors.redux'

import './ErrorWrapper.scss'

const ErrorWrapper = () => {
  const dispatch = useDispatch()
  const routeErrors = useSelector((state) => state.routeErrors)

  return (
    <TransitionablePortal
      open={routeErrors.length > 0}
      transition={{ animation: 'fade down', duration: 200 }}
      onClose={() => dispatch(clearRouteErrors())}
    >
      <div className="global-error">
        <header className="flex-space-between">
          <h2>Action Failed</h2>
          <Button
            basic
            compact
            icon
            className="svg-button"
            onClick={() => dispatch(clearRouteErrors())}
          >
            <IconX />
          </Button>
        </header>
        {routeErrors.length > 1 ? (
          <ul>
            {routeErrors.map((errorMessage) => (
              <li key={errorMessage}>{errorMessage}</li>
            ))}
          </ul>
        ) : (
          <p>{routeErrors}</p>
        )}
      </div>
    </TransitionablePortal>
  )
}

export default ErrorWrapper
