import React from 'react'
import classNames from 'classnames'

import './ListeningStatus.scss'

export const ListeningStatus = ({ status, absolute }) => {
  if (status === 'no_calls_today') return null

  return (
    <div
      className={classNames('pulse-status', {
        'green-pulse': status === 'on_call',
        green: status === 'online',
        gray: status === 'no_calls_today' || status === 'offline' || status === 'had_calls_today',
        absolute,
      })}
    />
  )
}
