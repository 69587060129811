import React, { useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { Grid, Icon } from 'semantic-ui-react'
import { FieldArray } from 'formik'
import './modalFields.css'

export const ModalFields = ({
  items,
  name,
  RowComponent,
  handleAddItem,
  addItemLabel,
  errors,
  removeFromObject,
  disableDraggable = false,
  updateStatusInObject,
  updateNameInObject,
  updateWeightInObject,
}) => {
  const [lastItemAdded, setLastItemAdded] = useState(-1)
  const shouldFocus = (index) => {
    return index === lastItemAdded
  }
  const sortedItems = items?.sort((a, b) => a.order_id - b.order_id)
  return (
    <FieldArray name={name}>
      {({ remove, push }) => (
        <>
          <Droppable
            droppableId="1"
            renderClone={(provided, snapshot, rubric) => (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <Grid.Column className="edit-scorecard-form__icon-drag-drop">
                  <Icon name="align justify" /> {items[rubric.source.index].name}
                </Grid.Column>
              </div>
            )}
          >
            {(provided) => (
              <div
                id="tasklist"
                className="tasklist"
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  width: '100%',
                  display: 'flex',
                  marginBottom: '8px',
                  flexDirection: 'column',
                }}
              >
                {sortedItems?.length > 0 &&
                  sortedItems.map((item, index) => (
                    <RowComponent
                      key={item.id}
                      items={items}
                      newItem={item}
                      index={index}
                      errors={errors}
                      remove={remove}
                      shouldFocus={shouldFocus(index)}
                      disableDraggable={disableDraggable}
                      removeObject={removeFromObject}
                      updateStatusInObject={updateStatusInObject}
                      updateNameInObject={updateNameInObject}
                      updateWeight={updateWeightInObject}
                    />
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <Grid.Row>
            <Grid.Column width={5} className=".modal-fields__add-item-row">
              <a
                className="modal-fields__add-item-link"
                onClick={() => {
                  handleAddItem(push, items)
                  setLastItemAdded(items.length)
                }}
                data-testid="basic-modal-add-item-link"
              >
                <Icon name="plus" /> {addItemLabel}
              </a>
            </Grid.Column>
          </Grid.Row>
        </>
      )}
    </FieldArray>
  )
}
