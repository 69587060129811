import React from 'react'

export default ({ onFileLoaded }) => {
  const handleChangeFile = (e) => {
    const reader = new FileReader()
    if (e.target.files.length > 0) {
      reader.onload = (event) => {
        const csvData = event.target.result
        onFileLoaded(csvData)
      }

      reader.readAsText(e.target.files[0], 'UTF-8')
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <label className="custom-file-upload">
        <input type="file" accept=".csv, text/csv" onChange={(e) => handleChangeFile(e)} />
        Upload CSV
      </label>
    </div>
  )
}
