import React from 'react'
import { Button, Grid, Icon, Popup } from 'semantic-ui-react'
import { Draggable } from 'react-beautiful-dnd'
import { Field } from 'formik'
import { Checkbox, TextField } from '../../../components/forms/formik'
import './editSectionsForm.css'

export const EditSectionsRow = ({
  items,
  index,
  remove,
  shouldFocus,
  errors,
  removeObject,
  updateWeight,
  updateStatusInObject,
  updateNameInObject,
  disableDraggable = false,
}) => {
  const currentSection = items[index]
  return (
    <Draggable
      draggableId={currentSection.id.toString()}
      index={index}
      isDragDisabled={disableDraggable}
    >
      {(provided) => {
        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            id="container"
          >
            <Grid.Row id="edit-scorecard-form__sections-row">
              {!disableDraggable && (
                <Grid.Column className="edit-scorecard-form__icon-drag-drop">
                  <Icon name="align justify" />
                </Grid.Column>
              )}
              <Grid.Column width={5} className="edit-scorecard-form__section-input-name">
                <Field
                  name={`sections.${index}.name`}
                  errorMessage={errors?.sections && errors.sections[index]?.name}
                  data-testid={`sections.${index}.name`}
                  disabled={!currentSection.active}
                  component={TextField}
                  type="text"
                  max="100"
                  style={{ width: '100%' }}
                  placeholder="Section Name"
                  autoFocus={shouldFocus}
                  onChange={(e) => updateNameInObject(e, currentSection)}
                />
              </Grid.Column>
              <Grid.Column width={2} className="edit-scorecard-form__measures-info">
                <Popup
                  trigger={<Icon name="info circle" className="edit-scorecard-form__icon-black" />}
                  hoverable
                >
                  <div className="edit-scorecard-form__measures-info-title">
                    {currentSection?.measures?.length > 0 ? 'Included Measures' : 'No Measures'}
                  </div>
                  {currentSection?.measures?.length > 0 &&
                    currentSection.measures.map((measure) => (
                      <div key={measure.id} className="edit-scorecard-form__measures-info-item">
                        {measure.name}
                      </div>
                    ))}
                </Popup>
              </Grid.Column>
              <Grid.Column width={2}>
                <Field name={`sections.${index}.active`} label={null}>
                  {(formikProps) => (
                    <Checkbox
                      toggle
                      className="edit-scorecard-form__toggle"
                      data-testid="section-active-toggle"
                      {...formikProps}
                      onClick={() => updateStatusInObject(currentSection)}
                    />
                  )}
                </Field>
              </Grid.Column>
              <Grid.Column width={2} className="edit-scorecard-form__input-weight">
                <Field
                  name={`sections.${index}.weight`}
                  data-testid={`sections.${index}.weight`}
                  disabled={!currentSection.active}
                  component={TextField}
                  type="number"
                  label={null}
                  max="100"
                  placeholder="0"
                  onChange={(e) => updateWeight(e, currentSection)}
                />
              </Grid.Column>

              <Grid.Column width={1} className="edit-scorecard-form__delete-column">
                <Button
                  type="button"
                  icon
                  onClick={() => {
                    remove(index)
                    removeObject(currentSection)
                  }}
                  className="edit-scorecard-form__delete-button"
                >
                  <Icon
                    name="trash alternate"
                    className="edit-scorecard-form__icon-lightgrey"
                    data-testid="basic-modal-delete-button"
                  />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </div>
        )
      }}
    </Draggable>
  )
}
