import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '@/reducers/scorecards/scorecards.redux'
import { useHistory } from 'react-router-dom'

import { AdvancedTable } from '../../../../components/tables/AdvancedTable/AdvancedTable'
import { returnScoreColor } from '../../ScoreAccordion/ScoreAccordion'
import './dashboardTable.css'

export const DashboardTableColoredCell = ({ score, scorecardConfig }) => {
  const scoreColor = returnScoreColor(score, scorecardConfig)

  return (
    <div
      className={`scorecard-dashboard__colored-cell ${scoreColor}`}
      data-testid="dashboard-table__colored-cell"
    >
      {`${score.toFixed(2)}%`}
    </div>
  )
}

export const DashboardTable = () => {
  const dispatch = useDispatch()
  const { data, loading } = useSelector((state) => state.scorecards)
  const history = useHistory()

  // Filter out scorecard scores here.
  const columns = data.tableHeaders?.scorecardDashboard.filter(
    (header) => !header.accessor.startsWith('total_calls_') && !header.accessor.startsWith('score_')
  )
  const rows = data.tableRows?.scorecardDashboard.map((userAggregate) => {
    const formattedRow = {}
    Object.keys(userAggregate).forEach((value) => {
      if (value === 'user_full_name' || value === 'overall_total_calls') {
        formattedRow[value] = userAggregate[value]
      } else {
        const aggregateCellData = userAggregate[value]
        formattedRow[value] = {
          as: (
            <DashboardTableColoredCell
              score={aggregateCellData}
              scorecardConfig={data.targetScorecardConfig}
            />
          ),
          value: aggregateCellData,
          cellProps: {
            className: returnScoreColor(aggregateCellData, data.targetScorecardConfig),
          },
        }
      }
    })
    return formattedRow
  })

  const redirectToScores = (e, row) => {
    const agentOption = {
      value: row.user_id.value,
      label: row.user_full_name,
    }
    dispatch(setFilter('agents', [agentOption]))
    history.push(`/scorecards/view_scores`)
  }

  return (
    <div className="scorecard-dashboard__table" data-testid="scorecard-dashboard__table">
      <AdvancedTable
        rows={rows}
        columns={columns}
        loading={loading.aggregateScoresByUser}
        rowAction={(e, row) => redirectToScores(e, row)}
        defaultOrderBy="percentage_score"
        defaultOrder="desc"
        pagination
      />
    </div>
  )
}
