import React, { useEffect } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'

export const AlertTranscript = ({
  realtimeUserAlerts,
  highlightedInstances,
  scrollToInstanceRef,
  groupedAlerts,
  toggleHighlightInstances,
}) => {
  const { scrollToAlertTime } = useSelector((state) => state.realtimeUserAlerts)
  useEffect(() => {
    groupedAlerts.forEach(({ name: alertName, created }) => {
      toggleHighlightInstances({ alertName, times: created })
    })
  }, [realtimeUserAlerts.transcription.length])

  // Since we use prediction on alerts, on occasion,
  // an alert will fire just before transcript
  // Adding some padding to these times to accommodate
  const alertIsBetween = (alertTime, startTime, endTime) => {
    return alertTime.isBetween(
      startTime.subtract(500, 'milliseconds'),
      endTime.add(500, 'milliseconds'),
      undefined,
      '[]'
    )
  }

  // For the first iteration, time will be a DateTime object
  // The following iterations it will be a float for the amount of seconds into a call we are
  // It can also go back to being a DateTime (possibly around side swaps?)
  const constructTimeByDateOrSeconds = (time) => {
    if (time instanceof Date) {
      return time.getTime()
    }
    if (realtimeUserAlerts.startTime) {
      return moment.utc(realtimeUserAlerts.startTime).add(time ? time.toFixed(0) : 0, 'seconds')
    }
    return new Date().setSeconds(time ? time.toFixed(0) : 0)
  }

  const convertToLocalMoment = (timeFromTranscript) => {
    const normalizedTime = constructTimeByDateOrSeconds(timeFromTranscript)
    return moment(normalizedTime).local()
  }

  let highlightedTimes = []
  highlightedInstances.forEach((alertObj) => {
    highlightedTimes = [...highlightedTimes, ...alertObj.times]
  })

  return realtimeUserAlerts?.transcription?.map(({ side, text, phrase, time, endTime }, index) => {
    const currentSpeaker = side === 0 ? 'Rep' : 'Consumer'
    const textToDisplay = phrase || text
    const newId = `side-${side}-index-${index}`

    const date = convertToLocalMoment(time)
    const endDate = convertToLocalMoment(endTime)
    const matchingInstances = []
    const highlight = highlightedTimes.some((alertTime) => {
      const isInTimeRange = alertIsBetween(alertTime, date, endDate)

      // Gathers alert names and instances, does not relate to actual highlighting
      if (isInTimeRange) {
        highlightedInstances.forEach((alertObject) => {
          alertObject.times.forEach((time, timeIndex) => {
            if (alertIsBetween(time, date, endDate)) {
              matchingInstances.push({
                alertName: alertObject.alertName,
                timeIndex: timeIndex + 1,
              })
            }
          })
        })
      }

      return isInTimeRange
    })
    const isScrollToRef = alertIsBetween(moment(scrollToAlertTime), date, endDate)

    return (
      <div
        className="alert-transcription-box-event"
        key={newId}
        ref={isScrollToRef ? scrollToInstanceRef : null}
        data-testid={newId}
      >
        <span className="alert-transcription-span">
          <div className="alert-transcription-box-speaker" data-testid={`${newId}-speaker`}>
            {currentSpeaker}
          </div>{' '}
          <div className="alert-transcription-box-time" data-testid={`${newId}-time`}>
            {date.format('LT')}
          </div>
        </span>
        <div
          className={`alert-transcription-box-text ${highlight && 'highlighted-instance'}`}
          data-testid={`${newId}-text`}
        >
          {textToDisplay}
        </div>
        {highlight &&
          matchingInstances.map(({ alertName, timeIndex }) => {
            return (
              <div
                key={`transcription-${alertName}-${timeIndex}`}
                className="transcription-alert-name"
                data-testid={`transcription-${alertName}-${timeIndex}`}
              >
                {`${alertName} · Instance ${timeIndex}`}
              </div>
            )
          })}
      </div>
    )
  })
}
