import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'

/* eslint-disable jsx-quotes */
const Tags = (props) => {
  const edit_users = useSelector((state) => state.currentUser.edit_users)
  return (
    <Draggable
      draggableId={props.tag.id.toString()}
      index={props.index}
      isDragDisabled={!edit_users}
    >
      {(provided) => (
        <div
          className="tag-container"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <span>
            <div className="ui label tags">
              <span>{props.tag.content}</span>
              <div>
                {edit_users && (
                  <i
                    data-testid="delete_tag_button"
                    onClick={() => props.deleteTag(props.tag)}
                    onMouseEnter={() => props.setHoveredTagId(props.tag.id)}
                    onMouseLeave={() => props.setHoveredTagId(null)}
                    style={{ cursor: 'pointer', position: 'relative', left: '8px' }}
                    className={`icon close ${
                      props.hoveredTagId === props.tag.id ? 'black' : 'grey'
                    }`}
                    role="presentation"
                    key={props.tag.id}
                  />
                )}
              </div>
            </div>
          </span>
        </div>
      )}
    </Draggable>
  )
}
export default Tags
