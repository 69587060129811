import React from 'react'
import { useSelector } from 'react-redux'
import { TascRecommendationsTable } from './TascRecommendationsTable'
import { filterPendingRecommendations } from './recommendations.helpers'

export const BeaconDisplay = () => {
  const { tascRecommendations } = useSelector((state) => state.recommendations)
  const filteredTascRecommendations = filterPendingRecommendations(tascRecommendations)

  return (
    <div className="beacon-display-and-entry-wrapper">
      <div className="recommendations-container" data-testid="agent-content-table">
        <header className="playbook-section-header" data-testid="playbook-section-header">
          <h2>Agent Content</h2>
        </header>
        <span className="item-count">{`${filteredTascRecommendations.length} items`}</span>
      </div>
      <TascRecommendationsTable />
    </div>
  )
}
