import React from 'react'
import { useDispatch } from 'react-redux'

import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { closeModal } from '@/reducers/ui/ui.redux'

export const ManageSalesforceButton = ({ handleUpdateSalesforceId, salesforceId }) => {
  const dispatch = useDispatch()

  return (
    <div style={{ margin: '.5rem' }}>
      <ButtonAndFormModal
        buttonLabel="Manage Salesforce ID"
        modalTitle="Manage Salesforce ID"
        modalId="manage-salesforce-id"
        modalProps={{ size: 'tiny' }}
        buttonProps={{ primary: true }}
        form={
          <AbstractForm
            schema={[
              {
                name: 'salesforce_id',
                type: 'text',
                label: 'Salesforce Id',
                placeholder: 'Enter a Salesforce ID',
              },
              {
                name: 'confirm_checkbox',
                type: 'checkbox',
                label: 'I want to make changes',
                required: true,
              },
            ]}
            onSubmit={handleUpdateSalesforceId}
            existingValues={{ salesforce_id: salesforceId || '' }}
            buttonLabel="Save"
            onClose={() => dispatch(closeModal())}
            isModal
          />
        }
      />
    </div>
  )
}
