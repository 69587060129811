import produce from 'immer'

import { GPT_DASHBOARD_TYPE } from '@/views/Insights/widgets/gptWidgets'

// Action Types
export const SET_USER_DASHBOARD_SETTINGS = 'userSettings/setDashboardSettings'
export const SET_WIDGET = 'userSettings/setWidget'
export const ADD_CATEGORY = 'userSettings/addCategory'
export const SET_LOADING = 'userSettings/setLoading'
export const SET_LOADING_CATEGORIES = 'userSettings/setLoadingCategories'

// Action Creators
export const setUserDashboardSettings = (payload) => ({ type: SET_USER_DASHBOARD_SETTINGS, payload }) // prettier-ignore
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setLoadingCategories = (payload) => ({ type: SET_LOADING_CATEGORIES, payload })
export const setWidget = (payload) => ({ type: SET_WIDGET, payload })
export const addCategory = (payload) => ({ type: ADD_CATEGORY, payload })

// State
export const initialState = {
  dashboardSettings: {
    [GPT_DASHBOARD_TYPE]: [],
  },
  widgets: {
    objectives: [],
    categories: {},
  },
  loading: {
    dashboardSettings: false,
    objectives: false,
    categories: {},
  },
}

// Reducer
export default function userSettingsReducer(state = initialState, action) {
  return produce(state, (newState) => {
    const { payload } = action

    switch (action.type) {
      case SET_USER_DASHBOARD_SETTINGS:
        newState.dashboardSettings[action.payload.dashboardType] = payload.data
        break
      case SET_WIDGET:
        newState.widgets = { ...state.widgets, ...payload }
        break
      case ADD_CATEGORY:
        newState.widgets.categories = { ...state.widgets.categories, ...payload }
        break
      case SET_LOADING:
        newState.loading = { ...state.loading, ...payload }
        break
      case SET_LOADING_CATEGORIES:
        newState.loading.categories = { ...state.loading.categories, ...payload }
        break
      default:
        break
    }
  })
}
