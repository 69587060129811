import React from 'react'

import Widget from '../../../components/widget/Widget'
import { ItemsByDateWidget } from '../widgets/ItemsByDateWidget'
import { ItemsByUserWidget } from '../widgets/ItemsByUserWidget'
import { ItemUsageWidget } from '../widgets/ItemUsageWidget'
import { DynamicPromptWinRateAnalysisWidget } from '../widgets/DynamicPromptWinRateAnalysisWidget'
import { ItemsByCallWidget } from '../widgets/ItemsByCallWidget'
import { CallsViewFilter } from '../widgets/CallsViewFilter'
import { CountByDateWidget } from '../widgets/CountByDateWidget'
import { CountByDateAndUserWidget } from '../widgets/CountByDateAndUserWidget'

export const DynamicPromptSection = () => {
  return (
    <div data-testid="reports-dynamic-prompt">
      <Widget
        widgetId="usage-responseCountByDate"
        label="Response Usage by Date"
        subtitle="The Count of Responses Used from Dynamic Prompts"
        showViewToggle
        showCsvDownload
        showGroupBy
      >
        <CountByDateWidget countedObj="response" />
      </Widget>
      <Widget
        widgetId="usage-responseCountByDateAndUser"
        label="Response Usage by Date and User"
        subtitle="The Count of Responses Used per User from Dynamic Prompts"
        showGroupBy
        showViewToggle
        showCsvDownload
        showIgnoreWeekends
      >
        <CountByDateAndUserWidget />
      </Widget>
      <Widget
        widgetId="dynamic-prompt-frequency"
        label="Dynamic Prompt Frequency"
        subtitle="The count of each Dynamic Prompt frequency across selected agents"
        subtitlePercent="Average Dynamic Prompt frequency across selected agents. Usage is calculated based on if the item was shown or in the specific playbook the agent was using at the time"
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ItemUsageWidget section="deck" />
      </Widget>
      <Widget
        widgetId="dynamic-prompt-frequency-by-date"
        label="Dynamic Prompt Frequency by Date"
        subtitle="Time series of the count of each Dynamic Prompt frequency across selected agents"
        subtitlePercent="Time series of average Dynamic Prompt frequency across selected agents. Frequency is calculated based on if the item was shown or in the specific playbook the agent was using at the time"
        showGroupBy
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ItemsByDateWidget section="deck" />
      </Widget>
      <Widget
        widgetId="dynamic-prompt-frequency-by-user"
        label="Dynamic Prompt Frequency by User"
        subtitle="The count of each Dynamic Prompt for each selected agents"
        subtitlePercent="Average Dynamic Prompt frequency for each selected agents. Usage is calculated based on if the item was shown or in the specific playbook the agent was using at the time"
        showPercent
        showViewToggle
        showCsvDownload
        percentViewDefault={false}
      >
        <ItemsByUserWidget section="deck" />
      </Widget>
      <Widget
        widgetId="dynamic-prompt-wins"
        label="Dynamic Prompt Wins"
        subtitle="The count of won calls of each Dynamic Prompt across selected agents"
        subtitlePercent="Average win rate of each Dynamic Prompt across selected agents"
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ItemUsageWidget section="deck" isWin />
      </Widget>
      <Widget
        widgetId="dynamic-prompt-win-rate-analysis"
        label="Dynamic Prompt Win Rate Analysis"
        subtitle="Table of each Dynamic Prompt, their associated Responses, and their count of uses and win rates"
        tableOnly
        showCsvDownload
      >
        <DynamicPromptWinRateAnalysisWidget />
      </Widget>
      <Widget
        widgetId="dynamic-prompt-frequency-by-call"
        label="Dynamic Prompt Frequency By Call"
        subtitle="Matrix table of calls, where each row represents a call and each column is a Dynamic Prompt, with colored cells indicating usage"
        customControls={<CallsViewFilter />}
      >
        <ItemsByCallWidget section="deck" />
      </Widget>
    </div>
  )
}
