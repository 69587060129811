import React, { useState } from 'react'
import { Dropdown, Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, kebabCase, get, truncate } from 'lodash'
import { IconCheck, IconX, IconChevronDown, IconChevronRight } from '@tabler/icons-react'
import * as Yup from 'yup'

import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { setTemporaryCriteria, editScorecardConfig } from '@/reducers/qa-copilot/qa-copilot.redux'
import { EditButton } from '@/components/buttons/EditButton'
import { openModal } from '@/reducers/ui/ui.redux'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'

import { setTemporaryPrompt } from '@/reducers/prompts/prompts.redux'
import { addTemporaryPromptHelper } from '@/reducers/prompts/prompts.helpers'
import { QACopilotSectionSettingsModal } from '../modals/QACopilotSectionSettingsModal'
import { QACopilotDeleteSectionModal } from '../modals/QACopilotDeleteSectionModal'
import { addTemporaryCriteriaHelper, updateSectionNameHelper } from '../QACopilot.helpers'

export const QACopilotScorecardSection = ({ section, collapsed, setCollapsed }) => {
  const dispatch = useDispatch()
  const [editingSectionName, setEditingSectionName] = useState(false)
  const { temporaryCriteria, scorecardConfig } = useSelector((state) => state.qaCopilot)
  const deleteModalId = `qaCopilot/deleteSection-${section.ssid}`
  const sectionSettingsModalId = `qaCopilot/sectionSettings-${section.ssid}`
  const allSectionsAreEmpty = !scorecardConfig.sections.some(
    (section) => !isEmpty(get(section, 'measures[0].criteria'))
  )
  const showActions = !editingSectionName && !temporaryCriteria && !allSectionsAreEmpty

  const handleOpenDeleteModal = () => {
    dispatch(openModal(deleteModalId))
  }

  const handleOpenSectionSettingsModal = () => {
    dispatch(openModal(sectionSettingsModalId))
  }

  const handleAddCriteria = () => {
    const tempCriteria = addTemporaryCriteriaHelper(section)
    dispatch(setTemporaryCriteria(tempCriteria))
    dispatch(setTemporaryPrompt(addTemporaryPromptHelper(tempCriteria.scid)))
  }

  const handleUpdateSectionName = (values) => {
    dispatch(
      editScorecardConfig(updateSectionNameHelper(scorecardConfig, { ...section, ...values }))
    )
    setEditingSectionName(false)
  }

  return (
    <>
      <section className="qa-copilot-scorecard-section" id={kebabCase(section.name)}>
        <div className="small-gap vertical-center">
          <Button
            icon
            basic
            compact
            className="svg-button"
            onClick={setCollapsed}
            style={{ marginRight: 0 }}
          >
            {collapsed ? <IconChevronRight /> : <IconChevronDown />}
          </Button>
          <div className="small-gap vertical-center">
            {!editingSectionName ? (
              <>
                <ConditionalTooltip
                  condition={section.name?.length > 60}
                  content={section.name}
                  tooltipProps={{ position: 'bottom left' }}
                >
                  <h2>{truncate(section.name, { length: 60 })}</h2>
                </ConditionalTooltip>
                <EditButton onEdit={() => setEditingSectionName(!editingSectionName)} />
              </>
            ) : (
              <AbstractForm
                horizontal
                noLabel
                onSubmit={handleUpdateSectionName}
                existingValues={{ name: section.name }}
                schema={[
                  {
                    name: 'name',
                    type: 'text',
                    required: true,
                    autoFocus: true,
                    autoComplete: 'off',
                    customValidators: [
                      Yup.string()
                        .required('QA Scorecard section name is required')
                        .max(500, 'Must be less than five hundred characters'),
                    ],
                  },
                ]}
                buttonProps={{ className: 'svg-button' }}
                closeButtonProps={{ className: 'svg-button' }}
                buttonLabel={<IconCheck />}
                closeButtonLabel={<IconX />}
                onClose={() => setEditingSectionName(false)}
              />
            )}
          </div>
        </div>
        {showActions && (
          <div className="flex vertical-center gap">
            <Button
              compact
              basic
              size="small"
              className="no-wrap"
              onClick={handleAddCriteria}
              disabled={!!temporaryCriteria}
            >
              <span>Add Criterion</span>
            </Button>
            <Button
              onClick={handleOpenSectionSettingsModal}
              compact
              basic
              size="small"
              className="no-wrap"
              data-testid={kebabCase(`section-settings-${section.name}`)}
            >
              <span>Section Settings</span>
            </Button>
            <Dropdown
              item
              direction="right"
              icon="ellipsis vertical"
              button
              className="icon minimal-button hidden"
            >
              <Dropdown.Menu>
                <Dropdown.Item className="danger" onClick={handleOpenDeleteModal}>
                  Delete Section
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </section>

      <QACopilotDeleteSectionModal section={section} />
      <QACopilotSectionSettingsModal section={section} />
    </>
  )
}
