/* eslint-disable react/no-danger */
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'

import { stickyNoteValidationSchema, stickyNoteInitialValues } from './sticky-note.schema'
import { StickyNoteForm } from './StickyNoteForm'
import { PlaybookPreview } from '../../components/PlaybookPreview'
import { PlaybookEditor } from '../../components/PlaybookEditor'
import { accessorSectionMap } from '../../playbook.helpers'

export const StickyNoteEditor = () => {
  const { entryId, section } = useParams()
  const { playbook } = useSelector((state) => state.playbook)
  const accessor = accessorSectionMap[section]
  const isCreate = entryId === 'create'
  const entry = playbook.body[accessor].entries[entryId]
  const initialValues = isCreate ? stickyNoteInitialValues : entry

  return (
    <PlaybookEditor entry={entry}>
      {({ handleUpdate, handleCancel, readOnly }) => (
        <Formik
          initialValues={initialValues}
          validationSchema={stickyNoteValidationSchema}
          onSubmit={handleUpdate}
          enableReinitialize
        >
          {({ values, handleSubmit, errors, ...formikProps }) => (
            <>
              <StickyNoteForm
                readOnly={readOnly}
                errors={errors}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                {...formikProps}
              />
              <PlaybookPreview values={values} section={section}>
                <div dangerouslySetInnerHTML={{ __html: values.display.html }} />
              </PlaybookPreview>
            </>
          )}
        </Formik>
      )}
    </PlaybookEditor>
  )
}
