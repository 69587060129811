import React from 'react'
import { Icon } from 'semantic-ui-react'

export default class MultiStepForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeStep: 0,
    }

    this.slidePanel = (nextStep, stepsLength) =>
      this.setState(() => ({
        activeStep: ((nextStep % stepsLength) + stepsLength) % stepsLength,
      }))

    this.nextPanel = () => this.slidePanel(this.state.activeStep + 1, this.props.steps.length)
    this.prevPanel = () => this.slidePanel(this.state.activeStep - 1, this.props.steps.length)
  }

  render() {
    const { modalClose, steps, navigatable = false } = this.props
    const { activeStep } = this.state

    const currentSlide = React.cloneElement(steps[activeStep], {
      modalClose,
      nextPanel: this.nextPanel,
    })

    return (
      <div data-testid="multi-step-form">
        <div className="ui grid">
          <div
            className="left floated one wide column"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            {activeStep !== 0 && (
              <Icon
                name="chevron left"
                size="huge"
                onClick={this.prevPanel}
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
          <div className="fourteen wide column">{currentSlide}</div>
          <div
            className="right floated right aligned one wide column"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            {activeStep !== steps.length - 1 && navigatable && (
              <Icon
                name="chevron right"
                size="huge"
                onClick={this.nextPanel}
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}
