import React, { useEffect, useMemo } from 'react'
import { isEqual, toString, isNull, truncate } from 'lodash'
import { useParams, useHistory, Prompt, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Loader } from 'semantic-ui-react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { Breadcrumbs } from '@/components/forms/Breadcrumbs/Breadcrumbs'
import {
  fetchPlaybook,
  getScorecardsAssociatedToPlaybook,
} from '@/reducers/playbooks/playbook.actions'
import { fetchTascRecommendations } from '@/reducers/recommendations/recommendations.actions'
import { setRecommendationsCid } from '@/reducers/recommendations/recommendations.redux'
import { ErrorMessage } from '@/components/forms/ErrorMessage'

import { playbookTitlesSectionMap, sections } from './playbook.helpers'
import PlaybookTabs from './components/PlaybookTabs'
import { PlaybookHeader } from './components/PlaybookHeader'
import { PlaybookScorecardWarning } from './components/PlaybookScorecardWarning'
import { BeaconDisplay } from './modules/beacon/BeaconDisplay'
import { CategoryDisplay } from './modules/categories/CategoryDisplay'
import { CategoryEntryEditor } from './modules/categories/CategoryEntryEditor'
import { ChecklistTabs } from './modules/checklist/ChecklistTabs'
import { NotificationsEditor } from './modules/notifications/NotificationsEditor'
import { StickyNoteEditor } from './modules/sticky-note/StickyNoteEditor'
import { PlaybookEntryDisplay } from './components/PlaybookEntryDisplay'
import { PlaybookEntryDisplayDraggable } from './components/PlaybookEntryDisplayDraggable'
import { PlaybookSubmitButton } from './components/PlaybookSubmitButton'
import { PlaybookVersionSelector } from './components/PlaybookVersionSelector'

import './Playbooks.scss'

export const sectionData = [
  {
    route: sections.CHECKLIST,
    components: { display: PlaybookEntryDisplayDraggable, editor: ChecklistTabs },
  },
  {
    route: sections.DYNAMIC_PROMPT,
    components: { display: CategoryDisplay, editor: CategoryEntryEditor },
  },
  {
    route: sections.NOTIFICATION,
    components: { display: PlaybookEntryDisplay, editor: NotificationsEditor },
  },
  {
    route: sections.STICKY_NOTE,
    components: { display: PlaybookEntryDisplay, editor: StickyNoteEditor },
  },
  {
    route: sections.POSTCALL,
    components: { display: CategoryDisplay, editor: CategoryEntryEditor },
  },
  {
    route: sections.BEACON,
    components: { display: BeaconDisplay },
  },
]

export function renderSection(section, showEditor, flags) {
  const sectionType = showEditor ? 'editor' : 'display'
  const routeFound = sectionData.find((data) => data.route === section)

  if (routeFound) {
    const Component = routeFound.components[sectionType]

    return <Component flags={flags} />
  }

  return <div />
}

const PlaybookEditorPage = ({ flags }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id, section, entryId } = useParams()
  const {
    loading,
    playbook,
    playbook: {
      id: playbookId,
      cid: playbookCid,
      organization_id: playbookOrgId,
      current: isLatestPlaybookVersion,
      active,
      mostRecentId,
    },
    validationError,
    dirty,
  } = useSelector((state) => state.playbook)
  const { organizationid: organizationId, edit_config } = useSelector((state) => state.currentUser)
  const { recommendationsCid } = useSelector((state) => state.recommendations)
  const { scorecardAssociations } = useSelector((state) => state.scorecards.data)

  // If user has landed on the old playbook editor URL, redirect to the new
  if (!section) {
    return <Redirect to={`/playbooks/${id}/checklist`} />
  }

  // Individual drill down detail editing page
  // Navigation and title is hidden, and editors are displayed instead of lists of entries
  // Beacon only has a viewer, so isEditorDetailPage will be false anytime the beacon tab is selected
  const isEditorDetailPage = !!entryId && !isEqual(section, 'beacon')
  const isTabPage = !!entryId && isEqual(section, 'checklist')
  const isCreate = entryId === 'create'
  const sectionLink = `/playbooks/${id}/${section}`
  const backlinks = isEditorDetailPage
    ? [
        { label: 'Playbooks', link: '/playbooks' },
        { label: truncate(playbook.name, 40), link: sectionLink },
      ]
    : [{ label: 'Playbooks', link: '/playbooks' }]
  const currentLink = isEditorDetailPage
    ? `${isCreate ? 'Add New' : 'Edit'} ${playbookTitlesSectionMap[section]}`
    : truncate(playbook.name, { length: 40 })
  const readOnly = !isLatestPlaybookVersion || !edit_config

  const getNumberOfBreakingChangesScorecards = (scorecardAssociations) => {
    let numOfBreakingScorecards = 0
    scorecardAssociations?.forEach((scorecard) => {
      if (scorecard.criteria_not_in_playbook.length > 0) {
        numOfBreakingScorecards += 1
      }
    })
    return numOfBreakingScorecards
  }

  const numberOfBreakingScorecards = useMemo(
    () => getNumberOfBreakingChangesScorecards(scorecardAssociations),
    [scorecardAssociations]
  )

  const shouldShowScorecardWarning = numberOfBreakingScorecards > 0 && !active

  useEffect(() => {
    // Fetch new playbook and scorecard details if URL changes
    if (toString(id) !== toString(playbookId)) {
      dispatch(fetchPlaybook(id, organizationId))
      dispatch(getScorecardsAssociatedToPlaybook(id))
    }
  }, [id, playbookId, organizationId])

  // Fetch recommendations on load and when cid changes
  useEffect(() => {
    const shouldFetchRecommendations =
      !isNull(playbookOrgId) && !isNull(playbookCid) && !isEqual(playbookCid, recommendationsCid)

    if (shouldFetchRecommendations) {
      dispatch(setRecommendationsCid(playbookCid))
      dispatch(fetchTascRecommendations())
    }
  }, [playbookCid])

  // Show a message if the user tries to close the window
  useEffect(() => {
    if (dirty) {
      window.onbeforeunload = () => {
        return true
      }
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [dirty])

  return (
    <>
      <Prompt
        when={dirty || (isLatestPlaybookVersion && !dirty && !active)}
        message={(nextLocation) => {
          if (nextLocation.pathname.startsWith('/playbooks/')) {
            return true
          }
          return `You have ${
            dirty ? 'unsaved' : 'unpublished'
          } changes. Are you sure you want to leave?`
        }}
      />

      {loading.playbook ? (
        <Loader active data-testid="loading" />
      ) : (
        <>
          <header
            className="page-header playbook-page-header"
            data-testid={`playbook-editor-page-${section}`}
          >
            <Breadcrumbs backLinks={backlinks} currentLink={currentLink} />
            <div>
              {!isEditorDetailPage && (
                <div className="playbook-page-header__actions">
                  <PlaybookVersionSelector />
                  <PlaybookSubmitButton numberOfBreakingScorecards={numberOfBreakingScorecards} />
                </div>
              )}
              {dirty && (
                <ErrorMessage
                  style={{ justifyContent: 'flex-end', marginTop: '1rem' }}
                  content="You have unsaved changes!"
                />
              )}
            </div>
          </header>

          {!isEditorDetailPage && (
            <>
              {shouldShowScorecardWarning && (
                <PlaybookScorecardWarning numberOfBreakingScorecards={numberOfBreakingScorecards} />
              )}
              {!isLatestPlaybookVersion && (
                <div className="playbook-switch">
                  <p>This playbook version is out of date.</p>
                  <Button
                    primary
                    onClick={() => {
                      history.push(`/playbooks/${mostRecentId}/checklist`)
                    }}
                  >
                    Go to Active Playbook Version
                  </Button>
                </div>
              )}

              <PlaybookHeader playbook={playbook} readOnly={readOnly} />
              <PlaybookTabs
                playbookId={id}
                section={section}
                validationError={validationError}
                isLatestPlaybookVersion={isLatestPlaybookVersion}
              />
            </>
          )}

          <div className="playbook-container">
            {isEditorDetailPage ? (
              <>
                {isTabPage ? (
                  renderSection(section, true, flags)
                ) : (
                  <div className="playbook-container__editor">
                    {renderSection(section, true, flags)}
                  </div>
                )}
              </>
            ) : (
              <div className="playbook-container__display">
                {renderSection(section, false, flags)}
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default withLDConsumer()(PlaybookEditorPage)
