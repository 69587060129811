import { toast } from 'react-toastify'
import moment from 'moment'
import { fetchingAPI, apiService } from '@/api'
import queryString from 'query-string'

import { setLoading, setData, addItems } from './activityLog.redux'
import { filterDuplicateObjectsByProperty } from '../helpers'

export const fetchActivityLogUsers =
  ({ alertedUserId, organizationId = null }) =>
  async (dispatch) => {
    dispatch(setLoading({ activityLogUsers: true }))
    try {
      let lastUpdate = new Date()
      const users = await fetchingAPI(
        `${apiService.web}/api/realtime_coaching/activity_log/users`,
        'POST',
        dispatch,
        JSON.stringify({ user_id: alertedUserId, organization_id: organizationId })
      )
      users.sort((firstUser, secondUser) => {
        return new Date(secondUser.last_call_ended_at) - new Date(firstUser.last_call_ended_at)
      })
      users.forEach((agent) => {
        dispatch(setData({ name: 'activityLogUsers', value: agent, key: agent.user_id, add: true }))

        // ensure next query window is as early as possible without picking up duplicate calls
        if (agent.last_call_ended_at > lastUpdate) lastUpdate = agent.last_call_ended_at
      })
    } catch (err) {
      toast.error('Failed to fetch activity log users')
    } finally {
      dispatch(setLoading({ activityLogUsers: false }))
    }
  }

export const fetchAgentCalls =
  ({ agentId, timeAfterFilter = null, managerId = null }) =>
  async (dispatch, getState) => {
    dispatch(setLoading({ agentCalls: true }))
    const queryParamsObject = {
      manager_id: managerId,
    }

    if (timeAfterFilter) {
      queryParamsObject.timestamped_after = timeAfterFilter.toISOString()
    }

    const queryParams = queryString.stringify(queryParamsObject)

    try {
      const state = getState()
      let lastUpdate = new Date()
      const { calls, total_count } = await fetchingAPI(
        `${apiService.web}/api/realtime_coaching/activity_log/calls/${agentId}?${queryParams}`,
        'GET',
        dispatch
      )

      /* Filtering out repeat callIDs to fix a hard-to-reproduce
          bug where duplicate calls were being displayed */
      const existingCalls = state.activityLog.data.agentCalls[agentId]?.calls || []
      const filteredCalls = filterDuplicateObjectsByProperty(calls, existingCalls, 'id')

      if (filteredCalls?.length) {
        filteredCalls.forEach((call) => {
          call.alerts.forEach((alert) => {
            alert.time_seconds = (moment(alert.created_at) - moment(call.call_start_time)) / 1000
          })

          if (call.call_end_time > lastUpdate) {
            lastUpdate = call.call_end_time
          }
        })

        dispatch(
          addItems({
            path: ['agentCalls', agentId, 'calls'],
            items: filteredCalls,
            updateTimestamp: lastUpdate,
          })
        )
      }

      // add remaining calls (calls not sent) to totalCount
      if (total_count > filteredCalls.length) {
        const remaining = total_count - filteredCalls.length
        dispatch(
          addItems({
            path: ['agentCalls', agentId, 'calls'],
            items: [],
            incrementCount: remaining,
          })
        )
      }
    } catch (err) {
      toast.error('Failed to fetch calls')
    } finally {
      dispatch(setLoading({ agentCalls: false }))
    }
  }
