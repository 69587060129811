import React from 'react'
import { Button } from 'semantic-ui-react'
import { capitalize } from 'lodash'

import { Pill } from '@/components/pills/Pill'

import { calculateGrade } from './helpers'

export const QAScore = ({ scorecard, handleEditScore }) => {
  const { possible_score: possibleScore, aggregate_score: aggregateScore } = scorecard
  const grade = calculateGrade(scorecard)
  const hasBeenScored = scorecard.scored_status === 'scored'

  const roundTwoDecimals = (num) => {
    return Math.round(num * 100) / 100
  }

  return (
    <>
      <header>
        <h2>
          <div className="flex-align-center medium-gap">
            <span>{scorecard.name}</span>
            <Pill
              small
              magical={scorecard.type === 'copilot'}
              success={scorecard.type === 'automated'}
            >
              {capitalize(scorecard.type)}
            </Pill>
            {scorecard.auto_failed && (
              <Pill small critical>
                Auto-fail
              </Pill>
            )}
          </div>
          <Button
            secondary
            className="no-wrap"
            onClick={() => handleEditScore(scorecard)}
            data-testid={!hasBeenScored ? 'override-score-button' : 'view-score-details-button'}
          >
            {!hasBeenScored ? 'Edit Score' : 'View Score'}
          </Button>
        </h2>
      </header>
      {scorecard.section_scores?.map((sectionScore) => {
        const { possible_score: sectionPossibleScore, aggregate_score: sectionAggregateScore } =
          sectionScore
        return (
          <section key={sectionScore.id}>
            <h2>{sectionScore.name}</h2>
            <div data-testid="qa-section-score">
              {hasBeenScored
                ? `${roundTwoDecimals(sectionAggregateScore)}/${sectionPossibleScore}`
                : '--'}
            </div>
          </section>
        )
      })}
      {hasBeenScored && (
        <footer>
          <div>Overall Score</div>
          <Pill large data-testid="qa-total-score" {...grade}>
            {roundTwoDecimals(aggregateScore)}/{possibleScore} points
          </Pill>
        </footer>
      )}
      {!hasBeenScored && <p className="text-center muted-text mt">This call is not yet scored.</p>}
    </>
  )
}
