import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Field } from 'formik'

import { TextField, Checkbox, RadioGroup, MultiSelect } from '@/components/forms/formik'
import { BaltoAdminOrganizationFilter } from '../../components/filters/BaltoAdminOrganizationFilter'
import './AlertWizard.css'

// First page
export const InfoPage = ({
  values,
  errors,
  tags,
  organizationUsers,
  readOnly = false,
  setFieldValue,
  validationFormName = 'infoPage',
}) => {
  const { data, loading } = useSelector((state) => state.realtimeCoaching)
  const { organizationid } = useSelector((state) => state.currentUser)

  const orgSelectorVisible =
    organizationid === 1 && !readOnly && values.displayedForm !== 'editForm'
  const managerSelectorVisible = !readOnly && values.displayedForm !== 'editForm'

  const clearSelections = () => {
    setFieldValue('managers', [])
    setFieldValue('selectedUsers', [])
    setFieldValue('selectedTags', [])
  }

  useEffect(() => {
    setFieldValue('displayedForm', validationFormName)
  }, [])

  return (
    <div className="alert-wizard__info-page" data-testid="alert-wizard__info-page">
      {orgSelectorVisible && <BaltoAdminOrganizationFilter additionalOnChange={clearSelections} />}
      {managerSelectorVisible && (
        <Field name="selectedTags" label="Select Tags">
          {(formikProps) => (
            <MultiSelect
              required
              data-testid="managers-dropdown"
              name="managers"
              placeholder="Select managers"
              label="Assign Alert to Managers"
              condenseOptions={false}
              isDisabled={readOnly}
              onChange={(event) => {
                if (event?.target?.value) {
                  event.preventDefault()
                } else {
                  formikProps.form.setFieldValue('managers', event)
                }
              }}
              loading={loading.managers}
              options={data.managers}
              value={values?.managers}
              {...formikProps}
            />
          )}
        </Field>
      )}

      <Field
        required
        disabled={readOnly}
        errors={errors}
        name="name"
        label="Alert Name"
        data-testid="name-field"
        component={TextField}
        placeholder="Name your alert"
      />

      <Field
        required
        name="audible"
        label="Enable audible notifications"
        data-testid="audible-field"
        inline
        component={Checkbox}
        disabled={readOnly}
      />

      <Field
        name="active"
        label="Active"
        inline={false}
        toggle
        component={Checkbox}
        disabled={readOnly}
      />

      <div data-testid="alertType">
        <Field
          required
          label="Type of Alert"
          name="alertType"
          component={RadioGroup}
          options={[
            { value: 'positive', text: 'Positive' },
            { value: 'negative', text: 'Negative' },
            { value: 'informative', text: 'Informative' },
          ]}
          disabled={readOnly}
        />
      </div>

      <Field
        required
        name="alertsFor"
        label="Receive alerts for"
        data-testid="alertsFor"
        component={RadioGroup}
        options={[
          { value: 'agents', text: 'Specific agents' },
          { value: 'tags', text: 'Group of agents' },
        ]}
        disabled={readOnly}
      />

      {(values.alertsFor === 'agents' || !values.alertsFor) && (
        <Field name="selectedUsers" label="Select Agents">
          {(formikProps) => (
            <MultiSelect
              required
              data-testid="agentsDropdown"
              label="Select Agents"
              placeholder="Select agents"
              name="selectedUsers"
              condenseOptions={false}
              isDisabled={!values.alertsFor || readOnly}
              onChange={(event) => {
                if (event?.target?.value) {
                  event.preventDefault()
                } else {
                  formikProps.form.setFieldValue('selectedUsers', event)
                }
              }}
              options={organizationUsers}
              value={values?.selectedUsers || []}
              {...formikProps}
            />
          )}
        </Field>
      )}

      {values.alertsFor === 'tags' && (
        <Field name="selectedTags" label="Select Tags">
          {(formikProps) => (
            <MultiSelect
              required
              data-testid="tagsDropdown"
              name="selectedTags"
              placeholder="Select tags"
              label="Select Tags"
              condenseOptions={false}
              isDisabled={!values.alertsFor || readOnly}
              onChange={(event) => {
                if (event?.target?.value) {
                  event.preventDefault()
                } else {
                  formikProps.form.setFieldValue('selectedTags', event)
                }
              }}
              options={tags}
              value={values?.selectedTags || []}
              {...formikProps}
            />
          )}
        </Field>
      )}
    </div>
  )
}
