import * as Yup from 'yup'
import { isEmpty, toNumber } from 'lodash'

import { customStaticRanges } from '@/components/helpers'

export const buildSchema = (data, loading, filterFormOptions = []) => {
  const nameSchema = {
    name: 'name',
    type: 'text',
    label: 'Filter Name',
    required: true,
    placeholder: 'Name',
  }

  const defaultSchema = {
    name: 'isDefault',
    type: 'checkbox',
    label: 'Set as default filter',
  }

  const staticSchema = {
    type: 'header',
    label: 'Filter Options',
    name: 'filterPropertiesHeader',
    isStatic: true,
    as: 'h4',
  }

  const agentSchema = {
    name: 'agents',
    label: 'Agents',
    type: 'multiSelect',
    placeholder: 'Search Agents',
    options: data.agents,
    condenseOptions: false,
    loading: loading.agents,
    showFullOptions: true,
  }

  const tagSchema = {
    name: 'tags',
    label: 'Tags',
    options: data.tags,
    condenseOptions: false,
    placeholder: 'Select Tags',
    type: 'multiSelect',
    loading: loading.tags,
    showFullOptions: true,
  }

  const playbookSchema = {
    name: 'playbooks',
    label: 'Playbooks',
    options: data.playbooks,
    condenseOptions: false,
    placeholder: 'Select Playbook(s)',
    type: 'multiSelect',
    loading: loading.playbooks,
  }

  const minDurationSchema = {
    name: 'callDuration',
    label: 'Min. Call Duration',
    type: 'text',
    inputType: 'number',
    customValidators: [
      Yup.number().when('maxCallDuration', {
        is: (val) => toNumber(val) > 0,
        then: Yup.number()
          .label('Min. Call Duration')
          .positive()
          .lessThan(Yup.ref('maxCallDuration'), 'Min must be less than max'),
        otherwise: Yup.number().label('Min. Call Duration').positive(),
      }),
    ],
    cyclicDependency: true,
  }

  const maxDurationSchema = {
    name: 'maxCallDuration',
    label: 'Max. Call Duration',
    type: 'text',
    inputType: 'number',
    customValidators: [
      Yup.number().when('callDuration', {
        is: (val) => toNumber(val) > 0,
        then: Yup.number()
          .label('Max. Call Duration')
          .positive()
          .moreThan(Yup.ref('callDuration'), 'Max must be greater than min'),
        otherwise: Yup.number().label('Max. Call Duration').positive(),
      }),
    ],
    cyclicDependency: true,
  }

  const dateRangeSchema = {
    name: 'dateRange',
    label: 'Date Range',
    placeholder: 'Select Date Range',
    options: customStaticRanges.map((staticRange) => {
      return {
        value: staticRange.label.toLowerCase(),
        label: staticRange.label,
      }
    }),
    type: 'select',
  }

  const includeManagersSchema = {
    name: 'includeManagers',
    label: 'Include Managers',
    type: 'checkbox',
  }

  const deckStaticSchema = {
    type: 'header',
    label: 'Deck Filter Options',
    name: 'deckFilters',
    isStatic: true,
    as: 'h4',
  }

  const deckCategoriesSchema = {
    name: 'deckCategories',
    label: 'Deck Categories',
    options: data.deckCategories,
    type: 'multiSelect',
    loading: loading.deckCategories,
    condenseOptions: false,
  }

  const postcallStaticSchema = {
    type: 'header',
    label: 'Post Call Filter Options',
    name: 'postcallFilters',
    isStatic: true,
    as: 'h4',
  }

  const postcallCategoriesSchema = {
    name: 'postcallCategories',
    label: 'Post Call Categories',
    options: data.postcallCategories,
    type: 'multiSelect',
    loading: loading.postcallCategories,
    condenseOptions: false,
  }

  const allOptions = [
    nameSchema,
    defaultSchema,
    staticSchema,
    agentSchema,
    tagSchema,
    playbookSchema,
    minDurationSchema,
    maxDurationSchema,
    dateRangeSchema,
    includeManagersSchema,
    deckStaticSchema,
    deckCategoriesSchema,
    postcallStaticSchema,
    postcallCategoriesSchema,
  ]

  if (isEmpty(filterFormOptions)) {
    return allOptions
  }
  return allOptions.filter(({ name }) => filterFormOptions.includes(name))
}
