import React from 'react'
import classNames from 'classnames'

import { DragAndDropIcon } from '@/components/icons/DragAndDropIcon'

import './DragAndDrop.scss'

// HTML button elements don't work with react-beautiful-dnd so we use a div instead
export const DragAndDropButton = ({
  provided,
  snapshot,
  invisible,
  outer,
  compact,
  readOnly,
  isHidden,
}) => {
  return (
    <div
      className={classNames('ui button icon draggable-button basic', {
        'is-dragging': snapshot.isDragging,
        hidden: !outer,
        'hidden-outer': outer,
        'hidden-permanent': isHidden,
        disabled: readOnly,
        compact,
      })}
      {...provided.dragHandleProps}
    >
      <DragAndDropIcon
        className={classNames('draggable-icon', { 'visibility-hidden': invisible })}
      />
    </div>
  )
}
