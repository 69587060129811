import * as Yup from 'yup'

const isViewingForm = (formName) => (displayedForm) => formName === displayedForm
const isViewingInfoForm = isViewingForm('infoPage')
const isViewingPlaybookConfigForm = isViewingForm('playbookConfigPage')
const isViewingEditForm = isViewingForm('editForm')

export const genericSchema = {
  displayedForm: Yup.string(),
  active: Yup.boolean(),
  audible: Yup.boolean(),
  name: Yup.string().when('displayedForm', {
    is: (displayedForm) => isViewingInfoForm(displayedForm) || isViewingEditForm(displayedForm),
    then: Yup.string()
      .required('Name is required.')
      .max(100, 'Name cannot be longer than 100 characters.'),
  }),
  alertType: Yup.string().when('displayedForm', {
    is: (displayedForm) => isViewingInfoForm(displayedForm) || isViewingEditForm(displayedForm),
    then: Yup.string().required('Alert type is required.'),
  }),
  managers: Yup.array().when('displayedForm', {
    is: (displayedForm) => isViewingInfoForm(displayedForm),
    then: Yup.array().min(1).required('At least 1 manager is required'),
  }),
  alertsFor: Yup.string().when('displayedForm', {
    is: (displayedForm) => isViewingInfoForm(displayedForm) || isViewingEditForm(displayedForm),
    then: Yup.string().required('Alert for is required.'),
  }),
  selectedUsers: Yup.array().when(['alertsFor', 'displayedForm'], {
    is: (alertsFor, displayedForm) =>
      alertsFor === 'agents' &&
      (isViewingInfoForm(displayedForm) || isViewingEditForm(displayedForm)),
    then: Yup.array().min(1).required('Agents are required'),
  }),
  selectedTags: Yup.array().when(['alertsFor', 'displayedForm'], {
    is: (alertsFor, displayedForm) =>
      alertsFor === 'tags' &&
      (isViewingInfoForm(displayedForm) || isViewingEditForm(displayedForm)),
    then: Yup.array().min(1).required('Tags are required'),
  }),
  triggerItems: Yup.array().when('displayedForm', {
    is: (displayedForm) =>
      isViewingPlaybookConfigForm(displayedForm) || isViewingEditForm(displayedForm),
    then: Yup.array().min(1).required('Triggers are required'),
  }),
}
