import React from 'react'
import { Dropdown } from 'semantic-ui-react'

export const PlaybookEntryActions = ({
  isWinnable,
  isRetiredNotificationType,
  readOnly,
  handleEdit,
  handleWin,
  handleDelete,
  entry,
}) => (
  <div className="playbook-entry__actions">
    <Dropdown
      icon="ellipsis horizontal"
      button
      className="icon minimal-button hidden"
      disabled={readOnly}
      data-testid="kebab-menu-dropdown"
    >
      <Dropdown.Menu>
        {handleEdit && (
          <Dropdown.Item onClick={handleEdit} data-testid="edit-option">
            {readOnly || isRetiredNotificationType ? 'View item' : 'Edit item'}
          </Dropdown.Item>
        )}
        {isWinnable && (
          <Dropdown.Item
            data-testid="win-option"
            onClick={(event) => {
              event.stopPropagation()

              handleWin()
            }}
          >
            {entry.win ? 'Remove win marker' : 'Mark as win'}
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={handleDelete} data-testid="delete-option">
          Delete item
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </div>
)
