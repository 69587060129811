export const overviewTableColumns = {
  topAlertConfigs: [
    { accessor: 'rank', label: 'Rank', isSortable: false },
    { accessor: 'name', label: 'Alert', isSortable: false },
    { accessor: 'alert_type_count', label: 'Number of Alerts', isSortable: false },
    { accessor: 'call_count', label: 'Calls', isSortable: false },
  ],
  topAlertingAgentsNegative: [
    { accessor: 'rank', label: 'Rank', isSortable: false },
    { accessor: 'name', label: 'Agent', isSortable: false },
    { accessor: 'alert_type_count', label: 'Number of Alerts', isSortable: false },
    { accessor: 'call_count', label: 'Calls', isSortable: false },
  ],
  topAlertingAgentsPositive: [
    { accessor: 'rank', label: 'Rank', isSortable: false },
    { accessor: 'name', label: 'Agent', isSortable: false },
    { accessor: 'alert_type_count', label: 'Number of Alerts', isSortable: false },
    { accessor: 'call_count', label: 'Calls', isSortable: false },
  ],
  topAlertingAgentsInformative: [
    { accessor: 'rank', label: 'Rank', isSortable: false },
    { accessor: 'name', label: 'Agent', isSortable: false },
    { accessor: 'alert_type_count', label: 'Number of Alerts', isSortable: false },
    { accessor: 'call_count', label: 'Calls', isSortable: false },
  ],
  topChattingSupervisors: [
    { accessor: 'rank', label: 'Rank', isSortable: false },
    { accessor: 'supervisor', label: 'Supervisor', isSortable: false },
    { accessor: 'chats', label: 'Number of Chats', isSortable: false },
    { accessor: 'calls_with_chats', label: 'Calls w/ Chats', isSortable: false },
  ],
  topListenerSupervisors: [
    { accessor: 'rank', label: 'Rank', isSortable: false },
    { accessor: 'supervisor', label: 'Supervisor', isSortable: false },
    { accessor: 'listens', label: 'Number of Listens', isSortable: false },
    { accessor: 'calls_with_listens', label: 'Calls w/ Listens', isSortable: false },
  ],
}

export const playbookItemOptions = [
  {
    value: 'checklist',
    label: 'Checklist',
  },
  {
    value: 'deck',
    label: 'Deck',
  },
  {
    value: 'classified_postcall',
    label: 'Post Call',
  },
  {
    value: 'notifications',
    label: 'Notifications',
  },
  {
    value: 'checklist-timing',
    label: 'Checklist - Absence Alert',
  },
]
