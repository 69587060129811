import React, { createContext, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { WizardModal } from '@/components/layout/modals/WizardModal'
import { closeModal } from '@/reducers/ui/ui.redux'
import { AlertSettingsForm } from '@/views/RealtimeCoaching/Alerts/forms/AlertSettingsForm'
import { AlertAgentSelectForm } from '@/views/RealtimeCoaching/Alerts/forms/AlertAgentSelectForm'
import { TriggerSelectForm } from '@/views/RealtimeCoaching/Alerts/forms/TriggerSelectForm'
import { ReviewPageForm } from '@/views/RealtimeCoaching/Alerts/forms/ReviewPageForm'
import { alertFormInitialValues } from '@/views/RealtimeCoaching/Alerts/forms/validation/AlertValidation'
import { genericSchema } from '../forms/validation/OrgLevelAlertValidationSchema'

export const AnimateTriggerItem = createContext(true)
export const OrgLevelAlertWizard = ({ handleSubmit, ...props }) => {
  const dispatch = useDispatch()
  const [alertName, updateAlertName] = useState(null)
  const title = `Create Alert${alertName ? ` ${alertName}` : ''}`

  const shape = genericSchema
  const validationSchema = Yup.object().shape(shape, ['selectedAgents', 'selectedTags'])

  const pages = [
    (values, _goToPageNumber, _errors, setFieldValue) => (
      <AlertSettingsForm values={values} setFieldValue={setFieldValue} {...props} />
    ),
    (values, _goToPageNumber, _errors, setFieldValue) => (
      <AlertAgentSelectForm values={values} setFieldValue={setFieldValue} {...props} />
    ),
    (values, _goToPageNumber, _errors, setFieldValue) => (
      <TriggerSelectForm values={values} setFieldValue={setFieldValue} {...props} />
    ),
    (values, goToPageNumber) => (
      <ReviewPageForm values={values} goToPageNumber={goToPageNumber} {...props} />
    ),
  ]

  const onSubmit = async (values) => {
    handleSubmit(values)
    dispatch(closeModal())
  }
  // TODO: Like the validation we cleaned up there is probably a better way to do this,
  // and we could get rid of this prop completely
  const onPageChange = (values) => {
    // this is stored as an obj because of how WizardModal renders name
    updateAlertName(values.name)
  }
  const dirtyRef = useRef(null)

  const onClose = () => {
    if (dirtyRef.current.dirty) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to leave without saving?')) {
        dispatch(closeModal())
      }
    } else {
      dispatch(closeModal())
    }
  }

  return (
    <AnimateTriggerItem.Provider value data-testid="alert-wizard">
      <WizardModal
        pages={pages}
        scrollable
        alwaysEnablePreviousButton
        isLoading={props.isLoading}
        initialValues={alertFormInitialValues}
        validationSchema={validationSchema}
        title={title}
        onSave={onSubmit}
        onClose={onClose}
        onPageChange={onPageChange}
        innerRef={dirtyRef}
        size="small"
      />
    </AnimateTriggerItem.Provider>
  )
}
