import React, { useEffect, useState } from 'react'
import { UserInitial } from '@/components/UserInitial'
import { createPseudoRandomNumberFromUUID } from '@/views/Gamification/Challenges/helpers'

import './ChallengesPage.scss'

export const AgentTagTrigger = ({ challenge, onClick }) => {
  const [orderedGroups, setOrderedGroups] = useState({})

  useEffect(() => {
    if (challenge.groups) {
      const groupOrderMissing = challenge.groups.some((group) => group.groupOrder === null)
      if (groupOrderMissing) {
        const groupOrder = challenge.groups.map((group, groupIndex) => {
          const teamName = group.groupName ?? `Team ${groupIndex + 1}`
          return { groupId: group.groupId, groupName: teamName, groupOrder: groupIndex + 1 }
        })
        setOrderedGroups({
          challengeId: challenge.id,
          groups: groupOrder,
        })
      } else {
        setOrderedGroups({})
      }
    }
  }, [challenge])

  const participantText = (teamNumber, teamName) => {
    if (challenge.type === 'team vs team') {
      return (
        <div className="agent-tag-triggers">
          <div className="team-name">{teamName} </div>
          {teamNumber === 0 ? <span className="team-vs-team">vs</span> : ''}
        </div>
      )
    }
    return <div>Add Participants</div>
  }

  if (!challenge.groups) return <></>

  return challenge.groups.map((group, groupIndex) => {
    const teamName = group.groupName ?? `Team ${groupIndex + 1}`
    if (group.agents.length === 0) {
      return (
        <div key={group.groupId} style={{ width: '100%' }} data-testid="display-participants">
          <div
            onClick={() => onClick(group, orderedGroups)}
            className="add-participants"
            data-testid="add-participants"
          >
            {participantText(groupIndex, teamName)}
          </div>
        </div>
      )
    }

    return (
      <div key={group.groupId} className="agent-tag-triggers" data-testid="display-participants">
        <div>
          {group.agents.map((agent, index) => {
            if (index <= 2) {
              return (
                <div
                  key={agent.value}
                  className="agent-names"
                  onClick={() => onClick(group, orderedGroups)}
                >
                  <div data-testid="agent-initials" className="agent-initials">
                    <UserInitial
                      inline
                      userFullName={agent?.label}
                      index={createPseudoRandomNumberFromUUID(group.groupId, index)}
                    />
                  </div>
                  {index === 2 && group.agents.length > 3 && (
                    <span data-testid="agent-count" className="agent-count">
                      +{group.agents.length - 3}
                    </span>
                  )}
                </div>
              )
            }
            return null
          })}
        </div>
        {challenge.type === 'team vs team' && groupIndex === 0 && (
          <span className="team-vs-team">vs</span>
        )}
      </div>
    )
  })
}
