import React from 'react'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import { setScrollToAlertTime } from '@/reducers/realtime/alerts/realtimeUserAlerts.redux'
import classNames from 'classnames'

export const AlertSidebarDropdown = ({
  activelyListeningToUser,
  agentUsername,
  alertName,
  count,
  created,
  expanded,
  timeStamp,
  toggleExpand,
  type,
}) => {
  const dispatch = useDispatch()
  const { scrollToAlertTime } = useSelector((state) => state.realtimeUserAlerts)

  const topBorderColorTypes = {
    positive: 'var(--color-fill-success)',
    negative: 'var(--color-fill-critical)',
    informative: 'var(--color-fill-informative)',
  }
  const bottomBorderColorTypes = {
    positive: 'var(--color-border-success)',
    negative: 'var(--color-border-critical)',
    informative: 'var(--color-border-informative)',
  }
  const firstAlertTime = timeStamp[0]
  const lastAlertTime =
    timeStamp[timeStamp.length - 1] === firstAlertTime ? null : timeStamp[timeStamp.length - 1]
  const alertTimeString = lastAlertTime ? `${firstAlertTime} - ${lastAlertTime}` : firstAlertTime
  const alertInstanceClick = (createdTime) => {
    dispatch(setScrollToAlertTime(createdTime))
  }

  return count === 1 ? (
    <div
      data-testid="timestamp-range"
      className="timestamp-range"
      style={{ borderLeftColor: topBorderColorTypes[type] }}
    >
      {alertTimeString}
    </div>
  ) : expanded ? (
    <>
      <IconChevronUp
        data-testid={`chevron-up-icon-${agentUsername}-${alertName}`}
        className="chevron-icon"
        onClick={() => {
          toggleExpand()
        }}
      />
      <div
        style={{ borderLeftColor: bottomBorderColorTypes[type] }}
        className="alert-instance-items"
      >
        {timeStamp.map((time, index) => (
          <div
            className={classNames('alert-instance-item', {
              'alert-instance-item-selected': scrollToAlertTime?.isSame(created[index]),
              clickable: activelyListeningToUser,
              'no-hover': !activelyListeningToUser,
            })}
            data-testid={`alert-instance-item-${agentUsername}-${alertName}-${index}`}
            key={created[index].toISOString()}
            onClick={() => alertInstanceClick(created[index])}
          >
            Instance {index + 1} <span className="alert-instance-timestamp">{time}</span>
          </div>
        ))}
      </div>
    </>
  ) : (
    <>
      <IconChevronDown
        data-testid={`chevron-down-icon-${agentUsername}-${alertName}`}
        className="chevron-icon"
        onClick={() => {
          toggleExpand()
        }}
      />
      <div
        data-testid="timestamp-range"
        className="timestamp-range"
        style={{ borderLeftColor: topBorderColorTypes[type] }}
      >
        {alertTimeString}
      </div>
    </>
  )
}
