import React from 'react'

import { AbstractForm } from '@/components/forms/formik/AbstractForm'

const sideOptions = [
  { label: 'Me', value: 'me' },
  { label: 'Them', value: 'them' },
]

const inferenceTypeOptions = [
  { label: 'Keywords', value: 'keywords' },
  { label: 'Deep Learning Model v1', value: 'deep_learning_v1' },
  { label: 'Knowledge Distillation Model', value: 'knowledge_distillation' },
]

export const KeywordForm = ({ handleSubmit, existingValues, isCreate, handleClose }) => {
  return (
    <AbstractForm
      existingValues={existingValues}
      schema={[
        {
          name: 'name',
          label: 'Name',
          type: 'text',
          placeholder: 'e.g. Budget, Gatekeeper...',
          required: true,
        },
        { name: 'definition', label: 'Definition', type: 'text' },
        {
          name: 'inference_type',
          label: 'Inference Type',
          type: 'select',
          options: inferenceTypeOptions,
        },
        { name: 'side', label: 'Side', type: 'select', options: sideOptions },
        { name: 'side_restricted', label: 'Restrict Side', type: 'checkbox', inline: true },
        {
          name: 'phrases',
          label: 'Phrases',
          type: 'textArea',
          placeholder: 'Words or phrases you want trigger this, separated by commas.',
          required: true,
        },
        {
          name: 'examples',
          label: 'Examples',
          type: 'textArea',
          placeholder: 'Examples of keyword entry, separated by commas.',
        },
      ]}
      onSubmit={handleSubmit}
      buttonLabel={isCreate ? 'Create' : 'Update'}
      onClose={handleClose}
      isModal
    />
  )
}
