import React from 'react'
import { Button } from 'semantic-ui-react'
import { IconChartAreaLine } from '@tabler/icons-react'

import { IconDecoration } from '../IconDecoration'

import './DashboardWidgetSidebar.scss'

export const DashboardWidgetSidebar = ({
  title,
  description,
  handleSubmit,
  submitText = 'Track Insight',
  icon,
  index,
}) => {
  return (
    <div className="dashboard-widget__sidebar">
      <div>
        <header>
          <IconDecoration icon={icon || <IconChartAreaLine />} index={index} />
          <h3>{title}</h3>
          <p>{description}</p>
        </header>
        <footer>
          <Button color="black" onClick={handleSubmit}>
            {submitText}
          </Button>
        </footer>
      </div>
    </div>
  )
}
