import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { IconX } from '@tabler/icons-react'

import './modal.css'

export const FormModal = ({
  title,
  description,
  submitButtonLabel,
  closeButtonLabel,
  disableSubmitButton = false,
  onClose,
  onSave,
  children,
  show,
  modalClassName = '',
  size = 'large',
  isLoading = false,
  critical = false,
  ...otherProps
}) => {
  const handleSave = async () => {
    await onSave()
  }
  return (
    <Modal
      size={size}
      open={Boolean(show)}
      data-testid="modal-form-page"
      onClose={onClose}
      className={modalClassName}
      {...otherProps}
    >
      <Modal.Header className="form-modal__header-container">
        {title}
        <Button
          icon
          onClick={onClose}
          data-testid="form-modal-close-button"
          className="basic-modal__close-button"
        >
          <IconX />
        </Button>
      </Modal.Header>
      <Modal.Content>
        {description && <p>{description}</p>}
        {children}
      </Modal.Content>
      <Modal.Actions>
        <Button secondary data-testid="close_modal" onClick={() => onClose(false)}>
          {closeButtonLabel}
        </Button>
        <Button
          data-testid="save_modal"
          primary={!critical}
          color={critical ? 'red' : 'blue'}
          loading={isLoading}
          disabled={isLoading || disableSubmitButton}
          onClick={handleSave}
        >
          {submitButtonLabel}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
