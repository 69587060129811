import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconColumns3 } from '@tabler/icons-react'

import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'

import {
  fetchCustomColumns,
  upsertCustomColumns,
} from '@/reducers/customColumns/customColumns.actions'
import { formatCustomColumns } from '@/components/helpers/tableColumnHelpers'

import { EditColumnsForm } from './EditColumnsForm'

export const CustomColumns = ({ tableName, defaultColumns, buttonProps = {} }) => {
  const dispatch = useDispatch()
  const { customColumns, loading } = useSelector((state) => state.customColumns)
  const currentColumns = customColumns[tableName] || []
  const isLoading = !!loading[tableName]

  const formatFormColumns = () => formatCustomColumns(defaultColumns, currentColumns, false)

  useEffect(() => {
    dispatch(fetchCustomColumns(tableName))
  }, [tableName])

  return (
    <ButtonAndFormModal
      icon={<IconColumns3 />}
      buttonLabel="Manage Columns"
      modalTitle="Manage Columns"
      modalId={`${tableName}/editColumns`}
      modalProps={{ size: 'tiny' }}
      buttonProps={{ disabled: isLoading, loading: isLoading, secondary: true, ...buttonProps }}
      form={
        <EditColumnsForm
          allColumns={formatFormColumns()}
          handleSubmit={(values) => {
            dispatch(upsertCustomColumns(tableName, values))
          }}
        />
      }
    />
  )
}
