import React, { useEffect, useState } from 'react'
import moment from 'moment'

export const TimeSinceLastAlert = ({ latestAlertIsoTime }) => {
  const getTimeDiff = (latestTime) => {
    const now = moment()
    const diff = now.diff(moment(latestTime), 'minutes')
    return diff === 0 ? '< 1' : diff
  }

  const [minutesSinceLastAlert, setMinutesSinceLastAlert] = useState(
    getTimeDiff(latestAlertIsoTime)
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setMinutesSinceLastAlert(getTimeDiff(latestAlertIsoTime))
    }, 60000)
    return () => clearInterval(interval)
  }, [])

  const timeSinceLastAlertString = `${minutesSinceLastAlert} min${
    minutesSinceLastAlert === '< 1' || minutesSinceLastAlert === 1 ? '' : 's'
  } since last alert`

  return (
    <div data-testid="time-since-last-alert" className="time-since-last-alert">
      {timeSinceLastAlertString}
    </div>
  )
}
