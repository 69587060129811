import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'

import { BasicModal } from '@/components/layout/modals/BasicModal'
import { closeModal } from '@/reducers/ui/ui.redux'
import { editScorecardConfig } from '@/reducers/qa-copilot/qa-copilot.redux'
import ConfirmForm from '@/components/forms/ConfirmForm'

import { removeSectionHelper } from '../QACopilot.helpers'

export const QACopilotDeleteSectionModal = ({ section }) => {
  const dispatch = useDispatch()
  const { currentlyOpenModalId } = useSelector((state) => state.ui)
  const { scorecardConfig } = useSelector((state) => state.qaCopilot)
  const modalId = `qaCopilot/deleteSection-${section.ssid}`

  const handleCloseModal = () => {
    dispatch(closeModal())
  }

  const handleDelete = () => {
    dispatch(editScorecardConfig(removeSectionHelper(scorecardConfig, section)))
    toast.success('Section and criteria have been deleted')
  }

  if (isEmpty(scorecardConfig?.sections)) {
    return null
  }

  if (modalId !== currentlyOpenModalId) {
    return null
  }

  return (
    <BasicModal
      title={`Delete ${section.name}`}
      onClose={handleCloseModal}
      show={currentlyOpenModalId === modalId}
      size="tiny"
    >
      <ConfirmForm
        valueToSubmit={section.ssid}
        bodyText={`Are you sure you want to delete this section: ${section.name}? All criteria within this section will be deleted as well.`}
        danger
        primaryButtonText="Delete Section"
        handleSubmit={handleDelete}
        modalClose={handleCloseModal}
      />
    </BasicModal>
  )
}
