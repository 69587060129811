import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, FieldArray, Field } from 'formik'
import { truncate, toNumber, get, isEmpty, toArray, cloneDeep } from 'lodash'
import { Button } from 'semantic-ui-react'
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react'
import classNames from 'classnames'
import { toast } from 'react-toastify'

import { Pill } from '@/components/pills/Pill'
import { Drag, Drop, DragAndDrop } from '@/components/dragDrop'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { closeModal } from '@/reducers/ui/ui.redux'
import { NumberField } from '@/components/forms/formik'
import { reorder } from '@/utils/helpers'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import { editScorecardConfig } from '@/reducers/qa-copilot/qa-copilot.redux'

import {
  getTotalPoints,
  getCriteriaPointsInitialValues,
  criteriaPointsValidationSchema,
} from '../QACopilot.helpers'

export const QACopilotCriteriaPointsModal = () => {
  const dispatch = useDispatch()
  const { currentlyOpenModalId } = useSelector((state) => state.ui)
  const { scorecardConfig } = useSelector((state) => state.qaCopilot)
  const [collapsedSections, setCollapsedSections] = useState([])
  const initialCriteriaPointsValues = getCriteriaPointsInitialValues(scorecardConfig)
  const modalId = 'qaCopilot/criteriaPoints'

  const setCollapsed = (section) => {
    if (collapsedSections.includes(section.ssid)) {
      setCollapsedSections(collapsedSections.filter((ssid) => ssid !== section.ssid))
    } else {
      setCollapsedSections([...collapsedSections, section.ssid])
    }
  }

  const handleCloseModal = () => {
    dispatch(closeModal())
  }

  const handleDragEnd = (result, values, formikProps) => {
    const { source, destination, type } = result
    if (!source || !destination) return
    if (type !== 'droppable-criteria') return

    const sourceSectionId = source.droppableId
    const destinationSectionId = destination.droppableId

    if (sourceSectionId === destinationSectionId) {
      const originalCriteriaOrder = toArray(
        values.sections.find((section) => section.ssid === sourceSectionId)?.criteria
      )
      const updatedCriteriaOrder = reorder(originalCriteriaOrder, source.index, destination.index)
      const updatedCriteriaWithOrderIds = updatedCriteriaOrder.map((section, index) => ({
        ...section,
        order_id: index,
      }))
      const updatedValues = values.sections.map((section) =>
        section.ssid === sourceSectionId
          ? { ...section, criteria: updatedCriteriaWithOrderIds }
          : section
      )

      formikProps.setValues({ sections: updatedValues })
    } else {
      const sourceOrder = toArray(
        values.sections.find((section) => section.ssid === sourceSectionId)?.criteria
      )
      const destinationOrder = toArray(
        values.sections.find((section) => section.ssid === destinationSectionId)?.criteria
      )

      const [removed] = sourceOrder.splice(source.index, 1)
      destinationOrder.splice(destination.index, 0, removed)

      destinationOrder[removed] = sourceOrder[removed]
      delete sourceOrder[removed]

      const updatedSourceWithOrderIds = sourceOrder.map((criteria, index) => ({
        ...criteria,
        order_id: index,
      }))
      const updatedDestinationWithOrderIds = destinationOrder.map((criteria, index) => ({
        ...criteria,
        order_id: index,
      }))

      const updatedValues = values.sections.map((section) =>
        section.ssid === sourceSectionId
          ? { ...section, criteria: updatedSourceWithOrderIds }
          : section.ssid === destinationSectionId
            ? { ...section, criteria: updatedDestinationWithOrderIds }
            : section
      )

      formikProps.setValues({ sections: updatedValues })
    }
  }

  const handleUpdateCriteriaPoints = (values) => {
    const updatedSections = values.sections
    const updatedScorecardConfig = cloneDeep(scorecardConfig)

    updatedScorecardConfig.sections.forEach((section) => {
      const updatedSection = updatedSections.find(
        (updatedSection) => updatedSection.ssid === section.ssid
      )

      if (isEmpty(section?.measures)) {
        return
      }

      section.measures[0].criteria = updatedSection.criteria
    })

    dispatch(editScorecardConfig(updatedScorecardConfig))
    dispatch(closeModal())
    toast.success('Scorecard criteria has been updated')
  }

  if (currentlyOpenModalId !== modalId) {
    return null
  }

  if (isEmpty(scorecardConfig?.sections)) {
    return null
  }

  return (
    <BasicModal
      className="qa-copilot-criteria-points-modal"
      title="Scorecard Points"
      onClose={handleCloseModal}
      show={currentlyOpenModalId === modalId}
      size="small"
      scrolling
    >
      <Formik
        validationSchema={criteriaPointsValidationSchema}
        initialValues={initialCriteriaPointsValues}
        onSubmit={handleUpdateCriteriaPoints}
      >
        {({ values, errors, handleSubmit, ...formikProps }) => {
          const totalPoints = getTotalPoints(values)

          return (
            <>
              <DragAndDrop onDragEnd={(results) => handleDragEnd(results, values, formikProps)}>
                <Drop droppableId="droppable-section" type="droppable-section" condensed>
                  {values.sections.map((section, index) => {
                    const isCollapsed = collapsedSections.some((ssid) => ssid === section.ssid)
                    const aggregatePoints = section.criteria.reduce(
                      (acc, criteria) => acc + toNumber(criteria.weight),
                      0
                    )

                    return (
                      <Drag
                        key={section.ssid}
                        draggableId={section.ssid}
                        index={index}
                        alwaysShowIcon
                        isModal
                        isHidden
                      >
                        <section className="scorecard-section-container" key={section.ssid}>
                          <header>
                            <div className="flex vertical-center small-gap">
                              <Button
                                icon
                                basic
                                className="svg-button"
                                onClick={() => setCollapsed(section)}
                              >
                                {isCollapsed ? <IconChevronRight /> : <IconChevronDown />}
                              </Button>
                              <h3>{truncate(section.name, { length: 60 })}</h3>
                            </div>
                            <Pill contrast>{`${aggregatePoints} points`}</Pill>
                          </header>

                          {!isCollapsed && (
                            <FieldArray
                              name={`sections[${index}].criteria`}
                              render={() => (
                                <div className="scorecard-criteria-container">
                                  <header>
                                    <h3>Criteria</h3>
                                    <h3>Points</h3>
                                  </header>

                                  <Drop droppableId={section.ssid} type="droppable-criteria">
                                    {toArray(section.criteria).map((criteria, criteriaIndex) => {
                                      const hasError = get(
                                        errors,
                                        `sections[${index}].criteria[${criteriaIndex}].weight`
                                      )

                                      return (
                                        <Drag
                                          key={criteria.scid}
                                          draggableId={criteria.scid}
                                          index={criteriaIndex}
                                          alwaysShowIcon
                                          isModal
                                          showDragStyle
                                        >
                                          <div>
                                            <div className="scorecard-criteria-item">
                                              <div>{truncate(criteria.name, { length: 70 })}</div>
                                              <Field
                                                name={`sections[${index}].criteria[${criteriaIndex}].weight`}
                                                component={NumberField}
                                                style={{ maxWidth: '100px' }}
                                                className={classNames({
                                                  'error-outline': hasError,
                                                })}
                                              />
                                            </div>
                                          </div>
                                          {hasError && (
                                            <div className="criteria-points-modal-error">
                                              {
                                                errors.sections[index].criteria[criteriaIndex]
                                                  .weight
                                              }
                                            </div>
                                          )}
                                        </Drag>
                                      )
                                    })}
                                  </Drop>
                                </div>
                              )}
                            />
                          )}
                        </section>
                      </Drag>
                    )
                  })}
                </Drop>
              </DragAndDrop>

              <div className="modal-footer">
                <div className="flex-space-betweenn">
                  <h3>Total</h3>
                  <div className="text-right">
                    <Pill success>{`${totalPoints} points`}</Pill>
                  </div>
                </div>
                <div className="flex-end gap">
                  <Button type="button" secondary onClick={handleCloseModal}>
                    Discard Changes
                  </Button>
                  <ConditionalTooltip
                    condition={!isEmpty(errors)}
                    content="Must fix errors to submit"
                  >
                    <Button
                      type="button"
                      primary
                      onClick={handleSubmit}
                      disabled={!isEmpty(errors)}
                    >
                      Update
                    </Button>
                  </ConditionalTooltip>
                </div>
              </div>
            </>
          )
        }}
      </Formik>
    </BasicModal>
  )
}
