import React from 'react'
import moment from 'moment/moment'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { AlertTableRowName } from './AlertTableRowName/AlertTableRowName'
import '../realtimeCoaching.scss'

export const AlertInstanceTable = (props) => {
  const { rows } = props

  function constructFormat(calculatedTime) {
    //  If the call is 1+ hour long and the alert fired after that, then we'll need to change the format
    return calculatedTime >= 3600 ? 'hh:mm:ss' : 'mm:ss'
  }

  function formatRows(rows) {
    return rows.map((row) => {
      const newRow = { ...row }
      const alertName = row.name
      const format = constructFormat(row.time_seconds)
      const emptyTime = moment().startOf('day')
      const formattedDurationTimestamp = emptyTime.add(row.time_seconds, 'seconds').format(format)

      newRow.name = <AlertTableRowName type={row.type} alertName={alertName} />
      newRow.alertTimeDisplay = <div className="timestamp-cell"> {formattedDurationTimestamp} </div>
      newRow.timeDisplay = (
        <div className="timestamp-cell">{moment.utc(row.created_at).local().format('LT')}</div>
      )
      return newRow
    })
  }

  function sortRowsByAscendingTime(rows) {
    return rows.sort((a, b) => {
      return a.datetime < b.datetime
    })
  }

  const formattedRows = formatRows(rows)
  const sortedRows = sortRowsByAscendingTime(formattedRows)

  const columns = [
    {
      accessor: 'name',
      label: '',
    },
    {
      accessor: 'alertTimeDisplay',
      label: 'time',
    },
    {
      accessor: 'timeDisplay',
      label: 'timestamp',
    },
  ]

  return (
    <div data-testid="alertInstanceTable" className="alerts-table scrollable-y">
      <AdvancedTable
        loading={false}
        index="uuid"
        rows={sortedRows}
        columns={columns}
        pagination={false}
        displayHeader={false}
      />
    </div>
  )
}
