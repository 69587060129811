import React, { useEffect } from 'react'
import { Field } from 'formik'

import { RadioGroup, PlaybookItemSelector } from '@/components/forms/formik'
import { TriggerItems } from './TriggerItems'

import './AlertWizard.css'

export const PlaybookConfigPage = ({
  values,
  setFieldValue,
  configPaths,
  readOnly = false,
  validationFormName = 'playbookConfigPage',
}) => {
  useEffect(() => {
    const triggerListBottom = document.getElementById('trigger-list-bottom')
    triggerListBottom?.scrollIntoView({ behavior: 'smooth' })
  }, [values?.triggerItems])

  useEffect(() => {
    setFieldValue('displayedForm', validationFormName)
  }, [])

  const triggerTypeOptions = [
    {
      value: 'occurrence',
      text: 'Occurrence of Items',
      disabled: false,
    },
    {
      value: 'absence',
      text: 'Absence of Items',
      disabled: true,
    },
  ]

  const isAllTimedChecklist = (triggerItems) => {
    let allTimedChecklist = true

    if (triggerItems && triggerItems.length > 0) {
      for (const item of triggerItems) {
        // check if all items timed_checklist
        if (item.path && !item.path.required_before) {
          allTimedChecklist = false
        }
      }
    }

    return allTimedChecklist
  }

  if (isAllTimedChecklist(values.triggerItems)) {
    triggerTypeOptions[1].disabled = false
  } else {
    triggerTypeOptions[1].disabled = true
  }

  return (
    <div data-testid="alert-wizard__weight-page">
      <Field required name="playbookItems" label="Add Trigger Item(s)">
        {(formikProps) => (
          <div className="w-100 field">
            <PlaybookItemSelector
              required
              label="Add Trigger Item(s)"
              className="w-100"
              configPaths={configPaths}
              readOnly={readOnly}
              onItemAdded={(items) => {
                const concatArray = values.triggerItems.concat(items)
                if (!isAllTimedChecklist(concatArray)) {
                  // formik issue, you cannot call setFieldValue multiple times,
                  // you need to call setValues
                  // https://github.com/formium/formik/issues/581
                  formikProps.form.setValues({
                    ...values,
                    triggerType: 'occurrence',
                    triggerItems: concatArray,
                  })
                } else {
                  formikProps.form.setFieldValue('triggerItems', concatArray)
                }
              }}
              {...formikProps}
            />
          </div>
        )}
      </Field>
      <Field required name="triggerItems" label="Trigger Item(s)">
        {(formikProps) => (
          <div className="w-100 field">
            <TriggerItems
              onItemRemoved={(remainingItems) => {
                formikProps.form.setFieldValue('triggerItems', remainingItems)
              }}
              items={values.triggerItems}
              required
              label="Trigger Item(s)"
              className="w-100"
              readOnly={readOnly}
              {...formikProps}
            />
          </div>
        )}
      </Field>
      <Field
        required
        name="triggerType"
        label="Trigger Type"
        component={RadioGroup}
        options={triggerTypeOptions}
      />
    </div>
  )
}
