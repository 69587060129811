import React from 'react'
import { Form, Button } from 'semantic-ui-react'

export const DeleteForm = ({ handleSubmit, modalClose, content, buttonText = 'Delete' }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div style={{ margin: '0 0 1rem 0' }}>{content}</div>

      <div className="modal-footer">
        <Button secondary type="button" data-testid="cancel-button" onClick={modalClose}>
          Cancel
        </Button>
        <Button type="submit" data-testid="confirm-button" color="red">
          {buttonText}
        </Button>
      </div>
    </Form>
  )
}
