import React, { useEffect, useState } from 'react'
import Select from '@/components/forms/Select'
import { Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { playbookItemOptions } from '@/views/RealtimeCoaching/Reports/constants'
import { loadPlaybookBodyByCid } from '@/reducers/realtime/realtime.actions'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { find, isEmpty } from 'lodash'
import { RequiredIndicator } from '@/components/forms/RequiredIndicator'

export const TriggerItemSelector = ({ formikTriggerItems, onAddTriggerItems, form }) => {
  const dispatch = useDispatch()
  const [selectedPlaybook, setSelectedPlaybook] = useState({})
  const [selectedTriggerItemType, setSelectedTriggerItemType] = useState('')
  const [selectedTriggerItems, setSelectedTriggerItems] = useState([])
  const { configPathsLoading, configPaths: playbooks } = useSelector(
    (state) => state.realtimeNewAlertConfiguration
  )
  const selectedPlaybookCid = selectedPlaybook?.value

  useEffect(() => {
    if (selectedPlaybookCid) {
      dispatch(loadPlaybookBodyByCid({ playbookCid: selectedPlaybookCid }))
    }
  }, [selectedPlaybookCid])

  const formatAndSortPlaybookOptions = (playbooks) => {
    const playbookOptions = Object.keys(playbooks).map((playbookConfigId) => {
      return {
        key: playbookConfigId,
        value: playbookConfigId,
        label: playbooks[playbookConfigId].name,
      }
    })
    return playbookOptions.sort((a, b) => a.label.localeCompare(b.label))
  }

  const isOptionInTriggerItems = (playbookCid, option, formikTriggerItems) => {
    return find(
      formikTriggerItems,
      (triggerItem) =>
        triggerItem.playbookCid === playbookCid && triggerItem.triggerItemPath === option.value
    )
  }

  const constructTriggerItemOptions = (playbookCid, triggerItemType, formikTriggerItems) => {
    if (!selectedPlaybookCid || !selectedTriggerItemType) return []

    // Filters options based on the trigger type selected
    const filteredTriggerItemOptions = playbooks[playbookCid].paths.filter(
      ({ type, required_before }) => {
        if (triggerItemType === 'checklist-timing') {
          return type === 'checklist' && required_before
        }
        if (triggerItemType === 'checklist') {
          return type === triggerItemType && !required_before
        }
        return type === triggerItemType
      }
    )

    // Prepends 'requirement_missed' to path if is an absense item
    const triggerItemOptions = filteredTriggerItemOptions.map((option) => ({
      ...option,
      value:
        triggerItemType === 'checklist-timing' && option.required_before
          ? `requirement_missed - ${option.value}`
          : option.value,
    }))

    // Disables any options that are already added to the form
    if (isEmpty(formikTriggerItems)) {
      return triggerItemOptions
    }
    return triggerItemOptions.map((item) => {
      if (item?.options) {
        return {
          ...item,
          options: item.options.map((innerOption) => {
            if (isOptionInTriggerItems(playbookCid, innerOption, formikTriggerItems)) {
              return { ...innerOption, isDisabled: true }
            }
            return innerOption
          }),
        }
      }
      if (isOptionInTriggerItems(playbookCid, item, formikTriggerItems)) {
        return {
          ...item,
          isDisabled: true,
        }
      }
      return item
    })
  }

  const handlePlaybookChange = (value) => {
    setSelectedPlaybook(value || {})
    setSelectedTriggerItemType('')
    setSelectedTriggerItems([])
  }

  const handleItemTypeChange = (value) => {
    setSelectedTriggerItemType(value?.value || '')
    setSelectedTriggerItems([])
  }

  const handleAddTriggerItemsClick = () => {
    setSelectedTriggerItemType('')
    setSelectedTriggerItems([])
    onAddTriggerItems(selectedPlaybook, selectedTriggerItemType, selectedTriggerItems, form)
  }

  return (
    <>
      <label className="alert-wizard__field-label-text">
        Add Trigger Item(s)
        <RequiredIndicator />
      </label>

      <div data-testid="add-trigger-item" className="alert-wizard__trigger-item-selector">
        <div className="w-100">
          <label className="alert-wizard__field-label-text">Select Playbook</label>
          <Select
            placeholder="Select Playbook..."
            options={formatAndSortPlaybookOptions(playbooks)}
            onChange={(value) => {
              handlePlaybookChange(value)
            }}
            value={selectedPlaybookCid}
          />
        </div>

        <div className="flex w-100">
          <div className="alert-wizard__item-type-selector">
            <label className="alert-wizard__field-label-text">Select Item Type</label>
            <Select
              options={playbookItemOptions}
              onChange={(value) => handleItemTypeChange(value)}
              value={selectedTriggerItemType}
              isDisabled={!selectedPlaybookCid}
            />
          </div>

          <div className="alert-wizard__item-selector">
            <label className="alert-wizard__field-label-text">Select Item(s)</label>
            <MultiSelect
              options={constructTriggerItemOptions(
                selectedPlaybookCid,
                selectedTriggerItemType,
                formikTriggerItems
              )}
              value={selectedTriggerItems}
              loading={configPathsLoading}
              onChange={(value) => setSelectedTriggerItems(value)}
              isDisabled={!selectedPlaybookCid || !selectedTriggerItemType}
            />
          </div>
        </div>

        <Button
          onClick={handleAddTriggerItemsClick}
          disabled={isEmpty(selectedTriggerItems)}
          className="w-100"
          data-testid="add-trigger-item-button"
          type="button"
          primary
        >
          Add Trigger Item(s)
        </Button>
      </div>
    </>
  )
}
