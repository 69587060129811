import React from 'react'
import { Form } from 'semantic-ui-react'
import { DateRangePicker } from '@/views/RealtimeCoaching/components/filters/DateRangePicker'
import { AgentsDropdown } from '@/views/RealtimeCoaching/components/filters/AgentsDropdown'
import { ManagersDropdown } from '@/views/RealtimeCoaching/components/filters/ManagersDropdown'
import { TagsDropdown } from '@/views/RealtimeCoaching/components/filters/TagsDropdown'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

const AlertFiltersComponent = ({ flags = {} }) => {
  const dispatch = useDispatch()
  const { data, filters, loading } = useSelector((state) => state.realtimeCoaching)

  return (
    <Form className="rtc-alerts-page-filters" data-testid="rtc-alerts-page-filters">
      <div className="rtc-exports-grid">
        <DateRangePicker filterKey="nullableDateRange" />
        <Form.Field data-testid="rtc-alerts-page-status-dropdown">
          <label>Status</label>
          <MultiSelect
            isSelectAll
            placeholderPill="All Statuses"
            options={
              flags.rtcOrgLevelAlertsTableColumns2022
                ? data.alertStatus
                : data.alertStatus.filter(({ value }) => value !== 'pending')
            }
            value={filters.alertStatus}
            loading={loading.alertStatus}
            onChange={(value) => dispatch(setFilter({ alertStatus: value }))}
          />
        </Form.Field>
        {flags.rtcOrgLevelAlertsTableColumns2022 && (
          <>
            <ManagersDropdown label="Created By" filterKey="createdBy" />
            <ManagersDropdown label="Assigned To" filterKey="assignedTo" />
          </>
        )}
        <AgentsDropdown label="Alert on" />
        <TagsDropdown />
      </div>
    </Form>
  )
}

export const AlertFilters = withLDConsumer()(AlertFiltersComponent)
