import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Segment, Checkbox } from 'semantic-ui-react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { IconPencil, IconWorld } from '@tabler/icons-react'
import { trim } from 'lodash'

import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import {
  createPlaybook,
  fetchPlaybooks,
  updatePlaybookLanguage,
  updatePlaybookOrganization,
} from '@/reducers/playbooks/playbooks.actions'
import { closeModal } from '@/reducers/ui/ui.redux'
import { formatTime, getOrganizationOptions } from '@/utils/helpers'
import { truncatedColumn } from '@/components/helpers/tableColumnHelpers'

import { PlaybookDeleteForm } from './components/PlaybookDeleteForm'

const PlaybooksPageComponent = ({ flags }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { organizationid: organizationId, edit_config: canEditPlaybooks } = useSelector(
    (state) => state.currentUser
  )
  const organizations = useSelector((state) => state.organizations)
  const { playbooks, loading } = useSelector((state) => state.playbooks)
  const insightEvents = useSelector((state) => state.insightEvents)
  const isBaltoAdmin = organizationId === 1

  const getPlaybookDeleteOptions = (cid) => {
    return playbooks
      .filter((playbook) => playbook.cid !== cid)
      .filter((playbook) => {
        if (!isBaltoAdmin) {
          return playbook.organization_id === organizationId
        }
        return true
      })
      .map((playbook) => ({ value: playbook.cid, label: playbook.name }))
  }

  const handleCreatePlaybook = (formValues) => {
    const { name, config_cid, organization_id } = formValues
    const playbook = { name: trim(name), organization_id }

    dispatch(createPlaybook(playbook, config_cid, insightEvents))
    dispatch(closeModal())
  }

  const handleUpdateConfigLanguage = (language, cid) => {
    dispatch(updatePlaybookLanguage(language, cid))
  }

  const handleUpdatePlaybookOrganization = (formValues, cid) => {
    const { organization_id: organizationId } = formValues

    dispatch(updatePlaybookOrganization(organizationId, cid))
    dispatch(closeModal())
  }

  const redirectToEdit = (e, row) => {
    history.push(`/playbooks/${row.id}/checklist`)
  }

  const columns = [
    {
      accessor: 'name',
      label: 'Name',
      isSearchable: true,
      clickableRowLink: {
        prefix: 'playbooks',
        accessor: 'id',
        suffix: '/checklist',
      },
      format: (playbook) => truncatedColumn(playbook),
    },
    {
      accessor: 'created_on',
      label: 'Last Updated',
      format: (value) => formatTime(value),
    },
  ]

  if (flags.allSpanishPlaybookToggle2022) {
    const toggleColumn = {
      accessor: 'language',
      label: 'Spanish',
      notClickable: true,
      format: (value, row) => {
        const isSpanish = value === 'spanish'

        return (
          <Checkbox
            toggle
            checked={isSpanish}
            onChange={() => {
              const language = isSpanish ? 'english' : 'spanish'
              handleUpdateConfigLanguage(language, row.cid)
            }}
          />
        )
      },
    }

    columns.splice(1, 0, toggleColumn)
  }

  if (isBaltoAdmin) {
    columns.push({
      accessor: 'organization_name',
      label: 'Organization',
      isSearchable: true,
    })
  }

  const actions = [{ label: 'Edit', icon: <IconPencil />, fn: redirectToEdit }]

  if (isBaltoAdmin) {
    actions.push({
      label: 'Update Playbook Organization',
      trigger: (row) => (
        <ButtonAndFormModal
          popup
          icon={<IconWorld />}
          modalTitle="Update Playbook Organization"
          modalId={`playbooks/update-playbook-organization-${row.id}`}
          modalProps={{ size: 'tiny' }}
          buttonProps={{ basic: true, compact: true }}
          form={
            <AbstractForm
              existingValues={{ organization_id: row.organization_id }}
              schema={[
                {
                  name: 'organization_id',
                  type: 'select',
                  label: 'Organization',
                  options: getOrganizationOptions(organizations),
                  required: true,
                },
              ]}
              onSubmit={(formValues) => handleUpdatePlaybookOrganization(formValues, row.cid)}
              buttonLabel="Save"
              onClose={() => dispatch(closeModal())}
              isModal
            />
          }
        />
      ),
    })
  }

  if (isBaltoAdmin || canEditPlaybooks) {
    actions.push({
      label: 'Delete',
      trigger: (row) => (
        <PlaybookDeleteForm
          playbookCid={row.cid}
          playbookOptions={getPlaybookDeleteOptions(row.cid)}
        />
      ),
    })
  }

  const rows = playbooks.map((playbook) => ({
    ...playbook,
    created_on: new Date(playbook.config_created).toISOString(),
  }))

  useEffect(() => {
    dispatch(fetchPlaybooks())
  }, [])

  return (
    <>
      <header className="page-header" data-testid="playbooks-page">
        <h1>Playbooks</h1>

        {(isBaltoAdmin || canEditPlaybooks) && (
          <ButtonAndFormModal
            buttonLabel="Create Playbook"
            modalTitle="Create Playbook"
            modalId="playbooks/create"
            modalProps={{ size: 'tiny' }}
            buttonProps={{ primary: true }}
            form={
              <AbstractForm
                schema={[
                  { name: 'name', type: 'text', label: 'Name', required: true },
                  {
                    name: 'config_cid',
                    type: 'select',
                    label: 'Starting Playbook (optional)',
                    options: playbooks.map((val) => ({ value: val.cid, label: val.name })),
                  },
                ]}
                onSubmit={handleCreatePlaybook}
                buttonLabel="Create"
                onClose={() => dispatch(closeModal())}
                isModal
              />
            }
          />
        )}
      </header>

      <Segment className="not-padded">
        <AdvancedTable
          defaultOrderBy="name"
          loading={loading}
          columns={columns}
          rows={rows}
          actions={actions}
          rowAction={redirectToEdit}
          stickyAction
          pagination
        />
      </Segment>
    </>
  )
}

const PlaybooksPage = withLDConsumer()(PlaybooksPageComponent)

export default PlaybooksPage
