import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ResponsiveLine } from '@nivo/line'
import { DotsItem, useTheme } from '@nivo/core'
import moment from 'moment'
import { isEmpty, uniq } from 'lodash'
import { Popup } from 'semantic-ui-react'

import { chartColors } from '@/utils/constants'
import { ColorButton } from '@/components/buttons/ColorButton'
import NoData from '@/components/NoData'
import { ContextMenu } from '@/components/contextMenu/ContextMenu'

import '@/components/widget/WidgetTooltip.scss'

function SpecialPoints({ points, ...props }) {
  const shownPoints = points.filter((point) => point.data.special)
  const theme = useTheme()

  return (
    <g>
      {shownPoints.map((point) => (
        <DotsItem
          key={point.id}
          x={point.x}
          y={point.y}
          datum={point.data}
          symbol={props.pointSymbol}
          size={20}
          color={point.color}
          borderWidth={props.pointBorderWidth}
          borderColor={point.borderColor}
          label={point.label}
          labelYOffset={props.pointLabelYOffset}
          theme={theme}
        />
      ))}
    </g>
  )
}

export const TrendsLineChart = ({ objectiveId }) => {
  const {
    widgets: { categories },
  } = useSelector((state) => state.userSettings)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [contextMenu, setContextMenu] = useState({ show: false, x: 0, y: 0 })
  const catValues = categories[objectiveId]

  const handleSelectCategory = (category) => {
    if (selectedCategory === category) {
      setSelectedCategory(null)
    } else {
      setSelectedCategory(category)
    }
  }

  if (isEmpty(catValues)) {
    return (
      <div className="empty-table">
        <NoData text="No trend data exists. Please check back later." />
      </div>
    )
  }

  const categoriesWithColors = catValues.map((category, i) => ({
    ...category,
    color: chartColors[i % chartColors.length],
  }))

  const data = categoriesWithColors
    .filter((category) => {
      if (selectedCategory) {
        return category.category === selectedCategory
      }
      return true
    })
    .map((category) => ({
      id: category.category,
      data: category.data.map((d) => ({
        x: moment(d.date_time).toISOString(),
        y: Number(d.value * 100).toFixed(2),
        special:
          category.category === 'Financial Clarification' && Number(d.value * 100).toFixed(2) > 35,
      })),
      color: category.color,
    }))

  const ticks = catValues.reduce((acc, val) => {
    const dates = val.data.map((d) => moment(d.date_time).toISOString())

    return uniq([...acc, ...dates])
  }, [])

  const tickValues = []
  ticks.forEach((date) => {
    const currentMonth = moment(date).format('MMM')
    if (!tickValues.find((month) => moment(month).format('MMM') === currentMonth)) {
      tickValues.push(date)
    }
  })

  return (
    <div>
      <div style={{ height: '350px' }}>
        <ResponsiveLine
          data={data}
          colors={
            selectedCategory
              ? categoriesWithColors.find((category) => category.category === selectedCategory)
                  ?.color || '#a14dff'
              : categoriesWithColors.map((item) => item.color)
          }
          margin={{ top: 40, right: 40, bottom: 60, left: 40 }}
          curve="monotoneX"
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false }}
          enableGridX={false}
          enableGridY={false}
          pointSize={0}
          isInteractive
          enableTouchCrosshair
          crosshairType="x"
          useMesh
          lineWidth={4}
          layers={[
            'grid',
            'axes',
            'areas',
            'lines',
            'crosshair',
            'slices',
            'mesh',
            'legends',
            SpecialPoints,
          ]}
          tooltip={() => null}
          pointColor={{ from: 'color', modifiers: [] }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: (v) => `${v}%`,
          }}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues,
            format: (v) => moment(v).format('MMM'),
          }}
          onClick={(point) => {
            if (point.data.special) {
              setContextMenu({ show: true, x: point.x, y: point.y, point })
            } else {
              setContextMenu({ show: false, x: 0, y: 0, point: null })
            }
          }}
        />
        <ContextMenu
          show={contextMenu.show}
          x={contextMenu.x}
          y={contextMenu.y}
          point={contextMenu.point}
          categories={catValues}
        />
      </div>
      <div className="flex-align-center flex-wrap medium-gap">
        {categoriesWithColors.map((category) => (
          <Popup
            key={category.category}
            position="top left"
            on="hover"
            content={category.description}
            disabled={!category.description}
            inverted
            style={{ textAlign: 'left' }}
            trigger={
              <div>
                <ColorButton
                  color={category.color}
                  onClick={() => handleSelectCategory(category.category)}
                  active={selectedCategory === category.category}
                >
                  {category.category}
                </ColorButton>
              </div>
            }
          />
        ))}
      </div>
    </div>
  )
}
