import { ExclusiveMultiSelect } from '@/views/RealtimeCoaching/components/pickers/ExclusiveMultiSelect'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import {
  fetchAgentsByOrg,
  fetchTagsByOrg,
} from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'

export const AgentsTagsExclusivePicker = ({ value, onAgentChange, onTagChange, ...props }) => {
  const dispatch = useDispatch()
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const { agents, tags } = useSelector((state) => state.realtimeCoaching.data)

  useEffect(() => {
    if (isEmpty(agents)) {
      dispatch(fetchAgentsByOrg(organizationId))
    }
    if (isEmpty(tags)) {
      dispatch(fetchTagsByOrg(organizationId))
    }
  }, [])

  return (
    <ExclusiveMultiSelect
      options={[
        {
          label: 'Agents',
          onChange: onAgentChange,
          data: agents,
        },
        {
          label: 'Tags',
          onChange: onTagChange,
          data: tags,
        },
      ]}
      value={value}
      {...props}
    />
  )
}
