import React from 'react'

export const BaltoGPTIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.364 19.3639C19.6227 18.1052 20.4798 16.5016 20.8271 14.7558C21.1743 13.0099 20.9961 11.2004 20.3149 9.55582C19.6337 7.9113 18.4802 6.50569 17.0001 5.51677C15.5201 4.52784 13.78 4 12 4C10.22 4 8.47992 4.52784 6.99988 5.51677C5.51984 6.50569 4.36629 7.9113 3.6851 9.55582C3.00391 11.2004 2.82567 13.0099 3.17293 14.7558C3.52019 16.5016 4.37734 18.1052 5.636 19.3639"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.536 16.536C16.2351 15.8367 16.7112 14.9458 16.904 13.9759C17.0968 13.0061 16.9978 12.0008 16.6193 11.0873C16.2408 10.1737 15.5999 9.39289 14.7777 8.84354C13.9555 8.29419 12.9889 8.00098 12 8.00098C11.0112 8.00098 10.0445 8.29419 9.22229 8.84354C8.40007 9.39289 7.75921 10.1737 7.38073 11.0873C7.00225 12.0008 6.90316 13.0061 7.09598 13.9759C7.2888 14.9458 7.76487 15.8367 8.464 16.536"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 13C11 13.2652 11.1054 13.5196 11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13C13 12.7348 12.8946 12.4804 12.7071 12.2929C12.5196 12.1054 12.2652 12 12 12C11.7348 12 11.4804 12.1054 11.2929 12.2929C11.1054 12.4804 11 12.7348 11 13Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
