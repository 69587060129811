import React, { useState, useEffect } from 'react'
import { Popup, Loader, Checkbox } from 'semantic-ui-react'
import classNames from 'classnames'
import { isEmpty, partition } from 'lodash'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { FilterBar } from '@/components/filters/FilterBar'

import { AdvancedTablePagination } from './AdvancedTablePagination'
import { AdvancedTableHeader } from './AdvancedTableHeader'
import { AdvancedTableBody } from './AdvancedTableBody'
import NoData from '../../NoData'
import { getComparator, stableSort, filterRows, calculateDataGridOffsets } from './helpers'

import './AdvancedTable.scss'
import { SelectAllBanner } from './SelectAllBanner'

export const AdvancedTable = ({
  testid = 'advanced-table',
  rows = [],
  columns = [],
  loading = false,
  loadingText,
  actions = [],
  defaultOrderBy = 'id',
  defaultOrder = 'asc',
  sortEnabled = true,
  index = 'id',
  displayHeader = true,
  rowAction,
  pagination = false,
  rowsPerPage = 20,
  enableUpdateRowsPerPage = true,
  stickyAction = false,
  truncate = false,
  wrapColumnContent = true,
  hoverable = true,
  noDataText,
  noDataHeader,
  noDataAction,
  striped = true,
  borderless = true,
  borderlessRows = false,
  compact = false,
  compactHeader = false,
  pinned,
  bulkSelectable,
  handleBulkSelectSingle,
  handleBulkSelectAll,
  selectedRows,
  reorderable = false,
  handleReorder,
  bulkSelectAction,
  handleSelectAll,
  handleDeselectAll,
  dataGrid = false,
  dataGridAdditionalHeaderOffset,
  filterBarSchema = [],
  filterBarActions = null,
  updateRowIdsRendered,
}) => {
  const [updatedRowsPerPage, setUpdatedRowsPerPage] = useState(rowsPerPage)
  const [activePage, setActivePage] = useState(1)
  const [order, setOrder] = useState(defaultOrder || 'asc')
  const [orderBy, setOrderBy] = useState(defaultOrderBy || 'id')
  const [searchFilters, setSearchFilters] = useState({})
  const [isSelectAllBannerVisible, setIsSelectAllBannerVisible] = useState(false)
  const isBulkSelectable =
    bulkSelectable && selectedRows && handleBulkSelectSingle && bulkSelectAction
  const allRowsSelected =
    isBulkSelectable && !isEmpty(selectedRows) && rows.length === selectedRows.length
  const offsets = calculateDataGridOffsets(
    dataGrid,
    isSelectAllBannerVisible,
    dataGridAdditionalHeaderOffset
  )

  useEffect(() => {
    // Reset table if there's a reload
    if (loading) {
      setSearchFilters({})
      setActivePage(1)
      setIsSelectAllBannerVisible(false)
    }
  }, [loading])

  // Update the default order by if it has changed between renders
  useEffect(() => {
    setOrderBy(defaultOrderBy)
  }, [defaultOrderBy])

  useEffect(() => {
    setOrder(defaultOrder)
  }, [defaultOrder])

  const handleUpdateRowsPerPage = (value) => {
    setActivePage(1)
    setUpdatedRowsPerPage(value)
  }

  const handleRequestSort = (event, accessor) => {
    const isAsc = orderBy === accessor && order === 'asc'

    // Reset active page on sort
    setActivePage(1)
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(accessor)
  }

  const updateSearch = (accessor, value) => {
    // Reset active page on search
    setActivePage(1)
    setSearchFilters((prevState) => ({
      ...prevState,
      [accessor]: value,
    }))
  }

  if (loading) {
    return (
      <div className="empty-table">
        <Loader active data-testid="loader">
          {loadingText || ''}
        </Loader>
      </div>
    )
  }

  if (isEmpty(rows)) {
    return (
      <div className={classNames('empty-table', { wide: dataGrid })} data-testid="empty-table">
        <NoData text={noDataText} headerText={noDataHeader} />
        {noDataAction && <div data-testid="no-data-action">{noDataAction}</div>}
      </div>
    )
  }

  // Filter all rows by search value
  const searchFilteredRows = filterRows(rows, searchFilters)

  // Find pagination values
  const count = searchFilteredRows.length
  const calculatedRowsPerPage = pagination || updatedRowsPerPage ? updatedRowsPerPage : count
  const totalPages = pagination ? Math.ceil(count / calculatedRowsPerPage) : 1

  // Apply sort and pagination
  let filteredRows
  if (!reorderable && sortEnabled) {
    // reorderable is always sort disabled
    if (pinned) {
      // `pinned` is the accessor of the column that should always be sorted first
      // first do the regular sort on the rows, then partition to get pinned and unpinned
      const sortedRows = stableSort(searchFilteredRows, getComparator(order, orderBy))
      // checking if object because sometimes a user can pass in `{ as: <Component>, value: 'value' }`
      const [pinnedRows, unPinnedRows] = partition(sortedRows, (item) =>
        typeof item[pinned] === 'object' ? item[pinned].value : !!item[pinned]
      )
      const allRows = [...pinnedRows, ...unPinnedRows]

      filteredRows = allRows.slice(
        (activePage - 1) * calculatedRowsPerPage,
        activePage * calculatedRowsPerPage
      )
    } else {
      filteredRows = stableSort(searchFilteredRows, getComparator(order, orderBy)).slice(
        (activePage - 1) * calculatedRowsPerPage,
        activePage * calculatedRowsPerPage
      )
    }
  } else {
    filteredRows = searchFilteredRows.slice(
      (activePage - 1) * calculatedRowsPerPage,
      activePage * calculatedRowsPerPage
    )
  }

  const handleSelectAllSearchFiltered = () => {
    handleSelectAll(searchFilteredRows)
    setIsSelectAllBannerVisible(false)
  }

  const handleBulkSelect = () => {
    // select paginated rows
    if (searchFilteredRows.length > rowsPerPage) {
      handleSelectAll(filteredRows)
      setIsSelectAllBannerVisible(true)
    } else {
      handleSelectAllSearchFiltered()
    }
  }

  const handleBulkDeselect = () => {
    handleDeselectAll()
    setIsSelectAllBannerVisible(false)
  }

  if (updateRowIdsRendered) {
    updateRowIdsRendered(filteredRows.map((row) => row.call_id))
  }

  return (
    <>
      {!isEmpty(filterBarSchema) && (
        <FilterBar
          filterBarSchema={filterBarSchema}
          loading={loading}
          searchFilters={searchFilters}
          updateSearch={updateSearch}
          rows={rows}
        >
          {filterBarActions}
        </FilterBar>
      )}

      {isSelectAllBannerVisible && (
        <SelectAllBanner
          rowsPerPage={rowsPerPage}
          handleSelectAllSearchFiltered={handleSelectAllSearchFiltered}
          rows={rows}
        />
      )}

      {isBulkSelectable && !isEmpty(selectedRows) && (
        <div className="bulk-select-actions" style={{ top: offsets.selectActionOffset }}>
          <div className="flex-align-center gap">
            <Popup
              inverted
              content="Deselect All"
              trigger={
                <Checkbox
                  indeterminate={!allRowsSelected && !isEmpty(selectedRows)}
                  checked={allRowsSelected}
                  onChange={handleBulkDeselect}
                />
              }
            />
            <span>{`${selectedRows.length} Selected`}</span>
          </div>
          <div className="bulk-select-action">{bulkSelectAction}</div>
        </div>
      )}

      <div
        className={classNames({ 'scrollable-table': !dataGrid, 'data-grid': dataGrid })}
        style={dataGrid ? { height: `calc(100vh - ${offsets.tableOffset}px)` } : undefined}
      >
        <table
          className={classNames('advanced-table', { borderless, striped, hoverable, compact })}
          data-testid={testid}
        >
          {displayHeader && (
            <AdvancedTableHeader
              actions={actions}
              columns={columns}
              orderBy={orderBy}
              order={order}
              onRequestSort={handleRequestSort}
              displayHeader={displayHeader}
              borderlessRows={borderlessRows}
              truncate={truncate}
              stickyAction={stickyAction}
              updateSearch={updateSearch}
              wrapColumnContent={wrapColumnContent}
              searchFilters={searchFilters}
              compactHeader={compactHeader}
              isBulkSelectable={isBulkSelectable}
              handleBulkSelectAll={handleBulkSelectAll}
              allRowsSelected={allRowsSelected}
              selectedRows={selectedRows}
              reorderable={reorderable}
              rows={rows}
              filteredRows={filteredRows}
              bulkSelectAction={bulkSelectAction}
              handleDeselectAll={handleBulkDeselect}
              handleSelectAll={handleBulkSelect}
              dataGrid={dataGrid}
            />
          )}
          <DragDropContext
            onDragEnd={(result) => handleReorder(result, activePage, calculatedRowsPerPage)}
          >
            <Droppable droppableId="reorderable-table" isDropDisabled={!reorderable}>
              {(dropProvided) => (
                <AdvancedTableBody
                  filteredRows={filteredRows}
                  columns={columns}
                  actions={actions}
                  index={index}
                  borderlessRows={borderlessRows}
                  isBulkSelectable={isBulkSelectable}
                  selectedRows={selectedRows}
                  handleBulkSelectSingle={handleBulkSelectSingle}
                  reorderable={reorderable}
                  rowAction={rowAction}
                  stickyAction={stickyAction}
                  wrapColumnContent={wrapColumnContent}
                  dropProvided={dropProvided}
                  searchFilters={searchFilters}
                  dataGrid={dataGrid}
                />
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
      {pagination && (
        <AdvancedTablePagination
          activePage={activePage}
          rowsPerPage={calculatedRowsPerPage}
          count={count}
          totalPages={totalPages}
          setActivePage={setActivePage}
          handleUpdateRowsPerPage={enableUpdateRowsPerPage && handleUpdateRowsPerPage}
          dataGrid={dataGrid}
        />
      )}
    </>
  )
}
