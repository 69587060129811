import React, { useEffect, useState } from 'react'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { Form } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { fetchManagersByOrg } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'

export const ManagersDropdown = ({ label = 'Managers', filterKey = 'managers' }) => {
  const dispatch = useDispatch()
  const [managerSearchQuery, setManagerSearchQuery] = useState('')
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const { data, filters, loading } = useSelector((state) => state.realtimeCoaching)

  useEffect(() => {
    if (isEmpty(data.managers)) {
      dispatch(fetchManagersByOrg(organizationId))
    }
  }, [])

  return (
    <Form.Field data-testid={`rtc-${label.toLowerCase().replace(' ', '-')}-dropdown`}>
      <label id={label.toLowerCase().replace(' ', '-')}>{label}</label>
      <MultiSelect
        aria-labelledby={label.toLowerCase().replace(' ', '-')}
        isSelectAll
        placeholderPill="All Supervisors"
        options={!managerSearchQuery && data.managers.length > 1000 ? [] : data.managers}
        optionsLength={data.managers.length}
        loading={loading.managers}
        value={filters[filterKey]}
        onInputChange={(inputValue) => setManagerSearchQuery(inputValue)}
        onChange={(value) => dispatch(setFilter({ [filterKey]: value }))}
        noOptionsMessage={() =>
          managerSearchQuery ? 'No results found' : 'Begin typing to search...'
        }
      />
    </Form.Field>
  )
}
