import * as Yup from 'yup'
import produce from 'immer'
import { isEmpty, isPlainObject, isArray } from 'lodash'

import { accessors } from '../../playbook.helpers'

export const categoryEntryValidationSchema = (accessor) =>
  Yup.object().shape({
    name: Yup.string().required('Name is required'),
    trigger: Yup.object()
      .shape({
        type: Yup.string().required(),
        side: Yup.string().when('type', {
          is: (triggerType) => {
            return ['keywords', 'talk_ratio', 'verbatim', 'transcription_classifier'].includes(
              triggerType
            )
          },
          then: Yup.string().required('Side is required'),
        }),
        ratio: Yup.string().when('type', {
          is: (triggerType) => {
            return triggerType === 'talk_ratio'
          },
          then: Yup.string().required('Talk ratio is required'),
        }),
        phrases: Yup.string().when('type', {
          is: (triggerType) => {
            return triggerType === 'keywords'
          },
          then: Yup.string().required('Custom keywords are required'),
        }),
        krid: Yup.string().when('type', {
          is: (triggerType) => {
            return triggerType === 'transcription_classifier'
          },
          then: Yup.string().required('Topic is required'),
        }),
        time_unit: Yup.string().when('type', {
          is: (triggerType) => {
            return triggerType === 'dead_air'
          },
          then: Yup.string().required('Time configuration is required'),
        }),
        threshold_time: Yup.string().when('type', {
          is: (triggerType) => {
            return triggerType === 'dead_air'
          },
          then: Yup.string().required('Time configuration is required'),
        }),
      })
      .nullable()
      .notRequired(),
    ...(accessor === accessors.DYNAMIC_PROMPT && {
      display: Yup.object()
        .shape({
          decklist: Yup.object({
            order: Yup.array(),
            entries: Yup.lazy((value) => {
              if (!isEmpty(value)) {
                const validationObject = { name: Yup.string().required('Item cannot be empty') }
                const newEntries = Object.keys(value).reduce(
                  (acc, val) => ({
                    ...acc,
                    [val]: Yup.object(validationObject),
                  }),
                  {}
                )

                return Yup.object().shape(newEntries)
              }
              return Yup.mixed().notRequired()
            }),
          }),
        })
        .test('atLeastOneDisplayValue', 'At least one display value must be applied', (value) => {
          return !!(
            value.header ||
            value.footer ||
            !isEmpty(value.decklist?.order) ||
            (!isEmpty(value.html) && value.html !== '<p></p>')
          )
        })
        .nullable()
        .notRequired(),
    }),
  })

export const categoryEntryInitialValues = (accessor) => ({
  name: '',
  trigger: { type: accessor === accessors.DYNAMIC_PROMPT ? 'none' : 'transcription_classifier' },
  ...(accessor === accessors.DYNAMIC_PROMPT && {
    display: { html: '', header: '', footer: '', decklist: { order: [], entries: {} } },
  }),
})

export const modifyExistingCategoryEntryInitialValues = (accessor) => (existingEntry) => {
  return produce(existingEntry, (draft) => {
    if (accessor === accessors.DYNAMIC_PROMPT) {
      if (isPlainObject(existingEntry.display) && !existingEntry.display.html) {
        if (!existingEntry.display?.html) {
          draft.display.html = ''
        }
      }

      // The display property can either be an object or an array
      if (Array.isArray(existingEntry.display)) {
        draft.display = existingEntry.display.reduce((acc, display) => ({ ...acc, ...display }))
      }

      // Default decklist item
      if (!draft.display.decklist || !draft.display.decklist.order.length) {
        draft.display.decklist = { order: [], entries: {} }
      }
    }

    // Add a none trigger for DP, AI library for Post Call
    if (!existingEntry.trigger) {
      draft.trigger = {}
      draft.trigger.type =
        accessor === accessors.DYNAMIC_PROMPT ? 'none' : 'transcription_classifier'
    }

    // Custom keywords comes in as an array, but needs to be edited as a comma-separated string
    if (isArray(existingEntry.trigger?.phrases)) {
      draft.trigger.phrases = existingEntry.trigger.phrases.join(',')
    }
  })
}
