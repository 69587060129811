import React from 'react'
import { ResponsiveHeatMap } from '@nivo/heatmap'

import { chartColors } from '../../utils/constants'

// TODO: There was a breaking change in 0.79.0 for Heatmap
// The data structure here is completely different from the other charts, and it was updated to be similar
// We would have to modify the data we pass in to get Heatmap to work with the latest Nivo version
// https://github.com/plouc/nivo/pull/1880
const HeatmapChart = ({ data, keys, index, ...props }) => {
  return (
    <ResponsiveHeatMap
      data={data}
      keys={keys}
      indexBy={index}
      colors={chartColors}
      margin={{ top: 10, right: 10, bottom: 10, left: 130 }}
      padding={4}
      axisRight={null}
      axisBottom={null}
      axisTop={null}
      axisLeft={null}
      cellOpacity={1}
      cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.8]] }}
      defs={[
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(0, 0, 0, 0.1)',
          rotation: -45,
          lineWidth: 4,
          spacing: 7,
        },
      ]}
      fill={[{ id: 'lines' }]}
      animate
      motionConfig="stiff"
      motionStiffness={80}
      motionDamping={9}
      hoverTarget="cell"
      cellHoverOthersOpacity={1}
      theme={{ tooltip: { container: { background: 'white', boxShadow: 'none', padding: '0' } } }}
      {...props}
    />
  )
}

export default HeatmapChart
