export const updatePromptHelper = (prompts, updatedPrompt) => {
  const newPrompts = prompts.filter(
    (prompt) =>
      (prompt.id && prompt.id !== updatedPrompt.id) ||
      (prompt.scid && prompt.scid !== updatedPrompt.scid)
  )
  newPrompts.push(updatedPrompt)
  return newPrompts
}

export const removePromptHelper = (prompts, scid) => {
  return prompts.filter((prompt) => prompt.scid !== scid)
}

export const addTemporaryPromptHelper = (scid) => {
  return {
    user_prompt: '',
    side: 'both',
    model: 'gpt-4o',
    feature: 'qa_copilot',
    scid,
  }
}

export const addPromptTestHelper = (prompts, promptToTest, promptTest) => {
  const newPrompts = prompts.filter(
    (prompt) =>
      (prompt.id && prompt.id !== promptToTest.id) ||
      (prompt.scid && prompt.scid !== promptToTest.scid)
  )
  const newPrompt = {
    ...promptToTest,
    prompt_tests: [...promptToTest.prompt_tests, promptTest],
  }
  newPrompts.push(newPrompt)
  return newPrompts
}

export const deletePromptTestHelper = (prompts, promptToTest, promptTestToDelete) => {
  const newPrompts = prompts.filter(
    (prompt) =>
      (prompt.id && prompt.id !== promptToTest.id) ||
      (prompt.scid && prompt.scid !== promptToTest.scid)
  )
  const newPrompt = {
    ...promptToTest,
    prompt_tests: promptToTest.prompt_tests.filter(
      (promptTest) => promptTest.id !== promptTestToDelete.id
    ),
  }
  newPrompts.push(newPrompt)
  return newPrompts
}
