import React from 'react'
import { validateUniqueName } from '@/views/Leaderboards/helpers'
import { LeaderboardQADetail } from '@/views/Leaderboards/LeaderboardForm/LeaderboardQADetail'

export const getSchema = ({
  isQA,
  determineIfQASelected,
  handleScorecardSelect,
  scorecardValues,
  playbookOptions,
  scorecardConfigOptions,
  rankOnOptions,
  timePeriodOptions,
  tagsOptionsWithCategories,
  leaderboardOptions,
}) => {
  const schema = [
    {
      label: 'Leaderboard Name',
      placeholder: 'Name your leaderboard',
      name: 'name',
      type: 'text',
      required: true,
      validate: (value) => validateUniqueName(value, leaderboardOptions),
    },
    {
      label: 'Rank On',
      name: 'rank_identifier',
      type: 'select',
      options: rankOnOptions,
      defaultValue: 'win_rate',
      required: true,
      onChange: (value = {}, formValues = {}) => determineIfQASelected({ ...value, ...formValues }),
    },
    {
      label: 'Playbook',
      name: 'config_ids',
      type: 'multiSelect',
      options: playbookOptions,
      condenseOptions: false,
      dependencies: [
        {
          key: 'rank_identifier',
          operator: '!==',
          value: 'qa',
        },
      ],
    },
    {
      label: 'QA Scorecard',
      name: 'scorecard_config_sid',
      type: 'select',
      options: scorecardConfigOptions,
      onChange: (value) => handleScorecardSelect(value || {}),
      condenseOptions: false,
      required: isQA,
      dependencies: [
        {
          key: 'rank_identifier',
          operator: '===',
          value: 'qa',
        },
      ],
    },
    {
      raw: true,
      as: <LeaderboardQADetail scorecard={scorecardValues} />,
      dependencies: [
        {
          key: 'rank_identifier',
          operator: '===',
          value: 'qa',
        },
        {
          key: 'scorecard_config_sid',
        },
      ],
    },
    {
      label: 'Visible to Agents',
      name: 'agent_visible',
      type: 'checkbox',
    },
    {
      label: 'Include Managers',
      name: 'include_managers',
      type: 'checkbox',
    },
    {
      label: 'Time Period',
      name: 'date_range',
      type: 'select',
      options: timePeriodOptions,
      defaultValue: 'today',
      required: true,
    },
    {
      label: 'Tag Filters',
      name: 'tags',
      type: 'multiSelect',
      options: tagsOptionsWithCategories,
      condenseOptions: false,
    },
    {
      label: 'Call Duration Minimum',
      name: 'call_duration',
      type: 'text',
      inputType: 'number',
      placeholder: 0,
      dependencies: [
        {
          key: 'rank_identifier',
          operator: '!==',
          value: 'qa',
        },
      ],
    },
  ]

  return schema
}
