export const reportLinks = [
  { path: '/reports', label: 'Overview', exact: true },
  { path: '/reports/checklist', label: 'Checklist' },
  { path: '/reports/dynamic-prompt', label: 'Dynamic Prompt' },
  { path: '/reports/notifications', label: 'Notifications' },
  { path: '/reports/postcall', label: 'Post Call' },
  { path: '/reports/usage', label: 'Usage' },
  { path: '/reports/csv', label: 'Export CSV' },
]

export const scorecardLinks = [
  { path: '/scorecards', label: 'Scorecards', exact: true },
  { path: '/scorecards/view_scores', label: 'Scores' },
  { path: '/scorecards/dashboard', label: 'Dashboard' },
  { path: '/scorecards/exports', label: 'Advanced Exports' },
  { path: '/scorecards/disputes', label: 'Agent Disputes' },
  { path: '/scorecards/activity', label: 'QA Activity' },
]

export const copilotScorecardLinks = [
  { path: '/qa-copilot', label: 'Scorecards', exact: true },
  { path: '/qa-copilot/view_scores', label: 'Scores' },
  { path: '/qa-copilot/enterprise', label: 'Enterprise Dashboard' },
  { path: '/qa-copilot/dashboard', label: 'Dashboard' },
  { path: '/qa-copilot/exports', label: 'Advanced Exports' },
  { path: '/qa-copilot/disputes', label: 'Agent Disputes' },
  { path: '/qa-copilot/activity', label: 'QA Activity' },
]

export const realTimeLinks = [
  { path: '/realtime_coaching/alerts', label: 'Alerts' },
  { path: '/realtime_coaching/activity-log', label: 'Activity Log' },
  { path: '/realtime_coaching/reports', label: 'Reports' },
  { path: '/realtime_coaching/exports', label: 'Advanced Exports' },
]

export const gamificationLinks = [
  { path: '/leaderboards', label: 'Leaderboards' },
  { path: '/gamification/challenges', label: 'Challenges' },
  { path: '/gamification/badges', label: 'Badges' },
]

export const getFilteredScorecardLinks = (
  links,
  flags,
  currentOrganization,
  isHierarchyManager
) => {
  return links.filter(({ label }) => {
    if (label === 'Agent Disputes') {
      return currentOrganization.qa_disputes_enabled
    }

    if (label === 'Enterprise Dashboard') {
      return currentOrganization.id === 1 || isHierarchyManager
    }

    return true
  })
}
