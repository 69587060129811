import React from 'react'
import { Button } from 'semantic-ui-react'

export const DateRangePickerClear = ({ clearButtonLabel, disabled, handleClear }) => {
  return (
    <div className="date-picker-clear-section" data-testid="date-picker-clear-section">
      <Button
        disabled={disabled}
        secondary
        onClick={handleClear}
        data-testid="clear"
        className="date-picker-clear-section__button"
      >
        {clearButtonLabel || 'Clear Results'}
      </Button>
    </div>
  )
}
