import React from 'react'
import queryString from 'query-string'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

import { fetchingAPI, apiService } from '@/api'

import { setLoading, setTascRecommendations } from './recommendations.redux'

export const fetchTascRecommendations = () => async (dispatch, getState) => {
  const {
    playbook: {
      playbook: { cid: playbookCid, organization_id: playbookOrgId },
    },
  } = getState()
  const query = {
    org_id: playbookOrgId,
    cid: playbookCid,
  }
  dispatch(setLoading(true))
  try {
    const recommendations = await fetchingAPI(
      `${apiService.insights}/api/recommendations/tasc?${queryString.stringify(query)}`,
      'GET',
      dispatch
    )

    dispatch(setTascRecommendations(recommendations))
  } catch (err) {
    toast.error('Failed to fetch Beacon Agents content')
    dispatch(setTascRecommendations([]))
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateRecommendationStatus = ({ recommendations, recommendationId, newStatus }) => {
  const queried = recommendations.find((element) => element.id === recommendationId)
  const recommendationIndex = recommendations.indexOf(queried)
  const newRecommendations = [...recommendations]
  newRecommendations[recommendationIndex] = { ...queried, status: newStatus }

  return newRecommendations
}

export const rejectTascRecommendation =
  ({ recommendationId }) =>
  async (dispatch, getState) => {
    const { currentUser } = getState()
    const {
      recommendations: { tascRecommendations: currentRecommendations },
    } = getState()

    const body = {
      user_id: currentUser.user_id,
      action_taken: 'dismiss',
    }
    try {
      await fetchingAPI(
        `${apiService.insights}/api/recommendations/tasc/${recommendationId}/events`,
        'POST',
        dispatch,
        JSON.stringify(body)
      )

      toast.success('Recommendation rejected')

      // Modify rejected recommendation after sending rejection to events API, no reload
      const newRecommendations = updateRecommendationStatus({
        recommendations: currentRecommendations,
        recommendationId,
        newStatus: 'rejected',
      })

      dispatch(setTascRecommendations(newRecommendations))
    } catch (err) {
      toast.error('Failed to apply changes')
      console.error(err)
    }
  }

const addChecklistItemToPlaybook = async (checklistEntry, playbookInfo, dispatch) => {
  const { cid } = playbookInfo
  const { entryVariationUuid } = checklistEntry
  const newPlaybookInfo = await fetchingAPI(
    `${apiService.web}/api/tasc-recommendations/add-checklist-item`,
    'POST',
    dispatch,
    JSON.stringify({
      cid,
      checklist_variation_uuid: entryVariationUuid,
    })
  )
  const playbookDeepLinkURL = `/playbooks/${newPlaybookInfo.config.id}/checklist`
  // we will likely need to LOAD_CONFIG and push to the new route
  return playbookDeepLinkURL
}

export const acceptTascRecommendation = (recommendation) => async (dispatch, getState) => {
  const { recommendationId, playbookInfo, entryVariationUuid, entryPropsUuid } = recommendation

  const { currentUser } = getState()

  const body = {
    user_id: currentUser.user_id,
    action_taken: 'save',
  }

  const checklistEntryInfo = {
    entryVariationUuid,
    entryPropsUuid,
  }

  try {
    const playbookDeepLinkURL = await addChecklistItemToPlaybook(
      checklistEntryInfo,
      playbookInfo,
      dispatch
    )

    await fetchingAPI(
      `${apiService.insights}/api/recommendations/tasc/${recommendationId}/events`,
      'POST',
      dispatch,
      JSON.stringify(body)
    )

    toast.success(
      <div>
        A recommendation was adopted as a checklist item.
        <br />
        <br />
        <Link to={playbookDeepLinkURL}>View Checklist</Link>
      </div>
    )
  } catch (err) {
    toast.error('Failed to apply changes')
    console.error(err)
  }
}
