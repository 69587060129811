import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBadgeSummaryByOrg } from '@/reducers/gamification/gamification.actions'
import { CollectionBadgePage } from '@/views/Gamification/Badges/CollectionBadgePage'
import { ChallengeBadgePage } from '@/views/Gamification/Badges/ChallengeBadgePage'
import { SecondaryNavRouter } from '@/components/layout/SecondaryNavRouter/SecondaryNavRouter'
import { Form, Grid } from 'semantic-ui-react'
import Select from '@/components/forms/Select'
import { isEmpty } from 'lodash'
import { getOrganizationOptions } from '@/utils/helpers'
import { setFilter } from '@/reducers/gamification/gamification.redux'
import './BadgesPage.scss'
import { useHistory } from 'react-router'

export const BadgesPage = ({ tab }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { filters } = useSelector((state) => state.gamification)
  const {
    data: { collectionBadgeTemplates, challengeBadgeTemplates },
  } = useSelector((state) => state.gamification)

  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const isBaltoAdmin = organizationId === 1

  const { organizations } = useSelector((state) => state)
  const organizationOptions = getOrganizationOptions(organizations)

  useEffect(() => {
    dispatch(fetchBadgeSummaryByOrg(filters.organizationId || organizationId))
  }, [])

  const handleOrgSelect = (option, action) => {
    if (action.action === 'clear') {
      dispatch(setFilter('organizationId', ''))
    } else {
      const orgSelected = option.value
      dispatch(setFilter('organizationId', orgSelected))
      dispatch(fetchBadgeSummaryByOrg(orgSelected))
    }
  }

  const redirectToBadgeDetailPage = (badgeId) => {
    history.push(`/gamification/badges/${badgeId}`)
  }

  const pages = [
    {
      label: 'Collections',
      component: (
        <CollectionBadgePage
          badgeTemplates={collectionBadgeTemplates}
          redirectToBadgeDetailPage={redirectToBadgeDetailPage}
        />
      ),
      url: '/gamification/badges/collections',
    },
    {
      label: 'Challenges',
      component: (
        <ChallengeBadgePage
          badgeTemplates={challengeBadgeTemplates}
          redirectToBadgeDetailPage={redirectToBadgeDetailPage}
        />
      ),
      url: '/gamification/badges/challenges',
    },
  ]

  return (
    <>
      <h1 data-testid="badges-page">Badges</h1>
      {isBaltoAdmin && (
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Form className="filter-form">
                <Form.Field data-testid="org-picker">
                  <label>Organization</label>
                  <Select
                    placeholder="Select Organization"
                    options={organizationOptions}
                    loading={isEmpty(organizations)}
                    value={filters.organizationId}
                    onChange={(option, action) => handleOrgSelect(option, action)}
                    fixedWidth
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      <SecondaryNavRouter pages={pages} activePage={tab} />
    </>
  )
}

export default BadgesPage
