import React from 'react'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import '../charts.scss'
import { RankedProgressBar } from '@/components/charts/RankedProgressBarChart/RankedProgressBar'
import { determineProgressColor } from '@/components/charts/RankedProgressBarChart/helpers'
import { isEmpty } from 'lodash'

export const RankedProgressBarChart = ({
  description,
  columns = [],
  rows = [],
  loading,
  progressKey,
  baltoThemeColor,
  format = (value) => value,
  maxValue = null,
  defaultOrderBy = progressKey,
}) => {
  const maxDataValue =
    maxValue ||
    rows.reduce((reducer, row) => (row[progressKey] > reducer ? row[progressKey] : reducer), 1)

  const rowsWithProgressBar = rows.map((row) => ({
    ...row,
    [progressKey]: {
      value: row[progressKey],
      as: RankedProgressBar({
        color: determineProgressColor((row[progressKey] / maxDataValue) * 100, baltoThemeColor),
        // max width of progress bar shouldn't exceed 90% or the bar looks smushed
        width: (row[progressKey] / maxDataValue) * 90,
        value: row[progressKey],
        format,
      }),
    },
  }))

  return (
    <div className="ranked-progress-bar-chart" data-testid="ranked-progress-bar-chart">
      {!isEmpty(description) && (
        <div className="ranked-progress-bar-chart-header">
          <h4>{description}</h4>
        </div>
      )}
      <AdvancedTable
        rows={rowsWithProgressBar}
        columns={columns}
        loading={loading}
        defaultOrder="asc"
        defaultOrderBy={defaultOrderBy}
        index="user_id"
        rowsPerPage={5}
        enableUpdateRowsPerPage={false}
      />
    </div>
  )
}
