import React from 'react'
import moment from 'moment'
import { Grid, Popup } from 'semantic-ui-react'
import './eventPopup.css'

const capitalize = (word) => word[0].toUpperCase() + word.substr(1)

export const EventPopup = (props) => {
  const { children, eventType, eventTime, callTimeOffset, description, section } = props
  const eventTypeDisplay =
    eventType === 'playbook'
      ? `${capitalize(section)} Item`
      : `${capitalize(eventType)} ${eventType !== 'Coaching' ? 'Alert' : ''}`
  const eventTimeDisplay =
    eventTime !== undefined ? moment.utc(eventTime).local().format('h:mm A') : ''
  const callTimeOffsetDisplay =
    callTimeOffset !== undefined ? moment.utc(callTimeOffset * 1000).format('mm:ss') : ''

  return (
    <Popup
      data-testid="eventPopup"
      position="top center"
      wide="very"
      pinned
      trigger={children}
      className="eventPopup"
    >
      <Popup.Header>
        <Grid>
          <Grid.Column width={7} data-testid="eventTypeDisplay">
            {eventTypeDisplay}
          </Grid.Column>
          <Grid.Column width={5} data-testid="eventTimeDisplay">
            {eventTimeDisplay}
          </Grid.Column>
          <Grid.Column width={2} data-testid="callTimeOffsetDisplay">
            {callTimeOffsetDisplay}
          </Grid.Column>
        </Grid>
      </Popup.Header>
      <Popup.Content data-testid="descriptionDisplay">{description}</Popup.Content>
    </Popup>
  )
}
