import React from 'react'
import { useSelector } from 'react-redux'
import { Segment } from 'semantic-ui-react'
import { NestedTable } from '@/components/tables/NestedTable/NestedTable'
import { getChallengeBadgesByTemplates } from '@/views/Gamification/Challenges/helpers'

const columns = [
  {
    accessor: 'name',
    label: 'Badge Name',
  },
  {
    accessor: 'challengeType',
    label: 'Challenge Type',
  },
  {
    accessor: 'description',
    label: 'Description',
    left: true,
  },
]

export const ChallengeBadgePage = ({ badgeTemplates, redirectToBadgeDetailPage }) => {
  const { loading } = useSelector((state) => state.gamification)

  const rows = badgeTemplates
    .filter((badgeTemplate) => !!badgeTemplate.name)
    .map((badgeTemplate) => ({
      ...badgeTemplate,
      index: badgeTemplate.templateId,
      description: badgeTemplate.description || badgeTemplate.templateDescription,
    }))

  return (
    <div data-testid="challenge-badges-page">
      <Segment className="not-padded">
        <NestedTable
          loading={loading.badgeTemplates}
          rows={getChallengeBadgesByTemplates(rows, redirectToBadgeDetailPage)}
          columns={columns}
          selectable={false}
          collapsibleChildren
          expandByDefault={false}
          striped
        />
      </Segment>
    </div>
  )
}

export default ChallengeBadgePage
