import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchCoachingPlaylist } from '@/reducers/playlists/playlists.actions'

import { PlaylistCallsContent } from './components/PlaylistCallsContent'

const CoachingPage = () => {
  const dispatch = useDispatch()
  const { organizationid: currentUserOrgId } = useSelector((state) => state.currentUser)

  useEffect(() => {
    dispatch(fetchCoachingPlaylist(currentUserOrgId))
  }, [])

  return <PlaylistCallsContent isCoachingPage />
}

export default CoachingPage
