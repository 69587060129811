import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumbs } from '@/components/forms/Breadcrumbs/Breadcrumbs'
import { Button, Loader } from 'semantic-ui-react'
import { UsersTab } from '@/views/RealtimeCoaching/Alerts/EditAlertPage/tabs/UsersTab'
import { TriggersTab } from '@/views/RealtimeCoaching/Alerts/EditAlertPage/tabs/TriggersTab'
import { SettingsTab } from '@/views/RealtimeCoaching/Alerts/EditAlertPage/tabs/SettingsTab'
import { Form, Formik } from 'formik'
import { capitalize, isEmpty } from 'lodash'
import {
  deleteAlertConfiguration,
  editOrgLevelAlertConfiguration,
  loadAlertConfigurations,
  loadPlaybookNamesAndCids,
} from '@/reducers/realtime/realtime.actions'
import { format } from 'date-fns'
import { alertFormInitialValues } from '@/views/RealtimeCoaching/Alerts/forms/validation/AlertValidation'
import { useHistory } from 'react-router-dom'
import NoData from '@/components/NoData'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import ConfirmForm from '@/components/forms/ConfirmForm'
import {
  fetchAgentsByOrg,
  fetchTagsByOrg,
} from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'
import { genericSchema } from '@/views/RealtimeCoaching/Alerts/forms/validation/OrgLevelAlertValidationSchema'
import * as Yup from 'yup'

export const EditAlertPage = ({ alertConfigIdStr }) => {
  const [selectedTab, setSelectedTab] = useState('settings')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const alertId = parseInt(alertConfigIdStr, 10)
  const dispatch = useDispatch()
  const history = useHistory()

  const { configPaths } = useSelector((state) => state.realtimeNewAlertConfiguration)
  const { data, filters } = useSelector((state) => state.realtimeCoaching)
  const { alertConfigurations: alertConfigs, loading: alertConfigsLoading } = useSelector(
    (state) => state.realtimeOrganizationAlertConfigurations
  )
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const alertConfig = alertConfigs.find((alertConfiguration) => alertConfiguration.id === alertId)

  const shape = genericSchema
  const validationSchema = Yup.object().shape(shape, ['selectedAgents', 'selectedTags'])

  const {
    name: alertName,
    created_at: createdAtIso,
    manager_name: createdByName,
  } = alertConfig || {}
  const createdAtStr = createdAtIso ? format(createdAtIso, 'MMMM d, yyyy') : ''

  useEffect(() => {
    if (isEmpty(alertConfigs)) {
      const managerId = filters.baltoAdminManager || null
      dispatch(loadAlertConfigurations({ userId: managerId }))
    }
    if (isEmpty(configPaths)) {
      dispatch(loadPlaybookNamesAndCids())
    }
    if (isEmpty(data.agents)) {
      dispatch(fetchAgentsByOrg(organizationId))
    }
    if (isEmpty(data.tags)) {
      dispatch(fetchTagsByOrg(organizationId))
    }
  }, [])

  const deleteAlert = ({ id, name }) => {
    dispatch(
      deleteAlertConfiguration({
        alertConfigurationId: id,
        alertName: name,
      })
    )
    history.push('/realtime_coaching/alerts')
  }

  const constructTriggerItems = (triggerItems) => {
    return triggerItems.map(({ cid, path }) => ({
      triggerItemPath: path,
      triggerItemLabel: path.split(' - ').pop(),
      itemType: capitalize(path.split(' - ')[0]),
      playbookCid: cid,
      playbookLabel: configPaths[cid]?.name || '',
    }))
  }

  const constructSelectedAgents = (selectedAgents) => {
    return selectedAgents.map((selectedAgentValue) =>
      data.agents.find((agent) => agent.value === selectedAgentValue)
    )
  }

  const constructSelectedTags = (selectedTags) => {
    const tagList = []
    data.tags.forEach((tagGroup) => {
      tagList.push(...tagGroup.options)
    })
    return selectedTags.map((selectedTagValue) =>
      tagList.find((tag) => selectedTagValue === tag.value)
    )
  }

  const constructInitialValues = (alertConfig) => {
    if (isEmpty(alertConfig)) {
      return alertFormInitialValues
    }
    return {
      ...alertFormInitialValues,
      displayedForm: 'edit',
      id: alertConfig.id,
      active_alert_config_version_id: alertConfig.active_alert_config_version_id,
      name: alertConfig.name,
      active: alertConfig.active,
      alertType: alertConfig.type,
      audible: alertConfig.audible,
      triggerItems: constructTriggerItems(alertConfig.trigger_on),
      selectedAgents: constructSelectedAgents(alertConfig.users),
      selectedTags: constructSelectedTags(alertConfig.tags),
    }
  }

  const saveAlertConfig = (alertConfig) => {
    dispatch(editOrgLevelAlertConfiguration(alertConfig, `${alertConfig.name} has been saved`))
  }

  if (alertConfigsLoading) {
    return (
      <div className="empty-table">
        <Loader active data-testid="loader" />
      </div>
    )
  }

  if (!alertConfig) {
    return (
      <div className="empty-table">
        <NoData text="No alert found" />
      </div>
    )
  }

  return (
    <>
      <Breadcrumbs
        backLinks={{ label: 'My Alerts', link: '/realtime_coaching/alerts' }}
        currentLink={alertName}
      />
      <Formik
        enableReinitialize
        initialValues={constructInitialValues(alertConfig)}
        onSubmit={saveAlertConfig}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <div className="flex">
              <div>
                <h1 className="edit-alert-page__title" data-testid="edit-alert-title">
                  {alertName}
                </h1>
                <h5 className="edit-alert-page__header">
                  Created On:{' '}
                  <span
                    className="edit-alert-page__header-values"
                    data-testid="edit-alert-created-at"
                  >
                    {createdAtStr}
                  </span>
                  <span className="edit-alert-page__bullet-separator">·</span>
                  Created By:{' '}
                  <span
                    className="edit-alert-page__header-values"
                    data-testid="edit-alert-created-by"
                  >
                    {createdByName}
                  </span>
                </h5>
              </div>
              <div className="ml-auto">
                <Button
                  data-testid="edit-alert-cancel-button"
                  type="button"
                  secondary
                  onClick={() => {
                    if (dirty) {
                      // eslint-disable-next-line no-alert
                      if (window.confirm('Are you sure you want to leave without saving?')) {
                        history.push('/realtime_coaching/alerts')
                      }
                    } else {
                      history.push('/realtime_coaching/alerts')
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="alert-save-button"
                  type="submit"
                  disabled={!dirty || !isValid}
                  primary
                >
                  Save
                </Button>
              </div>
            </div>
            <div className="edit-alert-page__tab-buttons">
              <Button
                type="button"
                onClick={() => setSelectedTab('settings')}
                className={`edit-alert-page__border_bottom_button${
                  selectedTab === 'settings' ? '--active' : ''
                }`}
              >
                Settings
              </Button>
              <Button
                type="button"
                data-testid="agents-button"
                onClick={() => setSelectedTab('users')}
                className={`edit-alert-page__border_bottom_button${
                  selectedTab === 'users' ? '--active' : ''
                }`}
              >
                Users
              </Button>
              <Button
                type="button"
                data-testid="triggers-button"
                onClick={() => setSelectedTab('triggers')}
                className={`edit-alert-page__border_bottom_button${
                  selectedTab === 'triggers' ? '--active' : ''
                }`}
              >
                Triggers
              </Button>
            </div>
            <div className="edit-alert-page__widget">
              {selectedTab === 'settings' && <SettingsTab setFieldValue={setFieldValue} />}
              {selectedTab === 'users' && (
                <UsersTab values={values} setFieldValue={setFieldValue} />
              )}
              {selectedTab === 'triggers' && (
                <TriggersTab values={values} setFieldValue={setFieldValue} />
              )}
            </div>
          </Form>
        )}
      </Formik>

      {selectedTab === 'settings' && (
        <>
          <hr className="alert-settings__hr" />
          <Button type="button" onClick={() => setShowDeleteModal(true)} color="red">
            Delete Alert
          </Button>
        </>
      )}

      <BasicModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title="Delete Alert"
        size="tiny"
      >
        {showDeleteModal && (
          <ConfirmForm
            valueToSubmit={alertConfig}
            bodyText={`Are you sure you want to delete the “${alertConfig.name}” alert? This action cannot be reversed.`}
            danger
            primaryButtonText="Delete Alert"
            handleSubmit={deleteAlert}
            modalClose={() => setShowDeleteModal(false)}
          />
        )}
      </BasicModal>
    </>
  )
}
