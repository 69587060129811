import React from 'react'
import classNames from 'classnames'
import ToolbarTooltip from './ToolbarTooltip'

export default React.memo(({ isActive, handleMouseDown, iconName, slateObjectType }) => (
  <ToolbarTooltip slateObjectType={slateObjectType}>
    <div
      className={classNames('text-editor-toolbar-button', { active: isActive })}
      onMouseDown={(e) => handleMouseDown(e, slateObjectType)}
    >
      <i className={iconName} />
    </div>
  </ToolbarTooltip>
))
