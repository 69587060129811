import React, { useEffect } from 'react'
import {
  fetchCategories,
  fetchDistinctOutcomes,
  updateOutcomes,
} from '@/reducers/integrations/integrations.actions'
import { useDispatch, useSelector } from 'react-redux'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { setDirty } from '@/reducers/integrations/integrations.redux'
import { Prompt } from 'react-router-dom'

const IntegrationsCallOutcomesPage = () => {
  const dispatch = useDispatch()
  const { outcomes, categories, loading, integration, dirty } = useSelector(
    (state) => state.integrations
  )
  useEffect(() => {
    dispatch(
      fetchDistinctOutcomes(integration.organization_id, integration.type, integration.token)
    )
  }, [])
  useEffect(() => {
    dispatch(fetchCategories(integration.token))
  }, [])

  const id_outcome_map = new Map()
  outcomes.forEach((outcome) => {
    id_outcome_map.set(outcome.id, outcome)
  })
  const categoryOptions = categories.map((category) => ({
    label: category.name,
    value: category.id,
  }))
  categoryOptions.push({ label: 'None', value: null })
  const outcomeFormSchema = outcomes.map((outcome) => ({
    label: outcome.name,
    name: outcome.id,
    defaultValue: outcome.category_id,
    options: categoryOptions,
    type: 'select',
    nullable: true,
  }))

  const handleDirty = (isDirty) => {
    // Must check equality or else it will infinite loop
    if (isDirty !== dirty) {
      dispatch(setDirty(isDirty))
    }
  }

  // Prompt react component does not work on page refresh so this is used for unsaved work popup
  if (dirty) {
    window.onbeforeunload = () => true
  } else {
    window.onbeforeunload = () => null
  }

  const handleSubmit = (
    { validateOnMount, ...outcome_id_category_id_pairs },
    { setSubmitting }
  ) => {
    dispatch(setDirty(false))
    window.onbeforeunload = () => null
    dispatch(
      updateOutcomes(
        id_outcome_map,
        outcome_id_category_id_pairs,
        integration.organization_id,
        integration.type,
        integration.token
      )
    )
    setSubmitting(false)
  }

  return (
    <>
      <Prompt when={dirty} message="You have unsaved changes. Are you sure you want to leave?" />
      <h2>Call Outcomes</h2>
      <AbstractForm
        schema={outcomeFormSchema}
        enableReinitialize
        onSubmit={handleSubmit}
        onDirty={handleDirty}
        buttonAlignment="left"
        buttonLabel="Save"
        buttonProps={{ loading }}
      />
    </>
  )
}

export default IntegrationsCallOutcomesPage
