import React from 'react'
import './TriggerItems.css'
import { TriggerItem } from './TriggerItem'

export const TriggerItems = ({ label, items, onItemRemoved, readOnly = false }) => {
  const isEmpty = items.length === 0

  return (
    <div>
      <div>
        <label data-testid="trigger-items-label">{label}</label>
      </div>
      <div className="trigger-items-box">
        {isEmpty && (
          <div className="trigger-empty-div">
            <div className="no-trigger-items">No Trigger Items</div>
            <div className="no-items-description">
              Add Playbook Items as triggers for this alert. You can add triggers using the section
              above.
            </div>
          </div>
        )}
        {!isEmpty && (
          <div className="trigger-list">
            {items &&
              items.map((item) => {
                return (
                  <TriggerItem
                    key={item.id}
                    item={item}
                    readOnly={readOnly}
                    onItemRemoved={(item) => {
                      onItemRemoved(items.filter((i) => i.id !== item.id))
                    }}
                  />
                )
              })}
            <div id="trigger-list-bottom" />
          </div>
        )}
      </div>
    </div>
  )
}
