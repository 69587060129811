// Action Types
export const SET_INSIGHT_EVENTS = 'insights/setInsightEvents'
export const SET_INSIGHT_DETAILS = 'insights/setInsightDetails'
export const SET_INSIGHTS_REORDER_RECOMMENDED = 'insights/setInsightsReorderRecommended'
export const SET_INSIGHTS_SUGGESTED_ORDER = 'insights/setInsightsSuggestedOrder'
export const CLEAR_INSIGHT_EVENTS = 'insights/clearInsightEvents'

// Action Creators
export const setInsightEvents = (payload) => ({ type: SET_INSIGHT_EVENTS, payload })
export const setInsightDetails = (payload) => ({ type: SET_INSIGHT_DETAILS, payload })
export const setInsightsReorderRecommended = (payload) => ({
  type: SET_INSIGHTS_REORDER_RECOMMENDED,
  payload,
})
export const setInsightsSuggestedOrder = (payload) => ({
  type: SET_INSIGHTS_SUGGESTED_ORDER,
  payload,
})
export const clearInsightEvents = () => ({ type: CLEAR_INSIGHT_EVENTS })

// State
export const initialState = {
  events: [],
}

// Reducer
export default function insightEventsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INSIGHT_EVENTS:
      return { ...state, events: [...state.events, ...action.payload] }
    case SET_INSIGHT_DETAILS:
      return { ...state, insightDetails: action.payload }
    case SET_INSIGHTS_REORDER_RECOMMENDED:
      return { ...state, reorderRecommended: action.payload }
    case SET_INSIGHTS_SUGGESTED_ORDER:
      return { ...state, suggestedOrder: action.payload }
    case CLEAR_INSIGHT_EVENTS:
      return initialState
    default:
      return state
  }
}
