import React from 'react'
import { ResponsiveLineCanvas } from '@nivo/line'

const LineChart = ({ data, isPercent, ...props }) => (
  <ResponsiveLineCanvas
    data={data}
    margin={{ top: 50, right: 50, bottom: 50, left: 70 }}
    xScale={{ type: 'point' }}
    yScale={{ type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false }}
    axisTop={null}
    axisLeft={null}
    axisRight={null}
    enableGridY={false}
    lineWidth={2}
    pointSize={8}
    pointColor={{ from: 'color', modifiers: [] }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor', modifiers: [] }}
    pointLabelYOffset={-12}
    useMesh
    enableCrosshair={false}
    motionConfig="stiff"
    animate={false}
    {...props}
  />
)

export default LineChart
