import React from 'react'

import Widget from '../../../components/widget/Widget'
import { ItemsByDateWidget } from '../widgets/ItemsByDateWidget'
import { ItemsByUserWidget } from '../widgets/ItemsByUserWidget'
import { ItemUsageWidget } from '../widgets/ItemUsageWidget'
import { ItemsByCallWidget } from '../widgets/ItemsByCallWidget'

export const PostCallSection = () => {
  return (
    <div data-testid="reports-postcall">
      <Widget
        widgetId="postcall-usage"
        label="Post Call Usage"
        subtitle="The count of each post call item trigger across selected agents"
        subtitlePercent="Average trigger rate of each post call item across selected agents"
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ItemUsageWidget section="postcall" />
      </Widget>
      <Widget
        widgetId="postcall-usageByDate"
        label="Post Call Frequency by Date"
        subtitle="Time series of the count of each post call item trigger across selected agents"
        subtitlePercent="Time series of average trigger rate of each post call item across selected agents"
        showGroupBy
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ItemsByDateWidget section="postcall" />
      </Widget>
      <Widget
        widgetId="postcall-usageByUser"
        label="Post Call Frequency by User"
        subtitle="The count of each post call item trigger for each selected agents"
        subtitlePercent="Average trigger rate of each post call item for each selected agents"
        showPercent
        showViewToggle
        showCsvDownload
        percentViewDefault={false}
      >
        <ItemsByUserWidget section="postcall" />
      </Widget>
      <Widget
        widgetId="postcall-wins"
        label="Post Call Wins"
        subtitle="The count of won calls of each post call item across selected agents"
        subtitlePercent="Average win rate of each post call item across selected agents"
        showPercent
        showViewToggle
        showCsvDownload
      >
        <ItemUsageWidget section="postcall" isWin />
      </Widget>
      <Widget
        widgetId="postcall-usageByCall"
        label="Post Call Usage By Call"
        subtitle="Matrix table of calls, where each row represents a call and each column is a post call item, with colored cells indicating usage"
      >
        <ItemsByCallWidget section="postcall" />
      </Widget>
    </div>
  )
}
