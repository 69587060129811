import React from 'react'
import { Grid } from 'semantic-ui-react'

export default function TagHeader(props) {
  return (
    <Grid className="category-header">
      <Grid.Row>
        <Grid.Column width={16}>
          <div className="ui segments">
            <div className="ui segment dark-knight">
              <h3>{props.title}</h3>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
