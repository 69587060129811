import { toast } from 'react-toastify'
import { sortBy } from 'lodash'
import moment from 'moment'
import queryString from 'query-string'

import { fetchingAPI, apiService } from '@/api'

import * as redux from './userSettings.redux'
import * as helpers from './userSettings.helpers'

export const fetchUserDashboardSettings = (dashboardType) => {
  return async (dispatch, getState) => {
    dispatch(redux.setLoading({ dashboardSettings: true }))

    const { user_id: userId } = getState().currentUser

    try {
      const response = await fetchingAPI(
        `${apiService.web}/api/reporting/users/${userId}/dashboard_settings/${dashboardType}`,
        'GET',
        dispatch
      )
      const sortedSettings = sortBy(response, 'order')

      dispatch(redux.setUserDashboardSettings({ data: sortedSettings, dashboardType }))
    } catch (err) {
      toast.error('Failed to fetch dashboard settings')
    } finally {
      dispatch(redux.setLoading({ dashboardSettings: false }))
    }
  }
}

export const upsertUserDashboardSettings = (dashboardType, dashboardSettings) => {
  return async (dispatch, getState) => {
    dispatch(redux.setLoading({ dashboardSettings: true }))

    const { user_id: userId } = getState().currentUser

    try {
      const body = JSON.stringify({ dashboard_settings: dashboardSettings })
      const response = await fetchingAPI(
        `${apiService.web}/api/reporting/users/${userId}/dashboard_settings/${dashboardType}`,
        'PUT',
        dispatch,
        body
      )

      const sortedDashboardSettings = sortBy(response, 'order')

      dispatch(redux.setUserDashboardSettings({ data: sortedDashboardSettings, dashboardType }))
    } catch (err) {
      toast.error('Failed to save dashboard settings')
    } finally {
      dispatch(redux.setLoading({ dashboardSettings: false }))
    }
  }
}

/* Helpers for adding/removing widgets from the dashboard */

// Only used the first time a widget is added, which creates a new DB entry
export const addNewWidget = (dashboardType, widgetToAdd) => {
  return async (dispatch, getState) => {
    const dashboardSettings = getState().userSettings.dashboardSettings[dashboardType]
    const updatedSettings = helpers.getSettingsAfterAddNewWidget(dashboardSettings, widgetToAdd)

    await upsertUserDashboardSettings(dashboardType, updatedSettings)(dispatch, getState)
  }
}
// Only used the first time a widget is added, which creates a new DB entry
export const dismissNewWidget = (dashboardType, widgetToDismiss) => {
  return async (dispatch, getState) => {
    const dashboardSettings = getState().userSettings.dashboardSettings[dashboardType]
    const updatedSettings = helpers.getSettingsAfterDismissNewWidget(
      dashboardSettings,
      widgetToDismiss
    )

    await upsertUserDashboardSettings(dashboardType, updatedSettings)(dispatch, getState)
  }
}

// Used for accepting a previously rejected widget. Must update the accepted flag on the existing DB entry
export const acceptWidget = (dashboardType, widgetToAdd) => {
  return async (dispatch, getState) => {
    const dashboardSettings = getState().userSettings.dashboardSettings[dashboardType]
    const updatedSettings = helpers.getSettingsAfterAcceptExistingWidget(
      dashboardSettings,
      widgetToAdd
    )

    await upsertUserDashboardSettings(dashboardType, updatedSettings)(dispatch, getState)
  }
}

// Used for rejecting a previously accepted widget. Must update the accepted flag on the existing DB entry
// and update all the orders for the remaining entries
export const removeWidget = (dashboardType, widgetToRemove) => {
  return async (dispatch, getState) => {
    const dashboardSettings = getState().userSettings.dashboardSettings[dashboardType]
    const updatedSettings = helpers.getSettingsAfterRemoveExistingWidget(
      dashboardSettings,
      widgetToRemove
    )

    await upsertUserDashboardSettings(dashboardType, updatedSettings)(dispatch, getState)
  }
}

export const fetchTrendsObjectives = () => {
  return async (dispatch) => {
    dispatch(redux.setLoading({ objectives: true }))

    try {
      const response = await fetchingAPI(
        `${apiService.reporting}/api/trends/objectives`,
        'GET',
        dispatch
      )

      dispatch(redux.setWidget({ objectives: response }))
    } catch (err) {
      console.error(err)
    } finally {
      dispatch(redux.setLoading({ objectives: false }))
    }
  }
}

export const fetchTrendsCategories = (objectiveId, dateRange) => {
  return async (dispatch) => {
    dispatch(redux.setLoadingCategories({ [objectiveId]: true }))

    let startDate = moment().subtract(1, 'year').startOf('day').toISOString()
    if (dateRange) {
      const [number, unit] = dateRange.split('_')
      startDate = moment()
        .subtract(number, unit)
        .startOf(unit.charAt(unit.length - 1))
        .toISOString()
    }

    try {
      const params = queryString.stringify({
        objective_id: objectiveId,
        start_date: startDate,
        end_date: moment().toISOString(),
      })

      const response = await fetchingAPI(
        `${apiService.reporting}/api/trends/categories?${params}`,
        'GET',
        dispatch
      )

      dispatch(redux.addCategory({ [objectiveId]: response }))
    } catch (err) {
      toast.error('Failed to fetch trending categories')
    } finally {
      dispatch(redux.setLoadingCategories({ [objectiveId]: false }))
    }
  }
}
