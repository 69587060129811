import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { AdvancedTablePagination } from '@/components/tables/AdvancedTable/AdvancedTablePagination'
import { formatTime, truncateString } from '@/utils/helpers'
import WidgetContext from '../../../components/widget/widget.context'
import WidgetContainer from '../../../components/widget/WidgetContainer'
import WidgetTooltip from '../../../components/widget/WidgetTooltip'
import BarChart from '../../../components/charts/BarChart'

export const CallsByUserWidget = () => {
  const { data, loading } = useSelector((state) => state.analytics)
  const { handleChangeCsvData } = useContext(WidgetContext)
  const [activePage, setActivePage] = useState(1)

  const rows = useMemo(() => {
    return data.callCountsByUser
      .sort((a, b) => a.last_name.localeCompare(b.last_name))
      .map((user) => {
        return { ...user, name: `${user.first_name} ${user.last_name}` }
      })
  }, [data])

  const columns = [
    { accessor: 'name', label: 'Name' },
    { accessor: 'email', label: 'Email' },
    {
      accessor: 'user_role',
      label: 'User Role',
      format: (v) => (v === 'agent' ? 'Agent' : 'Manager'),
    },
    {
      accessor: 'call_count',
      label: 'Total Call Count',
      format: (v) => v.toLocaleString(),
      headerAlignment: 'right',
    },
    {
      accessor: 'last_call',
      label: 'Last Activity',
      format: (v) => formatTime(v),
    },
    {
      accessor: 'last_login',
      label: 'Last Login',
      format: (v) => formatTime(v),
    },
  ]

  // Find pagination values
  const rowsPerPage = 20
  const count = rows.length
  const totalPages = Math.ceil(count / rowsPerPage)
  const paginatedRows = rows
    .slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)
    .reverse()

  useEffect(() => {
    handleChangeCsvData({ headers: columns, data: rows })
  }, [rows])

  useEffect(() => {
    setActivePage(1)
  }, [data])
  return (
    <WidgetContainer
      large
      loading={loading.usagePage}
      withPagination
      noData={isEmpty(rows)}
      table={
        <AdvancedTable
          index="name"
          defaultOrderBy="name"
          rows={rows}
          columns={columns}
          truncate
          rowsPerPage={10}
          pagination
          enableUpdateRowsPerPage={false}
        />
      }
      chart={
        <>
          <BarChart
            data={paginatedRows}
            keys={['call_count']}
            indexBy="name"
            layout="horizontal"
            enableGridY
            margin={{ bottom: 60, left: 150, right: 50, top: 10 }}
            tooltip={(item) => {
              return (
                <WidgetTooltip color={item.color}>
                  <h4>{item.indexValue}</h4>
                  <p>Call Count: {Number(item.value).toLocaleString()}</p>
                </WidgetTooltip>
              )
            }}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              padding: 0.5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Call Count',
              legendPosition: 'middle',
              legendOffset: 40,
              format: (v) => Number(v).toLocaleString(),
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: (v) => truncateString(v, 30),
            }}
          />
          <AdvancedTablePagination
            activePage={activePage}
            rowsPerPage={rowsPerPage}
            count={count}
            totalPages={totalPages}
            setActivePage={setActivePage}
          />
        </>
      }
    />
  )
}
