import React from 'react'

export const RankedProgressBar = ({
  color,
  width,
  value,
  format,
  hideValue = false,
  skinny = false,
}) => {
  return (
    <div
      className="ui primary progress progress_bar"
      data-testid="ranked-progress-bar"
      data-percent={width}
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        borderRadius: skinny ? '28px' : '6px',
        border: skinny ? '1px solid var(--border-color)' : 'none',
      }}
    >
      <div
        className="bar"
        style={{
          width: `${width}%`,
          backgroundColor: color,
          height: skinny ? '10px' : '1.5rem',
          borderRadius: skinny ? '28px' : '6px',
          minWidth: '1rem',
        }}
      />
      {!hideValue && <p className="progress_text">{format ? format(value) : value}</p>}
    </div>
  )
}
