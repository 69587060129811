import React from 'react'
import { Button, Grid, Icon } from 'semantic-ui-react'
import { useSelector } from 'react-redux'

import { Pill } from '@/components/pills/Pill'
import { NestedTable } from '@/components/tables/NestedTable/NestedTable'

import { sortCriteriaByPlaybookSections } from '../helpers/index'

import './ViewScorecardSummary.css'

const DefaultSectionSummary = ({ playbookSection, columns }) => {
  const rows = [
    {
      name: {
        value: playbookSection.sectionName,
        as: <h4>{playbookSection.sectionName}</h4>,
      },
      nestedField: 'criteria',
      criteria: playbookSection.criteria,
    },
  ]

  return <NestedTable columns={columns} rows={rows} basic noHeaders />
}

const PostcallSummary = ({ postcallCriteriaByCategory, columns }) => {
  if (!postcallCriteriaByCategory.criteria.length) return null

  const rows = [
    {
      name: {
        value: postcallCriteriaByCategory.sectionName,
        as: <h4>{postcallCriteriaByCategory.sectionName}</h4>,
      },
      nestedField: 'categories',
      categories: postcallCriteriaByCategory.criteria,
    },
  ]

  return <NestedTable columns={columns} rows={rows} basic noHeaders />
}

const DecklistSummary = ({ decklist, columns }) => {
  if (!decklist.criteria.length) return null

  const rows = [
    {
      name: {
        value: 'Dynamic Prompts',
        as: <h4>Dynamic Prompts</h4>,
      },
      nestedField: 'categories',
      categories: decklist.criteria,
    },
  ]

  return <NestedTable columns={columns} rows={rows} noHeaders basic />
}

export const MeasureSummary = ({ measure }) => {
  const { data } = useSelector((state) => state.scorecards)
  const { targetPlaybook } = data
  const criteriaByPlaybookSection = sortCriteriaByPlaybookSections(
    measure,
    targetPlaybook.body,
    true
  )

  const columns = [
    {
      label: 'Name',
      accessor: 'name',
    },
    {
      label: 'Present or Not?',
      accessor: 'must_be_present',
    },
  ]

  if (measure.condition_type === 'AND') {
    columns.push({
      label: 'Weight',
      accessor: 'weight',
    })
  }

  const tablesByPlaybookSection = {
    Deck: (decklist) => <DecklistSummary decklist={decklist} columns={columns} />,
    Postcall: (playbookSection) => (
      <PostcallSummary
        postcallCriteriaByCategory={playbookSection}
        conditionType={playbookSection.conditionType}
        columns={columns}
      />
    ),
    Checklist: (playbookSection) => (
      <DefaultSectionSummary playbookSection={playbookSection} columns={columns} />
    ),
    Notifications: (playbookSection) => (
      <DefaultSectionSummary playbookSection={playbookSection} columns={columns} />
    ),
    Manual: (playbookSection) => (
      <DefaultSectionSummary playbookSection={playbookSection} columns={columns} />
    ),
  }

  return (
    <Grid.Row className="scorecard-summary__measure-summary" data-testid="measure-summary">
      <div className="scorecard-summary__measure-summary-header">
        <h3>{measure.name}</h3>
        {measure.auto_fail && (
          <Pill small critical>
            Auto-fail
          </Pill>
        )}
        {measure.auto_zero && (
          <Pill small caution>
            Auto-zero
          </Pill>
        )}
      </div>
      <Grid>
        <Grid.Column>
          {criteriaByPlaybookSection.map((playbookSection) => {
            return tablesByPlaybookSection[playbookSection.sectionName](playbookSection)
          })}
        </Grid.Column>
      </Grid>
    </Grid.Row>
  )
}

export const EmptySection = () => {
  return (
    <Grid.Row className="scorecard-summary__empty-section" data-testid="empty-section">
      No items selected
    </Grid.Row>
  )
}

export const SectionSummary = ({ section }) => {
  const sectionHasCriteria = section.measures.some((measure) => measure.criteria.length > 0)
  if (!sectionHasCriteria) {
    return (
      <Grid.Row className="scorecard-summary__empty-section-container">
        <h2 className="scorecard-summary__section-title">{section.name}</h2>
        <EmptySection />
      </Grid.Row>
    )
  }

  return (
    <Grid.Row className="scorecard-summary__section-summary" data-testid="section-summary">
      <h2 className="scorecard-summary__section-title">{section.name}</h2>
      <Grid className="scorecard-summary__measure-container">
        <Grid.Column>
          {section.measures.map((measure) => {
            if (measure.criteria.length) {
              return <MeasureSummary measure={measure} key={measure.name} />
            }
            return null
          })}
        </Grid.Column>
      </Grid>
    </Grid.Row>
  )
}

export const ViewScorecardSummary = ({ scorecardConfig = {} }) => {
  return (
    <div>
      <Button className="scorecard-summary minimal-button secondary" onClick={() => window.print()}>
        <Icon name="print" />
        <h5>Print</h5>
      </Button>
      <Grid className="scorecard-summary" data-testid="view-scorecard-summary">
        <Grid.Column>
          {scorecardConfig.sections &&
            scorecardConfig.sections.map((section) => (
              <SectionSummary section={section} key={section.name} />
            ))}
        </Grid.Column>
      </Grid>
    </div>
  )
}
