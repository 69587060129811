import React, { Component } from 'react'
import { Loader, Dimmer } from 'semantic-ui-react'
import moment from 'moment'

export default class TemporaryLinkForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      formattedExpiration: undefined,
    }

    this.toggleLoading = this.toggleLoading.bind(this)
    this.updateTempLinkExpiration = this.updateTempLinkExpiration.bind(this)
  }

  componentDidMount() {
    const { user } = this.props
    const data = { ...user, is_new: !user.password }
    this.props.generateTemporaryLink(this.updateTempLinkExpiration, data)
  }

  toggleLoading() {
    this.setState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }))
  }

  updateTempLinkExpiration(expiration, link) {
    const formattedExpiration = moment(expiration).format('LLLL')
    this.setState((prevState) => ({ ...prevState, formattedExpiration, link }))
    this.toggleLoading()
  }

  render() {
    const { isLoading, formattedExpiration, link } = this.state
    const instructionMessage = `We've sent an email to ${this.props.user.username} that contains a password reset link.
    If they don't see the email from noreply@baltocloud.com in their inbox, please have them check their spam folder.`
    const expirationMessage = `This link will expire on ${formattedExpiration}`
    const loaderMessage = 'Preparing password reset link...'

    const loading = (
      <div className="ui center aligned basic segment">
        <Dimmer active inverted>
          <Loader active size="large" inline="centered">
            {loaderMessage}
          </Loader>
        </Dimmer>
      </div>
    )

    const loaded = (
      <>
        <h3>{instructionMessage}</h3>
        {link && <h4>You an also use this link to reset the password:</h4>}
        {link && <h4>{link}</h4>}
        <p>{expirationMessage}</p>
      </>
    )

    return <div className="ui center aligned basic segment">{isLoading ? loading : loaded}</div>
  }
}
