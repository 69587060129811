import React from 'react'
import { IconArrowsSort, IconSortAscending, IconSortDescending } from '@tabler/icons-react'
import classNames from 'classnames'

export const SortButton = ({
  label,
  dataType,
  handleClick,
  sortBy,
  asc,
  className,
  tagCategoryName,
  children,
  ...otherProps
}) => {
  const sorted = sortBy === dataType || sortBy === tagCategoryName
  let icon = <IconArrowsSort data-testid="no-sort" />

  if (sorted) {
    if (asc) {
      icon = <IconSortAscending data-testid="ascending" />
    } else {
      icon = <IconSortDescending data-testid="descending" />
    }
  }

  return (
    <button
      type="button"
      onClick={() => handleClick && handleClick(dataType, tagCategoryName)}
      className={classNames('sort-button', className)}
      {...otherProps}
    >
      {children}
      {icon}
    </button>
  )
}
