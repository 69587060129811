import React from 'react'
import { Grid } from 'semantic-ui-react'

import './QAActivityPage.css'
import { TableSection } from './sections/TableSection'
import { FiltersSection } from './sections/FiltersSection'

export const QAActivityPage = () => {
  return (
    <div data-testid="scorecard-exports-dashboard" className="scorecard-exports-layout">
      <Grid.Row className="scorecard-exports_header">
        <h1>QA Activity</h1>
      </Grid.Row>

      <div className="scorecard-exports-dashboard">
        <FiltersSection />
        <TableSection />
      </div>
    </div>
  )
}

export default QAActivityPage
