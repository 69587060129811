import React, { useState, createContext, useRef } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { WizardModal } from '@/components/layout/modals/WizardModal'
import { closeModal } from '@/reducers/ui/ui.redux'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { fetchAllRtcData } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'
import { genericSchema } from './AlertValidationSchema'
import { InfoPage } from './InfoPage'
import { PlaybookConfigPage } from './PlaybookConfigPage'

import './AlertWizard.css'
// Prop drilling is for 🟥s
export const AnimateTriggerItem = createContext(true)
// Wizard with all pages
export const AlertWizard = ({ handleSubmit, ...props }) => {
  const dispatch = useDispatch()
  const [alertName, updateAlertName] = useState(null)
  const title = `Create Alert${alertName ? ` ${alertName}` : ''}`
  const {
    user_id: currentUserId,
    first_name: firstName,
    last_name: lastName,
    organizationid: currentUserOrganizationId,
  } = useSelector((state) => state.currentUser)
  const {
    filters: { organization },
  } = useSelector((state) => state.realtimeCoaching)

  const isBaltoAdmin = currentUserOrganizationId === 1

  // Setting default values for Formik
  const initialValues = {
    displayedForm: 'infoPage',
    name: '',
    active: true,
    message: '',
    selectedUsers: [],
    selectedTags: [],
    triggerItems: [],
    alertsFor: null,
    triggerType: 'occurrence',
    audible: false,
    managers:
      organization === currentUserOrganizationId || !isBaltoAdmin
        ? [
            {
              key: currentUserId,
              label: `${firstName} ${lastName}`,
              value: currentUserId,
            },
          ]
        : [],
  }

  const shape = genericSchema
  const validationSchema = Yup.object().shape(shape)

  const pages = [
    (values, _goToPageNumber, _errors, setFieldValue) => (
      <InfoPage values={values} setFieldValue={setFieldValue} {...props} />
    ),
    (values, _goToPageNumber, _errors, setFieldValue) => (
      <PlaybookConfigPage values={values} setFieldValue={setFieldValue} {...props} />
    ),
  ]

  const resetRtcData = () => {
    dispatch(fetchAllRtcData(currentUserOrganizationId))
    dispatch(setFilter({ organization: currentUserOrganizationId }))
    dispatch(setFilter({ baltoAdminManager: currentUserId }))
  }

  const onSubmit = async (values) => {
    handleSubmit(values)
    dispatch(closeModal())
    resetRtcData()
  }

  const onPageChange = (values) => {
    // this is stored as an obj because of how WizardModal renders name
    updateAlertName(values.name)
  }
  const dirtyRef = useRef(null)
  const onClose = () => {
    if (dirtyRef.current.dirty) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to leave without saving?')) {
        dispatch(closeModal())
        resetRtcData()
      }
    } else {
      dispatch(closeModal())
      resetRtcData()
    }
  }

  return (
    <AnimateTriggerItem.Provider value data-testid="alert-wizard">
      <WizardModal
        pages={pages}
        isLoading={props.isLoading}
        initialValues={initialValues}
        validationSchema={validationSchema}
        title={title}
        onSave={onSubmit}
        onClose={onClose}
        onPageChange={onPageChange}
        innerRef={dirtyRef}
        alwaysEnablePreviousButton
        size="small"
      />
    </AnimateTriggerItem.Provider>
  )
}
