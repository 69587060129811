import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Form } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import './challengesFilters.scss'
import Select from '@/components/forms/Select'
import { getOrganizationOptions } from '@/utils/helpers'
import { isEmpty } from 'lodash'
// eslint-disable-next-line import/extensions
import {
  clearDataMinusOrgs,
  clearOrgFilter,
  setDateFilter,
  setFilter,
} from '@/reducers/gamification/gamification.redux'
import { DateRangePickerReusable } from '@/components/datePickers/DateRangePickerReusable'

import { MultiSelect } from '../../../../components/forms/MultiSelect'
import {
  applyChallengesFilters,
  fetchAgentsByOrg,
  fetchTagsByOrg,
} from '../../../../reducers/gamification/gamification.actions'

export const ChallengesFilters = ({ hide = {}, hideFilterButton, dateType = 'full' }) => {
  const dispatch = useDispatch()

  const { data, filters, loading } = useSelector((state) => state.gamification)
  const { organizations } = useSelector((state) => state)
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)

  const [selectedOrgId] = useState(organizationId)

  const isBaltoAdmin = organizationId === 1
  const organizationOptions = getOrganizationOptions(organizations)

  // ** INITIAL DATA LOAD **

  useEffect(() => {
    if (selectedOrgId) {
      dispatch(fetchAgentsByOrg(selectedOrgId))
      dispatch(fetchTagsByOrg(selectedOrgId))
      dispatch(setFilter('organizationId', selectedOrgId))
    }
  }, [selectedOrgId])

  const challengeTypeOptions = [
    {
      value: 'individual',
      label: 'Individual',
    },
    {
      value: 'leaderboard',
      label: 'Leaderboard',
    },
    {
      value: 'team vs team',
      label: 'Team vs Team',
    },
  ]

  const statusOptions = [
    {
      value: 'inactive',
      label: 'Not Scheduled',
    },
    {
      value: 'scheduled',
      label: 'Scheduled',
    },
    {
      value: 'active',
      label: 'In Progress',
    },
    {
      value: 'completed',
      label: 'Completed',
    },
    {
      value: 'cancelled',
      label: 'Cancelled',
    },
  ]

  // ** FORM HANDLERS **
  const handleFilterChange = (value) => {
    dispatch(setFilter(value))
  }

  const handleDateRangeFilterChange = (value) => {
    const formattedDate = {}
    formattedDate.startDate = moment(value.startDate).startOf('day').utc().format()
    formattedDate.endDate = moment(value.endDate).endOf('day').utc().format()
    dispatch(setDateFilter(formattedDate))
  }

  const handleDateRangeClear = () => {
    dispatch(setDateFilter({ startDate: null, endDate: null }))
  }

  const handleAgentsSelect = (agentOptions) => {
    dispatch(setFilter('agents', agentOptions))
  }
  const handleChallengeTypeSelect = (typeOptions) => {
    dispatch(setFilter('challengeType', typeOptions))
  }
  const handleStatusSelect = (challengeStatusOptions) => {
    dispatch(setFilter('challengeStatus', challengeStatusOptions))
  }

  const handleOrgSelect = (option, action) => {
    dispatch(clearOrgFilter())
    dispatch(clearDataMinusOrgs())
    if (action.action === 'clear') {
      dispatch(setFilter('organizationId', ''))
    } else {
      const orgSelected = option.value
      handleFilterChange({ organizationId: orgSelected })
      dispatch(setFilter('organizationId', orgSelected))
      dispatch(fetchTagsByOrg(orgSelected))
      dispatch(fetchAgentsByOrg(orgSelected))
    }
  }
  const handleFilterSubmit = (orgId) => {
    dispatch(applyChallengesFilters(orgId))
  }
  const removeDateRange = () => {
    const formattedDate = {}
    formattedDate.startDate = moment().startOf('day').utc().format()
    formattedDate.endDate = moment().startOf('day').utc().format()
    dispatch(setDateFilter(formattedDate))
  }
  const isLoading = loading.challengeTemplates

  const filterIsDisabled = () => {
    // Disable button while loading
    if (isLoading) {
      return true
    }
    // Disable button if organization is not selected for admin
    return isBaltoAdmin && !filters.organizationId
  }
  return (
    <Form
      className="filter-form challenges-filters"
      data-testid="challenges-filters-form"
      onSubmit={() => {
        handleFilterSubmit(filters.organizationId)
      }}
    >
      <div className="filter-grid">
        {!hide.organizations && isBaltoAdmin && (
          <Form.Field data-testid="org-picker">
            <label>Organization</label>
            <Select
              placeholder="Select Organization"
              options={organizationOptions}
              loading={isEmpty(organizations)}
              value={filters.organizationId || selectedOrgId}
              onChange={(option, action) => handleOrgSelect(option, action)}
              fixedWidth
            />
          </Form.Field>
        )}
        {!hide.dateRange && dateType === 'full' && (
          <Form.Field data-testid="date-range-field" className="form-field">
            <label>Date Range</label>
            <DateRangePickerReusable
              startDate={filters.startDate}
              endDate={filters.endDate}
              allowFutureDates
              displayClearButton
              onClear={handleDateRangeClear}
              onCancel={() => removeDateRange()}
              onChange={({ selection }) => {
                handleDateRangeFilterChange(selection)
              }}
            />
          </Form.Field>
        )}
        {!hide.agents && (
          <Form.Field data-testid="agents-field" className="form-field">
            <label>Agents</label>
            <div data-testid="agent-picker">
              <MultiSelect
                isSelectAll
                placeholderPill="All Agents"
                label="Agent"
                resource="Agents"
                options={data.agents}
                loading={loading.agents}
                value={filters.agents}
                onChange={(value) => handleAgentsSelect(value)}
                fixedWidth
              />
            </div>
          </Form.Field>
        )}
        {!hide.challengeTypes && (
          <Form.Field className="form-field" data-testid="challenge-type-field">
            <label className="challenge-type-form-label" id="challege-type">
              Challenge Type
            </label>
            <div data-testid="challenge-type-picker">
              <MultiSelect
                isSelectAll
                placeholderPill="All Challenge Types"
                options={challengeTypeOptions}
                allowSelectAll
                aria-labelledby="challenge-types"
                loading={loading.challengeType}
                value={filters.challengeType}
                onChange={(value) => handleChallengeTypeSelect(value)}
                fixedWidth
              />
            </div>
          </Form.Field>
        )}
        {!hide.challengeStatus && (
          <Form.Field className="form-field" data-testid="challenge-status-form-field">
            <label className="challenge-status-form-label" id="challenge-status">
              Status
            </label>
            <div data-testid="challenge-status-picker">
              <MultiSelect
                isSelectAll
                placeholderPill="All Statuses"
                options={statusOptions}
                allowSelectAll
                aria-labelledby="challenge-status"
                loading={loading.challengeStatus}
                value={filters.challengeStatus}
                onChange={(value) => handleStatusSelect(value)}
              />
            </div>
          </Form.Field>
        )}
      </div>
      {!hideFilterButton && (
        <Form.Field className="filter-buttons">
          <label className="visibility-hidden">&nbsp;</label>
          <div>
            <Button primary type="submit" loading={isLoading} disabled={filterIsDisabled()}>
              Filter
            </Button>
          </div>
        </Form.Field>
      )}
    </Form>
  )
}
