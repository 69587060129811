import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { DrillDownReports } from '@/views/RealtimeCoaching/Reports/DrillDownReports/DrillDownReports'
import { AdvancedExports } from '@/views/RealtimeCoaching/AdvancedExports/AdvancedExports'
import { EditAlertPage } from '@/views/RealtimeCoaching/Alerts/EditAlertPage/EditAlertPage'
import { fetchAllRtcData } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'
import { ActivityLog } from './ActivityLog/ActivityLog'
import Reports from './Reports/Reports'
import { Alerts } from './Alerts/Alerts'

const RealtimeCoaching = () => {
  const dispatch = useDispatch()
  const { organizationid } = useSelector((state) => state.currentUser)
  const { organization: filteredOrgId } = useSelector((state) => state.realtimeCoaching.filters)

  useEffect(() => {
    if (organizationid === 1) {
      dispatch(fetchAllRtcData(filteredOrgId || organizationid))
    } else {
      dispatch(fetchAllRtcData(organizationid))
    }
  }, [])

  const { section, subsection, alert_config_id: alertConfigIdStr } = useParams()
  const drilldownReportTypes = [
    'agent_reports',
    'tag_reports',
    'alert_reports',
    'chat_reports',
    'listen_reports',
  ]

  return (
    <div data-testid="RealtimeCoaching-page">
      {section === 'alerts' && <Alerts />}
      {alertConfigIdStr && <EditAlertPage alertConfigIdStr={alertConfigIdStr} />}
      {section === 'reports' && <Reports />}
      {section === 'activity-log' && <ActivityLog />}
      {subsection && drilldownReportTypes.includes(subsection) && <DrillDownReports />}
      {section === 'exports' && <AdvancedExports />}
    </div>
  )
}

export default RealtimeCoaching
