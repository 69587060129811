import React, { useState } from 'react'
import moment from 'moment'
import './CallCard.css'
import uuid from 'uuid/v4'
import { CallCardHeader } from './CallCardHeader'
import { CallInteractionTimeline } from '../CallInteractionTimeline/CallInteractionTimeline'
import { AlertInstanceTable } from '../AlertInstanceTable'
import { AlertCountTable } from '../AlertCountTable'

export const CallCard = (props) => {
  const { call = {} } = props
  const {
    id: callId,
    voip_id: voipId,
    config_name: playbookName,
    alerts = [],
    analysis = [],
    chats = [],
    listens = [],
  } = call
  const callEndTimeMoment = moment.utc(call.call_end_time)
  const callStartTimeMoment = moment.utc(call.call_start_time)
  const callDuration = callEndTimeMoment - callStartTimeMoment
  const getFilteredAlertTypeCount = (alertType) =>
    alerts.filter(({ type }) => type === alertType).length
  // TODO: could just be maps here spread into new array
  // TODO: section name needs to be formatted ie.
  // capitalized, classified_postcall -> PostCall, requirement_missed to actual section
  const eventHumps = [...alerts]
  analysis.forEach((analysisEvent) => {
    eventHumps.push({
      id: analysisEvent.id,
      type: 'playbook',
      time_seconds: analysisEvent.time,
      name: analysisEvent.subcategory,
      section: analysisEvent.context.split(' - ')[0],
      created_at: call.call_start_time,
    })
  })

  const interactions = []
  if (chats) {
    chats.forEach((chatLine) => {
      interactions.push({
        // TODO: is there not a chat id we could use instead?
        interaction_id: uuid(),
        type: 'chat',
        time_seconds: (moment.utc(chatLine.created_at) - callStartTimeMoment) / 1000,
        description: chatLine.message,
      })
    })
  }

  if (listens) {
    listens.forEach((listenEvent) => {
      interactions.push({
        // TODO: is there not a listen id we could use instead?
        interaction_id: uuid(),
        type: 'start-listen',
        time_seconds: (moment.utc(listenEvent.start_listen) - callStartTimeMoment) / 1000,
        description: 'Listen Event',
      })
      interactions.push({
        interaction_id: uuid(),
        type: 'stop-listen',
        time_seconds: (moment.utc(listenEvent.stop_listen) - callStartTimeMoment) / 1000,
        description: 'Stopped Listening',
      })
    })
  }

  const [showAlertDetails, setShowAlertDetails] = useState(false)

  return (
    <>
      <CallCardHeader
        callId={callId}
        voipId={voipId}
        playbookName={playbookName}
        callDuration={callDuration}
        callStartTime={callStartTimeMoment}
        negativeAlerts={getFilteredAlertTypeCount('negative')}
        positiveAlerts={getFilteredAlertTypeCount('positive')}
        informativeAlerts={getFilteredAlertTypeCount('informative')}
      />
      <div className="accordion-line" />
      <CallInteractionTimeline
        alerts={eventHumps}
        interactions={interactions}
        call_duration_seconds={callDuration / 1000}
      />

      <div
        className={showAlertDetails ? 'alert-tables-container' : 'alert-tables-container-hidden'}
        data-testid={showAlertDetails ? 'alert-tables-container' : 'alert-tables-container-hidden'}
      >
        <AlertInstanceTable rows={alerts} />
        <AlertCountTable rows={alerts} />
      </div>
      <div className="accordion-line" />
      <div className="call-card-show-alert-details" data-testid="call-card-expandable-container">
        {showAlertDetails ? (
          <a
            onClick={() => {
              setShowAlertDetails(false)
            }}
            className="button tertiary"
            data-testid="show-details-button"
          >
            Hide Alert Details
          </a>
        ) : (
          <a
            onClick={() => {
              setShowAlertDetails(true)
            }}
            className="button tertiary"
            data-testid="show-details-button"
          >
            Show Alert Details
          </a>
        )}
      </div>
    </>
  )
}
