import React from 'react'
import { Icon } from 'semantic-ui-react'
import { isEmpty, size } from 'lodash'
import { IconAlertTriangle } from '@tabler/icons-react'
import uuid from 'uuid/v4'
import pluralize from 'pluralize'

import { Banner } from '@/components/banners/Banner'

import { accessorSectionMap } from '../playbook.helpers'

export const ValidatingEntries = () => {
  return (
    <div className="flex-align-center small-gap">
      <Icon
        loading
        name="asterisk"
        style={{
          alignSelf: 'center',
          margin: '0px !important',
          padding: '0px !important',
        }}
      />
      <strong>Validating entries...</strong>
    </div>
  )
}

export const SectionWarnings = ({ section, warnings, loading, ...props }) => {
  const accessor = accessorSectionMap[section]

  if (loading && isEmpty(warnings[accessor])) {
    return (
      <div style={{ marginTop: '1rem' }}>
        <ValidatingEntries />
      </div>
    )
  }

  if (isEmpty(warnings[accessor])) {
    return null
  }

  const { section_suggestions, ...entryWarnings } = warnings[accessor]

  if (isEmpty(entryWarnings) && isEmpty(section_suggestions)) {
    return null
  }

  return (
    <Banner warning {...props}>
      {loading && (
        <div className="mb">
          <ValidatingEntries />
        </div>
      )}
      {!isEmpty(section_suggestions) &&
        section_suggestions.map((warning) => (
          <div className="playbook-warning" key={uuid()}>
            <IconAlertTriangle className="icon-svg" />
            <div>
              <strong>{warning.headline}:</strong> <span>{warning.detail}</span>
            </div>
          </div>
        ))}
      {!isEmpty(entryWarnings) && (
        <div className="playbook-warning">
          <IconAlertTriangle className="icon-svg" />
          <div>
            <strong>{pluralize('item', size(entryWarnings), true)} with issues:</strong>{' '}
            <span>{`Hover over each item's warning sign to see what's wrong.`}</span>
          </div>
        </div>
      )}
    </Banner>
  )
}
