import React from 'react'
import { Popup } from 'semantic-ui-react'

export const FilterTypePopup = ({ open, setOpen, trigger, children }) => {
  return (
    <Popup
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      className="filter-pill__popup"
      on="click"
      position="top left"
      trigger={<div>{trigger}</div>}
    >
      {children}
    </Popup>
  )
}
