/* eslint-disable react/no-danger */
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Divider } from 'semantic-ui-react'
import { Formik } from 'formik'
import { toArray, isEmpty } from 'lodash'

import { useQuery } from '@/utils/hooks/useQuery'

import {
  categoryEntryInitialValues,
  categoryEntryValidationSchema,
  modifyExistingCategoryEntryInitialValues,
} from './categoryEntry.schema'
import { CategoryEntryForm } from './CategoryEntryForm'
import { PlaybookPreview } from '../../components/PlaybookPreview'
import { PlaybookEditor } from '../../components/PlaybookEditor'
import { ChecklistEntryPreview } from '../checklist/ChecklistEntryPreview'
import { accessors, accessorSectionMap } from '../../playbook.helpers'

export const CategoryEntryEditor = () => {
  const { entryId, section } = useParams()
  const { playbook } = useSelector((state) => state.playbook)
  const accessor = accessorSectionMap[section]
  const query = useQuery()
  const isCreate = entryId === 'create'
  const categoryId = query.get('category_id')
  const recommendation = query.get('recommendation')
  const categoryName = playbook.body[accessor]?.entries[categoryId]?.name
  const entry = isCreate ? null : playbook.body[accessor]?.entries[categoryId]?.entries[entryId]

  return (
    <PlaybookEditor entry={entry} categoryId={categoryId} hasCategory>
      {({ handleUpdate, handleCancel, readOnly }) => {
        const initialValues = isCreate
          ? categoryEntryInitialValues(accessor)
          : modifyExistingCategoryEntryInitialValues(accessor)(entry)

        return (
          <Formik
            initialValues={initialValues}
            validationSchema={categoryEntryValidationSchema(accessor)}
            onSubmit={handleUpdate}
            enableReinitialize
          >
            {({ values, handleSubmit, errors, ...formikProps }) => (
              <>
                <CategoryEntryForm
                  readOnly={readOnly}
                  values={values}
                  errors={errors}
                  entryId={entryId}
                  handleSubmit={handleSubmit}
                  handleCancel={handleCancel}
                  accessor={accessor}
                  categoryName={categoryName}
                  recommendation={recommendation}
                  {...formikProps}
                />
                {accessor === accessors.DYNAMIC_PROMPT && (
                  <PlaybookPreview values={values} section={section}>
                    {values.display.header && <h3>{values.display.header}</h3>}
                    {values.display.decklist?.order && values.display.decklist?.entries && (
                      <ul>
                        {toArray(values.display.decklist.order).map((existingEntryId) => {
                          const checklistEntry = values.display.decklist.entries[existingEntryId]

                          if (checklistEntry) {
                            return (
                              <ChecklistEntryPreview
                                key={existingEntryId}
                                isEditEntry
                                entry={checklistEntry}
                                accessor={accessor}
                                inlineVerbatim
                              />
                            )
                          }

                          return null
                        })}
                      </ul>
                    )}
                    {values.display?.footer && <h3>{values.display.footer}</h3>}
                    {values.display?.html && !isEmpty(values.display?.decklist?.order) && (
                      <Divider />
                    )}
                    {values.display?.html && (
                      <div dangerouslySetInnerHTML={{ __html: values.display.html }} />
                    )}
                  </PlaybookPreview>
                )}
              </>
            )}
          </Formik>
        )
      }}
    </PlaybookEditor>
  )
}
