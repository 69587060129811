import React, { useEffect, useState } from 'react'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { Form, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { fetchAgentsByOrg } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'

export const AgentsDropdown = ({ disabledByTags = false, label = 'Agents' }) => {
  const dispatch = useDispatch()
  const [agentSearchQuery, setAgentSearchQuery] = useState('')
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const { data, filters, loading } = useSelector((state) => state.realtimeCoaching)
  const isDropdownDisabled = disabledByTags && !isEmpty(filters.tags)

  useEffect(() => {
    if (isEmpty(data.agents)) {
      dispatch(fetchAgentsByOrg(organizationId))
    }
  }, [])

  return (
    <Form.Field data-testid="rtc-agents-dropdown">
      <label htmlFor="rtc-filter-agents">{label}</label>
      <Popup
        content="Agents and Tags cannot be selected at the same time"
        position="top center"
        disabled={!isDropdownDisabled}
        trigger={
          <div>
            <MultiSelect
              isSelectAll
              placeholderPill="All Agents"
              inputId="rtc-filter-agents"
              options={!agentSearchQuery && data.agents.length > 1000 ? [] : data.agents}
              optionsLength={data.agents.length}
              loading={loading.agents}
              value={filters.agents}
              onInputChange={(inputValue) => setAgentSearchQuery(inputValue)}
              onChange={(value) => dispatch(setFilter({ agents: value }))}
              noOptionsMessage={() =>
                agentSearchQuery ? 'No results found' : 'Begin typing to search...'
              }
              disabled={isDropdownDisabled}
            />
          </div>
        }
      />
    </Form.Field>
  )
}
