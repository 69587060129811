export const buildPrompt = (values) => {
  const promptHeadline = `This is a transcript of a call between an agent and a customer. I would like you to summarize it and give me ${values?.length} things back.\n\n`
  const promptBody = values
    .map((field) => {
      // regex below removes all newlines in the value field and replaces them with spaces, then trims the input
      return `${field.key}: ${field.value.replace(/\r?\n|\r/g, ' ').trim()}\n`
    })
    .join('')
  const promptFooter = '\nhere is the transcript: $transcript'
  return `${promptHeadline}${promptBody}${promptFooter}`
}
