import React, { useState, useMemo } from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import classNames from 'classnames'
import { cloneDeep, isEmpty } from 'lodash'
import { IconX } from '@tabler/icons-react'

import { getNestedPlaceholderText } from '@/reducers/callSearch/helpers'

import { ReactSelectChevron } from '../icons/ReactSelectChevron'

import './NestedSelect.scss'

export const NestedSelect = (props) => {
  const { options, value: selectedEntries, section: accessor, includes, disabled } = props
  const [controlsVisible, setControlsVisible] = useState(false)
  const [toolTipVisible, setToolTipVisible] = useState(false)
  const [search, setSearch] = useState('')
  // important we don't do this every render
  const filteredOptions = useMemo(() => {
    const safeOptions = cloneDeep(options)
    return safeOptions?.filter((option) => {
      if (option.section === 'category') {
        const validChildren = option.children.filter((nestedEntry) => {
          if (nestedEntry.children) {
            const filteredDecklists = nestedEntry.children.filter((decklist) => {
              return decklist.value.toLowerCase().includes(search.toLowerCase())
            })
            nestedEntry.children = filteredDecklists
            return filteredDecklists.length
          }
          return nestedEntry.value.toLowerCase().includes(search.toLowerCase())
        })
        // mutation isn't great which is why we're cloning the options
        option.children = validChildren
        return validChildren.length
      }
      return option.value.toLowerCase().includes(search.toLowerCase())
    })
  }, [options, search])
  const showPill = Boolean(!controlsVisible && options?.length && selectedEntries?.length)
  const toggleIncludes = (event) => {
    // prevents it from being treated as "submit"
    event.preventDefault()
    event.stopPropagation()
    props.toggleIncludes(accessor)
  }

  const showControls = () => {
    setControlsVisible(true)
  }

  const hideControls = () => {
    setControlsVisible(false)
  }

  const resetSelectedEntries = (event) => {
    event.preventDefault()
    event.stopPropagation()
    props.clearAll(accessor)
  }

  const onChange = (selectedNode, selectedNodes) => {
    const { section } = selectedNode
    if (section === 'category') {
      props.toggleCategory(selectedNode, selectedNodes)
    } else if (section === 'subcategory') {
      props.toggleEntry(selectedNode, selectedNodes)
    } else if (section === 'decklist') {
      props.toggleEntry(selectedNode, selectedNodes)
    } else {
      console.error('Invalid section provided')
    }
  }
  const onNodeToggle = (currentNode) => {
    const { section } = currentNode

    if (section === 'category') {
      props.toggleCategory(currentNode)
    }
  }

  const handleMouseTooltip = (visible) => {
    if (controlsVisible) {
      return
    }
    setToolTipVisible(visible)
  }
  const totalEntries = selectedEntries.length
  const optionsEmpty = isEmpty(options)

  const includesClasses = classNames('occurs', { not: !includes })
  const dropdownClasses = classNames('', { active: controlsVisible, selected: totalEntries > 0 })
  const chevronClasses = classNames('chevron', { active: controlsVisible })
  const tooltipClasses = classNames('nested-select-tooltip', { long: optionsEmpty })
  const isDisabled = disabled || optionsEmpty

  return (
    <div
      data-testid={`nested-select-container-${accessor}`}
      onMouseEnter={() => handleMouseTooltip(true)}
      onMouseLeave={() => handleMouseTooltip(false)}
      style={{ marginTop: controlsVisible ? '-41px' : null, position: 'relative' }}
    >
      <ReactSelectChevron
        onClick={() => {
          if (!isDisabled) {
            setControlsVisible(!controlsVisible)
          }
        }}
        data-testid={`nested-select-chevron-${accessor}`}
        className={chevronClasses}
      />
      {controlsVisible && (
        <>
          <div className="nested-select-overlay" onClick={() => setControlsVisible(false)} />
          <div data-testid={`nested-select-controls-${accessor}`} className="controls">
            <button
              data-testid={`nested-select-controls-occurs-${accessor}`}
              onClick={toggleIncludes}
              className={includesClasses}
              type="button"
            >
              {includes ? 'Occurs' : 'Does Not Occur'}
            </button>
            <div className="selection-container">
              <span
                data-testid={`nested-select-controls-selected-count-${accessor}`}
              >{`${totalEntries} selected`}</span>
              <a
                data-testid={`nested-select-controls-clear-${accessor}`}
                onClick={resetSelectedEntries}
              >
                Clear
              </a>
            </div>
          </div>
        </>
      )}
      {showPill && (
        <div className="placeholder">
          <div data-testid={`nested-select-pill-${accessor}`} className="nested-select-pill">
            {`${totalEntries} selected`}
          </div>
          <IconX
            onClick={resetSelectedEntries}
            className="nested-select-clear-x"
            data-testid={`nested-select-clear-x-${accessor}`}
          />
        </div>
      )}
      {isEmpty(filteredOptions) && controlsVisible && (
        <div
          className="nested-select-no-options"
          data-testid={`nested-select-no-options-${accessor}`}
        >
          No Options
        </div>
      )}
      {toolTipVisible && isDisabled && (
        <div className={tooltipClasses} data-testid={`nested-select-tooltip-${accessor}`}>
          <div data-testid={`nested-select-tooltip-content-${accessor}`}>
            {disabled
              ? 'Please select one playbook'
              : 'No calls made with this playbook during the selected date range'}
          </div>
          <div className="nested-select-tail-1" />
          <div className="nested-select-tail-2" />
        </div>
      )}
      <input
        className="nested-select-input"
        onFocus={() => setControlsVisible(true)}
        onChange={(event) => setSearch(event.target.value)}
        value={search}
        placeholder={getNestedPlaceholderText(isDisabled, controlsVisible, showPill)}
        disabled={isDisabled}
      />
      {controlsVisible && (
        <DropdownTreeSelect
          data-testid={`nested-select-${accessor}`}
          className={dropdownClasses}
          data={filteredOptions}
          showDropdown="initial"
          disabled={isDisabled}
          onChange={onChange}
          onNodeToggle={onNodeToggle}
          onFocus={showControls}
          onBlur={hideControls}
        />
      )}
    </div>
  )
}
