import React from 'react'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { IconMail } from '@tabler/icons-react'

import { TextField } from '@/components/forms/formik'
import { sendEmailToAssignee } from '@/reducers/qa-copilot/qa-copilot.actions'

export const QACopilotEmailAssignmentForm = ({ row, callExplorerLink, dateRange, onClose }) => {
  const dispatch = useDispatch()
  const { username } = useSelector((state) => state.currentUser)

  const handleAssignEmail = (values) => {
    const data = {
      to_email: values.email,
      call_explorer_link: `${window.location.origin}${callExplorerLink}`,
      date_range: dateRange,
      criteria_name: row.top_missed_criteria.criteria_name,
      failing_call_count: row.top_missed_criteria.failing_call_count,
      call_count: row.top_missed_criteria.call_count,
      agent_first_name: row.first_name,
      agent_last_name: row.last_name,
      assigner_email: username,
    }

    dispatch(sendEmailToAssignee(data))
    onClose()
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email cannot be empty'),
  })

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleAssignEmail}
    >
      {({ errors, handleSubmit }) => (
        <Form data-test-id="email-assignment-form" onSubmit={handleSubmit}>
          <div className="overview-icon overview-icon--blurple">
            <IconMail className="icon-svg" />
          </div>
          <h3 style={{ marginTop: '1rem' }}>Assign Coaching Opportunity</h3>
          <p>
            Enter a valid email address to assign this coaching task. The assignee will receive
            detailed context and a direct link to the relevant evidence calls upon logging into
            their Balto account.
          </p>
          <Field
            required
            label="Email"
            name="email"
            placeholder="email@example.com"
            component={TextField}
            type="text"
          />
          <Button type="submit" primary className="full-width" disabled={!isEmpty(errors)}>
            Assign
          </Button>
        </Form>
      )}
    </Formik>
  )
}
