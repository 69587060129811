import React from 'react'
import { CircleIcon } from '../../../../components/icons/AlertIcon'

export const PillBanner = ({ alertCount, color, labelText }) => {
  return (
    <header className="alert-info_label">
      <CircleIcon color={alertCount > 0 ? color : 'grey'} />
      {`${alertCount} ${labelText}`}
    </header>
  )
}
