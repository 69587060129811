import React from 'react'

import './DashboardGrid.scss'

export const DashboardGrid = ({ children }) => {
  return (
    <div className="dashboard-grid-outer">
      <div className="dashboard-grid">{children}</div>
    </div>
  )
}
