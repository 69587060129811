import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import pluralize from 'pluralize'
import { kebabCase } from 'lodash'
import classNames from 'classnames'

import { createEntry, reorderEntry } from '@/reducers/playbooks/playbook.redux'
import { Drag, Drop, DragAndDrop } from '@/components/dragDrop'
import { reorder } from '@/utils/helpers'

import { accessorSectionMap } from '../../playbook.helpers'
import { PlaybookInlineEntry } from '../../components/PlaybookInlineEntry'

export const CategoriesSidebarDisplay = () => {
  const dispatch = useDispatch()
  const { section } = useParams()
  const {
    playbook,
    playbook: { current: isLatestPlaybookVersion },
  } = useSelector((state) => state.playbook)
  const { edit_config } = useSelector((state) => state.currentUser)
  const accessor = accessorSectionMap[section]
  const categories = playbook.body[accessor]
  const readOnly = !isLatestPlaybookVersion || !edit_config

  const handleReorder = (result) => {
    const { type, source, destination } = result

    if (!destination) return

    if (type === 'droppable-sidebar-category') {
      const originalOrder = categories.order
      const newOrder = reorder(originalOrder, source.index, destination.index)

      dispatch(reorderEntry(accessor)(newOrder))
    }
  }

  return (
    <div className="playbook-categories-sidebar">
      <h2>Categories</h2>
      <div className={classNames('playbook-categories-droppable', { 'read-only': readOnly })}>
        <DragAndDrop onDragEnd={handleReorder}>
          <Drop droppableId="droppable" type="droppable-sidebar-category">
            {categories.order.map((categoryId, categoryIndex) => {
              const category = categories.entries[categoryId]

              return (
                <Drag
                  key={categoryId}
                  draggableId={categoryId}
                  index={categoryIndex}
                  alwaysShowIcon
                  readOnly={readOnly}
                  showDragStyle
                >
                  <div className="playbook-category-row">
                    <div className="playbook-category-row__name">
                      <a href={`#${kebabCase(category.name)}`}>{category.name}</a>
                    </div>
                    <div className="playbook-category-row__count">{`${
                      category.order.length
                    } ${pluralize('item', category.order.length)}`}</div>
                  </div>
                </Drag>
              )
            })}
          </Drop>
        </DragAndDrop>
      </div>

      {!readOnly && (
        <PlaybookInlineEntry
          createEntry={createEntry(accessor)}
          label="Add Category"
          type="category"
          useIcons
        />
      )}
    </div>
  )
}
