import React from 'react'

import Form from '@/components/Modal'
import ConfirmForm from '@/components/forms/ConfirmForm'

export const DeleteOrganizationButton = ({ deleteOrganization }) => {
  return (
    <div className="ui segment">
      <h3>Delete Organization</h3>
      <Form
        header="Delete Organization"
        buttonLabel="Delete"
        buttonId="delete-organization-button"
        buttonProps={{ color: 'red' }}
        modalSize="tiny"
        form={
          <ConfirmForm
            bodyText="Are you sure you want to delete this organization? You will lose all users. This cannot be undone."
            primaryButtonText="Delete"
            handleSubmit={deleteOrganization}
            danger
          />
        }
      />
    </div>
  )
}
