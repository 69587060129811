import React from 'react'
import { useDropzone } from 'react-dropzone'
import { Form } from 'semantic-ui-react'
import { IconCloudUpload } from '@tabler/icons-react'
import { toast } from 'react-toastify'

import { FilePreview } from './FilePreview'

import './Dropzone.scss'

export const DragAndDropFileUploader = ({
  name,
  label,
  maxSize = 1048576,
  acceptedFileTypes = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/svg+xml': ['.svg'],
  },
  acceptedFileMessage = 'JPG, PNG, SVG. Max file size: 1MB',
  dark = false,
  existingValue,
  imageCache,
  ...formikProps
}) => {
  const value = formikProps.values[name]
  const existingUrlWithCache = `${existingValue}?${imageCache}`

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedFileTypes,
    maxFiles: 1,
    multiple: false,
    maxSize,
    onDropAccepted: (acceptedFiles) => {
      formikProps.setFieldValue(name, acceptedFiles)
    },
    onDropRejected: (err) => {
      const errorMessage = err[0]?.errors[0]?.message

      toast.error(errorMessage || 'File not accepted')
    },
    onError: () => {
      toast.error('Something went wrong')
    },
  })
  return (
    <Form.Field>
      <label>{label}</label>
      <div data-testid={`${name}-file-uploader`} {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="dropzone-container is-drag-active">
            <h2>Drop file here...</h2>
          </div>
        ) : (
          <div className="dropzone-container">
            {value ? (
              <FilePreview
                name={name}
                existingValue={existingValue}
                file={value[0]}
                formikProps={formikProps}
                dark={dark}
              />
            ) : existingValue ? (
              <img src={existingUrlWithCache} alt={name} className="preview-image" />
            ) : (
              <>
                <IconCloudUpload className="drop-icon" />
                <h2>Drag and Drop</h2>
                <p>Select a file or drop one here.</p>
              </>
            )}
            <div className="ui button secondary" type="button">
              Choose File
            </div>
            <p>
              <small>{acceptedFileMessage}</small>
            </p>
          </div>
        )}
      </div>
    </Form.Field>
  )
}
