import React from 'react'
import uuid from 'uuid/v4'
import { DragDropContext } from 'react-beautiful-dnd'
import { Button, Form, Grid } from 'semantic-ui-react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash/lang'
import * as Yup from 'yup'
import { closeModal } from '../../../reducers/ui/ui.redux'
import './editSectionsForm.css'
import { EditMeasuresRow } from './EditMeasuresRow'
import { handleDrag } from '../helpers/index'
import { ModalFields } from './ModalFields'
import { measuresSchema } from './scorecardConfig.schema'

export const EditMeasuresForm = ({
  measures = [],
  handleSubmit,
  updateMeasuresNow,
  removeFromObject,
  updateStatusInObject,
  updateNameInObject,
  addNewMeasure,
}) => {
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.scorecards)
  const onSubmit = async (values) => {
    await handleSubmit(values)
  }

  const handleAddMeasure = (push, items) => {
    const scorecardSectionId = items[0]?.scorecard_section_id
    const newMeasure = {
      name: '',
      active: true,
      deleted: false,
      scorecard_section_id: scorecardSectionId,
      order_id: items.length,
      id: uuid(),
    }
    addNewMeasure(newMeasure)
  }

  const onDragEnd = async (result) => {
    const { destination, source } = result
    if (!destination) {
      return
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }
    const updateMeasures = handleDrag(result, measures)
    await updateMeasuresNow(updateMeasures)
  }

  const shape = Yup.object().shape(measuresSchema)
  return (
    <Formik
      enableReinitialize
      initialValues={{ measures }}
      validationSchema={shape}
      onSubmit={onSubmit}
    >
      {({ values, errors, ...props }) => {
        return (
          <Form onSubmit={props.handleSubmit}>
            <Grid
              className="edit-scorecard-form__container"
              id="edit-scorecard-form__container"
              data-testid="manage-measures-form"
            >
              <Grid.Row centered>
                <Grid.Column width={12} className="edit-scorecard-form__columns-title">
                  Measures
                </Grid.Column>
                <Grid.Column width={1} />
                <Grid.Column width={2} className="edit-scorecard-form__columns-title">
                  Status
                </Grid.Column>
                <Grid.Column width={1} />
              </Grid.Row>
              <DragDropContext onDragEnd={onDragEnd}>
                <ModalFields
                  items={values.measures}
                  name="measures"
                  RowComponent={EditMeasuresRow}
                  errors={errors}
                  handleAddItem={handleAddMeasure}
                  addItemLabel="Add Measure"
                  removeFromObject={removeFromObject}
                  updateStatusInObject={updateStatusInObject}
                  updateNameInObject={updateNameInObject}
                />
              </DragDropContext>
            </Grid>
            <Grid>
              <Grid.Row style={{ margin: '0px 1px' }}>
                <Grid.Column
                  width={16}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    basic
                    data-testid="edit-measures-form__cancel-button"
                    onClick={() => dispatch(closeModal())}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid="save_modal"
                    type="submit"
                    primary
                    loading={loading.scorecards}
                    disabled={loading.scorecards || !isEmpty(errors)}
                  >
                    Save
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
