import React, { useState } from 'react'
import { Accordion, AccordionTitle, AccordionContent } from 'semantic-ui-react'
import { orderBy, first, isEmpty } from 'lodash'
import { IconBulb } from '@tabler/icons-react'

import { formatPercent } from '@/utils/helpers'

import { ExternalCallIdsLink } from '../components/ExternalCallIdsLink'

export const TopInfractorsRow = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(-1)
  const topInfractorsSorted = orderBy(data.top_infractors, 'failing_percent', 'desc')
  const topInfractor = first(topInfractorsSorted)

  if (isEmpty(topInfractor)) {
    return <div>No top infractors</div>
  }

  const infractorName = `${topInfractor.first_name} ${topInfractor.last_name}`
  const failurePercent = formatPercent(topInfractor.failing_percent)

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex)
  }

  return (
    <Accordion className="info-accordion">
      <AccordionTitle active={activeIndex === 0} index={0} onClick={handleAccordionClick}>
        <div className="vertical-center status-informative">
          <IconBulb className="icon-svg" />
        </div>
        <span>
          <strong>{infractorName} </strong> accounts for <strong> {failurePercent} </strong> of the
          failure rate.
        </span>
        <strong className="info-accordion-link">
          {activeIndex === 0 ? 'Collapse' : 'View Infractors'}
        </strong>
      </AccordionTitle>
      <AccordionContent active={activeIndex === 0} index={0}>
        <div>
          <table className="full-width table-compact">
            <thead>
              <tr>
                <th>Name</th>
                <th>Rate</th>
                <th>Calls</th>
              </tr>
            </thead>
            <tbody>
              {topInfractorsSorted.map((infractor) => {
                return (
                  <tr key={infractor.user_id}>
                    <td>
                      {infractor.first_name} {infractor.last_name}
                    </td>
                    <td>{formatPercent(infractor.failing_percent)}</td>
                    <td>
                      <ExternalCallIdsLink
                        callIds={infractor.failing_call_ids}
                        label={infractor.failing_call_ids?.length}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </AccordionContent>
    </Accordion>
  )
}
