import React from 'react'

export const BaltoLogo = ({ width = '100%', height = '100%', fill = '#1b1d22', ...props }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 32 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.496.337c1.63.915 3.87 2.133 4.784 2.64.914.506 1.212.634 1.212.747 0 .113-.23.18-.836.533-.606.354-1.167.736-1.666.736-.5 0-2.725-1.825-3.354-2.328-.629-.502-.932-.633-1.055-1.137-.123-.505-.084-1.056-.05-1.326.035-.269.169-.312.965.135zM18.792 7.264c.01.181.26.202.607.113.348-.088 1.744-.441 1.837-.706.093-.265-.882-.717-1.391-.59-.51.128-1.078.776-1.053 1.183z"
        fill={fill}
      />
      <path
        d="M31.694 1.443c-.38-.695-1.207-.672-3.287.761s-3.353 1.904-3.98 2.15c-.626.246-.85.336-1.833-.135-.983-.47-1.923-1.14-3.331-1.14-.914-.02-4.255 1.256-5.224 1.732-.768.376-1.701.851-2.056 1.033a.27.27 0 01-.281-.022c-.666-.48-3.617-2.612-4.41-3.239-.893-.707-1.883-1.097-2.17-1.097-.286 0-.421.307-.232 1.088.19.78 1.556 7.572 1.677 8.042.199.76.258.83-.085 1.274-.343.443-1.502 1.901-2.703 3.563-1.2 1.661-1.358 1.818-1.358 2.176 0 .358.171.415.53.415.357 0 .957-.099.5.559-.458.658-3.072 3.88-3.403 4.381-.331.501 1.127.953 2.14 1.007 1.565.083 3.617-.397 4.71-1.3 1.575-1.3 3.65-4.688 5.206-6.85 1.015-1.41 1.213-1.971.97-3.117-.242-1.146-.888-2.57.056-4.359.943-1.79 2.473-2.462 3.888-3.05 1.416-.587 2.874-.93 4.247-.3 1.373.63 2.087 1.403 3.531.701 1.445-.702 1.216-.744 2.917-1.79 1.701-1.045 2.016-1.345 2.517-.83.5.515.748 1.66.099 3.183-.65 1.523-4.428 5.26-6.239 7.434-1.81 2.174-4.36 4.657-4.471 5.105-.111.447 1.274.784 2.347.694 1.074-.089 1.834-.067 2.661-1.5.827-1.432 2.014-4.122 3.96-7.099 1.944-2.977 3.33-4.835 3.378-6.134.047-1.299.11-2.642-.271-3.336zM8.175 8.753c-.573.969-.752.49-.848.24-.095-.252-.73-2.966-1.076-4.46-.158-.68-.23-.98-.044-1.079.441-.237 2.236 1.13 2.802 2.31.512 1.064-.26 2.02-.834 2.99z"
        fill={fill}
      />
    </svg>
  )
}
