// Action Types
import produce from 'immer'

export const SET_LOADING = 'featureFeedback/setLoading'
export const SET_DATA = 'featureFeedback/setData'

// Action Creators
export const setData = (payload) => ({ type: SET_DATA, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })

// State
export const initialState = {
  data: {
    feedback: [],
  },
  loading: {
    feedback: false,
  },
}

export default function featureFeedback(state = initialState, action) {
  return produce(state, (newState) => {
    switch (action.type) {
      case SET_DATA:
        newState.data = { ...state.data, ...action.payload }
        break
      case SET_LOADING:
        newState.loading = { ...state.loading, ...action.payload }
        break
      default:
        break
    }
  })
}
