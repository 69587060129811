import React from 'react'

import Form from '../../../../components/Modal'
import VoipCampaignForm from '../../../../components/forms/VoipCampaignForm'

export const ManageVoipCampaignsButton = () => {
  return (
    <div style={{ margin: '.5rem' }}>
      <Form
        buttonLabel="Manage VoIP Campaigns"
        buttonId="manage-voip-campaigns"
        header="Manage VoIP Campaigns"
        form={<VoipCampaignForm />}
      />
    </div>
  )
}
