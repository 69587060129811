import { uniq } from 'lodash'

export const getTickValues = (data, key) => {
  let skipNum = 0

  if (data.length > 50) {
    skipNum = 8
  } else if (data.length > 25) {
    skipNum = 5
  } else if (data.length > 15) {
    skipNum = 3
  } else {
    skipNum = 1
  }

  return uniq(data.map((d) => d[key])).filter((item, i) => i % skipNum === 0) || []
}
