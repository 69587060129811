import React from 'react'
import { Button } from 'semantic-ui-react'

import { ErrorMessage } from '@/components/forms/ErrorMessage'

export const PlaybookFormActions = ({ isDisabled, handleCancel, dirty }) => {
  return (
    <div className="playbook-form-actions">
      <div className="flex-align-center small-gap" style={{ marginLeft: 'auto' }}>
        <Button secondary type="button" data-testid="playbook-cancel-button" onClick={handleCancel}>
          Cancel
        </Button>
        <Button primary type="submit" data-testid="playbook-submit-button" disabled={isDisabled}>
          Update
        </Button>
      </div>
      {dirty && <ErrorMessage content="You have unsaved changes on this page!" />}
    </div>
  )
}
