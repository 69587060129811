import React from 'react'
import { Popup } from 'semantic-ui-react'
import { truncate } from 'lodash'

export const isOrgUnique = (orgId) => {
  const orgIdInStorage = JSON.parse(localStorage.getItem('balto_org_id'))

  return orgId !== orgIdInStorage
}

export const setOrgIdInLocalStorage = (orgId) => {
  const orgIdString = JSON.stringify(orgId)

  if (orgIdString) {
    localStorage.setItem('balto_org_id', orgIdString)
  }
}

export const formatCustomColumns = (defaultColumns, customColumns, isTableView) => {
  // isTableView keeps the audio status column at index 0, but filters out hidden columns
  const formattedColumns = []
  const lockedColumns = defaultColumns.filter(({ is_locked }) => is_locked)
  const lockedAccessors = lockedColumns.map(({ accessor }) => accessor)

  if (isTableView) {
    lockedColumns.forEach((column) => {
      formattedColumns.push(column)
    })
  }

  let formattedCustomColumns = customColumns
    .map(({ accessor, ...rest }) => {
      const defaultColumn = defaultColumns.find(
        ({ accessor: defaultAccessor }) => defaultAccessor === accessor
      )
      return {
        ...defaultColumn,
        ...rest,
        is_locked: defaultColumn?.is_locked,
        isSearchable: defaultColumn?.isSearchable, // make sure it uses the FE values, not saved
        isSortable: defaultColumn?.isSortable,
      }
    })
    .filter(({ is_locked }) => !is_locked)

  if (isTableView) {
    formattedCustomColumns = formattedCustomColumns.filter(({ is_hidden }) => !is_hidden)
  }
  const userColumns = formattedColumns.concat(formattedCustomColumns)
  // this covers us if we add more defualt columns in the future
  const missingColumns = defaultColumns.filter(
    ({ accessor }) =>
      !lockedAccessors.includes(accessor) &&
      !customColumns.find(({ accessor: userAccessor }) => userAccessor === accessor)
  )

  return userColumns.concat(missingColumns)
}

export const truncatedColumn = (value, length = 50) => {
  if (value) {
    return (
      <Popup
        inverted
        content={value}
        position="top center"
        trigger={<div>{truncate(value, { length })}</div>}
        disabled={value.length < 50}
      />
    )
  }

  return null
}
