import React, { useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { Button, Card, Grid } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import { IconPlus } from '@tabler/icons-react'

import { Pill } from '@/components/pills/Pill'
import { editScorecard, newCustomMeasure } from '@/reducers/scorecards/scorecards.actions'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { WizardModal } from '@/components/layout/modals/WizardModal'
import { closeModal, openModal } from '@/reducers/ui/ui.redux'
import { Breadcrumbs } from '@/components/forms/Breadcrumbs/Breadcrumbs'

import { ScorecardEditorCard } from './ScorecardEditorCard'
import { ScorecardCriteriaSelector } from './ScorecardCriteria/ScorecardCriteriaSelector'
import { ScorecardCriteriaSummary } from './ScorecardCriteria/ScorecardCriteriaSummary'
import { EditMeasuresForm } from './forms/EditMeasuresForm'
import {
  giveOrderId,
  replaceSectionInScorecardConfig,
  updateMeasuresInScorecardConfig,
} from './helpers'

import './scorecards.css'

export const MeasureCardBadges = ({ measure }) => {
  const numCriteria = measure?.criteria?.length ? measure.criteria.length : null
  const isAutoFail = measure.auto_fail
  const isAutoZero = measure.auto_zero

  if (!isAutoFail && !isAutoZero && !numCriteria) {
    return <IconPlus className="icon-svg-large" data-testid="default-badge-icon" />
  }

  return (
    <>
      {numCriteria && (
        <Pill emphasized small informative data-testid="criteria-badge">
          {numCriteria} Criteria
        </Pill>
      )}
      {isAutoFail && (
        <Pill emphasized small critical data-testid="autofail-badge">
          Auto-fail
        </Pill>
      )}
      {isAutoZero && (
        <Pill emphasized small caution data-testid="autozero-badge">
          Auto-zero
        </Pill>
      )}
    </>
  )
}

// redux information is dispatched from the Wrapper for both targetSection and config editor
export const ScorecardSectionEditor = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { organizationid: organizationId } = useSelector((state) => state.currentUser)

  const { targetPlaybook } = useSelector((state) => state?.scorecards?.data)
  const [sectionDraft, setSectionDraft] = useState({})
  const [measureDraft, setMeasureDraft] = useState(null)
  const { data, loading } = useSelector((state) => state.scorecards)
  const { currentlyOpenModalId: modal } = useSelector((state) => state.ui)
  const scorecardConfig = { ...data.targetScorecardConfig } || {}
  // deep copy because it contains objects, so the references don't disappear with {...data}.
  const section = JSON.parse(JSON.stringify(data.targetScorecardSection || {}))
  const measures = section.measures ? section.measures : []

  const openModalFrontend = (measureData) => {
    dispatch(openModal('newMeasure'))
    setSectionDraft({ ...section })
    setMeasureDraft(measureData)
  }

  const loadedMeasures =
    measures.length &&
    measures.map((measure) => (
      <ScorecardEditorCard
        cardData={measure}
        onClick={openModalFrontend}
        key={measure?.uuid}
        sectionData
      >
        <MeasureCardBadges measure={measure} />
      </ScorecardEditorCard>
    ))

  const displayNoMeasures = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <div
              data-testid="scorecard-editor_empty_measures"
              className="scorecard-editor_empty_measures_header"
            >
              No Custom Measures
            </div>
            <p className="scorecard-editor_empty_measures_body">
              Get started by creating customizable measures for your scorecard
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  const backLinks = [
    {
      label: 'All Scorecards',
      link: '/scorecards',
    },
    {
      label: scorecardConfig.name || 'My Scorecard',
      link: `/scorecards/edit_config/${scorecardConfig.id}`,
    },
  ]

  const isInMeasure = (criteriaId) =>
    measureDraft.criteria.some((criteria) => criteria.entryId === criteriaId) ||
    measureDraft.criteria.some((criteria) => criteria.playbook_ref_id === criteriaId)

  const findInMeasure = (criteriaId) =>
    measureDraft.criteria.find((criteria) => criteria.entryId === criteriaId) ||
    measureDraft.criteria.find((criteria) => criteria.playbook_ref_id === criteriaId)

  const toggleCriteriaSettings = (criteria) => {
    let targetCriteria
    if (criteria.criteria_type === 'manual') {
      targetCriteria = measureDraft.criteria.find(
        (measureCriteria) => measureCriteria.name === criteria.name
      )
    } else {
      targetCriteria = findInMeasure(criteria.entryId || criteria.playbook_ref_id)
    }
    const newCriteria = cloneDeep({
      ...targetCriteria,
      must_be_present: !criteria.must_be_present,
    })
    const targetIndex = measureDraft.criteria.indexOf(targetCriteria)
    const newMeasureDraft = { ...measureDraft }
    newMeasureDraft.criteria.splice(targetIndex, 1, newCriteria)
    setMeasureDraft({ ...newMeasureDraft })
  }

  const removeCriteriaFromMeasure = (criteria) => {
    const targetCriteria = findInMeasure(criteria.entryId)
    const targetIndex = measureDraft.criteria.indexOf(targetCriteria)
    const newMeasure = { ...measureDraft }
    newMeasure.criteria.splice(targetIndex, 1)
    setMeasureDraft({ ...newMeasure })
  }

  const addCriteriaToMeasure = (criteria) => {
    const newMeasure = { ...measureDraft }
    newMeasure.criteria.push({
      ...criteria,
      playbook_ref_id: criteria.entryId,
    })
    setMeasureDraft({ ...newMeasure })
  }

  // sets criteria to specific value (for select all logic)
  const setCriteriaToSpecificValue = (criteria, shouldBeInMeasure) => {
    if (criteria.criteria_type !== 'manual') {
      if (shouldBeInMeasure) {
        addCriteriaToMeasure(criteria)
      } else {
        removeCriteriaFromMeasure(criteria)
      }
    } else {
      criteria.active = shouldBeInMeasure
    }
  }

  // simply toggles one criteria opposite its current state
  const toggleCriteria = (criteria) => {
    if (isInMeasure(criteria.entryId)) {
      removeCriteriaFromMeasure(criteria)
    } else {
      addCriteriaToMeasure(criteria)
    }
  }

  const handleCriteriaChange = (criteria, shouldBeInMeasure = null) => {
    if (shouldBeInMeasure !== null) {
      setCriteriaToSpecificValue(criteria, shouldBeInMeasure)
    } else {
      toggleCriteria(criteria)
    }
  }

  const updateMeasureInSection = (updatedMeasure) => {
    const sectionClone = cloneDeep(sectionDraft)
    const targetMeasure = sectionClone.measures.find((measure) => measure.id === updatedMeasure.id)
    const targetIndex = sectionClone.measures.indexOf(targetMeasure)
    sectionClone.measures[targetIndex] = updatedMeasure
    setSectionDraft({ ...sectionClone })
  }

  const toggleConditionType = (conditionType) => {
    const updateMeasureDraft = {
      ...measureDraft,
      condition_type: conditionType,
      auto_zero: false, // Auto zero is disabled for OR's. So when we toggle, just disable it.
    }

    setMeasureDraft(updateMeasureDraft)
    updateMeasureInSection(updateMeasureDraft)
  }

  const changeCriteriaWeight = (criteria) => {
    const targetCriteria = findInMeasure(criteria.entryId || criteria.playbook_ref_id)
    if (criteria.weight) {
      const newCriteria = cloneDeep({
        ...targetCriteria,
        weight: criteria.weight,
      })
      const targetIndex = measureDraft.criteria.indexOf(targetCriteria)
      const newMeasure = { ...measureDraft }
      newMeasure.criteria.splice(targetIndex, 1, newCriteria)
      setMeasureDraft({ ...newMeasure })
      updateMeasureInSection(newMeasure)
    }
  }

  const changeCriteriaName = (criteria) => {
    const targetCriteria = findInMeasure(criteria.entryId || criteria.playbook_ref_id)
    if (criteria.weight) {
      const newCriteria = cloneDeep({
        ...targetCriteria,
        name: criteria.name,
      })
      const targetIndex = measureDraft.criteria.indexOf(targetCriteria)
      const newMeasure = { ...measureDraft }
      newMeasure.criteria.splice(targetIndex, 1, newCriteria)
      setMeasureDraft({ ...newMeasure })
      updateMeasureInSection(newMeasure)
    }
  }

  const toggleAutoFail = () => {
    const updateMeasureDraft = {
      ...measureDraft,
      auto_fail: !measureDraft.auto_fail,
      auto_zero: false,
    }

    setMeasureDraft(updateMeasureDraft)
    updateMeasureInSection(updateMeasureDraft)
  }

  const toggleAutoZero = () => {
    const updateMeasureDraft = {
      ...measureDraft,
      auto_zero: !measureDraft.auto_zero,
      auto_fail: false,
    }

    setMeasureDraft(updateMeasureDraft)
    updateMeasureInSection(updateMeasureDraft)
  }

  const openCreateMeasureModal = () => {
    dispatch(openModal('createCustomMeasure'))
    const updateMeasures = measures.map((measure, index) =>
      !measure.order_id ? giveOrderId(measure, index) : measure
    )
    setMeasureDraft(updateMeasures)
  }

  const updateMeasuresNow = async (formValues) => {
    const updateMeasureDraft = cloneDeep(formValues)
    setMeasureDraft(updateMeasureDraft)
  }
  const removeFromObject = async (formValues) => {
    const updateMeasureDraft = measureDraft.filter((measure) => measure.id !== formValues.id)
    setMeasureDraft(updateMeasureDraft)
  }
  const addNewMeasure = async (newMeasure) => {
    const newMeasures = [...measureDraft, newMeasure]
    const updateMeasureDraft = cloneDeep(newMeasures)

    setMeasureDraft(updateMeasureDraft)
  }
  const updateNameInObject = async (formValues, measureObject) => {
    const updateMeasureDraftName = measureDraft.map((measure) => {
      const newMeasure = measure
      if (measure.id === measureObject.id) {
        newMeasure.name = formValues.target.value
      }
      return newMeasure
    })
    const updateMeasureDraft = cloneDeep(updateMeasureDraftName)
    setMeasureDraft(updateMeasureDraft)
  }
  const updateStatusInObject = async (formValues) => {
    const updateMeasureDraftName = measureDraft.map((measure) => {
      const newMeasure = measure
      if (measure.id === formValues.id) {
        newMeasure.active = !formValues.active
      }
      return newMeasure
    })
    const updateMeasureDraft = cloneDeep(updateMeasureDraftName)
    setMeasureDraft(updateMeasureDraft)
  }

  const createCustomMeasure = async (formValues) => {
    const configDraft = updateMeasuresInScorecardConfig(
      scorecardConfig,
      formValues.measures,
      data?.targetScorecardSection
    )

    const { newScorecard, newSection } = await dispatch(newCustomMeasure(configDraft))
    dispatch(closeModal())
    await history.push(`/scorecards/edit_config/${newScorecard.id}/edit_section/${newSection.id}`)
  }

  const handleSubmit = async () => {
    const configDraft = replaceSectionInScorecardConfig(scorecardConfig, sectionDraft)
    const { newScorecard, newSection } = await dispatch(
      editScorecard(configDraft, sectionDraft, organizationId)
    )
    dispatch(closeModal())
    history.push(`/scorecards/edit_config/${newScorecard.id}/edit_section/${newSection.id}`)
  }

  let pages = []
  if (measureDraft?.name) {
    pages = [
      () => (
        <ScorecardCriteriaSelector
          findInMeasure={findInMeasure}
          onClick={handleCriteriaChange}
          toggleFail={toggleAutoFail}
          toggleZero={toggleAutoZero}
          toggleCriteriaSettings={toggleCriteriaSettings}
          changeCriteriaWeight={changeCriteriaWeight}
          changeCriteriaName={changeCriteriaName}
          measureDraft={measureDraft}
          toggleConditionType={toggleConditionType}
          addCriteriaToMeasure={addCriteriaToMeasure}
          setMeasureDraft={setMeasureDraft}
          removeFromObject={removeFromObject}
          scorecardType={scorecardConfig?.type}
        />
      ),
      () => <ScorecardCriteriaSummary section={section} measureDraft={measureDraft} />,
    ]
  }
  return (
    <>
      <div className="editor-page scorecard-section-editor" data-testid="scorecard-section-editor">
        <Breadcrumbs backLinks={backLinks} currentLink={`Edit ${section.name}`} />
        <div className="page-header">
          <h1 data-testid="scorecard-section-editor-name">{section.name || 'Edit sectionDraft'}</h1>
          <Button
            primary
            data-testid="create_custom_measure"
            floated="right"
            onClick={() => openCreateMeasureModal()}
          >
            Edit Measures
          </Button>
        </div>
        {loadedMeasures.length > 0 && (
          <div className="scorecard-editor-grid">
            <Card.Group>{loadedMeasures}</Card.Group>
          </div>
        )}
        {!loadedMeasures && <div>{displayNoMeasures()}</div>}
      </div>
      {modal === 'createCustomMeasure' && (
        <BasicModal
          data-testid="custom-measure-modal"
          title="Edit Measure"
          onClose={() => dispatch(closeModal())}
          show={!!modal}
          size="small"
        >
          <EditMeasuresForm
            measures={measureDraft}
            handleSubmit={createCustomMeasure}
            updateMeasuresNow={updateMeasuresNow}
            removeFromObject={removeFromObject}
            updateStatusInObject={updateStatusInObject}
            updateNameInObject={updateNameInObject}
            addNewMeasure={addNewMeasure}
          />
        </BasicModal>
      )}
      {modal === 'newMeasure' && (
        <WizardModal
          pages={pages}
          isLoading={loading.scorecards}
          disableButtons={false}
          title={measureDraft?.name}
          playbook={targetPlaybook}
          onSave={handleSubmit}
          size="large"
          scrollable
          enableButton
        />
      )}
    </>
  )
}

export default withRouter(ScorecardSectionEditor)
