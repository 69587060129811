import React from 'react'
import moment from 'moment'
import { Button } from 'semantic-ui-react'

import './ContextMenu.scss'

export const ContextMenu = ({ x, y, show = false, point, categories }) => {
  if (!point || !show) {
    return null
  }

  return (
    <div
      className="context-menu"
      style={{
        display: show ? 'block' : 'none',
        top: `calc(${y + 130}px)`,
        left: `calc(${x + 43}px)`,
      }}
    >
      <div>
        {point.color && <div className="color-indicator" style={{ background: point.color }} />}
        <div>
          {point.serieId && <h4>{point.serieId}</h4>}
          <p className="muted-text" style={{ maxWidth: '240px' }}>
            {categories.find((cat) => cat.category === point.serieId)?.description}
          </p>
          <p>
            <strong>{moment.utc(point.data.x).local().format('MMMM D, YYYY')}</strong>
            <br />
            <strong>{`${point.data.yFormatted}%`}</strong>
          </p>
        </div>
      </div>
      <Button primary>Click Me</Button>
    </div>
  )
}
