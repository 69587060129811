import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty, sortBy } from 'lodash'
import { IconArrowUp, IconArrowDown, IconX } from '@tabler/icons-react'

import { setCallExplorerData } from '@/reducers/callSearch/callSearch.redux'
import { EventBlock } from './EventBlock'
import {
  HARD_SELECTED_EVENT_ID,
  EVENT_VIEWER_ID,
  SECTION_NAMES,
  getContextFromEvent,
  getPillIndex,
  updateCallExplorerParams,
  parseCallExplorerParams,
} from './helpers'

import { EventFilters } from './EventFilters'

import './EventsViewer.scss'

const TRIGGER_OFFSET_FROM_CONTAINER = 16

export const EventsViewer = ({ analysis, highlightedEvents, copilotAnchors }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const allEvents = [...analysis, ...copilotAnchors].sort((a, b) => a.timestamp - b.timestamp)

  const filterOptions = [...new Set(analysis?.map((item) => item.section))].map((section) => ({
    id: section,
    label: SECTION_NAMES[section],
  }))
  if (copilotAnchors.length) {
    filterOptions.push({ id: 'qa_copilot', label: 'QA Copilot Events' })
  }
  filterOptions.unshift({ id: 'all', label: 'All Events' })

  const [filtersApplied, setFiltersApplied] = useState(filterOptions.map((option) => option.id))
  const [filterLabel, setFilterLabel] = useState('all')
  const [carouselVisible, setCarouselVisible] = useState(true)
  const [highlightedEventIndex, setHighlightedEventIndex] = useState(0)

  const { callExplorer } = useSelector((state) => state.callSearch)
  const { selectedPillIndex, hardSelectedEvent, groupedEvents } = callExplorer

  useEffect(() => {
    /*
      if an event is hard-selected as a result of pill click,
      scroll the container to show the trigger
    */
    const myElement = document.getElementById(HARD_SELECTED_EVENT_ID)
    const triggerContainerScroll = hardSelectedEvent && myElement

    if (triggerContainerScroll) {
      const topPos = myElement.offsetTop - TRIGGER_OFFSET_FROM_CONTAINER

      document.getElementById(EVENT_VIEWER_ID).scrollTo({
        top: topPos,
        behavior: 'auto',
      })
    }
  }, [selectedPillIndex, hardSelectedEvent])

  const handleFilterSelect = (selectedSection) => {
    updateCallExplorerParams(location, history, { eventFilter: selectedSection.id })
    setFilterLabel(selectedSection.id)

    if (selectedSection.id === 'all') {
      setFiltersApplied(filterOptions.map((option) => option.id))
      return
    }
    setFiltersApplied([selectedSection.id])
  }

  useEffect(() => {
    const { eventFilter, eventId, timestamp, keywords } = parseCallExplorerParams(location)

    if (eventFilter) {
      handleFilterSelect({ id: eventFilter })
    }

    const isEventSelected = eventId && timestamp
    if (isEventSelected) {
      dispatch(
        setCallExplorerData({
          hardSelectedEvent: {
            id: eventId,
            timestamp: Number(timestamp),
          },
        })
      )
    }
    if (!isEventSelected && keywords) {
      const firstKeywordEvent = allEvents.find((event) => event.section === 'keywords')

      if (!isEmpty(firstKeywordEvent)) {
        const { id, timestamp: kwTimestamp } = firstKeywordEvent

        dispatch(
          setCallExplorerData({
            hardSelectedEvent: {
              id,
              timestamp: kwTimestamp,
            },
          })
        )
      }
    }
  }, [])

  const selectHighlightedEvent = (newIndex) => {
    const formattedIndex = Math.abs(newIndex % highlightedEvents.length)
    const newHardSelectedEvent = highlightedEvents[formattedIndex]
    const newPillIndex = getPillIndex(groupedEvents, newHardSelectedEvent)
    dispatch(
      setCallExplorerData({
        selectedPillIndex: newPillIndex,
        hardSelectedEvent: newHardSelectedEvent,
      })
    )
    setHighlightedEventIndex(formattedIndex)
    updateCallExplorerParams(location, history, {
      eventId: newHardSelectedEvent.id,
      timestamp: newHardSelectedEvent.timestamp,
    })
  }

  const handleNextHighlightedEvent = (ev) => {
    ev.preventDefault()
    selectHighlightedEvent(highlightedEventIndex + 1)
  }

  const handlePrevHighlightedEvent = (ev) => {
    ev.preventDefault()
    const prevIndex = highlightedEventIndex - 1
    const nextIndex = prevIndex >= 0 ? prevIndex : highlightedEvents.length - 1
    selectHighlightedEvent(nextIndex)
  }

  const closeCarousel = (ev) => {
    ev.preventDefault()
    setCarouselVisible(false)
  }

  const analysisFiltered = allEvents?.filter(
    (entry) =>
      filtersApplied.includes(entry?.section) ||
      (filtersApplied.includes('qa_copilot') && entry.criteria_name && entry.scorecard_name)
  )

  const highlightedFiltered = highlightedEvents.filter((entry) =>
    filtersApplied.includes(entry.section)
  )
  const isCarouselVisible = carouselVisible && !isEmpty(highlightedFiltered)

  const isArrowDisabled = !(highlightedFiltered.length > 1)
  const arrowClasses = classNames({
    'arrow-disabled': isArrowDisabled,
  })

  return (
    <>
      <EventFilters
        title="Quick Filters"
        filterOptions={sortBy(filterOptions, (option) => option.label)}
        selectedFilters={filterLabel}
        handleFilterSelect={handleFilterSelect}
      />
      {isCarouselVisible && (
        <div className="events-viewer-highlighted-carousel">
          <span>{`${highlightedEventIndex + 1} of ${highlightedFiltered.length}`}</span>
          <IconArrowDown className={arrowClasses} onClick={handleNextHighlightedEvent} />
          <IconArrowUp className={arrowClasses} onClick={handlePrevHighlightedEvent} />
          <IconX onClick={closeCarousel} />
        </div>
      )}
      <div id={EVENT_VIEWER_ID} className="events-scrollable-container" data-testid="event-blocks">
        {analysisFiltered?.map((event) => {
          const contextStr = getContextFromEvent(event)
          const highlighted = highlightedFiltered.some((hEvent) => {
            const eventContext = getContextFromEvent(hEvent)
            return eventContext === contextStr
          })
          return (
            <EventBlock
              key={`event-${event.id || `${event.criteria_name}-${event.scorecard_name}`}`}
              event={event}
              highlighted={highlighted}
            />
          )
        })}
      </div>
    </>
  )
}
