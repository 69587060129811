/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Field, FieldArray } from 'formik'
import { Button, Divider } from 'semantic-ui-react'
import { isEmpty, toArray } from 'lodash'
import { IconTrash } from '@tabler/icons-react'

import { TextField } from '@/components/forms/formik'
import { fetchAdditionalPhrases } from '@/reducers/playbooks/playbook.actions'

export const SmartPhraseTrigger = ({ values, readOnly, loading, dispatch, formikProps }) => {
  const smartPhrases = toArray(values.trigger?.smart_phrases)

  const generateSmartPhraseSuggestions = (index) => {
    dispatch(
      fetchAdditionalPhrases(
        {
          phrase: smartPhrases[index]?.seed_phrase,
          temperature: 1.3,
          side: values?.trigger?.side || 'them',
        },
        index,
        formikProps
      )
    )
  }

  const removeSuggestion = (index, suggestionIndex) => {
    formikProps.setFieldValue(
      `trigger.smart_phrases[${index}].suggestions`,
      smartPhrases[index]?.suggestions?.filter(
        (_, existingIndex) => existingIndex !== suggestionIndex
      )
    )
  }

  return (
    <div className="smart-phrases">
      <p>
        <strong>Smart Phrases</strong>
      </p>
      <FieldArray
        name="trigger.smart_phrases"
        render={(arrayHelpers) => {
          return (
            <div>
              {smartPhrases.map((value, index) => {
                return (
                  <div key={index}>
                    <p className="form-label">Seed Phrase</p>
                    <div className="seed-phrase">
                      <Field
                        name={`trigger.smart_phrases[${index}].seed_phrase`}
                        placeholder="Add a phrase to trigger this item"
                        component={TextField}
                        disabled={readOnly || !isEmpty(smartPhrases[index].suggestions)}
                        style={{ marginBottom: 0 }}
                      />
                      {isEmpty(smartPhrases[index].suggestions) && (
                        <Button
                          type="button"
                          loading={loading.smartPhraseSuggestions === index}
                          primary
                          disabled={!smartPhrases[index].seed_phrase}
                          onClick={() => generateSmartPhraseSuggestions(index)}
                        >
                          Generate
                        </Button>
                      )}
                      <Button
                        compact
                        basic
                        type="button"
                        icon
                        className="svg-button"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <IconTrash />
                      </Button>
                    </div>
                    <Divider />
                    {!isEmpty(smartPhrases[index].suggestions) &&
                      smartPhrases[index].suggestions.map((suggestion, suggestionIndex) => {
                        return (
                          <div key={suggestion} className="seed-phrase">
                            <div className="suggestion-connector" />
                            <div className="suggestion">{suggestion}</div>
                            <Button
                              compact
                              basic
                              type="button"
                              icon
                              className="svg-button"
                              onClick={() => removeSuggestion(index, suggestionIndex)}
                            >
                              <IconTrash />
                            </Button>
                          </div>
                        )
                      })}
                  </div>
                )
              })}
              <Button
                type="button"
                secondary
                onClick={() => arrayHelpers.push({ seed_phrase: '', suggestions: [] })}
              >
                Add Seed Phrase
              </Button>
            </div>
          )
        }}
      />
    </div>
  )
}
