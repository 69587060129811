import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  subDays,
} from 'date-fns'
import moment from 'moment'
import React from 'react'

const dateRange = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfSixDaysAgo: subDays(new Date(), 6),
  startOfTwoMonthsAgo: subDays(new Date(), 59),
  startOfOneMonthAgo: subDays(new Date(), 29),
}

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range()
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    )
  },
}

// "the past week" is default selection so isn't listed as an option
export const customStaticRanges = [
  {
    label: 'Today',
    range: () => ({
      startDate: dateRange.startOfToday,
      endDate: dateRange.endOfToday,
    }),
  },
  {
    label: 'This Week',
    range: () => ({
      startDate: dateRange.startOfWeek,
      endDate: dateRange.startOfToday,
    }),
  },
  {
    label: 'Last Week',
    range: () => ({
      startDate: dateRange.startOfLastWeek,
      endDate: dateRange.endOfLastWeek,
    }),
  },
  {
    label: 'This Month',
    range: () => ({
      text: 'thisMonth',
      startDate: dateRange.startOfMonth,
      endDate: dateRange.endOfMonth,
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      text: 'lastMonth',
      startDate: dateRange.startOfLastMonth,
      endDate: dateRange.endOfLastMonth,
    }),
  },
  {
    label: 'Last 7 Days',
    range: () => ({
      startDate: dateRange.startOfSixDaysAgo,
      endDate: dateRange.endOfToday,
    }),
  },
  {
    label: 'Last 30 Days',
    range: () => ({
      startDate: dateRange.startOfOneMonthAgo,
      endDate: dateRange.endOfToday,
    }),
  },
  {
    label: 'Last 60 Days',
    range: () => ({
      startDate: dateRange.startOfTwoMonthsAgo,
      endDate: dateRange.endOfToday,
    }),
  },
].map((range) => ({ ...staticRangeHandler, ...range }))

export const getTriggerLabel = (startDate, endDate, basicLabel, friendlyLabel) => {
  if (!startDate && !endDate) {
    return 'Select Date'
  }

  if (startDate && (!endDate || endDate === 'Invalid date')) {
    return `${moment(startDate).format('L')} to End Date`
  }

  if ((!startDate || startDate === 'Invalid date') && endDate) {
    return `Start Date to ${moment(endDate).format('L')}`
  }

  const formattedStartDate = moment(startDate).format('L')
  const formattedEndDate = moment(endDate).format('L')
  let displayLabel = `${formattedStartDate} to ${formattedEndDate}`

  if (friendlyLabel) {
    const dateRange = customStaticRanges.find((staticRange) => {
      const range = staticRange.range()

      if (
        formattedStartDate === moment(range.startDate).format('L') &&
        formattedEndDate === moment(range.endDate).format('L')
      ) {
        return true
      }

      return false
    })

    if (dateRange) {
      displayLabel = dateRange.label
    }
  }

  return basicLabel ? (
    displayLabel
  ) : (
    <div className="date-range-display" data-testid="default-label">
      <strong>{formattedStartDate}</strong>
      <span>to</span>
      <strong>{formattedEndDate}</strong>
    </div>
  )
}

export const isValidDate = (date) => {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(date)
}

export const determineGradient = (gradientNumber) => `var(--gradient-${gradientNumber % 20})`

export const dateAriaLabels = {
  dateInput: {
    selectedRange: {
      startDate: 'start date input of selected range',
      endDate: 'end date input of selected range',
    },
  },
  monthPicker: 'month picker',
  yearPicker: 'year picker',
  prevButton: 'previous month button',
  nextButton: 'next month button',
}
