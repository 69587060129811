import { Checkbox, Table } from 'semantic-ui-react'
import React from 'react'
import classNames from 'classnames'

export const NestedTableBaseBody = ({
  columns,
  rows,
  selectable,
  nestedSelect,
  selectedRows,
  basic,
  striped = false,
}) => {
  const basicClass = basic ? 'basic' : ''

  return rows.map((row) => (
    <Table.Row
      key={row?.name?.value || row?.name}
      className={classNames('basic-table__body-row', striped && 'striped')}
      data-testid="basic-table-row"
    >
      {selectable && (
        <Table.Cell collapsing className="basic-table__body-cell-checkbox">
          <Checkbox
            className="basic-table__row-select-box"
            data-testid="basic-table-checkbox"
            checked={selectedRows[row.key]?.selected}
            onClick={() => nestedSelect(row)}
          />
        </Table.Cell>
      )}
      {columns.map((column, index) =>
        row[column.accessor]?.as ? (
          <Table.Cell
            textAlign={index >= 2 ? 'right' : 'left'}
            key={column.accessor}
            data-testid={`custom-component-${column.accessor}`}
            className={`basic-table__body-cell ${
              row[column.accessor].cellProps?.className || ''
            } ${basicClass}`}
            {...row[column.accessor].cellProps}
          >
            {row[column.accessor].as}
          </Table.Cell>
        ) : (
          <Table.Cell
            key={column.accessor}
            className={`basic-table__body-cell ${basicClass}`}
            data-testid="basic-table-cell"
          >
            {row[column.accessor]}
          </Table.Cell>
        )
      )}
    </Table.Row>
  ))
}
