// Action Types
export const SET_LOADING = 'activityLog/setLoading'
export const ADD_ITEMS = 'activityLog/addItems'
export const SET_DATA = 'activityLog/setData'
export const SET_FILTER = 'activityLog/setFilter'

export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setData = (payload) => ({ type: SET_DATA, payload })
export const setFilter = (payload) => ({ type: SET_FILTER, payload })
export const addItems = (payload) => ({ type: ADD_ITEMS, payload })

export const initialState = {
  data: {
    agentCalls: {},
    activityLogUsers: {},
  },
  loading: {
    activityLogUsers: false,
    agentCalls: false,
  },
}

const addToPath = (currentData, payload, depth = 0) => {
  let result = currentData
  const key = payload.path[depth]
  if (payload.path.length && depth < payload.path.length - 1) {
    result = {
      ...currentData,
      [key]: addToPath(currentData[key] || {}, payload, depth + 1),
    }
  } else {
    const previousArray = currentData[key] ? currentData[key] : []
    result = {
      ...currentData,
      lastUpdated: payload.updateTimestamp,
      totalCount: (currentData.totalCount || 0) + (payload.incrementCount || payload.items.length),
      [key]: [...previousArray, ...payload.items],
    }
  }
  return result
}

export default function activityLogReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ITEMS: {
      return {
        ...state,
        data: addToPath({ ...state.data }, action.payload),
      }
    }
    case SET_DATA:
      if ('key' in action.payload) {
        if (
          action.payload?.overwrite === false &&
          state.data[action.payload.name][action.payload.key]
        ) {
          return state
        }
        // insert/update key-value pair into dictionary state[name]
        return {
          ...state,
          data: {
            ...state.data,
            [action.payload.name]: {
              ...state.data[action.payload.name],
              [action.payload.key]: { ...action.payload.value, lastUpdated: new Date() },
            },
          },
        }
      }
      return { ...state, data: { ...state.data, ...action.payload } }
    case SET_FILTER:
      return { ...state, filters: { ...state.filters, ...action.payload } }
    case SET_LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    default:
      return state
  }
}
