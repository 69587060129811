import React from 'react'
import Widget from '../../../../components/widget/Widget'
import { QAActivityTable } from './QAActivityTable'
import './TableSection.css'

export const TableSection = () => {
  return (
    <div className="scorecard-exports_table-wrapper">
      <Widget label="QA Activity Events" showCsvDownload>
        <QAActivityTable />
      </Widget>
    </div>
  )
}
