import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import {
  IconList,
  IconArrowRight,
  IconArrowLeft,
  IconCircleDashed,
  IconCircleCheckFilled,
} from '@tabler/icons-react'
import { Link, useLocation, useHistory } from 'react-router-dom'

import { useShortcut } from '@/utils/hooks/useShortcut'

import './BottomDrawer.scss'

export const PlaylistDrawer = ({ calls, currentCall }) => {
  const [isOpen, setIsOpen] = useState(true)
  const location = useLocation()
  const history = useHistory()

  // Find the index of the current call
  const currentIndex = calls.findIndex((call) => call.call_id === Number(currentCall))

  // Calculate the previous and next indices, wrapping around if necessary
  const prevIndex = (currentIndex - 1 + calls.length) % calls.length
  const nextIndex = (currentIndex + 1) % calls.length

  // Get the previous and next call IDs
  const prevCallId = calls[prevIndex].call_id
  const nextCallId = calls[nextIndex].call_id

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
  }

  const goToPrevCall = () => {
    history.push(`/call-explorer/${prevCallId}?${new URLSearchParams(location.search)}`)
  }

  const goToNextCall = () => {
    history.push(`/call-explorer/${nextCallId}?${new URLSearchParams(location.search)}`)
  }

  useShortcut('a', goToPrevCall)
  useShortcut('d', goToNextCall)

  return (
    <div data-testid="playlist-drawer" className={`bottom-drawer ${isOpen ? 'open' : ''}`}>
      <div className="drawer-header">
        <div className="flex-align-center gap">
          <Button className="svg-button" secondary icon onClick={toggleDrawer}>
            <IconList />
            {isOpen ? 'Hide Queue' : 'Show Queue'}
          </Button>
        </div>
        <div className="flex-align-center gap">
          <Button
            className="svg-button"
            secondary
            icon
            as={Link}
            to={`/call-explorer/${prevCallId}?${new URLSearchParams(location.search)}`}
          >
            <IconArrowLeft />
            Prev Call
          </Button>
          <Button
            className="svg-button"
            secondary
            icon
            as={Link}
            to={`/call-explorer/${nextCallId}?${new URLSearchParams(location.search)}`}
          >
            Next Call
            <IconArrowRight />
          </Button>
        </div>
      </div>
      <div className="drawer-content">
        {calls.map(({ call_id, agent_name, is_flagged, alias }) => (
          <div
            key={call_id}
            className={`playlist-drawer-row ${Number(currentCall) === call_id ? 'active' : ''}`}
          >
            <div className="flex-align-center gap">
              <div className="flex-align-center gap">
                {is_flagged ? (
                  <IconCircleCheckFilled className="status-success" />
                ) : (
                  <IconCircleDashed />
                )}
              </div>
              <div className="flex-align-center gap">
                <Link to={`/call-explorer/${call_id}?${new URLSearchParams(location.search)}`}>
                  {alias || call_id}
                </Link>
              </div>
            </div>
            <div>{agent_name}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
