import { fetchingAPI, apiService } from '@/api'
import queryString from 'query-string'
import moment from 'moment'
import { setLoading, setData } from './featureFeedback.redux'

export const fetchFeatureFeedback = () => async (dispatch) => {
  dispatch(setLoading({ feedback: true }))
  const start_date = moment.utc().subtract(30, 'days').format('YYYY-MM-DD')
  const end_date = moment.utc().format('YYYY-MM-DD')
  const queryParams = queryString.stringify({ start_date, end_date })
  try {
    const response = await fetchingAPI(
      `${apiService.reporting}/api/feature_feedback?${queryParams}`
    )
    const data = await response
    dispatch(setData({ feedback: data }))
  } catch (error) {
    console.error('Error fetching feature feedback:', error)
  } finally {
    dispatch(setLoading({ feedback: false }))
  }
}
