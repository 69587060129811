import produce from 'immer'

// Action Types
export const SET_CHAT_VISIBLE = 'baltoGPT/setChatVisible'
export const UPDATE_CHAT_FEED = 'baltoGPT/updateChatFeed'
export const SET_LOADING = 'baltoGPT/setLoading'
export const RESET = 'baltoGPT/reset'

// Action Creators
export const setChatVisible = (payload) => ({ type: SET_CHAT_VISIBLE, payload })
export const updateChatFeed = (payload) => ({ type: UPDATE_CHAT_FEED, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const reset = () => ({ type: RESET })

// State
export const initialState = {
  chatVisible: false,
  loading: {
    baltoGPTChat: false,
  },
  chatFeed: [],
}

// Reducer
export default function baltoGPTreducer(state = initialState, action) {
  return produce(state, (newState) => {
    const { payload } = action

    switch (action.type) {
      case SET_CHAT_VISIBLE:
        newState.chatVisible = payload
        break
      case UPDATE_CHAT_FEED:
        newState.chatFeed = [...state.chatFeed, payload]
        break
      case SET_LOADING:
        newState.loading = { ...newState.loading, ...payload }
        break
      case RESET:
        newState.chatFeed = []
        break
      default:
        break
    }
  })
}
