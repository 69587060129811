import React from 'react'
import { Header } from 'semantic-ui-react'
import { ReviewPageSettingsTable } from '@/views/RealtimeCoaching/Alerts/OrgLevelAlertWizard/components/ReviewPageSettingsTable'
import { ReviewPageUsersTable } from '@/views/RealtimeCoaching/Alerts/OrgLevelAlertWizard/components/ReviewPageUsersTable'
import { ReviewPageTriggersTable } from '@/views/RealtimeCoaching/Alerts/OrgLevelAlertWizard/components/ReviewPageTriggersTable'

export const ReviewPageForm = ({ values, goToPageNumber }) => {
  const { name, alertType, audible, active, selectedAgents, selectedTags, triggerItems } = values
  return (
    <div className="alert-wizard__page">
      <Header className="alert-wizard__field">Review Alert</Header>
      <ReviewPageSettingsTable
        name={name}
        alertType={alertType}
        audible={audible}
        active={active}
        goToPageNumber={goToPageNumber}
      />
      <ReviewPageUsersTable
        selectedAgents={selectedAgents}
        selectedTags={selectedTags}
        goToPageNumber={goToPageNumber}
      />
      <ReviewPageTriggersTable triggerItems={triggerItems} goToPageNumber={goToPageNumber} />
    </div>
  )
}
