import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { IconCheck, IconX } from '@tabler/icons-react'
import { truncate } from 'lodash'
import * as Yup from 'yup'

import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { EditButton } from '@/components/buttons/EditButton'
import { editScorecardConfig } from '@/reducers/qa-copilot/qa-copilot.redux'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'

import { addDefaultSectionHelper } from '../QACopilot.helpers'

export const QACopilotScorecardName = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { scorecardConfig } = useSelector((state) => state.qaCopilot)
  const [editingScorecardName, setEditingScorecardName] = useState(id === 'create')

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false)

    if (scorecardConfig) {
      dispatch(editScorecardConfig({ ...scorecardConfig, name: values.name }))
    } else {
      dispatch(editScorecardConfig({ name: values.name, sections: [addDefaultSectionHelper()] }))
    }
    setEditingScorecardName(false)
  }

  const handleCancel = () => {
    setEditingScorecardName(false)
  }

  return (
    <div className="qa-copilot-scorecard-name">
      <div>
        {!editingScorecardName ? (
          <ConditionalTooltip
            condition={scorecardConfig?.name?.length > 60}
            content={scorecardConfig?.name}
            tooltipProps={{ position: 'bottom left' }}
          >
            <h1>{truncate(scorecardConfig?.name, { length: 60 })}</h1>
          </ConditionalTooltip>
        ) : (
          <AbstractForm
            horizontal
            noLabel
            onSubmit={handleSubmit}
            existingValues={{ name: scorecardConfig?.name || '' }}
            schema={[
              {
                name: 'name',
                type: 'text',
                required: true,
                autoFocus: true,
                autoComplete: 'off',
                placeholder: 'Enter a name for your scorecard',
                customValidators: [
                  Yup.string()
                    .required('QA Scorecard config name is required')
                    .max(500, 'Must be less than five hundred characters'),
                ],
              },
            ]}
            buttonProps={{ className: 'svg-button' }}
            closeButtonProps={{ className: 'svg-button' }}
            buttonLabel={<IconCheck />}
            closeButtonLabel={<IconX />}
            onClose={scorecardConfig?.name && handleCancel}
          />
        )}
        {!editingScorecardName && (
          <EditButton onEdit={() => setEditingScorecardName(!editingScorecardName)} />
        )}
      </div>
    </div>
  )
}
