import moment from 'moment'

// Action Types
export const SET_ORGANIZATION_CUSTOM_BRANDING = 'organizations/setOrganizationCustomBranding'
export const SET_LOADING = 'organizations/setOrganizationCustomBrandingLoading'

// Action Creators
export const setOrganizationCustomBranding = (payload) => ({
  type: SET_ORGANIZATION_CUSTOM_BRANDING,
  payload,
})
export const setLoading = (payload) => ({ type: SET_LOADING, payload })

// State
export const initialState = {
  customBranding: {
    customBrandingDisplayName: null,
    faviconUrl: null,
    iconLightUrl: null,
    iconDarkUrl: null,
  },
  loading: false,
  imageCache: moment().toISOString(),
}

// Reducer
export default function organizationCustomBrandingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload }
    case SET_ORGANIZATION_CUSTOM_BRANDING:
      return {
        ...state,
        customBranding: { ...state.customBranding, ...action.payload },
        imageCache: moment().toISOString(),
      }
    default:
      return state
  }
}
