import React from 'react'

import { Pill } from '@/components/pills/Pill'

export const AudioStatus = ({ audioStatus }) => {
  const statusDisplays = {
    still_processing: 'Audio Processing',
    available: 'Audio Available',
    expired: 'No Audio',
    not_available: 'No Audio',
  }

  const label = statusDisplays[audioStatus] || 'No Audio'

  return (
    <Pill small success={audioStatus === 'available'}>
      {label}
    </Pill>
  )
}
