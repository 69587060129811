import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react'
import {
  IconPlayerPlayFilled,
  IconExclamationCircle,
  IconPlayerPauseFilled,
} from '@tabler/icons-react'

import { toggleCallAudioPlaying } from '@/reducers/audio/audio.redux'
import { fetchAudioForCallId } from '@/reducers/audio/audio.actions'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'

export const AudioPlayerButton = ({ call }) => {
  const dispatch = useDispatch()
  const { loading, callAudio } = useSelector((state) => state.audio)
  const { isPlaying } = callAudio

  if (!call) {
    return null
  }

  const isCurrentlySelectedPlayer = callAudio.callId === call.call_id

  const handlePlayCall = () => {
    if (isCurrentlySelectedPlayer) {
      dispatch(toggleCallAudioPlaying())
    } else {
      dispatch(fetchAudioForCallId(call.call_id))
    }
  }

  const determineIcon = () => {
    // Show exclamation circle if no audio
    if (call.audio_status !== 'available') {
      return <IconExclamationCircle data-testid="no-audio-available" />
    }

    // Toggle pause and play for currently selected player
    if (isCurrentlySelectedPlayer && isPlaying) {
      return <IconPlayerPauseFilled data-testid="pause" />
    }

    // Show play button by default
    return <IconPlayerPlayFilled data-testid="play" />
  }

  return (
    <div>
      <ConditionalTooltip
        content="No audio available for this call"
        condition={call.audio_status !== 'available'}
        tooltipProps={{ position: 'top left' }}
      >
        <Button
          data-testid="audio-player-button"
          disabled={call.audio_status !== 'available'}
          className="svg-button"
          icon
          compact
          circular
          secondary={!isCurrentlySelectedPlayer}
          primary={isCurrentlySelectedPlayer}
          onClick={handlePlayCall}
          loading={isCurrentlySelectedPlayer && loading.callAudio === call.call_id}
        >
          {determineIcon()}
        </Button>
      </ConditionalTooltip>
    </div>
  )
}
