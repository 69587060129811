import * as Yup from 'yup'

Yup.addMethod(Yup.array, 'unique', (message) => {
  return this.test('unique', message, (list) => {
    const values = []
    const errors = []
    list.forEach((segment, idx) => {
      if (!values.includes(segment.name)) {
        values.push(segment.name)
      } else {
        errors.push(idx)
      }
    })

    if (errors.length) {
      return this.createError({ path: `${this.path}[${errors[0]}].name`, message })
    }
    return true
  })
})

Yup.addMethod(Yup.array, 'unique', function validateUniqueName(message) {
  return this.test('unique', message, function validateName(list) {
    const values = []
    const errors = []
    list.forEach(function compareNames(segment, idx) {
      const formattedName = segment.name?.toLowerCase()
      if (!values.includes(formattedName)) {
        values.push(formattedName)
      } else {
        errors.push(idx)
      }
    })

    if (errors.length) {
      return this.createError({ path: `${this.path}[${errors[0]}].name`, message })
    }
    return true
  })
})

const sections = Yup.array()
  .of(
    Yup.object().shape({
      name: Yup.string().min(1, 'Please Enter a Section Name').required('Section Name Required'), // these constraints take precedence
      weight: Yup.number().min(0).required('Section Weight Required').integer(), // these constraints take precedence
    })
  )
  .required()
  .unique('Section Names Must be Unique', (a) => a.name)
  .min(1)

export const sectionsSchema = {
  sections,
}

const measures = Yup.array()
  .of(
    Yup.object().shape({
      name: Yup.string().min(1, 'Please Enter a Measure Name').required('Measure Name Required'), // these constraints take precedence
    })
  )
  .required()
  .unique('Measure Names Must be Unique', (a) => a.name)
  .min(1)

export const measuresSchema = {
  measures,
}

export const genericSchema = {
  name: Yup.string()
    .required('Name is required.')
    .max(100, 'Name cannot be longer than 100 characters.'),
  organizationId: Yup.number(),
  config_id: Yup.number().required('Scorecard config must be linked to a playbook.'),
  exceeds_threshold: Yup.number()
    .required('Required field.')
    .moreThan(Yup.ref('meets_threshold'), 'Cannot be less than meets.')
    .max(100, 'Cannot be more than 100.'),
  meets_threshold: Yup.number()
    .required('Required field.')
    .moreThan(Yup.ref('improvement_threshold'), 'Cannot be less than below.')
    .lessThan(Yup.ref('exceeds_threshold'), 'Cannot be more than exceeds.'),
  improvement_threshold: Yup.number()
    .required('Required field.')
    .lessThan(Yup.ref('exceeds_threshold'), 'Cannot be more than exceeds.')
    .lessThan(Yup.ref('meets_threshold'), 'Cannot be more than meets.')
    .min(0, 'Cannot be less than 0.'),
  min_duration_in_minutes: Yup.number()
    .required('Min. Call Duration is required.')
    .min(0, 'Cannot be less than 0.'),
  active: Yup.boolean(),
  total_weight: Yup.number().min(100).max(100),
  sections,
}
