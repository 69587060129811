import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'

import { PlaybookEditor } from '../../components/PlaybookEditor'
import {
  deadAirValidationSchema,
  deadAirInitialValues,
  talkSpeedValidationSchema,
  talkSpeedInitialValues,
  getValidationSchema,
} from './notification.schema'
import { NotificationsForm } from './NotificationsForm'
import { PlaybookPreview } from '../../components/PlaybookPreview'
import { accessorSectionMap } from '../../playbook.helpers'

export const NotificationsEditor = () => {
  const { entryId, section } = useParams()
  const { playbook } = useSelector((state) => state.playbook)
  const accessor = accessorSectionMap[section]
  const isCreate = entryId === 'create'
  const entry = playbook.body[accessor].entries[entryId]
  const [validationSchema, setValidationSchema] = useState(getValidationSchema(isCreate, entry))
  const [initialValues, setInitialValues] = useState(isCreate ? deadAirInitialValues : entry)

  const handleResetForm = ({ value }) => {
    if (value === 'dead_air') {
      setValidationSchema(deadAirValidationSchema)
      setInitialValues(deadAirInitialValues)
    } else if (value === 'words_per_minute') {
      setValidationSchema(talkSpeedValidationSchema)
      setInitialValues(talkSpeedInitialValues)
    }
  }

  return (
    <PlaybookEditor entry={entry}>
      {({ handleUpdate, handleCancel, readOnly }) => (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleUpdate}
          enableReinitialize
        >
          {({ values, handleSubmit, errors, ...formikProps }) => (
            <>
              <NotificationsForm
                readOnly={readOnly}
                values={values}
                errors={errors}
                entryId={entryId}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                handleResetForm={handleResetForm}
                {...formikProps}
              />
              <PlaybookPreview values={values} section={section}>
                <div>{values.display?.reason}</div>
              </PlaybookPreview>
            </>
          )}
        </Formik>
      )}
    </PlaybookEditor>
  )
}
