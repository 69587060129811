import { setOrganizationTagCategories } from './organizationTagCategories.redux'
import { fetchingAPI, apiService } from '../../api'

// TODO: Refactor toggleLoading
export const fetchOrganizationTagCategories =
  ({ organizationId, toggleLoading }) =>
  async (dispatch) => {
    try {
      const tagCategories = await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags/categories`,
        'GET',
        dispatch
      )
      dispatch(setOrganizationTagCategories(tagCategories))
      if (toggleLoading) toggleLoading()
    } catch (err) {
      console.error(err)
      if (toggleLoading) toggleLoading()
    }
  }
