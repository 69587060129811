import { fetchingAPI, apiService } from '@/api'
import { setCustomBranding, setLoading } from './customBranding.redux'

export const fetchCustomBranding = (organizationId) => async (dispatch) => {
  dispatch(setLoading(true))

  try {
    const customBranding = await fetchingAPI(
      `${apiService.web}/api/custom_branding/${organizationId}`,
      'GET',
      dispatch
    )
    dispatch(setCustomBranding(customBranding))
  } catch (err) {
    // catching error
  } finally {
    dispatch(setLoading(false))
  }
}
