import React from 'react'
import { isEmpty } from 'lodash'
import { truncateAndAddPopup } from '@/views/RealtimeCoaching/helpers'
import { Popup } from 'semantic-ui-react'
import { getItemTypeLabel } from '@/views/RealtimeCoaching/Alerts/OrgLevelAlertWizard/components/helpers'

export const TriggerItemContainer = ({ formikTriggerItems, onRemoveTriggerItem, form }) => {
  const characterLimit = 80

  const getTriggerItemLabelWithHover = (playbookLabel, itemType, triggerItemLabel) => {
    const isPopupDisabled = triggerItemLabel.length < characterLimit
    const truncatedDescription = `${triggerItemLabel.slice(0, characterLimit)}…`

    return (
      <Popup
        data-testid="trigger-description-popup"
        position="top center"
        header={`${playbookLabel} - ${itemType}`}
        content={
          <div className="alert-wizard__trigger-description-popup">
            <p>{triggerItemLabel}</p>
          </div>
        }
        trigger={<span>{isPopupDisabled ? triggerItemLabel : truncatedDescription}</span>}
        disabled={isPopupDisabled}
        key={triggerItemLabel}
      />
    )
  }

  const getRemoveButton = (playbookCid, triggerItemPath, form) => {
    return (
      <span
        onClick={() => onRemoveTriggerItem(playbookCid, triggerItemPath, form)}
        className="alert-wizard__remove-button rtc__inline_button"
      >
        Remove
      </span>
    )
  }

  return (
    <>
      <label className="alert-wizard__field-label-text">Trigger Item(s)</label>
      <div className="alert-wizard__trigger-items-container">
        {isEmpty(formikTriggerItems) && (
          <div className="alert-wizard__trigger-empty-div">
            <div className="alert-wizard__no-trigger-items">No Trigger items</div>
            <div className="alert-wizard__no-trigger-items-description">
              Add Playbook Items as triggers for this alert. You can add triggers using the section
              above.
            </div>
          </div>
        )}

        {!isEmpty(formikTriggerItems) &&
          formikTriggerItems.map(
            ({
              playbookLabel,
              playbookCid,
              itemType,
              triggerItemLabel,
              triggerItemPath,
              requiredBefore,
            }) => (
              <div key={playbookCid + triggerItemPath} className="alert-wizard__trigger-item">
                <span
                  data-testid="trigger-item-description"
                  className="alert-wizard__trigger-item-description"
                >
                  {truncateAndAddPopup(playbookLabel, 90)}
                  <span className="alert-wizard__trigger-item-bullet-separator">•</span>
                  {getItemTypeLabel(itemType)}
                </span>
                <span data-testid="trigger-item-name" className="alert-wizard__trigger-item-name">
                  {getTriggerItemLabelWithHover(playbookLabel, itemType, triggerItemLabel)}
                </span>
                {itemType === 'Checklist-timing' ? (
                  <>
                    <div className="alert-wizard__time-constrained">
                      <span className="alert-wizard__time-constrained-text">Time Constrained</span>
                      Start of call - {Math.round(requiredBefore / 60)} mins
                      {getRemoveButton(playbookCid, triggerItemPath, form)}
                    </div>
                  </>
                ) : (
                  getRemoveButton(playbookCid, triggerItemPath, form)
                )}
              </div>
            )
          )}
        <div id="trigger-list-bottom" />
      </div>
    </>
  )
}
