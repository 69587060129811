// Action Types
export const SET_CURRENT_ORGANIZATION = 'auth/setCurrentOrganization'

// Action Creators
export const setCurrentOrganization = (payload) => ({ type: SET_CURRENT_ORGANIZATION, payload })

// State
export const initialState = {}

// Reducer
export default function currentOrganizationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_ORGANIZATION:
      return action.payload
    default:
      return state
  }
}
