import React, { useState } from 'react'
import { Accordion } from 'semantic-ui-react'
import { useSelector } from 'react-redux'

import { Pill } from '@/components/pills/Pill'

import './scoreAccordion.css'

export const returnScoreColor = (score, scorecardConfig) => {
  if (score > scorecardConfig?.exceeds_threshold) return 'exceeds_threshold'
  if (score > scorecardConfig?.meets_threshold) return 'meets_threshold'
  if (score > scorecardConfig?.improvement_threshold) return 'improvement_threshold'
  return 'failure'
}

export const ScoreAccordion = ({
  score = 0,
  title = 'My section',
  children,
  sectionData = null,
  getNotableCalls,
  aggregateSection = false,
  showAllAccordions = false,
}) => {
  const [active, setActive] = useState(false)
  const { data } = useSelector((state) => state.scorecards)

  const toggleAccordion = () => {
    if (!active && aggregateSection) {
      getNotableCalls(sectionData, data.targetScorecardConfig, score)
    }
    setActive(!active)
  }

  const scoreColor = aggregateSection ? returnScoreColor(score, data.targetScorecardConfig) : null

  return (
    <Accordion styled className="score-accordion" data-testid="score-accordion">
      <Accordion.Title
        active={active}
        index={0}
        onClick={toggleAccordion}
        className="score-accordion__title-section"
        data-testid="score-accordion-title-section"
      >
        <span className="score-accordion__title" data-testid="score-accordion-title">
          {title}
        </span>
        <Pill
          emphasized
          critical={scoreColor === 'failure'}
          caution={scoreColor === 'improvement_threshold'}
          warning={scoreColor === 'meets_threshold'}
          success={scoreColor === 'exceeds_threshold'}
          data-testid="score-accordion-score-section"
          style={{ marginRight: '0.5rem' }}
        >
          {`${score}%`}
        </Pill>
      </Accordion.Title>
      <Accordion.Content active={showAllAccordions || active} className="score-accordion__content">
        {children}
      </Accordion.Content>
    </Accordion>
  )
}
