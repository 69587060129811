import { cloneDeep } from 'lodash'
import { Button } from 'semantic-ui-react'
import React from 'react'
import { isFullySelected, isPartiallySelected } from '@/components/tables/NestedTable/helpers'
import { createBasicCriteriaCell } from '../MeasureScoresTable/MeasureScoresTable'

import {
  ScorecardCriteriaButton,
  ScorecardCriteriaNameInput,
  ScorecardCriteriaWeightInput,
} from '../ScorecardCriteria/ScorecardCriteriaSelector'

export const parseCriteriaListToObject = (scorecardConfig) => {
  if (!scorecardConfig || !scorecardConfig.sections || !scorecardConfig.sections.length) return null

  const parsedConfig = cloneDeep(scorecardConfig)

  parsedConfig.sections.forEach((section) => {
    if (!section.measures || !section.measures.length) return

    section.measures.forEach((measure) => {
      if (!measure.criteria || !measure.criteria.length) return

      // ignoring this because i'm trying to reassign
      // eslint-disable-next-line no-param-reassign
      measure.criteria = measure.criteria.reduce(
        (measureObject, criteria) => ({
          ...measureObject,
          [criteria.playbook_ref_id]: criteria,
        }),
        {}
      )
    })
  })

  return parsedConfig
}
export const deckCategoryHasDeckItems = (deckCategory) => {
  const isArray = Array.isArray(deckCategory.display)

  // display is either an array of display objects, or one display object
  // we check for items differently based on datatype
  const hasDecklistItems = isArray
    ? deckCategory.display.some((displayItems) => Object.keys(displayItems).includes('decklist'))
    : Object.keys(deckCategory.display).includes('decklist')
  return hasDecklistItems
}

export const findDeckItemsInDeckDisplay = (deckDisplay) => {
  const isArray = Array.isArray(deckDisplay)

  // display[0] is the html - display[1] has the entry data
  const deckItemsObject = isArray
    ? deckDisplay.find((displayItems) => Object.keys(displayItems).includes('decklist'))
    : deckDisplay

  return deckItemsObject.decklist
}

export const scorecardHasCriteria = (scorecardConfig) => {
  for (const section of scorecardConfig.sections) {
    for (const measure of section.measures) {
      if (Object.keys(measure.criteria).length > 0) {
        return true
      }
    }
  }
  return false
}

// This sorts scorecard criteria, rather than the playbook side
export const sortCriteriaObjects = (criteriaObjects, sortOrder) => {
  if (sortOrder) {
    return criteriaObjects.sort((a, b) => {
      return sortOrder.indexOf(a.playbook_ref_id) - sortOrder.indexOf(b.playbook_ref_id)
    })
  }
  return criteriaObjects
}

// This sorts entries from the playbook side, rather than scorecard criteria
export const sortPlaybookEntryObjects = (criteriaObjects, sortOrder) => {
  return criteriaObjects.sort((a, b) => {
    return sortOrder.indexOf(a.entryId) - sortOrder.indexOf(b.entryId)
  })
}

export const flattenPostcallData = (scorecardMeasure, postcallCategories) => {
  const flattenedData = []

  Object.values(postcallCategories).forEach((categoryData) => {
    let matchingCriteria = []
    // if this statement is true, then we are on a measure, not a measure score
    if (scorecardMeasure.criteria) {
      matchingCriteria = scorecardMeasure.criteria
        .filter((criteria) => Object.keys(categoryData.entries).includes(criteria.playbook_ref_id))
        .map((criteria) => ({
          ...criteria,
          must_be_present: {
            value: criteria ? criteria.must_be_present : true,
            as: (
              <Button
                disabled
                compact
                content={criteria.must_be_present ? 'Must Be Present' : 'Must Not Be Present'}
              />
            ),
          },
          criteria_type: criteria ? criteria.criteria_type : 'automated',
          weight: {
            value: criteria ? criteria.weight : '1',
            as: <Button disabled compact content={criteria.weight} />,
            cellProps: {
              collapsing: true,
            },
          },
        }))
      // if this statement is false, then we are on a measure score
    } else {
      matchingCriteria = Object.values(scorecardMeasure)
        .filter((criteriaScore) =>
          Object.keys(categoryData.entries).includes(criteriaScore.playbook_ref_id)
        )
        .map((criteriaScore) => ({
          ...criteriaScore,
          name: {
            value: criteriaScore.name,
            as: createBasicCriteriaCell(criteriaScore),
          },
          must_be_present: {
            value: criteriaScore ? criteriaScore.must_be_present : true,
            as: (
              <Button
                disabled
                compact
                content={criteriaScore.must_be_present ? 'Must Be Present' : 'Must Not Be Present'}
              />
            ),
          },
          criteria_type: criteriaScore.criteria_type || 'automated',
          weight: {
            value: criteriaScore ? criteriaScore.weight : '1',
            as: <Button disabled compact content={criteriaScore.weight} />,
            cellProps: {
              collapsing: true,
            },
          },
        }))
    }

    if (matchingCriteria.length) {
      matchingCriteria = sortCriteriaObjects(matchingCriteria, categoryData.order)

      const categoryObject = {
        name: {
          value: categoryData.name,
          as: <p>{categoryData.name}</p>,
        },
        nestedField: 'criteria',
        criteria: matchingCriteria,
      }
      flattenedData.push(categoryObject)
    }
  })
  return flattenedData
}

const parseDynamicPromptScoresData = (scorecardMeasure, deckItems) => {
  return Object.values(scorecardMeasure)
    .filter((criteriaScore) =>
      Object.keys(deckItems.entries).includes(criteriaScore.playbook_ref_id)
    )
    .map((criteriaScore) => ({
      condition_type: criteriaScore.condition_type,
      hit: criteriaScore.hit,
      name: {
        value: criteriaScore.name,
        as: createBasicCriteriaCell(criteriaScore),
      },
      must_be_present: {
        value: criteriaScore.must_be_present,
        as: (
          <Button
            disabled
            compact
            content={criteriaScore.must_be_present ? 'Must Be Present' : 'Must Not Be Present'}
          />
        ),
      },
      criteria_type: criteriaScore.criteria_type || 'automated',
      weight: {
        value: criteriaScore.weight,
        as: <Button disabled compact content={criteriaScore.weight} />,
        cellProps: {
          collapsing: true,
        },
      },
    }))
}

const parseDynamicPromptScorecardConfigData = (scorecardMeasure, deckItems) => {
  return scorecardMeasure.criteria
    .filter((criteriaScore) =>
      Object.keys(deckItems.entries).includes(criteriaScore.playbook_ref_id)
    )
    .map((criteriaScore) => ({
      name: criteriaScore.name,
      must_be_present: {
        value: criteriaScore.must_be_present,
        as: (
          <Button
            disabled
            compact
            content={criteriaScore.must_be_present ? 'Must Be Present' : 'Must Not Be Present'}
          />
        ),
      },
      criteria_type: criteriaScore.criteria_type || 'automated',
      weight: {
        value: criteriaScore.weight,
        as: <Button disabled compact content={criteriaScore.weight} />,
        cellProps: {
          collapsing: true,
        },
      },
    }))
}

const flattenDynamicPromptData = (scorecardMeasure, decklistCategories) => {
  // need to ask if we're doing trigger names or category names or both.
  const flattenedData = [
    //    if trigger names, then { triggerName: 'name', criteria: [...]}, current table works
    //    if category and trigger, then { categoryName: 'name', triggers: [{ triggerName: 'name', criteria: [...]}] }, nested table required
  ]

  Object.values(decklistCategories).forEach((categoryData) => {
    Object.values(categoryData.entries).forEach((triggerData) => {
      const containsDeckItems = deckCategoryHasDeckItems(triggerData)

      if (containsDeckItems) {
        const triggerObject = {
          name: {
            value: triggerData.name,
            as: (
              <p>
                {categoryData.name} &middot; {triggerData.name}
              </p>
            ),
          },
          nestedField: 'criteria',
        }

        const deckItems = findDeckItemsInDeckDisplay(triggerData.display)
        Object.values(deckItems.entries).forEach(() => {
          triggerObject.criteria = Object.keys(scorecardMeasure).includes('criteria')
            ? parseDynamicPromptScorecardConfigData(scorecardMeasure, deckItems)
            : parseDynamicPromptScoresData(scorecardMeasure, deckItems)
        })

        if (triggerObject.criteria.length) {
          flattenedData.push(triggerObject)
        }
      }
    })
  })

  return flattenedData
}

const parseFlatSectionsToRows = (scorecardMeasure, sectionData) => {
  let rows = []
  if (scorecardMeasure.criteria) {
    rows = scorecardMeasure.criteria
      .filter((criteria) => Object.keys(sectionData?.entries).includes(criteria?.playbook_ref_id))
      .map((criteria) => ({
        ...criteria,
        must_be_present: {
          value: criteria ? criteria.must_be_present : true,
          as: (
            <Button
              disabled
              compact
              content={criteria.must_be_present ? 'Must Be Present' : 'Must Not Be Present'}
            />
          ),
        },
        criteria_type: criteria ? criteria.criteria_type : 'automated',
        weight: {
          value: criteria ? criteria.weight : '1',
          as: <Button disabled compact content={criteria.weight} />,
          cellProps: {
            collapsing: true,
          },
        },
      }))
  } else {
    rows = Object.values(scorecardMeasure)
      .filter((criteriaScore) =>
        Object.keys(sectionData.entries).includes(criteriaScore.playbook_ref_id)
      )
      .map((criteriaScore) => ({
        ...criteriaScore,
        name: {
          value: criteriaScore.name,
          as: createBasicCriteriaCell(criteriaScore),
        },
        must_be_present: {
          value: criteriaScore ? criteriaScore.must_be_present : true,
          as: (
            <Button
              disabled
              compact
              content={criteriaScore.must_be_present ? 'Must Be Present' : 'Must Not Be Present'}
            />
          ),
        },
        criteria_type: criteriaScore?.criteria_type || 'automated',
        weight: {
          value: criteriaScore ? criteriaScore.weight : '1',
          as: <Button disabled compact content={criteriaScore.weight} />,
          cellProps: {
            collapsing: true,
          },
        },
        hit: true,
      }))
  }

  if (sectionData.order) {
    rows = sortCriteriaObjects(rows, sectionData.order)
  }

  return rows
}

export const flattenCopilotSection = (scorecardMeasure) => {
  const rows = []
  // This is kind of a hack. This endpoint does not return a good response, it returns ids with one item called condition_type.
  // I don't want to refactor all of this as it is eventually all going to be reworked.
  // For now, I just filter out condition type key and loop through the rest.
  // This also returns a list because the rest of this mess expects a list. Cant wait to put this code out of its misery.
  for (const key in scorecardMeasure) {
    if (key !== 'condition_type') {
      const criteria = scorecardMeasure[key]
      rows.push({
        ...criteria,
        name: {
          value: criteria.name,
          as: createBasicCriteriaCell(criteria),
        },
        must_be_present: {
          value: criteria ? criteria.must_be_present : true,
          as: (
            <Button
              disabled
              compact
              content={criteria.must_be_present ? 'Must Be Present' : 'Must Not Be Present'}
            />
          ),
        },
        criteria_type: criteria ? criteria.criteria_type : 'automated',
        weight: {
          value: criteria ? criteria.weight : '1',
          as: <Button disabled compact content={criteria.weight} />,
          cellProps: {
            collapsing: true,
          },
        },
      })
    }
  }

  const copilotSectionObject = {
    sectionName: 'Copilot',
    conditionType: scorecardMeasure.condition_type,
    criteria: rows,
  }
  return [copilotSectionObject]
}

export const sortCriteriaByPlaybookSections = (scorecardMeasure, playbookBody) => {
  const bodyObject = playbookBody
  const sortedMeasureCriteria = []
  if (bodyObject) {
    Object.entries(bodyObject).forEach(([sectionName, sectionData]) => {
      const formattedSectionName =
        sectionName === 'classified_postcall'
          ? 'Postcall'
          : sectionName[0].toUpperCase() + sectionName.slice(1).toLowerCase()

      const sectionObject = {
        sectionName: formattedSectionName,
        conditionType: scorecardMeasure.condition_type,
      }

      if (sectionName === 'deck') {
        sectionObject.criteria = flattenDynamicPromptData(scorecardMeasure, sectionData.entries)
      } else if (sectionName === 'classified_postcall') {
        sectionObject.criteria = flattenPostcallData(scorecardMeasure, sectionData.entries)
      } else {
        sectionObject.criteria = parseFlatSectionsToRows(scorecardMeasure, sectionData)
      }

      if (sectionObject.criteria?.length) {
        sectionObject.criteria = sortCriteriaObjects(sectionObject.criteria, sectionData.order)
        sortedMeasureCriteria.push(sectionObject)
      }
    })
  }
  return sortedMeasureCriteria
}

export const convertMeasureDraftToNaturalLanguage = (measureDraft) => {
  if (!measureDraft || !measureDraft.criteria || !measureDraft.criteria.length) return []

  const naturalLanguageCriteriaList = []
  // tracking the index of triggers in the list allows us to update criteria easily on decklist criteria
  // key is triggered_playbook_ref_id and value is the trigger's index in naturalLanguageCriteriaList
  const triggerIndexTracker = {}

  measureDraft.criteria.forEach((criteria) => {
    const currentCriteriaObject = {
      name: criteria.name,
      weight: criteria.weight,
      criteria_type: criteria ? criteria.criteria_type : 'automated',
      mustBePresent: criteria.must_be_present,
    }

    // if criteria has trigger, it's a decklist item and requires special handling
    if (criteria.triggered_playbook_ref_id) {
      // if trigger id not in tracker, it means we can push to the list
      if (!triggerIndexTracker[criteria.triggered_playbook_ref_id]) {
        triggerIndexTracker[criteria.triggered_playbook_ref_id] = naturalLanguageCriteriaList.length
        naturalLanguageCriteriaList.push({
          name: criteria.trigger_name,
          criteria: [currentCriteriaObject],
        })
      }

      // otherwise, if trigger id IS in the tracker, we just update the trigger criteria list
      else {
        const triggerIndex = triggerIndexTracker[criteria.triggered_playbook_ref_id]
        naturalLanguageCriteriaList[triggerIndex].criteria.push(currentCriteriaObject)
      }
    }

    // if not a decklist, we just push it to the list
    else {
      naturalLanguageCriteriaList.push(currentCriteriaObject)
    }
  })

  return naturalLanguageCriteriaList
}

export const formatPostCallObjectForTable = (
  postcallBody,
  filterBy,
  findInMeasure,
  toggleCriteriaSettings,
  changeCriteriaWeight
) => {
  const postCallObject = Object.entries(postcallBody.entries).reduce(
    (reducer, [entryDataId, entryData]) => {
      const sortOrder = entryData.order
      const entries = Object.entries(entryData.entries)
      let criteriaObjects = entries
        // eslint-disable-next-line no-unused-vars
        .filter(([id, entryValue]) =>
          entryValue.name.toLowerCase().includes(filterBy.toLowerCase())
        )
        .map(([entryId, entry]) => {
          const criteria = findInMeasure(entryId)
          return {
            entryId,
            key: entryId,
            name: entry.name || !!criteria,
            active: criteria?.active || !!criteria,
            selected: criteria?.active || !!criteria,
            weight: {
              value: criteria ? criteria.weight : '1',
              as: (
                <ScorecardCriteriaWeightInput
                  criteria={criteria}
                  changeCriteriaWeight={changeCriteriaWeight}
                />
              ),
              cellProps: {
                collapsing: true,
              },
            },
            criteria_type: criteria ? criteria.criteria_type : 'automated',
            must_be_present: {
              value: criteria ? criteria.must_be_present : true,
              as: (
                <ScorecardCriteriaButton
                  mustBePresent={criteria ? criteria.must_be_present : true}
                  criteria={criteria}
                  toggleMustBePresent={toggleCriteriaSettings}
                />
              ),
            },
          }
        })
      if (sortOrder) {
        criteriaObjects = sortCriteriaObjects(criteriaObjects, sortOrder)
      }
      if (criteriaObjects.length === 0) {
        return [...reducer]
      }
      return [
        ...reducer,
        {
          name: entryData.name,
          key: entryDataId,
          nestedField: 'triggers',
          triggers: criteriaObjects,
          partiallySelected: isPartiallySelected(criteriaObjects),
          selected: isFullySelected(criteriaObjects),
        },
      ]
    },
    []
  )
  return postCallObject
}

export const formatObjectsForTable = (
  section,
  filterBy,
  findInMeasure,
  toggleCriteriaSettings,
  changeCriteriaWeight
) => {
  return (
    Object.entries(section.entries)
      // eslint-disable-next-line no-unused-vars
      .filter(([id, entryData]) => entryData.name.toLowerCase().includes(filterBy.toLowerCase()))
      .map(([entryId, entry]) => {
        const criteria = findInMeasure(entryId)
        return {
          entryId,
          key: entryId,
          name: entry.name || !!criteria,
          active: criteria?.active || !!criteria,
          selected: criteria?.active || !!criteria,
          weight: {
            value: criteria ? criteria.weight : '1',
            as: (
              <ScorecardCriteriaWeightInput
                criteria={criteria}
                changeCriteriaWeight={changeCriteriaWeight}
              />
            ),
            cellProps: {
              collapsing: true,
            },
          },
          criteria_type: criteria ? criteria.criteria_type : 'automated',
          must_be_present: {
            value: criteria ? criteria.must_be_present : true,
            as: (
              <ScorecardCriteriaButton
                mustBePresent={criteria ? criteria.must_be_present : true}
                criteria={criteria}
                toggleMustBePresent={toggleCriteriaSettings}
              />
            ),
          },
        }
      })
  )
}

export const parseManualToCriteriaLists = (
  measureDraft,
  filterBy,
  toggleCriteriaSettings,
  changeCriteriaWeight,
  changeCriteriaName
) => {
  return Object.entries(measureDraft.criteria)
    .filter(
      // eslint-disable-next-line no-unused-vars
      ([id, entryData]) =>
        entryData.criteria_type === 'manual' &&
        entryData.name.toLowerCase().includes(filterBy.toLowerCase())
    )
    .map(([entryId, criteria]) => {
      return {
        entryId,
        key: entryId,
        name: {
          value: criteria ? criteria.name : 'Manual Criteria',
          as: (
            <ScorecardCriteriaNameInput
              criteria={criteria}
              changeCriteriaName={changeCriteriaName}
            />
          ),
        },
        active: criteria?.active,
        selected: criteria?.active,
        weight: {
          value: criteria ? criteria.weight : '1',
          as: (
            <ScorecardCriteriaWeightInput
              criteria={criteria}
              changeCriteriaWeight={changeCriteriaWeight}
            />
          ),
          cellProps: {
            collapsing: true,
          },
        },
        criteria_type: criteria ? criteria.criteria_type : 'automated',
        must_be_present: {
          value: criteria ? criteria.must_be_present : true,
          as: (
            <ScorecardCriteriaButton
              mustBePresent={criteria ? criteria.must_be_present : true}
              criteria={criteria}
              toggleMustBePresent={toggleCriteriaSettings}
            />
          ),
        },
      }
    })
}

// make a new one for scores as opposed to scorecards
export const parseDecklistToCriteriaLists = (
  decklist,
  filterBy,
  findInMeasure,
  toggleCriteriaSettings,
  changeCriteriaWeight
) => {
  const data = Object.entries(decklist.entries).reduce(
    (reducer, [deckCategoryId, deckCategoryData]) => {
      // for each decklist category, we build out the trigger list
      const triggerData = Object.entries(deckCategoryData.entries).reduce(
        (reducer, [deckTriggerId, deckTriggerData]) => {
          // if there are no deck items, there's nothing more to do
          const hasDecklistItems = deckCategoryHasDeckItems(deckTriggerData)
          if (!hasDecklistItems) return reducer

          // display[0] is the htm - display[1] has the entry data
          const deckItemsObject = findDeckItemsInDeckDisplay(deckTriggerData.display)
          //  for each trigger, we build out the list of deckItems
          const deckItems = Object.entries(deckItemsObject.entries)
            // eslint-disable-next-line no-unused-vars
            .filter(([id, entryData]) =>
              entryData.name.toLowerCase().includes(filterBy.toLowerCase())
            )
            .map(([deckItemId, deckItemData]) => {
              const criteria = findInMeasure(deckItemId)
              return {
                name: deckItemData.name,
                triggered_playbook_ref_id: deckTriggerId,
                trigger_name: deckTriggerData.name,
                entryId: deckItemId,
                key: deckItemId,
                active: criteria?.active || !!criteria,
                selected: criteria?.active || !!criteria,
                must_be_present: {
                  value: criteria ? criteria.must_be_present : true,
                  as: (
                    <ScorecardCriteriaButton
                      mustBePresent={criteria ? criteria.must_be_present : true}
                      criteria={criteria}
                      toggleMustBePresent={toggleCriteriaSettings}
                    />
                  ),
                },
                criteria_type: criteria ? criteria.criteria_type : 'automated',
                weight: {
                  value: criteria ? criteria.weight : '1',
                  as: (
                    <ScorecardCriteriaWeightInput
                      criteria={criteria}
                      changeCriteriaWeight={changeCriteriaWeight}
                    />
                  ),
                  cellProps: {
                    collapsing: true,
                  },
                },
              }
            })
          if (deckItems.length === 0) return reducer
          return [
            ...reducer,
            {
              name: deckTriggerData.name,
              key: deckTriggerId,
              categoryId: deckCategoryId,
              nestedField: 'deckItems',
              partiallySelected: isPartiallySelected(deckItems),
              selected: isFullySelected(deckItems),
              deckItems,
            },
          ]
        },
        []
      )

      if (!triggerData.length) return reducer

      return [
        ...reducer,
        {
          name: deckCategoryData.name,
          key: deckCategoryId,
          triggers: triggerData,
          nestedField: 'triggers',
          partiallySelected: isPartiallySelected(triggerData),
          selected: isFullySelected(triggerData),
        },
      ]
    },
    []
  )

  return data
}

export const convertPlaybookBodyToCriteriaObjects = (
  playbookBody,
  section,
  filterBy,
  findInMeasure,
  toggleCriteriaSettings,
  changeCriteriaWeight,
  changeCriteriaName,
  measureDraft
) => {
  if (section !== 'manual' && !playbookBody[section]) {
    return null
  }
  let criteriaObjects = {}
  if (section === 'deck') {
    return parseDecklistToCriteriaLists(
      playbookBody.deck,
      filterBy,
      findInMeasure,
      toggleCriteriaSettings,
      changeCriteriaWeight
    )
  }
  if (section === 'classified_postcall') {
    return formatPostCallObjectForTable(
      playbookBody[section],
      filterBy,
      findInMeasure,
      toggleCriteriaSettings,
      changeCriteriaWeight
    )
  }
  if (section === 'manual') {
    return parseManualToCriteriaLists(
      measureDraft,
      filterBy,
      toggleCriteriaSettings,
      changeCriteriaWeight,
      changeCriteriaName
    )
  }
  criteriaObjects = formatObjectsForTable(
    playbookBody[section],
    filterBy,
    findInMeasure,
    toggleCriteriaSettings,
    changeCriteriaWeight
  )
  const sortOrder = playbookBody[section].order
  if (sortOrder) {
    criteriaObjects = sortPlaybookEntryObjects(criteriaObjects, sortOrder)
  }
  return criteriaObjects.length ? criteriaObjects : null
}

export const replaceSectionInScorecardConfig = (scorecardConfig, section) => {
  if (!scorecardConfig || !section) {
    return null
  }

  const scorecardCopy = JSON.parse(JSON.stringify(scorecardConfig))

  scorecardCopy.sections = scorecardCopy.sections.map((sectionCopy) => {
    if (sectionCopy.id === section.id) {
      const updatedSection = section
      return updatedSection
    }

    return sectionCopy
  })

  return scorecardCopy
}

export const addMeasureInScorecardConfig = (scorecardConfig, measureName, scorecardSection) => {
  if (!scorecardConfig.sections || !measureName) {
    return null
  }
  const scorecardCopy = JSON.parse(JSON.stringify(scorecardConfig))
  const sectionMeasures = scorecardCopy.sections.find(
    (measure) => measure.ssid === scorecardSection.ssid
  )
  sectionMeasures.measures.push({ name: measureName })
  return scorecardCopy
}

export const updateMeasuresInScorecardConfig = (scorecardConfig, measures, scorecardSection) => {
  const scorecardCopy = JSON.parse(JSON.stringify(scorecardConfig))
  const sectionMeasures = scorecardCopy.sections.find(
    (measure) => measure.ssid === scorecardSection.ssid
  )
  sectionMeasures.measures = measures
  return scorecardCopy
}

export const addSectionInScorecardConfig = (scorecardConfig, sectionName) => {
  if (!scorecardConfig.sections || !sectionName) {
    return null
  }
  const scorecardCopy = cloneDeep(scorecardConfig)
  scorecardCopy.sections.push({
    name: sectionName,
    weight: 0,
    active: false,
  })
  return scorecardCopy
}

export const increaseSortOrder = (oldIndex, newIndex, draggableId, items) => {
  const updatedItems = items.map((item) => {
    const updatedItem = { ...item }
    if (
      updatedItem.order_id < oldIndex &&
      updatedItem.order_id >= newIndex &&
      parseInt(draggableId, 10) !== updatedItem.id
    ) {
      const newId = updatedItem.order_id + 1
      updatedItem.order_id = newId
    }
    return updatedItem
  })
  updatedItems[oldIndex].order_id = newIndex
  return updatedItems
}

export const decreaseSortOrder = (oldIndex, newIndex, draggableId, items) => {
  const updatedItems = items.map((item) => {
    const updatedItem = { ...item }
    if (
      updatedItem.order_id > oldIndex &&
      updatedItem.order_id <= newIndex &&
      parseInt(draggableId, 10) !== updatedItem.id
    ) {
      const newId = updatedItem.order_id - 1
      updatedItem.order_id = newId
    }
    return updatedItem
  })
  updatedItems[oldIndex].order_id = newIndex
  return updatedItems
}

export const giveOrderId = (item, index) => {
  return {
    ...item,
    order_id: index,
  }
}

export const handleDrag = (result, sections) => {
  const { draggableId } = result
  const oldIndex = result.source.index
  const newIndex = result.destination.index
  let updateSections
  if (result.destination.index > result.source.index) {
    updateSections = decreaseSortOrder(oldIndex, newIndex, draggableId, sections)
  }
  if (result.destination.index < result.source.index) {
    updateSections = increaseSortOrder(oldIndex, newIndex, draggableId, sections)
  }
  return updateSections
}

export const editNameInObject = (formValue, formFields, sections) => {
  return sections.map((section) => {
    const newSection = section
    if (section.id === formFields.id) {
      newSection.name = formValue
    }
    return newSection
  })
}

export const formatScorecardType = (type) => {
  switch (type) {
    case 'manual':
      return 'Manual'
    case 'automated':
      return 'Automated'
    case 'copilot':
      return 'QA Copilot'
    default:
      return 'Invalid Type'
  }
}
