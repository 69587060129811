import React from 'react'
import { useSelector } from 'react-redux'

import Widget from '../../../../components/widget/Widget'
import { ExportsTable } from './ExportsTable'
import './TableSection.css'

export const TableSection = () => {
  const { loading } = useSelector((state) => state.scorecards)

  return (
    <div className="scorecard-exports_table-wrapper">
      <Widget
        label="Preview Data"
        subtitle="(Shows up to 20 results)"
        showCsvDownload={!loading.scores && !loading.advancedExportsStreamedCsv}
      >
        <ExportsTable />
      </Widget>
    </div>
  )
}
