import produce from 'immer'

import {
  initialPlaybookBody,
  initialPlaybookWarningsBody,
} from '@/views/Playbooks/playbook.helpers'

import {
  add,
  edit,
  remove,
  lock,
  win,
  reorder,
  reorderAndMove,
  addChecklistVariations,
  addChecklistItemVariations,
} from './playbook.helpers'

// Action Types
export const SET_LOADING = 'playbooks/setLoading'
export const SET_PLAYBOOK = 'playbooks/setPlaybook'
export const SET_PLAYBOOK_WARNINGS = 'playbooks/setPlaybookWarnings'
export const SET_PLAYBOOK_SECTION_WARNINGS = 'playbooks/setPlaybookSectionWarnings'
export const SET_PLAYBOOK_ENTRY_WARNINGS = 'playbooks/setPlaybookEntryWarnings'
export const SET_CHECKLIST_VARIATIONS = 'playbooks/setChecklistVariations'
export const SET_NOVEL_CHECKLIST_ITEMS = 'playbooks/setNovelChecklistItems'
export const TOGGLE_NOVEL_CHECKLIST_VISIBLE = 'playbooks/toggleNovelChecklistItems'
export const SET_CHECKLIST_ITEM_VARIATIONS = 'playbooks/setChecklistItemVariations'
export const SET_VALIDATION_ERROR = 'playbooks/setValidationError'
export const CLEAR_VALIDATION_ERROR = 'playbooks/clearValidationError'
export const SET_PLAYBOOK_VERSIONS = 'playbooks/setVersions'
export const ACTIVATE_PLAYBOOK_VERSION = 'playbooks/activateVersion'
export const UPDATE_PLAYBOOK_NAME = 'playbooks/updateName'
export const SET_RTN_PROMPT = 'playbooks/setRTNPrompt'
export const SET_RTN_MODEL_OPTIONS = 'playbooks/setRTNModelOptions'

export const CREATE_ENTRY = 'playbooks/createEntry'
export const UPDATE_ENTRY = 'playbooks/updateEntry'
export const DELETE_ENTRY = 'playbooks/deleteEntry'
export const REORDER_ENTRY = 'playbooks/reorderEntry'
export const LOCK_ENTRY = 'playbooks/lockEntry'
export const WIN_ENTRY = 'playbooks/winEntry'
export const REORDER_MOVE_ENTRY = 'playbooks/reorderAndMoveEntry'
export const UPDATE_CATEGORY_NAME = 'playbooks/updateCategoryName'

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setPlaybook = (payload) => ({ type: SET_PLAYBOOK, payload })
export const setPlaybookWarnings = (payload) => ({ type: SET_PLAYBOOK_WARNINGS, payload })
export const setPlaybookSectionWarnings = (accessor) => (payload) => ({ type: SET_PLAYBOOK_SECTION_WARNINGS,  payload,  accessor }) /* prettier-ignore */
export const setPlaybookEntryWarnings = (accessor) => (payload) => ({ type: SET_PLAYBOOK_ENTRY_WARNINGS, payload, accessor }) /* prettier-ignore */
export const setChecklistVariations = (payload) => ({ type: SET_CHECKLIST_VARIATIONS, payload }) /* prettier-ignore */
export const setNovelChecklistItems = (payload) => ({ type: SET_NOVEL_CHECKLIST_ITEMS, payload }) /* prettier-ignore */
export const toggleNovelChecklistItems = (payload) => ({
  type: TOGGLE_NOVEL_CHECKLIST_VISIBLE,
  payload,
})
export const setChecklistItemVariations = (payload, checklistItemId) => ({ type: SET_CHECKLIST_ITEM_VARIATIONS, checklistItemId, payload }) /* prettier-ignore */
export const setValidationError = (payload) => ({ type: SET_VALIDATION_ERROR, payload })
export const clearValidationError = () => ({ type: CLEAR_VALIDATION_ERROR })
export const setPlaybookVersions = (payload) => ({ type: SET_PLAYBOOK_VERSIONS, payload })
export const activatePlaybookVersion = () => ({ type: ACTIVATE_PLAYBOOK_VERSION })
export const updatePlaybookName = (payload) => ({ type: UPDATE_PLAYBOOK_NAME, payload, sanitizeType: 'playbook' }) /* prettier-ignore */
export const setRTNPrompt = (payload) => ({ type: SET_RTN_PROMPT, payload })
export const setRTNModelOptions = (payload) => ({ type: SET_RTN_MODEL_OPTIONS, payload })

export const createEntry = (accessor) => (payload) => ({ type: CREATE_ENTRY, payload, accessor, sanitizeType: 'entry' }) /* prettier-ignore */
export const updateEntry = (accessor) => (payload) => ({ type: UPDATE_ENTRY, payload, accessor, sanitizeType: 'entry' }) /* prettier-ignore */
export const deleteEntry = (accessor) => (payload) => ({ type: DELETE_ENTRY, payload, accessor })
export const reorderEntry = (accessor) => (payload) => ({ type: REORDER_ENTRY, payload, accessor })
export const lockEntryToggle = (accessor) => (payload) => ({ type: LOCK_ENTRY, payload, accessor })
export const winEntryToggle = (accessor) => (payload) => ({ type: WIN_ENTRY, payload, accessor })
export const reorderAndMoveEntry = (accessor) => (payload) => ({ type: REORDER_MOVE_ENTRY, payload, accessor }) /* prettier-ignore */
export const updateCategoryName = (accessor) => (payload) => ({ type: UPDATE_CATEGORY_NAME, payload, accessor, sanitizeType: 'category' }) /* prettier-ignore */

// State
export const initialState = {
  // This is the data that actually comes back from the endpoint
  playbook: {
    active: false,
    annotations: {},
    began_editing: null, // timestamp from the BE to prevent multiple people from submitting and overwriting each other's work
    body: initialPlaybookBody,
    cid: null, // playbook (config) id, a uuid
    created: '',
    current: false, // current = is latest playbook version id
    deleted: false,
    id: null, // playbook version id, a number
    name: '',
    organization_id: null,
    stats: {},
    uuid: null,
    created_by: null,
  },

  // This is other data we want to keep track of for playbook editing. Let's keep them separate.
  warnings: initialPlaybookWarningsBody,
  versions: [], // switch between playbook versions, used by Balto admins only
  dirty: false, // playbook has been edited
  scorecardAssociations: [],
  mostRecentId: null,
  validationError: {
    accessor: null,
    entry: null,
    errorMessage: null,
  },
  loading: {
    playbook: false,
    versions: false,
    savePlaybook: false,
    warnings: false,
    smartPhraseSuggestions: null,
    prompt: false,
    promptTests: false,
  },
  checklistVariations: {},
  checklistItemVariations: {},
  novelChecklistItems: [],
  novelChecklistItemsVisible: false,
  rtnPrompt: null,
  rtnModelOptions: [],
}

// Reducer
const playbookReducer = (state = initialState, action) => {
  return produce(state, (newState) => {
    const { payload, accessor } = action

    switch (action.type) {
      case SET_PLAYBOOK:
        newState.dirty = false
        newState.playbook = payload
        newState.novelChecklistItemsVisible = false
        break
      case SET_PLAYBOOK_WARNINGS:
        newState.warnings = payload
        break
      case SET_PLAYBOOK_SECTION_WARNINGS:
        newState.warnings[accessor] = payload
        break
      case SET_PLAYBOOK_ENTRY_WARNINGS:
        newState.warnings[accessor][payload.entryId] = payload.entryWarnings
        break
      case SET_NOVEL_CHECKLIST_ITEMS:
        newState.novelChecklistItems = payload
        break
      case TOGGLE_NOVEL_CHECKLIST_VISIBLE:
        newState.novelChecklistItemsVisible = payload
        break
      case SET_CHECKLIST_VARIATIONS:
        addChecklistVariations(newState, payload)
        break
      case SET_CHECKLIST_ITEM_VARIATIONS:
        addChecklistItemVariations(newState, payload, action.checklistItemId)
        break
      case SET_VALIDATION_ERROR:
        newState.validationError = action.payload
        break
      case CLEAR_VALIDATION_ERROR:
        newState.validationError = initialState.validationError
        break
      case SET_PLAYBOOK_VERSIONS:
        newState.versions = payload
        break
      case ACTIVATE_PLAYBOOK_VERSION:
        newState.playbook.active = true
        break
      case UPDATE_PLAYBOOK_NAME:
        newState.dirty = true
        newState.playbook.name = payload.name
        break
      case UPDATE_CATEGORY_NAME:
        newState.dirty = true
        newState.playbook.body[accessor].entries[payload.categoryId].name = payload.name
        break
      case CREATE_ENTRY:
        add(newState, payload, accessor)
        break
      case UPDATE_ENTRY:
        edit(newState, payload, accessor)
        break
      case DELETE_ENTRY:
        remove(newState, state, payload, accessor)
        break
      case LOCK_ENTRY:
        lock(newState, state, payload, accessor)
        break
      case WIN_ENTRY:
        win(newState, state, payload, accessor)
        break
      case REORDER_ENTRY:
        reorder(newState, payload, accessor)
        break
      case REORDER_MOVE_ENTRY:
        reorderAndMove(newState, payload, accessor)
        break
      case SET_RTN_PROMPT:
        newState.rtnPrompt = payload
        break
      case SET_RTN_MODEL_OPTIONS:
        newState.rtnModelOptions = payload
        break
      case SET_LOADING:
        newState.loading = { ...newState.loading, ...payload }
        break
      default:
        break
    }
  })
}

export default playbookReducer
