import React from 'react'
import { formatTimeForAudio } from '@/utils/helpers'

import './AudioTrackTime.scss'

export const AudioTrackTime = ({ duration, progress, divider }) => {
  return (
    <div className="audio-track-time small-gap">
      <span className="current-time" data-testid="audio-current-time">
        {formatTimeForAudio(progress)}
      </span>
      {divider && '/'}
      <span className="duration" data-testid="audio-duration">
        {formatTimeForAudio(duration)}
      </span>
    </div>
  )
}
