// Action Types
import produce from 'immer'

export const SET_FILTER = 'realtimeReports/setFilter'
export const SET_DATE_RANGE_FILTERS = 'realtimeReports/setDateRangeFilters'
export const SET_LOADING = 'realtimeReports/setLoading'
export const SET_DATA = 'realtimeReports/setData'

// Action Creators
export const setFilter = (payload) => ({ type: SET_FILTER, payload })
export const setData = (payload) => ({ type: SET_DATA, payload })
export const setDateRangeFilters = (payload) => ({ type: SET_DATE_RANGE_FILTERS, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })

// State
export const initialState = {
  data: {
    alertCountsByTypeByDay: {
      positive: [],
      negative: [],
      informative: [],
    },
    drillDownGraph: [],
    drillDownTable: [],
    previousPeriodAlertCountsByTypeByDay: {
      positive: [],
      negative: [],
      informative: [],
    },
    callAlertPercentage: 0.0,
    previousPeriodCallAlertPercentage: 0.0,
    topAlertConfigs: [],
    topAlertingAgentsInformative: [],
    topAlertingAgentsNegative: [],
    topAlertingAgentsPositive: [],
    topChattingSupervisor: '',
    topChattingSupervisors: [],
    topListenerSupervisor: '',
    topListenerSupervisors: [],
  },
  loading: {
    callAlertPercentage: false,
    avgAlertsPerCall: false,
    topAlertConfigs: false,
    alertCountsByTypeByDay: false,
    topAlertingAgentsInformative: false,
    topAlertingAgentsNegative: false,
    topAlertingAgentsPositive: false,
    topChattingSupervisor: false,
    topChattingSupervisors: false,
    topListenerSupervisor: false,
    topListenerSupervisors: false,
    previousPeriodAlertCountsByTypeByDay: false,
    previousPeriodCallAlertPercentage: false,
    drillDownPage: false,
    drillDownGraph: false,
    drillDownTable: false,
  },
}

export default function callsReducer(state = initialState, action) {
  return produce(state, (newState) => {
    switch (action.type) {
      case SET_DATA:
        newState.data = { ...state.data, ...action.payload }
        break

      case SET_FILTER:
        newState.filters = { ...state.filters, ...action.payload }
        break

      case SET_DATE_RANGE_FILTERS:
        newState.filters.dateRange = {
          startDate: action.payload.startDate || state.filters.dateRange.startDate,
          endDate: action.payload.endDate || state.filters.dateRange.endDate,
          text: action.payload.text,
        }
        break

      case SET_LOADING:
        newState.loading = { ...state.loading, ...action.payload }
        break

      default:
        break
    }
  })
}
