import React from 'react'
import { Form } from 'semantic-ui-react'

import Select from '@/components/forms/Select'

export const OrganizationFilter = ({ ...props }) => {
  return (
    <Form.Field data-testid={props.dataTestId ? props.dataTestId : 'organization-dropdown'}>
      <label>Organization</label>
      <div data-testid="organization-select">
        <Select placeholder="Select Organization" fixedWidth {...props} />
      </div>
    </Form.Field>
  )
}
