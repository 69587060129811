import React, { useEffect, useMemo } from 'react'
import { Form, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { BaltoAdminManagerFilter } from '@/views/RealtimeCoaching/components/filters/BaltoAdminManagerFilter'
import { DateRangePicker } from '@/views/RealtimeCoaching/components/filters/DateRangePicker'
import { AgentsDropdown } from '@/views/RealtimeCoaching/components/filters/AgentsDropdown'
import { TagsDropdown } from '@/views/RealtimeCoaching/components/filters/TagsDropdown'
import { ManagersDropdown } from '@/views/RealtimeCoaching/components/filters/ManagersDropdown'
import { AlertTypesDropdown } from '@/views/RealtimeCoaching/components/filters/AlertTypesDropdown'
import { OrgAlertNameDropdown } from '@/views/RealtimeCoaching/components/filters/OrgAlertNameDropdown'
import { AdvancedExportsPlaybooksDropdown } from '@/views/RealtimeCoaching/AdvancedExports/filters/AdvancedExportsPlaybooksDropdown'
import { isEmpty } from 'lodash'
import {
  fetchManagersByOrg,
  fetchAgentsByOrg,
  fetchTagsByOrg,
  fetchAdvancedExportsPlaybooks,
  fetchAlertNames,
} from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'

export const AdvancedExportsFilters = () => {
  const dispatch = useDispatch()
  const { organizationid: currentUserOrgId } = useSelector((state) => state.currentUser)
  const isBaltoAdmin = currentUserOrgId === 1
  const { data, filters, loading } = useSelector((state) => state.realtimeCoaching)
  // Deselecting playbooks clears triggers and trigger types
  useEffect(() => {
    if (isEmpty(filters.alertNames)) {
      dispatch(setFilter({ triggers: [] }))
      dispatch(setFilter({ triggerTypes: [] }))
    }
  }, [filters.alertNames])

  // Deselecting triggers clears trigger types
  useEffect(() => {
    if (isEmpty(filters.triggers)) {
      dispatch(setFilter({ triggerTypes: [] }))
    }
  }, [filters.triggers])

  const onBaltoAdminFilterSubmit = () => {
    dispatch(fetchManagersByOrg(filters.organization))
    dispatch(fetchAgentsByOrg(filters.organization))
    dispatch(fetchTagsByOrg(filters.organization))
    dispatch(fetchAdvancedExportsPlaybooks(filters.organization))
    dispatch(fetchAlertNames(filters.organization))
    dispatch(setFilter({ agents: [] }))
    dispatch(setFilter({ alertNames: [] }))
    dispatch(setFilter({ managers: [] }))
    dispatch(setFilter({ playbooksAdvancedExports: [] }))
    dispatch(setFilter({ tags: [] }))
    dispatch(setFilter({ triggers: [] }))
    dispatch(setFilter({ triggerTypes: [] }))
    dispatch(setFilter({ userAlertNames: [] }))
    dispatch(setFilter({ interactions: [] }))
    dispatch(setFilter({ alertTypes: [] }))
  }

  const filteredTriggersByTriggerType = useMemo(
    () =>
      data.triggers
        .map((triggerGroup) => {
          return {
            label: triggerGroup.label,
            options: triggerGroup.options.filter((trigger) => {
              if (
                filters.triggerTypes.some(
                  ({ value: triggerType }) => triggerType === 'occurrence'
                ) &&
                !trigger.value.includes('requirement_missed')
              ) {
                return trigger
              }
              if (
                filters.triggerTypes.some(({ value: triggerType }) => triggerType === 'absence') &&
                trigger.value.includes('requirement_missed')
              ) {
                return trigger
              }
              if (!filters.triggerTypes.length) {
                return trigger
              }
              return false
            }),
          }
        })
        .filter((triggerGroup) => triggerGroup.options.length),
    [filters.triggerTypes, data.triggers]
  )

  return (
    <>
      <h4 className="rtc-exports-filter-header">Filters</h4>
      <div>
        {isBaltoAdmin && (
          <BaltoAdminManagerFilter
            showManagerDropdown={false}
            extraClass="rtc-white-background rtc-auto-margin"
            onSubmit={onBaltoAdminFilterSubmit}
          />
        )}
      </div>
      <Form className="rtc-advanced-exports-filters" data-testid="advanced-exports-filters">
        <div className="rtc-exports-grid">
          <DateRangePicker />
          <ManagersDropdown />
          <AgentsDropdown disabledByTags />
          <TagsDropdown disabledByAgents />
          <AdvancedExportsPlaybooksDropdown />
          <AlertTypesDropdown />
          <OrgAlertNameDropdown useFilters />
          <Form.Field data-testid="advanced-exports-trigger-types-dropdown">
            <label>Trigger Types</label>
            <div>
              <MultiSelect
                isSelectAll
                placeholderPill="All Trigger Types"
                options={data.triggerTypes}
                value={filters.triggerTypes}
                loading={loading.triggerTypes}
                onChange={(value) => dispatch(setFilter({ triggerTypes: value }))}
              />
            </div>
          </Form.Field>
          <Form.Field data-testid="advanced-exports-triggers-dropdown">
            <label>Triggers</label>
            <Popup
              content="One alert must be selected in order to view triggers"
              position="top center"
              disabled={!!filters.alertNames.length}
              trigger={
                <div>
                  <MultiSelect
                    placeholderPill="All Triggers"
                    options={filteredTriggersByTriggerType}
                    value={filters.triggers}
                    loading={loading.triggers}
                    onChange={(value) => dispatch(setFilter({ triggers: value }))}
                    disabled={!filters.alertNames.length}
                  />
                </div>
              }
            />
          </Form.Field>
          <Form.Field data-testid="advanced-exports-interactions-dropdown">
            <label>Interactions</label>
            <MultiSelect
              isSelectAll
              placeholderPill="All Interactions"
              options={data.interactions}
              value={filters.interactions}
              loading={loading.interactions}
              onChange={(value) => dispatch(setFilter({ interactions: value }))}
            />
          </Form.Field>
        </div>
      </Form>
    </>
  )
}
