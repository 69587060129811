import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { setData } from '../../reducers/scorecards/scorecards.redux'
import { fetchTargetScorecardData } from '../../reducers/scorecards/scorecards.actions'
import { ScorecardSectionEditor } from './ScorecardSectionEditor'
import { ScorecardEditor } from './ScorecardEditor'

export const ScorecardEditorWrapper = () => {
  const dispatch = useDispatch()
  const { scorecard_config_id: configId, scorecard_section_id: sectionId } = useParams()

  const { targetScorecardConfig, targetScorecardSection } = useSelector(
    (state) => state.scorecards?.data
  )

  useEffect(() => {
    dispatch(fetchTargetScorecardData(configId))
  }, [dispatch, configId])

  useEffect(() => {
    if (targetScorecardConfig.sections && sectionId) {
      // only need to dispatch a new section if it's empty or url changed
      if (isEmpty(targetScorecardSection) || targetScorecardSection.id !== sectionId) {
        const targetScorecardSection = targetScorecardConfig.sections.find(
          (section) => section.id === Number(sectionId)
        )

        dispatch(setData('targetScorecardSection', targetScorecardSection))
      }
    }
  }, [targetScorecardConfig])

  if (sectionId) {
    return (
      <div data-testid="wrapper-section-editor">
        <ScorecardSectionEditor />
      </div>
    )
  }

  return (
    <div data-testid="wrapper-config-editor">
      <ScorecardEditor />
    </div>
  )
}

export default ScorecardEditorWrapper
