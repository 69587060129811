import React from 'react'
import { Button, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { startCase } from 'lodash'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IconAlertTriangle, IconLockSquareRoundedFilled, IconLockOpen } from '@tabler/icons-react'
import uuid from 'uuid/v4'
import format from 'date-fns/format'

import VariantIcon from '@/assets/images/variants.svg'
import ConfirmForm from '@/components/forms/ConfirmForm'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { Pill } from '@/components/pills/Pill'
import { fetchPlaybookSectionWarnings } from '@/reducers/playbooks/playbook.actions'
import { openModal, closeModal } from '@/reducers/ui/ui.redux'
import { lockEntryToggle, winEntryToggle, deleteEntry } from '@/reducers/playbooks/playbook.redux'
import { PlaybookEntryActions } from './PlaybookEntryActions'

import { getReadableWarning } from '../playbookWarning.helpers'

export const PlaybookEntryComponent = ({
  accessor,
  categoryId,
  entryId,
  entry,
  readOnly,
  label,
  triggerTypes,
  isLockable,
  isWinnable,
  isRetiredNotificationType,
  checklistVariationCount,
  notClickable,
  metadata,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { currentlyOpenModalId } = useSelector((state) => state.ui)
  const { warnings, validationError } = useSelector((state) => state.playbook)
  const { id, section } = useParams()
  const deleteModalId = `playbooks/delete/${section}/${entryId}`
  const valueToSend = categoryId ? { categoryId, entryId } : entryId // for nested entries
  const entryWarnings = warnings[accessor]?.[entryId]
  const hasValidationError =
    validationError?.accessor === accessor && validationError?.entry === entryId

  const handleOpenDeleteModal = () => dispatch(openModal(deleteModalId))
  const handleCloseModal = () => dispatch(closeModal())
  const openEntry = (event) => {
    if (notClickable) {
      return
    }
    event.stopPropagation()

    history.push(`/playbooks/${id}/checklist/${entryId}?tab=variations`)
  }
  const handleEdit = () => {
    if (notClickable) {
      return
    }
    const appendCategory = categoryId ? `?category_id=${categoryId}` : ''
    history.push(`/playbooks/${id}/${section}/${entryId}${appendCategory}`)
  }

  const handleDelete = (value) => {
    dispatch(deleteEntry(accessor)(value))

    toast.success(`${startCase(label)} has been deleted`)
    dispatch(fetchPlaybookSectionWarnings(accessor))
  }

  const handleWin = (value) => {
    dispatch(winEntryToggle(accessor)(value))
  }

  const handleLock = (value, is_locked, name) => {
    dispatch(lockEntryToggle(accessor)(value))

    const setToLocked = !is_locked

    toast.success(`Checklist item "${name}" has been ${setToLocked ? 'locked' : 'unlocked'}`)
  }

  const renderLockButton = () => {
    const LockButton = (
      <Button
        basic
        compact
        data-testid="lock-button"
        icon
        className={classNames('svg-button', { locked: entry.is_locked })}
        disabled={readOnly}
        onClick={(event) => {
          event.stopPropagation()
          handleLock(valueToSend, entry.is_locked, entry.name)
        }}
      >
        {entry.is_locked ? <IconLockSquareRoundedFilled /> : <IconLockOpen />}
      </Button>
    )
    return (
      <Popup
        inverted
        content={
          entry.is_locked
            ? 'This item is locked and cannot be edited by agents'
            : 'This item is unlocked and can be edited by agents'
        }
        trigger={LockButton}
      />
    )
  }

  return (
    <>
      <div className="playbook-warnings-container">
        <div
          key={entryId}
          className={classNames('playbook-entry hidden-button-wrapper', {
            'playbook-entry__grid': isLockable || entry.win,
            'read-only': readOnly,
            retired: isRetiredNotificationType,
            'not-clickable': notClickable,
          })}
          onClick={handleEdit}
        >
          {isLockable && (
            <div className="playbook-entry__lock">
              {isWinnable && entry.win ? (
                <Pill small success data-testid="win-badge">
                  Win
                </Pill>
              ) : (
                renderLockButton()
              )}
            </div>
          )}

          {!isLockable && isWinnable && entry.win && (
            <Pill small success data-testid="win-badge">
              Win
            </Pill>
          )}

          <div className="playbook-entry__name">
            {entry.trigger && triggerTypes && (
              <div
                className="playbook-entry__trigger-type"
                data-testid="playbook-entry-trigger-type"
              >
                {triggerTypes.find((trigger) => trigger.value === entry.trigger?.type)?.label || ''}
              </div>
            )}
            <div className="playbook-entry-name" data-testid="playbook-entry-name">
              <span>{entry.name}</span>
              {checklistVariationCount && !readOnly && (
                <Pill
                  brand
                  content={checklistVariationCount}
                  icon={<img src={VariantIcon} alt="Variant" />}
                  style={{ marginLeft: '1rem' }}
                  onClick={openEntry}
                />
              )}
            </div>
            {metadata && (
              <div
                data-testid={`playbook-entry-metadata-container-${entryId}`}
                className="playbook-entry-metadata-container"
              >
                <span data-testid={`playbook-entry-metadata-container-${entryId}-author`}>
                  By: {metadata.createdBy}
                </span>
                <div className="playbook-entry-metadata-dot" />
                <span data-testid={`playbook-entry-metadata-container-${entryId}-created-at`}>
                  Created at: {format(new Date(metadata.createdAt), "MMM d',' yyyy h:mm b")}
                </span>
              </div>
            )}
          </div>
          {!notClickable && (
            <PlaybookEntryActions
              isWinnable={isWinnable}
              isRetiredNotificationType={isRetiredNotificationType}
              readOnly={readOnly}
              handleEdit={handleEdit}
              handleWin={() => handleWin(valueToSend)}
              handleDelete={handleOpenDeleteModal}
              entry={entry}
            />
          )}
        </div>
        <div>
          {!readOnly && (entryWarnings?.warnings || entryWarnings?.decklist_warnings) && (
            <Popup
              content={
                <ul className="playbook-warnings-popup">
                  {entryWarnings?.warnings &&
                    entryWarnings.warnings.map((warning) => (
                      <li key={uuid()}>{getReadableWarning(warning)}</li>
                    ))}
                  {entryWarnings?.decklist_warnings &&
                    // eslint-disable-next-line no-unused-vars
                    Object.entries(entryWarnings.decklist_warnings).map(([dlUuid, warnings]) => {
                      return warnings.map((warning) => (
                        <li key={uuid()}>{getReadableWarning(warning)}</li>
                      ))
                    })}
                </ul>
              }
              trigger={
                <IconAlertTriangle
                  className="icon-svg-large status-warning"
                  style={{ marginLeft: '0.5rem' }}
                />
              }
            />
          )}
          {hasValidationError && (
            <Popup
              content={<div>{validationError?.errorMessage || 'This item requires attention'}</div>}
              trigger={
                <IconAlertTriangle
                  className="icon-svg-large status-critical"
                  style={{ marginLeft: '0.5rem' }}
                />
              }
            />
          )}
        </div>
      </div>

      {currentlyOpenModalId === deleteModalId && (
        <BasicModal
          data-testid={`${deleteModalId}-modal`}
          title={`Delete ${startCase(label)}`}
          onClose={handleCloseModal}
          show={currentlyOpenModalId === deleteModalId}
          size="tiny"
        >
          <ConfirmForm
            valueToSubmit={entryId}
            bodyText={`Are you sure you want to delete this ${label}? ${
              label === 'checklist item'
                ? 'This means all checklist variations for this item will be removed from the the agent app.'
                : ''
            }`}
            danger
            primaryButtonText={`Delete ${startCase(label)}`}
            handleSubmit={() => {
              handleDelete(valueToSend)
            }}
            modalClose={handleCloseModal}
          />
        </BasicModal>
      )}
    </>
  )
}

export const PlaybookEntry = withLDConsumer()(PlaybookEntryComponent)
