import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

import './ExportsTable.css'
import { Popup } from 'semantic-ui-react'
import WidgetContext from '../../../../components/widget/widget.context'
import { AdvancedTable } from '../../../../components/tables/AdvancedTable/AdvancedTable'

export const ExportsTable = () => {
  const { handleChangeCsvData } = useContext(WidgetContext)
  const { data, loading, filters } = useSelector((state) => state.scorecards)

  useEffect(() => {
    if (data.tableRows?.advancedExports?.length > 0) {
      data.tableRows.advancedExports.forEach((row) => {
        // Advanced table doesn't like zero values. It ignores them. Set them to a string as workaround.
        // TODO: can just format toString() in the columns instead of this
        Object.keys(row).forEach((key) => {
          if (row[key] === 0) {
            row[key] = '0'
          }
        })

        const rowIsCallSummary =
          (row.call_summary && !row.call_summary.value) || (row.Summary && !row.Summary.value)

        if (rowIsCallSummary) {
          const summaryData = row.call_summary || row.Summary
          row.call_summary = {
            as: (
              <div>
                <Popup
                  content={summaryData}
                  trigger={<div>{summaryData ? summaryData.slice(0, 50) : ''}...</div>}
                />
              </div>
            ),
            value: summaryData,
          }
          row.Summary = row.call_summary
        }
      })
    }
  }, [data?.tableRows?.advancedExports])

  useEffect(() => {
    handleChangeCsvData({
      headers: data.tableHeaders?.advancedExports.filter((column) => !column.is_hidden),
      data: data.tableRows.advancedExports.map((row) => ({
        ...row,
        call_summary: row.call_summary && row.call_summary.value,
        Summary: row.Summary && row.Summary.value,
      })),
      fileName: 'Scorecard Exports',
      filters: { startDate: filters.startDate, endDate: filters.endDate },
    })
  }, [data.tableRows.advancedExports, data.tableHeaders?.advancedExports])

  return (
    <div data-testid="scorecard-exports__table" className="scorecard-exports__table">
      <AdvancedTable
        index="uuid"
        rows={data.tableRows?.advancedExports}
        columns={data.tableHeaders?.advancedExports.filter((column) => !column.is_hidden)}
        loading={loading.scores}
        loadingText="Your data for this report is running. This could take a few minutes..."
        defaultOrderBy={data.tableHeaders?.advancedExports[0]?.accessor}
        defaultOrder="asc"
        wrapColumnContent={false}
        pagination
      />
    </div>
  )
}
