import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { AuthContainer } from './AuthContainer'
import { AuthMessage } from './AuthMessage'
import { AbstractForm } from '../../components/forms/formik/AbstractForm'
import { resetUserPassword } from '../../reducers/auth/currentUser.actions'
import { clearAuthMessage } from '../../reducers/ui/ui.redux'

const ResetPasswordPage = () => {
  const dispatch = useDispatch()
  const { authMessage } = useSelector((state) => state.ui)

  const handleResetPassword = ({ email }, { setSubmitting }) => {
    dispatch(resetUserPassword(email, setSubmitting))
  }

  useEffect(() => {
    dispatch(clearAuthMessage())
  }, [])

  return (
    <>
      <Helmet>
        <title>Balto Cloud | Reset Password</title>
      </Helmet>

      <AuthContainer>
        <h2 data-testid="reset-password-page">Reset Password</h2>
        {!authMessage.text && (
          <AbstractForm
            schema={[{ name: 'email', type: 'text', label: 'Email', required: true }]}
            onSubmit={handleResetPassword}
            buttonProps={{ fluid: true }}
            buttonLabel="Send Password Reset Link"
          />
        )}
        <AuthMessage />
        <Link to="/login" className="link">
          Back to Login
        </Link>
      </AuthContainer>
    </>
  )
}

export default ResetPasswordPage
