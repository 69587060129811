import React from 'react'

import { Pill } from '@/components/pills/Pill'
import { determineStatusLabel } from '@/views/Gamification/Challenges/helpers'

export const ChallengeStatusBadge = ({ status }) => {
  const statusBadgeData = determineStatusLabel(status)
  const { label: statusLabel, statusType } = statusBadgeData

  if (!statusLabel || !statusType) return null

  return (
    <Pill small {...statusType} data-testid="challenge-status-badge">
      {statusLabel}
    </Pill>
  )
}
