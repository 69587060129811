import React from 'react'
import { Breadcrumb, Divider } from 'semantic-ui-react'

import './breadcrumbs.css'
import { Link } from 'react-router-dom'

export const Breadcrumbs = ({ backLinks, currentLink }) => {
  const multipleBackLinks = Array.isArray(backLinks)
  const listOfBackLinks = multipleBackLinks ? backLinks : [backLinks]
  const breadcrumbTrail = listOfBackLinks.map((backLink) => {
    return (
      <React.Fragment key={backLink.label || 'Loading'}>
        <Link to={backLink.link} key={backLink.label}>
          <Breadcrumb.Section data-testid={backLink.dataTestId || 'breadcrumb-backlink'}>
            {backLink.label}
          </Breadcrumb.Section>
        </Link>
        <Breadcrumb.Divider />
      </React.Fragment>
    )
  })

  return (
    <React.Fragment key={currentLink}>
      <Breadcrumb size="large" data-testid="breadcrumbs">
        {breadcrumbTrail}
        <Breadcrumb.Section active data-testid="breadcrumb-current-link">
          {currentLink}
        </Breadcrumb.Section>
      </Breadcrumb>
      <Divider hidden />
    </React.Fragment>
  )
}
