import * as Yup from 'yup'
import { get, cloneDeep, isEmpty, toArray, toNumber } from 'lodash'
import { v4 as uuid } from 'uuid'

export const criteriaTypeOptions = [
  { value: 'ai', text: 'Copilot Scoring' },
  { value: 'manual', text: 'Manual Scoring' },
]

export const criteriaValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(500, 'Criteria name must be less than five hundred characters')
    .required('Name is required'),
  description: Yup.string(),
  ai_shell_prompt: Yup.string(),
  ai_prompt: Yup.string(),
  ai_secondary_prompt: Yup.string(),
  criteria_type: Yup.string().required(),
})

export const criteriaPromptValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(500, 'Criteria name must be less than five hundred characters')
    .required('Name is required'),
  description: Yup.string(),
  criteria_type: Yup.string().required(),
  user_prompt: Yup.string().matches(/\$transcript/, "AI prompt must include '$transcript'"),
})

export const criteriaPointsValidationSchema = Yup.object().shape({
  sections: Yup.array().of(
    Yup.object().shape({
      criteria: Yup.array().of(
        Yup.object().shape({
          weight: Yup.number()
            .integer('Points must be a whole number')
            .positive('Points must be a positive number')
            .min(1, 'Points must be greater than 0')
            .max(100, 'Points must be less than or equal to 100')
            .required('Points are required'),
        })
      ),
    })
  ),
})

export const settingsValidationSchema = Yup.object().shape({
  exceeds_threshold: Yup.number()
    .required('"Exceeds" is required')
    .moreThan(Yup.ref('meets_threshold'), 'Cannot be less than "Meets"')
    .max(100, 'Cannot be more than 100'),
  meets_threshold: Yup.number()
    .required('"Meets" is required')
    .moreThan(Yup.ref('improvement_threshold'), 'Cannot be less than "Below"')
    .lessThan(Yup.ref('exceeds_threshold'), 'Cannot be more than "Exceeds"'),
  improvement_threshold: Yup.number()
    .required('"Below" is required')
    .lessThan(Yup.ref('exceeds_threshold'), 'Cannot be more than "Exceeds"')
    .lessThan(Yup.ref('meets_threshold'), 'Cannot be more than "Meets"')
    .min(0, 'Cannot be less than 0'),
  min_duration_in_minutes: Yup.number()
    .min(1, 'Must be at least 1 minute')
    .max(120, 'Cannot be more than 120 minutes')
    .required('Minimum call length is required'),
  required_score_count: Yup.number()
    .min(0, 'Cannot be less than 0')
    .max(9999, 'Cannot be more than 9999'),
  organization_id: Yup.string().required('Organization is required'),
  user_prompt: Yup.string()
    .nullable()
    .matches(/\$transcript/, "AI prompt must include '$transcript'"),
})

export const addTemporaryCriteriaHelper = (section) => {
  return {
    scid: uuid(),
    name: 'New Criteria',
    description: '',
    criteria_type: 'ai',
    order_id: get(section, 'measures[0].criteria.length') || 0,
    ssid: section.ssid,
    is_new: true,
  }
}

export const addDefaultSectionHelper = () => {
  return {
    ssid: uuid(),
    name: 'Default Section',
    measures: [{ name: 'Default Measure', criteria: [] }],
    order_id: 0,
  }
}

export const getCriteriaPointsInitialValues = (scorecardConfig) => {
  if (isEmpty(scorecardConfig?.sections)) {
    return { sections: [] }
  }

  const intitialCriteriaPointsValues = scorecardConfig.sections.map((section) => ({
    ...section,
    criteria: toArray(section.measures.find((measure, mIndex) => mIndex === 0)?.criteria).map(
      (criteria, cIndex) => ({
        ...criteria,
        order_id: cIndex,
        weight: criteria.weight || 1,
      })
    ),
  }))

  return { sections: intitialCriteriaPointsValues }
}

export const getTotalPoints = (values) => {
  return toNumber(
    values.sections.reduce((acc, section) => {
      return (
        section.criteria.reduce((acc2, criteria) => {
          return acc2 + toNumber(criteria.weight)
        }, 0) + acc
      )
    }, 0)
  )
}

export const getFilteredCriteria = ({ is_new, ssid, ...criteria }) => criteria

export const updateSectionNameHelper = (scorecardConfig, sectionToUpdate) => {
  return {
    ...scorecardConfig,
    sections: scorecardConfig.sections.map((section) =>
      section.ssid === sectionToUpdate.ssid ? sectionToUpdate : section
    ),
  }
}

export const updateSectionSettingsHelper = (scorecardConfig, sectionToUpdate, updatedSettings) => {
  return {
    ...scorecardConfig,
    sections: scorecardConfig.sections.map((section) => {
      if (section.ssid === sectionToUpdate.ssid) {
        const sectionWithNewSettings = cloneDeep(section)
        sectionWithNewSettings.measures = toArray(sectionWithNewSettings.measures)
        sectionWithNewSettings.measures[0] = {
          ...sectionWithNewSettings.measures[0],
          ...updatedSettings,
        }

        return sectionWithNewSettings
      }

      return section
    }),
  }
}

export const removeSectionHelper = (scorecardConfig, sectionToRemove) => {
  const filteredSections = scorecardConfig.sections.filter(
    (section) => section.ssid !== sectionToRemove.ssid
  )
  if (isEmpty(filteredSections)) {
    filteredSections.push(addDefaultSectionHelper())
  }

  return { ...scorecardConfig, sections: filteredSections }
}

export const createCriteriaHelper = (scorecardConfig, criteriaToAdd) => {
  return {
    ...scorecardConfig,
    sections: scorecardConfig.sections.map((section) => {
      if (section.ssid === criteriaToAdd.ssid) {
        const sectionWithNewCriteria = cloneDeep(section)
        const newCriteria = getFilteredCriteria(criteriaToAdd)
        sectionWithNewCriteria.measures = toArray(sectionWithNewCriteria.measures)
        sectionWithNewCriteria.measures[0].criteria.push(newCriteria)

        return sectionWithNewCriteria
      }
      return section
    }),
  }
}

export const updateCriteriaHelper = (scorecardConfig, criteriaToUpdate) => {
  return {
    ...scorecardConfig,
    sections: scorecardConfig.sections.map((section) => ({
      ...section,
      measures: section.measures.map((measure) => ({
        ...measure,
        criteria: measure.criteria.map((criteria) =>
          criteria.scid === criteriaToUpdate.scid ? criteriaToUpdate : criteria
        ),
      })),
    })),
  }
}

export const removeCriteriaHelper = (scorecardConfig, criteriaToRemove) => {
  return {
    ...scorecardConfig,
    sections: scorecardConfig.sections.map((section) => ({
      ...section,
      measures: section.measures.map((measure) => ({
        ...measure,
        criteria: measure.criteria.filter((criteria) => criteria.scid !== criteriaToRemove.scid),
      })),
    })),
  }
}

export const getBooleanValue = (value, defaultValue) => {
  if (value === true) {
    return 'true'
  }

  if (value === false) {
    return 'false'
  }

  return defaultValue
}
