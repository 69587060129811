import React from 'react'
import { Button } from 'semantic-ui-react'

export const SelectAllBanner = ({ rowsPerPage, handleSelectAllSearchFiltered, rows }) => (
  <div className="select-all-banner">
    <div>
      {rowsPerPage} calls selected on this page.{' '}
      <Button
        data-testid="select-all-button"
        className="inline-link-button"
        onClick={handleSelectAllSearchFiltered}
      >
        Select all {rows.length} calls across all pages.
      </Button>
    </div>
  </div>
)
