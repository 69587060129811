import React, { createContext, useRef, useContext, useState } from 'react'

const VideoPlayerContext = createContext()

export const VideoPlayerProvider = ({ children }) => {
  const videoRef = useRef(null)
  const [isVideoVisible, setIsVideoVisible] = useState(true)

  const skipForward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime += 10
    }
  }

  const skipBackward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 10
    }
  }

  const changePlaybackSpeed = (speed) => {
    if (videoRef.current) {
      videoRef.current.playbackRate = speed
    }
  }

  const jumpTo = (time) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time
    }
  }

  const togglePlayPause = (manuallySet = undefined) => {
    if (videoRef.current) {
      if (!manuallySet) {
        if (videoRef.current.paused) {
          videoRef.current.play()
        } else {
          videoRef.current.pause()
        }
      } else {
        if (manuallySet === 'play' && videoRef.current.paused) {
          videoRef.current.play()
        }
        if (manuallySet === 'pause' && !videoRef.current.paused) {
          videoRef.current.pause()
        }
      }
    }
  }

  const toggleFullScreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen()
      }
    }
  }

  const toggleVideoVisibility = () => {
    setIsVideoVisible((prevVisibility) => !prevVisibility)
  }

  return (
    <VideoPlayerContext.Provider
      value={{
        videoRef,
        skipForward,
        skipBackward,
        changePlaybackSpeed,
        jumpTo,
        togglePlayPause,
        toggleFullScreen,
        toggleVideoVisibility,
        isVideoVisible,
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  )
}

export const useVideoPlayer = () => {
  return useContext(VideoPlayerContext)
}
