import React, { useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import Select from '@/components/forms/Select'
import { isEmpty } from 'lodash'
import { getOrganizationOptions } from '@/utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { fetchAllRtcData } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'

export const BaltoAdminOrganizationFilter = ({ additionalOnChange }) => {
  const dispatch = useDispatch()
  const { currentUser, organizations } = useSelector((state) => state)
  const { filters } = useSelector((state) => state.realtimeCoaching)
  const organizationOptions = getOrganizationOptions(
    organizations.filter((org) => org.realtime_coaching_access)
  )

  const handleOrgSelect = (organizationId) => {
    dispatch(fetchAllRtcData(organizationId))
    dispatch(setFilter({ organization: organizationId }))
  }

  useEffect(() => {
    if (!filters.organization) {
      dispatch(setFilter({ organization: currentUser.organizationid }))
    } else {
      handleOrgSelect(filters.organization)
    }
  }, [])

  return (
    <Form.Field required data-testid="rtc-filter-organization">
      <label id="organization">Organization</label>
      <Select
        aria-labelledby="organization"
        placeholder="Select Organization"
        options={organizationOptions}
        loading={isEmpty(organizations)}
        value={filters.organization}
        onChange={({ value }) => {
          handleOrgSelect(value)
          if (additionalOnChange) {
            additionalOnChange()
          }
        }}
        isClearable={false}
      />
    </Form.Field>
  )
}
