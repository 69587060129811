import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'

import {
  fetchUserDashboardSettings,
  addNewWidget,
  dismissNewWidget,
  fetchTrendsCategories,
} from '@/reducers/userSettings/userSettings.actions'
import { DashboardGrid } from '@/components/dashboards/DashboardGrid'
import { DashboardWidget } from '@/components/dashboards/DashboardWidget'
import { DashboardWidgetSuggested } from '@/components/dashboards/DashboardWidgetSuggested'
import { SvgDashboardLines } from '@/components/dashboards/SvgDashboardLines'

import { GPT_DASHBOARD_TYPE } from '../widgets/gptWidgets'
import { TrendsLineChart } from '../widgets/TrendsLineChart'

export const InsightsDashboardWidgets = ({ objectives }) => {
  const [fetched, setFetched] = useState(false)
  const dispatch = useDispatch()
  const {
    dashboardSettings,
    loading,
    loading: { dashboardSettings: dashboardSettingsLoading },
  } = useSelector((state) => state.userSettings)

  const handleAddSuggestedWidgetToDashboard = (objectiveId) => {
    dispatch(addNewWidget(GPT_DASHBOARD_TYPE, { chart_id: objectiveId }))
  }

  const handleRemoveSuggestedWidgetFromDashboard = (objectiveId) => {
    dispatch(dismissNewWidget(GPT_DASHBOARD_TYPE, { chart_id: objectiveId }))
  }

  const initialLoad = async () => {
    await dispatch(fetchUserDashboardSettings(GPT_DASHBOARD_TYPE))

    setFetched(true)
  }

  useEffect(() => {
    initialLoad()
  }, [])

  if (!fetched) {
    return (
      <div className="empty loading-container">
        <Loader active inline>
          User dashboard settings loading...
        </Loader>
      </div>
    )
  }

  return (
    <>
      <div className="dashboard-grid__suggestions">
        {objectives
          .filter((objective) => {
            // Filter out charts that are already on the dashboard
            const filteredOut = dashboardSettings[GPT_DASHBOARD_TYPE].filter(
              (dashboardSetting) => dashboardSetting.chart_id === objective.id
            ).map((filtered) => filtered.chart_id)

            return !filteredOut.includes(objective.id)
          })
          .map((objective) => (
            <DashboardWidgetSuggested
              key={objective.id}
              title={objective.name}
              description={objective.description}
              handleSubmit={() => handleAddSuggestedWidgetToDashboard(objective.id)}
              handleCancel={() => handleRemoveSuggestedWidgetFromDashboard(objective.id)}
            >
              <SvgDashboardLines />
            </DashboardWidgetSuggested>
          ))}
      </div>

      {dashboardSettingsLoading ? (
        <div className="empty loading-container">
          <Loader active inline>
            User dashboard settings loading...
          </Loader>
        </div>
      ) : (
        <DashboardGrid>
          {dashboardSettings[GPT_DASHBOARD_TYPE].filter(
            (dashboardSetting) => dashboardSetting.accepted
          ).map((dashboardSetting) => {
            const objective = objectives.find(
              (objective) => objective.id === dashboardSetting.chart_id
            )

            // this could happen if we increased the calls needed for an objective to show up.
            // objective could exist in the dashboard settings but wouldn't be returned from endpoint
            if (!objective) return null

            const title = objective?.name
            const description = objective?.description
            // Used if no settings exist
            // Should probably use a context for this widget for shared settings between the widget
            // and the chart, but it's not yet needed for just having two settings
            const defaultDateRange = '90_days'

            return (
              <DashboardWidget
                key={dashboardSetting.id}
                widget={dashboardSetting}
                dashboardType={GPT_DASHBOARD_TYPE}
                title={title}
                description={description}
                loading={loading.categories[objective.id]}
                settings={dashboardSetting.settings}
                defaultDateRange={defaultDateRange}
                handleFetchData={(dateRange) => {
                  dispatch(fetchTrendsCategories(objective.id, dateRange))
                }}
              >
                {/* // Only a `line_chart` type widget exists currently
                  // If the shape of the data changes or a new type of chart is added,
                  // a new type will need to be added to React to handle that */}
                <TrendsLineChart
                  objectiveId={dashboardSetting.chart_id}
                  defaultDateRange={defaultDateRange}
                />
              </DashboardWidget>
            )
          })}
        </DashboardGrid>
      )}
    </>
  )
}
