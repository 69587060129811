import React from 'react'
import { Button, Grid, Icon, Popup } from 'semantic-ui-react'
import { Draggable } from 'react-beautiful-dnd'
import { Field } from 'formik'
import { Checkbox, TextField } from '../../../components/forms/formik'
import './editSectionsForm.css'

export const EditMeasuresRow = ({
  newItem,
  index,
  remove,
  shouldFocus,
  errors,
  updateStatusInObject,
  updateNameInObject,
  removeObject,
}) => {
  const currentMeasure = newItem

  return (
    <Draggable
      key={currentMeasure.id.toString()}
      draggableId={currentMeasure.id.toString()}
      index={index}
    >
      {(provided) => {
        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            id="container"
          >
            <Grid.Row id="edit-scorecard-form__sections-row">
              <Grid.Column className="edit-scorecard-form__icon-drag-drop">
                <Icon name="align justify" />
              </Grid.Column>
              <Grid.Column width={12} className="edit-scorecard-form__input-name">
                <Field
                  name={`measures.${index}.name`}
                  data-testid={`measures.${index}.name`}
                  disabled={!currentMeasure.active}
                  component={TextField}
                  type="text"
                  max="100"
                  style={{ width: '100%' }}
                  placeholder="New Measure"
                  autoFocus={shouldFocus}
                  errorMessage={errors?.measures && errors.measures[index]?.name}
                  onChange={(e) => updateNameInObject(e, currentMeasure)}
                />
              </Grid.Column>
              <Grid.Column width={1} className="edit-scorecard-form__measures-info">
                <Popup
                  trigger={<Icon name="info circle" className="edit-scorecard-form__icon-black" />}
                  hoverable
                >
                  <div className="edit-scorecard-form__measures-info-title">
                    {currentMeasure?.criteria?.length > 0
                      ? `${currentMeasure?.criteria.length} Criteria in Use`
                      : 'No Criteria In Use'}
                  </div>
                </Popup>
              </Grid.Column>
              <Grid.Column width={2} className="edit-scorecard-form__toggle-status">
                <Field name={`measures.${index}.active`} label={null}>
                  {(formikProps) => (
                    <Checkbox
                      onClick={() => {
                        updateStatusInObject(newItem, index)
                      }}
                      toggle
                      className="edit-scorecard-form__toggle"
                      data-testid="measure-active-toggle"
                      {...formikProps}
                    />
                  )}
                </Field>
              </Grid.Column>
              <Grid.Column width={1} className="edit-scorecard-form__delete-column">
                <Button
                  type="button"
                  icon
                  onClick={() => {
                    remove(index)
                    removeObject(newItem, currentMeasure)
                  }}
                  className="edit-scorecard-form__delete-button"
                >
                  <Icon
                    name="trash alternate"
                    className="edit-scorecard-form__icon-lightgrey"
                    data-testid="basic-modal-delete-button"
                  />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </div>
        )
      }}
    </Draggable>
  )
}
