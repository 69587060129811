import { useCallback, useRef } from 'react'

export const heatmapCallbackRef = () => {
  const ref = useRef(null)
  const setRef = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
    ref.current = node
  }, [])

  return [setRef]
}
