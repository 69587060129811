import React, { useEffect } from 'react'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { Form, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { fetchTagsByOrg } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'

export const TagsDropdown = ({ disabledByAgents = false }) => {
  const dispatch = useDispatch()
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const { data, filters, loading } = useSelector((state) => state.realtimeCoaching)
  const isDropdownDisabled = disabledByAgents && !isEmpty(filters.agents)

  useEffect(() => {
    if (isEmpty(data.tags)) {
      dispatch(fetchTagsByOrg(organizationId))
    }
  }, [])

  return (
    <Form.Field data-testid="rtc-tags-dropdown">
      <label htmlFor="rtc-filter-tags">Tags</label>
      <Popup
        content="Agents and Tags cannot be selected at the same time"
        position="top center"
        disabled={!isDropdownDisabled}
        trigger={
          <div>
            <MultiSelect
              placeholderPill="All Tags"
              inputId="rtc-filter-tags"
              options={data.tags}
              loading={loading.tags}
              value={filters.tags}
              onChange={(value) => dispatch(setFilter({ tags: value }))}
              disabled={isDropdownDisabled}
            />
          </div>
        }
      />
    </Form.Field>
  )
}
