import React, { useState, useRef } from 'react'
import classNames from 'classnames'
import { IconChevronDown } from '@tabler/icons-react'

import { SearchableDropdownResults } from './SearchableDropdownResults'

import './SearchableDropdown.scss'

export const SearchableDropdown = ({
  buttonProps = {},
  inputProps = {},
  options = [],
  loading,
  handleSelect,
  menuPosition,
  hideSearch,
  selected,
  icon,
  actions,
  dataTestId = '',
}) => {
  const triggerRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [dynamicMenuPosition, setDynamicMenuPosition] = useState(menuPosition)
  const { content: buttonLabel, className, ...filteredButtonProps } = buttonProps
  const selectedOption = options.find((option) => option.value === selected)

  const verticalMenuPositions = ['top', 'bottom']

  const handleOpenDropdown = () => {
    if (!open) {
      const { bottom } = triggerRef.current.getBoundingClientRect()
      const { innerHeight } = window
      const offsetHeight = actions ? 260 + actions.length * 30 : 260

      // If the bottom of the component is less than the offset from the bottom of the screen
      if (innerHeight - bottom < offsetHeight) {
        // If menu position is originally set to bottom, set it to be top
        if (verticalMenuPositions.includes(menuPosition)) {
          setDynamicMenuPosition('top')
        } else {
          // If menu position is originally set to left/right, append top
          setDynamicMenuPosition(`${menuPosition} top`)
        }
      } else {
        setDynamicMenuPosition(menuPosition)
      }
    }

    setOpen(!open)
  }

  return (
    <div data-testid="searchable-dropdown" className="searchable-dropdown-trigger">
      <button
        data-testid={
          dataTestId ? `searchable-dropdown-button-${dataTestId}` : 'searchable-dropdown-button'
        }
        className={classNames('ui button svg-button icon', className)}
        type="button"
        ref={triggerRef}
        onClick={handleOpenDropdown}
        {...filteredButtonProps}
      >
        {selectedOption?.label || buttonLabel}
        {icon || <IconChevronDown />}
      </button>

      {open && (
        <SearchableDropdownResults
          hideSearch={hideSearch}
          triggerRef={triggerRef}
          options={options}
          setOpen={setOpen}
          handleSelect={(event) => {
            handleSelect(event)
            setOpen(false)
          }}
          loading={loading}
          inputProps={inputProps}
          position={dynamicMenuPosition}
          selected={selected}
          actions={actions}
        />
      )}
    </div>
  )
}
