import React from 'react'
import { Button, Popup } from 'semantic-ui-react'
import { useParams, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { IconPlus } from '@tabler/icons-react'

import { playbookTitlesSectionMap, accessors, accessorSectionMap } from '../playbook.helpers'

export const PlaybookSectionHeader = ({ buttonText, buttonDisabled }) => {
  const history = useHistory()
  const { id, section } = useParams()
  const accessor = accessorSectionMap[section]

  const handleCreate = (event) => {
    if (buttonDisabled) {
      event.preventDefault()
    } else {
      history.push(`/playbooks/${id}/${section}/create`)
    }
  }

  const renderCreateButton = () => {
    const CreateButton = (
      <Button
        data-testid={`${section}-create-button`}
        className={classNames('svg-button', { 'popup-button': buttonDisabled })}
        secondary
        icon
        onClick={handleCreate}
        style={{ marginRight: '32px' }}
      >
        <IconPlus />
        {buttonText && <span>{buttonText}</span>}
      </Button>
    )

    return buttonDisabled && accessor === accessors.STICKY_NOTE ? (
      <Popup content="You can only add one sticky note" trigger={CreateButton} />
    ) : (
      CreateButton
    )
  }

  return (
    <header className="playbook-section-header" data-testid="playbook-section-header">
      <div>
        <h2>{playbookTitlesSectionMap[section]}</h2>
      </div>
      <div>{renderCreateButton()}</div>
    </header>
  )
}
