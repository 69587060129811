import React, { useEffect, useState } from 'react'
import uuid from 'uuid/v4'
import * as Yup from 'yup'
import { Grid, Icon, Table } from 'semantic-ui-react'
import { Field } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { cloneDeep, isEmpty } from 'lodash'
import { DragDropContext } from 'react-beautiful-dnd'

import { Pill } from '@/components/pills/Pill'
import { editNameInObject, giveOrderId, handleDrag } from '@/views/Scorecards/helpers'
import { WizardModal } from '../../../components/layout/modals/WizardModal'
import { Select, TextField } from '../../../components/forms/formik'
import './scorecardForms.css'
import { addScorecard, fetchPlaybooksByOrg } from '../../../reducers/scorecards/scorecards.actions'
import { setFilter } from '../../../reducers/scorecards/scorecards.redux'
import { closeModal } from '../../../reducers/ui/ui.redux'
import { genericSchema } from './scorecardConfig.schema'
import { getOrganizationOptions } from '../../../utils/helpers'
import { EditSectionsRow } from './EditSectionsRow'
import { ModalFields } from './ModalFields'
import { handleAddSection } from './EditSectionsForm'

export const defaultSections = [
  {
    id: uuid(),
    name: 'Compliance',
    active: true,
    weight: 20,
    order_id: 0,
    measures: [
      { name: 'Account / Policy Verification' },
      { name: 'Company / Customer Information' },
      { name: 'Company Policy Violation' },
      { name: 'False Representation' },
      { name: 'Mini Miranda' },
      { name: 'Privacy Violation' },
      { name: 'Right Party Contact' },
      { name: 'Recording Disclosure' },
      { name: 'State / Federal Law Violation' },
      { name: 'Third-Party Disclosure' },
    ],
  },
  {
    id: uuid(),
    name: 'Opening / Greeting',
    active: true,
    weight: 20,
    order_id: 1,
    measures: [
      { name: 'Agent Identification' },
      { name: 'Offer Help' },
      { name: 'Use Personalized Greeting' },
    ],
  },
  {
    id: uuid(),
    name: 'Call Handling / Customer Service',
    active: true,
    weight: 20,
    order_id: 2,
    measures: [
      { name: 'Conversational Flow / Tone / Pace' },
      { name: 'Demonstrate Knowledge' },
      { name: 'Empathy, Rapport, Politeness' },
      { name: 'Followed Account Handling Process / Procedures' },
      { name: 'Hold / Transfer / Pause' },
      { name: 'Professionalism / Unprofessionalism' },
      { name: 'Red Flags' },
    ],
  },
  {
    id: uuid(),
    name: 'Resolution',
    active: true,
    weight: 20,
    order_id: 3,
    measures: [
      { name: 'Discovery Questions' },
      { name: 'Document Interactions in Appropriate Systems' },
      { name: 'Negotiations / Arrangements' },
    ],
  },
  {
    id: uuid(),
    name: 'Closing / Recap',
    active: true,
    weight: 20,
    order_id: 4,
    measures: [
      { name: 'Confirm Resolution' },
      { name: 'Offer Additional Assistance' },
      { name: 'Thank you' },
    ],
  },
]

// First page
export const InfoPage = ({ values, errors, disablePlaybookSelect }) => {
  const dispatch = useDispatch()
  const { organizations } = useSelector((state) => state)
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const { data, loading } = useSelector((state) => state.scorecards)

  const fetchOrgData = (id) => {
    dispatch(fetchPlaybooksByOrg(id))
  }

  const handleOrgSelect = (value, { field: { name }, form: { setFieldValue } }) => {
    if (value) {
      fetchOrgData(value)
    }
    setFieldValue(name, value)
  }

  const organizationOptions = getOrganizationOptions(organizations)

  const configOptions = data.playbooks.map((config) => ({
    label: config.label,
    value: config.id,
  }))

  useEffect(() => {
    if (values.organization_id) {
      fetchOrgData(values.organization_id)
    }
  }, [dispatch, values.organization_id])

  return (
    <div className="ui form">
      <div className="scorecard-wizard__info-page" data-testid="scorecard-wizard__info-page">
        <Field
          required
          errors={errors}
          name="name"
          label="Scorecard Name"
          data-testid="name-field"
          component={TextField}
          placeholder="Name your scorecard"
          className="w-100"
        />

        {organizationId === 1 && (
          <Field name="organization_id" label="Organization" placeholder="Select Organization">
            {(formikProps) => (
              <div className="w-100 field">
                <Select
                  disabled={disablePlaybookSelect}
                  required
                  label="Organization"
                  className="w-100"
                  loading={isEmpty(organizations)}
                  options={organizationOptions}
                  isDisabled={disablePlaybookSelect}
                  onChange={(option) => handleOrgSelect(option?.value || null, formikProps)}
                  {...formikProps}
                />
              </div>
            )}
          </Field>
        )}

        <Field
          required
          name="config_id"
          label="Linked Playbook"
          data-testid="playbook-dropdown"
          placeholder="Select a Playbook"
          component={Select}
          loading={loading.playbooks}
          options={configOptions}
          isDisabled={disablePlaybookSelect}
          className="w-100"
        />
        <Field
          required
          name="min_duration_in_minutes"
          data-testid="min-duration-in-minutes"
          label="Minimum Call Duration"
          placeholder="Minutes per call"
          component={TextField}
          type="number"
          step="0.05"
          max={999}
          min={0}
          style={{ display: 'flex' }}
        />
        <h3>Thresholds</h3>
        <p>
          Set performance bands for which calls are exceeding expectations, meeting expectations, or
          needing improvements.
        </p>
        <div className="scorecard-wizard__threshold-container">
          <Field
            required
            name="exceeds_threshold"
            label="Exceeds"
            component={TextField}
            type="number"
          />
          <Field
            required
            name="meets_threshold"
            label="Meets"
            component={TextField}
            type="number"
          />
          <Field
            required
            name="improvement_threshold"
            label="Below"
            component={TextField}
            type="number"
          />
        </div>
      </div>
    </div>
  )
}

// Second page
export const WeightPage = ({ values, setTotalWeight, errors, formikProps }) => {
  const { setValues } = formikProps
  const totalWeight = values.sections?.reduce((reducer, section) => {
    // Don't add in bad data structures
    if (typeof section.weight !== 'number') {
      return reducer
    }
    // Only add if section weight if section is active
    if (section?.active) {
      return reducer + section?.weight
    }
    return reducer
  }, 0)

  const onDragEnd = async (result) => {
    const { destination, source } = result
    if (!destination) {
      return
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }
    const updateSections = values.sections.map((section, index) =>
      !section.order_id ? giveOrderId(section, index) : section
    )
    const newSections = handleDrag(result, updateSections)
    const cloneSections = cloneDeep(newSections)
    setValues({ ...values, sections: cloneSections })
  }

  const updateSectionName = async (e, formFields) => {
    const updateSectionName = editNameInObject(e.target.value, formFields, values.sections)
    const updateSections = cloneDeep(updateSectionName)
    setValues({ ...values, sections: updateSections })
  }

  const updateStatusInObject = async (formValues) => {
    const updateSectionStatus = values.sections.map((section) => {
      const newSection = section
      if (section.id === formValues.id) {
        newSection.active = !formValues.active
      }
      return newSection
    })
    const updateSections = cloneDeep(updateSectionStatus)
    setValues({ ...values, sections: updateSections })
  }

  const updateWeightInObject = async (e, formValues) => {
    const updateSectionWeight = values.sections.map((section) => {
      const newSection = section
      if (section.id === formValues.id) {
        newSection.weight = parseInt(e.target.value, 10)
      }
      return newSection
    })
    const updateSections = cloneDeep(updateSectionWeight)
    setValues({ ...values, sections: updateSections })
  }

  const removeObject = async (formValues) => {
    const updateSections = values.sections.filter((section) => section.id !== formValues.id)
    setValues({ ...values, sections: updateSections })
  }
  // Total weight is passed to parent for disabling the next page button
  useEffect(() => {
    setTotalWeight(totalWeight)
  })
  return (
    <>
      <h5 style={{ fontSize: '16px' }}>Manage Sections</h5>
      <Grid
        data-testid="scorecard-wizard__weight-page"
        className="create-scorecard-form__container"
      >
        <Grid.Row>
          <Grid.Column width={7} style={{ justifyContent: 'left', marginLeft: '30px' }}>
            <h5 className="scorecard-wizard_section-headers">Sections</h5>
          </Grid.Column>
          <Grid.Column width={4}>
            <h5 className="scorecard-wizard_section-headers_status">Status</h5>
          </Grid.Column>
          <Grid.Column width={4} style={{ justifyContent: 'left', marginLeft: '10px' }}>
            <h5 className="scorecard-wizard_section-headers">Weight %</h5>
          </Grid.Column>
        </Grid.Row>
        <DragDropContext onDragEnd={onDragEnd}>
          <ModalFields
            items={values.sections}
            name="sections"
            RowComponent={EditSectionsRow}
            handleAddItem={handleAddSection}
            addItemLabel="Add Section"
            errors={errors}
            updateNameInObject={updateSectionName}
            updateStatusInObject={updateStatusInObject}
            updateWeightInObject={updateWeightInObject}
            removeFromObject={removeObject}
          />
        </DragDropContext>
        <Grid.Row>
          <Grid.Column
            width={12}
            style={{ display: 'flex', justifyContent: 'left', paddingTop: '6px' }}
          >
            <h5 style={{ paddingRight: '10px', marginBottom: '0px' }}>Total</h5>
            {totalWeight !== 100 && (
              <>
                <Icon name="exclamation triangle" color="red" data-testid="total-weight-warning" />
                <p>Sum of weights must equal 100%.</p>{' '}
              </>
            )}
          </Grid.Column>
          <Grid.Column width={4} floated="right">
            <Grid.Row>
              <Grid.Column
                width={4}
                floated="right"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Pill
                  emphasized
                  critical={totalWeight !== 100}
                  success={totalWeight === 100}
                  data-testid="total-weight-add"
                >
                  {`${Math.round(totalWeight).toString().slice(0, 3)}%`}
                </Pill>
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

// Table for third page
export const SectionSummaryTable = ({ title, rows, goToPageNumber, pageNumber }) => {
  return (
    <div>
      <div
        className="w-100"
        style={{
          borderBottom: '1px solid var(--dark-knight-300)',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h3>{title}</h3>
        <a onClick={() => goToPageNumber(pageNumber)}>Edit</a>
      </div>
      <Table basic="very" style={{ marginTop: 0 }}>
        {rows.map((row) => (
          <Table.Row key={row.value}>
            <Table.Cell>{row.label}</Table.Cell>
            <Table.Cell>{row.value}</Table.Cell>
          </Table.Row>
        ))}
      </Table>
    </div>
  )
}

// Third page
export const SummaryPage = ({ values, goToPageNumber }) => {
  const { data } = useSelector((state) => state.scorecards)
  const linkedConfig = data.playbooks.find((playbook) => playbook.id === values.config_id)

  // Tables expects an object structured as { title: [...rows] }
  const tableData = {
    'General Settings': [
      { label: 'Scorecard Name', value: values.name },
      { label: 'Linked Playbook', value: linkedConfig?.label || '' },
      { label: 'Min. Call Duration', value: `${values.min_duration_in_minutes} minute(s)` },
      { label: 'Exceeds Threshold', value: `${values.exceeds_threshold}%` },
      { label: 'Meets Threshold', value: `${values.meets_threshold}%` },
      { label: 'Below Threshold', value: `${values.improvement_threshold}%` },
    ],
    'Section Weighting': values.sections.map((scorecardSection) => ({
      label: scorecardSection.name,
      value: scorecardSection.active ? `${scorecardSection.weight}%` : '--',
    })),
  }

  return (
    <div className="scorecard-wizard__summary-page" data-testid="scorecard-wizard__summary-page">
      {Object.entries(tableData).map(([title, rows], index) => (
        <SectionSummaryTable
          title={title}
          rows={rows}
          goToPageNumber={goToPageNumber}
          pageNumber={index}
        />
      ))}
    </div>
  )
}

// Wizard with all pages
export const ScorecardWizard = () => {
  const { loading, filters } = useSelector((state) => state.scorecards)
  const dispatch = useDispatch()
  const history = useHistory()
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const [totalWeight, setTotalWeight] = useState(100)
  const [initialValues, setInitialValues] = useState({})
  // Setting default values for Formik
  useEffect(() => {
    const values = {
      name: '',
      organization_id: filters.organization || organizationId,
      config_id: null,
      exceeds_threshold: 90,
      meets_threshold: 80,
      improvement_threshold: 70,
      min_duration_in_minutes: '',
      active: false,
    }
    values.sections = defaultSections
    setInitialValues(values)
  }, [])

  // Building out the validation schema
  const shape = genericSchema
  const validationSchema = Yup.object().shape(shape)

  // Wizard modal provides current form values to pages on render
  const pages = [
    (values) => <InfoPage values={values} />,
    (values, _goToPageNumber, errors, _setFieldValues, props) => (
      <WeightPage
        values={values}
        setTotalWeight={setTotalWeight}
        errors={errors}
        formikProps={props}
      />
    ),
    (values, goToPageNumber) => <SummaryPage values={values} goToPageNumber={goToPageNumber} />,
  ]

  const handleSubmit = async (values) => {
    const sectionsWithUpdatedWeights = values.sections.map((section) => {
      return {
        ...section,
        weight: section.active ? section.weight : 0,
      }
    })

    const scorecardConfig = { ...values, sections: sectionsWithUpdatedWeights }
    const newScorecard = await dispatch(addScorecard(scorecardConfig))
    if (newScorecard) {
      history.push(`scorecards/edit_config/${newScorecard.id}`)
      dispatch(closeModal())
      dispatch(setFilter('playbooks', ''))
    }
  }
  return (
    <div data-testid="scorecard-wizard">
      {initialValues?.sections && (
        <WizardModal
          pages={pages}
          isLoading={loading.scorecards}
          initialValues={initialValues}
          validationSchema={validationSchema}
          disableButtons={totalWeight !== 100}
          rightButtonSubmitLabel="Create"
          title="Create Scorecard"
          onSave={handleSubmit}
          size="small"
        />
      )}
    </div>
  )
}
