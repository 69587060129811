import { DateRangePickerReusable } from '@/components/datePickers/DateRangePickerReusable'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { updateChallengeDates } from '@/reducers/gamification/gamification.actions'

export const ChallengeDatePicker = ({ individualChallenge }) => {
  const dispatch = useDispatch()
  const [challenge, setChallenge] = useState(individualChallenge)
  const [displayHighlight, setDisplayHighlight] = useState(false)
  const dateRangePickerRef = useRef(null)
  const { startDate: originalStartDate, endDate: originalEndDate } = individualChallenge
  const challengeHasStarted = !['scheduled', 'inactive'].includes(challenge.status)
  useEffect(() => {
    if (displayHighlight) {
      dateRangePickerRef.current.focus()
    }
  })
  const removeDateRange = () => {
    if (challenge.status !== 'inactive') return
    setDisplayHighlight(false)
    setChallenge({
      ...challenge,
      startDate: '',
      endDate: '',
    })
  }

  const cancelDatePicker = () => {
    setChallenge({
      ...challenge,
      startDate: originalStartDate,
      endDate: originalEndDate,
    })
  }

  const handleDateRangeFilterChange = (challengeToUpdate, selection) => {
    const { startDate, endDate } = selection
    setChallenge({
      ...challengeToUpdate,
      startDate: startDate ? moment(startDate).startOf('day').utc().format() : null,
      endDate: endDate ? moment(endDate).endOf('day').utc().format() : null,
    })
  }

  const handleDateSave = (event) => {
    event.stopPropagation()
    setDisplayHighlight(false)
    dispatch(updateChallengeDates(challenge))
  }

  const handleDateRangeClick = () => {
    setDisplayHighlight(true)
  }
  const returnLocalDate = (date) => (date ? moment.utc(date).local() : date)
  return (
    <div
      data-testid="challenge-date-range"
      tabIndex="-1"
      className="challenge-date-range"
      ref={dateRangePickerRef}
      onClick={handleDateRangeClick}
    >
      <DateRangePickerReusable
        startDate={returnLocalDate(challenge.startDate)}
        endDate={returnLocalDate(challenge.endDate)}
        initialStartDate={returnLocalDate(challenge.startDate)}
        initialEndDate={returnLocalDate(challenge.endDate)}
        position="right center"
        onSave={(e) => handleDateSave(e)}
        onChange={({ selection }) => {
          handleDateRangeFilterChange(challenge, selection)
        }}
        onCancel={cancelDatePicker}
        onClear={removeDateRange}
        displayClearButton
        disableClearButton={challenge.status !== 'inactive'}
        disableSaveButton={challengeHasStarted}
        clearButtonLabel="Clear Dates"
        withButtons
        withSidebar={false}
        basicLabel
        allowFutureDates={challenge.status !== 'active'}
        allowPastDates={false}
        retainEndDateOnFirstSelection
      />
    </div>
  )
}
