import React, { useMemo, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import WidgetContext from '../../../components/widget/widget.context'
import WidgetContainer from '../../../components/widget/WidgetContainer'
import WidgetTooltip from '../../../components/widget/WidgetTooltip'
import BarChart from '../../../components/charts/BarChart'
import { AdvancedTable } from '../../../components/tables/AdvancedTable/AdvancedTable'
import { formatPercent } from '../../../utils/helpers'
import { formatTopData } from './widgetHelpers'

export const TopWidget = ({ accessor, label }) => {
  const { data, loading } = useSelector((state) => state.analytics)
  const { isPercent, handleChangeCsvData } = useContext(WidgetContext)

  const dataAccessor = accessor === 'notification' ? 'topNotifications' : 'topDeckItems'

  /* Computed properties */
  const formattedData = useMemo(() => {
    return formatTopData(data[dataAccessor], isPercent)
  }, [data, isPercent])
  const horizontalChartData = useMemo(() => {
    return [...formattedData].reverse()
  }, [formattedData])
  const hortizontalChartColors = useMemo(
    () => horizontalChartData.map((data) => data.color),
    [horizontalChartData]
  )

  const columns = useMemo(
    () => [
      { accessor: 'item', label },
      {
        accessor: 'dataKey',
        label: `${label} Usage ${isPercent ? 'Percent' : 'Count'}`,
        format: (v) => (isPercent ? formatPercent(v) : Number(v).toLocaleString()),
      },
    ],
    [label, isPercent]
  )

  useEffect(() => {
    handleChangeCsvData({ headers: columns, data: formattedData })
  }, [formattedData])

  return (
    <WidgetContainer
      loading={loading.overviewPage}
      noData={isEmpty(formattedData)}
      withLegend
      dataForLegend={{ data: formattedData }}
      legendAccessor={['item']}
      disableLegendToggle
      table={
        <AdvancedTable
          index={['item']}
          defaultOrderBy="dataKey"
          defaultOrder="desc"
          rows={formattedData}
          columns={columns}
        />
      }
      chart={
        <BarChart
          isPercent={isPercent}
          data={horizontalChartData}
          colors={hortizontalChartColors}
          keys={['dataKey']}
          indexBy={['item']}
          enableGridX
          layout="horizontal"
          margin={{ top: 50, right: 50, bottom: 50, left: 20 }}
          tooltip={(item) => (
            <WidgetTooltip color={item.color}>
              <strong>{item.data.item}</strong>
              <span>
                {`: ${
                  isPercent
                    ? formatPercent(item.data.dataKey)
                    : Number(item.data.dataKey).toLocaleString()
                }`}
              </span>
            </WidgetTooltip>
          )}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Usage',
            legendPosition: 'middle',
            legendOffset: 40,
            format: (v) => (isPercent ? formatPercent(v) : Number(v).toLocaleString()),
          }}
        />
      }
    />
  )
}
