import React from 'react'

const IntegrationsPlaybookSwitchingPage = () => {
  return (
    <>
      <h2>Playbook Switching</h2>
    </>
  )
}

export default IntegrationsPlaybookSwitchingPage
