import React from 'react'
import { Popup } from 'semantic-ui-react'
import { capitalize } from 'lodash'

export default React.memo(({ slateObjectType, children }) => (
  <Popup
    inverted
    size="small"
    content={
      slateObjectType === 'inline-verbatim-container'
        ? 'Inline Phrase Matching'
        : slateObjectType.split('-').map((word) => `${capitalize(word)} `)
    }
    trigger={children}
  />
))
