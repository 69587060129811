import React from 'react'
import { Button } from 'semantic-ui-react'

export default React.memo((props) => (
  <div
    className="ui mini input"
    style={{
      display: 'flex',
      justifyContent: 'space-evenly',
      margin: '0.5rem',
      width: 'calc(100% - 1rem)',
    }}
  >
    <input
      type="text"
      value={props.urlInputValue}
      placeholder="Enter URL"
      onChange={props.updateUrlValue}
    />
    <Button
      onClick={props.handleSubmit}
      style={{ padding: '0px 5px 0px 5px', marginLeft: '.25em' }}
      size="tiny"
      primary
    >
      Confirm
    </Button>
    <Button
      onClick={(e) => {
        e.preventDefault()
        props.resetUrlInput()
      }}
      style={{ padding: '0px 5px 0px 5px' }}
      size="tiny"
      secondary
    >
      Cancel
    </Button>
  </div>
))
