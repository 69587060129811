import React from 'react'
import { Button } from 'semantic-ui-react'

export const EditButton = ({ onEdit, ...props }) => {
  return (
    <Button data-testid="edit-button" basic compact size="tiny" onClick={onEdit} {...props}>
      Edit
    </Button>
  )
}
