import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Loader, Radio, Segment } from 'semantic-ui-react'
import {
  fetchOrganization,
  fetchOrganizationAuditEvents,
  updateOrganizationProperties,
} from '@/reducers/organizations/organization.actions'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import './organizations.scss'
import { formatTime } from '@/utils/helpers'

export const OrganizationFlagsPage = () => {
  const dispatch = useDispatch()
  const organization = useSelector((state) => state.organization)
  const { organizationid: userOrganizationId } = useSelector((state) => state.currentUser)
  const isBaltoAdmin = userOrganizationId === 1
  const [loading, setLoading] = useState(true)
  const [auditEventsLoading, setAuditEventsLoading] = useState(true)
  const { organizationid } = useParams()

  const reloadAuditHistory = () => {
    const orgIdToUse = organizationid || userOrganizationId
    setAuditEventsLoading(true)
    dispatch(
      fetchOrganizationAuditEvents({
        organizationId: orgIdToUse,
        toggleLoading: () => setAuditEventsLoading(false),
      })
    )
  }

  useEffect(() => {
    reloadAuditHistory()
  }, [])

  useEffect(() => {
    const orgIdToUse = organizationid || userOrganizationId
    setLoading(true)
    dispatch(
      fetchOrganization({
        organizationId: orgIdToUse,
        toggleLoading: () => setLoading(false),
      })
    )
  }, [dispatch])

  const handleUpdateFlag = (property) => {
    setLoading(true)
    dispatch(
      updateOrganizationProperties({
        organizationId: organization.id,
        reloadOrganization: fetchOrganization,
        [property]: !organization[property],
        toggleLoading: () => setLoading(false),
      })
    ).then(() => reloadAuditHistory())
  }

  const create_toggle = (flag_name) => {
    return {
      as: (
        <Radio
          data-testid={`${flag_name}-toggle`}
          toggle
          checked={organization[flag_name]}
          onChange={() => handleUpdateFlag(flag_name)}
        />
      ),
      value: organization[flag_name],
    }
  }

  const flags = [
    {
      key: 'desktop_manual_config_selecting',
      active: create_toggle('desktop_manual_config_selecting'),
      description:
        'Makes agents start calls by manually selecting from a list of playbooks in the desktop app',
    },
    {
      key: 'save_audio',
      active: create_toggle('save_audio'),
      description: 'Saves audio of calls for organization',
    },
    {
      key: 'desktop_leaderboard_visibility',
      active: create_toggle('desktop_leaderboard_visibility'),
      description: '',
    },
    {
      key: 'qa_enabled',
      active: create_toggle('qa_enabled'),
      description: 'Enables Legacy QA Scorecards for Automated Scorecards',
    },
    {
      key: 'qa_copilot_enabled',
      active: create_toggle('qa_copilot_enabled'),
      description: 'Enables QA Copilot',
    },
    {
      key: 'realtime_coaching_access',
      active: create_toggle('realtime_coaching_access'),
      description: 'Enables Realtime Coaching',
    },
    {
      key: 'playbook_additional_info',
      active: create_toggle('playbook_additional_info'),
      description: 'Adds Additional Information to Playbooks. Currently used for E-Health',
    },
    {
      key: 'call_events_api_access',
      active: create_toggle('call_events_api_access'),
      description: 'Allows Access to Call Events API. Currently used for E-Health.',
    },
    {
      key: 'use_transcript_hyp',
      active: create_toggle('use_transcript_hyp'),
      description:
        'Whether to run analysis against partial transcription hypotheses, leading to faster analysis.',
    },
    {
      key: 'desktop_enterprise_enabled',
      active: create_toggle('desktop_enterprise_enabled'),
      description:
        'Enables Balto Enterprise (eHealth/NGIC) for users. Need to toggle the feature for each user.',
    },
    {
      key: 'custom_branding_enabled',
      active: create_toggle('custom_branding_enabled'),
      description: 'Enables custom branding for OEM partners',
    },
    {
      key: 'novel_content_disabled',
      active: create_toggle('novel_content_disabled'),
      description: "Disables agent's ability to add checklist items",
    },
    {
      key: 'smart_knowledge_base_enabled',
      active: create_toggle('smart_knowledge_base_enabled'),
      description: 'Enables smart knowledge base suggestions for BFS',
    },
    {
      key: 'screen_capture_enabled',
      active: create_toggle('screen_capture_enabled'),
      description:
        'Enables screen capture (NOTE: Enabling this will turn it on for ALL users of the org)',
    },
    {
      key: 'qa_disputes_enabled',
      active: create_toggle('qa_disputes_enabled'),
      description: 'Enables qa disputes in cloud and desktop app',
    },
    {
      key: 'call_data_dump_enabled',
      active: create_toggle('call_data_dump_enabled'),
      description: 'Enables nightly call data dump job',
    },
    {
      key: 'desktop_minimize_disabled',
      active: create_toggle('desktop_minimize_disabled'),
      description: 'Disables minimize button in agents desktop app',
    },
    {
      key: 'multi_call_scoring_enabled',
      active: create_toggle('multi_call_scoring_enabled'),
      description:
        'Enables merging scores of multiple calls into a single score for RTQA and QA Copilot',
    },
    {
      key: 'agent_gpt_enabled',
      active: create_toggle('agent_gpt_enabled'),
      description: 'Enables agent gpt',
    },
    {
      key: 'phrase_matching_intent_based_model_enabled',
      active: create_toggle('phrase_matching_intent_based_model_enabled'),
      description: 'Enables intent based phrase matching model',
    },
  ]

  const flagColumns = [
    {
      accessor: 'active',
      label: 'Status',
    },
    {
      accessor: 'key',
      label: 'Flag',
    },
    {
      accessor: 'description',
      label: 'Description',
    },
  ]

  const auditColumns = [
    {
      accessor: 'flag',
      label: 'What changed',
      isSearchable: true,
    },
    {
      accessor: 'value',
      label: 'Changed to',
      isSearchable: true,
    },
    {
      accessor: 'author',
      label: 'Who changed it',
      isSearchable: true,
    },
    {
      accessor: 'timestamp',
      label: 'When it happened',
      isSearchable: true,
    },
  ]

  const formatBooleanToOnOff = (value) => {
    return value ? 'On' : 'Off'
  }

  const formatAuditRows = (auditEvents) => {
    return auditEvents.map((event) => {
      return {
        key: event.id,
        flag: Object.keys(event.event_payload)[0],
        value: formatBooleanToOnOff(Object.values(event.event_payload)[0]),
        author: event.author,
        timestamp: formatTime(event.created_at),
      }
    })
  }

  return (
    <>
      <div>
        {isBaltoAdmin && loading ? (
          <Segment className="not-padded">
            <div className="empty-table">
              <Loader active />
            </div>
          </Segment>
        ) : (
          <>
            <h1 data-testid="org-name-header">{organization.name}</h1>
            <h5>
              Please make sure you know what you are doing before changing any of these flags. Ask
              Engineering for help if you are unsure. Thank you!
            </h5>
            <Segment className="not-padded">
              <AdvancedTable rows={flags} columns={flagColumns} defaultOrderBy="key" index="key" />
            </Segment>
          </>
        )}
      </div>
      <div>
        {isBaltoAdmin && auditEventsLoading ? (
          <Segment className="not-added">
            <div className="empty-table">
              <Loader active />
            </div>
          </Segment>
        ) : (
          <>
            <h2 className="second-header" data-testid="org-audit-events-header">
              Change History
            </h2>
            <Segment className="not-padded">
              <AdvancedTable
                rows={formatAuditRows(organization.auditEvents || [])}
                columns={auditColumns}
                defaultOrderBy="key"
                defaultOrder="desc"
                index="key"
                pagination
                testid="audit-events-table"
              />
            </Segment>
          </>
        )}
      </div>
    </>
  )
}

export default OrganizationFlagsPage
