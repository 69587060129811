import React, { useMemo, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { uniq, isEmpty, flatten, groupBy, sortBy } from 'lodash'

import WidgetContext from '../../../components/widget/widget.context'
import WidgetContainer from '../../../components/widget/WidgetContainer'
import WidgetLineChartTooltip from '../../../components/widget/WidgetLineChartTooltip'
import LineChart from '../../../components/charts/LineChart'
import { AdvancedTable } from '../../../components/tables/AdvancedTable/AdvancedTable'
import { formatPercent } from '../../../utils/helpers'
import { groupAndFormatItemsByDate, getSectionLabel } from './widgetHelpers'

export const ItemsByDateWidget = ({ section, isWin }) => {
  const { data, loading } = useSelector((state) => state.analytics)
  const { groupByValue, isPercent, hiddenItems, handleChangeCsvData, handleResetWidgetFilters } =
    useContext(WidgetContext)

  const itemAccessor = 'item'
  const sectionLabel = getSectionLabel(section)
  const dataAccessor = isWin ? 'win_count' : 'count'
  const dataLabel = isWin ? 'Win' : 'Frequency'

  // All the chart data, arranged by day/week/month view and percent view
  const formattedData = useMemo(() => {
    return groupAndFormatItemsByDate(
      itemAccessor,
      data[`${section}Items`],
      data[`${section}ItemsByDate`],
      groupByValue,
      isPercent,
      isWin
    )
  }, [data, groupByValue, isPercent])
  // If any items are hidden based on the legend selection
  const dataWithHiddenItems = useMemo(() => {
    return formattedData.filter((item) => !hiddenItems.has(item.id))
  }, [formattedData, hiddenItems])

  // Table data is flattened and still hidden based on legend
  const tableData = useMemo(() => {
    return flatten(
      dataWithHiddenItems.map((arr) => {
        // add color attr to data
        return arr.data.map((item) => {
          return Object.assign(item, { color: arr.color })
        })
      })
    )
  }, [dataWithHiddenItems])
  const legendData = section !== 'checklist' ? sortBy(formattedData, (o) => o.id) : formattedData

  const groupedByDate = groupBy(tableData, (item) => item.date)
  const colors = useMemo(() => dataWithHiddenItems.map((data) => data.color), [dataWithHiddenItems])
  const columns = useMemo(
    () => [
      { accessor: 'date', label: 'Date' },
      { accessor: itemAccessor, label: sectionLabel },
      ...(isPercent
        ? [
            {
              accessor: 'percent',
              label: `${sectionLabel} ${dataLabel} Percent`,
              format: (v) => formatPercent(v),
            },
          ]
        : [
            {
              accessor: dataAccessor,
              label: `${sectionLabel} ${dataLabel} Count`,
              format: (value) => value.toLocaleString(),
            },
          ]),
    ],
    [isPercent]
  )

  const tickValues = useMemo(
    () => uniq(tableData.map((d) => d.date)).filter((item, i) => i % 2 === 0) || [],
    [tableData]
  )

  useEffect(() => {
    handleChangeCsvData({ headers: columns, data: tableData })
  }, [tableData])

  useEffect(() => {
    handleResetWidgetFilters()
  }, [data])

  return (
    <WidgetContainer
      loading={loading[`${section}Page`]}
      noData={isEmpty(tableData) && hiddenItems.size === 0}
      withLegend
      dataForLegend={{ data: legendData }}
      legendAccessor="id"
      table={
        <AdvancedTable
          index="uuid"
          rows={tableData}
          columns={columns}
          rowsPerPage={10}
          pagination
          enableUpdateRowsPerPage={false}
        />
      }
      chart={
        <LineChart
          isPercent={isPercent}
          data={dataWithHiddenItems}
          colors={colors}
          tooltip={({ point }) => {
            const xGroupedData = groupedByDate[point.data.date]
            const tooltipData = xGroupedData.filter((item) =>
              isPercent
                ? item.percent === point.data.percent
                : item[dataAccessor] === point.data[dataAccessor]
            )

            const tooltipItemCount = tooltipData.length

            const noItemsUsed =
              tooltipItemCount === dataWithHiddenItems.length && point.data[dataAccessor] === 0
            const excessItems = tooltipItemCount > 20 ? tooltipData.length - 19 : 0

            const displayData = noItemsUsed
              ? // if none of possible items were used, display text instead of all zeroes
                [{ [itemAccessor]: `No entries for ${point.data.date}`, displayText: true }]
              : // if more than 20 items, cut off the list at 19 & incl explanatory text
                excessItems > 0
                ? tooltipData.slice(0, 19).concat({
                    [itemAccessor]: `+ ${excessItems} entries not displayed`,
                    displayText: true,
                  })
                : [...tooltipData]

            return (
              <WidgetLineChartTooltip
                header={point.data.date}
                tooltipData={displayData}
                isPercent={isPercent}
                keyAccessor={itemAccessor}
                dataAccessor={dataAccessor}
                column={!noItemsUsed && tooltipItemCount > 10}
              />
            )
          }}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Date',
            legendOffset: 36,
            legendPosition: 'middle',
            ...(groupByValue === 'day' &&
              uniq(tableData.map((d) => d.date)).length > 30 && {
                tickValues,
              }),
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: isWin ? `${dataLabel}s` : dataLabel,
            legendOffset: -60,
            legendPosition: 'middle',
            format: (v) =>
              isPercent
                ? formatPercent(v, 1)
                : Math.floor(v) === v
                  ? Number(v).toLocaleString()
                  : v,
          }}
        />
      }
    />
  )
}
