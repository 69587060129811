import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { applyCallsViewFilters } from '@/reducers/analytics/analytics.actions'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'

import './CallsViewFilter.scss'

const CALLS_WITH_EVENTS = 'CALLS_WITH_EVENTS'
const ALL_CALLS = 'ALL_CALLS'
const callsFilterValues = {
  [CALLS_WITH_EVENTS]: {
    withEvents: true,
  },
  [ALL_CALLS]: {
    withEvents: false,
  },
  // At some point we may implement Calls with No Responses option
  // which will require additional values to be sent
}
const callsFilterOptions = [
  { value: CALLS_WITH_EVENTS, label: 'Calls With Events' },
  { value: ALL_CALLS, label: 'All Calls' },
]

export const CallsViewFilter = () => {
  const dispatch = useDispatch()
  const {
    data: { deckItems },
    callsViewFilters: callsViewFiltersFromRedux,
  } = useSelector((state) => state.analytics)

  const filterCalls = (values, formikBag) => {
    const { setSubmitting } = formikBag
    setSubmitting(false)

    dispatch(
      applyCallsViewFilters({
        deckItems: values.deckItems.map((item) => item.value),
        ...callsFilterValues[values.callsFilter],
      })
    )
  }

  const deckItemsOptions = deckItems.map((item) => ({
    label: item,
    value: item,
    key: item,
  }))
  const deckItemsSchema = {
    name: 'deckItems',
    label: 'Dynamic Prompts',
    type: 'multiSelect',
    placeholder: 'Select Prompts',
    options: deckItemsOptions,
  }
  const callsFilterSchema = {
    name: 'callsFilter',
    label: 'Calls Filter',
    type: 'select',
    placeholder: 'Select Calls',
    options: callsFilterOptions,
    isClearable: false,
  }
  const schema = [deckItemsSchema, callsFilterSchema]
  const existingValues = {
    deckItems: callsViewFiltersFromRedux.entries.map((item) => ({
      label: item,
      value: item,
      key: item,
    })),
    callsFilter: callsViewFiltersFromRedux.with_events ? CALLS_WITH_EVENTS : ALL_CALLS,
  }

  return (
    <div className="calls-view-filter">
      <AbstractForm
        enableReinitialize
        onSubmit={filterCalls}
        existingValues={existingValues}
        schema={schema}
        buttonLabel="Filter"
      />
    </div>
  )
}
