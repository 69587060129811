import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const RtcSocketProvider = () => {
  const { isOpen: rtcSocketIsOpen } = useSelector((state) => state.realtimeSocket)
  const {
    org_realtime_coaching_access: orgRtcAccess,
    realtime_coaching_access: userRtcAccess,
    real_time_management_access: deprecatedRtcAccess,
  } = useSelector((state) => state.currentUser)
  const dispatch = useDispatch()

  useEffect(() => {
    if ((userRtcAccess || deprecatedRtcAccess) && !rtcSocketIsOpen && orgRtcAccess) {
      dispatch({ type: 'OPEN_RTM_SOCKET' })
    }

    if (((!userRtcAccess && !deprecatedRtcAccess) || !orgRtcAccess) && rtcSocketIsOpen) {
      dispatch({ type: 'CLOSE_RTM_SOCKET' })
    }
  }, [userRtcAccess, orgRtcAccess])

  return null
}

export default RtcSocketProvider
