// Action Types
import produce from 'immer'

const SET_CONFIG_PATHS = 'realtime/setConfigPaths'
const UPDATE_CONFIG_PATHS = 'realtime/updateConfigPaths'
const SET_CONFIG_PATHS_LOADING = 'realtime/setConfigPathsLoading'

export const setConfigPaths = (payload) => ({
  type: SET_CONFIG_PATHS,
  payload,
})
export const setConfigPathsLoading = (payload) => ({
  type: SET_CONFIG_PATHS_LOADING,
  payload,
})
export const updateConfigPaths = (payload) => ({
  type: UPDATE_CONFIG_PATHS,
  payload,
})

// Initial State
export const initialState = {
  configPaths: {},
  configPathsLoading: false,
}

// Reducer
export default function realtimeNewAlertConfiguration(state = initialState, action) {
  return produce(state, (newState) => {
    switch (action.type) {
      case SET_CONFIG_PATHS:
        newState.configPaths = action.payload
        break

      case UPDATE_CONFIG_PATHS:
        newState.configPaths[Object.keys(action.payload)[0]].paths = Object.values(
          action.payload
        )[0].paths
        break

      case SET_CONFIG_PATHS_LOADING:
        newState.configPathsLoading = action.payload
        break

      default:
        break
    }
  })
}
