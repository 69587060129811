import { toast } from 'react-toastify'

import { fetchingAPI, apiService } from '../../api'
import { setLoading, setKeywords } from './keywords.redux'

export const fetchKeywordsRepository = (language) => async (dispatch) => {
  dispatch(setLoading({ keywords: true }))

  try {
    let url = `${apiService.web}/api/keywords_repository`
    if (language) {
      url += `?language=${language}`
    }
    const response = await fetchingAPI(url, 'GET', dispatch)
    dispatch(setKeywords(response, language))
  } catch (err) {
    toast.error('Failed to fetch keywords')
  } finally {
    dispatch(setLoading({ keywords: false }))
  }
}

export const saveKeyword = (keyword, language) => async (dispatch) => {
  dispatch(setLoading({ keywords: true }))

  try {
    let url = `${apiService.web}/api/keywords_repository`
    if (language) {
      url += `?language=${language}`
    }
    await fetchingAPI(url, 'POST', dispatch, JSON.stringify(keyword))
    dispatch(fetchKeywordsRepository(language))
  } catch (err) {
    toast.error('Failed to save keywords')
  } finally {
    dispatch(setLoading({ keywords: false }))
  }
}

export const deleteKeyword = (krid, language) => async (dispatch) => {
  dispatch(setLoading({ keywords: true }))

  try {
    let url = `${apiService.web}/api/keywords_repository/${krid}/versions`
    if (language) {
      url += `?language=${language}`
    }
    await fetchingAPI(url, 'DELETE', dispatch)
    dispatch(fetchKeywordsRepository(language))
  } catch (err) {
    toast.error('Failed to delete keywords')
  } finally {
    dispatch(setLoading({ keywords: false }))
  }
}

export const deleteKeywordCheck = (krid) => async (dispatch) => {
  dispatch(setLoading({ deleteCheck: true }))

  try {
    const associatedPlaybooks = await fetchingAPI(
      `${apiService.web}/api/keywords_repository/${krid}/configs`
    )

    return associatedPlaybooks
  } catch (err) {
    throw new Error()
  } finally {
    dispatch(setLoading({ deleteCheck: false }))
  }
}
