import React, { useState } from 'react'
import { Accordion, AccordionTitle, AccordionContent } from 'semantic-ui-react'
import { toString, truncate } from 'lodash'
import pluralize from 'pluralize'
import moment from 'moment'
import { IconBulb } from '@tabler/icons-react'

import { formatPercent } from '@/utils/helpers'

export const CoachingOpportunityRow = ({ data, filters }) => {
  const [activeIndex, setActiveIndex] = useState(-1)
  const failurePercent = formatPercent(data.top_missed_criteria.failing_percent)
  const duration = moment.duration(moment(filters.endDate).diff(moment(filters.startDate)))
  const days = pluralize('day', toString(Math.floor(duration.asDays())), true)

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex)
  }

  return (
    <Accordion className="info-accordion">
      <AccordionTitle active={activeIndex === 0} index={0} onClick={handleAccordionClick}>
        <div className="vertical-center status-informative">
          <IconBulb className="icon-svg" />
        </div>
        <span>
          <strong>
            &ldquo;{truncate(data.top_missed_criteria.criteria_name, { length: 17 })}&rdquo;
          </strong>{' '}
          is missing <strong>{failurePercent}</strong> of the time.
        </span>
        <strong className="info-accordion-link">{activeIndex === 0 ? 'Less...' : 'More...'}</strong>
      </AccordionTitle>
      <AccordionContent active={activeIndex === 0} index={0}>
        <span>
          In the past {days}, {data.first_name} did not meet the
          <strong> &ldquo;{data.top_missed_criteria.criteria_name}&rdquo; </strong> criteria in
          <span> {data.top_missed_criteria.failing_call_count} </span> out of
          <span> {data.top_missed_criteria.call_count} </span> calls. It would be beneficial to
          review the calls and establish a realistic goal to decrease the number of instances where
          the
          <strong> &ldquo;{data.top_missed_criteria.criteria_name}&rdquo; </strong> criteria is not
          met.
        </span>
      </AccordionContent>
    </Accordion>
  )
}
