import React, { useState } from 'react'
import { Form, Button, Popup } from 'semantic-ui-react'
import classNames from 'classnames'
import { Formik, Field } from 'formik'
import { isEqual, startCase } from 'lodash'

import { IconAdjustments } from '@tabler/icons-react'
import { CreatableInput } from '@/components/forms/CreatableInput'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { RadioGroup } from '@/components/forms/formik'
import { initialState } from '@/reducers/callSearch/callSearch.redux'

import './KeywordSearch.scss'

export const KeywordSearch = ({ isEnabled, handleUpdateFilters, filters }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const filtersHaveBeenApplied = !isEqual(
    filters.keywordOptions,
    initialState.filters.keywordOptions
  )

  const handleClose = () => setModalOpen(false)

  const handleUpdateKeywordOptions = (values) => {
    handleUpdateFilters('keywordOptions', values)
    handleClose()
  }

  if (!isEnabled) {
    return null
  }

  return (
    <>
      <Form.Field data-testid="keyword-filter">
        <label>Keywords</label>
        <div className="keyword-search-container flex-align-stretch medium-gap">
          <CreatableInput
            nameKey="keywords"
            placeholder="Enter keywords..."
            value={filters.keywords}
            handleUpdateFilters={handleUpdateFilters}
          />
          <Popup
            inverted
            on="hover"
            position="bottom center"
            content={
              <div className="text-left">
                <div className="flex small-gap">
                  <strong>Search Logic:</strong>
                  <span>{startCase(filters.keywordOptions.logic)}</span>
                </div>
                <div className="flex small-gap">
                  <strong>Side:</strong>
                  <span>{startCase(filters.keywordOptions.side)}</span>
                </div>
              </div>
            }
            trigger={
              <Button
                icon
                secondary
                type="button"
                onClick={() => setModalOpen(true)}
                className={classNames('svg-button', { 'filters-applied': filtersHaveBeenApplied })}
              >
                <IconAdjustments />
              </Button>
            }
          />
        </div>
      </Form.Field>

      {modalOpen && (
        <BasicModal
          title="Advanced Keyword Search"
          onClose={() => setModalOpen(false)}
          show={modalOpen}
          size="tiny"
        >
          <Formik initialValues={filters.keywordOptions} onSubmit={handleUpdateKeywordOptions}>
            {({ handleSubmit }) => (
              <div className="ui form">
                <Field
                  name="logic"
                  label="Search Logic"
                  component={RadioGroup}
                  options={[
                    { label: 'And', value: 'and' },
                    { label: 'Or', value: 'or' },
                  ]}
                />
                <Field
                  name="side"
                  label="Side"
                  component={RadioGroup}
                  options={[
                    { label: 'Me', value: 'me' },
                    { label: 'Them', value: 'them' },
                    { label: 'Both', value: 'both' },
                  ]}
                />
                <div className="modal-footer">
                  <Button secondary type="button" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button primary onClick={handleSubmit} type="button">
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </BasicModal>
      )}
    </>
  )
}
