import queryString from 'query-string'

import { apiService, fetchingAPI } from '@/api'
import { setRouteError } from '@/reducers/errors/routeErrors.redux'

// TODO: Remove dependency from Scorecards, Leaderboards, CSV Preview
export const loadConfigs = ({ updateLoading, requestedProperties, organizationId } = {}) => {
  const requestedPropertiesQueryString = requestedProperties
    ? `?${queryString.stringify({ requested_properties: requestedProperties })}`
    : ''
  const url = organizationId
    ? `${apiService.web}/api/${organizationId}/configs${requestedPropertiesQueryString}`
    : `${apiService.web}/api/configs${requestedPropertiesQueryString}`
  return (dispatch) => {
    fetchingAPI(url, 'GET', dispatch)
      .then((configs) => {
        dispatch({
          type: 'LOAD_CONFIGS',
          configs,
        })
        if (updateLoading) updateLoading(false)
      })
      .catch((err) => {
        console.error('loadConfigs failed', err)
        dispatch(setRouteError('Unable to load playbook. Please contact Balto Customer Success'))
        if (updateLoading) updateLoading(false)
      })
  }
}
