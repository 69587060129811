import React from 'react'
import { Link } from 'react-router-dom'
import pluralize from 'pluralize'
import queryString from 'query-string'
import { toast } from 'react-toastify'
import { isEmpty, cloneDeep } from 'lodash'

import { apiService, fetchingAPI } from '@/api'
import {
  getCoachingComments,
  postCoachingComment,
  patchCoachingComment,
} from '@/reducers/callSearch/callSearch.actions'

import * as redux from './playlists.redux'

export const fetchPlaylists = (organizationId, includeCoaching = false) => {
  const params = queryString.stringify({
    requested_organization_id: organizationId,
    include_coaching: includeCoaching,
  })

  return async (dispatch) => {
    dispatch(redux.setLoading({ playlists: true }))

    try {
      const [playlistsResponse, playlistsChangesResponse] = await Promise.all([
        fetchingAPI(`${apiService.playlist}/playlists?${params}`, 'GET', dispatch),
        fetchingAPI(`${apiService.playlist}/history?${params}`, 'GET', dispatch),
      ])

      dispatch(redux.setPlaylists(playlistsResponse))
      dispatch(redux.setPlaylistsChanges(playlistsChangesResponse))
    } catch (err) {
      toast.error('Failed to fetch playlists')
    } finally {
      dispatch(redux.setLoading({ playlists: false }))
    }
  }
}

export const mapCommentsToCalls = (calls, comments) => {
  const filteredComments = comments.filter((comment) => !comment.deleted)
  return calls.map((call) => {
    const callComments = filteredComments.filter((comment) => comment.call_id === call.call_id)
    return { ...call, callComments }
  })
}

export const fetchPlaylist = (playlistUuid, organizationId) => {
  const params = queryString.stringify({ requested_organization_id: organizationId })

  return async (dispatch) => {
    dispatch(redux.setLoading({ playlist: true }))

    try {
      const [playlistResponse, playlistChangesResponse] = await Promise.all([
        fetchingAPI(`${apiService.playlist}/playlists/${playlistUuid}?${params}`, 'GET', dispatch),
        fetchingAPI(`${apiService.playlist}/history/${playlistUuid}?${params}`, 'GET', dispatch),
      ])

      let calls = []

      if (!isEmpty(playlistResponse.playlist_calls)) {
        const callsResponse = await fetchingAPI(
          `${apiService.reporting}/api/calls`,
          'POST',
          dispatch,
          JSON.stringify({
            call_ids: playlistResponse.playlist_calls.map((value) => value.call_id),
            organization_id: playlistResponse.organization_id,
          })
        )
        const commentsResponse = await fetchingAPI(
          `${apiService.reporting}/api/coaching_comments?${queryString.stringify({
            call_ids: playlistResponse.playlist_calls.map((value) => value.call_id),
            organization_id: playlistResponse.organization_id,
          })}`
        )

        calls = mapCommentsToCalls(callsResponse.calls, commentsResponse)
      }

      const playlist = {
        ...playlistResponse,
        playlist_calls: playlistResponse.playlist_calls.map((value) => {
          const callData = calls.find((call) => call.call_id === value.call_id)

          return { ...callData, ...value }
        }),
      }

      dispatch(redux.setPlaylist(playlist))
      dispatch(redux.setPlaylistChanges(playlistChangesResponse))
    } catch (err) {
      toast.error('Failed to fetch playlist')
      dispatch(redux.setPlaylist(null))
      dispatch(redux.setPlaylistChanges([]))
    } finally {
      dispatch(redux.setLoading({ playlist: false }))
    }
  }
}

export const fetchCoachingPlaylist = (organizationId) => {
  const params = queryString.stringify({
    requested_organization_id: organizationId,
    include_coaching: true,
  })

  return async (dispatch) => {
    try {
      const playlists = await fetchingAPI(
        `${apiService.playlist}/playlists?${params}`,
        'GET',
        dispatch
      )

      const coachingPlaylist = playlists.find((playlist) => playlist.type === 'coaching')
      if (coachingPlaylist) {
        await dispatch(fetchPlaylist(coachingPlaylist.uuid, organizationId))
      } else {
        dispatch(redux.setPlaylist(null))
        dispatch(redux.setPlaylistChanges([]))
      }
    } catch (err) {
      toast.error('Failed to fetch coaching playlist')
      dispatch(redux.setPlaylist(null))
      dispatch(redux.setPlaylistChanges([]))
    }
  }
}

export const createPlaylist = (name, organizationId, callIds = []) => {
  const params = queryString.stringify({ requested_organization_id: organizationId })

  return async (dispatch, getState) => {
    const { currentUser } = getState()
    const body = JSON.stringify({
      name,
      playlist_calls: callIds.map((callId) => ({ call_id: callId })),
    })

    try {
      const response = await fetchingAPI(
        `${apiService.playlist}/playlists?${params}`,
        'POST',
        dispatch,
        body
      )

      await dispatch(fetchPlaylists(organizationId || currentUser.organizationid))

      toast.success(
        <div>
          <p>Playlist created</p>
          <p>
            <Link to={`/playlists/${response.uuid}?organization_id=${organizationId}`}>
              View Playlist
            </Link>
          </p>
        </div>
      )
    } catch (err) {
      toast.error('Failed to create playlist')
    }
  }
}

export const patchPlaylist = (playlistUuid, organizationId, values) => {
  const params = queryString.stringify({ requested_organization_id: organizationId })

  return async (dispatch) => {
    try {
      const body = JSON.stringify(values)

      await fetchingAPI(
        `${apiService.playlist}/playlists/${playlistUuid}?${params}`,
        'PATCH',
        dispatch,
        body
      )
      // Must do your own fetching after this because sometimes it's used on the "playlist" page and sometimes on the "playlists" page

      toast.success('Playlist updated')
    } catch (err) {
      toast.error('Failed to update playlist')
    }
  }
}

export const addCallsToPlaylist = (
  playlistUuid,
  organizationId,
  callIds = [],
  isCoachingPlaylist
) => {
  return async (dispatch, getState) => {
    const { currentUser } = getState()
    const params = queryString.stringify({ requested_organization_id: organizationId })

    try {
      const body = JSON.stringify({
        playlist_calls: callIds.map((callId) => ({ call_id: callId })),
      })

      await fetchingAPI(
        `${apiService.playlist}/playlists/${playlistUuid}?${params}`,
        'PATCH',
        dispatch,
        body
      )

      if (isCoachingPlaylist) {
        toast.success(
          <div>
            <p>{`${pluralize('Call', callIds.length)} flagged for coaching`}</p>
            {currentUser.organizationid === organizationId ? (
              <p>
                <Link to="/coaching" data-testid="toast-playlist-link">
                  View Coaching List
                </Link>
              </p>
            ) : (
              <p>Coaching playlist not visible to you</p>
            )}
          </div>
        )
      } else {
        toast.success(
          <div>
            <p>{`${pluralize('Call', callIds.length)} added to playlist.`}</p>
            <p>
              <Link
                to={`/playlists/${playlistUuid}?organization_id=${organizationId}`}
                data-testid="toast-playlist-link"
              >
                View Playlist
              </Link>
            </p>
          </div>
        )
      }
    } catch (err) {
      toast.error('Failed to add calls')
    }
  }
}

export const deletePlaylistCall = (playlistUuid, organizationId, callId) => {
  const params = queryString.stringify({ requested_organization_id: organizationId })

  return async (dispatch) => {
    try {
      await fetchingAPI(
        `${apiService.playlist}/playlists/${playlistUuid}/playlist_call/${callId}?${params}`,
        'DELETE',
        dispatch
      )
      await dispatch(fetchPlaylist(playlistUuid, organizationId))
      toast.success('Call removed')
    } catch (err) {
      toast.error('Failed to remove call')
    }
  }
}

export const patchPlaylistCall = (playlistUuid, organizationId, callId, value) => {
  const params = queryString.stringify({ requested_organization_id: organizationId })

  return async (dispatch) => {
    try {
      const body = JSON.stringify({
        uuid: playlistUuid,
        playlist_calls: [{ call_id: callId, ...value }],
      })

      await fetchingAPI(
        `${apiService.playlist}/playlists/${playlistUuid}?${params}`,
        'PATCH',
        dispatch,
        body
      )
      await dispatch(fetchPlaylist(playlistUuid, organizationId))
      toast.success('Call updated')
    } catch (err) {
      toast.error('Failed to update call')
    }
  }
}

export const deletePlaylist = (playlistUuid, organizationId) => {
  const params = queryString.stringify({ requested_organization_id: organizationId })

  return async (dispatch) => {
    try {
      await fetchingAPI(
        `${apiService.playlist}/playlists/${playlistUuid}?${params}`,
        'DELETE',
        dispatch
      )
      await dispatch(fetchPlaylists(organizationId))
      toast.success('Playlist deleted')
    } catch (err) {
      toast.error('Failed to delete playlist')
    }
  }
}

export const fetchPlaylistsByCallId = (callId) => {
  return async (dispatch) => {
    try {
      dispatch(redux.setLoading({ playlistsByCallId: true }))

      const response = await fetchingAPI(
        `${apiService.playlist}/playlists/by_call_id/${callId}`,
        'GET',
        dispatch
      )

      dispatch(redux.setPlaylistsByCallId(response))
    } catch (err) {
      toast.error('Failed to fetch associated playlists')
    } finally {
      dispatch(redux.setLoading({ playlistsByCallId: false }))
    }
  }
}

// Duplicated from call explorer
export const fetchSummariesForCallIds = (call_ids) => async (dispatch, getState) => {
  const { playlist } = getState().playlists
  const playlistCalls = playlist?.playlist_calls || []
  const callsCopy = cloneDeep(playlistCalls).map((call) => {
    if (call_ids.includes(call.call_id)) {
      return {
        ...call,
        // this empty object is tracking which call_ids we make requests for.
        // If call_summary is empty object we requested and got nothing back so
        // no summary otherwise request if this call is rendered and call_summary is undefined
        call_summary: {},
      }
    }
    return call
  })

  try {
    const summaries = await fetchingAPI(
      `${apiService.summary}/summaries/multiple`,
      'POST',
      dispatch,
      JSON.stringify({ call_ids })
    )
    callsCopy.forEach((call) => {
      if (call.call_summary) {
        const summaryForCall = summaries.find((summary) => summary.call_id === call.call_id)
        if (summaryForCall?.parsed_summary) {
          Object.assign(call, { call_summary: summaryForCall.parsed_summary })
          Object.assign(call, { ...summaryForCall.parsed_summary })
        }
      }
    })
    dispatch(redux.setPlaylist({ ...playlist, playlist_calls: callsCopy }))
  } catch (error) {
    console.error('Unable to fetch summaries')
    toast.error('Failed to fetch summaries')
  }
}

export const fetchCoachingCommentsByCall = (callId) => async (dispatch) => {
  try {
    const callComments = await dispatch(getCoachingComments(callId))
    dispatch(redux.setPlaylistCallComments(callComments))
  } catch (err) {
    toast.error('Failed to fetch coaching notes')
  }
}

export const refreshComments = (callId) => async (dispatch, getState) => {
  const { playlist } = getState().playlists
  // refresh the comment count on the table

  const commentsResponse = await fetchingAPI(
    `${apiService.reporting}/api/coaching_comments?${queryString.stringify({
      call_ids: playlist.playlist_calls.map((value) => value.call_id),
      organization_id: playlist.organization_id,
    })}`
  )

  const calls = mapCommentsToCalls(playlist.playlist_calls, commentsResponse)

  dispatch(
    redux.setPlaylist({
      ...playlist,
      playlist_calls: calls,
    })
  )

  await dispatch(fetchCoachingCommentsByCall(callId))
}

export const addCoachingComment = (commentText, callId) => async (dispatch) => {
  try {
    await dispatch(postCoachingComment(commentText, callId))
    await dispatch(refreshComments(callId))

    toast.success('Coaching note added')
  } catch (err) {
    toast.error('Failed to add coaching note')
  }
}

export const updateCoachingComment = (updatedProperty, commentId, callId) => async (dispatch) => {
  try {
    await dispatch(patchCoachingComment(updatedProperty, commentId))
    await dispatch(refreshComments(callId))

    toast.success('Coaching note updated')
  } catch (err) {
    toast.error('Failed to update coaching note')
  }
}
