import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Segment } from 'semantic-ui-react'
import { format } from 'date-fns'
import { IconBan, IconPlus } from '@tabler/icons-react'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { acceptTascRecommendation } from '@/reducers/recommendations/recommendations.actions'
import TascRejectRecommendationModal from './TascRejectRecommendationModal'
import { filterPendingRecommendations } from './recommendations.helpers'

import './BeaconContent.scss'

export const TascRecommendationsTable = () => {
  const [showModal, setShowModal] = useState(false)
  const [selectedRecommendation, setSelectedRecommendation] = useState(null)

  const dispatch = useDispatch()
  const { tascRecommendations, loading } = useSelector((state) => state.recommendations)

  const onAcceptClick = (row) => {
    const {
      id: recommendationId,
      playbook_info: playbookInfo,
      // this will break when we start doing decklist tasc items with the new playbook structure
      checklist_variation_uuid: entryVariationUuid,
      checklist_item_uuid: entryPropsUuid,
    } = row
    dispatch(
      acceptTascRecommendation({
        recommendationId,
        playbookInfo,
        entryVariationUuid,
        entryPropsUuid,
      })
    )
  }

  const onRejectClick = (row) => {
    setSelectedRecommendation(row)
    setShowModal(true)
  }

  const columns = [
    {
      accessor: 'recommendation',
      label: 'Recommended Content',
      isSearchable: true,
    },
    {
      accessor: 'recommendation_type',
      label: 'Type',
      format: (type) => {
        if (type.indexOf('edited') === 0) {
          return <span className="agent-recommendation-type">Variation</span>
        }
        if (type.indexOf('novel') === 0) {
          return <span className="agent-recommendation-type">New</span>
        }
        return null
      },
    },
    {
      accessor: 'adoption_rate',
      label: 'Adoption Rate',
      format: (rate) => `${rate}%`,
    },
    {
      accessor: 'source_details',
      label: 'Author',
    },
    {
      accessor: 'created_on',
      label: 'Created Date',
      format: (date) => `${date ? format(new Date(date), 'M/d/y') : ''}`,
    },
  ]

  const actions = [
    {
      label: 'Reject',
      icon: <IconBan />,
      fn: (e, row) => {
        onRejectClick(row)
      },
    },
    {
      label: 'Accept',
      icon: <IconPlus />,
      fn: (e, row) => {
        onAcceptClick(row)
      },
    },
  ]

  const filteredRecommendations = filterPendingRecommendations(tascRecommendations)

  return (
    <>
      <TascRejectRecommendationModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedRecommendation={selectedRecommendation}
      />
      <Segment data-testid="tasc-table" className="not-padded">
        <AdvancedTable
          defaultOrderBy="adoption_rate"
          columns={columns}
          rows={filteredRecommendations}
          actions={actions}
          stickyAction
          loading={loading}
          pagination
          index="id"
          defaultOrder="desc"
          wrapColumnContent={false}
        />
      </Segment>
    </>
  )
}
