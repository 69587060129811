import React, { useMemo, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, toString } from 'lodash'
import pluralize from 'pluralize'

import WidgetContext from '../../../components/widget/widget.context'
import WidgetContainer from '../../../components/widget/WidgetContainer'
import WidgetTooltip from '../../../components/widget/WidgetTooltip'
import BarChart from '../../../components/charts/BarChart'
import { AdvancedTable } from '../../../components/tables/AdvancedTable/AdvancedTable'
import { AdvancedTablePagination } from '../../../components/tables/AdvancedTable/AdvancedTablePagination'
import { formatPercent, truncateString } from '../../../utils/helpers'
import {
  groupAndFormatItemsByUser,
  getUserTableData,
  getItemsWithColors,
  formatFullName,
} from './widgetHelpers'

export const ItemsByUserWidget = ({ section }) => {
  const [activePage, setActivePage] = useState(1)
  const { data, loading } = useSelector((state) => state.analytics)
  const { isPercent, hiddenItems, handleChangeCsvData, handleResetWidgetFilters } =
    useContext(WidgetContext)

  const itemAccessor = 'item'
  const itemsWithColor = useMemo(
    () => getItemsWithColors(section, data, itemAccessor),
    [section, data, itemAccessor]
  )

  // Data for the chart
  const formattedData = useMemo(() => {
    return groupAndFormatItemsByUser(itemAccessor, data[`${section}ItemsByUser`], isPercent)
  }, [itemAccessor, data, isPercent])

  // If any items are hidden based on the legend selection
  const dataWithHiddenItems = useMemo(() => {
    return formattedData.map((item) => {
      if (hiddenItems.size === 0) return item

      // Gotta remove the keys from the object
      return Object.fromEntries(Object.entries(item).filter((pair) => !hiddenItems.has(pair[0])))
    })
  }, [formattedData, hiddenItems])

  // Checklist items with predictable colors
  const keys = useMemo(
    () =>
      itemsWithColor
        .map((item) => item[itemAccessor])
        .sort((a, b) => toString(a.order).localeCompare(toString(b.order))),
    [data]
  )

  // Data for table
  const tableData = useMemo(() => {
    return getUserTableData(
      itemAccessor,
      itemsWithColor,
      formattedData,
      section,
      hiddenItems,
      isPercent
    )
  }, [itemAccessor, itemsWithColor, formattedData, section, hiddenItems, isPercent])

  // Find pagination values
  const rowsPerPage = 20
  const count = dataWithHiddenItems.length
  const totalPages = Math.ceil(count / rowsPerPage)
  const paginatedRows = useMemo(
    () =>
      dataWithHiddenItems.slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage).reverse(),
    [dataWithHiddenItems, activePage]
  )

  useEffect(() => {
    handleChangeCsvData({ headers: tableData.columns, data: tableData.rows })
  }, [tableData])

  useEffect(() => {
    setActivePage(1)
    handleResetWidgetFilters()
  }, [data])

  return (
    <WidgetContainer
      large
      loading={loading[`${section}Page`]}
      noData={isEmpty(dataWithHiddenItems) && hiddenItems.size === 0}
      withLegend
      withPagination
      dataForLegend={{ data: itemsWithColor }}
      legendAccessor={itemAccessor}
      table={
        <AdvancedTable
          index="user"
          defaultOrderBy="user"
          rows={tableData.rows}
          columns={tableData.columns}
          truncate
          rowsPerPage={10}
          pagination
          enableUpdateRowsPerPage={false}
        />
      }
      chart={
        <>
          <BarChart
            groupMode={isPercent ? 'grouped' : undefined}
            isPercent={isPercent}
            data={paginatedRows}
            keys={keys}
            indexBy="user"
            layout="horizontal"
            enableGridX
            colors={({ id }) => {
              const color = itemsWithColor.find((item) => item[itemAccessor] === id)?.color
              return color
            }}
            tooltip={(item) => {
              return (
                <WidgetTooltip color={item.color}>
                  <h4>{formatFullName(item.indexValue)}</h4>
                  <p>{item.id}</p>
                  <p>
                    {`${
                      isPercent
                        ? `${formatPercent(item.value)} ${section} usage`
                        : `Used ${pluralize('time', Number(item.value), true)}`
                    }`}
                  </p>
                </WidgetTooltip>
              )
            }}
            margin={{ bottom: 50, left: 150, right: 50, top: 20 }}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              padding: 0.5,
              tickPadding: 5,
              tickRotation: 0,
              format: (v) => {
                return isPercent ? formatPercent(v) : Number(v).toLocaleString()
              },
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: (v) => {
                const [lastName, firstName] = v.split(', ')
                return truncateString(`${firstName} ${lastName}`, 30)
              },
            }}
          />
          <AdvancedTablePagination
            activePage={activePage}
            rowsPerPage={rowsPerPage}
            count={count}
            totalPages={totalPages}
            setActivePage={setActivePage}
          />
        </>
      }
    />
  )
}
