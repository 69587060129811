import React from 'react'
import moment from 'moment'
import './CallCardHeader.css'
import { ExternalLink } from '@/components/ExternalLink'
import { formatTime } from '@/utils/helpers'
import { PillBanner } from './PillBanner'
import { CallCardInfoLabel } from './CallCardInfoLabel'

export const CallCardHeader = ({
  callId,
  voipId,
  callStartTime,
  playbookName,
  callDuration,
  negativeAlerts = 0,
  informativeAlerts = 0,
  positiveAlerts = 0,
}) => {
  return (
    <div className="call-card-header">
      <div className="call-card-header_column">
        <CallCardInfoLabel label="VOIP ID" content={voipId} />
        <CallCardInfoLabel
          label="Call Time"
          content={formatTime(callStartTime)}
          dataTestId="callTime"
        />
        <CallCardInfoLabel label="Playbook" content={playbookName} />
        <CallCardInfoLabel
          label="Call Duration"
          dataTestId="callDuration"
          content={moment.utc(callDuration).format('HH:mm:ss')}
        />
      </div>
      <div className="call-card-header_column">
        <PillBanner color="red" alertCount={negativeAlerts} labelText="Negative" />
        <PillBanner color="blue" alertCount={informativeAlerts} labelText="Informative" />
        <PillBanner color="green" alertCount={positiveAlerts} labelText="Positive" />
      </div>
      <div className="call-card-header_column">
        <ExternalLink url={`../call-explorer/${callId}`} label="Explore Call" id={callId} />
      </div>
    </div>
  )
}
