import React from 'react'

import { Pill } from '@/components/pills/Pill'

export const mergeOrgsandIntegrations = (integrations, organizations) => {
  return integrations.map((integration) => {
    const org = organizations.filter((it) => it.id === integration.organization_id)
    const mergedIntegration = { ...integration }
    mergedIntegration.organization_name = org.length
      ? org[0].name
      : `Organization ${integration.organization_id} missing`
    return mergedIntegration
  })
}
export const diffIntegrations = (obj1, obj2) => {
  const result = {}
  if (Object.is(obj1, obj2)) {
    return undefined
  }
  if (!obj2 || typeof obj2 !== 'object') {
    return obj2
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach((key) => {
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = obj2[key]
      }
      if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
        const value = diffIntegrations(obj1[key], obj2[key])
        if (value !== undefined) {
          result[key] = value
        }
      }
    })
  return result
}
export const getStatusClassName = (status) => {
  if (status === 'running') {
    return { success: true }
  }
  if (status === 'uninitialized' || status === 'not_running') {
    return { emphasized: true }
  }

  return { critical: true }
}

export const formatHealthValue = (value) => {
  return value ? `${value.toString().split('.')[0]}%` : 'No Health Score'
}

export const getAndCheckOrgColumn = (org_id) => {
  if (org_id >= 90000) {
    return <Pill small>Not Assigned</Pill>
  }
  return org_id
}

export const getConnectorDisplayType = (type) => {
  const connectorDict = {
    ringcentral: 'RingCentral Office',
    ringcentral_engage: 'RingCentral Engage',
    genesys: 'Genesys Cloud',
    five9: 'Five9',
    '8x8': '8x8 VCC',
    slack: 'Slack',
    vonage_enterprise: 'Vonage Enterprise (Nexmo)',
    amazon_connect: 'Amazon Connect',
    zoom: 'Zoom',
    zoom_video: 'Zoom Video',
    close: 'Close',
    aircall: 'AirCall',
    tld: 'TLD',
    trackdrive: 'Trackdrive',
    thrio: 'Thrio',
    nice: 'Nice',
    vonage_vbe: 'Vonage VBE',
    api: 'API',
    tcn: 'TCN',
    regal: 'Regal',
    dialpad: 'Dialpad',
    call_tracking_metrics: 'Call Tracking Metrics',
    mightycall: 'Mighty Call',
    goto: 'Goto',
  }
  return connectorDict[type]
}
