import React from 'react'
import './leaderboardForm.css'
import { useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'

export const LeaderboardQADetail = ({ scorecard }) => {
  if (!scorecard) return null

  const { loading } = useSelector((state) => state.scorecards)
  const isLoading = loading.targetScorecardConfig

  if (isLoading) {
    return (
      <div
        className="leaderboard-qa-detail"
        key="leaderboard-qa-detail"
        data-testid="qa-detail-loader"
      >
        <Loader inline="centered" active />
      </div>
    )
  }

  return (
    <div
      className="leaderboard-qa-detail"
      key="leaderboard-qa-detail"
      data-testid="leaderboard-qa-detail"
    >
      <div className="leaderboard-qa-detail--item">
        <span>Linked Playbook</span>
        <span>{scorecard.config_name}</span>
      </div>
      <div className="leaderboard-qa-detail--item">
        <span>Minimum Call Duration</span>
        <span>{scorecard.min_duration_in_seconds / 60} Minutes</span>
      </div>
      <div className="leaderboard-qa-detail--item">
        <span>Exceeds Threshold</span>
        <span>{scorecard.exceeds_threshold}%</span>
      </div>
      <div className="leaderboard-qa-detail--item">
        <span>Meets Expectations Threshold</span>
        <span>{scorecard.meets_threshold}%</span>
      </div>
      <div className="leaderboard-qa-detail--item">
        <span>Below Expectations Threshold</span>
        <span>{scorecard.improvement_threshold}%</span>
      </div>
    </div>
  )
}
