import { PCMPlayer } from '@/utils/PCMPlayer'
import { fetchingAPI, apiService } from '@/api'
import { toast } from 'react-toastify'
import {
  startListeningToCall,
  stopListeningToCall,
} from '@/reducers/commandCenter/commandCenter.redux'

// Would be preferable if this was baked into the ENV variable
const afsURL = process.env.AFS_URL || 'localhost:4010'
const websocketProtocol = afsURL.includes('localhost') ? 'ws' : 'wss'

let mePlayer = null
let themPlayer = null

const defaultPlayerSettings = {
  encoding: '16bitInt',
  channels: 1,
  sampleRate: 48000, // 44100 is default
  flushingTime: 1000,
}

// Primary socket is the single socket necessary for AFS-based listening.
// The Secondary socket is for the two-channel approach when connecting through processing.
export const listeningSocketMiddleware =
  (primarySocket) =>
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const { type, ...actionData } = action
    const { username: managerUsername, token } = getState().currentUser

    const mePlayerSettings = { ...defaultPlayerSettings }
    const themPlayerSettings = { ...defaultPlayerSettings }

    const connectSocketAFS = ({ alertId, agentUsername }) => {
      primarySocket.connect({
        token,
        username: agentUsername,
        binaryType: 'arraybuffer',
        url: `${websocketProtocol}://${afsURL}/listen?listener_username=${managerUsername}`,
      })

      primarySocket.on('open', () => {
        console.info('Listening socket open!')
        if (alertId) {
          fetchingAPI(`${apiService.rtc}/alert/${alertId}`, 'PATCH', dispatch).catch((error) =>
            console.error(error)
          )
        }
      })

      primarySocket.on('message', (event) => {
        // Handle a text message
        if (typeof event.data === 'string') {
          const eventData = JSON.parse(event.data)

          themPlayerSettings.sampleRate = eventData?.them_sample_rate
          mePlayerSettings.sampleRate = eventData?.me_sample_rate

          // if the starting sample rate is different than the one being used, update it
          if (themPlayer?.option.sampleRate !== themPlayerSettings.sampleRate) {
            themPlayer.option.sampleRate = eventData?.them_sample_rate
          }
          if (mePlayer?.option.sampleRate !== mePlayerSettings.sampleRate) {
            mePlayer.option.sampleRate = eventData?.me_sample_rate
          }

          return
        }

        // we can't look at the last byte of an ArrayBuffer without creating a `view`. This view
        // parses our bytes into an accessible array so that we can see the last item.
        const int8View = new Int8Array(event.data)

        // Split audio messages based on the byte signature to the me-side or them-side player
        if (int8View[int8View.length - 1] === 1) {
          themPlayer.feed(new Int16Array(event.data.slice(0, -1)))
        } else {
          mePlayer.feed(new Int16Array(event.data.slice(0, -1)))
        }
      })

      primarySocket.on('close', () => {
        console.info('Listening socket closed!')
      })
    }

    const stopListening = () => {
      if (primarySocket) {
        primarySocket.disconnect()
      }

      if (mePlayer) {
        mePlayer?.destroy()
        mePlayer = null
      }

      if (themPlayer) {
        themPlayer?.destroy()
        themPlayer = null
      }
    }

    const startListening = (actionData, type) => {
      const isCommandCenter = type.startsWith('commandCenter')
      mePlayer = new PCMPlayer(mePlayerSettings)
      themPlayer = new PCMPlayer(themPlayerSettings)

      try {
        connectSocketAFS(actionData)
        if (isCommandCenter) {
          dispatch(startListeningToCall(actionData))
        } else {
          dispatch({
            type: 'realtime/startListeningToCall',
            ...actionData,
          })
        }
      } catch (err) {
        if (isCommandCenter) {
          dispatch(stopListeningToCall(actionData))
        } else {
          dispatch({ type: 'realtime/stopListeningToCall' })
        }
        toast.error('Failed to listen to call')
      }
    }

    switch (type) {
      case 'realtime/attemptListeningToCall':
      case 'commandCenter/attemptListening':
        startListening(actionData, type)
        break

      case 'realtime/stopListeningToCall':
      case 'commandCenter/stopListening':
        stopListening()
        break

      default:
        break
    }

    return next(action)
  }

export default listeningSocketMiddleware
