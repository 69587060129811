import React from 'react'
import { ResponsiveBullet } from '@nivo/bullet'
import { thresholdColors } from '@/utils/constants'

const BulletChart = ({ data }) => (
  <ResponsiveBullet
    data={data}
    margin={{
      top: 50,
      right: 50,
      bottom: 50,
      left: 120,
    }}
    spacing={46}
    titleAlign="start"
    titleOffsetX={-100}
    measureSize={0.2}
    rangeColors={thresholdColors}
    measureColors={['#4D63FE']}
    minValue={0}
    measureBorderWidth={1}
    markerSize={0.3}
    animate
  />
)

export default BulletChart
