import React from 'react'
import { Button } from 'semantic-ui-react'

export const DeleteUserModal = ({ deleteUser, user }) => {
  const { first_name, last_name } = user

  const handleDeleteUser = () => {
    const deletedUser = { ...user }

    deletedUser.deleted = true
    deleteUser(deletedUser)
  }

  return (
    <>
      <div className="modal-message" data-testid="delete-modal">
        Are you sure you want to change{' '}
        <strong>
          {first_name} {last_name}
        </strong>{' '}
        status to be <span className="status-critical">inactive</span>?
      </div>

      <Button onClick={handleDeleteUser} color="red" data-testid="delete-user-button">
        Deactivate User
      </Button>
    </>
  )
}

export default DeleteUserModal
