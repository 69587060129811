// Action Types
export const SET_LOADING = 'leaderboards/setLoading'
export const SET_DATA = 'leaderboards/setData'
export const SET_FILTER = 'leaderboards/setFilter'
export const SET_LEADERBOARD_ORGANIZATION = 'leaderboards/setLeaderboardOrganization'

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setData = (payload) => ({ type: SET_DATA, payload })
export const setFilter = (payload) => ({ type: SET_FILTER, payload })
export const setLeaderboardOrganization = (payload) => ({
  type: SET_LEADERBOARD_ORGANIZATION,
  payload,
})

// State
export const initialState = {
  data: {
    leaderboards: [],
    leaderboard: null,
  },
  filters: {
    organizationId: '',
    leaderboardId: '',
  },
  loading: {
    organizations: false,
    leaderboards: false,
    leaderboard: false,
    csv: false,
  },
}

// Reducer
export default function leaderboardsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return { ...state, data: { ...state.data, ...action.payload } }
    case SET_FILTER:
      return { ...state, filters: { ...state.filters, ...action.payload } }
    case SET_LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    case SET_LEADERBOARD_ORGANIZATION:
      return { ...state, filters: { organizationId: action.payload, leaderboardId: '' } }
    default:
      return state
  }
}
