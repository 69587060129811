import React, { useEffect } from 'react'
import { Field } from 'formik'
import { Header } from 'semantic-ui-react'
import { TextField, Checkbox, RadioGroup } from '@/components/forms/formik'

export const AlertSettingsForm = ({
  errors,
  setFieldValue,
  readOnly = false,
  isEditPage = false,
}) => {
  useEffect(() => {
    if (!isEditPage) {
      setFieldValue('displayedForm', 'alertSettingsForm')
    }
  }, [])

  return (
    <div
      className={isEditPage ? 'alert-settings__page' : 'alert-wizard__page'}
      data-testid="alert-wizard__settings-page"
    >
      {!isEditPage && <Header className="alert-wizard__field">Configure Settings</Header>}
      <Field
        className="alert-wizard__alert-name-field"
        required
        disabled={readOnly}
        errors={errors}
        name="name"
        label={<label className="alert-wizard__field-label-text">Alert Name</label>}
        data-testid="name-field"
        component={TextField}
        placeholder="Name this alert..."
      />
      <div data-testid="alertType" className="alert-wizard__field">
        <Field
          required
          label={<label className="alert-wizard__radio-field-label-text">Alert Type</label>}
          name="alertType"
          component={RadioGroup}
          options={[
            { value: 'positive', text: 'Positive' },
            { value: 'negative', text: 'Negative' },
            { value: 'informative', text: 'Informative' },
          ]}
          disabled={readOnly}
        />
      </div>
      <Field
        required
        name="audible"
        label={
          <div>
            <div className="alert-wizard__field-label-text">Audible notifications</div>
            <div className="alert-wizard__field-subtext">Enable Audible Notifications</div>
          </div>
        }
        data-testid="audible-field"
        inline
        toggle
        reverse
        component={Checkbox}
        disabled={readOnly}
      />
      <Field
        data-testid="status-field"
        name="active"
        label={
          <div>
            <div className="alert-wizard__field-label-text">Alert Status</div>
            <div className="alert-wizard__field-subtext">Activate This Alert</div>
          </div>
        }
        inline
        toggle
        reverse
        component={Checkbox}
        disabled={readOnly}
      />
    </div>
  )
}
