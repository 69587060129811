import React from 'react'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Popup } from 'semantic-ui-react'

import './ProgressBar.scss'

export const ProgressBar = ({ currentPage, totalPages, links }) => {
  // Link to each item in the progress bar
  if (links) {
    return (
      <div className="progress-bar">
        {links.map((link, index) => (
          <div
            className="progress-bar-container"
            data-testid="progress-circle-container"
            key={uuid()}
          >
            <Popup
              inverted
              position="bottom center"
              content={link.label}
              trigger={
                <Link
                  to={link.slug}
                  className={classNames('progress-circle', { complete: index === currentPage - 1 })}
                  data-testid="progress-circle"
                />
              }
            />
            {!(index === totalPages - 1) && <div className="progress-line" />}
          </div>
        ))}
      </div>
    )
  }

  // Navigate through steps in a wizard
  return (
    <div className="progress-bar">
      {[...Array(totalPages)].map((_, index) => {
        return (
          <div
            className="progress-bar-container"
            data-testid="progress-circle-container"
            key={uuid()}
          >
            <div
              className={classNames('progress-circle', {
                active: index + 1 === currentPage,
                complete: index + 1 < currentPage,
              })}
              data-testid="progress-circle"
            />
            {!(index === totalPages - 1) && <div className="progress-line" />}
          </div>
        )
      })}
    </div>
  )
}
