import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'

import { checklistValidationSchema, checklistInitialValues } from './checklist.schema'
import { ChecklistForm } from './ChecklistForm'
import { ChecklistEntryPreview } from './ChecklistEntryPreview'
import { PlaybookPreview } from '../../components/PlaybookPreview'
import { PlaybookEditor } from '../../components/PlaybookEditor'
import { accessorSectionMap } from '../../playbook.helpers'

export const ChecklistEditor = () => {
  const { entryId, section } = useParams()
  const { playbook } = useSelector((state) => state.playbook)
  const accessor = accessorSectionMap[section]
  const isCreate = entryId === 'create'
  const entry = playbook.body[accessor].entries[entryId]
  const initialValues = isCreate ? checklistInitialValues : entry

  return (
    <PlaybookEditor entry={entry}>
      {({ handleUpdate, handleCancel, readOnly }) => (
        <Formik
          initialValues={initialValues}
          validationSchema={checklistValidationSchema}
          onSubmit={handleUpdate}
          enableReinitialize
        >
          {({ values, handleSubmit, errors, ...formikProps }) => (
            <div className="checklist-form-container">
              <ChecklistForm
                isCreate={isCreate}
                readOnly={readOnly}
                errors={errors}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                entryId={entryId}
                values={values}
                {...formikProps}
              />
              <PlaybookPreview values={values} section={section} title="Checklist">
                <ul>
                  {playbook.body[accessor].order.map((existingEntryId) => {
                    const checklistEntry = playbook.body[accessor].entries[existingEntryId]
                    const isEditEntry = entryId === existingEntryId
                    const entryToUse = isEditEntry ? values : checklistEntry

                    return (
                      <ChecklistEntryPreview
                        key={existingEntryId}
                        isEditEntry={isEditEntry}
                        entry={entryToUse}
                      />
                    )
                  })}
                  {entryId === 'create' && <ChecklistEntryPreview isEditEntry entry={values} />}
                </ul>
              </PlaybookPreview>
            </div>
          )}
        </Formik>
      )}
    </PlaybookEditor>
  )
}
