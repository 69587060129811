import { toast } from 'react-toastify'
import { fetchingAPI, apiService } from '../../api'
import { UNCATEGORIZED_TAGS } from '../../utils/constants'
import {
  setTags,
  setCategories,
  addCategory,
  emptyTagCategories,
  updateOrderOfTags,
} from './organizationTagManager.redux'
import { setUsers } from './organizationUsers.redux'
import { setRouteError } from '../errors/routeErrors.redux'

export const fetchCategories =
  ({ organizationId, toggleLoading }) =>
  async (dispatch) => {
    try {
      const tagCategories = await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags/categories`,
        'GET',
        dispatch
      )

      dispatch(setCategories(tagCategories))
    } catch (err) {
      dispatch(setRouteError('Error getting categories.'))
    } finally {
      if (toggleLoading) toggleLoading()
    }
  }

export const fetchTags =
  ({ organizationId, toggleLoading }) =>
  async (dispatch) => {
    try {
      const tags = await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags`,
        'GET',
        dispatch
      )
      tags.sort((a, b) => a.name.localeCompare(b.name))

      dispatch(setTags(tags))
    } catch (err) {
      dispatch(setRouteError('Error getting tags.'))
    } finally {
      if (toggleLoading) toggleLoading()
    }
  }

export const createTags =
  ({ tags, category, organizationId, toggleLoading, reloadTags }) =>
  async (dispatch) => {
    try {
      const body = JSON.stringify({
        tagNames: tags.tagNames,
        tag_category_id: category === '' ? null : category,
      })

      await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags`,
        'POST',
        dispatch,
        body
      )

      reloadTags({ organizationId, toggleLoading })
      toast.success('Tag successfully created')
    } catch (err) {
      dispatch(
        setRouteError('Adding tag failed. Tags cannot exceed 40 characters or be duplicates.')
      )
    } finally {
      if (toggleLoading) toggleLoading()
    }
  }

export const createCategory =
  ({ tagCategories, organizationId, toggleLoading, reloadTags, allCategories }) =>
  async (dispatch) => {
    try {
      const body = JSON.stringify({
        name: tagCategories,
        organization_id: organizationId,
        sort_id: allCategories.columnOrder.length - 1,
      })
      const category = await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags/categories`,
        'POST',
        dispatch,
        body
      )

      dispatch(addCategory(category))
      await reloadTags({ organizationId })
      toast.success('Category successfully created')
    } catch (err) {
      dispatch(setRouteError(`${err.error}`))
    } finally {
      if (toggleLoading) toggleLoading()
    }
  }

export const updateTagCategories = (tagId, destinationOrg) => async (dispatch) => {
  const orgId =
    destinationOrg.title.toLowerCase().split(' ').join('') ===
    UNCATEGORIZED_TAGS.toLowerCase().split(' ').join('')
      ? null
      : destinationOrg.categoryId

  const body = JSON.stringify({ tag_category_id: orgId })
  try {
    await fetchingAPI(`${apiService.web}/api/tags/${tagId}`, 'PATCH', dispatch, body)
  } catch {
    dispatch(setRouteError(`Updating tag failed.`))
  }
}

export const deleteCategory =
  ({ category, toggleLoading, organizationId }) =>
  async (dispatch) => {
    try {
      await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags/categories/${category.categoryId}`,
        'DELETE',
        dispatch
      )
      dispatch(emptyTagCategories())
      toast.success('Category successfully deleted')
    } catch {
      dispatch(setRouteError('Error deleting category.'))
    } finally {
      if (toggleLoading) toggleLoading()
    }
  }

export const updateCategory =
  ({ category, updates, reloadTags, toggleLoading, organizationId }) =>
  (dispatch) => {
    const body = JSON.stringify(updates)
    return fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/tags/categories/${category.categoryId}`,
      'PATCH',
      dispatch,
      body
    )
      .then((cat) => {
        dispatch(emptyTagCategories())
        if (reloadTags) reloadTags({ organizationId, toggleLoading })
        return cat
      })
      .catch(() => {
        if (toggleLoading) toggleLoading()
        dispatch(setRouteError('Error updating category.'))
      })
  }

export const deleteTag =
  ({ tag, reloadTags, toggleLoading, organizationId }) =>
  async (dispatch) => {
    try {
      await fetchingAPI(`${apiService.web}/api/tags/${tag.tagId}`, 'DELETE', dispatch)
      dispatch(emptyTagCategories())
      reloadTags({ organizationId, toggleLoading })
      toast.success('Tag successfully deleted')
    } catch {
      dispatch(setRouteError('Error deleting tag.'))
    } finally {
      if (toggleLoading) toggleLoading()
    }
  }

export const deleteAllTags =
  ({ organizationId, toggleLoading, reloadTags }) =>
  async (dispatch) => {
    try {
      await fetchingAPI(`${apiService.web}/api/organizations/${organizationId}/tags`, 'DELETE')
      dispatch(emptyTagCategories())
      reloadTags({ organizationId, toggleLoading })
    } catch {
      dispatch(setRouteError('Deleting all tags failed.'))
    } finally {
      if (toggleLoading) toggleLoading()
    }
  }

export const updateTagsInState = (tagStore) => (dispatch) => {
  return dispatch(updateOrderOfTags(tagStore))
}

export const updateUsers = (users) => (dispatch) => {
  return dispatch(setUsers(users))
}

export const duplicateTagsError = (element) => (dispatch) => {
  dispatch(setRouteError(`Duplicate ${element}. Please edit your ${element} query.`))
}

export const emptyCategories = () => (dispatch) => {
  return dispatch(emptyTagCategories())
}

export const displayError = (err) => (dispatch) => {
  return dispatch(setRouteError(`${err}`))
}
