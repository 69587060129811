import produce from 'immer'

// Action Types
export const UPDATE_SOCKET_STATUS = 'realtime/updateSocketStatus'
export const OPEN_RTC_SOCKET = 'OPEN_RTM_SOCKET'
export const CLOSE_RTC_SOCKET = 'CLOSE_RTM_SOCKET'
export const REFRESH_USERS_ALERTS = 'REFRESH_USERS_ALERTS'
export const SEND_CHAT_TO_DESKTOP = 'SEND_CHAT_TO_DESKTOP'

// Initial State
export const initialState = { isOpen: false, maxRetriesExceeded: false }

// Reducer
export default function realtimeSocketReducer(state = initialState, action) {
  return produce(state, (newState) => {
    switch (action.type) {
      case UPDATE_SOCKET_STATUS:
        newState.isOpen = action.isOpen
        newState.maxRetriesExceeded = action.maxRetriesExceeded
        break

      default:
        break
    }
  })
}
