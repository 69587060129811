import uuid from 'uuid/v4'
import { cloneDeep } from 'lodash'

import { orderedEntriesAccessorMap } from '@/views/Playbooks/playbook.helpers'

export const add = (state, payload, accessor) => {
  const newId = uuid()
  const isNestedEntry = !!payload.categoryId
  const isOrdered = orderedEntriesAccessorMap[accessor]

  state.dirty = true

  // If a categoryId is present, it is a nested entry, and order is guaranteed
  if (isNestedEntry) {
    state.playbook.body[accessor].entries[payload.categoryId].entries[newId] = payload.entry
    state.playbook.body[accessor].entries[payload.categoryId].order.push(newId)
  } else {
    state.playbook.body[accessor].entries[newId] = payload.entry

    if (isOrdered) {
      state.playbook.body[accessor].order.push(newId)
    }
  }
}

export const edit = (state, payload, accessor) => {
  const isNestedEntry = !!payload.categoryId

  state.dirty = true

  if (isNestedEntry) {
    state.playbook.body[accessor].entries[payload.categoryId].entries[payload.entryId] =
      payload.entry
  } else {
    state.playbook.body[accessor].entries[payload.entryId] = payload.entry
  }
}

export const remove = (state, prevState, payload, accessor) => {
  const isNestedEntry = !!payload.categoryId
  const isOrdered = orderedEntriesAccessorMap[accessor]

  state.dirty = true

  if (isNestedEntry) {
    delete state.playbook.body[accessor].entries[payload.categoryId].entries[payload.entryId]
    state.playbook.body[accessor].entries[payload.categoryId].order = prevState.playbook.body[
      accessor
    ].entries[payload.categoryId].order.filter((entryId) => entryId !== payload.entryId)
  } else {
    delete state.playbook.body[accessor].entries[payload]

    if (isOrdered) {
      state.playbook.body[accessor].order = prevState.playbook.body[accessor].order.filter(
        (entryId) => entryId !== payload
      )
    }
  }
}

export const lock = (state, prevState, payload, accessor) => {
  const isNestedEntry = !!payload.categoryId

  state.dirty = true

  if (isNestedEntry) {
    state.playbook.body[accessor].entries[payload.categoryId].entries[payload.entryId].is_locked =
      !prevState.playbook.body[accessor].entries[payload.categoryId].entries[payload.entryId]
        .is_locked
  } else {
    state.playbook.body[accessor].entries[payload].is_locked =
      !prevState.playbook.body[accessor].entries[payload].is_locked
  }
}

const handleChecklistWin = (state, prevState, payload, accessor) => {
  if (!prevState.playbook.body[accessor].entries[payload].win === true) {
    state.playbook.body[accessor].entries[payload].win = true
  } else {
    delete state.playbook.body[accessor].entries[payload].win
  }
}

const handleNestedWin = (state, prevState, payload, accessor) => {
  const { categoryId, entryId } = payload
  if (!prevState.playbook.body[accessor].entries[categoryId].entries[entryId].win === true) {
    state.playbook.body[accessor].entries[categoryId].entries[entryId].win = true
  } else {
    delete state.playbook.body[accessor].entries[categoryId].entries[entryId].win
  }
}

export const win = (state, prevState, payload, accessor) => {
  state.dirty = true

  if (typeof payload === 'string') {
    handleChecklistWin(state, prevState, payload, accessor)
  } else {
    handleNestedWin(state, prevState, payload, accessor)
  }
}

export const reorder = (state, payload, accessor) => {
  const isNestedEntry = !!payload.categoryId

  state.dirty = true

  if (isNestedEntry) {
    state.playbook.body[accessor].entries[payload.categoryId].order = payload.order
  } else {
    state.playbook.body[accessor].order = payload
  }
}

export const reorderAndMove = (state, payload, accessor) => {
  state.dirty = true
  state.playbook.body[accessor].entries[payload.source.id].entries = payload.source.entries
  state.playbook.body[accessor].entries[payload.source.id].order = payload.source.order
  state.playbook.body[accessor].entries[payload.destination.id].entries =
    payload.destination.entries
  state.playbook.body[accessor].entries[payload.destination.id].order = payload.destination.order
}

export const sanitizeBody = (body) => {
  const sanitizedBody = cloneDeep(body)

  Object.keys(body).forEach((section) => {
    if (Object.keys(body[section].entries).length < 1) delete sanitizedBody[section]
  })

  return sanitizedBody
}

export const addChecklistVariations = (state, payload) => {
  const checklistVariations = {}
  const agentChecklists = payload.map((agentChecklist) => agentChecklist.items)
  const checklistItems = agentChecklists.flatMap((agentChecklists) => agentChecklists)
  const filteredItems = checklistItems.filter(
    (checklistItem) => checklistItem.item_type === 'variation'
  )
  filteredItems.forEach((item) => {
    checklistVariations[item.checklist_properties_uuid] =
      (checklistVariations[item.checklist_properties_uuid] || 0) + 1
  })

  state.checklistVariations = checklistVariations
}

export const addChecklistItemVariations = (state, payload, checklistItemId) => {
  const checklistItemVariations = []

  payload.forEach((agentChecklist) => {
    const agentName = `${agentChecklist.user.first_name} ${agentChecklist.user.last_name}`
    const checklistItems = agentChecklist.items
    const checklistItem = checklistItems.find(
      (checklistItem) => checklistItem.checklist_properties_uuid === checklistItemId
    )

    const variation = {
      user: agentName,
      user_id: agentChecklist.user.user_id,
      text: checklistItem.text,
      created_at: checklistItem.created_at,
      checklist_properties_uuid: checklistItem.checklist_properties_uuid,
    }

    checklistItemVariations.push(variation)
  })

  state.checklistItemVariations[checklistItemId] = checklistItemVariations
}
