import React, { Fragment, useEffect, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import WidgetContext from '../../../components/widget/widget.context'
import WidgetContainer from '../../../components/widget/WidgetContainer'
import { formatPercent, getPercentNumerical } from '../../../utils/helpers'
import { getDynamicPromptAnalysisWinRateCsvData } from './widgetHelpers'

export const DynamicPromptWinRateAnalysisWidget = () => {
  const { data, loading } = useSelector((state) => state.analytics)
  const { handleChangeCsvData } = useContext(WidgetContext)

  const csvData = useMemo(() => {
    return getDynamicPromptAnalysisWinRateCsvData(data)
  }, [data])

  const columns = [
    {
      accessor: 'dp_category',
      label: 'Dynamic Prompt Category',
    },
    {
      accessor: 'dp',
      label: 'Dynamic Prompt',
    },
    {
      accessor: 'dp_response',
      label: 'Dynamic Prompt Response',
    },
    {
      accessor: 'count',
      label: 'Count',
    },
    {
      accessor: 'win_rate',
      label: 'Win Rate',
    },
  ]

  useEffect(() => {
    handleChangeCsvData({ headers: columns, data: csvData })
  }, [csvData])

  const generateTable = () => {
    return (
      <div className="nested-table">
        <div className="nested-table__grid nested-table__item">
          <div />
          <div className="text-right">Count</div>
          <div className="text-right">Win Rate</div>
        </div>
        {data.deckWinRate.map((deckCategory) => {
          return (
            <Fragment key={deckCategory.category}>
              <div className="nested-table__grid nested-table__category">
                <div>{deckCategory.category}</div>
              </div>

              {deckCategory.items.map((deckItem) => {
                return (
                  <Fragment key={deckItem.item}>
                    <div className="nested-table__grid nested-table__item">
                      <div>{deckItem.item}</div>
                      <div className="text-right">{Number(deckItem.count).toLocaleString()}</div>
                      <div className="text-right">
                        {formatPercent(getPercentNumerical(deckItem.win_count, deckItem.count))}
                      </div>
                    </div>

                    {deckItem.entries.map((decklistItem) => {
                      return (
                        <div
                          className="nested-table__grid nested-table__inner-item"
                          key={decklistItem.item}
                        >
                          <div>{decklistItem.item}</div>
                          <div className="text-right">
                            {Number(decklistItem.count).toLocaleString()}
                          </div>
                          <div className="text-right">
                            {formatPercent(
                              getPercentNumerical(decklistItem.win_count, decklistItem.count)
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </Fragment>
                )
              })}
            </Fragment>
          )
        })}
      </div>
    )
  }

  return (
    <WidgetContainer
      large
      loading={loading.deckPageWins}
      noData={isEmpty(data.deckWinRate)}
      isTableView
      table={generateTable()}
      chart={<div />}
    />
  )
}
