export const SET_LOADING = 'orgHierarchy/setLoading'
export const SET_ORG_HIERARCHY = 'orgHierarchy/setOrgHierarchy'
export const SET_ORG_DROPDOWN = 'orgHierarchy/setOrgDropdown'
export const SET_USER_ORG_HIERARCHY = 'orgHierarchy/setUserOrgHierarchy'
export const SET_FLATTENED_USER_ORG_HIERARCHY = 'orgHierarchy/setFlattenedUserOrgHierarchy'

export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setOrgHierarchy = (payload) => ({ type: SET_ORG_HIERARCHY, payload })
export const setOrgDropdown = (payload) => ({ type: SET_ORG_DROPDOWN, payload })
export const setUserOrgHierarchy = (payload) => ({ type: SET_USER_ORG_HIERARCHY, payload })
export const setFlattenedUserOrgHierarchy = (payload) => ({
  type: SET_FLATTENED_USER_ORG_HIERARCHY,
  payload,
})

export const initialState = {
  // this is the hierarchy shown on the organizations/<id>/hierarchy page
  orgHierarchy: {},
  orgDropdown: { parentOrgId: null, selectedChildOrgId: '' },
  // this is the hierarchy from which the user can select which org to impersonate
  userOrgHierarchy: {},
  // this is a flattened version of userOrgHierarchy, this way we don't have to flatten
  // anytime we want to populate a dropdown with a list of all child/grandchild orgs
  flattenedUserOrgHierarchy: [],
  loading: false,
}

export default function orgHierarchyReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload }
    case SET_ORG_HIERARCHY:
      return { ...state, orgHierarchy: action.payload }
    case SET_ORG_DROPDOWN:
      return { ...state, orgDropdown: { ...state.orgDropdown, ...action.payload } }
    case SET_USER_ORG_HIERARCHY:
      return { ...state, userOrgHierarchy: action.payload }
    case SET_FLATTENED_USER_ORG_HIERARCHY:
      return { ...state, flattenedUserOrgHierarchy: action.payload }
    default:
      return state
  }
}
