import React, { createContext, useState } from 'react'

const WidgetContext = createContext()

export const WIDGET_MINIMIZATION_SETTINGS = 'balto_minimized_widgets'
export const getSavedMinimizationSettings = (widgetId) => {
  const minimizationSettings = JSON.parse(localStorage.getItem(WIDGET_MINIMIZATION_SETTINGS)) || {}

  return minimizationSettings[widgetId] || false
}
export const setSavedMinimizationSettings = (widgetId, value) => {
  const minimizationSettings = JSON.parse(localStorage.getItem(WIDGET_MINIMIZATION_SETTINGS)) || {}

  minimizationSettings[widgetId] = value
  localStorage.setItem(WIDGET_MINIMIZATION_SETTINGS, JSON.stringify(minimizationSettings))
}

export const WidgetProvider = ({
  widgetId,
  tableOnly = false,
  percentViewDefault = true,
  children,
}) => {
  // Whether the widget content and controls are hidden from view
  const [isMinimized, setMinimized] = useState(getSavedMinimizationSettings(widgetId))
  // 'hidden', 'partial', or 'all' for select all button
  const [selectAllStatus, setSelectAllStatus] = useState('all')
  // Whether top 5 is selected
  const [topFiveStatus, setTopFiveStatus] = useState(false)
  // Whether bottom 5 is selected
  const [bottomFiveStatus, setBottomFiveStatus] = useState(false)
  // Show table view in the widget
  const [isTableView, setShowTableView] = useState(tableOnly)
  // Show percent view instead of numerical counts
  const [isPercent, setShowPercent] = useState(percentViewDefault)
  // Deselected items from the legend
  const [hiddenItems, setHiddenItems] = useState(new Set())
  // Whether data is grouped by day, week, or month
  const [groupByValue, setGroupByValue] = useState('day')
  // Show detailed item data in heatmap
  const [itemDetail, setItemDetail] = useState({})
  // For CSV download
  const [csvData, setCsvData] = useState({})
  // Whether or not to you should filter weekends from the data
  const [ignoreWeekends, setIgnoreWeekends] = useState(false)

  const handleChangeIgnoreWeekends = () => {
    setIgnoreWeekends((currentIgnoreWeekends) => !currentIgnoreWeekends)
  }

  const handleMinimizationToggle = () => {
    const newMinimized = !isMinimized

    setSavedMinimizationSettings(widgetId, newMinimized)
    setMinimized(newMinimized)
  }

  const handlePercentToggle = () => {
    setShowPercent((currentShowPercent) => !currentShowPercent)
  }

  const handleChangeGroupBy = (option) => {
    setGroupByValue(option.value)
  }

  const handleChangeView = () => {
    setShowTableView((currentView) => !currentView)
  }

  const handleChangeItemDetail = (detail = {}) => {
    setItemDetail(detail)
  }

  const handleChangeCsvData = (data = {}) => {
    setCsvData(data)
  }

  const handleChangeHiddenItems = (item) => {
    if (hiddenItems.has(item)) {
      setHiddenItems((prevSet) => {
        const updatedSet = new Set(prevSet)
        updatedSet.delete(item)

        return updatedSet
      })
    } else {
      setHiddenItems((prevSet) => new Set(prevSet).add(item))
    }
  }

  const handleToggleAllHiddenItems = (items) => {
    if (hiddenItems.size === 0) {
      setHiddenItems(new Set(items))
    } else {
      setHiddenItems(new Set())
    }
  }

  const handleResetWidgetFilters = () => {
    setHiddenItems(new Set())
    setItemDetail({})
  }

  // Forces an array of items to hide, regardless of their previous states
  const forceHideItems = (items) => {
    const updatedSet = new Set(hiddenItems)
    items.forEach((item) => {
      if (!hiddenItems.has(item)) {
        updatedSet.add(item)
      }
    })
    setHiddenItems(updatedSet)
  }

  // Forces an array of items to show, regardless of their previous states
  const forceShowItems = (items) => {
    const updatedSet = new Set(hiddenItems)
    items.forEach((item) => {
      if (hiddenItems.has(item)) {
        updatedSet.delete(item)
      }
    })
    setHiddenItems(updatedSet)
  }

  const handleSelectAllToggle = (items) => {
    if (selectAllStatus === 'all') {
      setSelectAllStatus('hidden')
    } else {
      setSelectAllStatus('all')
    }
    handleToggleAllHiddenItems(items)
  }

  const handleSelectTopFive = (items) => {
    if (topFiveStatus) {
      forceHideItems(items)
    } else {
      forceShowItems(items)
    }
  }
  const handleSelectBottomFive = (items) => {
    if (bottomFiveStatus) {
      forceHideItems(items)
    } else {
      forceShowItems(items)
    }
  }

  const updateLegendState = (items) => {
    // No items are being hidden
    if (!hiddenItems.size) {
      setSelectAllStatus('all')
      setTopFiveStatus(true)
      setBottomFiveStatus(true)
    }
    // All items are being hidden
    else if (hiddenItems.size === items.length) {
      setSelectAllStatus('hidden')
      setTopFiveStatus(false)
      setBottomFiveStatus(false)
    }
    // Some items are being hidden
    else {
      setSelectAllStatus('partial')
      // Checking if any of top 5 or bottom 5 are hidden
      const topFive = items.slice(0, 5)
      const bottomFive = items.slice(-5)
      const isTopFive = !topFive.some((item) => hiddenItems.has(item))
      const isBottomFive = !bottomFive.some((item) => hiddenItems.has(item))
      setTopFiveStatus(isTopFive)
      setBottomFiveStatus(isBottomFive)
    }
  }

  const onPageLoad = (items, tableFilter) => {
    if (tableFilter === 'bottomFive') {
      setHiddenItems(new Set(items.slice(0, -5)))
    } else if (tableFilter === 'topFive') {
      setHiddenItems(new Set(items.slice(5)))
    }
  }

  const value = {
    isMinimized,
    selectAllStatus,
    topFiveStatus,
    bottomFiveStatus,
    isTableView,
    isPercent,
    ignoreWeekends,
    groupByValue,
    hiddenItems,
    itemDetail,
    csvData,
    handleMinimizationToggle,
    handleChangeIgnoreWeekends,
    handlePercentToggle,
    handleChangeGroupBy,
    handleChangeView,
    handleChangeHiddenItems,
    forceHideItems, // exposed for unit testing
    forceShowItems, // exposed for unit testing
    handleToggleAllHiddenItems,
    handleChangeItemDetail,
    handleChangeCsvData,
    handleResetWidgetFilters,
    handleSelectAllToggle,
    setTopFiveStatus, // exposed for unit testing
    handleSelectTopFive,
    setBottomFiveStatus, // exposed for unit testing
    handleSelectBottomFive,
    updateLegendState,
    onPageLoad,
  }

  return <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>
}

export default WidgetContext
