export const alertFormInitialValues = {
  displayedForm: 'alertSettingsForm',
  name: '',
  active: true,
  alertType: '',
  selectedAgents: [],
  selectedTags: [],
  triggerItems: [],
  triggerType: 'occurrence',
  audible: false,
  // We will remove this when we add all agents as an option
  alertOn: 'custom',
}

export const alertFormOnValidate = (values, currentPage) => {
  const errors = {}
  if (currentPage === 0) {
    if (!values.name) {
      errors.name = 'Name is required.'
    }
  }
  return errors
}
