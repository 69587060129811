import React from 'react'
import { useSelector } from 'react-redux'

import { Banner } from '@/components/banners/Banner'

export const AuthMessage = () => {
  const { authMessage } = useSelector((state) => state.ui)

  if (!authMessage?.text) return null

  return (
    <Banner
      data-testid={authMessage.type}
      success={authMessage.type === 'success'}
      critical={authMessage.type === 'error'}
      content={authMessage.text}
    />
  )
}
