import React from 'react'
import { Segment } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { truncate } from 'lodash'

import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { formatPercent } from '@/utils/helpers'

import { TopInfractorsRow } from './TopInfractorsRow'
import { ExternalCallIdsLink } from '../components/ExternalCallIdsLink'

export const TopInfractorsTable = () => {
  const {
    loading,
    data: { topInfractors },
  } = useSelector((state) => state.qaCopilot)

  const columns = [
    {
      accessor: 'criteria_name',
      label: 'Criteria',
      format: (value) => truncate(value, { length: 25 }),
    },
    {
      accessor: 'scorecard_config_name',
      label: 'Scorecard',
      format: (value) => truncate(value, { length: 25 }),
    },
    {
      accessor: 'call_count',
      label: 'Calls',
      headerAlignment: 'right',
      format: (value) => Number(value).toLocaleString(),
    },
    {
      accessor: 'failing_percent',
      label: 'Fail Rate',
      format: formatPercent,
      headerAlignment: 'right',
    },
    { accessor: 'top_infractors', label: 'Top Non-Compliant Agents', isSortable: false },
  ]

  const rows = topInfractors.map((data, index) => {
    return {
      ...data,
      call_count: {
        as: (
          <div className="text-right">
            <ExternalCallIdsLink
              callIds={data.call_ids}
              label={Number(data.call_count).toLocaleString()}
            />
          </div>
        ),
        value: data.call_count,
      },
      top_infractors: {
        as: <TopInfractorsRow data={data} index={index} />,
        value: 'top_infractors',
      },
    }
  })

  return (
    <div>
      <header className="secondary-header">
        <h2>Non-Compliant Agents per Criteria</h2>
      </header>

      <Segment className="not-padded">
        <AdvancedTable
          defaultOrderBy="failing_percent"
          defaultOrder="desc"
          testid="top-infractors-table"
          index="uuid"
          loading={loading.topInfractors}
          columns={columns}
          rows={rows}
          striped={false}
          pagination
          rowsPerPage={5}
          wrapColumnContent={false}
          enableUpdateRowsPerPage={false}
        />
      </Segment>
    </div>
  )
}
