import React from 'react'

import Widget from '../../../components/widget/Widget'
import { CountByDateWidget } from '../widgets/CountByDateWidget'
import { UsersWithNoCallsWidget } from '../widgets/UsersWithNoCallsWidget'
import { CallsByUserWidget } from '../widgets/CallsByUserWidget'

export const UsageSection = () => {
  return (
    <div data-testid="reports-usage">
      <Widget
        widgetId="usage-usersWithCalls"
        label="Users With Calls"
        subtitle="Time series of the count of agents with calls"
        showViewToggle
        showCsvDownload
      >
        <CountByDateWidget countedObj="user" />
      </Widget>
      <Widget
        widgetId="usage-totalCalls"
        label="Total Calls"
        subtitle="Count of calls by users in the time period"
        showGroupBy
        showViewToggle
        showCsvDownload
      >
        <CountByDateWidget countedObj="call" />
      </Widget>
      <Widget
        widgetId="usage-callsByUser"
        label="Calls By User"
        subtitle="The count of calls for each select agent"
        showViewToggle
        showCsvDownload
      >
        <CallsByUserWidget />
      </Widget>
      <Widget
        widgetId="usage-usersWithNoCalls"
        label="Users With No Calls"
        subtitle="No calls during time period in the assigned playbook"
        tableOnly
        showCsvDownload
      >
        <UsersWithNoCallsWidget />
      </Widget>
    </div>
  )
}
