import React from 'react'
import classNames from 'classnames'
import { Button } from 'semantic-ui-react'
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react'
import { SearchableDropdown } from '@/components/dropdowns/SearchableDropdown'

import { getPageBeginning, getPageEnd } from './helpers'

import './AdvancedTablePagination.scss'

export const AdvancedTablePagination = ({
  activePage,
  rowsPerPage,
  count,
  totalPages,
  setActivePage,
  transparent,
  dataGrid,
  handleUpdateRowsPerPage,
}) => {
  const pageBeginning = getPageBeginning(activePage, rowsPerPage, count)
  const pageEnd = getPageEnd(activePage, totalPages, rowsPerPage, count, pageBeginning)

  return (
    <div
      className={classNames('advanced-table__pagination', {
        transparent,
        'no-border': dataGrid,
      })}
      data-testid="advanced-table-pagination"
    >
      <div className="advanced-table__pagination--details">
        {handleUpdateRowsPerPage && (
          <SearchableDropdown
            menuPosition="top"
            hideSearch
            placeholder="Select Rows per Page"
            options={[
              { value: 10, label: '10 Rows per Page' },
              { value: 20, label: '20 Rows per Page' },
              { value: 30, label: '30 Rows per Page' },
              { value: 40, label: '40 Rows per Page' },
              { value: 50, label: '50 Rows per Page' },
            ]}
            selected={rowsPerPage}
            handleSelect={handleUpdateRowsPerPage}
            buttonProps={{ className: 'basic compact' }}
          />
        )}
        <div>{`${pageBeginning} – ${pageEnd} of ${count}`}</div>
      </div>
      <div className="advanced-table__pagination--arrows">
        <Button
          icon
          secondary
          compact
          disabled={activePage === 1}
          data-testid="pagination-prev"
          type="button"
          className="svg-button"
          onClick={() => activePage !== 1 && setActivePage((prevState) => prevState - 1)}
        >
          <IconArrowLeft />
        </Button>
        <Button
          icon
          secondary
          compact
          data-testid="pagination-next"
          disabled={activePage === totalPages}
          type="button"
          className="svg-button"
          onClick={() => activePage !== totalPages && setActivePage((prevState) => prevState + 1)}
        >
          <IconArrowRight />
        </Button>
      </div>
    </div>
  )
}
