import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Form, Divider } from 'semantic-ui-react'
import { toArray, isEmpty, get, cloneDeep, isString } from 'lodash'
import { Field, FieldArray } from 'formik'
import uuid from 'uuid/v4'
import produce from 'immer'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { IconPlus, IconChevronRight, IconChevronDown, IconBulb } from '@tabler/icons-react'

import { TextField, TextArea, Select } from '@/components/forms/formik'
import { FieldWithLabel } from '@/components/forms/formik/FieldWithLabel'
import RichTextEditor from '@/components/richTextEditor/RichTextEditor'
import { Drag, Drop, DragAndDrop } from '@/components/dragDrop'

import { fetchKeywordsRepository } from '@/reducers/keywords/keywords.actions'
import { ErrorMessage } from '@/components/forms/ErrorMessage'
import { fetchInsightDetails, saveInsightEvent } from '@/reducers/insights/insightEvents.actions'
import {
  setInsightDetails,
  setInsightsReorderRecommended,
} from '@/reducers/insights/insightEvents.redux'

import { PlaybookFormActions } from '../../components/PlaybookFormActions'
import { FieldWarning } from '../../components/FieldWarning'
import { FormObserver } from '../../components/FormObserver'
import { PlaybookEntryFormTriggers } from '../../components/PlaybookEntryFormTriggers'
import { ReorderDecklist } from './ReorderDecklist'
import { EntryInsight } from './EntryInsight'
import {
  dynamicPromptTriggerTypes,
  postcallTriggerTypes,
  accessors,
  triggerTimeUnits,
} from '../../playbook.helpers'
import { PlaybookEntryActions } from '../../components/PlaybookEntryActions'

const CategoryEntryFormComponent = ({
  flags,
  values,
  handleSubmit,
  handleCancel,
  errors,
  entryId,
  accessor,
  readOnly,
  categoryName,
  recommendation,
  ...formikProps
}) => {
  const dispatch = useDispatch()
  const [focusInput, setFocusInput] = useState(false)
  const [showAdvancedDisplay, setShowAdvancedDisplay] = useState(
    !isEmpty(values.display?.html) || false
  )
  const {
    playbook: {
      current: isLatestPlaybookVersion,
      cid,
      id: playbookId,
      organization_id: playbookOrgId,
    },
    loading,
  } = useSelector((state) => state.playbook)
  const { insightDetails, reorderRecommended, suggestedOrder } = useSelector(
    (state) => state.insightEvents
  )

  const { organizationid: userOrgId, user_id } = useSelector((state) => state.currentUser)
  const isAdmin = userOrgId === 1
  const deckName = values.name
  const {
    keywords,
    sideMapping,
    loading: keywordsLoading,
    language: keywordsLanguage,
  } = useSelector((state) => state.keywords)
  const { language: playbookLanguage } = useSelector((state) => state.playbook?.playbook)
  const showDisplay = accessor === accessors.DYNAMIC_PROMPT
  const triggerTypeOptions =
    accessor === accessors.DYNAMIC_PROMPT ? dynamicPromptTriggerTypes : postcallTriggerTypes
  // only give smart phrases option if flag on and english playbook (no support for spanish)
  const updatedTriggerTypeOptions =
    flags?.smartPhrasesTriggerType && playbookLanguage === 'english'
      ? [...triggerTypeOptions, { label: 'Smart Phrases', value: 'smart_phrases' }]
      : triggerTypeOptions
  const submitDisabled = readOnly || !isEmpty(errors)

  // SLP default and restricted sides
  const selectedKRID = values.trigger.krid
  const selectedSide = values.trigger.side

  const sideHidden = ['words_per_minute', 'dead_air'].includes(values.trigger.type)
  const deadAirSelected = values.trigger.type === 'dead_air'
  const talkSpeedSelected = values.trigger.type === 'words_per_minute'
  const minimumThresholdTime = values.trigger.time_unit === 'sec' ? '10' : '1'
  const sideInfo = sideMapping[selectedKRID]
  const sideRestricted = sideInfo && sideInfo.side && sideInfo.restricted

  useEffect(() => {
    if (accessor === accessors.DYNAMIC_PROMPT) {
      dispatch(
        fetchInsightDetails(cid, playbookOrgId, categoryName, deckName, values.display?.decklist)
      )
    }
  }, [accessor])

  useEffect(() => {
    if (deadAirSelected) {
      formikProps.setValues({
        ...values,
        trigger: {
          ...values.trigger,
          threshold_time: values.trigger.threshold_time || 10,
          time_unit: values.trigger.time_unit || 'sec',
          side: 'me',
        },
      })
    } else if (talkSpeedSelected) {
      formikProps.setValues({
        ...values,
        trigger: { ...values.trigger, side: 'me', threshold_time: undefined, time_unit: undefined },
      })
    } else if (!sideHidden) {
      formikProps.setValues({
        ...values,
        trigger: { ...values.trigger, threshold_time: undefined, time_unit: undefined },
      })
    }
  }, [deadAirSelected, talkSpeedSelected, sideHidden])

  useEffect(() => {
    // get keywords if language is different or we don't have them yet
    if (playbookLanguage !== keywordsLanguage || (isEmpty(keywords) && !keywordsLoading)) {
      dispatch(fetchKeywordsRepository(playbookLanguage))
    }
  }, [playbookLanguage])

  // case where an existing item from old playbook is misconfigured
  // depends on sideMapping so only done upon initial load
  useEffect(() => {
    if (sideRestricted && sideInfo.side !== selectedSide) {
      formikProps.setFieldValue('trigger.side', sideInfo.side)
    }
  }, [sideMapping])

  // When user switches category, we should update side to preferred value.
  // We do not update if side is already selected, otherwise side would be cleared when loading existing playbook items.
  // Note that the form's onChange handler clears the side when the KRID changes in order to handle
  // the case where a side is selected but KRID changes (since selectedSide will be True)
  useEffect(() => {
    if (sideInfo?.side && (sideRestricted || !selectedSide)) {
      formikProps.setFieldValue('trigger.side', sideInfo.side)
    }
  }, [selectedKRID])

  // arrayHelpers handles `decklist.order` and `decklist.entries` is handled manually
  const handleAddListItem = (arrayHelpers) => {
    if (isEmpty(get(errors, 'display.decklist.entries'))) {
      const newId = uuid()
      arrayHelpers.push(newId)
      formikProps.setFieldValue(`display.decklist.entries[${newId}].name`, '')
      setFocusInput(true)
    }
  }

  const handleDeleteListItem = (arrayHelpers, index, nestedEntryId, values) => {
    const updatedEntries = produce(values.display.decklist.entries, (newState) => {
      delete newState[nestedEntryId]
    })
    arrayHelpers.remove(index)
    formikProps.setFieldValue(`display.decklist.entries`, updatedEntries)
  }

  const handleWin = (nestedEntryId) => {
    const entry = { ...values.display.decklist.entries[nestedEntryId] }
    if (entry.win) {
      delete entry.win
      formikProps.setFieldValue(`display.decklist.entries[${nestedEntryId}]`, entry)
    } else {
      formikProps.setFieldValue(`display.decklist.entries[${nestedEntryId}].win`, true)
    }
  }

  const handleUndoDismissInsight = (dismissedInsightPayload) => {
    // set variables back to their original state so that the User can see the insight again.
    if (dismissedInsightPayload.action_recommended === 'reorder') {
      dispatch(setInsightsReorderRecommended(true))
    } else {
      const newInsightsDetailsState = insightDetails.map((insightDetail) =>
        insightDetail.item === dismissedInsightPayload.sub_item
          ? { ...insightDetail, [`${dismissedInsightPayload.action_recommended}`]: true }
          : insightDetail
      )
      dispatch(setInsightDetails(newInsightsDetailsState))
    }

    const undoPayload = { ...dismissedInsightPayload, action_taken: 'undo_dismiss' }
    dispatch(saveInsightEvent(undoPayload))
    toast.success('Restored dismissed insight')
  }

  const handleInsightEvent = (item, actionRecommended, actionTaken, details) => {
    const eventPayload = {
      user_id,
      config_id: playbookId,
      section: accessor,
      category: categoryName,
      item: deckName,
      sub_item: item,
      action_recommended: actionRecommended,
      action_taken: actionTaken,
      insight_details: details,
      new_config_id: null,
    }
    let newInsightsDetailsState = []

    // If insight is dismissed only turn off the flags that display the insight.
    // If any other action taken add the event to the insightEvents state.
    if (actionTaken === 'dismiss' && actionRecommended === 'reorder') {
      dispatch(setInsightsReorderRecommended(false))
    } else if (actionTaken === 'dismiss') {
      newInsightsDetailsState = insightDetails.map((insightDetail) =>
        insightDetail.item === item
          ? { ...insightDetail, [`${actionRecommended}`]: false }
          : insightDetail
      )
      dispatch(setInsightDetails(newInsightsDetailsState))
    }
    if (actionTaken === 'dismiss') {
      toast.success(
        <div>
          <span>You have dismissed this Balto Insight Recommendation for 30 days.</span>
          <Button
            className="inline-link-button"
            onClick={() => {
              handleUndoDismissInsight(eventPayload)
            }}
          >
            Undo
          </Button>
        </div>
      )
    }
    dispatch(saveInsightEvent(eventPayload))
  }

  const handleDismissInsight = () => {
    handleInsightEvent(null, 'reorder', 'dismiss', insightDetails)
  }

  const useInsightOrder = (decklist, orderSuggestion) => {
    const newOrder = decklist.order
      .filter((item) => orderSuggestion.includes(item))
      .sort((a, b) => orderSuggestion.indexOf(a) - orderSuggestion.indexOf(b))

    // add items newly added or not present in the suggested ranking.
    if (decklist.order.length !== orderSuggestion.length) {
      decklist.order.filter((item) => !newOrder.includes(item)).map((item) => newOrder.push(item))
    }

    // new variable stores the decklist object and only changes the order attribute
    const newDecklist = cloneDeep(decklist)
    newDecklist.order = newOrder

    formikProps.setFieldValue(`display.decklist`, newDecklist)
    handleInsightEvent(null, 'reorder', 'accept', insightDetails)
    dispatch(setInsightsReorderRecommended(false))
  }

  const insightAction = (fieldName, actionTaken, actionRecommended = null) => {
    let removalReason = actionRecommended
    // Try and pull reason from insight details if not provided
    if (!removalReason) {
      const insight = insightDetails.find((insight) => insight.item === fieldName.name)
      if (insight?.drop_performance) {
        removalReason = 'drop_performance'
      } else if (insight?.drop_count) {
        removalReason = 'drop_count'
      }
    }
    // Handle an insight event if a reason to remove exists, which would be given by the insight
    // If there isn't a reason, it means the user removed an item not because of any insight provided
    if (removalReason) {
      handleInsightEvent(fieldName.name, removalReason, actionTaken, insightDetails)
    }
  }

  const updateTriggerWithAi = (krid) => {
    formikProps.setFieldValue('trigger.type', 'transcription_classifier')
    formikProps.setFieldValue('trigger.krid', krid)
  }

  const clearTriggerValues = ({ value }) => {
    formikProps.setFieldValue('trigger.phrases', undefined)
    formikProps.setFieldValue('trigger.krid', undefined)
    formikProps.setFieldValue('trigger.ratio', undefined)
    formikProps.setFieldValue('trigger.side', undefined)

    // A previous value is being validated after these changes if we don't update here
    formikProps.setFieldValue('trigger.type', value)
  }

  return (
    <Form onSubmit={handleSubmit} className="playbook-detail__content">
      <FormObserver entryId={entryId} sectionName={accessor} />
      <PlaybookFormActions
        isDisabled={submitDisabled}
        handleCancel={handleCancel}
        dirty={formikProps.dirty}
      />
      <Field
        required
        label="Name"
        name="name"
        placeholder="e.g. Budget, Wrong Person, ..."
        component={TextField}
        disabled={readOnly}
      />
      {!readOnly && (
        <FieldWarning
          accessor={accessor}
          field="name"
          entryId={entryId}
          updateTriggerWithAi={updateTriggerWithAi}
        />
      )}

      {showDisplay && (
        <>
          <Divider horizontal>Display</Divider>
          <Field
            label="Headline"
            name="display.header"
            placeholder="A quick opening line"
            component={TextField}
            disabled={readOnly}
          />
          <Form.Field className="ui-input-item">
            <label>Items</label>
            <ReorderDecklist
              reorderRecommend={reorderRecommended}
              useInsightOrder={() => {
                useInsightOrder(values.display.decklist, suggestedOrder)
              }}
              dismissInsight={handleDismissInsight}
            />
            <FieldArray
              name="display.decklist.order"
              render={(arrayHelpers) => (
                <div className="nested-list-items">
                  <DragAndDrop
                    onDragEnd={({ source, destination }) => {
                      arrayHelpers.move(source.index, destination.index)
                    }}
                  >
                    <Drop droppableId={`${accessor}-list-items-droppable`}>
                      {toArray(values.display.decklist?.order).map((nestedEntryId, index) => (
                        <Drag
                          key={nestedEntryId}
                          draggableId={nestedEntryId}
                          index={index}
                          alwaysShowIcon
                          readOnly={readOnly}
                        >
                          <div className="category-entry-checklist-item">
                            <div className="category-entry-checklist-item__editor">
                              <Field
                                name={`display.decklist.entries[${nestedEntryId}].name`}
                                component={TextArea}
                                onKeyDown={(event) => {
                                  if (event.keyCode === 13) {
                                    event.preventDefault()
                                    handleAddListItem(arrayHelpers, formikProps)
                                  }
                                }}
                                autoFocus={focusInput}
                                disabled={readOnly}
                              />
                              <PlaybookEntryActions
                                isWinnable
                                readOnly={readOnly}
                                handleWin={() => handleWin(nestedEntryId)}
                                handleDelete={() =>
                                  handleDeleteListItem(arrayHelpers, index, nestedEntryId, values)
                                }
                                entry={values.display.decklist.entries[nestedEntryId]}
                              />
                            </div>
                            {insightDetails && isLatestPlaybookVersion && (
                              <EntryInsight
                                insightDetails={insightDetails}
                                fieldName={values.display.decklist.entries[nestedEntryId]}
                                insightAction={(action) =>
                                  insightAction(
                                    values.display.decklist.entries[nestedEntryId],
                                    action
                                  )
                                }
                                handleDeleteListItem={() =>
                                  handleDeleteListItem(arrayHelpers, index, nestedEntryId, values)
                                }
                                isAdmin={isAdmin}
                              />
                            )}

                            {recommendation === nestedEntryId && (
                              <div className="recommendation-indicator">
                                <IconBulb />
                                Added as a recommendation on {format(new Date(), 'M/d/yy')}
                              </div>
                            )}

                            <FieldWarning
                              accessor={accessor}
                              field="name"
                              entryId={entryId}
                              nestedEntryId={nestedEntryId}
                            />
                          </div>
                        </Drag>
                      ))}
                    </Drop>
                  </DragAndDrop>
                  {!isEmpty(get(errors, 'display.decklist.entries')) && (
                    <ErrorMessage
                      warning
                      content="Items must not be empty"
                      style={{ margin: '-1rem 0 1rem' }}
                    />
                  )}
                  <Button
                    data-testid="add-list-item-button"
                    type="button"
                    icon
                    primary
                    className="svg-button"
                    onClick={() => handleAddListItem(arrayHelpers, formikProps)}
                    disabled={readOnly || !isEmpty(get(errors, 'display.decklist.entries'))}
                  >
                    <IconPlus /> Add Item
                  </Button>
                </div>
              )}
            />
          </Form.Field>
          <Field
            label="Close"
            name="display.footer"
            placeholder="A powerful transition back to the checklist"
            component={TextField}
            disabled={readOnly}
          />
          {!flags?.cloudHideDynamicPromptAdvancedOptions2022 && (
            <>
              <button
                type="button"
                onClick={() => setShowAdvancedDisplay((prev) => !prev)}
                className="text-button"
                style={{ marginBottom: '0.25rem' }}
              >
                <span>Advanced Options</span>
                {!showAdvancedDisplay ? (
                  <IconChevronRight className="icon-svg" />
                ) : (
                  <IconChevronDown className="icon-svg" />
                )}
              </button>
              {showAdvancedDisplay && (
                <FieldWithLabel
                  name="display.html"
                  inlineVerbatim
                  component={RichTextEditor}
                  disabled={readOnly}
                />
              )}
            </>
          )}
          {!isEmpty(errors) && isString(errors.display) && (
            <ErrorMessage content={errors.display} style={{ margin: '1rem 0' }} />
          )}
        </>
      )}

      <Divider horizontal>Trigger</Divider>
      <Field
        required
        label="Trigger Type"
        name="trigger.type"
        component={Select}
        options={updatedTriggerTypeOptions}
        isClearable={false}
        disabled={readOnly}
        onChange={clearTriggerValues}
      />
      <PlaybookEntryFormTriggers
        loading={loading}
        values={values}
        dispatch={dispatch}
        triggerType={values.trigger?.type}
        formikProps={formikProps}
        readOnly={readOnly}
        sideDisabled={readOnly || sideRestricted}
        accessor={accessor}
        entryId={entryId}
        updateTriggerWithAi={updateTriggerWithAi}
        sideHidden={sideHidden}
        triggerHidden={sideHidden}
      />
      {sideHidden && deadAirSelected && (
        <Form.Field>
          <label>Time Configuration</label>
          <div className="form-caption">
            Specify the length of dead air that must be present before this notification triggers
          </div>
          <div className="form-row">
            <Field
              name="trigger.threshold_time"
              className="small-field"
              component={TextField}
              type="number"
              min={minimumThresholdTime}
              disabled={readOnly}
            />
            <Field
              name="trigger.time_unit"
              component={Select}
              options={triggerTimeUnits}
              isClearable={false}
              disabled={readOnly}
            />
          </div>
        </Form.Field>
      )}
      <PlaybookFormActions
        isDisabled={submitDisabled}
        handleCancel={handleCancel}
        dirty={formikProps.dirty}
      />
    </Form>
  )
}

export const CategoryEntryForm = withLDConsumer()(CategoryEntryFormComponent)
