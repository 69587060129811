// Action Types
export const SET_ORGANIZATION_VOIP_CAMPAIGNS = 'organizations/setOrganizationVoipCampaigns'

// Action Creators
export const setOrganizationVoipCampaigns = (payload) => ({
  type: SET_ORGANIZATION_VOIP_CAMPAIGNS,
  payload,
})

// State
export const initialState = { voipCampaigns: [] }

// Reducer
export default function organizationVoipCampaignsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORGANIZATION_VOIP_CAMPAIGNS:
      return { ...state, voipCampaigns: action.payload }
    default:
      return state
  }
}
