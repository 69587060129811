import React from 'react'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { Form } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

export const AlertTypesDropdown = () => {
  const dispatch = useDispatch()
  const { filters } = useSelector((state) => state.realtimeCoaching)

  const filterOptions = [
    { value: 'positive', label: 'Positive' },
    { value: 'negative', label: 'Negative' },
    { value: 'informative', label: 'Informative' },
  ]

  return (
    <Form.Field data-testid="rtc-alert-types-dropdown">
      <label htmlFor="alertTypes">Alert Types</label>
      <MultiSelect
        isSelectAll
        inputId="alertTypes"
        placeholderPill="All Alert Types"
        options={filterOptions}
        value={filters.alertTypes}
        onChange={(value) => dispatch(setFilter({ alertTypes: value }))}
      />
    </Form.Field>
  )
}
