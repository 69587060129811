import { Dropdown } from 'semantic-ui-react'
import React from 'react'

export const ChallengeStatusDropdown = ({ challenge, actions }) => {
  const statusDropdownOption = challenge.status === 'active' ? 'Cancel' : 'Delete'
  const statusDropdownAction =
    statusDropdownOption.toLowerCase() === 'cancel'
      ? () => actions.cancelChallenge(challenge)
      : () => actions.deleteChallenge(challenge)

  return (
    <Dropdown
      icon="ellipsis horizontal"
      button
      className="icon minimal-button"
      direction="left"
      data-testid="challenge-status-ellipsis"
    >
      <Dropdown.Menu>
        <Dropdown.Item
          data-testid="action-button-challenge-status"
          onClick={statusDropdownAction}
          className="cancelChallenge"
        >
          {statusDropdownOption}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
