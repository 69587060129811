/* eslint-disable consistent-return */
import React from 'react'
import { Button } from 'semantic-ui-react'
import { v4 } from 'uuid'
// this function returns hot key mappings
// for each mark
export const markHotkey = (options) => {
  const { type, key } = options

  // Return our "plugin" object, containing the `onKeyDown` handler.
  return {
    onKeyDown(event, editor, next) {
      // If it doesn't match our `key`, let other plugins handle it.
      if (!event.ctrlKey || event.key !== key) return next()

      // Prevent the default characters from being inserted.
      event.preventDefault()

      // Toggle the mark `type`.
      return editor.toggleMark(type)
    },
  }
}
// blocks = nodes they are same same
const BLOCK_TAGS = {
  p: 'paragraph',
  li: 'list-item',
  ul: 'bulleted-list',
  ol: 'numbered-list',
  h1: 'header-one',
  h2: 'header-two',
  hr: 'horizontal-rule',
  img: 'image',
}

const MARK_TAGS = {
  em: 'italic',
  strong: 'bold',
}

const INLINE_TAGS = {
  button: 'button',
  a: 'link',
}

// I know eslint is crying but don't put returns outside of the if block
// when one rule fails it continues to the next so if the function returns early/returns nothing
// nothing will serialize/deserialize

/* when adding a new rule, if you are looking to create a new tag, add it to the appropriate tag
however if you're looking to override behavior of an HTML tag we are using data-type to indicate
that and data-id so it can be parsed and added to the trigger dict in server */
export const rules = [
  {
    // deserialize functions tell slate how to turn html into slate's data structure
    deserialize(el, next) {
      const dataType =
        ['li', 'ul'].some((element) => element === el.tagName.toLowerCase()) &&
        el.getAttribute('data-type')
      // if an element has a dataType we assume that we want to override it
      const type = dataType || BLOCK_TAGS[el.tagName.toLowerCase()]

      if (type) {
        return {
          object: 'block',
          type,
          data: {
            className: el.getAttribute('class'),
            src: el.getAttribute('src'),
            'data-id': el.getAttribute('data-id'),
          },
          nodes: next(el.childNodes),
        }
      }
    },
    // serialize functions tell slate how to turn it's JSON data structure into html
    serialize(obj, children) {
      if (obj.object === 'block') {
        switch (obj.type) {
          case 'paragraph':
            return <p>{children}</p>
          case 'list-item':
            return <li>{children}</li>
          case 'inline-verbatim':
            return (
              <li className="inline-verbatim" data-type="inline-verbatim" data-id={v4()}>
                {children}
              </li>
            )
          case 'bulleted-list':
            return <ul>{children}</ul>
          case 'numbered-list':
            return <ol>{children}</ol>
          case 'header-one':
            return <h1>{children}</h1>
          case 'header-two':
            return <h2>{children}</h2>
          case 'image':
            return (
              <div>
                <img src={obj.data.get('src')} alt="" />
              </div>
            )
          case 'horizontal-rule':
            return (
              <div>
                <hr />
              </div>
            )
          case 'inline-verbatim-container':
            // we use id here for the trigger dict
            return (
              <ul className="inline-verbatim-container" data-type="inline-verbatim-container">
                {children}
              </ul>
            )
          default:
            return ''
        }
      }
    },
  },
  {
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()]
      if (type) {
        return {
          object: 'mark',
          type,
          nodes: next(el.childNodes),
        }
      }
    },
    serialize(obj, children) {
      if (obj.object === 'mark') {
        switch (obj.type) {
          case 'bold':
            return <strong>{children}</strong>
          case 'italic':
            return <em>{children}</em>
          default:
            return ''
        }
      }
    },
  },
  {
    deserialize(el, next) {
      const type = INLINE_TAGS[el.tagName.toLowerCase()]
      if (type) {
        return {
          object: 'inline',
          type,
          nodes: next(el.childNodes),
          data: {
            href: el.getAttribute('href'),
          },
        }
      }
    },
    serialize(obj, children) {
      if (obj.object === 'inline') {
        switch (obj.type) {
          case 'link':
            return (
              <a href={obj.data.get('href')} rel="noopener noreferrer" target="_blank">
                {children}
              </a>
            )
          case 'button':
            return (
              <button type="button" href={obj.data.get('href')}>
                {children}
              </button>
            )
          default:
            return ''
        }
      }
    },
  },
]
// these functions turn p tags into links/buttons and vice versa
export const wrapInlineNode = (editor, href, slateObjectType) => {
  editor.wrapInline({
    type: slateObjectType,
    data: { href },
  })
}

export const unwrapInlineNode = (editor, slateObjectType) => {
  editor.unwrapInline(slateObjectType)
}

export const insertImage = (editor, src, target) => {
  if (target) {
    editor.select(target)
  }

  editor.insertBlock({
    type: 'image',
    data: { src },
  })

  editor.moveToEnd()
}
// this defines how you render nodes, a node is a "block", typically nodes won't coexist
// except for links/buttons which are "inline" nodes
export const renderNode = (props, editor, next) => {
  switch (props.node.type) {
    case 'list-item':
      return <li {...props.attributes}>{props.children}</li>
    case 'bulleted-list':
      return <ul {...props.attributes}>{props.children}</ul>
    case 'numbered-list':
      return <ol {...props.attributes}>{props.children}</ol>
    case 'header-one':
      return <h1 {...props.attributes}>{props.children}</h1>
    case 'header-two':
      return <h2 {...props.attributes}>{props.children}</h2>
    case 'horizontal-rule':
      return <hr {...props.attributes} />
    case 'button':
      return (
        <Button
          {...props.attributes}
          primary
          // preventdefault is important or click will close modal
          onClick={(e) => e.preventDefault()}
          size="small"
        >
          {props.children}
        </Button>
      )
    case 'image':
      return <img {...props.attributes} alt="" width="425" src={props.node.data.get('src')} />
    case 'link':
      return (
        <a
          {...props.attributes}
          // target blank is important or preview links will open on current page
          target="_blank"
          rel="noopener noreferrer"
          href={props.node.data.get('href')}
        >
          {props.children}
        </a>
      )
    case 'inline-verbatim-container':
      return (
        <ul {...props.attributes} style={{ listStyleType: 'circle' }}>
          {props.children}
        </ul>
      )
    case 'inline-verbatim':
      return <li {...props.attributes}>{props.children}</li>
    default:
      return next()
  }
}

// this defines how to render a mark, marks are styling applied to the contents of a block
export const renderMark = (props, editor, next) => {
  switch (props.mark.type) {
    case 'bold':
      return <strong {...props.attributes}>{props.children}</strong>
    case 'italic':
      return <em {...props.attributes}>{props.children}</em>
    default:
      return next()
  }
}
