import React from 'react'
import classNames from 'classnames'

import './WidgetTooltip.scss'

const WidgetTooltip = ({ color, children, pointing, small, ...props }) => {
  return (
    <div
      data-testid="widget-tooltip"
      className={classNames('widget__tooltip', { pointing, color, small })}
      {...props}
    >
      {color && (
        <div>
          <div className="color-indicator" style={{ background: color }} data-testid={color} />
        </div>
      )}
      <div data-testid={pointing ? 'pointing' : 'not-pointing'}>{children}</div>
    </div>
  )
}

export default WidgetTooltip
