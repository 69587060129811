import React from 'react'
import { isFunction, truncate } from 'lodash'

import { Pill } from '@/components/pills/Pill'

import { FilterPillAction } from './FilterPillAction'

import './FilterPill.scss'

export const FilterPill = ({
  accessor,
  label,
  handleRemoveFilter,
  format,
  value = '',
  isClearable = true,
  loading,
  ...props
}) => {
  const displayValue = isFunction(format) ? format(accessor) : value
  const hasSelection = !!displayValue

  return (
    <Pill className="filter-pill-button" transparent ghost={!hasSelection} {...props}>
      <FilterPillAction
        hasSelection={hasSelection}
        handleRemoveFilter={handleRemoveFilter}
        loading={loading}
        isClearable={isClearable}
      />

      <strong>
        {label}
        {hasSelection && ':'}
      </strong>
      {hasSelection && <span>{truncate(displayValue, { length: 25 })}</span>}
    </Pill>
  )
}
