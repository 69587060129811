import React, { useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Popup, Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import { audioErrorSwitch } from '@/utils/helpers'
import { setCallExplorerData } from '@/reducers/callSearch/callSearch.redux'
import { fetchAudioUrl, setHardSelectedEvent } from '@/reducers/callSearch/callSearch.actions'
import { useVideoPlayer } from '@/components/VideoPlayerContext'
import { IconVideoOff, IconVideo } from '@tabler/icons-react'
import { SKIP_MODIFIER } from '@/utils/constants'
import { SkipButton } from '@/components/audio/components/SkipButton'
import { PlayPauseButton } from '@/components/audio/components/PlayPauseButton'
import { PlaybackRateDropdown } from '@/components/audio/components/PlaybackRateDropdown'
import { AudioTrackProgress } from '@/components/audio/components/AudioTrackProgress'
import { AudioTrackTime } from '@/components/audio/components/AudioTrackTime'
import { VolumeSlider } from '@/components/audio/components/VolumeSlider'

import { parseCallExplorerParams, updateCallExplorerParams } from './helpers'

import './AudioPlayer.scss'

// This acts as a buffer so the file is refreshed before it actually expires
// Hopefully avoiding interruptions for the user
export const AUDIO_EXPIRATION_BUFFER = 60

export const AudioPlayer = ({
  transcriptScrollerRef = { current: null },
  isScreenCaptureAvailable,
  showAudioPlayer,
  isAudioAvailable,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const audioRef = useRef()
  const trackProgressRef = useRef()
  const trackExpirationRef = useRef()

  const { callExplorer } = useSelector((state) => state.callSearch)
  const {
    audioUrlExpiration,
    audioDuration: duration,
    audioProgress: progress,
    audioPlaybackRate,
    audioPlaying: isPlaying,
    audioVolume,
    audioError,
    audioUrl,
    callId,
    hardSelectedEvent,
  } = callExplorer
  const {
    skipForward,
    skipBackward,
    changePlaybackSpeed,
    jumpTo,
    togglePlayPause,
    toggleVideoVisibility,
    isVideoVisible,
    videoRef,
  } = useVideoPlayer()

  const setIsPlaying = (value) => {
    dispatch(setCallExplorerData({ audioPlaying: value }))
  }

  const setProgress = (value) => {
    dispatch(setCallExplorerData({ audioProgress: value }))
  }

  const setDuration = (value) => {
    dispatch(setCallExplorerData({ audioDuration: value }))
  }

  const setVolume = (value) => {
    dispatch(setCallExplorerData({ audioVolume: value }))
  }

  const handleAudioPlaybackRateChange = (event, option) => {
    const playbackRate = parseFloat(option.value)

    dispatch(setCallExplorerData({ audioPlaybackRate: playbackRate }))

    localStorage.setItem('audioPlaybackRate', playbackRate)

    if (audioRef.current) {
      audioRef.current.playbackRate = playbackRate
    }
  }

  const togglePlayState = () => {
    setIsPlaying(!isPlaying)
  }

  const setErrorState = (value) => {
    dispatch(setCallExplorerData({ audioError: value }))
  }

  const startProgressTracking = () => {
    // Clear any timers already running
    clearInterval(trackProgressRef.current)

    trackProgressRef.current = setInterval(() => {
      const currentTime = isAudioAvailable
        ? audioRef.current.currentTime
        : videoRef.current.currentTime
      const isEnded = isAudioAvailable ? audioRef.current.ended : videoRef.current.ended
      isEnded ? setIsPlaying(false) : setProgress(currentTime)
    }, [50])
  }
  // doing this because we need access to the audio player ref in the transcriptViewer component
  // if I use just one ref (in transcriptviewer and pass to here) and don't create audioRef here,
  // it breaks all the tests because I can't pass a real ref to this component in tests
  useEffect(() => {
    transcriptScrollerRef.current = audioRef.current
  }, [audioRef.current])

  useEffect(() => {
    const isAudioOrVideoAvailable = isAudioAvailable || isScreenCaptureAvailable
    if (isPlaying) {
      if (isAudioAvailable) {
        audioRef.current.play()
      }
      if (isScreenCaptureAvailable) {
        togglePlayPause('play')
      }
      if (isAudioOrVideoAvailable) {
        startProgressTracking()
      }
    } else {
      clearInterval(trackProgressRef.current)
      if (progress > 0) {
        updateCallExplorerParams(location, history, { timestamp: progress })
      }

      if (isAudioAvailable) {
        audioRef.current.pause()
      }
      if (isScreenCaptureAvailable) {
        togglePlayPause('pause')
      }
    }
  }, [isPlaying])

  useEffect(() => {
    const hardSelectedTime = hardSelectedEvent?.timestamp
    // There is no timestamp on render therefore requiring a value check here
    if (hardSelectedTime !== undefined && !audioError) {
      audioRef.current.currentTime = hardSelectedTime
      setProgress(hardSelectedTime)
      jumpTo(hardSelectedTime)
    }
  }, [hardSelectedEvent])

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = audioPlaybackRate
    }
    if (isScreenCaptureAvailable) {
      changePlaybackSpeed(audioPlaybackRate)
    }
  }, [audioPlaybackRate])

  useEffect(() => {
    const setDurationListener = (e) => {
      setDuration(Math.floor(e.target.duration))
    }
    const errorListener = () => {
      audioErrorSwitch(audioRef.current.error.code)

      setErrorState(true)
    }

    audioRef.current.addEventListener('loadedmetadata', setDurationListener)
    audioRef.current.addEventListener('error', errorListener)

    if (!(showAudioPlayer && isAudioAvailable) && isScreenCaptureAvailable) {
      videoRef.current.addEventListener('loadedmetadata', setDurationListener)
    }

    if (audioUrlExpiration > 0) {
      const intervalValue = audioUrlExpiration * 1000 - AUDIO_EXPIRATION_BUFFER
      trackExpirationRef.current = setInterval(() => {
        dispatch(fetchAudioUrl(callId))
      }, intervalValue)
    }

    const storedPlaybackRate = localStorage.getItem('audioPlaybackRate')
    if (storedPlaybackRate) {
      dispatch(setCallExplorerData({ audioPlaybackRate: parseFloat(storedPlaybackRate) }))
    }

    const { timestamp: strTimestamp, eventId, autoplay } = parseCallExplorerParams(location)
    const timestamp = Number(strTimestamp)
    if (timestamp) {
      if (isAudioAvailable) {
        audioRef.current.currentTime = timestamp
      }
      if (isScreenCaptureAvailable) {
        jumpTo(timestamp)
      }
      setProgress(timestamp)
      // we set this in EventViewer if there's a selected event
      if (!eventId) {
        dispatch(setHardSelectedEvent({ timestamp }))
      }
      if (autoplay) {
        setIsPlaying(true)
      }
    }

    return () => {
      audioRef.current.removeEventListener('loadedmetadata', setDurationListener)
      audioRef.current.removeEventListener('error', errorListener)
      clearInterval(trackProgressRef.current)
      clearInterval(trackExpirationRef.current)
    }
  }, [])

  const handleScrub = (event) => {
    // For range inputs, value is always a string, but valueAsNumber can be used for numbers
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/range
    const newTime = event.target.valueAsNumber

    setProgress(newTime)
    jumpTo(newTime)
    audioRef.current.currentTime = newTime

    updateCallExplorerParams(location, history, { timestamp: newTime })
  }

  const handleForwardSkip = () => {
    const canScrubForward = progress + SKIP_MODIFIER <= duration

    if (canScrubForward) {
      setProgress(progress + SKIP_MODIFIER)
      skipForward()
      audioRef.current.currentTime += SKIP_MODIFIER
    } else {
      // Max progress is the duration as an integer, so this just sets to the end of the range
      setProgress(duration) // BUG: duration might round up to be higher than total
      audioRef.current.currentTime = duration
    }
  }

  const handleBackSkip = () => {
    const canScrubBack = progress - SKIP_MODIFIER >= 0

    if (canScrubBack) {
      setProgress(progress - SKIP_MODIFIER)
      skipBackward()
      audioRef.current.currentTime -= SKIP_MODIFIER
    } else {
      setProgress(0)
      audioRef.current.currentTime = 0.0
    }
  }

  const handleVolumeChange = (value) => {
    if (audioRef.current) {
      audioRef.current.volume = value
      setVolume(value)
    }
  }

  const isDisabled = audioError && !isScreenCaptureAvailable
  const isPlayerVisible = (showAudioPlayer && isAudioAvailable) || isScreenCaptureAvailable
  const isPopupDisabled = !audioError || isScreenCaptureAvailable

  return (
    <div>
      <audio src={audioUrl} ref={audioRef} />
      <div className="audio-player">
        {isPlayerVisible && (
          <Popup
            inverted
            disabled={isPopupDisabled}
            content={
              <span data-testid="error-message">Audio file is unavailable at this time</span>
            }
            trigger={
              <div className="audio-controls-container" data-testid="audio-controls">
                <PlayPauseButton
                  isPlaying={isPlaying}
                  togglePlayState={togglePlayState}
                  isDisabled={isDisabled}
                />
                <div className="flex-align-center">
                  <SkipButton
                    modifier={SKIP_MODIFIER}
                    handleSkip={handleBackSkip}
                    isDisabled={isDisabled}
                  />
                  <SkipButton
                    modifier={SKIP_MODIFIER}
                    handleSkip={handleForwardSkip}
                    isForwardSkip
                    isDisabled={isDisabled}
                  />
                </div>
                <VolumeSlider
                  volume={audioVolume}
                  handleVolumeChange={handleVolumeChange}
                  isDisabled={audioError}
                />
              </div>
            }
          />
        )}
        <div className="additional-audio-options">
          {isScreenCaptureAvailable && (
            <Button className="svg-button" secondary icon onClick={toggleVideoVisibility}>
              {isVideoVisible ? <IconVideoOff className="status-critical" /> : <IconVideo />}
              Toggle Video
            </Button>
          )}
          {isPlayerVisible && (
            <PlaybackRateDropdown
              isDisabled={isDisabled}
              handleAudioPlaybackRateChange={handleAudioPlaybackRateChange}
              audioPlaybackRate={audioPlaybackRate}
            />
          )}
        </div>
      </div>
      {isPlayerVisible && (
        <div data-testid="audio-progress-track">
          <AudioTrackTime duration={duration} progress={progress} />
          <AudioTrackProgress
            duration={duration}
            progress={progress}
            isDisabled={isDisabled}
            handleScrub={handleScrub}
          />
        </div>
      )}
    </div>
  )
}
